import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen14 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const [checkedQues14Option1, setCheckedQues14Option1] = useState('');
	const [checkedQues14Option2, setCheckedQues14Option2] = useState('');

	const [checkedQues14Val, setCheckedQues14Val] = useState('');

	const [checkedQues15Option1, setCheckedQues15Option1] = useState('');
	const [checkedQues15Option2, setCheckedQues15Option2] = useState('');

	const [checkedQues15Val, setCheckedQues15Val] = useState('');

	const [checkedQues16Option1, setCheckedQues16Option1] = useState('');
	const [checkedQues16Option2, setCheckedQues16Option2] = useState('');

	const [checkedQues16Val, setCheckedQues16Val] = useState('');



	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(e.target.value);
		checkedVal(quesId, e.target.value)
	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques14": {
					"ans": checkedQues14Val,
					"ques_id": "14"
				},
				"ques15": {
					"ans": checkedQues15Val,
					"ques_id": "15"
				},
				"ques16": {
					"ans": checkedQues16Val,
					"ques_id": "16"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-03/chapter-04-screen-15')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	const BtnDisabled = () => {
		if (checkedQues14Val !== '' && checkedQues15Val !== '' && checkedQues16Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	const checkedVal = (param, ans) => {
		if (param === '14') {
			if (ans === 'option1') {
				setCheckedQues14Option1('checked')
				setCheckedQues14Option2('');

			} else if (ans === 'option2') {
				setCheckedQues14Option1('')
				setCheckedQues14Option2('checked');

			}
			setCheckedQues14Val(ans)
		}

		if (param === '15') {
			if (ans === 'option1') {
				setCheckedQues15Option1('checked')
				setCheckedQues15Option2('');

			} else if (ans === 'option2') {
				setCheckedQues15Option1('')
				setCheckedQues15Option2('checked');

			}
			setCheckedQues15Val(ans)
		}

		if (param === '16') {
			if (ans === 'option1') {
				setCheckedQues16Option1('checked')
				setCheckedQues16Option2('');

			} else if (ans === 'option2') {
				setCheckedQues16Option1('')
				setCheckedQues16Option2('checked');

			}
			setCheckedQues16Val(ans)
		}



	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-0">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3">{t('apply')}:  </h3>
									<p className="mb-2 mt-3"> {t('M3chapter04Screen14Text1')}</p>

									<a
										href="https://www.youtube.com/watch?v=oghXe2TI1Yc"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M3chapter04Screen14Text2')}</p>
											</div>
										</div>
									</a>


									<p className="mb-2 mt-3">{t('M3chapter04Screen14Text3')} </p>
									<div className="d-flex mt-3">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle1"
												id="mycirle5"
												defaultValue="option1"

												onClick={saveRadio}
												checked={checkedQues14Option1}
												data-quesId="14"

											/>
											<label className="form-check-label" htmlFor="mycirle5">{t('yes')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle1"
												id="mycirle6"
												defaultValue="option2"


												onClick={saveRadio}
												checked={checkedQues14Option2}
												data-quesId="14"
											/>
											<label className="form-check-label" htmlFor="mycirle6">{t('no')}</label>
										</div>
									</div>

									<p className="mb-2 mt-3 pn">{t('M3chapter04Screen14Text4')} </p>
									<div className="d-flex mt-3">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle2"
												id="mycirle1"
												defaultValue="option1"

												onClick={saveRadio}
												checked={checkedQues15Option1}
												data-quesId="15"
											/>
											<label className="form-check-label" htmlFor="mycirle1">{t('yes')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle2" id="mycirle2"

												defaultValue="option2"


												onClick={saveRadio}
												checked={checkedQues15Option2}
												data-quesId="15"
											/>
											<label className="form-check-label" htmlFor="mycirle2">{t('no')}</label>
										</div>
									</div>

									<p className="mb-2 mt-3 pn">{t('M3chapter04Screen14Text5')} </p>
									<div className="d-flex mt-3">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle3"
												id="mycirle3"

												defaultValue="option1"


												onClick={saveRadio}
												checked={checkedQues16Option1}
												data-quesId="16"
											/>
											<label className="form-check-label" htmlFor="mycirle3">{t('yes')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle3"
												id="mycirle4"
												defaultValue="option2"

												onClick={saveRadio}
												checked={checkedQues16Option2}
												data-quesId="16"

											/>
											<label className="form-check-label" htmlFor="mycirle4">{t('no')}</label>
										</div>
									</div>







								</div>

								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="70%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-2-2.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-13" />
								{/* <NextBtn link="/module-03/chapter-04-screen-15" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>




			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>
							<p className="text-white text-center">{t('M3chapter04Screen14Text6')} </p>


							<div className=" d-flex justify-content-center mt-3">
								<button className="button_su m-auto" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="/module-03/chapter-03-screen-11" className="button_su_inner"><span className="button_text_container"> {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen14;
