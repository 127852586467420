import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen07 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="2/5" percentage="58" star="58" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between mt-4">
								<div
									className="col-lg-7 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mt-3">
										{t('M4chapter03screen07Text1')}
										
									</p>

									<a
										href="https://feminisminindia.com/2018/05/16/bollywoods-tryst-toxic-masculinity/"
										target="_blank"
									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p> {t('M4chapter03screen07Text2')}{" "}
													<b> {t('M4chapter03screen07Text3')}</b></p>
											</div>
										</div>
									</a>

									<a
										href="https://hindi.feminisminindia.com/2020/09/21/men-masculinity-and-society-hindi/"
										target="_blank"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p> {t('M4chapter03screen07Text4')}{" "}
													<b>{t('M4chapter03screen07Text5')} </b></p>
											</div>
										</div>
									</a>

									<a
										href="https://www.indianwomenblog.org/feminist-nandita-shah-dares-honey-singh-to-sing-about-consent-equality/"
										target="_blank"
									>
										<div className="d-flex align-items-center mange-icon ">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p> {t('M4chapter03screen07Text6')}</p>
											</div>
										</div>
									</a>


								</div>
								<div
									className="col-lg-5 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<iframe
										width="100%"
										height={300}
										src="https://www.youtube.com/embed/61bdro-rHWE"
										title="Let's Talk About Toxic Masculinity Again | Breakthrough India"
										frameBorder={0}
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowFullScreen=""
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-03-screen-06" />

									<NextBtn link="/module-04/chapter-03-screen-08" text={t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen07;
