import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";


const Chapter02Screen02 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper page-auto md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="25" star="1" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3 mt-m-3">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h4>{t('learn')}</h4>

									<p className="mb-2 mt-3">{t('M5chapter02Screen2Text1')}  </p>

								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="100%"
											draggable="false"
											src="/assets/img/md3/md3-shap-2-3.png"
											alt=""
										/>
									</center>
								</div>

							</div>






						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-05/chapter-02-screen-01" />
									<NextBtn link="/module-05/chapter-02-screen-02n" text={t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
