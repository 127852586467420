import React, { useState, useRef, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter03Screen03 = () => {

	const dragItem = useRef();
	const dragOverItem = useRef();
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	let nameText1 = t('M6hapter01screen05Text2')
	let nameText2 = t('M6hapter01screen05Text3')
	let nameText3 = t('M6hapter01screen05Text4')
	let nameText4 = t('M6hapter01screen05Text5')

	let descText1 = t('M6hapter01screen05Text6')
	let descText2 = t('M6hapter01screen05Text7')
	let descText3 = t('M6hapter01screen05Text8')
	let descText4 = t('M6hapter01screen05Text9')


	const [list, setList] = useState([
		{ "id": "1", "name": nameText1 },
		{ "id": "2", "name": nameText2 },
		{ "id": "3", "name": nameText3 },
		{ "id": "4", "name": nameText4 },

	]);

	const [list1, setList1] = useState([
		{ "id": "1", "desc": descText1 },
		{ "id": "2", "desc": descText2 },
		{ "id": "3", "desc": descText3 },
		{ "id": "4", "desc": descText4 },

	]);


	// const [arrangeList, setArrangeList] = useState([]);

	const dragStart = (e, position) => {
		console.log(position);
		dragItem.current = position;
		// console.log(e.target.innerHTML);
	};

	const dragEnter = (e, position) => {

		console.log("curr" + position);

		dragOverItem.current = position;
		// console.log(e.target.innerHTML);
	};

	const drop = (e) => {
		const copyListItems = [...list1];
		const dragItemContent = copyListItems[dragItem.current];
		copyListItems.splice(dragItem.current, 1);
		copyListItems.splice(dragOverItem.current, 0, dragItemContent);
		dragItem.current = null;
		dragOverItem.current = null;
		setList1(copyListItems);
	};

	// console.log(list1);

	const saveAns = () => {

		// alert();
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques3": {
					"ans": JSON.stringify(list), // as sexual activity
					"ans2": JSON.stringify(list1),      // as sexual behaviour
					"ques_id": "3"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/ApproachChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-06/chapter-01-screen-06');
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,

			"ques_id": "3"
		});

		var config = {
			method: 'post',
			url: BaseURL + '/approachChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				console.log(response);
				if (response.data.success === true) {
					// console.log(JSON.parse(response.data.data.chapter.ans));
					const ans = JSON.parse(response.data.data.chapter.ans)
					const ans2 = JSON.parse(response.data.data.chapter.ans2)


					setList(ans);
					setList1(ans2);





				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);


	const BtnDisabled = () => {
		if (list1.length !== 0) {
			return (
				<>

					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="18" star="50" />
					</div>
				</div>

				<div className="d-flex">
					<Sidebar />

					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-2">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="mb-2 h2 mt-3">{t('engage')}</h2>
									<p>{t('M6hapter01screen05Text1')}</p>
								</div>
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>


									<div className="row">
										<div className="col-md-6">
											<table className="table table-bordered table-dark table-red hand-cursor">

												<tbody>
													<tr >
														<td>{t('Sno')}</td>
														<td>{t('Left')}</td>
													</tr>
													<tr className="color-dark-red first-width">
														<td>1</td>
														<td>{nameText1}</td>
													</tr>
													<tr className="color-dark-red first-width">
														<td>2</td>
														<td>{nameText2} &nbsp;<br></br>&nbsp;<br></br>&nbsp;</td>

													</tr>
													<tr className="color-dark-red first-width">
														<td>3</td>
														<td>{nameText3}</td>
													</tr>

													<tr className="color-dark-red first-width">
														<td>4</td>
														<td>{nameText4}</td>
													</tr>




												</tbody>
											</table>
										</div>
										<div className="col-md-6">
											<table className="table table-bordered table-dark table-red hand-cursor">

												<tbody>
													<tr>
														<td>{t('Sno')}</td>
														<td>Right</td>
													</tr>
													{
														list1.map((item, index) => {
															return (
																<>
																	<tr className="color-dark-red">
																		<td>{item.id}</td>
																		<td>
																			<div
																				onDragStart={(e) => dragStart(e, index)}
																				onDragEnter={(e) => dragEnter(e, index)}
																				onDragEnd={drop}
																				key={index}
																				draggable
																			>
																				{item.desc}

																			</div>
																		</td>
																	</tr>
																</>
															)
														})
													}


												</tbody>
											</table>
										</div>
									</div>





									{/* <table className="table table-bordered table-dark table-red hand-cursor">

									<tbody>
										<tr>
											<td>S.No.</td>
											<td>Left</td>
											<td>Right</td>
										</tr>
										{
											list.map((item, index) => {
												return (
													<>
														<tr className="color-dark-red first-width">
															<td>{item.id}</td>
															<td>{item.name}</td>
															<td>
																<div
																	onDragStart={(e) => dragStart(e, index)}
																	onDragEnter={(e) => dragEnter(e, index)}
																	onDragEnd={drop}
																	key={index}
																	draggable
																>
																	{item.desc}

																</div>
															</td>
														</tr>
													</>
												)
											})
										}


									</tbody>
								</table> */}


								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-06/chapter-01-screen-04" />

								{/* <NextBtn link="/module-06/chapter-01-screen-06" text="Go Next" /> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen03;
