import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";

import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";


const Module1FinalQuizScreen02 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [checkedQues12Yes, setCheckedQues12Yes] = useState('');
  const [checkedQues12No, setCheckedQues12No] = useState('');
  const [checkedQues12Val, setCheckedQues12Val] = useState('');

  const [checkedQues13Yes, setCheckedQues13Yes] = useState('');
  const [checkedQues13No, setCheckedQues13No] = useState('');
  const [checkedQues13Val, setCheckedQues13Val] = useState('');

  const [checkedQues14Yes, setCheckedQues14Yes] = useState('');
  const [checkedQues14No, setCheckedQues14No] = useState('');
  const [checkedQues14Val, setCheckedQues14Val] = useState('');

  const [checkedQues15Yes, setCheckedQues15Yes] = useState('');
  const [checkedQues15No, setCheckedQues15No] = useState('');
  const [checkedQues15Val, setCheckedQues15Val] = useState('');

  const [checkedQues16Yes, setCheckedQues16Yes] = useState('');
  const [checkedQues16No, setCheckedQues16No] = useState('');
  const [checkedQues16Val, setCheckedQues16Val] = useState('');

  const [checkedQues17Yes, setCheckedQues17Yes] = useState('');
  const [checkedQues17No, setCheckedQues17No] = useState('');
  const [checkedQues17Val, setCheckedQues17Val] = useState('');

  const [checkedQues18Yes, setCheckedQues18Yes] = useState('');
  const [checkedQues18No, setCheckedQues18No] = useState('');
  const [checkedQues18Val, setCheckedQues18Val] = useState('');

  const [checkedQues19Yes, setCheckedQues19Yes] = useState('');
  const [checkedQues19No, setCheckedQues19No] = useState('');
  const [checkedQues19Val, setCheckedQues19Val] = useState('');

  const [checkedQues20Yes, setCheckedQues20Yes] = useState('');
  const [checkedQues20No, setCheckedQues20No] = useState('');
  const [checkedQues20Val, setCheckedQues20Val] = useState('');



  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");

    checkedVal(quesId,e.target.value)

  }

  const saveAns = () => {

    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": "",
          "ques_id": "10"
        },
        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
        "ques12": {
          "ans": checkedQues12Val,
          "ques_id": "12"
        },
        "ques13": {
          "ans": checkedQues13Val,
          "ques_id": "13"
        },
        "ques14": {
          "ans": checkedQues14Val,
          "ques_id": "14"
        },
        "ques15": {
          "ans": checkedQues15Val,
          "ques_id": "15"
        },
        "ques16": {
          "ans": checkedQues16Val,
          "ques_id": "16"
        },
        "ques17": {
          "ans": checkedQues17Val,
          "ques_id": "17"
        },
        "ques18": {
          "ans": checkedQues18Val,
          "ques_id": "18"
        },
        "ques19": {
          "ans": checkedQues19Val,
          "ques_id": "19"
        },
        "ques20": {
          "ans": checkedQues20Val,
          "ques_id": "20"
        },
      }
    });

    // console.log(data);
    var config = {
      method: 'post',
      url: BaseURL +'/GenderFinalQuiz',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-02/score')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues12Val !== '' && checkedQues13Val !== '' && checkedQues14Val !== ''&& checkedQues15Val !== ''
    && checkedQues16Val !== ''&& checkedQues17Val !== ''&& checkedQues18Val !== ''&& checkedQues19Val !== ''
    && checkedQues20Val !== ''
    ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };


  const checkedVal = (param,ans) => {




    if (param === '12') {
      if (ans === 'yes') {
        setCheckedQues12Yes('checked')
        setCheckedQues12No('')
        setCheckedQues12Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues12No('checked')
          setCheckedQues12Yes('')
          setCheckedQues12Val(ans)
        }
      }
    }

    if (param === '13') {
      if (ans === 'yes') {
        setCheckedQues13Yes('checked')
        setCheckedQues13No('')
        setCheckedQues13Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues13No('checked')
          setCheckedQues13Yes('')
          setCheckedQues13Val(ans)
        }
      }
    }

    if (param === '14') {
      if (ans === 'yes') {
        setCheckedQues14Yes('checked')
        setCheckedQues14No('')
        setCheckedQues14Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues14No('checked')
          setCheckedQues14Yes('')
          setCheckedQues14Val(ans)
        }
      }
    }

    if (param === '15') {
      if (ans === 'yes') {
        setCheckedQues15Yes('checked')
        setCheckedQues15No('')
        setCheckedQues15Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues15No('checked')
          setCheckedQues15Yes('')
          setCheckedQues15Val(ans)
        }
      }
    }

    if (param === '16') {
      if (ans === 'yes') {

        setCheckedQues16Yes('checked')
        setCheckedQues16No('')
        setCheckedQues16Val(ans)
      } else {
        if (ans === 'no') {

          setCheckedQues16No('checked')
          setCheckedQues16Yes('')
          setCheckedQues16Val(ans)
        }
      }
    }

    if (param === '17') {
      if (ans === 'yes') {
        setCheckedQues17Yes('checked')
        setCheckedQues17No('')
        setCheckedQues17Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues17No('checked')
          setCheckedQues17Yes('')
          setCheckedQues17Val(ans)
        }
      }
    }

    if (param === '18') {
      if (ans === 'yes') {
        setCheckedQues18Yes('checked')
        setCheckedQues18No('')
        setCheckedQues18Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues18No('checked')
          setCheckedQues18Yes('')
          setCheckedQues18Val(ans)
        }
      }
    }

    if (param === '19') {
      if (ans === 'yes') {
        setCheckedQues19Yes('checked')
        setCheckedQues19No('')
        setCheckedQues19Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues19No('checked')
          setCheckedQues19Yes('')
          setCheckedQues19Val(ans)
        }
      }
    }

    if (param === '20') {
      if (ans === 'yes') {
        setCheckedQues20Yes('checked')
        setCheckedQues20No('')
        setCheckedQues20Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues20No('checked')
          setCheckedQues20Yes('')
          setCheckedQues20Val(ans)
        }
      }
    }








  }

  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/GenderFinalQuizResult',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])




  return (
    <>
      <div className="wrapper">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
          <Header leftChapter="5/5" percentage="100" star="90" />
          </div>
        </div>
        <div className="d-flex">
            <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-center pt-20 mt-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <div className="wiout-border-table without-bg table-padding-2">
                    <table className="table">
                      <tbody>

                        <tr>
                          <td>

                          <p class="pn"> <span>12.</span>{t('M2quizscreen02Text1')}</p>


                          </td>
                          <td className="wdth-table-yes">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz2"
                                  id="yes2"
                                  defaultValue="yes"
                                  data-quesId="12"
                                  onClick={saveRadio}
                                  checked={checkedQues12Yes}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="yes2"
                                >
                                  {t('yes')}
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz2"
                                  id="no2"
                                  defaultValue="no"
                                  data-quesId="12"
                                  onClick={saveRadio}
                                  checked={checkedQues12No}
                                />
                                <label
                                  className="form-check-label "
                                  htmlFor="no2"
                                >
                                  {t('no')}
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>


                          <p class="pn"> <span>13.</span>
                            {t('M2quizscreen02Text2')}
                            {" "}</p>



                          </td>
                          <td className="wdth-table-yes">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz3"
                                  id="yes3"
                                  defaultValue="yes"
                                  data-quesId="13"
                                  onClick={saveRadio}
                                  checked={checkedQues13Yes}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="yes3"
                                >
                                  {t('yes')}
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz3"
                                  id="no3"
                                  defaultValue="no"
                                  data-quesId="13"
                                  onClick={saveRadio}
                                  checked={checkedQues13No}
                                />
                                <label
                                  className="form-check-label "
                                  htmlFor="no3"
                                >
                                  {t('no')}
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <p class="pn"> <span>14.</span>
                          {t('M2quizscreen02Text3')}
                          {" "}</p>


                          </td>
                          <td className="wdth-table-yes">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz4"
                                  id="yes4"
                                  defaultValue="yes"
                                  data-quesId="14"
                                  onClick={saveRadio}
                                  checked={checkedQues14Yes}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="yes4"
                                >
                                  {t('yes')}
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz4"
                                  id="no4"
                                  defaultValue="no"
                                  data-quesId="14"
                                  onClick={saveRadio}
                                  checked={checkedQues14No}
                                />
                                <label
                                  className="form-check-label "
                                  htmlFor="no4"
                                >
                                  {t('no')}
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>

                          <p class="pn"> <span>15.</span>
                          {t('M2quizscreen02Text4')}
                         {" "}</p>


                          </td>
                          <td className="wdth-table-yes">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz5"
                                  id="yes5"
                                  defaultValue="yes"
                                  data-quesId="15"
                                  onClick={saveRadio}
                                  checked={checkedQues15Yes}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="yes5"
                                >
                                  {t('yes')}
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz5"
                                  id="no5"
                                  defaultValue="no"
                                  data-quesId="15"
                                  onClick={saveRadio}
                                  checked={checkedQues15No}
                                />
                                <label
                                  className="form-check-label "
                                  htmlFor="no5"
                                >
                                  {t('no')}
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>

                          <p class="pn"> <span>16.</span>
                          {t('M2quizscreen02Text5')}
                          </p>


                          </td>
                          <td className="wdth-table-yes">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz6"
                                  id="yes6"
                                  defaultValue="yes"
                                  data-quesId="16"
                                  onClick={saveRadio}
                                  checked={checkedQues16Yes}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="yes6"
                                >
                                  {t('yes')}
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz6"
                                  id="no6"
                                  defaultValue="no"
                                  data-quesId="16"
                                  onClick={saveRadio}
                                  checked={checkedQues16No}
                                />
                                <label
                                  className="form-check-label "
                                  htmlFor="no6"
                                >
                                  {t('no')}
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>


                          <p class="pn"> <span>17.</span>
                          {t('M2quizscreen02Text6')}
                          </p>

                          </td>
                          <td className="wdth-table-yes">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz7"
                                  id="yes7"
                                  defaultValue="yes"
                                  data-quesId="17"
                                  onClick={saveRadio}
                                  checked={checkedQues17Yes}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="yes7"
                                >
                                  {t('yes')}
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz7"
                                  id="no7"
                                  defaultValue="no"
                                  data-quesId="17"
                                  onClick={saveRadio}
                                  checked={checkedQues17No}
                                />
                                <label
                                  className="form-check-label "
                                  htmlFor="no7"
                                >
                                  {t('no')}
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <p class="pn"> <span>18.</span>
                          {t('M2quizscreen02Text7')}
                          </p>


                          </td>
                          <td className="wdth-table-yes">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz8"
                                  id="yes8"
                                  defaultValue="yes"
                                  data-quesId="18"
                                  onClick={saveRadio}
                                  checked={checkedQues18Yes}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="yes8"
                                >
                                  {t('yes')}
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz8"
                                  id="no8"
                                  defaultValue="no"
                                  data-quesId="18"
                                  onClick={saveRadio}
                                  checked={checkedQues18No}
                                />
                                <label
                                  className="form-check-label "
                                  htmlFor="no8"
                                >
                                  {t('no')}
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>

                          <p class="pn"> <span>19.</span>
                          {t('M2quizscreen02Text8')}
                          {" "}</p>


                          </td>
                          <td className="wdth-table-yes">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz9"
                                  id="yes9"
                                  defaultValue="yes"
                                  data-quesId="19"
                                  onClick={saveRadio}
                                  checked={checkedQues19Yes}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="yes9"
                                >
                                  {t('yes')}
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz9"
                                  id="no9"
                                  defaultValue="no"
                                  data-quesId="19"
                                  onClick={saveRadio}
                                  checked={checkedQues19No}
                                />
                                <label
                                  className="form-check-label "
                                  htmlFor="no9"
                                >
                                  {t('no')}
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <p class="pn"> <span>20.</span>{t('M2quizscreen02Text9')}</p>
                          </td>
                          <td className="wdth-table-yes">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz10"
                                  id="yes10"
                                  defaultValue="yes"
                                  data-quesId="20"
                                  onClick={saveRadio}
                                  checked={checkedQues20Yes}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="yes10"
                                >
                                  {t('yes')}
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz10"
                                  id="no10"
                                  defaultValue="no"
                                  data-quesId="20"
                                  onClick={saveRadio}
                                  checked={checkedQues20No}
                                />
                                <label
                                  className="form-check-label "
                                  htmlFor="no10"
                                >
                                  {t('no')}
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
              <PrevBtn link="/module-02/module-01-finalquiz-screen-01" />
                {/* <NextBtn
                  link="/"
                  text="Go Next"
                /> */}

                <BtnDisabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Module1FinalQuizScreen02;
