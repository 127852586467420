import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';




const Chapter04Screen04 = () => {

	const navigate = useNavigate();
	const { t, i18n } = useTranslation();



	const [checkedQues3, setCheckedQues3] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3,

					"ques_id": "3"
				},
				"ques4": {
					"ans": "",

					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-04-screen-05')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					results.map((result) => {
						console.log(result);
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);
	const checkedVal = (param, ans) => {
		if (param === '3') {
			setCheckedQues3(ans)

		}

	}
	const BtnDisabled = () => {
		if (checkedQues3 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};




	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="36" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />




					<div className="data-adjust">
						<div className="container-fluid cf-set">

							<div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-4">

								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>


									<h4 className="mb-3"> <b> {t('M5chapter04Screen4Text1')}
									</b></h4>

									<h3>{t('engage')}</h3>
									<a
										href="https://www.theadvocatesforhumanrights.org/Defenders"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M5chapter04Screen4Text2')} </p>
											</div>
										</div>
									</a>

									<p className=" mt-2">{t('M5chapter04Screen4Text3')} </p>


									<p className=" mt-"> <b>{t('M5chapter04Screen4Text4')}</b></p>
									<a
										href="https://www.youtube.com/watch?v=1NRdZCN4Nos&list=PLuOmNjZTlhxRYaT_pdRnEAd73BAjR7M2v&index=9"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>#{t('M5chapter04Screen4Text5')}</p>
											</div>
										</div>
									</a>
									<a
										href="https://www.youtube.com/watch?v=7Jtzdfweggg&list=PLuOmNjZTlhxRYaT_pdRnEAd73BAjR7M2v&index=7"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>#{t('M5chapter04Screen4Text6')}</p>
											</div>
										</div>
									</a>
								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											className="mt-0"
											width="70%"
											src="/assets/img/md3/md3-shap-4-04.png"
											alt=""
										/>
									</center>
								</div>

							</div>


						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-05/chapter-04-screen-03" />
									<NextBtn link="/module-05/chapter-04-screen-05" text={t('CommonBtnGoNext')}
									/>
									{/* <BtnDisabled/> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen04;
