import React, { useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";
const Chapter03Screen03 = () => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showModalText, setShowModalText] = useState();
  const { t, i18n } = useTranslation();
  const ClickOpenPopup = (e) => {
    const dataYear = e.currentTarget.getAttribute("data-year");

    let nameText1 = t('M6hapter01screen06Text2')
    let nameText2 = t('M6hapter01screen06Text3')
    let nameText3 = t('M6hapter01screen06Text4')
    let nameText4 = t('M6hapter01screen06Text5')
    let nameText5 = t('M6hapter01screen06Text6')
    let nameText6 = t('M6hapter01screen06Text7')

    if (dataYear === '1999') {
      setShowModalText(nameText1)
    }
    if (dataYear === '2003') {
      setShowModalText(nameText2)
    }

    if (dataYear === '2008') {
      setShowModalText(nameText3)
    }
    if (dataYear === '2012') {
      setShowModalText(nameText4)
    }
    if (dataYear === '2017') {
      setShowModalText(nameText5)
    }

    if (dataYear === '2021') {
      setShowModalText(nameText6)
    }


    setShow(true);


  }

  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="0/4" percentage="25" star="50" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-3">
                <div
                  className="col-lg-9 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h3 className="h2 mb-1">{t('learn')}</h3>
                  <h3 className="h3 mb-1">{t('M6chapter01screen01')}</h3>
                  <p>
                  {t('M6hapter01screen06Text1')}
                  </p>

                  <div
                    className="road_map popup"
                    onClick={ClickOpenPopup}
                    data-year="1999"

                  >
                    <div className="circul d-flex align-items-center justify-content-center">
                      2018
                    </div>
                  </div>
                  <div className="road_map"
                    onClick={ClickOpenPopup}
                    data-year="2003"
                  >
                    <div className="circul2 d-flex align-items-center justify-content-center">
                    2019
                    </div>
                  </div>
                  <div className="road_map"
                    onClick={ClickOpenPopup}
                    data-year="2008"
                  >
                    <div className="circul3 d-flex align-items-center justify-content-center">
                    2020
                    </div>
                  </div>
                  <div className="road_map"
                    onClick={ClickOpenPopup}
                    data-year="2012"
                  >
                    <div className="circul4 d-flex align-items-center justify-content-center">
                    2021
                    </div>
                  </div>
                  <div className="road_map"
                    onClick={ClickOpenPopup}
                    data-year="2017"
                  >
                    <div className="circul5 d-flex align-items-center justify-content-center">
                    2022
                    </div>
                  </div>
                  <div className="road_map"
                    onClick={ClickOpenPopup}
                    data-year="2021"
                  >
                    <div className="circul6 d-flex align-items-center justify-content-center">
                    2023
                    </div>
                  </div>
                  <img src="/assets/img/road.png" alt="" width="100%" />
                </div>

                <div
                  className="col-lg-3 mt-m-3 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <center>
                    <img
                      width="65%"
                      src="/assets/img/md6/md-60chap-1-3.png"
                      alt=""
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-06/chapter-01-screen-05" />

                <NextBtn
                  link="/module-06/chapter-01-screen-07"
                  text={t('CommonBtnGoNext')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Women are physically weak :</Modal.Title> */}
        </Modal.Header>

        <Modal.Body>
          <p>{showModalText}</p>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Chapter03Screen03;
