import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter01Screen01 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="4" star="50" />
					</div>
				</div>

				<div className="d-flex">
				<Sidebar />
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-center pt-20 mt-4 mt-m-3">
							<div className="col-lg-9">
								<h1
									className="what-gender wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
									style={{ fontSize: "6vw", lineHeight: "7vw" }}
								>
									{" "}
									{t('M6chapter01screen01')}
								</h1>
								<PrevBtn link="/module-06/module-06-intro-screen" />
								<NextBtn link="/module-06/chapter-01-screen-02" text={t('Letstart')} classType="mt-5 mt-m-3 wow fadeInDown" />
							</div>
							<div
								className="col-lg-3 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="73%"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen01;
