import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen05 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/5" percentage="25" star="25" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3 mt-m-3">
								<div
									className="col-lg-7 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h3 mb-1">{t('M4chapter02screen05Text1')}</h2>
									<ul className="list-style-set list-circle mt-3">
										<li>{t('M4chapter02screen05Text2')}</li>
										<li>{t('M4chapter02screen05Text3')}</li>
										<li>{t('M4chapter02screen05Text4')}</li>
										<li>
											{t('M4chapter02screen05Text5')}
											
										</li>
										<li>{t('M4chapter02screen05Text6')}</li>
										<li>
											{t('M4chapter02screen05Text7')}
											{" "}
										</li>
									</ul>
								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="93%"
											draggable="false"
											src="/assets/img/md4/md4-shap-2-5.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-02-screen-04" />

									<NextBtn link="/module-04/chapter-02-screen-06" text={t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen05;
