import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";
const Chapter03Screen03 = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="wrapper bg-bd-2">
        <div
          className="fixed-header"
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="0/4" percentage="15" star="50" />
          </div>
        </div>
        <div className="d-flex">
				<Sidebar />
        <div className="data-adjust">
          <div className="container-fluid cf-set">
          <div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-3">

            <div className="col-lg-12 mt-3">

            <a href="https://inbreakthrough.org/our-story/" target="_blank">
              <div className="d-flex align-items-center mange-icon mt-2">
                {/* <div>
                  <img src="/assets/img/chapter2/video-icon.png" alt="" />
                </div> */}
                <div>
                  <p>
                  {t('M6hapter01screen04Text1')}
                  </p>
                </div>
              </div>
            </a>

            <p>  {t('M6hapter01screen04Text2')}</p>
            <p>  {t('M6hapter01screen04Text3')}</p>
            {/* <p>  {t('M6hapter01screen04Text4')}</p> */}
            {/* <p>  {t('M6hapter01screen04Text5')}</p> */}
            {/* <p>  {t('M6hapter01screen04Text6')}</p> */}


            </div>

          </div>





          </div>
        </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-06/chapter-01-screen-03"/>
                <NextBtn link="/module-06/chapter-01-screen-05" text={t('CommonBtnGoNext')}/>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen03;
