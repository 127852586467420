import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen07 = () => {
	const { t, i18n } = useTranslation();

	return (

		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="10" star="10" />
					</div>
				</div>

				<div className="d-flex">
					<Sidebar />

					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-4 mt-m-3">
								<div
									className="col-lg-7 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h2 mb-1">{t('M1chapter01screen07Title')} </h2>

									<a href="https://www.youtube.com/watch?v=UHwVyplU3Pg" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M1chapter01screen07Text1')}</p>
											</div>
										</div>
									</a>

									<a href="https://wordart.com/create" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p> {t('M1chapter01screen07Text2')}</p>
											</div>
										</div>
									</a>

									<a href="https://www.youtube.com/watch?v=Q84PtXfpANI" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M1chapter01screen07Text3')}</p>
											</div>
										</div>
									</a>

								</div>
								<div
									className="col-lg-4 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img width="60%" src="/assets/img/md2/md2-shap-1-7.png" alt="" />
									</center>
								</div>
							</div>
						</div>
					</div>

				</div>

			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-01-screen-06n" />

								<NextBtn link="/module-01/chapter-02-screen-01" text={t('CommonBtnGoNext')} />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	);
};

export default Chapter01Screen07;
