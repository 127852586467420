import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen23 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [checkedQues10Option1, setCheckedQues10Option1] = useState('');
	const [checkedQues10Option2, setCheckedQues10Option2] = useState('');
	const [checkedQues10Option3, setCheckedQues10Option3] = useState('');
	const [checkedQues10Option4, setCheckedQues10Option4] = useState('');
	const [checkedQues10Val, setCheckedQues10Val] = useState('');

	const [checkedQues11Option1, setCheckedQues11Option1] = useState('');
	const [checkedQues11Option2, setCheckedQues11Option2] = useState('');
	const [checkedQues11Option3, setCheckedQues11Option3] = useState('');
	const [checkedQues11Option4, setCheckedQues11Option4] = useState('');
	const [checkedQues11Val, setCheckedQues11Val] = useState('');






	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showModalText, setShowModalText] = useState();
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		if (quesId === '10') {
			setShowModalText(t('M3chapter03Screen23Text8'))
		}


		if (quesId === '11') {
			setShowModalText(t('M3chapter03Screen23Text9'))
		}



		setShow(true);
		checkedVal(quesId, e.target.value)

	}


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques10": {
					"ans": checkedQues10Val,
					"ques_id": "10"
				},
				"ques11": {
					"ans": checkedQues11Val,
					"ques_id": "11"
				},



			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-03/chapter-03-screen-25')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans) => {
		if (param === '10') {

			if (ans === 'option1') {
				setCheckedQues10Option1('checked')
				setCheckedQues10Option2('')
				setCheckedQues10Option3('')
				setCheckedQues10Option4('')
			} else if (ans === 'option2') {
				setCheckedQues10Option1('')
				setCheckedQues10Option2('checked')
				setCheckedQues10Option3('')
				setCheckedQues10Option4('')
			} else if (ans === 'option3') {
				setCheckedQues10Option1('')
				setCheckedQues10Option2('')
				setCheckedQues10Option3('checked')
				setCheckedQues10Option4('')
			} else if (ans === 'option4') {
				setCheckedQues10Option1('')
				setCheckedQues10Option2('')
				setCheckedQues10Option3('')
				setCheckedQues10Option4('checked')
			}

			setCheckedQues10Val(ans)
		}

		if (param === '11') {

			if (ans === 'option1') {
				setCheckedQues11Option1('checked')
				setCheckedQues11Option2('')
				setCheckedQues11Option3('')
				setCheckedQues11Option4('')
			} else if (ans === 'option2') {
				setCheckedQues11Option1('')
				setCheckedQues11Option2('checked')
				setCheckedQues11Option3('')
				setCheckedQues11Option4('')
			} else if (ans === 'option3') {
				setCheckedQues11Option1('')
				setCheckedQues11Option2('')
				setCheckedQues11Option3('checked')
				setCheckedQues11Option4('')
			} else if (ans === 'option4') {
				setCheckedQues11Option1('')
				setCheckedQues11Option2('')
				setCheckedQues11Option3('')
				setCheckedQues11Option4('checked')
			}

			setCheckedQues11Val(ans)
		}





	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues10Val !== '' && checkedQues11Val !== ''
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-2">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									{/* Yahan Par Popup Rahega */}

									<p className="mb-2 mt-3 pn"><span><b>5.</b></span> <b> {t('M3chapter03Screen23Text1')} </b> </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle3" id="mycirle7"
												onClick={saveRadio}
												defaultValue="option1"
												data-quesId="10"
												checked={checkedQues10Option1}
											/>
											<label className="form-check-label" htmlFor="mycirle7">{t('M3chapter03Screen23Text2')}  </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle3" id="mycirle8"
												onClick={saveRadio}
												defaultValue="option2"
												data-quesId="10"
												checked={checkedQues10Option2}

											/>
											<label className="form-check-label" htmlFor="mycirle8">{t('M3chapter03Screen23Text3')}  </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle3"
												id="mycirle9"
												onClick={saveRadio}
												defaultValue="option3"
												data-quesId="10"
												checked={checkedQues10Option3}
											/>
											<label className="form-check-label" htmlFor="mycirle9">{t('M3chapter03Screen23Text4')}</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check"
												type="radio" name="namecircle3"
												id="mycirle10"
												onClick={saveRadio}
												defaultValue="option4"
												data-quesId="10"
												checked={checkedQues10Option4}

											/>
											<label className="form-check-label" htmlFor="mycirle10">{t('M3chapter03Screen23Text5')} </label>
										</div>
									</div>




									<p className="mb-2 mt-3 pn"><span><b>6.</b></span> <b> {t('M3chapter03Screen23Text6')} </b> </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle4" id="mycirle1"
												onClick={saveRadio}
												defaultValue="option1"
												data-quesId="11"
												checked={checkedQues11Option1}

											/>
											<label className="form-check-label" htmlFor="mycirle1">	{t('Safe')}</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle4" id="mycirle1"
												onClick={saveRadio}
												defaultValue="option2"
												data-quesId="11"
												checked={checkedQues11Option2}
											/>
											<label className="form-check-label" htmlFor="mycirle2">{t('Comfortable')}</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle4" id="mycirle1"
												onClick={saveRadio}
												defaultValue="option3"
												data-quesId="11"
												checked={checkedQues11Option3}

											/>
											<label className="form-check-label" htmlFor="mycirle3">	{t('Confident')} </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle4" id="mycirle1"
												onClick={saveRadio}
												defaultValue="option4"
												data-quesId="11"
												checked={checkedQues11Option4}

											/>
											<label className="form-check-label" htmlFor="mycirle4"> {t('M3chapter03Screen23Text7')} </label>
										</div>
									</div>


								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="42%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-2-15.png"
											alt=""
										/>
									</center>
								</div>




							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-03-screen-22" />
								{/* <NextBtn link="/module-03/chapter-03-screen-24" text="Go Next"/> */}
								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					{/* <Modal.Title>Women are physically weak :</Modal.Title> */}
				</Modal.Header>
				<Modal.Body>
					<p>{showModalText}</p>
				</Modal.Body>
			</Modal>



		</>
	);
};

export default Chapter03Screen23;
