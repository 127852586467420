import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL, { AdminUrl } from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';


const Chapter02Screen04N2 = () => {

  const { t, i18n } = useTranslation();
const navigate = useNavigate();
const [checkedQues20_1,setCheckedQues20_1] = useState('')
const [checkedQues20_2,setCheckedQues20_2] = useState('')
const [checkedQues20_3,setCheckedQues20_3] = useState('')
const [checkedQues20_4,setCheckedQues20_4] = useState('')
const [checkedQues20_5,setCheckedQues20_5] = useState('')
const [checkedQues20_6,setCheckedQues20_6] = useState('')
const [checkedQues20_7,setCheckedQues20_7] = useState('')
const [checkedQues20_8,setCheckedQues20_8] = useState('')
const [checkedQues20_9,setCheckedQues20_9] = useState('')
const [checkedQues20_10,setCheckedQues20_10] = useState('')
const [checkedQues20_11,setCheckedQues20_11] = useState('')
const [checkedQues20_12,setCheckedQues20_12] = useState('')

const [resul,setResults] = useState();

const user_details = JSON.parse(localStorage.getItem('user_details'));

const allinput_data = {
  "name": checkedQues20_1,
  "tv": checkedQues20_2,
  "mdayatcollege": checkedQues20_3,
  "TVdayatcollege": checkedQues20_4,
  "Myfriends": checkedQues20_5,
  "TVfriends": checkedQues20_6,
  "Myparents": checkedQues20_7,
  "TVparents": checkedQues20_8,
  "Myrelationships": checkedQues20_9,
  "Tvrelationships": checkedQues20_10,
  "Mysexualexperience": checkedQues20_11,
  "TVsexualexperience": checkedQues20_12
}




  const saveAns = () => {

    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques20": {
          "ans": JSON.stringify(allinput_data),
          "ques_id": "20"
        },




      }
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SexualityChapter02',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {

        if (response.status === "Token is Expired") {
          window.localStorage.clear();
          navigate('/login')
        }
        if (response.data.message === "Token Invalid") {
          window.localStorage.clear();
          navigate('/login')
        }

        if (response.data.success === true) {
          navigate('/module-03/chapter-02-screen-04n3')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": "20"
    });
    var config = {
      method: 'post',
      url: BaseURL + '/sexualityChapter02Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {


          const results = JSON.parse(response.data.data.chapter.ans);

          setCheckedQues20_1(results.name);
          setCheckedQues20_2(results.tv);
          setCheckedQues20_3(results.mdayatcollege);
          setCheckedQues20_4(results.TVdayatcollege);
          setCheckedQues20_5(results.Myfriends);
          setCheckedQues20_6(results.TVfriends);
          setCheckedQues20_7(results.Myparents);
          setCheckedQues20_8(results.TVparents);
          setCheckedQues20_9(results.Myrelationships);
          setCheckedQues20_10(results.Tvrelationships);
          setCheckedQues20_11(results.Mysexualexperience);
          setCheckedQues20_12(results.TVsexualexperience);



          // })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  const BtnDisabled = () => {
    return (
      <>
        <button
          className={`button_su`}
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <span className="su_button_circle" />
          <button onClick={saveAns} className="button_su_inner">
            <span className="button_text_container">
                          {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
            </span>
          </button>
        </button>
      </>
    );
  };



  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
            <Header leftChapter="1/4" percentage="0" star="50" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-between pt-20 mt-2">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >

                  <h3 className="h3 mt-3">{t('engage')}  </h3>
                  <p className="mb-2  mt-3">{t('M3chapter02Screen04N2Text1')}  </p>

                  <table className="table table-bordered table-dark table-red table-padding">
                    <tbody>
                      <tr className="color-dark-red">
                        <td>{t('M3chapter02Screen04N2Text2')}:
                          <input type="text"
                            onChange={(e) => { setCheckedQues20_1(e.target.value) }}
                            data-quesId="20"
                            defaultValue={checkedQues20_1}
                            placeholder className="input-without-style form-control" />
                        </td>

                        <td>{t('M3chapter02Screen04N2Text3')}:
                          <input type="text"
                            onChange={(e) => { setCheckedQues20_2(e.target.value) }}
                            data-quesId="20"
                            defaultValue={checkedQues20_2}
                            placeholder className="input-without-style form-control" />
                        </td>

                      </tr>
                      <tr className="color-dark-red">
                        <td >{t('M3chapter02Screen04N2Text4')}
                          <input type="text" onChange={(e) => { setCheckedQues20_3(e.target.value) }}
                            data-quesId="20"
                            defaultValue={checkedQues20_3} placeholder className="input-without-style form-control" />
                        </td>
                        <td>{t('M3chapter02Screen04N2Text5')}
                        <input type="text" onChange={(e) => { setCheckedQues20_4(e.target.value) }}
                            data-quesId="20"
                            defaultValue={checkedQues20_4} placeholder className="input-without-style form-control" />
                        </td>
                      </tr>

                      <tr className="color-dark-red">
                        <td >{t('M3chapter02Screen04N2Text6')}
                          <input type="text" onChange={(e) => { setCheckedQues20_5(e.target.value) }}
                            data-quesId="20"
                            defaultValue={checkedQues20_5} placeholder className="input-without-style form-control" />
                        </td>
                        <td>{t('M3chapter02Screen04N2Text7')}
                        <input type="text" onChange={(e) => { setCheckedQues20_6(e.target.value) }}
                            data-quesId="20"
                            defaultValue={checkedQues20_6} placeholder className="input-without-style form-control" />
                        </td>
                      </tr>

                      <tr className="color-dark-red">
                        <td>{t('M3chapter02Screen04N2Text8')}
                          <input type="text" onChange={(e) => { setCheckedQues20_7(e.target.value) }}
                            data-quesId="20"
                            defaultValue={checkedQues20_7} placeholder className="input-without-style form-control" />
                        </td>
                        <td>{t('M3chapter02Screen04N2Text9')}
                          <input type="text" onChange={(e) => { setCheckedQues20_8(e.target.value) }}
                            data-quesId="20"
                            defaultValue={checkedQues20_8} placeholder className="input-without-style form-control" />
                        </td>
                      </tr>

                      <tr className="color-dark-red">
                        <td>{t('M3chapter02Screen04N2Text10')}

                          <input type="text" onChange={(e) => { setCheckedQues20_9(e.target.value) }}
                            data-quesId="20"
                            defaultValue={checkedQues20_9} placeholder className="input-without-style form-control" />
                        </td>
                        <td>{t('M3chapter02Screen04N2Text11')}
                          <input type="text" onChange={(e) => { setCheckedQues20_10(e.target.value) }}
                            data-quesId="20"
                            defaultValue={checkedQues20_10} placeholder className="input-without-style form-control" />
                        </td>
                      </tr>
                      <tr className="color-dark-red">
                        <td>{t('M3chapter02Screen04N2Text12')}
                          <input type="text" onChange={(e) => { setCheckedQues20_11(e.target.value) }}
                            data-quesId="20"
                            defaultValue={checkedQues20_11} placeholder className="input-without-style form-control" />
                        </td>
                        <td>{t('M3chapter02Screen04N2Text13')}
                          <input type="text" onChange={(e) => { setCheckedQues20_12(e.target.value) }}
                            data-quesId="20"
                            defaultValue={checkedQues20_12} placeholder className="input-without-style form-control" />
                        </td>
                      </tr>


                    </tbody>
                  </table>




                  {/* <div className="box">
                  <form>
                    <input id="demo1" className="demo1"  type="file" onChange={onImageChange}  placeholder="Select Files" name="demo1[]" />
                  </form>
                </div> */}

                  {/* <div class="file-input">
                        <input type="file" name="file-input" id="file-input" class="file-input__input" onChange={onImageChange} />
                        <label class="file-input__label" for="file-input">
                          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" class="svg-inline--fa fa-upload fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
                          </svg>
                          <span>Upload file</span></label>
                          <a href={AdminUrl+"/public/mascul/"+imageUrl} target="__blank">{imageUrl}</a>
                      </div> */}





                </div>




              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">

                <PrevBtn link="/module-03/chapter-02-screen-04n1" />
                {/* <NextBtn link="/module-03/chapter-02-screen-04n3" text={t('CommonBtnGoNext')} /> */}
                <BtnDisabled />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen04N2;
