import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';


const Chapter03Screen04 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [checkedQues5Hegemonic, setCheckedQues5Hegemonic] = useState('');
	const [checkedQues5Complicit, setCheckedQues5Complicit] = useState('');
	const [checkedQues5Subordinate, setCheckedQues5Subordinate] = useState('');
	const [checkedQues5Marginalised, setCheckedQues5Marginalised] = useState('');
	const [checkedQues5Inclusive, setCheckedQues5Inclusive] = useState('');
	const [checkedQues5Val, setCheckedQues5Val] = useState('');

	const [checkedQues6Hegemonic, setCheckedQues6Hegemonic] = useState('');
	const [checkedQues6Complicit, setCheckedQues6Complicit] = useState('');
	const [checkedQues6Subordinate, setCheckedQues6Subordinate] = useState('');
	const [checkedQues6Marginalised, setCheckedQues6Marginalised] = useState('');
	const [checkedQues6Inclusive, setCheckedQues6Inclusive] = useState('');
	const [checkedQues6Val, setCheckedQues6Val] = useState('');






	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		checkedVal(quesId, e.target.value)
	}

	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": checkedQues5Val,
					"ques_id": "5"
				},
				"ques6": {
					"ans": checkedQues6Val,
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-04/chapter-03-screen-05')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues5Val !== '' && checkedQues6Val !== '') {
			return (
				<>
					<button className={`button_su`} data-wow-duration="1s" data-wow-delay="0.2s">
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {

		if (param === '5') {
			// alert(param);
			if (ans === 'Hegemonic') {
				setCheckedQues5Hegemonic('checked')
				setCheckedQues5Complicit('');
				setCheckedQues5Subordinate('')
				setCheckedQues5Marginalised('')
				setCheckedQues5Inclusive('')
			} else if (ans === 'Complicit') {
				setCheckedQues5Hegemonic('')
				setCheckedQues5Complicit('checked');
				setCheckedQues5Subordinate('')
				setCheckedQues5Marginalised('')
				setCheckedQues5Inclusive('')
			} else if (ans === 'Subordinate') {
				setCheckedQues5Hegemonic('')
				setCheckedQues5Complicit('');
				setCheckedQues5Subordinate('checked')
				setCheckedQues5Marginalised('')
				setCheckedQues5Inclusive('')
			} else if (ans === 'Marginalised') {
				setCheckedQues5Hegemonic('')
				setCheckedQues5Complicit('');
				setCheckedQues5Subordinate('')
				setCheckedQues5Marginalised('checked')
				setCheckedQues5Inclusive('')
			} else if (ans === 'Inclusive and gender equitable') {
				setCheckedQues5Hegemonic('')
				setCheckedQues5Complicit('');
				setCheckedQues5Subordinate('')
				setCheckedQues5Marginalised('')
				setCheckedQues5Inclusive('checked')
			}
			setCheckedQues5Val(ans)
		}

		if (param === '6') {
			// alert(param);
			if (ans === 'Hegemonic') {
				setCheckedQues6Hegemonic('checked')
				setCheckedQues6Complicit('');
				setCheckedQues6Subordinate('')
				setCheckedQues6Marginalised('')
				setCheckedQues6Inclusive('')
			} else if (ans === 'Complicit') {
				setCheckedQues6Hegemonic('')
				setCheckedQues6Complicit('checked');
				setCheckedQues6Subordinate('')
				setCheckedQues6Marginalised('')
				setCheckedQues6Inclusive('')
			} else if (ans === 'Subordinate') {
				setCheckedQues6Hegemonic('')
				setCheckedQues6Complicit('');
				setCheckedQues6Subordinate('checked')
				setCheckedQues6Marginalised('')
				setCheckedQues6Inclusive('')
			} else if (ans === 'Marginalised') {
				setCheckedQues6Hegemonic('')
				setCheckedQues6Complicit('');
				setCheckedQues6Subordinate('')
				setCheckedQues6Marginalised('checked')
				setCheckedQues6Inclusive('')
			} else if (ans === 'Inclusive and gender equitable') {
				setCheckedQues6Hegemonic('')
				setCheckedQues6Complicit('');
				setCheckedQues6Subordinate('')
				setCheckedQues6Marginalised('')
				setCheckedQues6Inclusive('checked')
			}
			setCheckedQues6Val(ans)
		}








	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="2/5" percentage="56" star="56" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mt-3">
										<b>
											{t('M4chapter03screen04Text1')}
											{" "}
										</b>{" "}
									</p>
								</div>
								<div className="row align-items-center justify-content-between">
									<div
										className="col-lg-4 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<div className="d-flex ">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle"
													id="mycirle"
													defaultValue="Hegemonic"
													onClick={saveRadio}
													checked={checkedQues5Hegemonic}
													data-quesId="5"
												/>
												<label className="form-check-label" htmlFor="mycirle">
													{" "}
													{t('Hegemonic')}{" "}
												</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle"
													id="mycirle2"
													defaultValue="Complicit"
													onClick={saveRadio}
													checked={checkedQues5Complicit}
													data-quesId="5"
												/>
												<label className="form-check-label" htmlFor="mycirle2">
												{t('Complicit')}
												</label>
											</div>
										</div>
										{/* <div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle"
													id="mycirle3"
													defaultValue="Subordinate"
													onClick={saveRadio}
													checked={checkedQues5Subordinate}
													data-quesId="5"
												/>
												<label className="form-check-label" htmlFor="mycirle3">
													{" "}
													{t('Subordinate')}
												</label>
											</div>
										</div> */}
										<div className="d-flex ">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle"
													id="mycirle4"
													defaultValue="Marginalised"
													onClick={saveRadio}
													checked={checkedQues5Marginalised}
													data-quesId="5"
												/>
												<label className="form-check-label" htmlFor="mycirle4">
													{" "}
													{t('Marginalised')}
												</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle"
													id="mycirle5"
													defaultValue="Inclusive and gender equitable"
													onClick={saveRadio}
													checked={checkedQues5Inclusive}
													data-quesId="5"
												/>
												<label className="form-check-label" htmlFor="mycirle5">
												{t('M4chapter02screen13Text2')}
												</label>
											</div>
										</div>
									</div>
									<div
										className="col-lg-4 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<center>
											<img
												width="100%"
												draggable="false"
												src="/assets/img/md4/hum-sath-sath-han.png"
												alt=""
											/>
										</center>
									</div>
								</div>
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mt-3">
										<b>
											{t('M4chapter03screen04Text2')}
											{" "}
										</b>{" "}
									</p>
								</div>
								<div className="row align-items-center justify-content-between">
									<div
										className="col-lg-4 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle2"
													id="mycirle6"
													defaultValue="Hegemonic"
													onClick={saveRadio}
													checked={checkedQues6Hegemonic}
													data-quesId="6"
												/>
												<label className="form-check-label" htmlFor="mycirle6">
													{" "}
													{t('Hegemonic')}{" "}
												</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle2"
													id="mycirle7"
													defaultValue="Complicit"
													onClick={saveRadio}
													checked={checkedQues6Complicit}
													data-quesId="6"
												/>
												<label className="form-check-label" htmlFor="mycirle7">
												{t('Complicit')}{" "}
												</label>
											</div>
										</div>
										{/* <div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle2"
													id="mycirle8"
													defaultValue="Subordinate"
													onClick={saveRadio}
													checked={checkedQues6Subordinate}
													data-quesId="6"
												/>
												<label className="form-check-label" htmlFor="mycirle8">
												{t('Subordinate')}
												</label>
											</div>
										</div> */}
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle2"
													id="mycirle9"
													defaultValue="Marginalised"
													onClick={saveRadio}
													checked={checkedQues6Marginalised}
													data-quesId="6"
												/>
												<label className="form-check-label" htmlFor="mycirle9">
												{t('Marginalised')}
												</label>
											</div>
										</div>
										<div className="d-flex ">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle2"
													id="mycirle10"
													defaultValue="Inclusive and gender equitable"
													onClick={saveRadio}
													checked={checkedQues6Inclusive}
													data-quesId="6"
												/>
												<label className="form-check-label" htmlFor="mycirle10">
												{t('M4chapter02screen13Text2')} {" "}
												</label>
											</div>
										</div>
									</div>
									<div
										className="col-lg-4 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<center>
											<img
												width="100%"
												draggable="false"
												src="/assets/img/md4/zindagi-na-milegi-duwara.png"
												alt=""
											/>
										</center>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-03-screen-03" />

									{/* <NextBtn link="/module-04/chapter-03-screen-05" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen04;
