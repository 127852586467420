import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { saveViewPages } from '../../../../api/api';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Module06IntroScreen = () => {

	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const navigate = useNavigate();

	const { t, i18n } = useTranslation();

	const handleSavePage = (event) => {


		try {
			const key_name = event.target.getAttribute("data-key_name");
			const key_url = event.target.getAttribute("data-key_url");

			const newUser = { user_id: user_details.user_id, key_name: key_name, url: key_url };
			const createdUser = saveViewPages(newUser);
			navigate('/module-06/chapter-01-screen-01');
		} catch (error) {
			console.error("Error creating user:", error);
		}
	};

	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="0" star="0" />
					</div>
				</div>

				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between pt-20 mt-4">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									{/* <h4>
										{" "}
										<b>{t('M6chapter01introscreenTitle')}</b>
									</h4> */}
									<div className="para-color-box mt-4">
										<p>
											<b>
												{t('M6chapter01introscreenText1')}
											</b>
										</p>
										<ul className="list-style-set list-circle mt-1">
											<li>{t('M6chapter01introscreenText2')} </li>
											{/* <li>{t('M6chapter01introscreenText2')} </li> */}
											<li>{t('M6chapter01introscreenText3')}</li>
											<li>{t('M6chapter01introscreenText4')}</li>
											<li>{t('M6chapter01introscreenText5')}</li>
										</ul>
									</div>
								</div>
								<div
									className="col-lg-3 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="60%"
											draggable="false"
											src="/assets/img/chapter1/reading-book.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
					<div
						className="button-fixed wow fadeInDown"
						data-wow-duration="1s"
						data-wow-delay="0.3s"
					>
						<div className="container-fluid cf-set">
							<div className="row">
								<div className="col-lg-12">
									<div className=" d-flex justify-content-end">
										<PrevBtn link="/module-03/score" />

										{/* <NextBtn link="/module-06/chapter-01-screen-01" text="Go Next" /> */}

										{/* <button className="button_su_inner" onClick={handleSavePage} data-key_name="M6C1" data-key_url="/module-06/module-06-intro-screen" >{t('CommonBtnGoNext')}</button> */}


										<button
									className={`button_su`}
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<span className="su_button_circle" />
									<button className="button_su_inner" onClick={handleSavePage} data-key_name="M6C1" data-key_url="/module-06/module-06-intro-screen">
										<span className="button_text_container">
											{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
										</span>
									</button>
								</button>


									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Module06IntroScreen;
