import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen05 = () => {

	const navigate = useNavigate();
	const [checkedQues12, setCheckedQues12] = useState('');
	const [checkedQues13, setCheckedQues13] = useState('');
	const [checkedQues14, setCheckedQues14] = useState('');
	// const [checkedQues7, setCheckedQues7] = useState('');
	// const [checkedQues8, setCheckedQues8] = useState('');
	// const [checkedQues3, setCheckedQues3] = useState('');


	const { t, i18n } = useTranslation();


	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {

				"ques12": {
					"ans": checkedQues12,

					"ques_id": "12"
				},
				"ques13": {
					"ans": checkedQues13,

					"ques_id": "13"
				},
				"ques14": {
					"ans": checkedQues14,
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-04-screen-06')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					results.map((result) => {
						console.log(result);
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);
	const checkedVal = (param, ans) => {

		if (param === '12') {
			setCheckedQues12(ans)

		}

		if (param === '13') {
			setCheckedQues13(ans)

		}

		if (param === '14') {
			setCheckedQues14(ans)

		}



	}
	const BtnDisabled = () => {
		if (checkedQues12 !== '' && checkedQues13 !== '' && checkedQues14 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">



								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};





	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="36" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">

							<div className="row align-items-top justify-content-between pt-20 mt-4">

								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>


									<p className=" mt-3"> <b>{t('M5chapter04Screen5nText1')}</b></p>
									<a
										href="https://www.youtube.com/watch?v=VkIuvHnVqzw&list=PLbyiiPziC3QruFIevDyTJdEVWtn5K7hoQ&index=3"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M5chapter04Screen5nText2')}</p>
											</div>
										</div>
									</a>



									<a
										href="https://www.youtube.com/watch?v=DRIRqy4Covs"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M5chapter04Screen5nText3')}</p>
											</div>
										</div>
									</a>



									<p className=""><b>{t('M5chapter04Screen5nText4')}</b></p>
									<div className="mt-1 positon-submit">
										<textarea className="form-control" rows={1} placeholder="Type your Answer......."
											defaultValue={checkedQues12}
											onChange={(e) => setCheckedQues12(e.target.value)}
										/>
									</div>

									<p className=" mt-1"><b>{t('M5chapter04Screen5nText5')}</b></p>
									<div className="mt-1 positon-submit">
										<textarea className="form-control fc-height" rows={1} placeholder="Type your Answer......."
											defaultValue={checkedQues13}
											onChange={(e) => setCheckedQues13(e.target.value)}
										/>
									</div>
									<p className=" mt-1"><b>{t('M5chapter04Screen5nText6')}</b></p>
									<div className="mt-1 positon-submit">
										<textarea className="form-control fc-height" rows={1} placeholder="Type your Answer......."

											defaultValue={checkedQues14}
											onChange={(e) => setCheckedQues14(e.target.value)}
										/>
									</div>









								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											className="mt-0"
											width="70%"
											src="/assets/img/md3/md3-shap-4-04.png"
											alt=""
										/>
									</center>
								</div>

							</div>


						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-05/chapter-04-screen-05" />

									{/* <NextBtn link="/module-05/chapter-04-screen-06" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen05;
