import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter02Screen07 = () => {
	const navigate = useNavigate();
	const [checkedQues3, setCheckedQues3] = useState([]);
	const [checkedQues3Option1, setCheckedQues3Option1] = useState('');
	const [checkedQues3Option2, setCheckedQues3Option2] = useState('');
	const [checkedQues3Option3, setCheckedQues3Option3] = useState('');
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const { t, i18n } = useTranslation();
	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;
		checkBoxChecked(res, checked, quesId);
		if (quesId === '3') {
			setCheckedQues3(checkedQues3 => [...checkedQues3, res]);
		}
	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3.join(','),
					"ques_id": "3"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/ApproachFinalQuiz',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.success === true) {
					navigate('/module-06/chapter-03-screen-01')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues3.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '3') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues3(ansArr)
		}
	}
	const checkBoxChecked = (val, checked, quesId) => {
		if (quesId === '3') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues3Option1('checked');
				} else {
					setCheckedQues3Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues3Option2('checked');
				} else {
					setCheckedQues3Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues3Option3('checked');
				} else {
					setCheckedQues3Option3('');
				}
			}
		}

	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/ApproachFinalQuizResult',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	return (
		<>
			<div className="wrapper bg-bd-2 hp">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="52" star="50" />
					</div>
				</div>

				<div className="d-flex">
					<Sidebar />

					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-2 mt-m-3">
								<div
									className="col-lg-7 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mb-2 mt-2 pn text-bold"> <span> <b>3.</b></span> <b> {t('M6hapter02screen07Text1')} </b> </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle4"
												onClick={saveGenderBased}
												defaultValue="option1"
												checked={checkedQues3Option1}
												data-quesId="3"
											/>
											<label className="form-check-label" htmlFor="mycirle4">{t('M6hapter02screen07Text2')}</label></div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle5"
												onClick={saveGenderBased}
												defaultValue="option2"
												checked={checkedQues3Option2}
												data-quesId="3"

											/>
											<label className="form-check-label" htmlFor="mycirle5"> {t('M6hapter02screen07Text3')}  </label></div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle6"
												onClick={saveGenderBased}
												defaultValue="option3"
												checked={checkedQues3Option3}
												data-quesId="3"
											/>
											<label className="form-check-label" htmlFor="mycirle6"> {t('M6hapter02screen07Text4')}</label></div>
									</div>



									<p><b>{t('M6hapter02screen07Text5')}.</b></p>
								</div>

								<div className="col-lg-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">
									<center>
										<img width="100%" draggable="false" src="/assets/img/md6/md-60chap-2-7.png" alt="" />
									</center>
								</div>




							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-06/chapter-02-screen-06" />
								{/* <NextBtn link="/" text="Go Next"/> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);


};
export default Chapter02Screen07;
