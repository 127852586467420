import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter02Screen02 = () => {
	const navigate = useNavigate();
	const [checkedQues7, setCheckedQues7] = useState('');
	const [checkedQues7_1, setCheckedQues7_1] = useState('');
	const { t, i18n } = useTranslation();

	const user_details = JSON.parse(localStorage.getItem('user_details'));




	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques7": {
					"ans": checkedQues7,
					"ans2": checkedQues7_1,
					"ques_id": "7"
				},




			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/ApproachChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-06/chapter-04-screen-01')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans, ans2) => {


		if (param === '7') {

			setCheckedQues7(ans)
			setCheckedQues7_1(ans2)
		}






	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/approachChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans, result.ans2)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues7 !== '' && checkedQues7_1 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="70" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-4 mt-m-3">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<h3 className="h3"> {t('reflectandapply')} </h3>
									<p className="mb-2 mt-3"> <b>{t('M6chapter03screen07Text1')}</b> </p>
									<div className="mb-0 mt-2">
										<textarea className="form-control" rows="2" defaultValue={checkedQues7} onChange={(e) => setCheckedQues7(e.target.value)} placeholder=".."></textarea>
									</div>
									<div className="mt-3">
										<textarea className="form-control" rows="2" defaultValue={checkedQues7_1} onChange={(e) => setCheckedQues7_1(e.target.value)} placeholder=".."></textarea>
									</div>



									{/* <p className="mb-2 mt-3">
										<b>
											{t('M6chapter03screen07Text2')}
											<a className="display-inline" href="https://docs.google.com/viewerng/viewer?url=https://inbreakthrough.org/wp-content/uploads/2018/11/jpal-1.pdf" target="_blank"> {t('M6chapter03screen07Text3')}.</a>
										</b>
									</p> */}
									<p className="mb-2 mt-3"><b>{t('M6chapter03screen07Text4')}</b> {t('M6chapter03screen07Text5')}</p>

									<a href="https://www.youtube.com/watch?v=HFPjypCj7MM" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M6chapter03screen07Text6')}</p>
											</div>
										</div>
									</a>




								</div>

								<div className="col-lg-3 mt-m-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">
									<center>
										<img
											width="50%"
											src="/assets/img/md6/md-60chap-2-3.png"
											alt=""
										/>
									</center>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-06/chapter-03-screen-06" />
								{/* <NextBtn link="/module-06/chapter-02-screen-03" text="Go Next"/> */}

								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
