import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen02 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="60" star="60" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-5">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3 mb-4 text-ledr">
										{t('M4chapter04screen02Text1')}
										
									</h3>
									<a
										href="https://www.youtube.com/watch?v=aOLYIzJnKT4"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>
												{t('M4chapter04screen02Text2')} <br />{" "}
													<b>
													{t('M4chapter04screen02Text3')}
													</b>
												</p>
											</div>
										</div>
									</a>
									<a
										href="https://en.wikipedia.org/wiki/Kamla_Bhasin"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M4chapter04screen02Text4')} </p>
											</div>
										</div>
									</a>
									{/* <a href="" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
			<div class="d-flex align-items-center mange-icon">
				<div>
				<img src="/assets/img/chapter2/video-icon.png" alt="">
				</div>
				<div>
				<p>Satyamev Jayate Season 3 | Episode 6 |  <br> <b>When Masculinity Harms Men | Love of power (Subtitled)</b></p>
				</div>
			</div>
			</a> */}
								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="90%"
											draggable="false"
											src="/assets/img/md4/md4-shap-4-2.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-04-screen-01" />

									<NextBtn link="/module-04/chapter-04-screen-03" text={t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
								{t('M4chapter04screen02Text5')}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen02;
