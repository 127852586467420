import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';


const Chapter03Screen06 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [checkedQues9Always, setCheckedQues9Always] = useState('');
  const [checkedQues9Often, setCheckedQues9Often] = useState('');
  const [checkedQues9Rarely, setCheckedQues9Rarely] = useState('');
  const [checkedQues9Never, setCheckedQues9Never] = useState('');
  const [checkedQues9Val, setCheckedQues9Val] = useState('');

  const [checkedQues10Always, setCheckedQues10Always] = useState('');
  const [checkedQues10Often, setCheckedQues10Often] = useState('');
  const [checkedQues10Rarely, setCheckedQues10Rarely] = useState('');
  const [checkedQues10Never, setCheckedQues10Never] = useState('');
  const [checkedQues10Val, setCheckedQues10Val] = useState('');

  const [checkedQues11Always, setCheckedQues11Always] = useState('');
  const [checkedQues11Often, setCheckedQues11Often] = useState('');
  const [checkedQues11Rarely, setCheckedQues11Rarely] = useState('');
  const [checkedQues11Never, setCheckedQues11Never] = useState('');
  const [checkedQues11Val, setCheckedQues11Val] = useState('');

  const [checkedQues12Always, setCheckedQues12Always] = useState('');
  const [checkedQues12Often, setCheckedQues12Often] = useState('');
  const [checkedQues12Rarely, setCheckedQues12Rarely] = useState('');
  const [checkedQues12Never, setCheckedQues12Never] = useState('');
  const [checkedQues12Val, setCheckedQues12Val] = useState('');

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    // alert(e.target.value);
    checkedVal(quesId,e.target.value)
  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": checkedQues9Val,
          "ques_id": "9"
        },
        "ques10": {
          "ans": checkedQues10Val,
          "ques_id": "10"
        },
        "ques11": {
          "ans": checkedQues11Val,
          "ques_id": "11"
        },
        "ques12": {
          "ans": checkedQues12Val,
          "ques_id": "12"
        },
        "ques13": {
          "ans": "",
          "ques_id": "13"
        },
        "ques14": {
          "ans": "",
          "ques_id": "14"
        }
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/SelfChapter03',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-01/chapter-03-screen-07')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const checkedVal = (param,ans) => {
    if (param === '9') {
      if (ans === 'Always') {
        setCheckedQues9Always('checked')
        setCheckedQues9Often('')
        setCheckedQues9Rarely('')
        setCheckedQues9Never('')
      } else if (ans === 'Often/sometimes') {
        setCheckedQues9Always('')
        setCheckedQues9Often('checked')
        setCheckedQues9Rarely('')
        setCheckedQues9Never('')
        }else if(ans === 'Rarely')
        {
          setCheckedQues9Always('')
          setCheckedQues9Often('')
          setCheckedQues9Rarely('checked')
          setCheckedQues9Never('')
        }else if(ans === 'Never')
        {
          setCheckedQues9Always('')
          setCheckedQues9Often('')
          setCheckedQues9Rarely('')
          setCheckedQues9Never('checked')
        }
        setCheckedQues9Val(ans)
    }
    if (param === '10') {
      if (ans === 'Always') {
        setCheckedQues10Always('checked')
        setCheckedQues10Often('')
        setCheckedQues10Rarely('')
        setCheckedQues10Never('')
      } else if (ans === 'Often/sometimes') {
        setCheckedQues10Always('')
        setCheckedQues10Often('checked')
        setCheckedQues10Rarely('')
        setCheckedQues10Never('')
        }else if(ans === 'Rarely')
        {
          setCheckedQues10Always('')
          setCheckedQues10Often('')
          setCheckedQues10Rarely('checked')
          setCheckedQues10Never('')
        }else if(ans === 'Never')
        {
          setCheckedQues10Always('')
          setCheckedQues10Often('')
          setCheckedQues10Rarely('')
          setCheckedQues10Never('checked')
        }
        setCheckedQues10Val(ans)
    }
    if (param === '11') {
      if (ans === 'Always') {
        setCheckedQues11Always('checked')
        setCheckedQues11Often('')
        setCheckedQues11Rarely('')
        setCheckedQues11Never('')
      } else if (ans === 'Often/sometimes') {
        setCheckedQues11Always('')
        setCheckedQues11Often('checked')
        setCheckedQues11Rarely('')
        setCheckedQues11Never('')
        }else if(ans === 'Rarely')
        {
          setCheckedQues11Always('')
          setCheckedQues11Often('')
          setCheckedQues11Rarely('checked')
          setCheckedQues11Never('')
        }else if(ans === 'Never')
        {
          setCheckedQues11Always('')
          setCheckedQues11Often('')
          setCheckedQues11Rarely('')
          setCheckedQues11Never('checked')
        }
        setCheckedQues11Val(ans)
    }

    if (param === '12') {
      if (ans === 'Always') {
        setCheckedQues12Always('checked')
        setCheckedQues12Often('')
        setCheckedQues12Rarely('')
        setCheckedQues12Never('')
      } else if (ans === 'Often/sometimes') {
        setCheckedQues12Always('')
        setCheckedQues12Often('checked')
        setCheckedQues12Rarely('')
        setCheckedQues12Never('')
        }else if(ans === 'Rarely')
        {
          setCheckedQues12Always('')
          setCheckedQues12Often('')
          setCheckedQues12Rarely('checked')
          setCheckedQues12Never('')
        }else if(ans === 'Never')
        {
          setCheckedQues12Always('')
          setCheckedQues12Often('')
          setCheckedQues12Rarely('')
          setCheckedQues12Never('checked')
        }
        setCheckedQues12Val(ans)
    }

  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfChapter03Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  const BtnDisabled = () => {
    if (checkedQues9Val !== '' && checkedQues10Val !== '' && checkedQues11Val !== '' && checkedQues12Val !== '' ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };

  return (
    <>
  <div className="wrapper bg-bd-2">
    <div
      className="fixed-header"

    >
      <div className="container-fluid cf-set">
      <Header leftChapter="2/4" percentage="38" star="38" />
      </div>
    </div>
    <div className="d-flex">
      <Sidebar />
      
      <div className="data-adjust">
        <div className="container-fluid cf-set">
          <div className="row align-items-top justify-content-between pt-20 mt-3">
            <div
              className="col-lg-7 wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <p className="mb-2 mt-3 pn"> <span>7.</span>
                {t('M1chapter03screen06Text1')}
              </p>
              <div className="d-flex">
                  <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="a"
                  id=""
                  defaultValue="Always"
                  onClick={saveRadio}
                  data-quesId="9"
                  checked={checkedQues9Always}
                />
                <label className="form-check-label" htmlFor="">
                {t('always')}
                </label>
                </div>
              </div>
              <div className="d-flex">
                  <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="a"
                  id=""
                  defaultValue="Often/sometimes"
                  onClick={saveRadio}
                  data-quesId="9"
                  checked={checkedQues9Often}
                />
                <label className="form-check-label" htmlFor="">
                {t('often_sometimes')}
                </label>
                </div>
              </div>
              <div className="d-flex">
                  <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="a"
                  id=""
                  defaultValue="Rarely"
                  onClick={saveRadio}
                  data-quesId="9"
                  checked={checkedQues9Rarely}
                />
                <label className="form-check-label" htmlFor="">
                {t('rarely')}
                </label>
                </div>
              </div>
              <div className="d-flex">
                  <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="a"
                  id=""
                  defaultValue="Never"
                  onClick={saveRadio}
                  data-quesId="9"
                  checked={checkedQues9Never}
                />
                <label className="form-check-label" htmlFor="">
                {t('never')}
                </label>
                </div>
              </div>
              <p className="mb-2 mt-3 pn"> <span>8.</span>
              {t('M1chapter03screen06Text2')}
               
              </p>
              <div className="d-flex">
                  <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="b"
                  id=""
                  defaultValue="Always"
                  onClick={saveRadio}
                  data-quesId="10"
                  checked={checkedQues10Always}
                />
                <label className="form-check-label" htmlFor="">
                {t('always')}
                </label>
                </div>
              </div>
              <div className="d-flex">
                  <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="b"
                  id=""
                  defaultValue="Often/sometimes"
                  onClick={saveRadio}
                  data-quesId="10"
                  checked={checkedQues10Often}
                />
                <label className="form-check-label" htmlFor="">
                {t('often-sometimes')}
                </label>
                </div>
              </div>
              <div className="d-flex">
                  <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="b"
                  id=""
                  defaultValue="Rarely"
                  onClick={saveRadio}
                  data-quesId="10"
                  checked={checkedQues10Rarely}
                />
                <label className="form-check-label" htmlFor="">
                {t('rarely')}
                </label>
              </div>
              </div>
              <div className="d-flex">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input custom-check"
                    type="radio"
                    name="b"
                    id=""
                    defaultValue="Never"
                    onClick={saveRadio}
                    data-quesId="10"
                    checked={checkedQues10Never}
                  />
                  <label className="form-check-label" htmlFor="">
                  {t('never')}
                  </label>
                </div>
              </div>
            </div>
            <div
              className="col-lg-5 wow fadeInDown"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
            >
              <p className="mb-2 mt-3 pn"> <span>9.</span>
              {t('M1chapter03screen06Text3')}
                
              </p>
              <div className="d-flex">
                  <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="c"
                  id=""
                  defaultValue="Always"
                  onClick={saveRadio}
                  data-quesId="11"
                  checked={checkedQues11Always}
                />
                <label className="form-check-label" htmlFor="">
                {t('always')}
                </label>
              </div>
              </div>
              <div className="d-flex">
                <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="c"
                  id=""
                  defaultValue="Often/sometimes"
                  onClick={saveRadio}
                  data-quesId="11"
                  checked={checkedQues11Often}
                />
                <label className="form-check-label" htmlFor="">
                  {t('often_sometimes')}
                </label>
                </div>
              </div>
              <div className="d-flex">
                <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="c"
                  id=""
                  defaultValue="Rarely"
                  onClick={saveRadio}
                  data-quesId="11"
                  checked={checkedQues11Rarely}
                />
                <label className="form-check-label" htmlFor="">
                {t('rarely')}
                </label>
              </div>
              </div>
              <div className="d-flex">
                <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="c"
                  id=""
                  defaultValue="Never"
                  onClick={saveRadio}
                  data-quesId="11"
                  checked={checkedQues11Never}
                />
                <label className="form-check-label" htmlFor="">
                {t('never')}
                </label>
              </div>
              </div>
              <p className="mb-2 mt-3 pn"> <span>10.</span>
                {t('M1chapter03screen06Text4')}
                
              </p>
              <div className="d-flex">
                <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="d"
                  id=""
                  defaultValue="Always"
                  onClick={saveRadio}
                  data-quesId="12"
                  checked={checkedQues12Always}
                />
                <label className="form-check-label" htmlFor="">
                {t('always')}
                </label>
              </div>
              </div>
              <div className="d-flex">
                <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="d"

                  defaultValue="Often/sometimes"
                  onClick={saveRadio}
                  data-quesId="12"
                  checked={checkedQues12Often}
                />
                <label className="form-check-label" htmlFor="">
                {t('often_sometimes')}
                </label>
              </div>
              </div>
              <div className="d-flex">
                <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="d"

                  defaultValue="Rarely"
                  onClick={saveRadio}
                  data-quesId="12"
                  checked={checkedQues12Rarely}
                />
                <label className="form-check-label" htmlFor="">
                {t('rarely')}
                </label>
              </div>
              </div>
              <div className="d-flex">
                <div className="form-check form-check-inline">
                <input
                  className="form-check-input custom-check"
                  type="radio"
                  name="d"
                  id=""
                  defaultValue="Never"
                  onClick={saveRadio}
                  data-quesId="12"
                  checked={checkedQues12Never}
                />
                <label className="form-check-label" htmlFor="">
                  {t('never')}
                </label>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    className="button-fixed wow fadeInDown"
    data-wow-duration="1s"
    data-wow-delay="0.2s"
  >
    <div className="container-fluid cf-set">
      <div className="row">
        <div className="col-lg-12">
          <div className=" d-flex justify-content-end">
          <PrevBtn link="/module-01/chapter-03-screen-05"/>

                {/* <NextBtn link="/module-02/chapter-03-screen-07" text="Go Next"/> */}
                <BtnDisabled/>

          </div>
        </div>
      </div>
    </div>
  </div>
</>

  );
};

export default Chapter03Screen06;
