import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import PrevBtn from "../../../common/PrevBtn";
import Header from '../../../header/Header';
import { useDrop } from "react-dnd";
// import Image from './Image';
import Chapter04Screen03_1 from './Chapter04Screen03_1';
import axios from 'axios';
import BaseURL from '../../../config/Baseurl';
import { useEffect } from 'react';
import NextBtn from '../../../common/NextBtn'
import { useDrag } from "react-dnd";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen30 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	let text1 = t('M3chapter04Screen30Text5');
	let text2 = t('M3chapter04Screen30Text6');
	let text3 = t('M3chapter04Screen30Text7');
	let text4 = t('M3chapter04Screen30Text8');
	let text5 = t('M3chapter04Screen30Text9');
	let text6 = t('M3chapter04Screen30Text10');
	let text7 = t('M3chapter04Screen30Text11');
	let text8 = t('M3chapter04Screen30Text12');
	let text9 = t('M3chapter04Screen30Text13');

	const [PictureList, setPictureList] = useState([
		{ "id": "1", "name": text1 },
		{ "id": "2", "name": text2 },
		{ "id": "3", "name": text3 },
		{ "id": "4", "name": text4 },
		{ "id": "5", "name": text5 },
		{ "id": "6", "name": text6 },
		{ "id": "7", "name": text7 },
		// { "id": "8", "name": text8 },
		// { "id": "9", "name": text9 },

	]);




	const [consquesncesArray, setConsquesncesArray] = useState([]);
	const [responsibilitesArray, setResponsibilitesArray] = useState([]);
	const [notComfortableArray, setNotComfortableArray] = useState([]);
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	// not comfortable
	const [{ isNonComfortOver }, notcomfortdrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addNotComfortableArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	// Add To Array Samar
	const addNotComfortableArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setNotComfortableArray((notComfortableArray) => [...notComfortableArray, picture_List[0]]);
	};

	const onItemRemoveNonComfort = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = notComfortableArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setNotComfortableArray(notComfortableArray =>
			notComfortableArray.filter(comfort => {
				return comfort.id !== id;
			}),
		);
	};





	const [{ isOver }, consdrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addSamarArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const [{ isShasOver }, respdrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addSahasArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));


	// Add To Array Samar
	const addSamarArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setConsquesncesArray((consquesncesArray) => [...consquesncesArray, picture_List[0]]);
	};

	// Add To sahas Array
	const addSahasArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setResponsibilitesArray((responsibilitesArray) => [...responsibilitesArray, picture_List[0]]);
	};


	const onItemRemoveSamar = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = consquesncesArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setConsquesncesArray(consquesncesArray =>
			consquesncesArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};

	const onItemRemoveSahas = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = responsibilitesArray.filter((item) => item.id === id);
		console.log(_item);
		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setResponsibilitesArray(responsibilitesArray =>
			responsibilitesArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques33": {
					"ans": JSON.stringify(responsibilitesArray),
					"ans2": JSON.stringify(consquesncesArray),
					"ans3": JSON.stringify(notComfortableArray),
					"ques_id": "33"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-04-screen-31');
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,

			"ques_id": "33"
		});

		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				console.log(response.data);
				if (response.data.success === true) {
					const ans = JSON.parse(response.data.data.chapter.ans)
					const ans2 = JSON.parse(response.data.data.chapter.ans2)
					const ans3 = JSON.parse(response.data.data.chapter.ans3)
					setConsquesncesArray(ans);
					setResponsibilitesArray(ans2);
					setNotComfortableArray(ans3);
					if (ans.length > 0) {
						ans.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans2.length > 0) {
						ans2.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}


				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);


	const BtnDisabled = () => {
		if (responsibilitesArray.length !== 0 && consquesncesArray.length !== 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 ">


								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3 mt-1">{t('engage')} </h3>
									<p className="mb-2 ">{t('M3chapter04Screen30Text1')}  </p>
									<table className="table table-bordered table-dark table-red table-padding mt-1">
										<tbody>
											<tr>
												<td className="td-green">{t('M3chapter04Screen30Text2')} </td>
												<td className="td-yellow">{t('M3chapter04Screen30Text3')}</td>
												<td className="td-red">{t('M3chapter04Screen30Text4')}</td>
											</tr>
											<tr className="drag-table">
												<td>
													<div className="dragarea" ref={consdrop}>
														<ul>

															{consquesncesArray.map((e) => {

																return (
																	<>
																		<li>

																			{e.name}
																			<button
																				onClick={onItemRemoveSamar}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			></button>
																		</li>

																	</>
																)


															})}
														</ul>
													</div>
												</td>
												<td>
													<div className="dragarea" ref={respdrop}>
														<ul>

															{responsibilitesArray.map((e) => {

																return (
																	<>
																		<li>

																			{e.name}
																			<button
																				onClick={onItemRemoveSahas}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			></button>
																		</li>

																	</>
																)


															})}
														</ul>
													</div>
												</td>

												<td>
													<div className="dragarea" ref={notcomfortdrop}>
														<ul>

															{notComfortableArray.map((e) => {

																return (
																	<>
																		<li>

																			{e.name}
																			<button
																				onClick={onItemRemoveNonComfort}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			></button>
																		</li>

																	</>
																)


															})}
														</ul>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>



								<div
									className="col-lg-6 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<ul className="list-style-set list-circle mt-1 mb-1">
										{
											PictureList.map((e) => {
												if (e.id < 6) {

													return (

														<Chapter04Screen03_1 key={e.id} id={e.id} imageName={e.name} />


													)
												}
											})
										}

									</ul>
								</div>


								<div
									className="col-lg-6 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<ul className="list-style-set list-circle mt- mb-1">
										{
											PictureList.map((e) => {
												if (e.id > 5) {
													return (
														<Chapter04Screen03_1 key={e.id} id={e.id} imageName={e.name} />
													)
												}
											})
										}
									</ul>
								</div>

								{/* <div
					className="col-lg-10 wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<center>
					<img
						width="100%"
						draggable="false"
						src="/assets/img/md5/chap02-2/4.jpg"
						alt=""
					/>
					</center>
				</div>

					*/}



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-29" />
								{/* <NextBtn link="/module-03/chapter-04-screen-31" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen30;
