import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL,{AdminUrl} from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen18 = () => {
  const { t, i18n } = useTranslation();
  const [imageUrl,setImageUrl] = useState();


  const [images, setImages] = useState([]);


  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const onImageChange = async(e) => {


    setImages([...e.target.files]);



    const questId = '17';

    const user_id = user_details.user_id;
    const files = e.target.files;
    const formData = new FormData();


    formData.append("image1", files[0]);
    formData.append("user_id", user_id);
    formData.append("ques_id", questId);

    // console.log(user_details.user_id);

    fetch(BaseURL + "/sexualityImageUploadCh1", {
      method: "POST",
      body: formData,
    }).then((res) => {

      res.json().then((result) => {


      });
    });

    // console.log(questId);
  };


  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": "17"
    });
    var config = {
      method: 'post',
      url: BaseURL + '/sexualityChapter01Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;

          setImageUrl(results.ans2);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
          <Header leftChapter="1/4" percentage="0" star="50" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-between pt-20 mt-2">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >

                <h3 className="h3 mt-3">{t('share')}  </h3>
                  <p className="mb-2  mt-3">{t('M3chapter01Screen18Text1')}  </p>

                  <table className="table table-bordered table-dark table-red table-padding">
                    <tbody>
                      <tr>
                        <td>{t('M3chapter01Screen18Text2')}: ...............</td>
                        <td>{t('M3chapter01Screen18Text3')}: ......................</td>

                      </tr>
                      <tr className="color-dark-red">
                        <td >{t('M3chapter01Screen18Text4')} </td>
                        <td>{t('M3chapter01Screen18Text5')}</td>
                      </tr>

                      <tr className="color-dark-red">
                        <td >{t('M3chapter01Screen18Text6')}  </td>
                        <td>{t('M3chapter01Screen18Text7')} </td>
                      </tr>

                      <tr className="color-dark-red">
                        <td>{t('M3chapter01Screen18Text8')} </td>
                        <td>{t('M3chapter01Screen18Text9')}  </td>
                      </tr>

                      <tr className="color-dark-red">
                        <td>{t('M3chapter01Screen18Text10')} </td>
                        <td>{t('M3chapter01Screen18Text11')} </td>
                      </tr>
                      <tr className="color-dark-red">
                        <td>{t('M3chapter01Screen18Text12')} </td>
                        <td>{t('M3chapter01Screen18Text13')}  </td>
                      </tr>


                    </tbody>
                  </table>




                  {/* <div className="box">
                    <form>
                      <input id="demo1" className="demo1"  type="file" onChange={onImageChange}  placeholder="Select Files" name="demo1[]" />
                    </form>
                  </div> */}

                  <div class="file-input">
                          <input type="file" name="file-input" id="file-input" class="file-input__input" onChange={onImageChange} />
                          <label class="file-input__label" for="file-input">
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" class="svg-inline--fa fa-upload fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                              <path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
                            </svg>
                            <span>{t('UploadBtn')}</span></label>
                            <a href={AdminUrl+"/public/mascul/"+imageUrl} target="__blank">{imageUrl}</a>
                        </div>





                </div>




              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">

                <PrevBtn link="/module-03/chapter-01-screen-17"/>
                <NextBtn link="/module-03/chapter-01-screen-18n" text={t('CommonBtnGoNext')}/>


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter01Screen18;
