import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen10 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/2" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-4 mt-m-3">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>



									<h3 className="h3 ">{t('engage')} </h3>

									<div>
												<p> {t('M3chapter01Screen10Text1')}   </p>
											</div>

									<a href="https://www.youtube.com/watch?v=IaDn_U8JRMM" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
												{t('M3chapter01Screen10Text2')}
										</div>
									</a>

									<a href="https://www.youtube.com/watch?v=8UlsTmilCm8 " target="_blank">
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											{t('M3chapter01Screen10Text3')}
										</div>
									</a>



									{/* <p className="mb-2  mt-3"> <b>{t('M3chapter01Screen10Text3')} </b> </p>

									<ul class="list-style-set list-circle mt-1">
										<li>{t('M3chapter01Screen10Text4')}  </li>
										<li>	{t('M3chapter01Screen10Text5')} </li>
										<li>{t('M3chapter01Screen10Text6')}	</li>
									</ul> */}

								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="90%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-2-17.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>







					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-01-screen-09" />
								<NextBtn link="/module-03/chapter-01-screen-11" text={t('CommonBtnGoNext')} />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen10;
