import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen04 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrappe page-auto bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="0" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<h3 className="h3"> {t('M3chapter01Screen04Text1')} </h3>
									<div className="main-timeline mt-3">
										<div className="timeline">
											<span className="timeline-content">
												<div className="timeline-year">1</div>
												<h3 className="title"> {t('M3chapter01Screen04Text2')} </h3>
												<p className="description">
												{t('M3chapter01Screen04Text3')}
												</p>
											</span>
										</div>
										<div className="timeline">
											<span className="timeline-content">
												<div className="timeline-year">2</div>
												<h3 className="title">{t('M3chapter01Screen04Text4')} </h3>
												<p className="description">
												{t('M3chapter01Screen04Text5')}
												</p>
											</span>
										</div>
										<div className="timeline">
											<span className="timeline-content">
												<div className="timeline-year">3</div>
												<h3 className="title">{t('M3chapter01Screen04Text6')}</h3>
												<p className="description">
												{t('M3chapter01Screen04Text7')}
												</p>
											</span>
										</div>
									</div>

									<p className="mb-2 pb-3 mt-3"> <b>{t('M3chapter01Screen04Text8')} </b> </p>


								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="76%"
											draggable="false"
											src="/assets/img/md5/jwd.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-01-screen-03" />
								<NextBtn link="/module-03/chapter-01-screen-05" text={t('CommonBtnGoNext')} />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen04;
