import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';



const Chapter04Screen15 = () => {
	const { t, i18n } = useTranslation();


	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="36" />
					</div>
				</div>

				<div className="d-flex">
					<Sidebar />

					<div className="data-adjust">
						<div className="container-fluid cf-set">

							<div className="row align-items-top justify-content-between pt-20 mt-2">


								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<h4 className="mb-3 h3 mt-3"> <b> {t('M5chapter04Screen15Text1')} </b></h4>


									<a href="https://www.ohchr.org/en/what-are-human-rights" target="_blank" rel="noreferrer">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M5chapter04Screen15Text2')}</p>
											</div>
										</div>
									</a>
									<a href="https://www.unfpa.org/resources/human-rights-principles" target="_blank" rel="noreferrer">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M5chapter04Screen15Text3')} </p>
												{/* <p>Human Rights Principles | United Nations Population Fund</p> */}
											</div>
										</div>
									</a>

									<a href="http://www.un.org/en/universal-declaration-human-rights/index.html" target="_blank" rel="noreferrer">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p> {t('M5chapter04Screen15Text4')} </p>
												{/* <p> <b>The complete Universal Declaration of Human Right’s document: </b>  Universal declaration human rights </p> */}
											</div>
										</div>
									</a>
									<a href="https://www.youtube.com/watch?v=K50LAk28RXw&list=PLbyiiPziC3QofMeGNC88n-l93sxrT1_pX&index=8" target="_blank" rel="noreferrer">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>	{t('M5chapter04Screen15Text5')} </p>
												{/* <p>A Young Bride Fights Back | Breakthrough India </p> */}
											</div>
										</div>
									</a>

									<a href="https://www.youtube.com/watch?v=4Rx8S1ouy2U" target="_blank" rel="noreferrer">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M5chapter04Screen15Text6')} </p>
											</div>
										</div>
									</a>


								</div>

								<div
									className="col-lg-4 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											className="mt-0"
											width="70%"
											src="/assets/img/md3/md3-shap-4-15.png"
											alt=""
										/>
									</center>
								</div>

							</div>


						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-05/chapter-04-screen-14" />
									<NextBtn link="/module-05/score" text={t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen15;
