import React from "react";
import { NavLink } from "react-router-dom";



const HeaderHome = () => {
  return (
    <>
      <header className="d-flex flex-wrap  align-items-center">
        <div className="d-flex align-items-center mb-md-0 me-md-auto  text-decoration-none">
          <NavLink
            to="/"
          >
            <div className="home-icon">
              <i className="bi bi-house-door-fill" />
            </div>
          </NavLink>






        </div>
      </header>
    </>
  );
};

export default HeaderHome;
