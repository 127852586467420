import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import HeaderHome from "../../header/HeaderHome";
import axios from "axios";
import BaseURL from "../../config/Baseurl";
// import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import Rating from "../../rating/Rating";

const Feedback = () => {

	const navigate = useNavigate();
	const [comment, setComment] = useState('');

	const [rating, setRating] = useState(0); // Current selected rating
	const [hover, setHover] = useState(0);   // Hovered star
	const [starValRating,setStarValueRating] = useState(0)
	const user_details = JSON.parse(localStorage.getItem("user_details"));


	useEffect(()=>{
		getFeedBack();
	})
	const getFeedBack = async() => {

		let data = JSON.stringify({
			"user_id": user_details.user_id,
		});

		let config = {
			method: 'post',
			maxBodyLength: Infinity,
			url: BaseURL+'/getFeedBack',
			headers: {
				'Content-Type': 'application/json'
			},
			data: data
		};

		await axios.request(config)
			.then((response) => {
				if(response.data.success === true)
				{
					let feedData = response.data.data;
					setStarValueRating(feedData.star)

				}
				// console.log(JSON.stringify(response.data));
			})
			.catch((error) => {
				console.log(error);
			});


	}

	const handleRatingSubmit = async (e) => {
		e.preventDefault();

		if (rating === 0) {
			toast("Please Select Rating");
			return false;
		}


		var data12 = JSON.stringify({
			"user_id": user_details.user_id,
			"star": rating,
			"comment": comment
		});

		var config = {
			method: "post",
			url: BaseURL + "/addfeedback",
			headers: {
				"Content-Type": "application/json",
			},
			data: data12,
		};

		await axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					let star = response.data.data.star;
					setRating(0);
					setComment('');

					toast("Thank you for your feedback!");
					return false;
				}

				toast("Failed to submit feedback");
				return false;


			})
			.catch(function (error) {
				toast("Error submitting feedback!");

				console.error("Error submitting feedback:", error);
			});



	};



	const handleComment = (event) => {
		setComment(event.target.value); // Update the state with the textarea value
	  };

	return (
		<>

			<div className="wrapper">
				<ToastContainer />
				<div className="wrapper">
					<div className="fixed-header ">
						<div className="container-fluid cf-set">
							<HeaderHome />
						</div>
					</div>


					<form >
						<div className="data-adjust">

							<div className="container-fluid cf-set">
								<div className="row align-items-top justify-content-center pt-20">
									<div className="col-lg-8">
										<h2 className="h2 mb-5">Feedback</h2>

										<div className="row">

											<div className="col-lg-12">
												<div className="mb-3">
													<label
														htmlFor="exampleInputEmail1"
														className="form-label"
													>
														Comment
													</label>

													<input  onChange={handleComment} className='form-control  form-textare' type="text"/>

												</div>

											</div>



											<div className="col-lg-6 ">
												<div className="mb-3 relative">
													<label
														htmlFor="exampleInputEmail1"
														className="form-label"
													>
														Rating
													</label>


													<div>
														{[...Array(5)].map((_, index) => {
															const starValue = index + 1;

															return (



																<span

																	key={starValue}
																	style={{
																		cursor: "pointer",
																		color: starValue <= (hover || rating) ? "#ffc107" : "#e4e5e9",
																		fontSize: "2rem",
																	}}
																	onClick={() => setRating(starValue)}
																	onMouseEnter={() => setHover(starValue)}
																	onMouseLeave={() => setHover(0)}
																>
																	★
																</span>
															);
														})}
													</div>
												</div>



												<button type="submit" className="button_su_inner" onClick={handleRatingSubmit}>
													<span className="button_text_container">
														{" "}
														Submit <i className="bi bi-arrow-right" />
													</span>
												</button>
											</div>

											<div>




											<Rating rating={starValRating} comment={comment}/>
														</div>

										</div>
									</div>
									<div
										className="col-lg-3 offset-lg-1 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<center>
											<img
												width="70%"
												src="/assets/img/chapter1/reading-book.png"
												alt=""
											/>
										</center>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>

			<div
				className="button-fixed termsc wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-center">

								<ul className="list-style-set list-circle mt-1">
									<li> <NavLink to="/terms-and-conditions">Terms & Conditions </NavLink>	</li>
									<li> <NavLink to="/privacy-policy">Privacy Policy </NavLink>	</li>

								</ul>


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Feedback;