import React from "react";
import { Routes,Route  } from "react-router-dom";

import Home from "../components/pages/home/Home";
import Homefirst from "../components/pages/home/Homefirst";
import AboutBreakthrough from "../components/pages/home/AboutBreakthrough";
import AboutCourse from "../components/pages/home/AboutCourse";
import Login from "../components/pages/Auth/Login";
import Profile from "../components/pages/Auth/Profile";
import Certificate from "../components/pages/Auth/Certificate";
import TermsAndConditions from "../components/pages/Auth/TermsAndConditions";
import PrivacyPolicy from "../components/pages/Auth/PrivacyPolicy";

import Register from "../components/pages/Auth/Register";
import Otp from "../components/pages/Auth/Otp.jsx";

import SignUpOtp from "../components/pages/Auth/SignUpOtp";
import ForgotPassword from "../components/pages/Auth/ForgotPassword";
import ResetPassword from "../components/pages/Auth/ResetPassword";
import Faq from "../components/pages/faq/Faq";
import Protected from "../components/pages/Auth/Protected";
import ChangePassword from "../components/pages/Auth/ChangePassword";
// import PrivacyPolicy from "../components/pages/Auth/PrivacyPolicy";
import Feedback from "../components/pages/Auth/Feedback";


import ChapterM02Title from "../components/pages/module-02/chapter01/ChapterM02Title";
import Chapter01IntroScreen from "../components/pages/module-02/chapter01/Chapter01IntroScreen";
import Chapter01Screen01 from "../components/pages/module-02/chapter01/Chapter01Screen01";

import Chapter01Screen02 from "../components/pages/module-02/chapter01/Chapter01Screen02";
import Chapter01Screen022 from "../components/pages/module-02/chapter01/Chapter01Screen022";
import Chapter01Screen03 from "../components/pages/module-02/chapter01/Chapter01Screen03";
import Chapter01Screen04 from "../components/pages/module-02/chapter01/Chapter01Screen04";
import Chapter01Screen05 from "../components/pages/module-02/chapter01/Chapter01Screen05";
import Chapter01Screen06 from "../components/pages/module-02/chapter01/Chapter01Screen06";
import Chapter01Screen07 from "../components/pages/module-02/chapter01/Chapter01Screen07";

import Chapter01Screen08 from "../components/pages/module-02/chapter01/Chapter01Screen08";
import Chapter01Screen09 from "../components/pages/module-02/chapter01/Chapter01Screen09";

import Chapter02Screen01 from "../components/pages/module-02/chapter02/Chapter02Screen01";
import Chapter02Screen02 from "../components/pages/module-02/chapter02/Chapter02Screen02";

import Chapter02Screen03 from "../components/pages/module-02/chapter02/Chapter02Screen03";
import Chapter02Screen04 from "../components/pages/module-02/chapter02/Chapter02Screen04";
import Chapter02Screen05 from "../components/pages/module-02/chapter02/Chapter02Screen05";
import Chapter02Screen05n1 from "../components/pages/module-02/chapter02/Chapter02Screen05n1";

import Chapter02Screen06 from "../components/pages/module-02/chapter02/Chapter02Screen06";
import Chapter02Screen06n from "../components/pages/module-02/chapter02/Chapter02Screen06n";
import Chapter02Screen07 from "../components/pages/module-02/chapter02/Chapter02Screen07";
import Chapter02Screen08 from "../components/pages/module-02/chapter02/Chapter02Screen08";


import Chapter03Screen01 from "../components/pages/module-02/chapter03/Chapter03Screen01";
import Chapter03Screen02 from "../components/pages/module-02/chapter03/Chapter03Screen02";
import Chapter03Screen02N from "../components/pages/module-02/chapter03/Chapter03Screen02N";
import Chapter03Screen03 from "../components/pages/module-02/chapter03/Chapter03Screen03";
import Chapter03Screen03n4 from "../components/pages/module-02/chapter03/Chapter03Screen03n4";
import Chapter03Screen03n5 from "../components/pages/module-02/chapter03/Chapter03Screen03n5";
import Chapter03Screen04 from "../components/pages/module-02/chapter03/Chapter03Screen04";
import Chapter03Screen05 from "../components/pages/module-02/chapter03/Chapter03Screen05";
import Chapter03Screen06 from "../components/pages/module-02/chapter03/Chapter03Screen06";
import Chapter03Screen07 from "../components/pages/module-02/chapter03/Chapter03Screen07";
import Chapter03Screen08 from "../components/pages/module-02/chapter03/Chapter03Screen08";

import Chapter04Screen01 from "../components/pages/module-02/chapter04/Chapter04Screen01";
import Chapter04Screen02 from "../components/pages/module-02/chapter04/Chapter04Screen02";
import Chapter04Screen03 from "../components/pages/module-02/chapter04/Chapter04Screen03";
import Chapter04Screen04 from "../components/pages/module-02/chapter04/Chapter04Screen04";
import Chapter04Screen05 from "../components/pages/module-02/chapter04/Chapter04Screen05";
import Chapter04Screen05N from "../components/pages/module-02/chapter04/Chapter04Screen05N";

import Chapter04Screen06 from "../components/pages/module-02/chapter04/Chapter04Screen06";
import Chapter04Screen07 from "../components/pages/module-02/chapter04/Chapter04Screen07";
import Chapter04Screen07N from "../components/pages/module-02/chapter04/Chapter04Screen07N";
import Chapter04Screen08 from "../components/pages/module-02/chapter04/Chapter04Screen08";
import Chapter04Screen09 from "../components/pages/module-02/chapter04/Chapter04Screen09";
import Chapter04Screen10 from "../components/pages/module-02/chapter04/Chapter04Screen10";
import Chapter04Screen11 from "../components/pages/module-02/chapter04/Chapter04Screen11";

import Chapter05Screen01 from "../components/pages/module-02/chapter05/Chapter05Screen01";
import Chapter05Screen02 from "../components/pages/module-02/chapter05/Chapter05Screen02";
import Chapter05Screen03 from "../components/pages/module-02/chapter05/Chapter05Screen03";
import Chapter05Screen04 from "../components/pages/module-02/chapter05/Chapter05Screen04";
import Chapter05Screen05 from "../components/pages/module-02/chapter05/Chapter05Screen05";
import Chapter05Screen06 from "../components/pages/module-02/chapter05/Chapter05Screen06";
import Chapter05Screen07 from "../components/pages/module-02/chapter05/Chapter05Screen07";
import Chapter05Screen07N from "../components/pages/module-02/chapter05/Chapter05Screen07N";
import Chapter05Screen08 from "../components/pages/module-02/chapter05/Chapter05Screen08";
import Chapter05Screen09 from "../components/pages/module-02/chapter05/Chapter05Screen09";
import Chapter05Screen10 from "../components/pages/module-02/chapter05/Chapter05Screen10";
import Chapter05Screen11 from "../components/pages/module-02/chapter05/Chapter05Screen11";

import Chapter05Screen12 from "../components/pages/module-02/chapter05/Chapter05Screen12";
import Chapter05Screen13 from "../components/pages/module-02/chapter05/Chapter05Screen13";
import Chapter05Screen14 from "../components/pages/module-02/chapter05/Chapter05Screen14";
import Chapter05Screen15 from "../components/pages/module-02/chapter05/Chapter05Screen15";
import Chapter05Screen16 from "../components/pages/module-02/chapter05/Chapter05Screen16";
import Module1FinalQuizScreen01 from "../components/pages/module-02/chapter05/Module1FinalQuizScreen01";
import Module1FinalQuizScreen02 from "../components/pages/module-02/chapter05/Module1FinalQuizScreen02";
import Module1Score  from "../components/pages/module-02/Score";

//module 2
import ChapterM01TitleModule2 from "../components/pages/module-01/chapter01/ChapterM01Title";
import Chapter01IntroScreenModule2 from "../components/pages/module-01/chapter01/Chapter01IntroScreen";
import Chapter01Screen01Module2 from "../components/pages/module-01/chapter01/Chapter01Screen01";
import Chapter01Screen02Module2 from "../components/pages/module-01/chapter01/Chapter01Screen02";
import Chapter01Screen03Module2 from "../components/pages/module-01/chapter01/Chapter01Screen03";
import Chapter01Screen04Module2 from "../components/pages/module-01/chapter01/Chapter01Screen04";
import Chapter01Screen05Module2 from "../components/pages/module-01/chapter01/Chapter01Screen05";
import Chapter01Screen06Module2 from "../components/pages/module-01/chapter01/Chapter01Screen06";
import Chapter01Screen06Module2N from "../components/pages/module-01/chapter01/Chapter01Screen06N";
import Chapter01Screen07Module2 from "../components/pages/module-01/chapter01/Chapter01Screen07";

import Chapter02Screen01Module2 from "../components/pages/module-01/chapter02/Chapter02Screen01";
import Chapter02Screen02Module2 from "../components/pages/module-01/chapter02/Chapter02Screen02";
import Chapter02Screen02NModule2 from "../components/pages/module-01/chapter02/Chapter02Screen02N";
import Chapter02Screen03Module2 from "../components/pages/module-01/chapter02/Chapter02Screen03";
import Chapter02Screen03Module2N from "../components/pages/module-01/chapter02/Chapter02Screen03N";
import Chapter02Screen04Module2 from "../components/pages/module-01/chapter02/Chapter02Screen04";
import Chapter02Screen04Module2N from "../components/pages/module-01/chapter02/Chapter02Screen04N";
import Chapter02Screen05Module2 from "../components/pages/module-01/chapter02/Chapter02Screen05";
import Chapter02Screen06Module2 from "../components/pages/module-01/chapter02/Chapter02Screen06";
import Chapter02Screen07Module2 from "../components/pages/module-01/chapter02/Chapter02Screen07";
import Chapter02Screen08Module2 from "../components/pages/module-01/chapter02/Chapter02Screen08";
import Chapter02Screen08Module2N from "../components/pages/module-01/chapter02/Chapter02Screen08N";
import Chapter02Screen09Module2 from "../components/pages/module-01/chapter02/Chapter02Screen09";

import Chapter03Screen01Module2 from "../components/pages/module-01/chapter03/Chapter03Screen01";
import Chapter03Screen02Module2 from "../components/pages/module-01/chapter03/Chapter03Screen02";
import Chapter03Screen03Module2 from "../components/pages/module-01/chapter03/Chapter03Screen03";

import Chapter03Screen04Module2 from "../components/pages/module-01/chapter03/Chapter03Screen04";
import Chapter03Screen05Module2 from "../components/pages/module-01/chapter03/Chapter03Screen05";
import Chapter03Screen06Module2 from "../components/pages/module-01/chapter03/Chapter03Screen06";
import Chapter03Screen07Module2 from "../components/pages/module-01/chapter03/Chapter03Screen07";
import Chapter03Screen08Module2 from "../components/pages/module-01/chapter03/Chapter03Screen08";
import Chapter03Screen09Module2 from "../components/pages/module-01/chapter03/Chapter03Screen09";
import Chapter03Screen10Module2 from "../components/pages/module-01/chapter03/Chapter03Screen10";
import Chapter03Screen11Module2 from "../components/pages/module-01/chapter03/Chapter03Screen11";

import Chapter04Screen01Module2 from "../components/pages/module-01/chapter04/Chapter04Screen01";
import Chapter04Screen02Module2 from "../components/pages/module-01/chapter04/Chapter04Screen02";
import Chapter04Screen03Module2 from "../components/pages/module-01/chapter04/Chapter04Screen03";
import Chapter04Screen04Module2 from "../components/pages/module-01/chapter04/Chapter04Screen04";
import Chapter04Screen05Module2 from "../components/pages/module-01/chapter04/Chapter04Screen05";
import Chapter04Screen06Module2 from "../components/pages/module-01/chapter04/Chapter04Screen06";
import Chapter04Screen07Module2 from "../components/pages/module-01/chapter04/Chapter04Screen07";
import Chapter04Screen08Module2 from "../components/pages/module-01/chapter04/Chapter04Screen08";
import Chapter04Screen09Module2 from "../components/pages/module-01/chapter04/Chapter04Screen09";
import Chapter04Screen10Module2 from "../components/pages/module-01/chapter04/Chapter04Screen10";
import Chapter04Screen11Module2 from "../components/pages/module-01/chapter04/Chapter04Screen11";
import Chapter04Screen12Module2 from "../components/pages/module-01/chapter04/Chapter04Screen12";
import Chapter04Screen13Module2 from "../components/pages/module-01/chapter04/Chapter04Screen13";
import Chapter04Screen14Module2 from "../components/pages/module-01/chapter04/Chapter04Screen14";
import Module2Score  from "../components/pages/module-01/Score";

//module 3


import ChapterM05TitleModule3 from "../components/pages/module-05/chapter01/ChapterM05Title";
import Chapter01IntroScreenModule3 from "../components/pages/module-05/chapter01/Chapter01IntroScreen";
import Chapter01Screen01Module3 from "../components/pages/module-05/chapter01/Chapter01Screen01";
import Chapter01Screen02Module3 from "../components/pages/module-05/chapter01/Chapter01Screen02";
import Chapter01Screen03Module3 from "../components/pages/module-05/chapter01/Chapter01Screen03";
import Chapter01Screen04Module3 from "../components/pages/module-05/chapter01/Chapter01Screen04";
import Chapter01Screen05Module3 from "../components/pages/module-05/chapter01/Chapter01Screen05";
import Chapter01Screen06Module3 from "../components/pages/module-05/chapter01/Chapter01Screen06";
import Chapter01Screen07Module3 from "../components/pages/module-05/chapter01/Chapter01Screen07";
import Chapter01Screen08Module3 from "../components/pages/module-05/chapter01/Chapter01Screen08";
import Chapter01Screen09Module3 from "../components/pages/module-05/chapter01/Chapter01Screen09";
import Chapter01Screen10Module3 from "../components/pages/module-05/chapter01/Chapter01Screen10";
import Chapter01Screen11Module3 from "../components/pages/module-05/chapter01/Chapter01Screen11";
import Chapter01Screen12Module3 from "../components/pages/module-05/chapter01/Chapter01Screen12";
import Chapter01Screen13Module3 from "../components/pages/module-05/chapter01/Chapter01Screen13";
import Chapter01Screen14Module3 from "../components/pages/module-05/chapter01/Chapter01Screen14";
import Chapter01Screen15Module3 from "../components/pages/module-05/chapter01/Chapter01Screen15";
import Chapter01Screen16Module3 from "../components/pages/module-05/chapter01/Chapter01Screen16";
import Chapter01Screen17Module3 from "../components/pages/module-05/chapter01/Chapter01Screen17";
import Chapter01Screen18Module3 from "../components/pages/module-05/chapter01/Chapter01Screen18";
import Chapter01Screen19Module3 from "../components/pages/module-05/chapter01/Chapter01Screen19";
import Chapter01Screen20Module3 from "../components/pages/module-05/chapter01/Chapter01Screen20";
import Chapter01Screen21Module3 from "../components/pages/module-05/chapter01/Chapter01Screen21";
import Chapter01Screen22Module3 from "../components/pages/module-05/chapter01/Chapter01Screen22";
import Chapter01Screen23Module3 from "../components/pages/module-05/chapter01/Chapter01Screen23";
import Chapter01Screen24Module3 from "../components/pages/module-05/chapter01/Chapter01Screen24";


import Chapter02Screen01Module3 from "../components/pages/module-05/chapter02/Chapter02Screen01";
import Chapter02Screen02Module3 from "../components/pages/module-05/chapter02/Chapter02Screen02";
import Chapter02Screen02NModule3 from "../components/pages/module-05/chapter02/Chapter02Screen02N";
import Chapter02Screen03Module3 from "../components/pages/module-05/chapter02/Chapter02Screen03";
import Chapter02Screen04Module3 from "../components/pages/module-05/chapter02/Chapter02Screen04";
import Chapter02Screen05Module3 from "../components/pages/module-05/chapter02/Chapter02Screen05";
import Chapter02Screen06Module3 from "../components/pages/module-05/chapter02/Chapter02Screen06";
import Chapter02Screen07Module3 from "../components/pages/module-05/chapter02/Chapter02Screen07";
import Chapter02Screen08Module3 from "../components/pages/module-05/chapter02/Chapter02Screen08";
import Chapter02Screen09Module3 from "../components/pages/module-05/chapter02/Chapter02Screen09";
import Chapter02Screen10Module3 from "../components/pages/module-05/chapter02/Chapter02Screen10";



import Chapter03Screen01Module3 from "../components/pages/module-05/chapter03/Chapter03Screen01";
import Chapter03Screen02Module3 from "../components/pages/module-05/chapter03/Chapter03Screen02";
import Chapter03Screen03Module3 from "../components/pages/module-05/chapter03/Chapter03Screen03";
import Chapter03Screen04Module3 from "../components/pages/module-05/chapter03/Chapter03Screen04";
import Chapter03Screen05Module3 from "../components/pages/module-05/chapter03/Chapter03Screen05";
import Chapter03Screen06Module3 from "../components/pages/module-05/chapter03/Chapter03Screen06";
import Chapter03Screen07Module3 from "../components/pages/module-05/chapter03/Chapter03Screen07";
import Chapter03Screen08Module3 from "../components/pages/module-05/chapter03/Chapter03Screen08";
import Chapter03Screen09Module3 from "../components/pages/module-05/chapter03/Chapter03Screen09";
import Chapter03Screen10Module3 from "../components/pages/module-05/chapter03/Chapter03Screen10";
import Chapter03Screen11Module3 from "../components/pages/module-05/chapter03/Chapter03Screen11";
import Chapter03Screen11NModule3 from "../components/pages/module-05/chapter03/Chapter03Screen11N";
import Chapter03Screen12Module3 from "../components/pages/module-05/chapter03/Chapter03Screen12";
import Chapter03Screen13Module3 from "../components/pages/module-05/chapter03/Chapter03Screen13";

import Chapter04Screen01Module3 from "../components/pages/module-05/chapter04/Chapter04Screen01";
import Chapter04Screen02Module3 from "../components/pages/module-05/chapter04/Chapter04Screen02";
import Chapter04Screen03Module3 from "../components/pages/module-05/chapter04/Chapter04Screen03";
import Chapter04Screen04Module3 from "../components/pages/module-05/chapter04/Chapter04Screen04";
import Chapter04Screen05Module3 from "../components/pages/module-05/chapter04/Chapter04Screen05";
import Chapter04Screen05NModule3 from "../components/pages/module-05/chapter04/Chapter04Screen05N";
import Chapter04Screen06Module3 from "../components/pages/module-05/chapter04/Chapter04Screen06";
import Chapter04Screen07Module3 from "../components/pages/module-05/chapter04/Chapter04Screen07";
import Chapter04Screen08Module3 from "../components/pages/module-05/chapter04/Chapter04Screen08";
import Chapter04Screen09Module3 from "../components/pages/module-05/chapter04/Chapter04Screen09";
import Chapter04Screen10Module3 from "../components/pages/module-05/chapter04/Chapter04Screen10";
import Chapter04Screen11Module3 from "../components/pages/module-05/chapter04/Chapter04Screen11";
import Chapter04Screen12Module3 from "../components/pages/module-05/chapter04/Chapter04Screen12";
import Chapter04Screen13Module3 from "../components/pages/module-05/chapter04/Chapter04Screen13";
import Chapter04Screen14Module3 from "../components/pages/module-05/chapter04/Chapter04Screen14";
import Chapter04Screen15Module3 from "../components/pages/module-05/chapter04/Chapter04Screen15";

import Module3Score  from "../components/pages/module-05/Score";

//module 4

import ModuleM04Title from "../components/pages/module-04/chapter-01/ChapterM04Title";
import Module04IntroScreen from "../components/pages/module-04/chapter-01/Module04IntroScreen";
import Chapter01Screen01Module4 from "../components/pages/module-04/chapter-01/Chapter01Screen01";
import Chapter01Screen02FirstModule4 from "../components/pages/module-04/chapter-01/Chapter01Screen02First";
import Chapter01Screen02Module4 from "../components/pages/module-04/chapter-01/Chapter01Screen02";
import Chapter01Screen03Module4 from "../components/pages/module-04/chapter-01/Chapter01Screen03";
import Chapter01Screen04Module4 from "../components/pages/module-04/chapter-01/Chapter01Screen04";
import Chapter01Screen05Module4 from "../components/pages/module-04/chapter-01/Chapter01Screen05";
import Chapter01Screen06Module4 from "../components/pages/module-04/chapter-01/Chapter01Screen06";
import Chapter01Screen07Module4 from "../components/pages/module-04/chapter-01/Chapter01Screen07";
import Chapter01Screen08Module4 from "../components/pages/module-04/chapter-01/Chapter01Screen08";

import Chapter02Screen01Module4 from "../components/pages/module-04/chapter-02/Chapter02Screen01";
import Chapter02Screen02Module4 from "../components/pages/module-04/chapter-02/Chapter02Screen02";
import Chapter02Screen03Module4 from "../components/pages/module-04/chapter-02/Chapter02Screen03";
import Chapter02Screen04Module4 from "../components/pages/module-04/chapter-02/Chapter02Screen04";
import Chapter02Screen05Module4 from "../components/pages/module-04/chapter-02/Chapter02Screen05";
import Chapter02Screen06Module4 from "../components/pages/module-04/chapter-02/Chapter02Screen06";
import Chapter02Screen07Module4 from "../components/pages/module-04/chapter-02/Chapter02Screen07";
import Chapter02Screen08Module4 from "../components/pages/module-04/chapter-02/Chapter02Screen08";
import Chapter02Screen09Module4 from "../components/pages/module-04/chapter-02/Chapter02Screen09";
import Chapter02Screen10Module4 from "../components/pages/module-04/chapter-02/Chapter02Screen10";
import Chapter02Screen11Module4 from "../components/pages/module-04/chapter-02/Chapter02Screen11";
import Chapter02Screen12Module4 from "../components/pages/module-04/chapter-02/Chapter02Screen12";
import Chapter02Screen13Module4 from "../components/pages/module-04/chapter-02/Chapter02Screen13";
import Chapter02Screen14Module4 from "../components/pages/module-04/chapter-02/Chapter02Screen14";

import Chapter03Screen01Module4 from "../components/pages/module-04/chapter-03/Chapter03Screen01";
import Chapter03Screen02Module4 from "../components/pages/module-04/chapter-03/Chapter03Screen02";
import Chapter03Screen03Module4 from "../components/pages/module-04/chapter-03/Chapter03Screen03";
import Chapter03Screen04Module4 from "../components/pages/module-04/chapter-03/Chapter03Screen04";
import Chapter03Screen05Module4 from "../components/pages/module-04/chapter-03/Chapter03Screen05";
import Chapter03Screen06Module4 from "../components/pages/module-04/chapter-03/Chapter03Screen06";
import Chapter03Screen07Module4 from "../components/pages/module-04/chapter-03/Chapter03Screen07";
import Chapter03Screen08Module4 from "../components/pages/module-04/chapter-03/Chapter03Screen08";
import Chapter03Screen09Module4 from "../components/pages/module-04/chapter-03/Chapter03Screen09";


import Chapter04Screen01Module4 from "../components/pages/module-04/chapter-04/Chapter04Screen01";
import Chapter04Screen02Module4 from "../components/pages/module-04/chapter-04/Chapter04Screen02";
import Chapter04Screen03Module4 from "../components/pages/module-04/chapter-04/Chapter04Screen03";
import Chapter04Screen04Module4 from "../components/pages/module-04/chapter-04/Chapter04Screen04";
import Chapter04Screen05Module4 from "../components/pages/module-04/chapter-04/Chapter04Screen05";
import Chapter04Screen06Module4 from "../components/pages/module-04/chapter-04/Chapter04Screen06";
import Chapter04Screen06NModule4 from "../components/pages/module-04/chapter-04/Chapter04Screen06N";
import Chapter04Screen07Module4 from "../components/pages/module-04/chapter-04/Chapter04Screen07";
import Chapter04Screen08Module4 from "../components/pages/module-04/chapter-04/Chapter04Screen08";
import Chapter04Screen09Module4 from "../components/pages/module-04/chapter-04/Chapter04Screen09";
import Chapter04Screen10Module4 from "../components/pages/module-04/chapter-04/Chapter04Screen10";
import Chapter04Screen11Module4 from "../components/pages/module-04/chapter-04/Chapter04Screen11";
import Chapter04Screen12Module4 from "../components/pages/module-04/chapter-04/Chapter04Screen12";
import Chapter04Screen12Module4N from "../components/pages/module-04/chapter-04/Chapter04Screen12N";
import Chapter04Screen13Module4 from "../components/pages/module-04/chapter-04/Chapter04Screen13";
import Chapter04Screen14Module4 from "../components/pages/module-04/chapter-04/Chapter04Screen14";

import Chapter05Screen01Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen01";
import Chapter05Screen02Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen02";
import Chapter05Screen03Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen03";
import Chapter05Screen04Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen04";
import Chapter05Screen05Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen05";
import Chapter05Screen06Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen06";
import Chapter05Screen07Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen07";
import Chapter05Screen08Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen08";
import Chapter05Screen09Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen09";
import Chapter05Screen10Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen10";
import Chapter05Screen11Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen11";
import Chapter05Screen12Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen12";
import Chapter05Screen13Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen13";
import Chapter05Screen14Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen14";
import Chapter05Screen15Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen15";
import Chapter05Screen16Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen16";
import Chapter05Screen17Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen17";
import Chapter05Screen18Module4 from "../components/pages/module-04/chapter-05/Chapter05Screen18";
import Module4Score  from "../components/pages/module-04/Score";

//module 6
import Module06ChapterM06Title from "../components/pages/module-06/chapter-01/ChapterM06Title";
import Module06IntroScreen from "../components/pages/module-06/chapter-01/Module06IntroScreen";
import Chapter01Screen01Module6 from "../components/pages/module-06/chapter-01/Chapter01Screen01";
import Chapter01Screen02Module6 from "../components/pages/module-06/chapter-01/Chapter01Screen02";
import Chapter01Screen03Module6 from "../components/pages/module-06/chapter-01/Chapter01Screen03";
import Chapter01Screen04Module6 from "../components/pages/module-06/chapter-01/Chapter01Screen04";
import Chapter01Screen05Module6 from "../components/pages/module-06/chapter-01/Chapter01Screen05";
import Chapter01Screen06Module6 from "../components/pages/module-06/chapter-01/Chapter01Screen06";
import Chapter01Screen07Module6 from "../components/pages/module-06/chapter-01/Chapter01Screen07";



import Chapter02Screen01Module6 from "../components/pages/module-06/chapter-02/Chapter02Screen01";
import Chapter02Screen02Module6 from "../components/pages/module-06/chapter-02/Chapter02Screen02";
import Chapter02Screen03Module6 from "../components/pages/module-06/chapter-02/Chapter02Screen03";
import Chapter02Screen04Module6 from "../components/pages/module-06/chapter-02/Chapter02Screen04";
import Chapter02Screen05Module6 from "../components/pages/module-06/chapter-02/Chapter02Screen05";
import Chapter02Screen06Module6 from "../components/pages/module-06/chapter-02/Chapter02Screen06";
import Chapter02Screen07Module6 from "../components/pages/module-06/chapter-02/Chapter02Screen07";
import Module6Score  from "../components/pages/module-06/Score";

import Chapter03Screen01Module6 from "../components/pages/module-06/chapter-03/Chapter03Screen01";
import Chapter03Screen02Module6 from "../components/pages/module-06/chapter-03/Chapter03Screen02";
import Chapter03Screen03Module6 from "../components/pages/module-06/chapter-03/Chapter03Screen03";
import Chapter03Screen04Module6 from "../components/pages/module-06/chapter-03/Chapter03Screen04";
import Chapter03Screen05Module6 from "../components/pages/module-06/chapter-03/Chapter03Screen05";
import Chapter03Screen06Module6 from "../components/pages/module-06/chapter-03/Chapter03Screen06";
import Chapter03Screen07Module6 from "../components/pages/module-06/chapter-03/Chapter03Screen07";

import Chapter04Screen01Module6 from "../components/pages/module-06/chapter-04/Chapter04Screen01";
import Chapter04Screen02Module6 from "../components/pages/module-06/chapter-04/Chapter04Screen02";
import Chapter04Screen03Module6 from "../components/pages/module-06/chapter-04/Chapter04Screen03";
import Chapter04Screen04Module6 from "../components/pages/module-06/chapter-04/Chapter04Screen04";
import Chapter04Screen05Module6 from "../components/pages/module-06/chapter-04/Chapter04Screen05";
import Chapter04Screen06Module6 from "../components/pages/module-06/chapter-04/Chapter04Screen06";
import Chapter04Screen07Module6 from "../components/pages/module-06/chapter-04/Chapter04Screen07";
import Chapter04Screen08Module6 from "../components/pages/module-06/chapter-04/Chapter04Screen08";
import Chapter04Screen09Module6 from "../components/pages/module-06/chapter-04/Chapter04Screen09";
import Chapter04Screen10Module6 from "../components/pages/module-06/chapter-04/Chapter04Screen10";
import Chapter04Screen11Module6 from "../components/pages/module-06/chapter-04/Chapter04Screen11";
import Chapter04Screen12Module6 from "../components/pages/module-06/chapter-04/Chapter04Screen12";



// Module 5
import ChapterM03TitleNModule5 from "../components/pages/module-03/chapter-01/ChapterM03Title.jsx";
import Chapter01Screen01NModule5 from "../components/pages/module-03/chapter-01/Chapter01Screen01N.jsx";
import Module05IntroScreen from "../components/pages/module-03/chapter-01/Module05IntroScreen";
import Chapter01Screen01Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen01";
import Chapter01Screen02Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen02";
import Chapter01Screen03Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen03";
import Chapter01Screen04Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen04";
import Chapter01Screen05Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen05";
import Chapter01Screen06Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen06";
import Chapter01Screen07Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen07";
import Chapter01Screen08Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen08";
import Chapter01Screen09Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen09";
import Chapter01Screen10Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen10";
import Chapter01Screen11Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen11";
import Chapter01Screen12Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen12";
import Chapter01Screen13Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen13";
import Chapter01Screen14Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen14";
import Chapter01Screen15Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen15";
import Chapter01Screen16Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen16";
import Chapter01Screen17Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen17";
import Chapter01Screen18Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen18";
import Chapter01Screen18NModule5 from "../components/pages/module-03/chapter-01/Chapter01Screen18N";
import Chapter01Screen18TModule5 from "../components/pages/module-03/chapter-01/Chapter01Screen18T";
import Chapter01Screen18THModule5 from "../components/pages/module-03/chapter-01/Chapter01Screen18TH";
import Chapter01Screen19Module5 from "../components/pages/module-03/chapter-01/Chapter01Screen19";



import Chapter02Screen01Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen01";
import Chapter02Screen02Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen02";
import Chapter02Screen02Module5N from "../components/pages/module-03/chapter-02/Chapter02Screen02N";
import Chapter02Screen03Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen03";
import Chapter02Screen02Module5N2 from "../components/pages/module-03/chapter-02/Chapter02Screen03N";
import Chapter02Screen04Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen04";
import Chapter02Screen04Module5N3 from "../components/pages/module-03/chapter-02/Chapter02Screen04N.jsx";
import Chapter02Screen04Module5N4 from "../components/pages/module-03/chapter-02/Chapter02Screen04N1.jsx";
import Chapter02Screen04Module5N5 from "../components/pages/module-03/chapter-02/Chapter02Screen04N2.jsx";
import Chapter02Screen04Module5N6 from "../components/pages/module-03/chapter-02/Chapter02Screen04N3.jsx";
import Chapter02Screen04Module5N7 from "../components/pages/module-03/chapter-02/Chapter02Screen04N4.jsx";
import Chapter02Screen04Module5N8 from "../components/pages/module-03/chapter-02/Chapter02Screen04N5.jsx";
import Chapter02Screen04Module5N9 from "../components/pages/module-03/chapter-02/Chapter02Screen04N6.jsx";
import Chapter02Screen05Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen05";

import Chapter02Screen05NModule5 from "../components/pages/module-03/chapter-02/Chapter02Screen05N";

import Chapter02Screen06Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen06";
import Chapter02Screen07Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen07";
import Chapter02Screen08Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen08";
import Chapter02Screen09Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen09";
import Chapter02Screen10Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen10";
import Chapter02Screen11Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen11";
import Chapter02Screen12Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen12";
import Chapter02Screen13Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen13";
import Chapter02Screen14Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen14";
import Chapter02Screen15Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen15";
import Chapter02Screen16Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen16";
import Chapter02Screen17Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen17";
import Chapter02Screen18Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen18";
import Chapter02Screen19Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen19";
import Chapter02Screen20Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen20";
import Chapter02Screen21Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen21";
import Chapter02Screen22Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen22";
import Chapter02Screen23Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen23";
import Chapter02Screen24Module5 from "../components/pages/module-03/chapter-02/Chapter02Screen24";


import Chapter03Screen01Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen01";
import Chapter03Screen02Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen02";
import Chapter03Screen02NModule5 from "../components/pages/module-03/chapter-03/Chapter03Screen02N";
import Chapter03Screen03Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen03";
import Chapter03Screen04Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen04";
import Chapter03Screen05Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen05";
import Chapter03Screen06Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen06";
import Chapter03Screen07Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen07";
import Chapter03Screen08Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen08";
import Chapter03Screen09Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen09";
import Chapter03Screen10Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen10";
import Chapter03Screen11Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen11";
import Chapter03Screen12Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen12";
import Chapter03Screen13Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen13";
import Chapter03Screen14Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen14";
import Chapter03Screen15Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen15";
import Chapter03Screen16Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen16";
import Chapter03Screen17Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen17";
import Chapter03Screen18Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen18";
import Chapter03Screen19Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen19";
import Chapter03Screen20Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen20";
import Chapter03Screen21Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen21";
import Chapter03Screen22Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen22";
import Chapter03Screen23Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen23";
import Chapter03Screen24Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen24";
import Chapter03Screen25Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen25";
import Chapter03Screen26Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen26";
import Chapter03Screen27Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen27";
import Chapter03Screen28Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen28";
import Chapter03Screen29Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen29";
import Chapter03Screen30Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen30";
import Chapter03Screen31Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen31";
import Chapter03Screen32Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen32";
import Chapter03Screen33Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen33";
import Chapter03Screen34Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen34";
import Chapter03Screen35Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen35";
import Chapter03Screen36Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen36";
import Chapter03Screen37Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen37";
import Chapter03Screen38Module5 from "../components/pages/module-03/chapter-03/Chapter03Screen38";




import Chapter04Screen01Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen01";
import Chapter04Screen02Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen02";
import Chapter04Screen03Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen03";
import Chapter04Screen04Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen04";
import Chapter04Screen05Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen05";
import Chapter04Screen06Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen06";
import Chapter04Screen07Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen07";
import Chapter04Screen08Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen08";
import Chapter04Screen09Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen09";
import Chapter04Screen10Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen10";
import Chapter04Screen11Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen11";
import Chapter04Screen12Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen12";
import Chapter04Screen13Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen13";
import Chapter04Screen14Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen14";
import Chapter04Screen15Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen15";
import Chapter04Screen16Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen16";
import Chapter04Screen17Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen17";
import Chapter04Screen18Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen18";
import Chapter04Screen19Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen19";
import Chapter04Screen20Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen20";
import Chapter04Screen21Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen21";
import Chapter04Screen22Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen22";
import Chapter04Screen23Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen23";
import Chapter04Screen23NModule5 from "../components/pages/module-03/chapter-04/Chapter04Screen23N";
import Chapter04Screen24Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen24";
import Chapter04Screen24NModule5 from "../components/pages/module-03/chapter-04/Chapter04Screen24N";

import Chapter04Screen25Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen25";
import Chapter04Screen26Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen26";
import Chapter04Screen27Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen27";
import Chapter04Screen28Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen28";
import Chapter04Screen29Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen29";
import Chapter04Screen30Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen30";
import Chapter04Screen31Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen31";
import Chapter04Screen32Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen32";
import Chapter04Screen33Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen33";
import Chapter04Screen34Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen34";
import Chapter04Screen34NModule5 from "../components/pages/module-03/chapter-04/Chapter04Screen34N";
import Chapter04Screen34N1Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen34N1";
import Chapter04Screen34N2Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen34N2";
import Chapter04Screen35Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen35";
import Chapter04Screen36Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen36";
import Chapter04Screen37Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen37";
import Chapter04Screen38Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen38";
import Chapter04Screen39Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen39";
import Chapter04Screen40Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen40";
import Chapter04Screen41Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen41";

import Chapter04Screen42Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen42";
import Chapter04Screen43Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen43";
import Chapter04Screen44Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen44";
import Chapter04Screen45Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen45";
import Chapter04Screen46Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen46";
import Chapter04Screen47Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen47";
import Chapter04Screen48Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen48";
import Chapter04Screen49Module5 from "../components/pages/module-03/chapter-04/Chapter04Screen49";
import Module5Score  from "../components/pages/module-03/Score";



const AppRoute = () => {
  return (
    <>
        <Routes>
            <Route path="/" element={<Homefirst/>}></Route>
            <Route path="/login" element={<Login/>}></Route>
            <Route path="/home"  element={<Protected Component={Home} />} ></Route>
            <Route path="/home-first" element={<Homefirst/>}></Route>
            <Route path="/about-breakthrough" element={<AboutBreakthrough/>}></Route>
            <Route path="/about-course" element={<AboutCourse/>}></Route>
            <Route path="/terms-and-conditions" element={<TermsAndConditions />}></Route>
            <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>

            <Route path="/profile" element={<Profile/>}></Route>
            <Route path="/certificate" element={<Certificate/>}></Route>
            <Route path="/change-password" element={<ChangePassword/>}></Route>
            <Route path="/register" element={<Register/>}></Route>
            <Route exact path="/otp" element={<Otp />}></Route>
            <Route exact path="/signup-otp" element={<SignUpOtp />}></Route>
            <Route exact path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route exact path="/reset-password" element={<ResetPassword />}></Route>
            <Route exact path="/faq" element={<Faq />}></Route>
            <Route path="/change-password" element={<ChangePassword/>}></Route>


{/* change module1 to module2 */}
            <Route path="/module-02/module-02-title"  element={<Protected Component={ChapterM02Title} />} ></Route>
            <Route path="/module-02/chapter-01-intro-screen"  element={<Protected Component={Chapter01IntroScreen} />} ></Route>
            <Route path="/module-02/chapter-01-screen-01" element={<Protected Component={Chapter01Screen01} />} ></Route>
            <Route path="/module-02/chapter-01-screen-022" element={<Protected Component={Chapter01Screen022} />} ></Route>


            <Route path="/module-02/chapter-01-screen-02" element={<Protected Component={Chapter01Screen02}/>}></Route>
            {/* <Route path="/module-01/chapter-01-screen-03" element={<Protected Component={Chapter01Screen03}/>}></Route> */}
            <Route path="/module-02/chapter-01-screen-04" element={<Protected Component={Chapter01Screen04}/>}></Route>
            <Route path="/module-02/chapter-01-screen-05" element={<Protected Component={Chapter01Screen05}/>}></Route>
            <Route path="/module-02/chapter-01-screen-06" element={<Protected Component={Chapter01Screen06}/>}></Route>
            <Route path="/module-02/chapter-01-screen-07" element={<Protected Component={Chapter01Screen07}/>}></Route>
            <Route path="/module-02/chapter-01-screen-08" element={<Protected Component={Chapter01Screen08}/>}></Route>
            <Route path="/module-02/chapter-01-screen-09" element={<Protected Component={Chapter01Screen09}/>}></Route>

            <Route path="/module-02/chapter-02-screen-01" element={<Protected Component={Chapter02Screen01}/>}></Route>
            <Route path="/module-02/chapter-02-screen-02" element={<Protected Component={Chapter02Screen02}/>}></Route>
            <Route path="/module-02/chapter-02-screen-03" element={<Protected Component={Chapter02Screen03}/>}></Route>
            <Route path="/module-02/chapter-02-screen-04" element={<Protected Component={Chapter02Screen04}/>}></Route>
            <Route path="/module-02/chapter-02-screen-05" element={<Protected Component={Chapter02Screen05}/>}></Route>
            <Route path="/module-02/chapter-02-screen-05n1" element={<Protected Component={Chapter02Screen05n1}/>}></Route>

            <Route path="/module-02/chapter-02-screen-06n" element={<Protected Component={Chapter02Screen06n}/>}></Route>
            <Route path="/module-02/chapter-02-screen-06" element={<Protected Component={Chapter02Screen06}/>}></Route>
            <Route path="/module-02/chapter-02-screen-07" element={<Protected Component={Chapter02Screen07}/>}></Route>
            <Route path="/module-02/chapter-02-screen-08" element={<Protected Component={Chapter02Screen08}/>}></Route>

            <Route path="/module-02/chapter-03-screen-01" element={<Protected Component={Chapter03Screen01}/>}></Route>
            <Route path="/module-02/chapter-03-screen-02" element={<Protected Component={Chapter03Screen02}/>}></Route>
            <Route path="/module-02/chapter-03-screen-02n" element={<Protected Component={Chapter03Screen02N}/>}></Route>
            <Route path="/module-02/chapter-03-screen-03" element={<Protected Component={Chapter03Screen03}/>}></Route>
            <Route path="/module-02/chapter-03-screen-03n4" element={<Protected Component={Chapter03Screen03n4}/>}></Route>
            <Route path="/module-02/chapter-03-screen-03n5" element={<Protected Component={Chapter03Screen03n5}/>}></Route>
            <Route path="/module-02/chapter-03-screen-04" element={<Protected Component={Chapter03Screen04}/>}></Route>
            <Route path="/module-02/chapter-03-screen-05" element={<Protected Component={Chapter03Screen05}/>}></Route>
            <Route path="/module-02/chapter-03-screen-06" element={<Protected Component={Chapter03Screen06}/>}></Route>
            <Route path="/module-02/chapter-03-screen-07" element={<Protected Component={Chapter03Screen07}/>}></Route>
            <Route path="/module-02/chapter-03-screen-08" element={<Protected Component={Chapter03Screen08}/>}></Route>

            <Route path="/module-02/chapter-04-screen-01" element={<Protected Component={Chapter04Screen01}/>}></Route>
            <Route path="/module-02/chapter-04-screen-02" element={<Protected Component={Chapter04Screen02}/>}></Route>
            <Route path="/module-02/chapter-04-screen-03" element={<Protected Component={Chapter04Screen03}/>}></Route>
            <Route path="/module-02/chapter-04-screen-04" element={<Protected Component={Chapter04Screen04}/>}></Route>
            <Route path="/module-02/chapter-04-screen-05" element={<Protected Component={Chapter04Screen05}/>}></Route>
            <Route path="/module-02/chapter-04-screen-05n" element={<Protected Component={Chapter04Screen05N}/>}></Route>
            <Route path="/module-02/chapter-04-screen-06" element={<Protected Component={Chapter04Screen06}/>}></Route>
            <Route path="/module-02/chapter-04-screen-07" element={<Protected Component={Chapter04Screen07}/>}></Route>
            <Route path="/module-02/chapter-04-screen-07n" element={<Protected Component={Chapter04Screen07N}/>}></Route>
            <Route path="/module-02/chapter-04-screen-08" element={<Protected Component={Chapter04Screen08}/>}></Route>
            <Route path="/module-02/chapter-04-screen-09" element={<Protected Component={Chapter04Screen09}/>}></Route>
            <Route path="/module-02/chapter-04-screen-10" element={<Protected Component={Chapter04Screen10}/>}></Route>
            <Route path="/module-02/chapter-04-screen-11" element={<Protected Component={Chapter04Screen11}/>}></Route>

            <Route path="/module-02/chapter-05-screen-01" element={<Protected Component={Chapter05Screen01}/>}></Route>
            <Route path="/module-02/chapter-05-screen-02" element={<Protected Component={Chapter05Screen02}/>}></Route>
            <Route path="/module-02/chapter-05-screen-03" element={<Protected Component={Chapter05Screen03}/>}></Route>
            <Route path="/module-02/chapter-05-screen-04" element={<Protected Component={Chapter05Screen04}/>}></Route>
            <Route path="/module-02/chapter-05-screen-05" element={<Protected Component={Chapter05Screen05}/>}></Route>
            <Route path="/module-02/chapter-05-screen-06" element={<Protected Component={Chapter05Screen06}/>}></Route>
            <Route path="/module-02/chapter-05-screen-07" element={<Protected Component={Chapter05Screen07}/>}></Route>
            <Route path="/module-02/chapter-05-screen-07n" element={<Protected Component={Chapter05Screen07N}/>}></Route>
            <Route path="/module-02/chapter-05-screen-08" element={<Protected Component={Chapter05Screen08}/>}></Route>
            <Route path="/module-02/chapter-05-screen-09" element={<Protected Component={Chapter05Screen09}/>}></Route>
            <Route path="/module-02/chapter-05-screen-10" element={<Protected Component={Chapter05Screen10}/>}></Route>
            <Route path="/module-02/chapter-05-screen-11" element={<Protected Component={Chapter05Screen11}/>}></Route>

            <Route path="/module-02/chapter-05-screen-12" element={<Protected Component={Chapter05Screen12}/>}></Route>
            <Route path="/module-02/chapter-05-screen-13" element={<Protected Component={Chapter05Screen13}/>}></Route>
            <Route path="/module-02/chapter-05-screen-14" element={<Protected Component={Chapter05Screen14}/>}></Route>
            <Route path="/module-02/chapter-05-screen-15" element={<Protected Component={Chapter05Screen15}/>}></Route>
            <Route path="/module-02/chapter-05-screen-16" element={<Protected Component={Chapter05Screen16}/>}></Route>
            <Route path="/module-02/module-01-finalquiz-screen-01" element={<Protected Component={Module1FinalQuizScreen01}/>}></Route>
            <Route path="/module-02/module-01-finalquiz-screen-02" element={<Protected Component={Module1FinalQuizScreen02}/>}></Route>
            <Route path="/module-02/score" element={<Protected Component={Module1Score}/>}></Route>

{/* change module2 to module1 */}
            <Route path="/module-01/module-01-title" element={<Protected Component={ChapterM01TitleModule2}/>}></Route>
            <Route path="/module-01/chapter-01-intro-screen" element={<Protected Component={Chapter01IntroScreenModule2}/>}></Route>
            <Route path="/module-01/chapter-01-screen-01" element={<Protected Component={Chapter01Screen01Module2}/>}></Route>
            <Route path="/module-01/chapter-01-screen-02" element={<Protected Component={Chapter01Screen02Module2}/>}></Route>
            <Route path="/module-01/chapter-01-screen-03" element={<Protected Component={Chapter01Screen03Module2}/>}></Route>
            <Route path="/module-01/chapter-01-screen-04" element={<Protected Component={Chapter01Screen04Module2}/>}></Route>
            <Route path="/module-01/chapter-01-screen-05" element={<Protected Component={Chapter01Screen05Module2}/>}></Route>
            <Route path="/module-01/chapter-01-screen-06" element={<Protected Component={Chapter01Screen06Module2}/>}></Route>
            <Route path="/module-01/chapter-01-screen-06n" element={<Protected Component={Chapter01Screen06Module2N}/>}></Route>
            <Route path="/module-01/chapter-01-screen-07" element={<Protected Component={Chapter01Screen07Module2}/>}></Route>

            <Route path="/module-01/chapter-02-screen-01" element={<Protected Component={Chapter02Screen01Module2}/>}></Route>
            <Route path="/module-01/chapter-02-screen-02" element={<Protected Component={Chapter02Screen02Module2}/>}></Route>
            <Route path="/module-01/chapter-02-screen-02n" element={<Protected Component={Chapter02Screen02NModule2}/>}></Route>
            <Route path="/module-01/chapter-02-screen-03" element={<Protected Component={Chapter02Screen03Module2}/>}></Route>
            <Route path="/module-01/chapter-02-screen-03n" element={<Protected Component={Chapter02Screen03Module2N}/>}></Route>
            <Route path="/module-01/chapter-02-screen-04" element={<Protected Component={Chapter02Screen04Module2}/>}></Route>
            <Route path="/module-01/chapter-02-screen-04n" element={<Protected Component={Chapter02Screen04Module2N}/>}></Route>
            <Route path="/module-01/chapter-02-screen-05" element={<Protected Component={Chapter02Screen05Module2}/>}></Route>
            <Route path="/module-01/chapter-02-screen-06" element={<Protected Component={Chapter02Screen06Module2}/>}></Route>
            <Route path="/module-01/chapter-02-screen-07" element={<Protected Component={Chapter02Screen07Module2}/>}></Route>
            <Route path="/module-01/chapter-02-screen-08" element={<Protected Component={Chapter02Screen08Module2}/>}></Route>
            <Route path="/module-01/chapter-02-screen-08n" element={<Protected Component={Chapter02Screen08Module2N}/>}></Route>
            <Route path="/module-01/chapter-02-screen-09" element={<Protected Component={Chapter02Screen09Module2}/>}></Route>

            <Route path="/module-01/chapter-03-screen-01" element={<Protected Component={Chapter03Screen01Module2}/>}></Route>
            <Route path="/module-01/chapter-03-screen-02" element={<Protected Component={Chapter03Screen02Module2}/>}></Route>
            <Route path="/module-01/chapter-03-screen-03" element={<Protected Component={Chapter03Screen03Module2}/>}></Route>
            <Route path="/module-01/chapter-03-screen-04" element={<Protected Component={Chapter03Screen04Module2}/>}></Route>
            <Route path="/module-01/chapter-03-screen-05" element={<Protected Component={Chapter03Screen05Module2}/>}></Route>
            <Route path="/module-01/chapter-03-screen-06" element={<Protected Component={Chapter03Screen06Module2}/>}></Route>
            <Route path="/module-01/chapter-03-screen-07" element={<Protected Component={Chapter03Screen07Module2}/>}></Route>
            <Route path="/module-01/chapter-03-screen-08" element={<Protected Component={Chapter03Screen08Module2}/>}></Route>
            <Route path="/module-01/chapter-03-screen-09" element={<Protected Component={Chapter03Screen09Module2}/>}></Route>
            <Route path="/module-01/chapter-03-screen-10" element={<Protected Component={Chapter03Screen10Module2}/>}></Route>
            <Route path="/module-01/chapter-03-screen-11" element={<Protected Component={Chapter03Screen11Module2}/>}></Route>

            <Route path="/module-01/chapter-04-screen-01" element={<Protected Component={Chapter04Screen01Module2}/>}></Route>
            <Route path="/module-01/chapter-04-screen-02" element={<Protected Component={Chapter04Screen02Module2}/>}></Route>
            <Route path="/module-01/chapter-04-screen-03" element={<Protected Component={Chapter04Screen03Module2}/>}></Route>
            <Route path="/module-01/chapter-04-screen-04" element={<Protected Component={Chapter04Screen04Module2}/>}></Route>
            <Route path="/module-01/chapter-04-screen-05" element={<Protected Component={Chapter04Screen05Module2}/>}></Route>
            <Route path="/module-01/chapter-04-screen-06" element={<Protected Component={Chapter04Screen06Module2}/>}></Route>
            <Route path="/module-01/chapter-04-screen-07" element={<Protected Component={Chapter04Screen07Module2}/>}></Route>
            <Route path="/module-01/chapter-04-screen-08" element={<Protected Component={Chapter04Screen08Module2}/>}></Route>
            <Route path="/module-01/chapter-04-screen-09" element={<Protected Component={Chapter04Screen09Module2}/>}></Route>
            <Route path="/module-01/chapter-04-screen-10" element={<Protected Component={Chapter04Screen10Module2}/>}></Route>
            <Route path="/module-01/chapter-04-screen-11" element={<Protected Component={Chapter04Screen11Module2}/>}></Route>
            <Route path="/module-01/chapter-04-screen-12" element={<Protected Component={Chapter04Screen12Module2}/>}></Route>
            <Route path="/module-01/chapter-04-screen-13" element={<Protected Component={Chapter04Screen13Module2}/>}></Route>
            <Route path="/module-01/chapter-04-screen-14" element={<Protected Component={Chapter04Screen14Module2}/>}></Route>
            <Route path="/module-01/score" element={<Protected Component={Module2Score}/>}></Route>

            {/* change module2 to module1 */}

            <Route path="/module-05/module-05-title" element={<Protected Component={ChapterM05TitleModule3}/>}></Route>
            <Route path="/module-05/chapter-01-intro-screen" element={<Protected Component={Chapter01IntroScreenModule3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-01" element={<Protected Component={Chapter01Screen01Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-02" element={<Protected Component={Chapter01Screen02Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-03" element={<Protected Component={Chapter01Screen03Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-04" element={<Protected Component={Chapter01Screen04Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-05" element={<Protected Component={Chapter01Screen05Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-06" element={<Protected Component={Chapter01Screen06Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-07" element={<Protected Component={Chapter01Screen07Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-08" element={<Protected Component={Chapter01Screen08Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-09" element={<Protected Component={Chapter01Screen09Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-10" element={<Protected Component={Chapter01Screen10Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-11" element={<Protected Component={Chapter01Screen11Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-12" element={<Protected Component={Chapter01Screen12Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-13" element={<Protected Component={Chapter01Screen13Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-14" element={<Protected Component={Chapter01Screen14Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-15" element={<Protected Component={Chapter01Screen15Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-16" element={<Protected Component={Chapter01Screen16Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-17" element={<Protected Component={Chapter01Screen17Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-18" element={<Protected Component={Chapter01Screen18Module3}/>}></Route>
            {/* <Route path="/module-05/chapter-01-screen-19" element={<Protected Component={Chapter01Screen19Module3}/>}></Route> */}
            <Route path="/module-05/chapter-01-screen-20" element={<Protected Component={Chapter01Screen20Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-21" element={<Protected Component={Chapter01Screen21Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-22" element={<Protected Component={Chapter01Screen22Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-23" element={<Protected Component={Chapter01Screen23Module3}/>}></Route>
            <Route path="/module-05/chapter-01-screen-24" element={<Protected Component={Chapter01Screen24Module3}/>}></Route>


            <Route path="/module-05/chapter-02-screen-01" element={<Protected Component={Chapter02Screen01Module3}/>}></Route>
            <Route path="/module-05/chapter-02-screen-02" element={<Protected Component={Chapter02Screen02Module3}/>}></Route>
            <Route path="/module-05/chapter-02-screen-02n" element={<Protected Component={Chapter02Screen02NModule3}/>}></Route>
            {/* <Route path="/module-05/chapter-02-screen-03" element={<Protected Component={Chapter02Screen03Module3}/>}></Route> */}
            <Route path="/module-05/chapter-02-screen-04" element={<Protected Component={Chapter02Screen04Module3}/>}></Route>
            <Route path="/module-05/chapter-02-screen-05" element={<Protected Component={Chapter02Screen05Module3}/>}></Route>
            <Route path="/module-05/chapter-02-screen-06" element={<Protected Component={Chapter02Screen06Module3}/>}></Route>
            <Route path="/module-05/chapter-02-screen-07" element={<Protected Component={Chapter02Screen07Module3}/>}></Route>
            <Route path="/module-05/chapter-02-screen-08" element={<Protected Component={Chapter02Screen08Module3}/>}></Route>
            <Route path="/module-05/chapter-02-screen-09" element={<Protected Component={Chapter02Screen09Module3}/>}></Route>
            <Route path="/module-05/chapter-02-screen-10" element={<Protected Component={Chapter02Screen10Module3}/>}></Route>



            <Route path="/module-05/chapter-03-screen-01" element={<Protected Component={Chapter03Screen01Module3}/>}></Route>
            <Route path="/module-05/chapter-03-screen-02" element={<Protected Component={Chapter03Screen02Module3}/>}></Route>
            <Route path="/module-05/chapter-03-screen-03" element={<Protected Component={Chapter03Screen03Module3}/>}></Route>
            {/* <Route path="/module-05/chapter-03-screen-04" element={<Protected Component={Chapter03Screen04Module3}/>}></Route> */}
            {/* <Route path="/module-05/chapter-03-screen-05" element={<Protected Component={Chapter03Screen05Module3}/>}></Route> */}
            <Route path="/module-05/chapter-03-screen-06" element={<Protected Component={Chapter03Screen06Module3}/>}></Route>
            <Route path="/module-05/chapter-03-screen-07" element={<Protected Component={Chapter03Screen07Module3}/>}></Route>
            <Route path="/module-05/chapter-03-screen-08" element={<Protected Component={Chapter03Screen08Module3}/>}></Route>
            <Route path="/module-05/chapter-03-screen-09" element={<Protected Component={Chapter03Screen09Module3}/>}></Route>
            <Route path="/module-05/chapter-03-screen-10" element={<Protected Component={Chapter03Screen10Module3}/>}></Route>
            <Route path="/module-05/chapter-03-screen-11" element={<Protected Component={Chapter03Screen11Module3}/>}></Route>
            <Route path="/module-05/chapter-03-screen-11n" element={<Protected Component={Chapter03Screen11NModule3}/>}></Route>
            <Route path="/module-05/chapter-03-screen-12" element={<Protected Component={Chapter03Screen12Module3}/>}></Route>
            <Route path="/module-05/chapter-03-screen-13" element={<Protected Component={Chapter03Screen13Module3}/>}></Route>


            <Route path="/module-05/chapter-04-screen-01" element={<Protected Component={Chapter04Screen01Module3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-02" element={<Protected Component={Chapter04Screen02Module3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-03" element={<Protected Component={Chapter04Screen03Module3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-04" element={<Protected Component={Chapter04Screen04Module3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-05" element={<Protected Component={Chapter04Screen05Module3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-05n" element={<Protected Component={Chapter04Screen05NModule3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-06" element={<Protected Component={Chapter04Screen06Module3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-07" element={<Protected Component={Chapter04Screen07Module3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-08" element={<Protected Component={Chapter04Screen08Module3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-09" element={<Protected Component={Chapter04Screen09Module3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-10" element={<Protected Component={Chapter04Screen10Module3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-11" element={<Protected Component={Chapter04Screen11Module3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-12" element={<Protected Component={Chapter04Screen12Module3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-13" element={<Protected Component={Chapter04Screen13Module3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-14" element={<Protected Component={Chapter04Screen14Module3}/>}></Route>
            <Route path="/module-05/chapter-04-screen-15" element={<Protected Component={Chapter04Screen15Module3}/>}></Route>
            <Route path="/module-05/score" element={<Protected Component={Module3Score}/>}></Route>


            <Route path="/module-04/module-04-title" element={<Protected Component={ModuleM04Title}/>}></Route>
            <Route path="/module-04/module-04-intro-screen" element={<Protected Component={Module04IntroScreen}/>}></Route>
            <Route path="/module-04/chapter-01-screen-01" element={<Protected Component={Chapter01Screen01Module4}/>}></Route>
            <Route path="/module-04/chapter-01-screen-02" element={<Protected Component={Chapter01Screen02Module4}/>}></Route>
            <Route path="/module-04/chapter-01-screen-02-First" element={<Protected Component={Chapter01Screen02FirstModule4}/>}></Route>

            {/* <Route path="/module-04/chapter-01-screen-03" element={<Protected Component={Chapter01Screen03Module4}/>}></Route> */}
            <Route path="/module-04/chapter-01-screen-04" element={<Protected Component={Chapter01Screen04Module4}/>}></Route>
            <Route path="/module-04/chapter-01-screen-05" element={<Protected Component={Chapter01Screen05Module4}/>}></Route>
            <Route path="/module-04/chapter-01-screen-06" element={<Protected Component={Chapter01Screen06Module4}/>}></Route>
            <Route path="/module-04/chapter-01-screen-07" element={<Protected Component={Chapter01Screen07Module4}/>}></Route>
            <Route path="/module-04/chapter-01-screen-08" element={<Protected Component={Chapter01Screen08Module4}/>}></Route>

            <Route path="/module-04/chapter-02-screen-01" element={<Protected Component={Chapter02Screen01Module4}/>}></Route>
            <Route path="/module-04/chapter-02-screen-02" element={<Protected Component={Chapter02Screen02Module4}/>}></Route>
            <Route path="/module-04/chapter-02-screen-03" element={<Protected Component={Chapter02Screen03Module4}/>}></Route>
            <Route path="/module-04/chapter-02-screen-04" element={<Protected Component={Chapter02Screen04Module4}/>}></Route>
            <Route path="/module-04/chapter-02-screen-05" element={<Protected Component={Chapter02Screen05Module4}/>}></Route>
            <Route path="/module-04/chapter-02-screen-06" element={<Protected Component={Chapter02Screen06Module4}/>}></Route>
            <Route path="/module-04/chapter-02-screen-07" element={<Protected Component={Chapter02Screen07Module4}/>}></Route>
            <Route path="/module-04/chapter-02-screen-08" element={<Protected Component={Chapter02Screen08Module4}/>}></Route>
            <Route path="/module-04/chapter-02-screen-09" element={<Protected Component={Chapter02Screen09Module4}/>}></Route>
            <Route path="/module-04/chapter-02-screen-10" element={<Protected Component={Chapter02Screen10Module4}/>}></Route>
            <Route path="/module-04/chapter-02-screen-11" element={<Protected Component={Chapter02Screen11Module4}/>}></Route>
            <Route path="/module-04/chapter-02-screen-12" element={<Protected Component={Chapter02Screen12Module4}/>}></Route>
            <Route path="/module-04/chapter-02-screen-13" element={<Protected Component={Chapter02Screen13Module4}/>}></Route>
            <Route path="/module-04/chapter-02-screen-14" element={<Protected Component={Chapter02Screen14Module4}/>}></Route>

            <Route path="/module-04/chapter-03-screen-01" element={<Protected Component={Chapter03Screen01Module4}/>}></Route>
            <Route path="/module-04/chapter-03-screen-02" element={<Protected Component={Chapter03Screen02Module4}/>}></Route>
            <Route path="/module-04/chapter-03-screen-03" element={<Protected Component={Chapter03Screen03Module4}/>}></Route>
            <Route path="/module-04/chapter-03-screen-04" element={<Protected Component={Chapter03Screen04Module4}/>}></Route>
            <Route path="/module-04/chapter-03-screen-05" element={<Protected Component={Chapter03Screen05Module4}/>}></Route>
            <Route path="/module-04/chapter-03-screen-06" element={<Protected Component={Chapter03Screen06Module4}/>}></Route>
            <Route path="/module-04/chapter-03-screen-07" element={<Protected Component={Chapter03Screen07Module4}/>}></Route>
            <Route path="/module-04/chapter-03-screen-08" element={<Protected Component={Chapter03Screen08Module4}/>}></Route>
            <Route path="/module-04/chapter-03-screen-09" element={<Protected Component={Chapter03Screen09Module4}/>}></Route>

            <Route path="/module-04/chapter-04-screen-01" element={<Protected Component={Chapter04Screen01Module4}/>}></Route>
            <Route path="/module-04/chapter-04-screen-02" element={<Protected Component={Chapter04Screen02Module4}/>}></Route>
            <Route path="/module-04/chapter-04-screen-03" element={<Protected Component={Chapter04Screen03Module4}/>}></Route>
            <Route path="/module-04/chapter-04-screen-04" element={<Protected Component={Chapter04Screen04Module4}/>}></Route>
            <Route path="/module-04/chapter-04-screen-05" element={<Protected Component={Chapter04Screen05Module4}/>}></Route>
            <Route path="/module-04/chapter-04-screen-06" element={<Protected Component={Chapter04Screen06Module4}/>}></Route>
            <Route path="/module-04/chapter-04-screen-06n" element={<Protected Component={Chapter04Screen06NModule4}/>}></Route>
            <Route path="/module-04/chapter-04-screen-07" element={<Protected Component={Chapter04Screen07Module4}/>}></Route>
            <Route path="/module-04/chapter-04-screen-08" element={<Protected Component={Chapter04Screen08Module4}/>}></Route>
            <Route path="/module-04/chapter-04-screen-09" element={<Protected Component={Chapter04Screen09Module4}/>}></Route>
            <Route path="/module-04/chapter-04-screen-10" element={<Protected Component={Chapter04Screen10Module4}/>}></Route>
            <Route path="/module-04/chapter-04-screen-11" element={<Protected Component={Chapter04Screen11Module4}/>}></Route>
            {/* <Route path="/module-04/chapter-04-screen-12" element={<Protected Component={Chapter04Screen12Module4}/>}></Route> */}
            <Route path="/module-04/chapter-04-screen-12-n" element={<Protected Component={Chapter04Screen12Module4N}/>}></Route>
            <Route path="/module-04/chapter-04-screen-13" element={<Protected Component={Chapter04Screen13Module4}/>}></Route>
            <Route path="/module-04/chapter-04-screen-14" element={<Protected Component={Chapter04Screen14Module4}/>}></Route>

            <Route path="/module-04/chapter-05-screen-01" element={<Protected Component={Chapter05Screen01Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-02" element={<Protected Component={Chapter05Screen02Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-03" element={<Protected Component={Chapter05Screen03Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-04" element={<Protected Component={Chapter05Screen04Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-05" element={<Protected Component={Chapter05Screen05Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-06" element={<Protected Component={Chapter05Screen06Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-07" element={<Protected Component={Chapter05Screen07Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-08" element={<Protected Component={Chapter05Screen08Module4}/>}></Route>
            {/* <Route path="/module-04/chapter-05-screen-09" element={<Protected Component={Chapter05Screen09Module4}/>}></Route> */}
            <Route path="/module-04/chapter-05-screen-10" element={<Protected Component={Chapter05Screen10Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-11" element={<Protected Component={Chapter05Screen11Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-12" element={<Protected Component={Chapter05Screen12Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-13" element={<Protected Component={Chapter05Screen13Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-14" element={<Protected Component={Chapter05Screen14Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-15" element={<Protected Component={Chapter05Screen15Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-16" element={<Protected Component={Chapter05Screen16Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-17" element={<Protected Component={Chapter05Screen17Module4}/>}></Route>
            <Route path="/module-04/chapter-05-screen-18" element={<Protected Component={Chapter05Screen18Module4}/>}></Route>
            <Route path="/module-04/score" element={<Protected Component={Module4Score}/>}></Route>





            <Route path="/module-06/module-06-title" element={<Protected Component={Module06ChapterM06Title}/>}></Route>
            <Route path="/module-06/module-06-intro-screen" element={<Protected Component={Module06IntroScreen}/>}></Route>
            <Route path="/module-06/chapter-01-screen-01" element={<Protected Component={Chapter01Screen01Module6}/>}></Route>
            <Route path="/module-06/chapter-01-screen-02" element={<Protected Component={Chapter01Screen02Module6}/>}></Route>
            <Route path="/module-06/chapter-01-screen-03" element={<Protected Component={Chapter01Screen03Module6}/>}></Route>
            <Route path="/module-06/chapter-01-screen-04" element={<Protected Component={Chapter01Screen04Module6}/>}></Route>
            <Route path="/module-06/chapter-01-screen-05" element={<Protected Component={Chapter01Screen05Module6}/>}></Route>
            <Route path="/module-06/chapter-01-screen-06" element={<Protected Component={Chapter01Screen06Module6}/>}></Route>
            <Route path="/module-06/chapter-01-screen-07" element={<Protected Component={Chapter01Screen07Module6}/>}></Route>



            <Route path="/module-06/chapter-02-screen-01" element={<Protected Component={Chapter02Screen01Module6}/>}></Route>
            <Route path="/module-06/chapter-02-screen-02" element={<Protected Component={Chapter02Screen02Module6}/>}></Route>
            <Route path="/module-06/chapter-02-screen-03" element={<Protected Component={Chapter02Screen03Module6}/>}></Route>
            <Route path="/module-06/chapter-02-screen-04" element={<Protected Component={Chapter02Screen04Module6}/>}></Route>
            <Route path="/module-06/chapter-02-screen-05" element={<Protected Component={Chapter02Screen05Module6}/>}></Route>
            <Route path="/module-06/chapter-02-screen-06" element={<Protected Component={Chapter02Screen06Module6}/>}></Route>
            <Route path="/module-06/chapter-02-screen-07" element={<Protected Component={Chapter02Screen07Module6}/>}></Route>
            <Route path="/module-06/score" element={<Protected Component={Module6Score}/>}></Route>

            <Route path="/module-06/chapter-03-screen-01" element={<Protected Component={Chapter03Screen01Module6}/>}></Route>
            <Route path="/module-06/chapter-03-screen-02" element={<Protected Component={Chapter03Screen02Module6}/>}></Route>
            <Route path="/module-06/chapter-03-screen-03" element={<Protected Component={Chapter03Screen03Module6}/>}></Route>
            <Route path="/module-06/chapter-03-screen-04" element={<Protected Component={Chapter03Screen04Module6}/>}></Route>
            <Route path="/module-06/chapter-03-screen-05" element={<Protected Component={Chapter03Screen05Module6}/>}></Route>
            <Route path="/module-06/chapter-03-screen-06" element={<Protected Component={Chapter03Screen06Module6}/>}></Route>
            <Route path="/module-06/chapter-03-screen-07" element={<Protected Component={Chapter03Screen07Module6}/>}></Route>

            <Route path="/module-06/chapter-04-screen-01" element={<Protected Component={Chapter04Screen01Module6}/>}></Route>
            <Route path="/module-06/chapter-04-screen-02" element={<Protected Component={Chapter04Screen02Module6}/>}></Route>
            <Route path="/module-06/chapter-04-screen-03" element={<Protected Component={Chapter04Screen03Module6}/>}></Route>
            <Route path="/module-06/chapter-04-screen-04" element={<Protected Component={Chapter04Screen04Module6}/>}></Route>
            <Route path="/module-06/chapter-04-screen-05" element={<Protected Component={Chapter04Screen05Module6}/>}></Route>
            <Route path="/module-06/chapter-04-screen-06" element={<Protected Component={Chapter04Screen06Module6}/>}></Route>
            <Route path="/module-06/chapter-04-screen-07" element={<Protected Component={Chapter04Screen07Module6}/>}></Route>
            <Route path="/module-06/chapter-04-screen-08" element={<Protected Component={Chapter04Screen08Module6}/>}></Route>
            <Route path="/module-06/chapter-04-screen-09" element={<Protected Component={Chapter04Screen09Module6}/>}></Route>
            {/* <Route path="/module-06/chapter-04-screen-10" element={<Protected Component={Chapter04Screen10Module6}/>}></Route> */}
            {/* <Route path="/module-06/chapter-04-screen-11" element={<Protected Component={Chapter04Screen11Module6}/>}></Route> */}
            <Route path="/module-06/chapter-04-screen-12" element={<Protected Component={Chapter04Screen12Module6}/>}></Route>



            <Route path="/module-03/module-03-title" element={<Protected Component={ChapterM03TitleNModule5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-01-n" element={<Protected Component={Chapter01Screen01NModule5}/>}></Route>
            <Route path="/module-03/chapter-01-intro-screen" element={<Protected Component={Module05IntroScreen}/>}></Route>
            <Route path="/module-03/chapter-01-screen-01" element={<Protected Component={Chapter01Screen01Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-02" element={<Protected Component={Chapter01Screen02Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-03" element={<Protected Component={Chapter01Screen03Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-04" element={<Protected Component={Chapter01Screen04Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-05" element={<Protected Component={Chapter01Screen05Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-06" element={<Protected Component={Chapter01Screen06Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-07" element={<Protected Component={Chapter01Screen07Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-08" element={<Protected Component={Chapter01Screen08Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-09" element={<Protected Component={Chapter01Screen09Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-10" element={<Protected Component={Chapter01Screen10Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-11" element={<Protected Component={Chapter01Screen11Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-12" element={<Protected Component={Chapter01Screen12Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-13" element={<Protected Component={Chapter01Screen13Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-14" element={<Protected Component={Chapter01Screen14Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-15" element={<Protected Component={Chapter01Screen15Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-16" element={<Protected Component={Chapter01Screen16Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-17" element={<Protected Component={Chapter01Screen17Module5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-18" element={<Protected Component={Chapter01Screen18Module5}/>}></Route>

            <Route path="/module-03/chapter-01-screen-18n" element={<Protected Component={Chapter01Screen18NModule5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-18t" element={<Protected Component={Chapter01Screen18TModule5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-18th" element={<Protected Component={Chapter01Screen18THModule5}/>}></Route>
            <Route path="/module-03/chapter-01-screen-19" element={<Protected Component={Chapter01Screen19Module5}/>}></Route>


            <Route path="/module-03/chapter-02-screen-01" element={<Protected Component={Chapter02Screen01Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-02" element={<Protected Component={Chapter02Screen02Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-02n" element={<Protected Component={Chapter02Screen02Module5N}/>}></Route>
            <Route path="/module-03/chapter-02-screen-03" element={<Protected Component={Chapter02Screen03Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-03n" element={<Protected Component={Chapter02Screen02Module5N2}/>}></Route>

            <Route path="/module-03/chapter-02-screen-04" element={<Protected Component={Chapter02Screen04Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-04n" element={<Protected Component={Chapter02Screen04Module5N3}/>}></Route>
            <Route path="/module-03/chapter-02-screen-04n1" element={<Protected Component={Chapter02Screen04Module5N4}/>}></Route>
            <Route path="/module-03/chapter-02-screen-04n2" element={<Protected Component={Chapter02Screen04Module5N5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-04n3" element={<Protected Component={Chapter02Screen04Module5N6}/>}></Route>
            <Route path="/module-03/chapter-02-screen-04n4" element={<Protected Component={Chapter02Screen04Module5N7}/>}></Route>
            <Route path="/module-03/chapter-02-screen-04n5" element={<Protected Component={Chapter02Screen04Module5N8}/>}></Route>
            <Route path="/module-03/chapter-02-screen-04n6" element={<Protected Component={Chapter02Screen04Module5N9}/>}></Route>
            <Route path="/module-03/chapter-02-screen-05" element={<Protected Component={Chapter02Screen05Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-05n" element={<Protected Component={Chapter02Screen05NModule5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-06" element={<Protected Component={Chapter02Screen06Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-07" element={<Protected Component={Chapter02Screen07Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-08" element={<Protected Component={Chapter02Screen08Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-09" element={<Protected Component={Chapter02Screen09Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-10" element={<Protected Component={Chapter02Screen10Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-11" element={<Protected Component={Chapter02Screen11Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-12" element={<Protected Component={Chapter02Screen12Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-13" element={<Protected Component={Chapter02Screen13Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-14" element={<Protected Component={Chapter02Screen14Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-15" element={<Protected Component={Chapter02Screen15Module5}/>}></Route>
            {/* <Route path="/module-03/chapter-02-screen-16" element={<Protected Component={Chapter02Screen16Module5}/>}></Route> */}
            <Route path="/module-03/chapter-02-screen-17" element={<Protected Component={Chapter02Screen17Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-18" element={<Protected Component={Chapter02Screen18Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-19" element={<Protected Component={Chapter02Screen19Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-20" element={<Protected Component={Chapter02Screen20Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-21" element={<Protected Component={Chapter02Screen21Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-22" element={<Protected Component={Chapter02Screen22Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-23" element={<Protected Component={Chapter02Screen23Module5}/>}></Route>
            <Route path="/module-03/chapter-02-screen-24" element={<Protected Component={Chapter02Screen24Module5}/>}></Route>


            <Route path="/module-03/chapter-03-screen-01" element={<Protected Component={Chapter03Screen01Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-02" element={<Protected Component={Chapter03Screen02Module5}/>}></Route>

            <Route path="/module-03/chapter-03-screen-02n" element={<Protected Component={Chapter03Screen02NModule5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-03" element={<Protected Component={Chapter03Screen03Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-04" element={<Protected Component={Chapter03Screen04Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-05" element={<Protected Component={Chapter03Screen05Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-06" element={<Protected Component={Chapter03Screen06Module5}/>}></Route>
            {/* <Route path="/module-03/chapter-03-screen-07" element={<Protected Component={Chapter03Screen07Module5}/>}></Route> */}
            {/* <Route path="/module-03/chapter-03-screen-08" element={<Protected Component={Chapter03Screen08Module5}/>}></Route> */}
            <Route path="/module-03/chapter-03-screen-09" element={<Protected Component={Chapter03Screen09Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-10" element={<Protected Component={Chapter03Screen10Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-11" element={<Protected Component={Chapter03Screen11Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-12" element={<Protected Component={Chapter03Screen12Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-13" element={<Protected Component={Chapter03Screen13Module5}/>}></Route>
            {/* <Route path="/module-03/chapter-03-screen-14" element={<Protected Component={Chapter03Screen14Module5}/>}></Route> */}
            <Route path="/module-03/chapter-03-screen-15" element={<Protected Component={Chapter03Screen15Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-16" element={<Protected Component={Chapter03Screen16Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-17" element={<Protected Component={Chapter03Screen17Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-18" element={<Protected Component={Chapter03Screen18Module5}/>}></Route>
            {/* <Route path="/module-03/chapter-03-screen-19" element={<Protected Component={Chapter03Screen19Module5}/>}></Route> */}
            <Route path="/module-03/chapter-03-screen-20" element={<Protected Component={Chapter03Screen20Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-21" element={<Protected Component={Chapter03Screen21Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-22" element={<Protected Component={Chapter03Screen22Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-23" element={<Protected Component={Chapter03Screen23Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-24" element={<Protected Component={Chapter03Screen24Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-25" element={<Protected Component={Chapter03Screen25Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-26" element={<Protected Component={Chapter03Screen26Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-27" element={<Protected Component={Chapter03Screen27Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-28" element={<Protected Component={Chapter03Screen28Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-29" element={<Protected Component={Chapter03Screen29Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-30" element={<Protected Component={Chapter03Screen30Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-31" element={<Protected Component={Chapter03Screen31Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-32" element={<Protected Component={Chapter03Screen32Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-33" element={<Protected Component={Chapter03Screen33Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-34" element={<Protected Component={Chapter03Screen34Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-35" element={<Protected Component={Chapter03Screen35Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-36" element={<Protected Component={Chapter03Screen36Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-37" element={<Protected Component={Chapter03Screen37Module5}/>}></Route>
            <Route path="/module-03/chapter-03-screen-38" element={<Protected Component={Chapter03Screen38Module5}/>}></Route>


            <Route path="/module-03/chapter-04-screen-01" element={<Protected Component={Chapter04Screen01Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-02" element={<Protected Component={Chapter04Screen02Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-03" element={<Protected Component={Chapter04Screen03Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-04" element={<Protected Component={Chapter04Screen04Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-05" element={<Protected Component={Chapter04Screen05Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-06" element={<Protected Component={Chapter04Screen06Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-07" element={<Protected Component={Chapter04Screen07Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-08" element={<Protected Component={Chapter04Screen08Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-09" element={<Protected Component={Chapter04Screen09Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-10" element={<Protected Component={Chapter04Screen10Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-11" element={<Protected Component={Chapter04Screen11Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-12" element={<Protected Component={Chapter04Screen12Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-13" element={<Protected Component={Chapter04Screen13Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-14" element={<Protected Component={Chapter04Screen14Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-15" element={<Protected Component={Chapter04Screen15Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-16" element={<Protected Component={Chapter04Screen16Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-17" element={<Protected Component={Chapter04Screen17Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-18" element={<Protected Component={Chapter04Screen18Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-19" element={<Protected Component={Chapter04Screen19Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-20" element={<Protected Component={Chapter04Screen20Module5}/>}></Route>
            {/* <Route path="/module-03/chapter-04-screen-21" element={<Protected Component={Chapter04Screen21Module5}/>}></Route> */}
            <Route path="/module-03/chapter-04-screen-22" element={<Protected Component={Chapter04Screen22Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-23" element={<Protected Component={Chapter04Screen23Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-23n" element={<Protected Component={Chapter04Screen23NModule5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-24" element={<Protected Component={Chapter04Screen24Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-24n" element={<Protected Component={Chapter04Screen24NModule5}/>}></Route>


            <Route path="/module-03/chapter-04-screen-25" element={<Protected Component={Chapter04Screen25Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-26" element={<Protected Component={Chapter04Screen26Module5}/>}></Route>
            {/* <Route path="/module-03/chapter-04-screen-27" element={<Protected Component={Chapter04Screen27Module5}/>}></Route> */}
            <Route path="/module-03/chapter-04-screen-28" element={<Protected Component={Chapter04Screen28Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-29" element={<Protected Component={Chapter04Screen29Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-30" element={<Protected Component={Chapter04Screen30Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-31" element={<Protected Component={Chapter04Screen31Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-32" element={<Protected Component={Chapter04Screen32Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-33" element={<Protected Component={Chapter04Screen33Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-34" element={<Protected Component={Chapter04Screen34Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-34n" element={<Protected Component={Chapter04Screen34NModule5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-34n1" element={<Protected Component={Chapter04Screen34N1Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-34n2" element={<Protected Component={Chapter04Screen34N2Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-35" element={<Protected Component={Chapter04Screen35Module5}/>}></Route>
            {/* <Route path="/module-03/chapter-04-screen-36" element={<Protected Component={Chapter04Screen36Module5}/>}></Route> */}
            {/* <Route path="/module-03/chapter-04-screen-37" element={<Protected Component={Chapter04Screen37Module5}/>}></Route> */}
            {/* <Route path="/module-03/chapter-04-screen-38" element={<Protected Component={Chapter04Screen38Module5}/>}></Route> */}
            {/* <Route path="/module-03/chapter-04-screen-39" element={<Protected Component={Chapter04Screen39Module5}/>}></Route> */}
            {/* <Route path="/module-03/chapter-04-screen-40" element={<Protected Component={Chapter04Screen40Module5}/>}></Route> */}
            {/* <Route path="/module-03/chapter-04-screen-41" element={<Protected Component={Chapter04Screen41Module5}/>}></Route> */}
            {/* <Route path="/module-03/chapter-04-screen-42" element={<Protected Component={Chapter04Screen42Module5}/>}></Route> */}
            {/* <Route path="/module-03/chapter-04-screen-43" element={<Protected Component={Chapter04Screen43Module5}/>}></Route> */}
            <Route path="/module-03/chapter-04-screen-44" element={<Protected Component={Chapter04Screen44Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-45" element={<Protected Component={Chapter04Screen45Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-46" element={<Protected Component={Chapter04Screen46Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-47" element={<Protected Component={Chapter04Screen47Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-48" element={<Protected Component={Chapter04Screen48Module5}/>}></Route>
            <Route path="/module-03/chapter-04-screen-49" element={<Protected Component={Chapter04Screen49Module5}/>}></Route>
            <Route path="/module-03/score" element={<Protected Component={Module5Score}/>}></Route>


            <Route path="/feedback" element={<Feedback/>}></Route>
        </Routes>
    </>
  )
}

export default AppRoute;