import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen09 = () => {
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();

	const [checkedQues3, setCheckedQues3] = useState([]);
	const [checkedQues3Option1, setCheckedQues3Option1] = useState('');
	const [checkedQues3Option2, setCheckedQues3Option2] = useState('');
	const [checkedQues3Option3, setCheckedQues3Option3] = useState('');
	const [checkedQues3Option4, setCheckedQues3Option4] = useState('');
	const [checkedQues3Option5, setCheckedQues3Option5] = useState('');
	const [checkedQues3Option6, setCheckedQues3Option6] = useState('');
	const [checkedQues3Option7, setCheckedQues3Option7] = useState('');
	const [checkedQues3Option8, setCheckedQues3Option8] = useState('');
	const [checkedQues3Option9, setCheckedQues3Option9] = useState('');
	const [checkedQues3Option10, setCheckedQues3Option10] = useState('');
	const [checkedQues3Option11, setCheckedQues3Option11] = useState('');
	const [checkedQues3Option12, setCheckedQues3Option12] = useState('');




	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;

		checkBoxChecked(res, checked, quesId);
		if (quesId === '3') {
			setCheckedQues3(checkedQues3 => [...checkedQues3, res]);

		}



	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3.join(','),
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},


			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-03/chapter-02-screen-10')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues3.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>





				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>


				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '3') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues3(ansArr)
		}



	}
	const checkBoxChecked = (val, checked, quesId) => {
		if (quesId === '3') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues3Option1('checked');
				} else {
					setCheckedQues3Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues3Option2('checked');
				} else {
					setCheckedQues3Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues3Option3('checked');
				} else {
					setCheckedQues3Option3('');
				}
			}
			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues3Option4('checked');
				} else {
					setCheckedQues3Option4('');
				}
			}

			if (val === 'option5') {
				if (checked === true) {
					setCheckedQues3Option5('checked');
				} else {
					setCheckedQues3Option5('');
				}
			}

			if (val === 'option6') {
				if (checked === true) {
					setCheckedQues3Option6('checked');
				} else {
					setCheckedQues3Option6('');
				}
			}

			if (val === 'option7') {
				if (checked === true) {
					setCheckedQues3Option7('checked');
				} else {
					setCheckedQues3Option7('');
				}
			}

			if (val === 'option8') {
				if (checked === true) {
					setCheckedQues3Option8('checked');
				} else {
					setCheckedQues3Option8('');
				}
			}

			if (val === 'option9') {
				if (checked === true) {
					setCheckedQues3Option9('checked');
				} else {
					setCheckedQues3Option9('');
				}
			}

			if (val === 'option10') {
				if (checked === true) {
					setCheckedQues3Option10('checked');
				} else {
					setCheckedQues3Option10('');
				}
			}


			if (val === 'option11') {
				if (checked === true) {
					setCheckedQues3Option11('checked');
				} else {
					setCheckedQues3Option11('');
				}
			}


			if (val === 'option12') {
				if (checked === true) {
					setCheckedQues3Option12('checked');
				} else {
					setCheckedQues3Option12('');
				}
			}



		}



	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-2">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3">{t('engage')} </h3>
									<p className="mb-2  mt-3"><b>{t('M3chapter02Screen09Text1')} </b> </p>

									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check"
												type="checkbox" name="namecircle"
												id="mycirle1"
												defaultValue="option1"
												onClick={saveGenderBased}
												checked={checkedQues3Option1}
												data-quesId="3"

											/>
											<label className="form-check-label" htmlFor="mycirle1">	{t('M3chapter02Screen09Text2')} </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle" id="mycirle2"
												defaultValue="option2"
												onClick={saveGenderBased}
												checked={checkedQues3Option2}
												data-quesId="3"

											/>
											<label className="form-check-label" htmlFor="mycirle2">	{t('M3chapter02Screen09Text3')} </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle" id="mycirle3"
												defaultValue="option3"
												onClick={saveGenderBased}
												checked={checkedQues3Option3}
												data-quesId="3"

											/>
											<label className="form-check-label" htmlFor="mycirle3">{t('M3chapter02Screen09Text4')} </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle" id="mycirle4"
												defaultValue="option4"
												onClick={saveGenderBased}
												checked={checkedQues3Option4}
												data-quesId="3"

											/>
											<label className="form-check-label" htmlFor="mycirle4">{t('M3chapter02Screen09Text5')}  </label>
										</div>
									</div>


									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle" id="mycirle5"
												defaultValue="option5"
												onClick={saveGenderBased}
												checked={checkedQues3Option5}
												data-quesId="3"

											/>
											<label className="form-check-label" htmlFor="mycirle5">	{t('M3chapter02Screen09Text6')}  </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle" id="mycirle6"
												defaultValue="option6"
												onClick={saveGenderBased}
												checked={checkedQues3Option6}
												data-quesId="3"

											/>
											<label className="form-check-label" htmlFor="mycirle6">{t('M3chapter02Screen09Text7')}	  </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle" id="mycirle7"
												defaultValue="option7"
												onClick={saveGenderBased}
												checked={checkedQues3Option7}
												data-quesId="3"

											/>
											<label className="form-check-label" htmlFor="mycirle7">{t('M3chapter02Screen09Text8')}  </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle" id="mycirle8"
												defaultValue="option8"
												onClick={saveGenderBased}
												checked={checkedQues3Option8}
												data-quesId="3"

											/>
											<label className="form-check-label" htmlFor="mycirle8">{t('M3chapter02Screen09Text9')}  </label>
										</div>
									</div>




									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle" id="mycirle9"
												defaultValue="option9"
												onClick={saveGenderBased}
												checked={checkedQues3Option9}
												data-quesId="3"

											/>
											<label className="form-check-label" htmlFor="mycirle9">{t('M3chapter02Screen09Text10')}  </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle" id="mycirle10"
												defaultValue="option10"
												onClick={saveGenderBased}
												checked={checkedQues3Option10}
												data-quesId="3"
											/>
											<label className="form-check-label" htmlFor="mycirle10">{t('M3chapter02Screen09Text11')}  </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle" id="mycirle11"
												defaultValue="option11"
												onClick={saveGenderBased}
												checked={checkedQues3Option11}
												data-quesId="3"
											/>
											<label className="form-check-label" htmlFor="mycirle11">{t('M3chapter02Screen09Text12')}   </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle" id="mycirle12"
												defaultValue="option12"
												onClick={saveGenderBased}
												checked={checkedQues3Option12}
												data-quesId="3"

											/>
											<label className="form-check-label" htmlFor="mycirle12">{t('M3chapter02Screen09Text13')}  </label>
										</div>
									</div>

								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="80%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-2-9.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-02-screen-08" />
								{/* <NextBtn link="/module-03/chapter-02-screen-10" text="Go Next"/> */}

								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen09;
