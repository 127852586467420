import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter02Screen02N = () => {

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  const [checkedQues10Option1, setCheckedQues10Option1] = useState('');
  const [checkedQues10Option2, setCheckedQues10Option2] = useState('');

  const [checkedQues10Val, setCheckedQues10Val] = useState('');

  const [checkedQues9, setCheckedQues9] = useState([]);

  const [checkedQues9Option1, setCheckedQues9Option1] = useState('');
  const [checkedQues9Option2, setCheckedQues9Option2] = useState('');
  const [checkedQues9Option3, setCheckedQues9Option3] = useState('');
  const [checkedQues9Option4, setCheckedQues9Option4] = useState('');
  const [checkedQues9Option5, setCheckedQues9Option5] = useState('');

  const [checkedQues11, setCheckedQues11] = useState([]);

  const [checkedQues11Option1, setCheckedQues11Option1] = useState('');
  const [checkedQues11Option2, setCheckedQues11Option2] = useState('');
  const [checkedQues11Option3, setCheckedQues11Option3] = useState('');
  const [checkedQues11Option4, setCheckedQues11Option4] = useState('');
  const [checkedQues11Option5, setCheckedQues11Option5] = useState('');










  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e) => {
    const quesId = e.target.getAttribute("data-quesId");

    checkedVal(quesId, e.target.value)

  }

  const saveGenderBased = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    const res = e.target.value;
    const checked = e.target.checked;




    checkBoxChecked(res, checked, quesId);
    if (quesId === '9') {

      setCheckedQues9(checkedQues9 => [...checkedQues9, res]);

    }

    if (quesId === '10') {
      setCheckedQues10Val(checkedQues10 => [...checkedQues10, res]);

    }

    if (quesId === '11') {

      setCheckedQues11(checkedQues11 => [...checkedQues11, res]);

    }








  }



  const saveAns = () => {

    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques9": {
          "ans": checkedQues9.join(','),
          "ques_id": "9"
        },
        "ques10": {
          "ans": checkedQues10Val,
          "ques_id": "10"
        },

        "ques11": {
          "ans": checkedQues11.join(','),
          "ques_id": "11"
        },



      }
    });



    var config = {
      method: 'post',
      url: BaseURL + '/SexualityChapter02',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {

        if (response.status === "Token is Expired") {
          window.localStorage.clear();
          navigate('/login')
        }
        if (response.data.message === "Token Invalid") {
          window.localStorage.clear();
          navigate('/login')
        }

        if (response.data.success === true) {
          navigate('/module-03/chapter-02-screen-03')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  console.log(checkedQues10Val);
  const BtnDisabled = () => {
    if (checkedQues10Val !== '' && checkedQues9.length > 0   && checkedQues11.length > 0) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button  className="button_su_inner" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
              <span className="button_text_container">
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>


        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')}<i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param, ans) => {


    if (param === '10') {

      if (ans === 'option1') {
        setCheckedQues10Option1('checked')
        setCheckedQues10Option2('')

      } else if (ans === 'option2') {
        setCheckedQues10Option1('')
        setCheckedQues10Option2('checked')

      }
      setCheckedQues10Val(ans)
    }

    if (param === '9') {
      const ansArr = ans.split(',');
      ansArr.map((el) => {
        const checked = true;
        checkBoxChecked(el, checked, param);
      })
      setCheckedQues9(ansArr)
    }

    if (param === '11') {
      const ansArr = ans.split(',');
      ansArr.map((el) => {
        const checked = true;
        checkBoxChecked(el, checked, param);
      })
      setCheckedQues11(ansArr)
    }






  }
  const checkBoxChecked = (val, checked, quesId) => {

    if (quesId === '9') {
      if (val === 'option1') {
        if (checked === true) {
          setCheckedQues9Option1('checked');
        } else {
          setCheckedQues9Option1('');
        }
      }
      if (val === 'option2') {
        if (checked === true) {
          setCheckedQues9Option2('checked');
        } else {
          setCheckedQues9Option2('');
        }
      }
      if (val === 'option3') {
        if (checked === true) {
          setCheckedQues9Option3('checked');
        } else {
          setCheckedQues9Option3('');
        }
      }
      if (val === 'option4') {
        if (checked === true) {
          setCheckedQues9Option4('checked');
        } else {
          setCheckedQues9Option4('');
        }
      }

      if (val === 'option5') {
        if (checked === true) {
          setCheckedQues9Option5('checked');
        } else {
          setCheckedQues9Option5('');
        }
      }







    }


    if (quesId === '11') {
      if (val === 'option1') {
        if (checked === true) {
          setCheckedQues11Option1('checked');
        } else {
          setCheckedQues11Option1('');
        }
      }
      if (val === 'option2') {
        if (checked === true) {
          setCheckedQues11Option2('checked');
        } else {
          setCheckedQues11Option2('');
        }
      }
      if (val === 'option3') {
        if (checked === true) {
          setCheckedQues11Option3('checked');
        } else {
          setCheckedQues11Option3('');
        }
      }
      if (val === 'option4') {
        if (checked === true) {
          setCheckedQues11Option4('checked');
        } else {
          setCheckedQues11Option4('');
        }
      }

      if (val === 'option5') {
        if (checked === true) {
          setCheckedQues11Option5('checked');
        } else {
          setCheckedQues11Option5('');
        }
      }







    }




  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/sexualityChapter02Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(), result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])



  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
            <Header leftChapter="3/4" percentage="78" star="50" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-between pt-20 mr-3 mt-m-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >


<p className="mb-2 mt-3 pn"><span><b>1.</b></span><b>{t('M3chapter02Screen02NText1')}</b> </p>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircle"
                        id="mycirle1"
                        defaultValue="option1"
                        onClick={saveGenderBased}
                        checked={checkedQues9Option1}
                        data-quesId="9"
                      />
                      <label className="form-check-label" htmlFor="mycirle1">{t('M3chapter02Screen02NText2')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircle"
                        id="mycirle2"
                        defaultValue="option2"
                        onClick={saveGenderBased}
                        checked={checkedQues9Option2}
                        data-quesId="9"
                      />
                      <label className="form-check-label" htmlFor="mycirle2">{t('M3chapter02Screen02NText3')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircle"
                        id="mycirle3"
                        defaultValue="option3"
                        onClick={saveGenderBased}
                        checked={checkedQues9Option3}
                        data-quesId="9"

                      />
                      <label className="form-check-label" htmlFor="mycirle3"> {t('M3chapter02Screen02NText4')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircle"
                        id="mycirle4"
                        defaultValue="option4"
                        onClick={saveGenderBased}
                        checked={checkedQues9Option4}
                        data-quesId="9"

                      />
                      <label className="form-check-label" htmlFor="mycirle4"> {t('M3chapter02Screen02NText5')} </label>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircle"
                        id="mycirle4"
                        defaultValue="option5"
                        onClick={saveGenderBased}
                        checked={checkedQues9Option5}
                        data-quesId="9"

                      />
                      <label className="form-check-label" htmlFor="mycirle4"> {t('M3chapter02Screen02NText6')} </label>
                    </div>
                  </div>





                  <p className="mb-2 mt-3 pn"><span><b>2</b></span><b> {t('M3chapter02Screen02NText7')}</b> </p>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio"
                        name="namecircle3"
                        id="mycirle9"
                        defaultValue="option1"
                        onClick={saveRadio}
                        checked={checkedQues10Option1}
                        data-quesId="10"

                      />
                      <label className="form-check-label" htmlFor="mycirle9"> {t('M3chapter02Screen02NText8')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio"
                        name="namecircle3"
                        id="mycirle10"
                        defaultValue="option2"
                        onClick={saveRadio}
                        checked={checkedQues10Option2}
                        data-quesId="10"

                      />
                      <label className="form-check-label" htmlFor="mycirle10"> {t('M3chapter02Screen02NText9')} </label>
                    </div>
                  </div>






                  <p className="mb-2 mt-3 pn"><span><b>3.</b></span><b>{t('M3chapter02Screen02NText10')}</b> </p>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircle"
                        id="mycirle1"
                        defaultValue="option1"
                        onClick={saveGenderBased}
                        checked={checkedQues11Option1}
                        data-quesId="11"
                      />
                      <label className="form-check-label" htmlFor="mycirle1">{t('M3chapter02Screen02NText11')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircle"
                        id="mycirle2"
                        defaultValue="option2"
                        onClick={saveGenderBased}
                        checked={checkedQues11Option2}
                        data-quesId="11"
                      />
                      <label className="form-check-label" htmlFor="mycirle2">{t('M3chapter02Screen02NText12')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircle"
                        id="mycirle3"
                        defaultValue="option3"
                        onClick={saveGenderBased}
                        checked={checkedQues11Option3}
                        data-quesId="11"

                      />
                      <label className="form-check-label" htmlFor="mycirle3"> {t('M3chapter02Screen02NText13')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircle"
                        id="mycirle4"
                        defaultValue="option4"
                        onClick={saveGenderBased}
                        checked={checkedQues11Option4}
                        data-quesId="11"

                      />
                      <label className="form-check-label" htmlFor="mycirle4"> {t('M3chapter02Screen02NText14')} </label>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircle"
                        id="mycirle4"
                        defaultValue="option5"
                        onClick={saveGenderBased}
                        checked={checkedQues11Option5}
                        data-quesId="11"

                      />
                      <label className="form-check-label" htmlFor="mycirle4"> {t('M3chapter02Screen02NText6')} </label>
                    </div>
                  </div>













                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">

                <PrevBtn link="/module-03/chapter-02-screen-02" />
                {/* <NextBtn link="/module-06/chapter-04-screen-04" text="Go Next"/> */}
                <BtnDisabled />

              </div>
            </div>
          </div>
        </div>
      </div>

        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-xl">
                <div className="modal-content modal-bg-blue">
                  <div className="modal-body">
                    <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

                    <p className="text-white text-left">{t('M3chapter02Screen02NText16')}
                    </p>

                    <ul className="text-white">
                      <li>{t('M3chapter02Screen02NText17')}</li>
                      <li>{t('M3chapter02Screen02NText18')}</li>
                      <li>{t('M3chapter02Screen02NText19')}</li>
                      <li>{t('M3chapter02Screen02NText20')}</li>
                    </ul>

                    <div class=" d-flex justify-content-center mt-3">
                      <button className="button_su" data-bs-dismiss="modal" aria-label="Close">
                        <span className="su_button_circle desplode-circle">
                        </span>

                        <NavLink to="" onClick={saveAns}  className="button_su_inner"><span className="button_text_container"> {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" /></span> </NavLink>
                      </button>
                    </div>




                  </div>
                </div>
              </div>
            </div>

    </>
  );
};

export default Chapter02Screen02N;
