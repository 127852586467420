import React from "react";
import { NavLink } from "react-router-dom";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";


const Chapter03Screen05 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/5" percentage="52" star="32" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-5">
								<div
									className="col-lg-9 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.1s"
								>
									<p className="text-justify">
										{t('M2chapter03screen05Text1')}{" "}
									</p>

									<a
										href="https://www.youtube.com/watch?v=XJqpsdqb1k0"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>जज करें है</p>
											</div>
										</div>
									</a>

								</div>
								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="52%"
											src="/assets/img/md1/md1-shap-3-6.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-03-screen-04" />

								<NextBtn
									link="/module-02/chapter-03-screen-06"
									text={t('CommonBtnGoNext')}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen05;
