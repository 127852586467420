import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter04Screen06 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2 hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="85" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mr-3 mt-m-3">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<h3 className="h2 mt-3">{t('learn')}</h3>
									<h3 className="h3 mt-3">{t('M6chapter04screen06Text1')}</h3>

									<p className="mb-2 mt-3">{t('M6chapter04screen06Text2')} <a className="display-inline" href="https://inbreakthrough.org/focus-area/building-gender-equitable-behaviours-in-adolescents-through-government-partnerships/" target="_blank"> <b>{t('readmore')}</b> </a> </p>


									<p className="mb-2 mt-3">{t('M6chapter04screen06Text3')}
										<a className="display-inline" href="https://inbreakthrough.org/campaigns/" target="_blank">{t('M6chapter04screen06Text4')} </a>{t('M6chapter04screen06Text5')}
										<a className="display-inline" href="https://www.youtube.com/c/breakthroughtv" target="_blank">{t('M6chapter04screen06Text6')} </a>
									</p>
									<p className="mb-2 mt-3">{t('M6chapter04screen06Text7')}</p>

									<div className="social-word">
										<a href="https://inbreakthrough.org/" target="_blank" class="btn btn-outline-danger"><i class="bi bi-suit-heart"></i> {t('website')} </a>
										<a href="https://www.facebook.com/BreakthroughIN" target="_blank" class="btn btn-outline-danger"><i class="bi bi-facebook"></i> {t('facebook')} </a>
										<a href="https://twitter.com/INBreakthrough" target="_blank" class="btn btn-outline-danger"><i class="bi bi-twitter"></i> {t('twitter')}  </a>
										<a href="https://www.instagram.com/INBreakthrough/" target="_blank" class="btn btn-outline-danger"><i class="bi bi-instagram"></i> {t('instagram')} </a>
										<a href="https://www.linkedin.com/company/breakthrough-india?originalSubdomain=in" target="_blank" class="btn btn-outline-danger"><i class="bi bi-linkedin"></i> {t('linkdin')} </a>
										<a href="https://www.youtube.com/user/LetsBreakthrough" target="_blank" class="btn btn-outline-danger"><i class="bi bi-youtube"></i> {t('youtube')} </a>
									</div>



								</div>

								<div className="col-lg-3 mt-m-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">
									<center>
										<img
											width="50%"
											src="/assets/img/md6/md-60chap-2-3.png"
											alt=""
										/>
									</center>
								</div>


							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-06/chapter-04-screen-05" />
								<NextBtn link="/module-06/chapter-04-screen-07" text={t('CommonBtnGoNext')} />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen06;
