import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import PrevBtn from "../../../common/PrevBtn";
import Header from '../../../header/Header';
import { useDrop } from "react-dnd";
// import Image from './Image';

import Chapter03Screen06_1 from './Chapter03Screen06_1';
import axios from 'axios';
import BaseURL from '../../../config/Baseurl';
import { useEffect } from 'react';
import NextBtn from '../../../common/NextBtn'
import { useDrag } from "react-dnd";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen06 = () => {
	const { t, i18n } = useTranslation();

	let name1 = t('M3chapter03Screen06Text3');
	let name2 = t('M3chapter03Screen06Text4');
	let name3 = t('M3chapter03Screen06Text5');
	let name4 = t('M3chapter03Screen06Text6');
	let name5 = t('M3chapter03Screen06Text7');
	let name6 = t('M3chapter03Screen06Text8');
	let name7 = t('M3chapter03Screen06Text9');
	let name8 = t('M3chapter03Screen06Text10');

	const navigate = useNavigate();
	const [PictureList, setPictureList] = useState([
		{ "id": "1", "name": name1 },
		{ "id": "2", "name": name2 },
		{ "id": "3", "name": name3 },
		{ "id": "4", "name": name4 },
		{ "id": "5", "name": name5 },
		{ "id": "6", "name": name6 },
		{ "id": "7", "name": name7 },
		{ "id": "8", "name": name8 },

	]);




	const [agreeArray, setAgreeArray] = useState([]);
	const [disagreeArray, setDisagreeArray] = useState([]);
	const [unsureArray, setUnsureArray] = useState([]);
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const [{ isUnsureOver }, unsuredrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addUnsureArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));


	const [{ isOver }, agreedrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addAgreeArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const [{ isShasOver }, disagreedrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addDisagreeArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));


	// Add To Array Samar

	const addUnsureArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setUnsureArray((unsureArray) => [...unsureArray, picture_List[0]]);
	};


	const addAgreeArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setAgreeArray((agreeArray) => [...agreeArray, picture_List[0]]);
	};

	// Add To sahas Array
	const addDisagreeArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setDisagreeArray((disagreeArray) => [...disagreeArray, picture_List[0]]);
	};


	const onItemRemoveAgree = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = agreeArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setAgreeArray(agreeArray =>
			agreeArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};

	const onItemRemoveUnsure = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = unsureArray.filter((item) => item.id === id);
		console.log(_item);
		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setUnsureArray(unsureArray =>
			unsureArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};


	const onItemRemoveDisagree = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = disagreeArray.filter((item) => item.id === id);
		console.log(_item);
		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setDisagreeArray(disagreeArray =>
			disagreeArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques3": {
					"ans": JSON.stringify(agreeArray),
					"ans2": JSON.stringify(disagreeArray),
					"ans3": JSON.stringify(unsureArray),
					"ques_id": "3"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-03/chapter-03-screen-09');
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,

			"ques_id": "3"
		});

		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				console.log(response.data);
				if (response.data.success === true) {
					const ans = JSON.parse(response.data.data.chapter.ans)
					const ans2 = JSON.parse(response.data.data.chapter.ans2)
					const ans3 = JSON.parse(response.data.data.chapter.ans3)
					console.log(ans);
					setAgreeArray(ans);
					setDisagreeArray(ans2);
					setUnsureArray(ans3);
					if (ans.length > 0) {
						ans.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans2.length > 0) {
						ans2.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}


				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);


	const BtnDisabled = () => {
		if (disagreeArray.length !== 0 && agreeArray.length !== 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	return (
		<>
			<div className="wrapper page-auto bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-1">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3">{t('engage')}: </h3>

									<p className="mt-2">{t('M3chapter03Screen06Text1')} </p>
									<p className="mt-2"><b>{t('M3chapter03Screen06Text2')}</b> </p>

									<table className="table table-bordered table-dark table-red table-white table-padding mt-4">
										<tbody>
											<tr>
												<td className="td-green" style={{textAlign:"center"}}>{t('agree')}</td>
												<td className="td-red" style={{textAlign:"center"}}>{t('disagree')}</td>
												<td className="td-yellow" style={{textAlign:"center"}}>{t('unsure')}</td>

											</tr>
											<tr className="drag-table table-width-set">
												<td>
													<div className="dragarea" ref={agreedrop}>
														<ul>

															{agreeArray.map((e) => {

																return (
																	<>
																		<li>

																			{e.name}
																			<button
																				onClick={onItemRemoveAgree}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			></button>
																		</li>

																	</>
																)


															})}
														</ul>
													</div>
												</td>
												<td>
													<div className="dragarea" ref={disagreedrop}>
														<ul>

															{
																disagreeArray.map((e) => {

																return (
																	<>
																		<li>

																			{e.name}
																			<button
																				onClick={onItemRemoveDisagree}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			></button>
																		</li>

																	</>
																)

															})}
														</ul>
													</div>
												</td>
												<td>
													<div className="dragarea" ref={unsuredrop}>
														<ul>

															{unsureArray.map((e) => {

																return (
																	<>
																		<li>

																			{e.name}
																			<button
																				onClick={onItemRemoveUnsure}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			></button>
																		</li>

																	</>
																)


															})}
														</ul>
													</div>
												</td>
											</tr>
										</tbody>
									</table>
								</div>

								<div
									className="col-lg-6 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<ul className="list-style-set list-cursor list-circle mt-3 mb-3">
										{
											PictureList.map((e) => {


												return (

													<Chapter03Screen06_1 key={e.id} id={e.id} imageName={e.name} />


												)

											})
										}
									</ul>
								</div>



								{/* <div
					className="col-lg-10 wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<center>
					<img
						width="100%"
						draggable="false"
						src="/assets/img/md5/chap02-2/4.jpg"
						alt=""
					/>
					</center>
				</div>

					*/}



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-03-screen-05" />
								{/* <NextBtn link="/module-03/chapter-03-screen-07" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen06;
