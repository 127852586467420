import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import BaseURL, { AdminUrl } from "../../../config/Baseurl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter02Screen04 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [checkedQues9, setCheckedQues9] = useState();
	const [checkedQues9_2, setCheckedQues9_2] = useState();
	const [checkedQues9_3, setCheckedQues9_3] = useState();
	const [checkedQues9_4, setCheckedQues9_4] = useState();
	const [checkedQues9_5, setCheckedQues9_5] = useState();
	const [images, setImages] = useState([]);
	const [imageurl1, setImageUrl1] = useState();
	const [imageurl2, setImageUrl2] = useState();
	const [imageurl3, setImageUrl3] = useState();
	const [imageurl4, setImageUrl4] = useState();
	const [imageurl5, setImageUrl5] = useState();


	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const onImageChange1 = async (e) => {

		const imageno = e.target.getAttribute("data-image");


		setImages([...e.target.files]);



		const questId = '9';

		const user_details = JSON.parse(localStorage.getItem("user_details"));
		const user_id = user_details.user_id;
		const files = e.target.files;
		const formData = new FormData();


		formData.append("image1", files[0]);


		formData.append("user_id", user_id);
		formData.append("ques_id", questId);

		// console.log(user_details.user_id);

		fetch(BaseURL + "/genderImageUpload1", {
			method: "POST",
			body: formData,
		}).then((res) => {

			res.json().then((result) => {


			});
		});

		// console.log(questId);
	};

	const onImageChange2 = async (e) => {

		const imageno = e.target.getAttribute("data-image");


		setImages([...e.target.files]);



		const questId = '9';

		const user_details = JSON.parse(localStorage.getItem("user_details"));
		const user_id = user_details.user_id;
		const files = e.target.files;
		const formData = new FormData();


		formData.append("image1", files[0]);


		formData.append("user_id", user_id);
		formData.append("ques_id", questId);

		// console.log(user_details.user_id);

		fetch(BaseURL + "/genderImageUpload2", {
			method: "POST",
			body: formData,
		}).then((res) => {

			res.json().then((result) => {


			});
		});

		// console.log(questId);
	};

	const onImageChange3 = async (e) => {

		const imageno = e.target.getAttribute("data-image");


		setImages([...e.target.files]);



		const questId = '9';

		const user_details = JSON.parse(localStorage.getItem("user_details"));
		const user_id = user_details.user_id;
		const files = e.target.files;
		const formData = new FormData();


		formData.append("image1", files[0]);


		formData.append("user_id", user_id);
		formData.append("ques_id", questId);

		// console.log(user_details.user_id);

		fetch(BaseURL + "/genderImageUpload3", {
			method: "POST",
			body: formData,
		}).then((res) => {

			res.json().then((result) => {


			});
		});

		// console.log(questId);
	};

	const onImageChange4 = async (e) => {

		const imageno = e.target.getAttribute("data-image");


		setImages([...e.target.files]);



		const questId = '9';

		const user_details = JSON.parse(localStorage.getItem("user_details"));
		const user_id = user_details.user_id;
		const files = e.target.files;
		const formData = new FormData();


		formData.append("image1", files[0]);


		formData.append("user_id", user_id);
		formData.append("ques_id", questId);

		// console.log(user_details.user_id);

		fetch(BaseURL + "/genderImageUpload4", {
			method: "POST",
			body: formData,
		}).then((res) => {

			res.json().then((result) => {


			});
		});

		// console.log(questId);
	};

	const onImageChange5 = async (e) => {

		const imageno = e.target.getAttribute("data-image");


		setImages([...e.target.files]);



		const questId = '9';

		const user_details = JSON.parse(localStorage.getItem("user_details"));
		const user_id = user_details.user_id;
		const files = e.target.files;
		const formData = new FormData();


		formData.append("image1", files[0]);


		formData.append("user_id", user_id);
		formData.append("ques_id", questId);

		// console.log(user_details.user_id);

		fetch(BaseURL + "/genderImageUpload5", {
			method: "POST",
			body: formData,
		}).then((res) => {

			res.json().then((result) => {


			});
		});

		// console.log(questId);
	};














	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": checkedQues9,
					"ans2": checkedQues9_2,
					"ans3": checkedQues9_3,
					"ans4": checkedQues9_4,
					"ans5": checkedQues9_5,

					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-02/chapter-02-screen-05')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans, ans2, ans3, ans4, ans5) => {


		if (param === '9') {

			setCheckedQues9(ans)
			setCheckedQues9_2(ans2)
			setCheckedQues9_3(ans3)
			setCheckedQues9_4(ans4)
			setCheckedQues9_5(ans5)
		}




	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						setImageUrl1(result.image1);
						setImageUrl2(result.image2);
						setImageUrl3(result.image3);
						setImageUrl4(result.image4);
						setImageUrl5(result.image5);
						checkedVal(result.ques_id.toString(), result.ans, result.ans2, result.ans3, result.ans4, result.ans5)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues9 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};





	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/5" percentage="24" star="11" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-center pt-20 mt-3">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h2">{t('M2chapter02screen04Text1')}</h2>
									<p>
										{t('M2chapter02screen04Text2')}
										
									</p>

									<div className="row">
										<div className="col-lg-9">
											<div className="mb-4 positon-submit">
												<textarea
													className="form-control "
													rows={1}
													placeholder="Write Something here....."
													onChange={(e) => setCheckedQues9(e.target.value)}
													data-quesId="9"
													defaultValue={checkedQues9}
												/>

											</div>
										</div>
										<div className="col-lg-3">
											<div class="file-input">
												<input type="file" name="file-input" id="file-input" data-image="image1" class="file-input__input" onChange={onImageChange1} />
												<label class="file-input__label" for="file-input">
													<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" class="svg-inline--fa fa-upload fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
														<path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
													</svg>
													<span>{t('UploadBtn')}</span></label>
												<a target="__blank" href={AdminUrl + "/public/mascul/" + imageurl1}>{imageurl1}</a>

											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-lg-9">
											<div className="mb-4 positon-submit">
												<textarea
													className="form-control "
													rows={1}
													placeholder="Write Something here....."
													onChange={(e) => setCheckedQues9_2(e.target.value)}
													data-quesId="9"
													defaultValue={checkedQues9_2}
												/>

											</div>
										</div>
										<div className="col-lg-3">
											<div class="file-input">
												<input type="file" name="file-input" id="file-input2" class="file-input__input" data-image="image2" onChange={onImageChange2} />
												<label class="file-input__label" for="file-input2">
													<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" class="svg-inline--fa fa-upload fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
														<path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
													</svg>
													<span>{t('UploadBtn')}</span></label>
												<a target="__blank" href={AdminUrl + "/public/mascul/" + imageurl2}>{imageurl2}</a>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-lg-9">
											<div className="mb-4 positon-submit">
												<textarea
													className="form-control "
													rows={1}
													placeholder="Write Something here....."
													onChange={(e) => setCheckedQues9_3(e.target.value)}
													data-quesId="9"
													defaultValue={checkedQues9_3}
												/>

											</div>
										</div>
										<div className="col-lg-3">
											<div class="file-input">
												<input type="file" name="file-input" id="file-input3" class="file-input__input" data-image="image3" onChange={onImageChange3} />
												<label class="file-input__label" for="file-input3">
													<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" class="svg-inline--fa fa-upload fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
														<path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
													</svg>
													<span>{t('UploadBtn')}</span></label>
												<a target="__blank" href={AdminUrl + "/public/mascul/" + imageurl3}>{imageurl3}</a>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-lg-9">
											<div className="mb-4 positon-submit">
												<textarea
													className="form-control "
													rows={1}
													placeholder="Write Something here....."
													onChange={(e) => setCheckedQues9_4(e.target.value)}
													data-quesId="9"
													defaultValue={checkedQues9_4}
												/>

											</div>
										</div>
										<div className="col-lg-3">
											<div class="file-input">
												<input type="file" name="file-input" id="file-input4" class="file-input__input" data-image="image4" onChange={onImageChange4} />
												<label class="file-input__label" for="file-input4">
													<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" class="svg-inline--fa fa-upload fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
														<path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
													</svg>
													<span>{t('UploadBtn')}</span></label>
												<a target="__blank" href={AdminUrl + "/public/mascul/" + imageurl4}>{imageurl4}</a>
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-lg-9">
											<div className="mb-4 positon-submit">
												<textarea
													className="form-control "
													rows={1}
													placeholder="Write Something here....."
													onChange={(e) => setCheckedQues9_5(e.target.value)}
													data-quesId="9"
													defaultValue={checkedQues9_5}
												/>

											</div>
										</div>
										<div className="col-lg-3">
											<div class="file-input">
												<input type="file" name="file-input" id="file-input5" class="file-input__input" data-image="image5" onChange={onImageChange5} />
												<label class="file-input__label" for="file-input5">
													<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" class="svg-inline--fa fa-upload fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
														<path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
													</svg>
													<span>{t('UploadBtn')}</span></label>
												<a target="__blank" href={AdminUrl + "/public/mascul/" + imageurl5}>{imageurl5}</a>
											</div>
										</div>
									</div>






								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="41%"
											src="/assets/img/md1/md1-shap-2-4.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-02-screen-03" />

								{/* <NextBtn link="/module-01/chapter-02-screen-05" text="Go Next" classtype=""/> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen04;