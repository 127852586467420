import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';




const Chapter04Screen03 = () => {
	const { t, i18n } = useTranslation();



	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="36" />
					</div>
				</div>

				<div className="d-flex">
					<Sidebar />


					<div className="data-adjust">
						<div className="container-fluid cf-set">


							<div className="row align-items-center justify-content-between pt-20 mt-3">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3>{t('learn')}</h3>
									<h4 className="mb-3"> <b> {t('M5chapter04Screen3Text1')}  </b></h4>

									<p className=" mt-1">{t('M5chapter04Screen3Text2')} </p>

									<a
										href="https://ishr.ch/about-human-rights/who-protects-human-rights/"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M5chapter04Screen3Text3')}  </p>
											</div>
										</div>
									</a>
									<p className=" mt-1">{t('M5chapter04Screen3Text4')} </p>



									<a
										href="https://www.youtube.com/watch?v=SthbN5Nc9NY&list=PLWqowEyMVW8ZbdwqJf12V4qkbtFRNdYrE&index=25"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M5chapter04Screen3Text5')} </p>
											</div>
										</div>
									</a>

									<a
										href="https://www.hrw.org/news/2020/04/15/india-activists-detained-peaceful-dissent"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M5chapter04Screen3Text6')}  </p>
											</div>
										</div>
									</a>



								</div>
							</div>


						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-05/chapter-04-screen-02" />




									<NextBtn link="/module-05/chapter-04-screen-04" text={t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen03;
