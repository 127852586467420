import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";

import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen02 = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [checkedQues1, setCheckedQues1] = useState([]);
  const [checkedQues1Option1, setCheckedQues1Option1] = useState('');
  const [checkedQues1Option2, setCheckedQues1Option2] = useState('');
  const [checkedQues1Option3, setCheckedQues1Option3] = useState('');
  const [checkedQues1Option4, setCheckedQues1Option4] = useState('');
  const [checkedQues1Option5, setCheckedQues1Option5] = useState('');
  const [checkedQues1Option6, setCheckedQues1Option6] = useState('');

  const [checkedQues2, setCheckedQues2] = useState([]);
  const [checkedQues2Option1, setCheckedQues2Option1] = useState('');
  const [checkedQues2Option2, setCheckedQues2Option2] = useState('');
  const [checkedQues2Option3, setCheckedQues2Option3] = useState('');
  const [checkedQues2Option4, setCheckedQues2Option4] = useState('');
  const [checkedQues2Option5, setCheckedQues2Option5] = useState('');
  const [checkedQues2Option6, setCheckedQues2Option6] = useState('');
  const [checkedQues2Option7, setCheckedQues2Option7] = useState('');

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    // alert(quesId);
    checkedVal(quesId,e.target.value)
  }
  const saveGenderBased = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    const res = e.target.value;
    const checked = e.target.checked;
    checkBoxChecked(res,checked,quesId);
    if(quesId === '1')
    {
      setCheckedQues1(checkedQues1 => [...checkedQues1, res]);
    }

    if(quesId === '2')
    {
      setCheckedQues2(checkedQues2 => [...checkedQues2, res]);
    }

  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans":  checkedQues1.join(','),
          "ques_id": "1"
        },
        "ques2": {
          "ans": checkedQues2.join(','),
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques10": {
          "ans":"",
          "ques_id": "10"
        },
        "ques11": {
          "ans":"",
          "ques_id": "11"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/HumanRightsChapter04',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-05/chapter-04-screen-03')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues1.length > 0 && checkedQues2.length > 0) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {
    if (param === '1') {
      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues1(ansArr)
    }

    if (param === '2') {
      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues2(ansArr)
    }


  }
  const checkBoxChecked = (val,checked,quesId)=>{
    if(quesId === '1')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues1Option1('checked');
        }else{
          setCheckedQues1Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues1Option2('checked');
        }else{
          setCheckedQues1Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues1Option3('checked');
        }else{
          setCheckedQues1Option3('');
        }
      }

      if(val === 'option4')
      {
        if(checked === true)
        {
          setCheckedQues1Option4('checked');
        }else{
          setCheckedQues1Option4('');
        }
      }

      if(val === 'option5')
      {
        if(checked === true)
        {
          setCheckedQues1Option5('checked');
        }else{
          setCheckedQues1Option5('');
        }
      }

      if(val === 'option6')
      {
        if(checked === true)
        {
          setCheckedQues1Option6('checked');
        }else{
          setCheckedQues1Option6('');
        }
      }



    }

    if(quesId === '2')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues2Option1('checked');
        }else{
          setCheckedQues2Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues2Option2('checked');
        }else{
          setCheckedQues2Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues2Option3('checked');
        }else{
          setCheckedQues2Option3('');
        }
      }

      if(val === 'option4')
      {
        if(checked === true)
        {
          setCheckedQues2Option4('checked');
        }else{
          setCheckedQues2Option4('');
        }
      }

      if(val === 'option5')
      {
        if(checked === true)
        {
          setCheckedQues2Option5('checked');
        }else{
          setCheckedQues2Option5('');
        }
      }

      if(val === 'option6')
      {
        if(checked === true)
        {
          setCheckedQues2Option6('checked');
        }else{
          setCheckedQues2Option6('');
        }
      }

      if(val === 'option7')
      {
        if(checked === true)
        {
          setCheckedQues2Option7('checked');
        }else{
          setCheckedQues2Option7('');
        }
      }




    }

  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/HumanRightsChapter04Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])



  return (
    <>
      <div className="wrapper md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="3/4" percentage="75" star="36" />
          </div>
        </div>

        <div className="d-flex">
<Sidebar/>

        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-between pt-20 mt-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h3>{t('reflect')}</h3>
              <h4 className="mb-2 pn"> <b>{t('M5chapter04Screen2Text1')}</b></h4>

                <div className="d-flex ">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle"
                    defaultValue="option1"


                    onClick={saveGenderBased}
                    checked={checkedQues1Option1}
                    data-quesId="1"
                    />
                    <label className="form-check-label" htmlFor="mycirle">{t('M5chapter04Screen2Text3')} </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle2"
                     defaultValue="option2"


                        onClick={saveGenderBased}
                        checked={checkedQues1Option2}
                        data-quesId="1"

                     />
                    <label className="form-check-label" htmlFor="mycirle2">{t('M5chapter04Screen2Text4')} </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle3"

                    defaultValue="option3"


                      onClick={saveGenderBased}
                      checked={checkedQues1Option3}
                      data-quesId="1"
                    />
                    <label className="form-check-label" htmlFor="mycirle3">{t('M5chapter04Screen2Text5')} </label>
                  </div>
                </div>

                <div className="d-flex ">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle4"
                    defaultValue="option4"



                  onClick={saveGenderBased}
                  checked={checkedQues1Option4}
                  data-quesId="1"
                    />
                    <label className="form-check-label" htmlFor="mycirle4">{t('M5chapter04Screen2Text6')} </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle5"

                     defaultValue="option5"


                      onClick={saveGenderBased}
                      checked={checkedQues1Option5}
                      data-quesId="1"
                     />
                    <label className="form-check-label" htmlFor="mycirle5">{t('M5chapter04Screen2Text7')} </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle6"

                    defaultValue="option6"


                  onClick={saveGenderBased}
                  checked={checkedQues1Option6}
                  data-quesId="1"
                    />
                    <label className="form-check-label" htmlFor="mycirle6"> {t('M5chapter04Screen2Text8')} </label>
                  </div>
                </div>





                <h4 className="mb-2 pn mt-2">  <b>{t('M5chapter04Screen2Text9')}</b></h4>

                <div className="d-flex ">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle7"
                    defaultValue="option1"
                    onClick={saveGenderBased}
                    checked={checkedQues2Option1}
                    data-quesId="2"
                     />
                    <label className="form-check-label" htmlFor="mycirle7">{t('M5chapter04Screen2Text10')} </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle8"

                    defaultValue="option2"
                    onClick={saveGenderBased}
                    checked={checkedQues2Option2}
                    data-quesId="2"
                    />
                    <label className="form-check-label" htmlFor="mycirle8">{t('M5chapter04Screen2Text11')}</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle9"

                    defaultValue="option3"
                    onClick={saveGenderBased}
                    checked={checkedQues2Option3}
                    data-quesId="2"
                    />
                    <label className="form-check-label" htmlFor="mycirle9"> {t('M5chapter04Screen2Text12')}</label>
                  </div>
                </div>

                <div className="d-flex ">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle10"

                     defaultValue="option4"
                    onClick={saveGenderBased}
                    checked={checkedQues2Option4}
                    data-quesId="2"
                     />
                    <label className="form-check-label" htmlFor="mycirle10">{t('M5chapter04Screen2Text13')}</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle11"

                    defaultValue="option5"
                    onClick={saveGenderBased}
                    checked={checkedQues2Option5}
                    data-quesId="2"
                    />
                    <label className="form-check-label" htmlFor="mycirle11">{t('M5chapter04Screen2Text14')}</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle12"

                    defaultValue="option6"
                    onClick={saveGenderBased}
                    checked={checkedQues2Option6}
                    data-quesId="2"

                     />
                    <label className="form-check-label" htmlFor="mycirle12">{t('M5chapter04Screen2Text15')} </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle13"

                    defaultValue="option7"
                    onClick={saveGenderBased}
                    checked={checkedQues2Option7}
                    data-quesId="2"
                     />
                    <label className="form-check-label" htmlFor="mycirle13">{t('M5chapter04Screen2Text16')}</label>
                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-05/chapter-04-screen-01" />
                {/* <NextBtn link="/module-05/chapter-04-screen-03" text="Go Next"/> */}
                <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter04Screen02;
