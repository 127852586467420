import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen24 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-5">
								<div
									className="col-lg-7 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3">{t('M3chapter04Screen24Text1')} </h3>
									<ul className="list-style-set list-circle mt-3 mb-3">
										<li>	{t('M3chapter04Screen24Text2')}  </li>
										<li>	{t('M3chapter04Screen24Text3')} </li>
										<li>	{t('M3chapter04Screen24Text4')}  </li>
									</ul>
								</div>
								<div
									className="col-lg-5 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="100%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-4-24.png"
											alt=""
										/>
									</center>
								</div>


							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-23n" />
								<NextBtn link="/module-03/chapter-04-screen-24n" text={t('CommonBtnGoNext')} />




							</div>
						</div>
					</div>
				</div>
			</div>



		</>
	);
};

export default Chapter04Screen24;
