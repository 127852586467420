import React from "react";

import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { saveViewPages } from '../../../../api/api';
import { NavLink, useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';

const Chapter01IntroScreen = () => {
	const { t, i18n } = useTranslation();

	const user_details = JSON.parse(localStorage.getItem('user_details'));
    const navigate = useNavigate();
    const handleSavePage = (event) => {


        try {
            const key_name = event.target.getAttribute("data-key_name");
            const key_url = event.target.getAttribute("data-key_url");
			localStorage.setItem('key_name', key_name);
            const newUser = { user_id: user_details.user_id, key_name: key_name, url: key_url };
            const createdUser = saveViewPages(newUser);
            navigate('/module-01/chapter-01-screen-01');
        } catch (error) {
            console.error("Error creating user:", error);
        }
    };

	return (
		<>

			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="1" star="0" />
					</div>
				</div>

				<div className="d-flex">

					<Sidebar/>
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between">
								<div
									className="col-lg-9 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h4>
										{" "}
										<b>
										<h1>{t('M1chapter01introscreenTitle')}</h1>

										</b>{" "}
									</h4>
									<div className="para-color-box">
										{/* <p>
											<b>{t('M1chapter01introscreenText1')}</b>
										</p> */}
										<ul className="list-style-set list-circle ml-1">
											<li>
											{t('M1chapter01introscreenText2')}
											</li>
										</ul>
										{/* <p className="">
											<b>{t('M1chapter01introscreenText3')}</b>
										</p> */}
										<ul className="list-style-set list-circle ml-1">
											<li>
											{t('M1chapter01introscreenText4')}
											</li>
											<li>
											{t('M1chapter01introscreenText5')}
											</li>
										</ul>
										{/* <p className="">
											<b>{t('M1chapter01introscreenText6')}</b>
										</p> */}
										<ul className="list-style-set list-circle ml-1">
											<li>
											{t('M1chapter01introscreenText7')}
											</li>
											<li>
											{t('M1chapter01introscreenText8')}
											</li>
										</ul>
										{/* <p className="">
											<b>{t('M1chapter01introscreenText9')} </b>
										</p> */}
										<ul className="list-style-set list-circle ml-1">
											<li>
											{t('M1chapter01introscreenText10')}
											</li>
											<li>
											{t('M1chapter01introscreenText11')}
											</li>
										</ul>
									</div>
								</div>
								<div
									className="col-lg-3 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="60%"
											draggable="false"
											src="/assets/img/chapter1/reading-book.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
					<div
						className="button-fixed wow fadeInDown"
						data-wow-duration="1s"
						data-wow-delay="0.3s"
					>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-01/chapter-01-intro-screen" />

									{/* <NextBtn link="/module-01/chapter-01-screen-01" text="Go Next" /> */}

									{/* <button className="button_su_inner" onClick={handleSavePage} data-key_name="M1C1" data-key_url="/module-01/chapter-01-intro-screen" >{t('CommonBtnGoNext')}</button> */}

<button className="button_su m-auto" onClick={handleSavePage} data-key_name="M1C1" data-key_url="/module-01/chapter-01-intro-screen" >
									<span className="su_button_circle desplode-circle">
									</span>

									<NavLink to="/module-01/chapter-01-screen-01" className="button_su_inner"><span className="button_text_container"> {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>

								</div>
							</div>
						</div>
					</div>
				</div>
				</div>

			</div>
		</>
	);
};

export default Chapter01IntroScreen;
