import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { NavLink } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter05Screen06 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="75" star="75" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between mt-2">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="">
										{" "}
										<b>
											{t('M4chapter05screen06Text1')}

										</b>{" "}
									</h3>
								</div>
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<a href="https://www.youtube.com/watch?v=-hcti8ViynU" target="_blank">
										<div className="d-flex align-items-center mange-icon">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>
													{" "}
													<span dangerouslySetInnerHTML={{
														__html: t('M4chapter05screen06Text2')
													}}></span>

													{" "}
												</p>
											</div>
										</div>
									</a>
									<a href="https://www.youtube.com/watch?v=Zq3Wm_923pw" target="_blank">
										<div className="d-flex align-items-center mange-icon">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>
												<span dangerouslySetInnerHTML={{
														__html: t('M4chapter05screen06Text3')
													}}></span>
													{" "}
												</p>
											</div>
										</div>
									</a>

									<p>{t('M4chapter05screen06Text4')}{" "}</p>
									<h3 >{t('M4chapter05screen06Text5')}{" "}</h3>
									<div className="post__content" dangerouslySetInnerHTML={{__html: t('M4chapter05screen06Text6')}}></div>

								</div>
								{/* <div
					className="col-lg-4 wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<center>
					<img
						width="31%"
						draggable="false"
						src="/assets/img/md4/md4-shap-5-6.png"
						alt=""
					/>
					</center>
				</div> */}
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-05-screen-05" />

									<NextBtn link="/module-04/chapter-05-screen-08" text={t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen06;
