import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen14 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="71" star="71" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3">

								<div
									className="col-lg-7 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h2 mb-4">{t('additional_resources')} </h2>
									<a
										href="https://www.youtube.com/watch?v=DfTbM15CboM&t=30s"
										target="_blank"
									>
										<div className="d-flex align-items-center mange-icon">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>
													<span dangerouslySetInnerHTML={{  __html: t('M4chapter04screen14Text1')}}></span>
													
												</p>
											</div>
										</div>
									</a>
									<a
										href="https://hindi.feminisminindia.com/2020/07/06/toxic-masculinity-and-boyrs-locker-romm-hindi/"
										target="_blank"
									>
										<div className="d-flex align-items-center mange-icon">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>
												<span dangerouslySetInnerHTML={{  __html: t('M4chapter04screen14Text2')}}></span>
													
												</p>
											</div>
										</div>
									</a>
									<a
										href="https://hindi.feminisminindia.com/2020/04/20/why-domestic-violence-cases-increased-in-lockdown-hindi/"
										target="_blank"
									>
										<div className="d-flex align-items-center mange-icon">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>
												<span dangerouslySetInnerHTML={{  __html: t('M4chapter04screen14Text3')}}></span>
													
												</p>
											</div>
										</div>
									</a>
									<a
										href="https://hindi.feminisminindia.com/2020/07/15/mental-health-of-trans-man-india-hindi/"
										target="_blank"
									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>
												<span dangerouslySetInnerHTML={{  __html: t('M4chapter04screen14Text4')}}></span>
													
												</p>
											</div>
										</div>
									</a>
								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="51%"
											draggable="false"
											src="/assets/img/md4/md4-shap-4-14.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-04-screen-13" />

									<NextBtn link="/module-04/chapter-05-screen-01" text={t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen14;
