import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { saveViewPages } from '../../../../api/api';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Chapter02Screen01 = () => {
	const { t, i18n } = useTranslation();
				const user_details = JSON.parse(localStorage.getItem('user_details'));
				  const navigate = useNavigate();
				  const handleSavePage = (event) => {
			   
			   
					  try {
						  const key_name = event.target.getAttribute("data-key_name");
						  const key_url = event.target.getAttribute("data-key_url");
			   
						  const newUser = { user_id: user_details.user_id, key_name: key_name, url: key_url };
						  const createdUser = saveViewPages(newUser);
						  navigate('/module-03/chapter-02-screen-02');
					  } catch (error) {
						  console.error("Error creating user:", error);
					  }
				  };
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-center pt-20 mt-4 mt-m-3">
								<div className="col-lg-9">
									<h1
										className="what-gender wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
										style={{ fontSize: "6vw", lineHeight: "7vw" }}
									>
										{" "}
										{t('M3chapter02Screen01Title')}
										
									</h1>
									<PrevBtn link="/module-03/chapter-01-screen-19" />
									{/* <NextBtn link="/module-03/chapter-02-screen-02" text=" Let’s Start" classType="mt-5 mt-m-3 wow fadeInDown" /> */}
									<button className="button_su_inner" onClick={handleSavePage} data-key_name="M3C2" data-key_url="/module-03/chapter-02-screen-01" >{t('CommonBtnGoNext')}</button>
								</div>
								<div
									className="col-lg-3 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="73%"
											src="/assets/img/chapter1/reading-book.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen01;
