import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";
const Chapter03Screen03 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="10" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">





							<div className="row align-items-top justify-content-between pt-20 mt-1 mt-m-3">
								<h2 className="h2 mb-1">{t('learn')}</h2>
								<div className="col-lg-7 mt-3">
									<h3 className="h3 mb-1">{t('M6hapter01screen03Text1')} </h3>
									<iframe width="100%" height="390" src="https://www.youtube.com/embed/apNw22pR0WM" title="We Are Breakthrough | Breakthrough India" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
								</div>

								<div className="col-lg-3 mt-m-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">
									<center>
										<img
											width="65%"
											src="/assets/img/md6/md-60chap-1-3.png"
											alt=""
										/>
									</center>
								</div>
							</div>





						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-06/chapter-01-screen-02" />
								<NextBtn link="/module-06/chapter-01-screen-04" text={t('CommonBtnGoNext')} />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen03;
