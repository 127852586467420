import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';
import { Modal, Button } from "react-bootstrap";

const Chapter03Screen02 = () => {

	const navigate = useNavigate();

 const { t, i18n } = useTranslation();
	const [checkedQues1Option1, setCheckedQues1Option1] = useState('');
	const [checkedQues1Option2, setCheckedQues1Option2] = useState('');
	const [checkedQues1Option3, setCheckedQues1Option3] = useState('');

	const [checkedQues1Val, setCheckedQues1Val] = useState('');


	const [checkedQues2, setCheckedQues2] = useState('');



const [show1, setShow1] = useState(false);

const handleClose1 = () => setShow1(false);
const handleShow1 = () => setShow1(true);
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);

		if (quesId === '1') {
			handleShow1()

		}

		checkedVal(quesId, e.target.value)
	}

	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues1Val,
					"ques_id": "1"
				},
				"ques2": {
					"ans": checkedQues2,
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-03-screen-03')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues1Val !== '' && checkedQues2 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '1') {
			if (ans === 'option1') {
				setCheckedQues1Option1('checked')
				setCheckedQues1Option2('');
				setCheckedQues1Option3('');

			} else if (ans === 'option2') {
				setCheckedQues1Option1('')
				setCheckedQues1Option3('')
				setCheckedQues1Option2('checked');

			}else if (ans === 'option3') {
				setCheckedQues1Option1('')
				setCheckedQues1Option2('');
				setCheckedQues1Option3('checked');

			}


			setCheckedQues1Val(ans)
		}

		if (param === '2') {

			setCheckedQues2(ans)
		}







	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="25" star="2" />
					</div>
				</div>

				<div className="d-flex">
				<Sidebar/>

				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								{/* <h4 className="h3 mb-2 mt-3">  <b>{t('M5chapter03Screen2Text1')}</b></h4> */}

							</div>
								<h3 className="h3 mb-2 mt-3">{t('reflect')}</h3>
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<p className="mb-2">{t('M5chapter03Screen2Text2')} </p>
								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio" name="namecircle" id="ns"

											defaultValue="option1"
											onClick={saveRadio}
											checked={checkedQues1Option1}
											data-quesId="1"
										/>
										<label className="form-check-label" htmlFor="ns">{t('M5chapter03Screen2Text3')}</label>
									</div>
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio" name="namecircle" id="ns2"

											defaultValue="option2"
											onClick={saveRadio}
											checked={checkedQues1Option2}
											data-quesId="1"
										/>
										<label className="form-check-label" htmlFor="ns2">{t('M5chapter03Screen2Text4')}</label>
									</div>

									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio" name="namecircle" id="ns2"

											defaultValue="option3"
											onClick={saveRadio}
											checked={checkedQues1Option3}
											data-quesId="1"
										/>
										<label className="form-check-label" htmlFor="ns2">{t('M5chapter03Screen2Text5')}</label>
									</div>



								</div>

								<p className="mb-2 mt-3"> {t('M5chapter03Screen2Text6')}</p>

								<div className="mt-4 positon-submit">
									<textarea className="form-control" rows={2} placeholder="Type your Answer......."
										defaultValue={""}
										onChange={(e) => setCheckedQues2(e.target.value)}
									/>
								</div>




							</div>
							<div
								className="col-lg-4 mt-m-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="50%"
										draggable="false"
										src="/assets/img/md3/md3-shap-1-2.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-05/chapter-03-screen-01" />
									{/* <NextBtn link="/module-05/chapter-03-screen-03" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>




			<Modal show={show1} onHide={handleClose1}>
				<Modal.Header closeButton>

				</Modal.Header>
				<Modal.Body>

					<p>{t('M5chapter03Screen2Text5N')}</p>
				</Modal.Body>
			</Modal>


		</>
	);
};

export default Chapter03Screen02;
