import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';





const Chapter04Screen07 = () => {
	const { t, i18n } = useTranslation();


	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="36" />
					</div>
				</div>


				<div className="d-flex">
					<Sidebar />

					<div className="data-adjust">
						<div className="container-fluid cf-set">

							<div className="row align-items-top justify-content-between pt-20 mt-2">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h4 className="mb-3 h3 mt-3"> <b> {t('M5chapter04Screen7Text1')}  </b></h4>
								</div>

								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<a
										href="https://www.youtube.com/watch?v=ElTOEFuv7Lc&feature=youtu.be"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M5chapter04Screen7Text2')}</p>
											</div>
										</div>
									</a>
									<a
										href="https://www.youtube.com/watch?v=bHWfDAmUUPI&list=PLWqowEyMVW8ZUeG-7AXJT6Tj8gmrBC1z2&index=4"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M5chapter04Screen7Text3')}</p>
											</div>
										</div>
									</a>
									<a
										href="https://www.youtube.com/watch?v=xHv8V4s_QYY&list=PLWqowEyMVW8ZUeG-7AXJT6Tj8gmrBC1z2&index=8"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>#{t('M5chapter04Screen7Text4')}</p>
											</div>
										</div>
									</a>


									<a
										href="https://www.theadvocatesforhumanrights.org/Defenders"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p><b>{t('M5chapter04Screen7Text5')}</b></p>
											</div>
										</div>
									</a>
									<a
										href="https://pjp-eu.coe.int/documents/42128013/47262631/Amnesty+International+Community+Campaigns_Strategies+for+Human+Rights+Defenders.pdf/e495b5ee-5352-cafc-4a39-b15568c20e2b"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p><b>{t('M5chapter04Screen7Text6')}</b></p>
											</div>
										</div>
									</a>

								</div>

								<div
									className="col-lg-4 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											className="mt-0"
											width="65%"
											src="/assets/img/md3/md3-shap-4-07.png"
											alt=""
										/>
									</center>
								</div>

							</div>


						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-05/chapter-04-screen-06" />

									<NextBtn link="/module-05/chapter-04-screen-08" text={t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen07;
