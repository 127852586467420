import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen07 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [checkedQues9Yes, setCheckedQues9Yes] = useState('');
  const [checkedQues9No, setCheckedQues9No] = useState('');
  const [checkedQues9Val, setCheckedQues9Val] = useState('');
  const [checkedQues10Yes, setCheckedQues10Yes] = useState('');
  const [checkedQues10No, setCheckedQues10No] = useState('');
  const [checkedQues10Val, setCheckedQues10Val] = useState('');

  const [checkedQues11Yes, setCheckedQues11Yes] = useState('');
  const [checkedQues11No, setCheckedQues11No] = useState('');
  const [checkedQues11Val, setCheckedQues11Val] = useState('');

  const [checkedQues12Yes, setCheckedQues12Yes] = useState('');
  const [checkedQues12No, setCheckedQues12No] = useState('');
  const [checkedQues12Val, setCheckedQues12Val] = useState('');

  const [checkedQues13Yes, setCheckedQues13Yes] = useState('');
  const [checkedQues13No, setCheckedQues13No] = useState('');
  const [checkedQues13Val, setCheckedQues13Val] = useState('');


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showModalText,setShowModalText] = useState();
  const user_details = JSON.parse(localStorage.getItem('user_details'));

 

  const saveRadio = (e)=>{
    let pop1 = t('M2chapter01screen07pop1');
    let pop2 = t('M2chapter01screen07pop2');
    let pop3 = t('M2chapter01screen07pop3');
    let pop4 = t('M2chapter01screen07pop4');
    let pop5 = t('M2chapter01screen07pop5');

    const quesId = e.target.getAttribute("data-quesId");
    if(quesId === '9')
    {
      setShowModalText(pop1)
    }
    if(quesId === '10')
    {
      setShowModalText(pop2)
    }

    if(quesId === '11')
    {
      setShowModalText(pop3)
    }
    if(quesId === '12')
    {
      setShowModalText(pop4)
    }
    if(quesId === '13')
    {
      setShowModalText(pop5)
    }

    setShow(true);
    checkedVal(quesId,e.target.value)

  }


  const saveAns = () => {

    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": checkedQues9Val,
          "ques_id": "9"
        },
        "ques10": {
          "ans": checkedQues10Val,
          "ques_id": "10"
        },
        "ques11": {
          "ans": checkedQues11Val,
          "ques_id": "11"
        },
        "ques12": {
          "ans": checkedQues12Val,
          "ques_id": "12"
        },
        "ques13": {
          "ans": checkedQues13Val,
          "ques_id": "13"
        },
        "ques14": {
          "ans": "",
          "ques_id": "14"
        }
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/GenderChapter01',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-02/chapter-01-screen-08')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const checkedVal = (param,ans) => {
    if (param === '9') {

      if (ans === 'yes') {
        setCheckedQues9Yes('checked')
        setCheckedQues9No('')
      } else {
        if (ans === 'no') {
          setCheckedQues9No('checked')
          setCheckedQues9Yes('')
        }
      }
      setCheckedQues9Val(ans)
    }
    if (param === '10') {
      if (ans === 'yes') {
        setCheckedQues10Yes('checked')
        setCheckedQues10No('')
      } else {
        if (ans === 'no') {
          setCheckedQues10No('checked')
          setCheckedQues10Yes(''
          )
        }
      }
      setCheckedQues10Val(ans)
    }




    if (param === '11') {
      if (ans === 'yes') {
        setCheckedQues11Yes('checked')
        setCheckedQues11No('')

      } else {
        if (ans === 'no') {
          setCheckedQues11No('checked')
          setCheckedQues11Yes('')
        }
      }
      setCheckedQues11Val(ans)
    }


    if (param === '12') {
      if (ans === 'yes') {
        setCheckedQues12Yes('checked')
        setCheckedQues12No('')

      } else {
        if (ans === 'no') {
          setCheckedQues12No('checked')
          setCheckedQues12Yes('')
        }
      }
      setCheckedQues12Val(ans)
    }


    if (param === '13') {
      if (ans === 'yes') {
        setCheckedQues13Yes('checked')
        setCheckedQues13No('')

      } else {
        if (ans === 'no') {
          setCheckedQues13No('checked')
          setCheckedQues13Yes('')
        }
      }
      setCheckedQues13Val(ans)
    }


  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/GenderChapter01Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  const BtnDisabled = () => {

    if (checkedQues9Val !== '' && checkedQues10Val !== '' && checkedQues11Val !== '' &&
    checkedQues12Val !== '' && checkedQues13Val !== '' ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };




  return (
    <>
    <div className="wrapper">
      <div
        className="fixed-header"

      >
        <div className="container-fluid cf-set">
        <Header leftChapter="0/5" percentage="6" star="6" />
        </div>
      </div>
      <div className="d-flex">
        <Sidebar />
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-center justify-content-center pt-0">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >
                <h2 className="h2">{t('engage')}&nbsp;</h2>

                <h4>{t('M2chapter01screen07Text1')} </h4>
              </div>
            </div>
            <div className="row align-items-top justify-content-center pt-20 mt-2">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.1s"
              >
                <h5>{t('M2chapter01screen07Text2')}</h5>
                <div className="wiout-border-table ">
                  <table className="table mb-0">
                    <tbody>

                      <tr className="table-warning">
                        <td>
                        {t('M2chapter01screen07Text3')}
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques9"
                                id="inlineRadio1"


                                onClick={saveRadio}
                                defaultValue="yes"
                                data-quesId="9"
                                checked={checkedQues9Yes}


                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                {t('yes')}
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques9"
                                id="inlineRadio2"
                                defaultValue="no"
                                data-quesId="9"
                                onClick={saveRadio}
                                checked={checkedQues9No}
                              />
                              <label
                                className="form-check-label "
                                htmlFor="inlineRadio2"
                              >
                                {t('no')}
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="table-light">
                        <td>
                        {t('M2chapter01screen07Text4')}
                          
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques10"
                                id="inlineRadio1"
                                defaultValue="yes"
                                data-quesId="10"
                                onClick={saveRadio}
                                checked={checkedQues10Yes}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                {t('yes')}
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques10"
                                id="inlineRadio2"
                                defaultValue="no"
                                data-quesId="10"
                                onClick={saveRadio}
                                checked={checkedQues10No}
                              />
                              <label
                                className="form-check-label "
                                htmlFor="inlineRadio2"
                              >
                                {t('no')}
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="table-warning">
                        <td>
                        {t('M2chapter01screen07Text5')}
                          
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques11"
                                id="inlineRadio1"
                                defaultValue="yes"
                                data-quesId="11"
                                onClick={saveRadio}
                                checked={checkedQues11Yes}

                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                {t('yes')}
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques11"
                                id="inlineRadio2"
                                defaultValue="no"
                                data-quesId="11"
                                onClick={saveRadio}
                                checked={checkedQues11No}
                              />
                              <label
                                className="form-check-label "
                                htmlFor="inlineRadio2"
                              >
                                {t('no')}
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="table-light">
                        <td>
                          {t('M2chapter01screen07Text6')}
                          
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques12"
                                id="inlineRadio1"
                                defaultValue="yes"
                                data-quesId="12"
                                onClick={saveRadio}
                                checked={checkedQues12Yes}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                {t('yes')}
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques12"
                                id="inlineRadio2"
                                defaultValue="no"
                                data-quesId="12"
                                onClick={saveRadio}
                                checked={checkedQues12No}
                              />
                              <label
                                className="form-check-label "
                                htmlFor="inlineRadio2"
                              >
                                {t('no')}
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr className="table-warning">
                        <td>
                        {t('M2chapter01screen07Text7')}
                          
                        </td>
                        <td className="widthset-td">
                          <div className="d-flex align-items-center justify-content-end">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques13"
                                id="inlineRadio1"
                                defaultValue="yes"
                                data-quesId="13"
                                onClick={saveRadio}
                                checked={checkedQues13Yes}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                {t('yes')}
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques13"
                                id="inlineRadio2"
                                defaultValue="no"
                                data-quesId="13"
                                onClick={saveRadio}
                                checked={checkedQues13No}
                              />
                              <label
                                className="form-check-label "
                                htmlFor="inlineRadio2"
                              >
                                {t('no')}
                              </label>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="button-fixed wow fadeInDown"
      data-wow-duration="1s"
      data-wow-delay="0.3s"
    >
      <div className="container-fluid cf-set">
        <div className="row">
          <div className="col-lg-12">
            <div className=" d-flex justify-content-end">
            <PrevBtn link="/module-02/chapter-01-screen-06"/>

                {/* <NextBtn link="/module-01/chapter-01-screen-08" text="Go Next" classtype=""/> */}
              {/* <button class="button_su">
              <span class="su_button_circle">
              </span>
              <a href="chapter-1-7.html" class="button_su_inner" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><span class="button_text_container"> Popup</span> </a>
            </button> */}
            <BtnDisabled/>
            </div>
          </div>
        </div>
      </div>
    </div>



      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          {/* <Modal.Title>Women are physically weak :</Modal.Title> */}
        </Modal.Header>

        <Modal.Body>
         <p>{showModalText}</p>
        </Modal.Body>
      </Modal>

  </>

  );
};

export default Chapter01Screen07;
