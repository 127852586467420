import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter02Screen02 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2 hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="55" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between pt-20 mr-3 mt-m-3">
								<div
									className="col-lg-6 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h2">{t('learn')}</h3>
									<p className="mb-2 mt-1">{t('M6chapter03screen02Text1')}</p>
									<h3 className="h4">{t('M6chapter03screen02Text2')}</h3>

									<ul className="list-style-set list-circle mt-2">
										<li>{t('M6chapter03screen02Text3N1')}</li>
										<li>{t('M6chapter03screen02Text3')}</li>
										<li>{t('M6chapter03screen02Text3N2')}</li>
										<li>{t('M6chapter03screen02Text4')}</li>
										<li>{t('M6chapter03screen02Text5')}</li>
									</ul>

								</div>

								<div
									className="col-lg-6 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="90%"
											draggable="false"
											src="/assets/img/md6/map.JPG"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-06/chapter-03-screen-01" />
								<NextBtn link="/module-06/chapter-03-screen-03" text={t('CommonBtnGoNext')} />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
