import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter02Screen05n1 = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [girls1Val,setGirls1Val] = useState('');
  const [boys1Val,setBoys1Val] = useState('');

  const [girls2Val,setGirls2Val] = useState('');
  const [boys2Val,setBoys2Val] = useState('');

  const [girls3Val,setGirls3Val] = useState('');
  const [boys3Val,setBoys3Val] = useState('');

  const [girls4Val,setGirls4Val] = useState('');
  const [boys4Val,setBoys4Val] = useState('');

  const [girls5Val,setGirls5Val] = useState('');
  const [boys5Val,setBoys5Val] = useState('');



  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveAns = (e)=>{




    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": girls1Val+','+girls2Val+','+girls3Val+','+girls4Val+','+girls5Val,
          "ans2": boys1Val+','+boys2Val+','+boys3Val+','+boys4Val+','+boys5Val,
          "ques_id": "10"
        },
        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        }

      }
    });


    var config = {
      method: 'post',
      url: BaseURL + '/GenderChapter02',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate('/module-02/chapter-02-screen-06n');
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });


  }

  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": "10"
    });
    var config = {
      method: 'post',
      url: BaseURL + '/GenderChapter02Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const girls = response.data.data.chapter.ans;
          const boys = response.data.data.chapter.ans2;

          const __boys = boys.split(",");

          const __girls = girls.split(",");

          __girls.map((elem,i)=>{
            if(i===0)
            {

              setGirls1Val(elem);
            }
            if(i===1)
            {
              setGirls2Val(elem);
            }
            if(i===2)
            {
              setGirls3Val(elem);
            }
            if(i===3)
            {
              setGirls4Val(elem);
            }
            if(i===4)
            {
              setGirls5Val(elem);
            }


          });

          __boys.map((elem,i)=>{
            if(i===0)
            {

              setBoys1Val(elem);
            }
            if(i===1)
            {
              setBoys2Val(elem);
            }
            if(i===2)
            {
              setBoys3Val(elem);
            }
            if(i===3)
            {
              setBoys4Val(elem);
            }
            if(i===4)
            {
              setBoys5Val(elem);
            }


          })

        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  const BtnDisabled = () => {

    if (girls1Val !== '' && girls2Val !== '' && girls3Val !==''
      && boys1Val !=='' && boys2Val !== '' && boys3Val !== ''
    ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };


  return (
    <>
      <div className="wrapper hp">
        <div  className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="1/5" percentage="24" star="11" />
          </div>
        </div>
        <div className="d-flex">
					<Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-between pt-20 mt-3">




                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >

                  <h2 className="h2">{t('M2chapter02screen05n1Text1')}</h2>
                  <p>
                    {t('M2chapter02screen05n1Text2')}
                  </p>
                  <table className="table table-bordered table-padding-0">
                    <thead>
                      <tr>
                        <th scope="col">{t('M2chapter02screen05n1Text3')}</th>
                        <th scope="col">{t('M2chapter02screen05n1Text4')}</th>
                      </tr>
                    </thead>
                    <tbody className="padding-adjust-table">
                      <tr>
                        <td> <textarea defaultValue={girls1Val} onChange={(e)=>setGirls1Val(e.target.value)} className="form-control without-border-input" type="text" placeholder="..." /> </td>
                        <td> <textarea defaultValue={boys1Val} onChange={(e)=>setBoys1Val(e.target.value)} className="form-control without-border-input" type="text" placeholder="..." /> </td>
                      </tr>
                      <tr>
                        <td> <textarea defaultValue={girls2Val} onChange={(e)=>setGirls2Val(e.target.value)} className="form-control without-border-input" type="text" placeholder="..." /> </td>
                        <td> <textarea defaultValue={boys2Val} onChange={(e)=>setBoys2Val(e.target.value)} className="form-control without-border-input" type="text" placeholder="..." /> </td>
                      </tr>
                      <tr>
                        <td> <textarea defaultValue={girls3Val} onChange={(e)=>setGirls3Val(e.target.value)} className="form-control without-border-input" type="text" placeholder="..." /> </td>
                        <td> <textarea defaultValue={boys3Val} onChange={(e)=>setBoys3Val(e.target.value)} className="form-control without-border-input" type="text" placeholder="..." /> </td>
                      </tr>
                      {/* <tr>
                        <td> <textarea defaultValue={girls4Val} onChange={(e)=>setGirls4Val(e.target.value)} className="form-control without-border-input" type="text" placeholder="..." /> </td>
                        <td> <textarea defaultValue={boys4Val} onChange={(e)=>setBoys4Val(e.target.value)} className="form-control without-border-input" type="text" placeholder="..." /> </td>
                      </tr>
                      <tr>
                        <td> <textarea defaultValue={girls5Val} onChange={(e)=>setGirls5Val(e.target.value)} className="form-control without-border-input" type="text" placeholder="..." /> </td>
                        <td> <textarea defaultValue={boys5Val} onChange={(e)=>setBoys5Val(e.target.value)} className="form-control without-border-input" type="text" placeholder="..." /> </td>
                      </tr> */}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-02/chapter-02-screen-05" />

                {/* <NextBtn
                  link="/module-01/chapter-02-screen-06n"
                  text="Go Next"
                  classtype=""
                /> */}

                <button

                className={`button_su`}
                data-wow-duration="1s"
                data-wow-delay="0.2s"
                >
                <span className="su_button_circle" />
                {/* <button onClick={saveAnswers} className="button_su_inner">
                  <span className="button_text_container">
                    Go Next <i className="bi bi-arrow-right" />
                  </span>
                </button> */}
                <BtnDisabled/>
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen05n1;
