import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import Sidebar from "../../../common/Sidebar";
import { saveViewPages } from '../../../../api/api';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Chapter01Screen01N = () => {
	const { t, i18n } = useTranslation();
				const user_details = JSON.parse(localStorage.getItem('user_details'));
				  const navigate = useNavigate();
				  const handleSavePage = (event) => {


					  try {
						  const key_name = event.target.getAttribute("data-key_name");
						  const key_url = event.target.getAttribute("data-key_url");

						  const newUser = { user_id: user_details.user_id, key_name: key_name, url: key_url };
						  const createdUser = saveViewPages(newUser);
						  navigate('/module-03/chapter-01-intro-screen');
					  } catch (error) {
						  console.error("Error creating user:", error);
					  }
				  };
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="0" star="0" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between pt-20 mt-4">
                                <p>{t('M3Chapter01Screen01NText1N')}</p>
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h4>
										{" "}
										<b> {t('M3Chapter01Screen01NText1')}</b>
									</h4>
									<div className="para-color-box mt-2">
                                        <ul>
                                            <li>{t('M3Chapter01Screen01NText2')}</li>
                                            <li>{t('M3Chapter01Screen01NText3')}</li>
                                            <li>{t('M3Chapter01Screen01NText4')}</li>
                                            <li>{t('M3Chapter01Screen01NText5')}</li>
                                            <li>{t('M3Chapter01Screen01NText6')}</li>
                                        </ul>


									</div>
								</div>
								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="60%"
											draggable="false"
											src="/assets/img/chapter1/reading-book.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.3s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-02/score" />

									<NextBtn link="/module-03/chapter-01-intro-screen" text={t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen01N;
