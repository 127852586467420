import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen31 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-0">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<h3 className="h3">{t('learn')} </h3>
									<p className="mb-2 mt-3">{t('M3chapter04Screen31Text1')}  </p>
									<p className="mb-2 mt-3">{t('M3chapter04Screen31Text2')}  </p>

								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="100%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-4-31.png"
											alt=""
										/>
									</center>
								</div>




							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-30" />
								<NextBtn link="/module-03/chapter-04-screen-32" text={t('CommonBtnGoNext')} />


								{/* <button className="button_su">
                  <span className="su_button_circle desplode-circle">
                  </span>
                  <a href="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </a>
                </button> */}


							</div>
						</div>
					</div>
				</div>
			</div>




			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>
							<p className="text-white text-center">{t('M3chapter04Screen31Text3')}</p>


							<div className=" d-flex justify-content-center mt-3">
								<button className="button_su m-auto" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="/module-03/chapter-04-screen-32" className="button_su_inner"><span className="button_text_container">{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen31;
