import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter02Screen02 = () => {

  const navigate = useNavigate();
  const [checkedQues4Option1, setCheckedQues4Option1] = useState('');
  const [checkedQues4Option2, setCheckedQues4Option2] = useState('');
  const [checkedQues4Option3, setCheckedQues4Option3] = useState('');

  const [checkedQues4Val, setCheckedQues4Val] = useState('');

  const [checkedQues5Option1, setCheckedQues5Option1] = useState('');
  const [checkedQues5Option2, setCheckedQues5Option2] = useState('');
  const [checkedQues5Option3, setCheckedQues5Option3] = useState('');
  const [checkedQues5Option4, setCheckedQues5Option4] = useState('');

  const [checkedQues5Val, setCheckedQues5Val] = useState('');
  const { t, i18n } = useTranslation();

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    // alert(quesId);
    checkedVal(quesId, e.target.value)
  }

  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {

        "ques4": {
          "ans": checkedQues4Val,
          "ques_id": "4"
        },
        "ques5": {
          "ans": checkedQues5Val,
          "ques_id": "5"
        },

      }
    });
    var config = {
      method: 'post',
      url: BaseURL + '/ApproachChapter04',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate('/module-06/chapter-04-screen-05')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues4Val !== '' && checkedQues5Val !== '') {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param, ans) => {

    if (param === '4') {
      if (ans === 'option1') {
        setCheckedQues4Option1('checked')
        setCheckedQues4Option2('');
        setCheckedQues4Option3('')


      } else if (ans === 'option2') {
        setCheckedQues4Option1('')
        setCheckedQues4Option2('checked');
        setCheckedQues4Option3('')


      } else if (ans === 'option3') {
        setCheckedQues4Option1('')
        setCheckedQues4Option2('');
        setCheckedQues4Option3('checked')


      }
      setCheckedQues4Val(ans)
    }

    if (param === '5') {
      if (ans === 'option1') {
        setCheckedQues5Option1('checked')
        setCheckedQues5Option2('');
        setCheckedQues5Option3('')
        setCheckedQues5Option4('')
      } else if (ans === 'option2') {
        setCheckedQues5Option1('')
        setCheckedQues5Option2('checked');
        setCheckedQues5Option3('')
        setCheckedQues5Option4('')
      } else if (ans === 'option3') {
        setCheckedQues5Option1('')
        setCheckedQues5Option2('');
        setCheckedQues5Option3('checked')
        setCheckedQues5Option4('')
      } else if (ans === 'option4') {
        setCheckedQues5Option1('')
        setCheckedQues5Option2('');
        setCheckedQues5Option3('')
        setCheckedQues5Option4('checked')
      }
      setCheckedQues5Val(ans)
    }


  }

  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/approachChapter04Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(), result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])



  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
            <Header leftChapter="3/4" percentage="80" star="50" />
          </div>
        </div>

        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-between pt-20 mr-3 mt-m-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >



                  <p className="mb-2 mt-3 pn"><span><b>3</b></span><b>{t('M6chapter04screen04Text1')}</b> </p>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio"
                        name="namecircle3"
                        id="mycirle9"
                        defaultValue="option1"
                        onClick={saveRadio}
                        data-quesId="4"
                        checked={checkedQues4Option1}
                      />
                      <label className="form-check-label" htmlFor="mycirle9"> {t('M6chapter04screen04Text2')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio"
                        name="namecircle3"
                        id="mycirle10"
                        defaultValue="option2"
                        onClick={saveRadio}
                        data-quesId="4"
                        checked={checkedQues4Option2}

                      />
                      <label className="form-check-label" htmlFor="mycirle10"> {t('M6chapter04screen04Text3')} </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check"
                        type="radio"
                        name="namecircle3"
                        id="mycirle11"
                        defaultValue="option3"
                        onClick={saveRadio}
                        data-quesId="4"
                        checked={checkedQues4Option3}
                      />
                      <label className="form-check-label" htmlFor="mycirle11"> {t('M6chapter04screen04Text4')} </label>
                    </div>
                  </div>


                  <p className="mb-2 mt-3 pn"><span><b>4</b></span><b> {t('M6chapter04screen04Text5')}</b> </p>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check"
                        type="radio"
                        name="namecircle2"
                        id="mycirle1"
                        defaultValue="option1"
                        onClick={saveRadio}
                        data-quesId="5"
                        checked={checkedQues5Option1}

                      />
                      <label className="form-check-label" htmlFor="mycirle1">{t('M6chapter04screen04Text6')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio"
                        name="namecircle2"
                        id="mycirle2"
                        defaultValue="option2"
                        onClick={saveRadio}
                        data-quesId="5"
                        checked={checkedQues5Option2}
                      />
                      <label className="form-check-label" htmlFor="mycirle2">{t('M6chapter04screen04Text7')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio"
                        name="namecircle2"
                        id="mycirle3"
                        defaultValue="option3"
                        onClick={saveRadio}
                        data-quesId="5"
                        checked={checkedQues5Option3}

                      />
                      <label className="form-check-label" htmlFor="mycirle3">{t('M6chapter04screen04Text8')} </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio"
                        name="namecircle2"
                        id="mycirle4"
                        defaultValue="option4"
                        onClick={saveRadio}
                        data-quesId="5"
                        checked={checkedQues5Option4}
                      />
                      <label className="form-check-label" htmlFor="mycirle4">{t('M6chapter04screen04Text9')} </label>
                    </div>
                  </div>







                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">

                <PrevBtn link="/module-06/chapter-04-screen-03" />
                {/* <NextBtn link="/module-06/chapter-04-screen-05" text="Go Next"/> */}
                <BtnDisabled />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen02;
