import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen09 = () => {
	const { t, i18n } = useTranslation();

	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="22" star="22" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar/>

					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between mt-3">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h2 mb-1">{t('M1chapter02screen09Title')} </h2>
								</div>
								<div
									className="col-lg-6 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<a href="https://personalvalu.es/" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M1chapter02screen09Text1')}</p>
											</div>
										</div>
									</a>

									<a href="https://www.youtube.com/watch?v=te_h08znkz4" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M1chapter02screen09Text2')}</p>
											</div>
										</div>
									</a>

									<a href="https://www.youtube.com/watch?v=ye7TVMl-_TQ" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M1chapter02screen09Text3')}</p>
											</div>
										</div>
									</a>

									<a href="https://daretolead.brenebrown.com/wp-content/uploads/2019/02/Values.pdf" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-3 ">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('values')}</p>
											</div>
										</div>
									</a>

								</div>
								<div
									className="col-lg-4 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img width="54%" src="/assets/img/md2/md2-shap-2-9.png" alt="" />
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-02-screen-08n" />

								<NextBtn link="/module-01/chapter-03-screen-01" text={t('CommonBtnGoNext')} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen09;
