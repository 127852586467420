import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen08 = () => {

	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const [checkedQues3Option1, setCheckedQues3Option1] = useState('');
	const [checkedQues3Option2, setCheckedQues3Option2] = useState('');

	const [checkedQues3Val, setCheckedQues3Val] = useState('');

	const [checkedQues4Option1, setCheckedQues4Option1] = useState('');
	const [checkedQues4Option2, setCheckedQues4Option2] = useState('');

	const [checkedQues4Val, setCheckedQues4Val] = useState('');

	const [checkedQues5Option1, setCheckedQues5Option1] = useState('');
	const [checkedQues5Option2, setCheckedQues5Option2] = useState('');

	const [checkedQues5Val, setCheckedQues5Val] = useState('');

	const [checkedQues6Option1, setCheckedQues6Option1] = useState('');
	const [checkedQues6Option2, setCheckedQues6Option2] = useState('');
	const [checkedQues6Option3, setCheckedQues6Option3] = useState('');

	const [checkedQues6Val, setCheckedQues6Val] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(e.target.value);
		checkedVal(quesId, e.target.value)
	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3Val,
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4Val,
					"ques_id": "4"
				},
				"ques5": {
					"ans": checkedQues5Val,
					"ques_id": "5"
				},
				"ques6": {
					"ans": checkedQues6Val,
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-03/chapter-04-screen-09')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues3Val !== '' && checkedQues4Val !== '' && checkedQues5Val !== '' && checkedQues6Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '3') {
			if (ans === 'option1') {
				setCheckedQues3Option1('checked')
				setCheckedQues3Option2('');

			} else if (ans === 'option2') {
				setCheckedQues3Option1('')
				setCheckedQues3Option2('checked');

			}
			setCheckedQues3Val(ans)
		}

		if (param === '4') {
			if (ans === 'option1') {
				setCheckedQues4Option1('checked')
				setCheckedQues4Option2('');

			} else if (ans === 'option2') {
				setCheckedQues4Option1('')
				setCheckedQues4Option2('checked');

			}
			setCheckedQues4Val(ans)
		}

		if (param === '5') {
			if (ans === 'option1') {
				setCheckedQues5Option1('checked')
				setCheckedQues5Option2('');

			} else if (ans === 'option2') {
				setCheckedQues5Option1('')
				setCheckedQues5Option2('checked');

			}
			setCheckedQues5Val(ans)
		}

		if (param === '6') {
			if (ans === 'option1') {
				setCheckedQues6Option1('checked')
				setCheckedQues6Option2('');
				setCheckedQues6Option2('');

			} else if (ans === 'option2') {
				setCheckedQues6Option1('')
				setCheckedQues6Option2('checked');
				setCheckedQues6Option3('');

			} else if (ans === 'option3') {
				setCheckedQues6Option1('')
				setCheckedQues6Option2('');
				setCheckedQues6Option3('checked');

			}
			setCheckedQues6Val(ans)
		}







	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-0">
								<div
									className="col-lg-9 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mb-2 mt-3">{t('M3chapter04Screen08Text1')}</p>
									<div className="d-flex mt-3">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle"
												id="mycirle1"
												defaultValue="option1"

												onClick={saveRadio}
												checked={checkedQues3Option1}
												data-quesId="3"

											/>
											<label className="form-check-label" htmlFor="mycirle1">{t('yes')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle" id="mycirle2"
												defaultValue="option2"
												onClick={saveRadio}
												checked={checkedQues3Option2}
												data-quesId="3"
											/>
											<label className="form-check-label" htmlFor="mycirle2">{t('no')}</label>
										</div>
									</div>

									<p className="mb-2 mt-3">{t('M3chapter04Screen08Text2')} </p>
									<div className="d-flex mt-3">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle2" id="mycirle3" defaultValue="option1" onClick={saveRadio} checked={checkedQues4Option1} data-quesId="4" />
											<label className="form-check-label" htmlFor="mycirle3">{t('yes')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle2" id="mycirle4"
												defaultValue="option2"
												onClick={saveRadio} checked={checkedQues4Option2} data-quesId="4"
											/>
											<label className="form-check-label" htmlFor="mycirle4">{t('no')}</label>
										</div>
									</div>

									<p className="mb-2 mt-3">{t('M3chapter04Screen08Text3')}  </p>
									<div className="d-flex mt-3">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle3"
												id="mycirle5"
												defaultValue="option1"
												onClick={saveRadio}
												checked={checkedQues5Option1}
												data-quesId="5"

											/>
											<label className="form-check-label" htmlFor="mycirle5">{t('yes')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle3" id="mycirle6"

												defaultValue="option2"
												onClick={saveRadio}
												checked={checkedQues5Option2}
												data-quesId="5"
											/>
											<label className="form-check-label" htmlFor="mycirle6">{t('no')}</label>
										</div>
									</div>

									<p className="mb-2 mt-3 pn">{t('M3chapter04Screen08Text4')} </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle8"
												id="mycirle8"
												defaultValue="option1"

												onClick={saveRadio}
												checked={checkedQues6Option1}
												data-quesId="6"
											/>
											<label className="form-check-label" htmlFor="mycirle8">{t('M3chapter04Screen08Text5')}	 </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle4" id="mycirle9"
												defaultValue="option2"

												onClick={saveRadio}
												checked={checkedQues6Option2}
												data-quesId="6"

											/>
											<label className="form-check-label" htmlFor="mycirle9">{t('M3chapter04Screen08Text6')} </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle4"
												id="mycirle10"
												defaultValue="option3"
												onClick={saveRadio}
												checked={checkedQues6Option3}
												data-quesId="6"
											/>
											<label className="form-check-label" htmlFor="mycirle10">{t('M3chapter04Screen08Text7')} </label>
										</div>
									</div>





								</div>

								<div
									className="col-lg-2 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="85%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-2-15.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-07" />
								{/* <NextBtn link="/module-03/chapter-04-screen-09" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>


		</>
	);
};

export default Chapter04Screen08;
