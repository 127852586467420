import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen08 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [checkedQues13, setCheckedQues13] = useState('');


  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans":"",
          "ques_id": "5"
        },
        "ques6": {
          "ans":  "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": "",
          "ques_id": "10"
        },
        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        },
        "ques13": {
          "ans": checkedQues13,
          "ques_id": "13"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/SelfChapter02',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-01/chapter-02-screen-08n')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {

      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );

  };
  const checkedVal = (param,ans) => {
    if (param === '13') {

        setCheckedQues13(ans)
    }


  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfChapter02Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },

      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
          <Header leftChapter="1/4" percentage="20" star="20" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20 mt-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                <h2 className="h3 mt-1">{t('learn')}</h2>
                  <p className="mb-2 mt-2">
                    {t('M1chapter02screen08Text1')}
                  </p>


                  <p className="mb-2 mt-2">
                    {t('M1chapter02screen08Text2')}
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
              <PrevBtn link="/module-01/chapter-02-screen-07"/>
              <NextBtn link="/module-01/chapter-02-screen-08n" text={t('CommonBtnGoNext')} />
                {/* <NextBtn link="/module-02/chapter-02-screen-09" text="Go Next"/> */}
                {/* <BtnDisabled/> */}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen08;
