import React, { useState, useEffect } from "react";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Header from "../../../header/Header";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen28 = () => {
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
	const [checkedQues32_1, setCheckedQues32_1] = useState('');
	const [checkedQues32_2, setCheckedQues32_2] = useState('');
	const [checkedQues32_3, setCheckedQues32_3] = useState('');
	const [checkedQues32_4, setCheckedQues32_4] = useState('');
	// const [checkedQues32_5, setCheckedQues11_5] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques32": {
					"ans": checkedQues32_1 + "," + checkedQues32_2 + "," + checkedQues32_3 + "," + checkedQues32_4,
					"ques_id": "32"
				}




			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-04-screen-29')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": "32"
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter.ans;
					const res = results.split(",");
					res.map((elem, i) => {
						console.log(i);
						if (i === 0) {
							setCheckedQues32_1(elem)
						}
						if (i === 1) {
							setCheckedQues32_2(elem)
						}
						if (i === 2) {
							setCheckedQues32_3(elem)
						}
						if (i === 3) {
							setCheckedQues32_4(elem)
						}

					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	const BtnDisabled = () => {
		if (checkedQues32_1 !== '' && checkedQues32_2 !== '' && checkedQues32_4 !== ''
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper page-auto bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-0">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<h3 className="h3">{t('apply')}  </h3>
									<p className="mb-2  mt-3"> <b>{t('M3chapter04Screen28Text1')} </b> </p>


									<p className="mb-2  mt-3">{t('M3chapter04Screen28Text2')} </p>
									<p className="mb-2  mt-3">{t('M3chapter04Screen28Text3')} </p>
									<p className="mb-2  mt-3">{t('M3chapter04Screen28Text4')}  </p>

									<div className="row ">
										<div className="col-lg-9 ">
											<ul className="list-style-set list-input list-line-height mt-3 mb-3">
												<li>{t('M3chapter04Screen28Text5')} </li>
												<li>
													<input type="text" placeholder onChange={(e) => { setCheckedQues32_1(e.target.value) }} data-quesId="32"
														defaultValue={checkedQues32_1} className="input-without-style form-control" />
												</li>

												<li className="mt-4">{t('M3chapter04Screen28Text6')}    </li>
												<li><input type="text" placeholder
													className="input-without-style form-control"
													onChange={(e) => { setCheckedQues32_2(e.target.value) }}
													data-quesId="32"
													defaultValue={checkedQues32_2}
												/></li>


												{/* <li>but why get an STI test, that seems unnecessary.   <input type="text"
					placeholder
					className="input-without-style form-control"
					onChange={(e)=>{setCheckedQues32_3(e.target.value)}}
						data-quesId="32"
						defaultValue={checkedQues32_3}
					/></li> */}




												<li className="mt-4">{t('M3chapter04Screen28Text7')}   </li>

												<li><input type="text" placeholder
													className="input-without-style form-control"
													onChange={(e) => { setCheckedQues32_4(e.target.value) }}
													data-quesId="32"
													defaultValue={checkedQues32_4}
												/></li>


												<li className="mt-4"><b>{t('M3chapter04Screen28Text8')}</b> </li>
											</ul>


										</div>
									</div>



								</div>

								{/* <div
					className="col-lg-2 wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<center>
					<img
						width="80%"
						draggable="false"
						src="/assets/img/md4/md4-shap-2-3.png"
						alt=""
					/>
					</center>
				</div> */}



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-26" />
								{/* <NextBtn link="/module-03/chapter-04-screen-29" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>



		</>
	);
};

export default Chapter04Screen28;
