import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";


const Chapter01Screen21 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [checkedQues4_1, setCheckedQues4_1] = useState('');
	const [checkedQues4_2, setCheckedQues4_2] = useState('');
	const [checkedQues4_3, setCheckedQues4_3] = useState('');



	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",


					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4_1,
					"ans2": checkedQues4_2,
					"ans3": checkedQues4_3,
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-01-screen-22')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					results.map((result) => {
						// console.log(result);
						checkedVal(result.ques_id.toString(), result.ans, result.ans2, result.ans3)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const checkedVal = (param, ans, ans2, ans3) => {
		if (param === '4') {
			setCheckedQues4_1(ans)
			setCheckedQues4_2(ans2)
			setCheckedQues4_3(ans3)


		}




	}


	const BtnDisabled = () => {
		if (checkedQues4_1 !== '' || checkedQues4_2 !== '' || checkedQues4_3 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="2" star="1" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3">{t('M5chapter01Screen21Text1')}</h3>
								</div>
								<div
									className="col-lg-6 mt-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>



									<div className="mb-3 positon-submit">
										<textarea
											className="form-control"
											rows={1}
											placeholder="Type your Answer........"
											onChange={(e) => setCheckedQues4_1(e.target.value)}
											data-quesId="4"
											defaultValue={checkedQues4_1}
										/>
									</div>

									<div className="mb-3 positon-submit">
										<textarea
											className="form-control"
											rows={1}
											placeholder="Type your Answer......."
											onChange={(e) => setCheckedQues4_2(e.target.value)}
											data-quesId="2"
											defaultValue={checkedQues4_2}
										/>
									</div>

									<div className="mb-3 positon-submit">
										<textarea
											className="form-control"
											rows={1}
											placeholder="Type your Answer........"
											onChange={(e) => setCheckedQues4_3(e.target.value)}
											data-quesId="2"
											defaultValue={checkedQues4_3}
										/>
									</div>



								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											className="mt-4"
											width="80%"
											src="/assets/img/md3/md3-shap-3-21.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-05/chapter-01-screen-20" />
									{/* <NextBtn link="/module-05/chapter-01-screen-22" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen21;
