import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter02Screen06 = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <>
        <div className="wrapper">
          <div
            className="fixed-header"

          >
            <div className="container-fluid cf-set">
              <Header leftChapter="1/5" percentage="26" star="13" />
            </div>
          </div>
          <div className="d-flex">
            <Sidebar />
            <div className="data-adjust">
              <div className="container-fluid cf-set">
                <div className="row align-items-center justify-content-between pt-20 mt-3">
                  <div
                    className="col-lg-7 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    <h1 className="h1 text-left">{t('learn')} </h1>
                    <p className="text-left mb-3">
                      {t('M2chapter02screen06Text1')}
                      {" "}
                    </p>
                    {/* <p className="text-left mb-3">
                      <a
                        href="https://www.youtube.com/watch?v=HFPjypCj7MM&t=2s"
                        target="_blank"
                      >
                        <b>The Murailapur Champions | Breakthrough India</b>
                      </a>
                    </p> */}
                  </div>
                  <div
                    className="col-lg-5 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >


                    <iframe
                      width="100%"
                      height="315"
                      src="https://www.youtube.com/embed/HFPjypCj7MM"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                  <PrevBtn link="/module-02/chapter-02-screen-06n" />

                  <NextBtn
                    link="/module-02/chapter-02-screen-07"
                    text={t('CommonBtnGoNext')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Chapter02Screen06;
