import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen05 = () => {
  const navigate = useNavigate();
  const [checkedQues4, setCheckedQues4] = useState('');
  const { t, i18n } = useTranslation();

  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const saveAns = () => {

    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": checkedQues4,
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        }

      }
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfChapter01',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate('/module-01/chapter-01-screen-06')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfChapter01Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;

          results.map((result) => {
            checkedVal(result.ques_id.toString(), result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const checkedVal = (param, ans) => {
    if (param === '4') {
      setCheckedQues4(ans)
    }




  }


  const BtnDisabled = () => {
    if (checkedQues4 !== '') {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div className="wrapper bg-bd-2">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
            <Header leftChapter="0/4" percentage="5" star="4" />
          </div>
        </div>

        <div className="d-flex">
          <Sidebar />

          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20 mt-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h2 className="h2">{t('M1chapter01screen05Title')}</h2>
                  <p className="mb-2 mt-3">
                  {t('M1chapter01screen05Des')}
                  </p>
                  <div className="mt-4 positon-submit">
                    <textarea
                      className="form-control"
                      rows={6}
                      placeholder="Write Something here....."
                      onChange={(e) => setCheckedQues4(e.target.value)}
                      data-quesId="4"
                      defaultValue={checkedQues4}
                    />




                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-01/chapter-01-screen-04" />

                {/* <NextBtn link="/module-02/chapter-01-screen-06" text="Go Next"/> */}
                <BtnDisabled />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter01Screen05;
