import React from "react";


import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { saveViewPages } from '../../../../api/api';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Chapter01IntroScreen = () => {

	const { t, i18n } = useTranslation();
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const navigate = useNavigate();
	const handleSavePage = (event) => {


		try {
			const key_name = event.target.getAttribute("data-key_name");
			const key_url = event.target.getAttribute("data-key_url");

			const newUser = { user_id: user_details.user_id, key_name: key_name, url: key_url };
			const createdUser = saveViewPages(newUser);
			navigate('/module-02/chapter-01-screen-01');
		} catch (error) {
			console.error("Error creating user:", error);
		}
	};

	return (
		<div className="wrapper">
			<div
				className="fixed-header"

			>
				<div className="container-fluid cf-set">
					<Header leftChapter="0/5" percentage="0" star="0" />
				</div>
			</div>
			<div className="d-flex">
				<Sidebar />
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-between pt-20">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>



								<h4>
									{" "}
									<b>
										{t('M2chapter01IntroscreenText1')}
									</b>
								</h4>
								<div className="para-color-box">
									<p>
										<b>{t('M2chapter01IntroscreenText2')}</b>
									</p>
									<ul className="list-style-set list-circle mt-1">
										<li>{t('M2chapter01IntroscreenText3')}</li>
										<li>
											{t('M2chapter01IntroscreenText4')}

										</li>
										<li>
											{t('M2chapter01IntroscreenText5')}
										</li>
										<li>{t('M2chapter01IntroscreenText6')}</li>
									</ul>
									<p>
										<b>{t('M2chapter01IntroscreenText7')}</b>{" "}
									</p>
									<ul className="list-style-set list-circle list-number mt-1">
										<li>
											{t('M2chapter01IntroscreenText8')}{" "}
										</li>
										<li>
											{t('M2chapter01IntroscreenText9')}

										</li>
										<li>
											{t('M2chapter01IntroscreenText10')}{" "}
										</li>
										<li>
											{t('M2chapter01IntroscreenText11')}{" "}
										</li>
									</ul>
								</div>
								{/* <p>
				<b>Duration of the module: </b> 1 week
			</p> */}
							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="60%"
										draggable="false"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/module-02-title" />

								{/* <NextBtn link="/module-02/chapter-01-screen-01" text="Go Next" classtype="" /> */}

								{/* <button className="button_su_inner" onClick={handleSavePage} data-key_name="M2C1" data-key_url="/module-02/chapter-01-intro-screen" >{t('CommonBtnGoNext')}</button> */}




								<button
									className={`button_su`}
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<span className="su_button_circle" />
									<button className="button_su_inner" onClick={handleSavePage} data-key_name="M2C1" data-key_url="/module-02/chapter-01-intro-screen">
										<span className="button_text_container">
											{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
										</span>
									</button>
								</button>



							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


	);
};

export default Chapter01IntroScreen;
