import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';


const Chapter01Screen02 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="1" star="1" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-3">
								<div
									className="col-lg-6 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h2 mb-1">{t('learn')}</h2>
									<p className="mb-2 mt-3">
										{t('M4chapter01screen02Text1')}
										
									</p>
								</div>
								<div
									className="col-lg-5 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="95%"
											draggable="false"
											src="/assets/img/md4/md4-shap-1-2.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-01-screen-02-First" />
									<NextBtn link="/module-04/chapter-01-screen-04" text={t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen02;
