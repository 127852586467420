import React, { useState, useEffect } from "react";

import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';



const Chapter03Screen02 = () => {
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
	const [checkedQues1, setCheckedQues1] = useState('');
	const [checkedQues2, setCheckedQues2] = useState('');
	const [checkedQues16, setCheckedQues16] = useState('');
	const [checkedQues17, setCheckedQues17] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues1,
					"ques_id": "1"
				},
				"ques2": {
					"ans": checkedQues2,
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues16,
					"ques_id": "16"
				},
				"ques4": {
					"ans": checkedQues17,
					"ques_id": "17"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-03-screen-03')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const checkedVal = (param, ans) => {

		if (param === '16') {
			setCheckedQues16(ans)
		}

		if (param === '17') {
			setCheckedQues17(ans)
		}

		if (param === '1') {
			setCheckedQues1(ans)
		}

		if (param === '2') {
			setCheckedQues2(ans)
		}




	}


	const BtnDisabled = () => {
		if (checkedQues1 !== '' && checkedQues2 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<div className="wrapper page-auto bg-bd-2 hp">
			<div
				className="fixed-header"

			>
				<div className="container-fluid cf-set">
					<Header leftChapter="2/4" percentage="1" star="1" />
				</div>
			</div>
			<div className="d-flex">
				<Sidebar/>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4 mt-m-2">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<p className="mb-2 mt-3">
									{t('M1chapter03screen02Text1')}
								</p>

								<h2 className="h3 mt-4">{t('reflect')} </h2>

								<p className="mb-2 mt-3">{t('M1chapter03screen02Text2')}</p>
								<textarea
									className="form-control"
									rows={1}
									placeholder="Write Something here....."
									defaultValue={checkedQues16}
									onChange={(e) => setCheckedQues16(e.target.value)}
								/>
								<p className="mb-2 mt-3">
									{t('M1chapter03screen02Text3')} </p>
								<textarea
									className="form-control"
									rows={1}
									placeholder="Write Something here....."
									defaultValue={checkedQues1}
									onChange={(e) => setCheckedQues1(e.target.value)}
								/>


								<div className="mt-4 positon-submit">
								</div>

								<p> {t('M1chapter03screen02Text4')}</p>
								<textarea
									className="form-control"
									rows={1}
									placeholder="Write Something here....."
									defaultValue={checkedQues17}
									onChange={(e) => setCheckedQues17(e.target.value)}
								/>

								<p>
									{t('M1chapter03screen02Text5')}
									</p>
								<textarea
									className="form-control"
									rows={1}
									placeholder="Write Something here....."
									defaultValue={checkedQues2}
									onChange={(e) => setCheckedQues2(e.target.value)}
								/>



							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="48%"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-03-screen-01" />

								{/* <NextBtn link="/module-02/chapter-03-screen-03" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Chapter03Screen02;
