import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import BaseURL, { AdminUrl } from "../../../config/Baseurl";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen34 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [checkedQues34, setCheckedQues34] = useState();
	const [imageVal, setImagevalue] = useState();

	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const [images, setImages] = useState([]);

	const onImageChange = async (e) => {

		// setCheckBlankData("filled");

		setImages([...e.target.files]);



		const questId = '34';

		const user_details = JSON.parse(localStorage.getItem("user_details"));
		const user_id = user_details.user_id;
		const files = e.target.files;
		const formData = new FormData();


		formData.append("image1", files[0]);
		formData.append("user_id", user_id);
		formData.append("ques_id", questId);

		// console.log(user_details.user_id);

		fetch(BaseURL + "/sexualityImageUpload", {
			method: "POST",
			body: formData,
		}).then((res) => {

			res.json().then((result) => {


			});
		});

		// console.log(questId);
	};


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {


				"ques34": {
					"ans": checkedQues34,


					"ques_id": "34"
				}


			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-04-screen-34n')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans, ans2) => {


		if (param === '34') {

			setCheckedQues34(ans)
			setImagevalue(ans2)

		}




	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans, result.ans2)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues34 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-0">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3">{t('share')}  </h3>
									<p className="mb-2 mt-3">{t('M3chapter04Screen34Text1')} </p>

									<ul className="list-style-set list-circle mt-3 mb-3">
										<li>{t('M3chapter04Screen34Text2')}</li>
										<li>{t('M3chapter04Screen34Text3')} </li>
										<li>{t('M3chapter04Screen34Text4')}  </li>

									</ul>

									<p className="mb-2 mt-3"><b>{t('M3chapter04Screen34Text5')}</b>  </p>


									<div className="mb-3 positon-submit">
										<textarea
											className="form-control "
											rows={1}
											placeholder="Write Something here....."
											onChange={(e) => setCheckedQues34(e.target.value)}
											data-quesId="34"
											defaultValue={checkedQues34}
										/>
									</div>
									<div class="file-input">
										<input type="file" name="file-input" id="file-input" class="file-input__input" onChange={onImageChange} />
										<label class="file-input__label" for="file-input">
											<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" class="svg-inline--fa fa-upload fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
												<path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
											</svg>
											<span>{t('UploadBtn')}</span></label>
									</div>
									<a target="__blank" href={AdminUrl + "/public/mascul/" + imageVal}>{imageVal}</a>
								</div>



								<div className="col-lg-4">
									<center>
										<img
											width="77%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-4-34.png"
											alt=""
										/>
									</center>
								</div>




							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-33" />
								{/* <NextBtn link="/module-03/chapter-04-screen-35" text="Go Next"/> */}
								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>




		</>
	);
};

export default Chapter04Screen34;
