import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen11 = () => {
    const { t, i18n } = useTranslation();
const navigate = useNavigate();
const [checkedQues2GroupA, setCheckedQues2GroupA] = useState('');
const [checkedQues2GroupB, setCheckedQues2GroupB] = useState('');
const [checkedQues2GroupC, setCheckedQues2GroupC] = useState('');
const [checkedQues2GroupD, setCheckedQues2GroupD] = useState('');
const [checkedQues2GroupE, setCheckedQues2GroupE] = useState('');
const [checkedQues2Val, setCheckedQues2Val] = useState('');

const [checkedQues3GroupA, setCheckedQues3GroupA] = useState('');
const [checkedQues3GroupB, setCheckedQues3GroupB] = useState('');
const [checkedQues3GroupC, setCheckedQues3GroupC] = useState('');
const [checkedQues3GroupD, setCheckedQues3GroupD] = useState('');
const [checkedQues3GroupE, setCheckedQues3GroupE] = useState('');
const [checkedQues3Val, setCheckedQues3Val] = useState('');



const user_details = JSON.parse(localStorage.getItem('user_details'));
const saveRadio = (e)=>{
const quesId = e.target.getAttribute("data-quesId");

checkedVal(quesId,e.target.value)
}

const saveAns = () => {
var data = JSON.stringify({
"user_id": user_details.user_id,
"answers": {
"ques1": {
"ans": "",
"ques_id": "1"
},
"ques2": {
"ans": checkedQues2Val,
"ques_id": "2"
},
"ques3": {
"ans": checkedQues3Val,
"ques_id": "3"
},
"ques4": {
"ans": "",
"ques_id": "4"
},
"ques5": {
"ans": "",
"ques_id": "5"
},
"ques6": {
"ans": "",
"ques_id": "6"
},
"ques7": {
"ans": "",
"ques_id": "7"
},
"ques8": {
"ans": "",
"ques_id": "8"
}
}
});
var config = {
method: 'post',
url: BaseURL +'/MasculChapter02',
headers: {
'Authorization': `Bearer ${user_details.token}`,
'Content-Type': 'application/json'
},
data: data
};
axios(config)
.then(function (response) {
if(response.data.success === true)
{
navigate('/module-04/chapter-02-screen-12')
}
})
.catch(function (error) {
console.log(error);
});
}
const BtnDisabled = () => {
if (checkedQues2Val !== '' && checkedQues3Val !== '') {
return (
<>
    <button className={`button_su`} data-wow-duration="1s" data-wow-delay="0.2s">
        <span className="su_button_circle" />
        <button onClick={saveAns} className="button_su_inner">
            <span className="button_text_container">
            {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
            </span>
        </button>
    </button>
</>
);
} else {
return (
<>
    <button className="button_su disabled-btn" type="button" disabled="">
        <span className="su_button_circle"></span>
        <span className="button_su_inner">
            <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
            </span>{" "}
        </span>
    </button>
</>
);
}
};
const checkedVal = (param,ans) => {

  if (param === '2') {
    // alert(param);
  if (ans === 'Group A') {
  setCheckedQues2GroupA('checked')
  setCheckedQues2GroupB('');
  setCheckedQues2GroupC('')
  setCheckedQues2GroupD('')
  setCheckedQues2GroupE('')
  } else if (ans === 'Group B') {
  setCheckedQues2GroupA('')
  setCheckedQues2GroupB('checked');
  setCheckedQues2GroupC('')
  setCheckedQues2GroupD('')
  setCheckedQues2GroupE('')
  }else if (ans === 'Group C') {
  setCheckedQues2GroupA('')
  setCheckedQues2GroupB('');
  setCheckedQues2GroupC('checked')
  setCheckedQues2GroupD('')
  setCheckedQues2GroupE('')
  }else if (ans === 'Group D') {
  setCheckedQues2GroupA('')
  setCheckedQues2GroupB('');
  setCheckedQues2GroupC('')
  setCheckedQues2GroupD('checked')
  setCheckedQues2GroupE('')
  }else if (ans === 'Group E') {
  setCheckedQues2GroupA('')
  setCheckedQues2GroupB('');
  setCheckedQues2GroupC('')
  setCheckedQues2GroupD('')
  setCheckedQues2GroupE('checked')
  }
  setCheckedQues2Val(ans)
  }

  if (param === '3') {
    
    if (ans === 'Group A') {
    setCheckedQues3GroupA('checked')
    setCheckedQues3GroupB('');
    setCheckedQues3GroupC('')
    setCheckedQues3GroupD('')
    setCheckedQues3GroupE('')
    } else if (ans === 'Group B') {
    setCheckedQues3GroupA('')
    setCheckedQues3GroupB('checked');
    setCheckedQues3GroupC('')
    setCheckedQues3GroupD('')
    setCheckedQues3GroupE('')
    }else if (ans === 'Group C') {
    setCheckedQues3GroupA('')
    setCheckedQues3GroupB('');
    setCheckedQues3GroupC('checked')
    setCheckedQues3GroupD('')
    setCheckedQues3GroupE('')
    }else if (ans === 'Group D') {
    setCheckedQues3GroupA('')
    setCheckedQues3GroupB('');
    setCheckedQues3GroupC('')
    setCheckedQues3GroupD('checked')
    setCheckedQues3GroupE('')
    }else if (ans === 'Group E') {
    setCheckedQues3GroupA('')
    setCheckedQues3GroupB('');
    setCheckedQues3GroupC('')
    setCheckedQues3GroupD('')
    setCheckedQues3GroupE('checked')
    }
    setCheckedQues3Val(ans)
    }

    

}

useEffect(() => {
var data = JSON.stringify({
"user_id": user_details.user_id,
"ques_id": ""
});
var config = {
method: 'post',
url: BaseURL + '/MasculChapter02Result',
headers: {
'Authorization': `Bearer ${user_details.token}`,
'Content-Type': 'application/json'
},
data: data
};
axios(config)
.then(function (response) {
if (response.data.success === true) {
const results = response.data.data.chapter;
// console.log(results);
results.map((result) => {
checkedVal(result.ques_id.toString(),result.ans)
})
}
})
.catch(function (error) {
console.log(error);
});
}, [])

return (
<>
    <div className="wrapper bg-bd-2 hp">
        <div className="fixed-header">
            <div className="container-fluid cf-set">
                <Header leftChapter="1/5" percentage="40" star="36" />
            </div>
        </div>
        <div className="d-flex">
					<Sidebar />
            <div className="data-adjust">
                <div className="container-fluid cf-set">
                    <div className="row align-items-top justify-content-between pt-20 mt-3 mt-m-3">
                        <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                            <h3 className="h3 mb-2 mt-3">
                                <b>
                                    {t('M4chapter02screen11Text1')}
                                    
                                </b>
                            </h3>
                            <div className="d-flex ">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input custom-check" type="radio" name="namecircle"
                                        id="mycirle" defaultValue="Group A" onClick={saveRadio} data-quesId="2"
                                        checked={checkedQues2GroupA} />
                                    <label className="form-check-label" htmlFor="mycirle">
                                        {" "}
                                        {t('GroupA')}
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input custom-check" type="radio" name="namecircle"
                                        id="mycirle2" defaultValue="Group B" onClick={saveRadio} data-quesId="2"
                                        checked={checkedQues2GroupB} />
                                    <label className="form-check-label" htmlFor="mycirle2">
                                    {t('GroupB')}{" "}
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input custom-check" type="radio" name="namecircle"
                                        id="mycirle3" defaultValue="Group C" onClick={saveRadio} data-quesId="2"
                                        checked={checkedQues2GroupC} />
                                    <label className="form-check-label" htmlFor="mycirle3">
                                        {" "}
                                        {t('GroupC')}
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex ">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input custom-check" type="radio" name="namecircle"
                                        id="mycirle4" defaultValue="Group D" onClick={saveRadio} data-quesId="2"
                                        checked={checkedQues2GroupD} />
                                    <label className="form-check-label" htmlFor="mycirle4">
                                        {" "}
                                        {t('GroupD')}
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input custom-check" type="radio" name="namecircle"
                                        id="mycirle5" defaultValue="Group E" onClick={saveRadio} data-quesId="2"
                                        checked={checkedQues2GroupE} />
                                    <label className="form-check-label" htmlFor="mycirle5">
                                    {t('GroupE')}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
                            <h3 className="h3 mb-2 mt-3">
                                <b>
                                {t('M4chapter02screen11Text2')}
                                    
                                </b>
                            </h3>
                            <div className="d-flex">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input custom-check" type="radio" name="namecircle1"
                                        id="mycirle7" defaultValue="Group A" onClick={saveRadio} data-quesId="3"
                                        checked={checkedQues3GroupA} />
                                    <label className="form-check-label" htmlFor="mycirle7">
                                        {" "}
                                        {t('GroupA')}
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input custom-check" type="radio" name="namecircle1"
                                        id="mycirle8" defaultValue="Group B" onClick={saveRadio} data-quesId="3"
                                        checked={checkedQues3GroupB} />
                                    <label className="form-check-label" htmlFor="mycirle8">
                                    {t('GroupB')}
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input custom-check" type="radio" name="namecircle1"
                                        id="mycirle9" defaultValue="Group C" onClick={saveRadio} data-quesId="3"
                                        checked={checkedQues3GroupC} />
                                    <label className="form-check-label" htmlFor="mycirle9">
                                    {t('GroupC')}{" "}
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex ">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input custom-check" type="radio" name="namecircle1"
                                        id="mycirle10" defaultValue="Group D" onClick={saveRadio} data-quesId="3"
                                        checked={checkedQues3GroupD} />
                                    <label className="form-check-label" htmlFor="mycirle10">
                                    {t('GroupD')}{" "}
                                    </label>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input custom-check" type="radio" name="namecircle1"
                                        id="mycirle11" defaultValue="Group E" onClick={saveRadio} data-quesId="3"
                                        checked={checkedQues3GroupE} />
                                    <label className="form-check-label" htmlFor="mycirle11">
                                    {t('GroupE')}{" "}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="button-fixed wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
            <div className="container-fluid cf-set">
                <div className="row">
                    <div className="col-lg-12">
                        <div className=" d-flex justify-content-end">
                            <PrevBtn link="/module-04/chapter-02-screen-10" />

                            {/*
                            <NextBtn link="/module-04/chapter-02-screen-12" text="Go Next" /> */}
                            <BtnDisabled />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
);
};

export default Chapter02Screen11;
