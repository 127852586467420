import React, { useState, useRef, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter04Screen08 = () => {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  // const [list, setList] = useState(['Item 1','Item 2','Item 3','Item 4','Item 5','Item 6']);
  const [list, setList] = useState([
    { "id": "1", "name": "AskingForIt" },
    { "id": "2", "name": "Bell Bajao" },
    { "id": "3", "name": "StandWithMe" },
    { "id": "4", "name": "WebWonderWomen" },

  ]);

  let descText1 = t('M6chapter04screen08Text8')
  let descText2 = t('M6chapter04screen08Text9')
  let descText3 = t('M6chapter04screen08Text10')
  let descText4 = t('M6chapter04screen08Text11')

  const [list1, setList1] = useState([
    { "id": "1", "desc": descText1, "image": "assets/img/md3/tree.png" },
    { "id": "2", "desc": descText2, "image": "assets/img/md3/tree2.jpg" },
    { "id": "3", "desc": descText3, "image": "assets/img/md3/tree3.jpg" },
    { "id": "4", "desc": descText4, "image": "assets/img/md3/tree4.jpg" },

  ]);




  const dragStart = (e, position) => {
    dragItem.current = position;
    console.log(e.target.innerHTML);
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
    console.log(e.target.innerHTML);
  };

  const drop = (e) => {
    const copyListItems = [...list1];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setList1(copyListItems);
  };

  // setArrangeList(list)
  console.log(list);

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveAns = () => {
    // alert();
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {

        "ques9": {
          "ans": JSON.stringify(list),
          "ans2": JSON.stringify(list1),
          "ques_id": "9"
        },

      }
    });
    var config = {
      method: 'post',
      url: BaseURL + '/ApproachChapter04',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate('/module-06/chapter-04-screen-12')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {

    var data = JSON.stringify({
      "user_id": user_details.user_id,

      "ques_id": "9"
    });

    var config = {
      method: 'post',
      url: BaseURL + '/approachChapter04Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          // console.log(JSON.parse(response.data.data.chapter.ans));
          const ans = JSON.parse(response.data.data.chapter.ans)
          const ans2 = JSON.parse(response.data.data.chapter.ans2)

          // console.log(ans);
          setList(ans);
          setList1(ans2);





        }
      })
      .catch(function (error) {
        console.log(error);
      });

  }, []);



  const BtnDisabled = () => {
    if (list.length > 0) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };



  return (
    <>
      <div className="wrapper page-auto bg-bd-2 hp">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
            <Header leftChapter="3/4" percentage="90" star="50" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-between pt-20 mr-3 mt-m-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >

                  <p className="mb-2 mt-3 pn"><span><b>3.</b></span><b>{t('M6chapter04screen08Text1')}</b> </p>

                  <div className="row">
                    <div className="col-md-6">
                      <table className="table table-bordered table-dark table-red hand-cursor">

                        <tbody>
                          <tr>
                            <td>{t('sno')}</td>
                            <td>{t('M6chapter04screen08Text2')}</td>
                          </tr>

                          <tr className="color-dark-red first-width">
                            <td>1</td>
                            <td>{t('M6chapter04screen08Text3')} &nbsp;<br></br>&nbsp;<br></br>&nbsp;&nbsp;<br></br>&nbsp;<br></br>&nbsp;</td>
                          </tr>
                          <tr className="color-dark-red first-width">
                            <td>2</td>
                            <td>{t('M6chapter04screen08Text4')}&nbsp;<br></br>&nbsp;<br></br>&nbsp;&nbsp;<br></br>&nbsp;<br></br>&nbsp;</td>

                          </tr>
                          <tr className="color-dark-red first-width">
                            <td>3</td>
                            <td>{t('M6chapter04screen08Text5')} &nbsp;<br></br>&nbsp;<br></br>&nbsp;&nbsp;<br></br>&nbsp;<br></br>&nbsp;</td>
                          </tr>

                          <tr className="color-dark-red first-width">
                            <td>4</td>
                            <td>{t('M6chapter04screen08Text6')} &nbsp;<br></br>&nbsp;<br></br>&nbsp;&nbsp;<br></br>&nbsp;</td>
                          </tr>





                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-6">
                      <table className="table table-bordered table-dark table-red hand-cursor">

                        <tbody>
                          <tr>
                            <td>{t('ID')}</td>
                            <td>{t('M6chapter04screen08Text7')}</td>
                          </tr>
                          {
                            list1.map((item, index) => {
                              return (
                                <>
                                  <tr className="color-dark-red first-width">
                                    <td>{item.id}</td>
                                    <td>
                                      <div
                                        onDragStart={(e) => dragStart(e, index)}
                                        onDragEnter={(e) => dragEnter(e, index)}
                                        onDragEnd={drop}
                                        key={index}
                                        draggable
                                      >
                                        {item.desc}

                                      </div>
                                    </td>
                                  </tr>
                                </>
                              )
                            })
                          }


                        </tbody>
                      </table>
                    </div>
                  </div>





                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">

                <PrevBtn link="/module-06/chapter-04-screen-07" />
                {/* <NextBtn link="/module-06/chapter-04-screen-09" text="Go Next"/> */}
                <BtnDisabled />


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter04Screen08;
