import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen02 = () => {

	const { t, i18n } = useTranslation();
	const navigate = useNavigate();



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3">
								<div
									className="col-lg-9 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mb-2  mt-3"> {t('M3chapter02Screen02Text1')} </p>
									<h3 className="h3">{t('engage')}  </h3>


									<a href="https://www.youtube.com/watch?v=ztVAqPmYlU0" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p> <b>{t('M3chapter02Screen02Text2')} </b> </p>
											</div>
										</div>
									</a>



									<p className="mb-2  mt-3">{t('M3chapter02Screen02Text3')}  </p>
									<p>{t('M3chapter02Screen02Text4')}</p>
									<ul>
										<li>{t('M3chapter02Screen02Text5')}</li>
										<li>{t('M3chapter02Screen02Text6')}</li>
										<li>{t('M3chapter02Screen02Text7')}</li>
									</ul>







								</div>

								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="70%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-2-2.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-02-screen-01" />
								<NextBtn link="/module-03/chapter-02-screen-02n" text={t('CommonBtnGoNext')}/>
								{/* <BtnDisabled /> */}


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
