import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate, NavLink } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen04 = () => {
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
	const [checkedQues6, setCheckedQues6] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));




	const saveAns = () => {

		// alert();
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {

				"ques6": {
					"ans": checkedQues6,
					"ques_id": "6"
				},


			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-02-screen-05')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans) => {


		if (param === '6') {

			setCheckedQues6(ans)
		}




	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])




	const BtnDisabled = () => {

			return (
				<>


					<button className="button_su" >
						<span className="su_button_circle desplode-circle">
						</span>
						<button  className="button_su_inner" data-bs-toggle="modal" data-bs-target="#staticBackdrop" >
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);

	};


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20">




								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

								<h4 className="h3">{t('learn')}</h4>
									<p className="mb-2  mt-3"> <b>{t('M3chapter02Screen04Text1')}</b> </p>

									<ul class="list-style-set list-circle mt-1">
										<li>{t('M3chapter02Screen04Text2')} </li>
										<li> {t('M3chapter02Screen04Text3')} </li>
										<li>  {t('M3chapter02Screen04Text4')}  </li>


									</ul>


								</div>

								<div
									className="col-lg-4 mt-5 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="100%"
											draggable="false"
											src="/assets/img/md5/here.jpg"
											alt=""
										/>
									</center>
								</div>







							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-02-screen-03" />
								{/* <button link="/module-05/chapter-02-screen-05" data-bs-toggle="modal" data-bs-target="#staticBackdrop"  text="Go Next"/> */}
								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>

			 <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
						  <div className="modal-dialog modal-dialog-centered modal-xl">
							<div className="modal-content modal-bg-blue">
							  <div className="modal-body">
								<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

								<p className="text-white text-left">{t('M3chapter02Screen04Text5')}
								</p>



								<div class=" d-flex justify-content-center mt-3">
								  <button className="button_su" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>

									<NavLink to="/module-03/chapter-02-screen-04n"   className="button_su_inner"><span className="button_text_container"> {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" /></span> </NavLink>
								  </button>
								</div>




							  </div>
							</div>
						  </div>
						</div>
		</>
	);
};

export default Chapter02Screen04;
