import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
// import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL, { AdminUrl } from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter02Screen03 = () => {

	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
	const [checkedQues1, setCheckedQues1] = useState('');
	const [penChecked, setPenChecked] = useState('');
	const [pencilChecked, setPencilChecked] = useState('');
	const [penPencilChecked, setPenPencilChecked] = useState('');
	const [butterChecked, setButterChecked] = useState('');
	const [jamChecked, setJamChecked] = useState('');
	const [butterJamChecked, setButterJamChecked] = useState('');
	const [smartChecked, setSmartChecked] = useState('');
	const [lapChecked, setLapChecked] = useState('');
	const [smartLapChecked, setSmartLapChecked] = useState('');
	const [imageUrl, setImageUrl] = useState();


	const [images, setImages] = useState([]);


	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const onImageChange = async (e) => {

		// setCheckBlankData("filled");

		setImages([...e.target.files]);



		const questId = '1';

		const user_id = user_details.user_id;
		const files = e.target.files;
		const formData = new FormData();


		formData.append("image1", files[0]);
		formData.append("user_id", user_id);
		formData.append("ques_id", questId);

		// console.log(user_details.user_id);

		fetch(BaseURL + "/selfImageUpload", {
			method: "POST",
			body: formData,
		}).then((res) => {

			res.json().then((result) => {


			});
		});

		// console.log(questId);
	};


	// const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const itemVal = e.target.value;
		if (quesId === '2_1') {
			setPenPencilChecked(itemVal);
			checkedVal(quesId, itemVal)
		}

		if (quesId === '2_2') {
			setButterJamChecked(itemVal);
			checkedVal(quesId, itemVal)
		}

		if (quesId === '2_3') {
			setSmartLapChecked(itemVal);
			checkedVal(quesId, itemVal)
		}


	}


	// console.log(checkedQues1);
	const saveAns = () => {
		// console.log('helo');
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues1,
					"ques_id": "1"
				},
				"ques2": {
					"ans": "fasdfsa",
					// "ans2": "",

					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				console.log(response);
				if (response.data.success === true) {
					navigate('/module-01/chapter-02-screen-03n')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues1 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '1') {

			setCheckedQues1(ans)
		}

		if (ans === 'pen') {

			setPenChecked('checked');
			setPencilChecked('');
		}
		if (ans === 'pencil') {

			setPencilChecked('checked');
			setPenChecked('');
		}

		if (ans === 'butter') {

			setButterChecked('checked')
			setJamChecked('');
		}

		if (ans === 'jam') {

			setJamChecked('checked');
			setButterChecked('');
		}

		if (ans === 'smartphone') {

			setSmartChecked('checked');
			setLapChecked('')
		}

		if (ans === 'laptop') {

			setLapChecked('checked');
			setSmartChecked('');
		}





	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					results.map((result) => {
						if (result.ques_id === 1) {
							setImageUrl(result.ans2);
						}

						if (result.ques_id !== 2) {

							checkedVal(result.ques_id.toString(), result.ans)
						} else {
							const ___ans = result.ans;
							const res_ans = ___ans.split(',');
							res_ans.map((rel) => {
								if (rel === 'pen' || rel === 'pencil') {
									setPenPencilChecked(rel);
								}


								if (rel === 'butter' || rel === 'jam') {
									setButterJamChecked(rel);
								}

								if (rel === 'smartphone' || rel === 'laptop') {
									setSmartLapChecked(rel);
								}



								checkedVal(result.ques_id.toString(), rel)
							})
							// console.log(___ans);
						}
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="3" star="3" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between pt-20 mt-5 mt-m-3">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h3">{t('reflect')} </h2>
									<p className="mb-2 mt-3">{t('M1chapter02screen03Text1')}</p>

									<div className="row mt-3">
										<div className="col-lg-12 ">
											<div className="positon-submit">
												<textarea
													className="form-control"
													rows={2}
													placeholder="Write Something here....."
													onChange={(e) => setCheckedQues1(e.target.value)}
													defaultValue={checkedQues1}
												/>
											</div>
										</div>

										<div className="col-lg-2 mt-m-3">
											<div class="file-input">
											<br></br>
												<input type="file" name="file-input" id="file-input" class="file-input__input" onChange={onImageChange} />
												<label class="file-input__label" for="file-input">
													<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="upload" class="svg-inline--fa fa-upload fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
														<path fill="currentColor" d="M296 384h-80c-13.3 0-24-10.7-24-24V192h-87.7c-17.8 0-26.7-21.5-14.1-34.1L242.3 5.7c7.5-7.5 19.8-7.5 27.3 0l152.2 152.2c12.6 12.6 3.7 34.1-14.1 34.1H320v168c0 13.3-10.7 24-24 24zm216-8v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h136v8c0 30.9 25.1 56 56 56h80c30.9 0 56-25.1 56-56v-8h136c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"></path>
													</svg>
													<span>{t('UploadBtn')}</span></label>
												<a href={AdminUrl + "/public/mascul/" + imageUrl} target="__blank">{imageUrl}</a>
											</div>
										</div>
									</div>








								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-02-screen-02n" />
								{/* <NextBtn link="/module-02/chapter-02-screen-04" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen03;
