import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen12 = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="1/5" percentage="42" star="42" />
          </div>
        </div>
        <div className="d-flex">
					<Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-between pt-20 mt-2 mt-m-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <p>
                    <span dangerouslySetInnerHTML={{ __html: t('M4chapter02screen12Text1') }}></span>
                    {" "}
                  </p>
                  <p className="mt-2">
                  <span dangerouslySetInnerHTML={{ __html: t('M4chapter02screen12Text2') }}></span>

                  </p>
                  <p className="mt-3">
                  <span dangerouslySetInnerHTML={{ __html: t('M4chapter02screen12Text3') }}></span>

                  </p>
                  <p className="mt-3">
                  <span dangerouslySetInnerHTML={{ __html: t('M4chapter02screen12Text4') }}></span>

                  </p>
                  <p className="mt-3">
                  {/* <b> {t('M4chapter02screen12Text5')}</b>{" "} */}
                  </p>


                  <p className="">
                  {t('M4chapter02screen12Text6')}
                   </p>


                </div>

              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-04/chapter-02-screen-11"/>

                <NextBtn link="/module-04/chapter-02-screen-13" text={t('CommonBtnGoNext')}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen12;
