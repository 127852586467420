import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter05Screen07 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [checkedQues4Yes, setCheckedQues4Yes] = useState('');
	const [checkedQues4No, setCheckedQues4No] = useState('');
	const [checkedQues4Val, setCheckedQues4Val] = useState('');


	const [checkedQues5, setCheckedQues5] = useState([]);

	const [economicChecked, setEconomicChecked] = useState('');
	const [sexualChecked, setSexualChecked] = useState('');
	const [verbalChecked, setVerbalChecked] = useState('');
	const [psychologicalChecked, setPsychologicalChecked] = useState('');
	const [emotionalChecked, setEmotionalChecked] = useState('');
	const [PhysicalChecked, setPhysicalChecked] = useState('');

	//  const Ques4 = (checkedQues4Yes !== '')?checkedQues4Val:checkedQues4Val;

	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveGenderBased = (e) => {
		const res = e.target.value;
		const checked = e.target.checked;


		checkBoxChecked(res, checked);
		setCheckedQues5(checkedQues5 => [...checkedQues5, res]);
	}

	const saveRadio = (e) => {
		checkedVal('4', e.target.value)

	}

	// console.log(checkedQues5);
	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4Val,
					"ques_id": "4"
				},
				"ques5": {
					"ans": checkedQues5.join(','),
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter05',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-02/chapter-05-screen-07n')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	// alert(checkedQues4Val);
	const BtnDisabled = () => {
		if (checkedQues4Val != '' ) {

			if(checkedQues4Val == 'yes')
			{
				if(checkedQues5.length > 0)
				{
					return (
						<>
							<button
								className={`button_su`}
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<span className="su_button_circle" />
								<button onClick={saveAns} className="button_su_inner">
									<span className="button_text_container">
									{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
									</span>
								</button>
							</button>
						</>
					);
				}else{
					return (
						<>
							<button className="button_su disabled-btn" type="button" disabled="">
								<span className="su_button_circle"></span>
								<span className="button_su_inner">
									<span className="button_text_container">
										{" "}
										{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
									</span>{" "}
								</span>
							</button>
						</>
					);
				}

			}else if(checkedQues4Val == 'no')
			{
				return (
					<>
						<button
							className={`button_su`}
							data-wow-duration="1s"
							data-wow-delay="0.2s"
						>
							<span className="su_button_circle" />
							<button onClick={saveAns} className="button_su_inner">
								<span className="button_text_container">
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
								</span>
							</button>
						</button>
					</>
				);
			}

		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	const checkedVal = (param, ans) => {


		if (param === '4') {
			if (ans === 'yes') {
				setCheckedQues4Yes('checked')
				setCheckedQues4No('')
				setCheckedQues4Val(ans)
			} else {
				if (ans === 'no') {
					setCheckedQues4No('checked')
					setCheckedQues4Yes('')
					setCheckedQues4Val(ans)
				}
			}
		}

		if (param === '5') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked);
			})
			setCheckedQues5(ansArr)
		}


	}

	const checkBoxChecked = (val, checked) => {
		if (val === 'Economic') {
			if (checked === true) {
				setEconomicChecked('checked');

			} else {
				setEconomicChecked('');
			}
		}

		if (val === 'Sexual') {
			if (checked === true) {
				setSexualChecked('checked');
			} else {
				setSexualChecked('');
			}
		}

		if (val === 'Verbal') {
			if (checked === true) {
				setVerbalChecked('checked');
			} else {
				setVerbalChecked('');
			}
		}


		if (val === 'Psychological') {
			if (checked === true) {
				setPsychologicalChecked('checked');
			} else {
				setPsychologicalChecked('');
			}
		}
		if (val === 'Emotional') {
			if (checked === true) {
				setEmotionalChecked('checked');
			} else {
				setEmotionalChecked('');
			}
		}

		if (val === 'Physical') {
			if (checked === true) {
				setPhysicalChecked('checked');
			} else {
				setPhysicalChecked('');
			}
		}

	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter05Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="88" star="68" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h2">{t('reflet_share')}</h2>
									<p className="mb-2">
										{t('M2chapter05screen07Text1')}
										{" "}
									</p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="ques4"
												id="inlineRadio1"
												defaultValue="yes"
												onClick={saveRadio}
												checked={checkedQues4Yes}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
												{t('yes')}
											</label>
										</div>
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="ques4"
												id="inlineRadio2"
												defaultValue="no"
												onClick={saveRadio}
												checked={checkedQues4No}
											/>
											<label className="form-check-label " htmlFor="inlineRadio2">
											{t('no')}
											</label>
										</div>
									</div>
									<p className="mb-4">
										{t('M2chapter05screen07Text2')}
										{" "}
									</p>

									<div className="checkbox-design">
										<form>
											<div className="d-flex">
												<div className="form-check form-check-inline">
													<input
														className="form-check-input custom-check"
														type="checkbox"
														name="namecircle"
														id="mycirle1"
														defaultValue="Economic"
														onClick={saveGenderBased}
														checked={economicChecked}
													/>
													<label className="form-check-label" htmlFor="mycirle1">
														{t('economic')}
													</label>
												</div>
											</div>

											<div className="d-flex">
												<div className="form-check form-check-inline">
													<input
														className="form-check-input custom-check"
														type="checkbox"
														name="namecircle"
														id="mycirle2"
														defaultValue="Sexual"
														onClick={saveGenderBased}
														checked={sexualChecked}
													/>
													<label className="form-check-label" htmlFor="mycirle2">
														{t('sexual')}
													</label>
												</div>
											</div>

											<div className="d-flex">
												<div className="form-check form-check-inline">
													<input
														className="form-check-input custom-check"
														type="checkbox"
														name="namecircle"
														id="mycirle3"
														defaultValue="Verbal"
														onClick={saveGenderBased}
														checked={verbalChecked}
													/>
													<label className="form-check-label" htmlFor="mycirle3">
													{t('verbal')}
													</label>
												</div>
											</div>

											<div className="d-flex">
												<div className="form-check form-check-inline">
													<input
														className="form-check-input custom-check"
														type="checkbox"
														name="namecircle"
														id="mycirle4"
														defaultValue="Psychological"
														onClick={saveGenderBased}
														checked={psychologicalChecked}
													/>
													<label className="form-check-label" htmlFor="mycirle4">
														{t('psychological')}
													</label>
												</div>
											</div>

											<div className="d-flex">
												<div className="form-check form-check-inline">
													<input
														className="form-check-input custom-check"
														type="checkbox"
														name="namecircle"
														id="mycirle5"
														defaultValue="Emotional"
														onClick={saveGenderBased}
														checked={emotionalChecked}
													/>
													<label className="form-check-label" htmlFor="mycirle5">
														{t('emotional')}
													</label>
												</div>
											</div>

											<div className="d-flex">
												<div className="form-check form-check-inline">
													<input
														className="form-check-input custom-check"
														type="checkbox"
														name="namecircle"
														id="mycirle6"
														defaultValue="Physical"
														onClick={saveGenderBased}
														checked={PhysicalChecked}
													/>
													<label className="form-check-label" htmlFor="mycirle6">
														{t('physical')}
													</label>
												</div>
											</div>

										</form>
									</div>
								</div>
								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img width="53%" src="/assets/img/md1/md1-shap-3-7.png" alt="" />
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-05-screen-05" />
								{/* <NextBtn
                    link="/module-01/chapter-05-screen-07n"
                    text="Go Next"
                  /> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen07;
