import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter01Screen15 = () => {
	const { t, i18n } = useTranslation();

	return (
		<>
			<div className="wrapper md-5-bg">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="5" star="4" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">

							<div className="row align-items-top justify-content-center pt-20 mt-5 mt-m-3">
								<div className="col-lg-8 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s" style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.1s', animationName: 'fadeInDown' }}>
									<h4>{t('learn')}</h4>
									<p>{t('M5chapter01Screen15Text1')} </p>

									<p className="mt-3">{t('M5chapter01Screen15Text2')} </p>

									<p className="mt-3">{t('M5chapter01Screen15Text3')}</p>

									<p className="mt-3">{t('M5chapter01Screen15Text4')}
									</p>




								</div>
								<div className="col-lg-4 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDuration: '1s', animationDelay: '0.2s', animationName: 'fadeInDown' }}>
									<center>
										<img width="60%" src="/assets/img/md3/md3-shap-3-7.png" alt="" />
									</center>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>


			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-05/chapter-01-screen-14" />

								<NextBtn link="/module-05/chapter-01-screen-16" text={t('CommonBtnGoNext')} />
								{/* <BtnDisabled/> */}

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen15;
