import React, { useState, useEffect } from "react";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Header from "../../../header/Header";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen04N = () => {

const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [checkedQues16_1, setCheckedQues16_1] = useState('');
	const [checkedQues16_2, setCheckedQues16_2] = useState('');
	const [checkedQues16_3, setCheckedQues16_3] = useState('');
	const [checkedQues16_4, setCheckedQues16_4] = useState('');
	const [checkedQues16_5, setCheckedQues16_5] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques11": {
					"ans": checkedQues16_3 + "," + checkedQues16_4 + "," + checkedQues16_5,
					"ques_id": "16"
				}




			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-03/chapter-02-screen-04n1')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": "16"
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter.ans;
					const res = results.split(",");
					res.map((elem, i) => {
						console.log(i);

						if (i === 0) {
							setCheckedQues16_3(elem)
						}
						if (i === 1) {
							setCheckedQues16_4(elem)
						}
						if (i === 2) {
							setCheckedQues16_5(elem)
						}
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	const BtnDisabled = () => {
		if (checkedQues16_3 !== '' && checkedQues16_4 !== ' ' && checkedQues16_5 !== ''
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
                            {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>

                <div className="d-flex">
					<Sidebar />

				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3 className="h3">{t('reflect')} </h3>
								<p className="mb-2  mt-3">{t('M3chapter02Screen04NText1') }</p>
								<ul className="list-style-set list-input mt-3 mb-3">
									<li>1. {t('M3chapter02Screen04NText2')}



									</li>
									<li>2. {t('M3chapter02Screen04NText3')}


									</li>
									<li>3. <input type="text"
										onChange={(e) => { setCheckedQues16_3(e.target.value) }}
										data-quesId="16"
										defaultValue={checkedQues16_3}
										placeholder className="input-without-style form-control" /></li>
									<li>4. <input type="text"
										onChange={(e) => { setCheckedQues16_4(e.target.value) }}
										data-quesId="16"
										defaultValue={checkedQues16_4}
										placeholder className="input-without-style form-control" /></li>
									<li>5. <input type="text"
										onChange={(e) => { setCheckedQues16_5(e.target.value) }}
										data-quesId="16"
										defaultValue={checkedQues16_5}
										placeholder className="input-without-style form-control" /></li>
								</ul>



							</div>

							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="50%"
										draggable="false"
										src="/assets/img/md5/md-5-chap-1-15.png"
										alt=""
									/>
								</center>
							</div>



						</div>
					</div>
				</div>
                </div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-02-screen-04" />
								{/* <NextBtn link="/module-05/chapter-01-screen-16" text="Go Next"/> */}

								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen04N;
