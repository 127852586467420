import React from 'react'

const Rating = (props) => {


    let rating = props.rating;

    const Rate = ()=>{
        if(rating === 1)
        {
            return (
                <>
                    <i class="fa fa-star active-rating" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i>
                </>
            )
        }

        if(rating === 2)
            {
                return (
                    <>
                        <i class="fa fa-star active-rating" aria-hidden="true"></i> <i class="fa fa-star active-rating" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i>
                    </>
                )
            }

            if(rating === 3)
                {
                    return (
                        <>
                            <i class="fa fa-star active-rating" aria-hidden="true"></i> <i class="fa fa-star active-rating" aria-hidden="true"></i> <i class="fa fa-star active-rating" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i>
                        </>
                    )
                }

                if(rating === 4)
                    {
                        return (
                            <>
                                <i class="fa fa-star active-rating" aria-hidden="true"></i> <i class="fa fa-star active-rating" aria-hidden="true"></i> <i class="fa fa-star active-rating" aria-hidden="true"></i> <i class="fa fa-star active-rating" aria-hidden="true"></i> <i class="fa fa-star" aria-hidden="true"></i>
                            </>
                        )
                    }

                    if(rating === 5)
                        {
                            return (
                                <>
                                    <i class="fa fa-star active-rating" aria-hidden="true"></i> <i class="fa fa-star active-rating" aria-hidden="true"></i> <i class="fa fa-star active-rating" aria-hidden="true"></i> <i class="fa fa-star active-rating" aria-hidden="true"></i> <i class="fa fa-star active-rating" aria-hidden="true"></i>
                                </>
                            )
                        }



    }
    return (
        <>
 {
            <div className='rating'>
                <div>{props.comment}</div>
                  <Rate/>


            </div>
                }
        </>

    )
}

export default Rating