import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';


const Chapter03Screen07 = () => {

	const { t, i18n } = useTranslation();
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const [always, setAlways] = useState();
	const [rarely, setRarely] = useState();

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/selfChapter03ShowResultMsg',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					setAlways(response.data.data.always);
					setRarely(response.data.data.rarely);
					// console.log(response);
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	const Msg = () => {
		if (always >= 5) {
			return (
				<>
				{/* <b>{t('M1chapter03screen07Text1N')}</b> */}
					<p className="mb-2 mt-3">
						{t('M1chapter03screen07Text1')}
					</p>
				</>
			)
		}

		if (rarely >= 5) {
			return (
				<>
					<p className="mb-2 mt-3">
						{t('M1chapter03screen07Text2')}

					</p>
				</>
			)
		}

	}
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="40" star="0" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-4">

								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h2 mb-1"> {t('results')}</h2>
									<Msg />
								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img width="90%" src="/assets/img/md2/md2-shap-3-7.png" alt="" />
									</center>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-03-screen-06" />
								<NextBtn link="/module-01/chapter-03-screen-08" text={t('CommonBtnGoNext')} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	);
};

export default Chapter03Screen07;
