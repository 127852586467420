import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter04Screen12 = () => {
  const navigate = useNavigate();

const { t, i18n } = useTranslation();
  const [checkedQues11, setCheckedQues11] = useState([]);
  const [checkedQues11Option1, setCheckedQues11Option1] = useState('');
  const [checkedQues11Option2, setCheckedQues11Option2] = useState('');
  const [checkedQues11Option3, setCheckedQues11Option3] = useState('');
  const [checkedQues11Option4, setCheckedQues11Option4] = useState('');
  const [checkedQues11Option5, setCheckedQues11Option5] = useState('');
  const [checkedQues11Option6, setCheckedQues11Option6] = useState('');



  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const saveGenderBased = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    const res = e.target.value;
    const checked = e.target.checked;

    checkBoxChecked(res, checked, quesId);

    if (quesId === '11') {
      setCheckedQues11(checkedQues11 => [...checkedQues11, res]);

    }



  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {

        "ques11": {
          "ans": checkedQues11.join(','),
          "ques_id": "11"
        },

      }
    });
    var config = {
      method: 'post',
      url: BaseURL + '/ApproachChapter04',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate('/module-06/score')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues11.length > 0) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param, ans) => {
    if (param === '11') {
      const ansArr = ans.split(',');
      ansArr.map((el) => {
        const checked = true;
        checkBoxChecked(el, checked, param);
      })
      setCheckedQues11(ansArr)
    }




  }
  const checkBoxChecked = (val, checked, quesId) => {
    if (quesId === '11') {
      if (val === 'option1') {
        if (checked === true) {
          setCheckedQues11Option1('checked');
        } else {
          setCheckedQues11Option1('');
        }
      }
      if (val === 'option2') {
        if (checked === true) {
          setCheckedQues11Option2('checked');
        } else {
          setCheckedQues11Option2('');
        }
      }
      if (val === 'option3') {
        if (checked === true) {
          setCheckedQues11Option3('checked');
        } else {
          setCheckedQues11Option3('');
        }
      }
      if (val === 'option4') {
        if (checked === true) {
          setCheckedQues11Option4('checked');
        } else {
          setCheckedQues11Option4('');
        }
      }

      if (val === 'option5') {
        if (checked === true) {
          setCheckedQues11Option5('checked');
        } else {
          setCheckedQues11Option5('');
        }
      }

      if (val === 'option6') {
        if (checked === true) {
          setCheckedQues11Option6('checked');
        } else {
          setCheckedQues11Option6('');
        }
      }


    }




  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/approachChapter04Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(), result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])




  return (
    <>
      <div className="wrapper page-auto bg-bd-2 hp">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
            <Header leftChapter="3/4" percentage="100" star="50" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-between pt-20 mr-3 mt-m-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >



                  <p className="mb-2 mt-3 pn"><span><b>4.</b></span><b>{t('M6chapter04screen12Text1')}</b> </p>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircle"
                        id="mycirle1"
                        defaultValue="option1"
                        onClick={saveGenderBased}
                        data-quesId="11"
                        checked={checkedQues11Option1}

                      />
                      <label className="form-check-label" htmlFor="mycirle1">{t('M6chapter04screen12Text2')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check"
                        type="checkbox"
                        name="namecircle"
                        id="mycirle2"
                        defaultValue="option2"
                        onClick={saveGenderBased}
                        data-quesId="11"
                        checked={checkedQues11Option2}
                      />
                      <label className="form-check-label" htmlFor="mycirle2"> {t('M6chapter04screen12Text3')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircle"
                        id="mycirle3"
                        defaultValue="option3"
                        onClick={saveGenderBased}
                        data-quesId="11"
                        checked={checkedQues11Option3}

                      />
                      <label className="form-check-label" htmlFor="mycirle3">{t('M6chapter04screen12Text4')} </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircle"
                        id="mycirle4"

                        defaultValue="option4"
                        onClick={saveGenderBased}
                        data-quesId="11"
                        checked={checkedQues11Option4}
                      />
                      <label className="form-check-label" htmlFor="mycirle4">{t('M6chapter04screen12Text5')}</label>
                    </div>
                  </div>



                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircle2"
                        id="mycirle5"
                        defaultValue="option5"
                        onClick={saveGenderBased}
                        data-quesId="11"
                        checked={checkedQues11Option5}
                        data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                      />
                      <label className="form-check-label" htmlFor="mycirle5"> {t('M6chapter04screen12Text6')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline mb-7">
                      <input className="form-check-input custom-check" type="checkbox"
                        name="namecircl2"
                        id="mycirle6"
                        defaultValue="option6"
                        onClick={saveGenderBased}
                        data-quesId="11"
                        checked={checkedQues11Option6}
                        data-bs-toggle="modal" data-bs-target="#staticBackdrop2"

                      />
                      <label className="form-check-label" htmlFor="mycirle6"> {t('M6chapter04screen12Text7')}</label>
                    </div>
                  </div>





                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-06/chapter-04-screen-08" />
                {/* <NextBtn link="/module-06/chapter-04-screen-12" text="Go Next"/> */}
                <BtnDisabled />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <p className="text-white "> Please Click </p>
              <a target="__blank" className="text-white line-height-24" href="https://inbreakthrough.org/focus-area/building-gender-equitable-behaviours-in-adolescents-through-government-partnerships/"> https://inbreakthrough.org/focus-area/building-gender-equitable-behaviours-in-adolescents-through-government-partnerships/</a>


            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <p className="text-white "> Please Click </p>
              <a target="__blank" className="text-white" href="https://inbreakthrough.org/men-and-boys/">https://inbreakthrough.org/men-and-boys/</a>


            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default Chapter04Screen12;
