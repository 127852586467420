import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import BaseURL from '../config/Baseurl';
import { NavLink,useLocation  } from "react-router-dom";
const Sidebar = () => {

    const location = useLocation();
    const { t, i18n } = useTranslation();
    const user_details = JSON.parse(localStorage.getItem('user_details'));
    const [pagedata, setPageData] = useState([]);
    const key_name = localStorage.getItem('key_name');
    const [moduleno,setModuleNo] = useState('');
    const [chapterno,setChapterNo] = useState('');



    const closeNav = () => {
        document.getElementById("sidebar-menu").style.width = "0vw";
        document.querySelector(".side-contentent").style.margin = "0";

    }

    let pathname = location.pathname;
    const myArray = pathname.split('/');
    // console.log(myArray[1]);
    useEffect(() => {
        getViewPage();
        getResumeUrl();

    }, []);

    const [pageItem, setPageItems] = useState([]);
    const getViewPage = async () => {

        let data = JSON.stringify({
            "user_id": user_details.user_id,
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: BaseURL + '/getViewPages',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        await axios.request(config)
            .then((response) => {
                if (response.data.success == true) {
                    if (response.data.status == 1) {

                        setPageData(response.data.data);
                        let pageData = response.data.data;
                        pageData.map((item)=>{

                            // setPageItems([...pagedata, item.key_name]);
                            setPageItems((prevItems) => [ ...prevItems,item.key_name]);

                            // console.log(item);
                        });
                        return false;
                    }
                    setPageData([]);
                    return false;
                }

                setPageData([]);
                // console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const getResumeUrl= async()=>{

		var data = JSON.stringify({
		  user_id:user_details.user_id

		});
		var config = {
		  method: "post",
		  url: BaseURL+"/getresumePages",
		  headers: {
			'type': "user",
			"Content-Type": "application/json",
		  },
		  data: data,
		};
		await axios(config)
		  .then(function (response) {

            const url = response.data.data.url;
            let arr = url.split('/');
            let module = arr[1];
            let chapter = arr[2].split('-');
                let chapterNo = chapter[0]+chapter[1];
            setModuleNo(module);
            setChapterNo(chapterNo);



		  })
		  .catch(function (error) {
			// console.log(error);
		  });
	  }







    const SideMenuBar = () => {


        return (
            <>


                <li>
                    <div class="accordion" id="accordionExample">
                        <div class="accordion-item" >
                            <h2 class="accordion-header" id="headingOne">
                                <button class="accordion-button sidemenu-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                  {t('M1Name')}
                                </button>
                            </h2>
                            <div id="collapseOne" class={(myArray[1] == 'module-01')? "accordion-collapse collapse show" :"accordion-collapse collapse"} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <ul class="nav-links">
                                        <li class='d-flex align-items-center' >

                                        {(pageItem.includes('M1C1'))  ? <NavLink to="/module-01/module-01-title" className={(moduleno == 'module-01' && chapterno == 'chapter01')?"active-sidebar":""} exact >
                                           {t('M1chapter01screen01')}
                                                </NavLink>:<NavLink to="/module-01/module-01-title" exact className={(moduleno == 'module-01' && chapterno == 'chapter01')?"custom-active active-sidebar":"custom-active"}   >
                                            {t('M1chapter01screen01')}
                                                </NavLink>}


                                            {(pageItem.includes('M1C1'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}



                                        </li>

                                        <li class='d-flex align-items-center' >



                                            {(pageItem.includes('M1C2'))  ? <NavLink to="/module-01/chapter-02-screen-01" exact className={(moduleno == 'module-01' && chapterno == 'chapter02')?"active-sidebar":""} >
                                            {t('M1chapter02screen01Title')}
                                                </NavLink>:<NavLink to=""   disabled={true} className={(moduleno == 'module-01' && chapterno == 'chapter02')?"active-sidebar":""}>
                                            {t('M1chapter02screen01Title')}
                                                </NavLink>}


                                            {(pageItem.includes('M1C2'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center' >



                                            {(pageItem.includes('M1C3'))  ? <NavLink to="/module-01/chapter-03-screen-01" exact className={(moduleno == 'module-01' && chapterno == 'chapter03')?"active-sidebar":""} >
                                            {t('M1chapter03screen01Title')}
                                                </NavLink>:<NavLink to=""   disabled={true} className={(moduleno == 'module-01' && chapterno == 'chapter03')?"active-sidebar":""}>
                                            {t('M1chapter03screen01Title')}
                                                </NavLink>}

                                            {(pageItem.includes('M1C3'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                            {/* <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}
                                        </li>

                                        <li class='d-flex align-items-center'>


                                            {(pageItem.includes('M1C4'))  ? <NavLink to="/module-01/chapter-04-screen-01" exact className={(moduleno == 'module-01' && chapterno == 'chapter04')?"active-sidebar":""} >
                                            {t('M1chapter04screen01Title')}
                                                </NavLink>:<NavLink to=""   disabled={true} className={(moduleno == 'module-01' && chapterno == 'chapter04')?"active-sidebar":""}>
                                            {t('M1chapter04screen01Title')}
                                                </NavLink>}

                                            {(pageItem.includes('M1C4'))  ? <i class="fa fa-check-circle-o radio-circle checked-color " aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                            {/* <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="accordion-button sidemenu-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                {t('M2Name')}
                                </button>
                            </h2>
                            <div id="collapseTwo" class={(myArray[1] == 'module-02')? "accordion-collapse collapse show" :"accordion-collapse collapse"} aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <ul class="nav-links">
                                        <li class='d-flex align-items-center'>

                                            {/* <a href="">{t('M2chapter01screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M2C1'))  ? <NavLink to="/module-02/module-02-title"  className={(moduleno == 'module-02' && chapterno == 'chapter01')?"active-sidebar":""}>
                                            {t('M2chapter01screen01Title')}
                                                </NavLink>:<NavLink to="/module-02/module-02-title" className={(moduleno == 'module-02' && chapterno == 'chapter01')?"active-sidebar":""}   >
                                            {t('M2chapter01screen01Title')}
                                                </NavLink>}

                                            {(pageItem.includes('M2C1'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>

                                            {(pageItem.includes('M2C2'))  ? <NavLink to="/module-02/chapter-02-screen-01" exact className={(moduleno == 'module-02' && chapterno == 'chapter02')?"active-sidebar":""}>
                                            {t('M2chapter02screen01Title')}
                                                </NavLink>:<NavLink to=""  className={(moduleno == 'module-02' && chapterno == 'chapter02')?"active-sidebar":""} >
                                            {t('M2chapter02screen01Title')}
                                                </NavLink>}

                                            {(pageItem.includes('M2C2'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>


                                            {(pageItem.includes('M2C3'))  ? <NavLink to="/module-02/chapter-03-screen-01" exact className={(moduleno == 'module-02' && chapterno == 'chapter03')?"active-sidebar":""}>
                                            {t('M2chapter03screen01Text1')}
                                                </NavLink>:<NavLink to=""  className={(moduleno == 'module-02' && chapterno == 'chapter03')?"active-sidebar":""} >
                                            {t('M2chapter03screen01Text1')}
                                                </NavLink>}

                                            {(pageItem.includes('M2C3'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>


                                            {(pageItem.includes('M2C4'))  ? <NavLink to="/module-02/chapter-04-screen-01" className={(moduleno == 'module-02' && chapterno == 'chapter04')?"active-sidebar":""} >
                                            {t('M2chapter04screen01Title')}
                                                </NavLink>:<NavLink to=""   className={(moduleno == 'module-02' && chapterno == 'chapter04')?"active-sidebar":""}>
                                            {t('M2chapter04screen01Title')}
                                                </NavLink>}

                                            {(pageItem.includes('M2C4'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>
                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M2chapter05screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M2C5'))  ? <NavLink to="/module-02/chapter-05-screen-01" className={(moduleno == 'module-02' && chapterno == 'chapter05')?"active-sidebar":""} >
                                            {t('M2chapter05screen01')}
                                                </NavLink>:<NavLink to=""  className={(moduleno == 'module-02' && chapterno == 'chapter05')?"active-sidebar":""} >
                                            {t('M2chapter05screen01')}
                                                </NavLink>}

                                            {(pageItem.includes('M2C5'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="accordion-button sidemenu-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                {t('M3Name')}
                                </button>
                            </h2>
                            <div id="collapseThree" class={(myArray[1] == 'module-03')? "accordion-collapse collapse show" :"accordion-collapse collapse"} aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <ul class="nav-links">
                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M3chapter01screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M3C1'))  ? <NavLink to="/module-03/module-03-title" className={(moduleno == 'module-03' && chapterno == 'chapter01')?"active-sidebar":""} >
                                            {t('M3chapter01Screen1Text')}
                                                </NavLink>:<NavLink to="/module-03/module-03-title" className={(moduleno == 'module-03' && chapterno == 'chapter01')?"active-sidebar":""}   >
                                            {t('M3chapter01Screen1Text')}
                                                </NavLink>}

                                            {(pageItem.includes('M3C1'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M3chapter02screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M3C2'))  ? <NavLink to="/module-03/chapter-02-screen-01" className={(moduleno == 'module-03' && chapterno == 'chapter02')?"active-sidebar":""} >
                                            {t('M3chapter02Screen01Title')}
                                                </NavLink>:<NavLink to=""  className={(moduleno == 'module-03' && chapterno == 'chapter02')?"active-sidebar":""} >
                                            {t('M3chapter02Screen01Title')}
                                                </NavLink>}

                                            {(pageItem.includes('M3C2'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M3chapter03screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M3C3'))  ? <NavLink to="/module-03/chapter-03-screen-01" className={(moduleno == 'module-03' && chapterno == 'chapter03')?"active-sidebar":""} >
                                            {t('M3chapter03Screen01Title')}
                                                </NavLink>:<NavLink to="" className={(moduleno == 'module-03' && chapterno == 'chapter03')?"active-sidebar":""}  >
                                            {t('M3chapter03Screen01Title')}
                                                </NavLink>}

                                            {(pageItem.includes('M3C3'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M3chapter04screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M3C4'))  ? <NavLink to="/module-03/chapter-04-screen-01" className={(moduleno == 'module-03' && chapterno == 'chapter04')?"active-sidebar":""} >
                                            {t('M3chapter04Screen01Text1')}
                                                </NavLink>:<NavLink to=""  className={(moduleno == 'module-03' && chapterno == 'chapter04')?"active-sidebar":""} >
                                            {t('M3chapter04Screen01Text1')}
                                                </NavLink>}

                                            {(pageItem.includes('M3C4'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFour">
                                <button class="accordion-button sidemenu-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                {t('M4Name')}
                                </button>
                            </h2>
                            <div id="collapseFour" class={(myArray[1] == 'module-04')? "accordion-collapse collapse show" :"accordion-collapse collapse"}aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <ul class="nav-links">
                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M4chapter01screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M4C1'))  ? <NavLink to="/module-04/module-04-title" className={(moduleno == 'module-04' && chapterno == 'chapter01')?"active-sidebar":""} >
                                            {t('M4chapter01screen01')}
                                                </NavLink>:<NavLink to="/module-04/module-04-title" className={(moduleno == 'module-04' && chapterno == 'chapter01')?"active-sidebar":""}    >
                                            {t('M4chapter01screen01')}
                                                </NavLink>}

                                            {(pageItem.includes('M4C1'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M4chapter02screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M4C2'))  ? <NavLink to="/module-04/chapter-02-screen-01" className={(moduleno == 'module-04' && chapterno == 'chapter02')?"active-sidebar":""} >
                                            {t('M4chapter02screen01Title')}
                                                </NavLink>:<NavLink to=""  className={(moduleno == 'module-04' && chapterno == 'chapter02')?"active-sidebar":""} >
                                            {t('M4chapter02screen01Title')}
                                                </NavLink>}

                                            {(pageItem.includes('M4C2'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M4chapter03screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M4C3'))  ? <NavLink to="/module-04/chapter-03-screen-01" className={(moduleno == 'module-04' && chapterno == 'chapter03')?"active-sidebar":""} >
                                            {t('M4chapter03screen01Title')}
                                                </NavLink>:<NavLink to="" className={(moduleno == 'module-04' && chapterno == 'chapter03')?"active-sidebar":""}  >
                                            {t('M4chapter03screen01Title')}
                                                </NavLink>}

                                            {(pageItem.includes('M4C3'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M4chapter04screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}


                                            {(pageItem.includes('M4C4'))  ? <NavLink to="/module-04/chapter-04-screen-01" className={(moduleno == 'module-04' && chapterno == 'chapter04')?"active-sidebar":""} >
                                            {t('M4chapter04screen01Title')}
                                                </NavLink>:<NavLink to="" className={(moduleno == 'module-04' && chapterno == 'chapter04')?"active-sidebar":""}  >
                                            {t('M4chapter04screen01Title')}
                                                </NavLink>}

                                            {(pageItem.includes('M4C4'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M4chapter05screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M4C5'))  ? <NavLink to="/module-04/chapter-05-screen-01" className={(moduleno == 'module-04' && chapterno == 'chapter05')?"active-sidebar":""} >
                                            {t('M4chapter05screen01Title')}
                                                </NavLink>:<NavLink to="" className={(moduleno == 'module-04' && chapterno == 'chapter05')?"active-sidebar":""}  >
                                            {t('M4chapter05screen01Title')}
                                                </NavLink>}

                                            {(pageItem.includes('M4C5'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingFive">
                                <button class="accordion-button sidemenu-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                {t('M5Name')}
                                </button>
                            </h2>
                            <div id="collapseFive" class={(myArray[1] == 'module-05')? "accordion-collapse collapse show" :"accordion-collapse collapse"} aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <ul class="nav-links">
                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M5chapter01screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M5C1'))  ? <NavLink to="/module-05/module-05-title" className={(moduleno == 'module-05' && chapterno == 'chapter01')?"active-sidebar":""} >
                                            {t('M5chapter01screen01')}
                                                </NavLink>:<NavLink to="/module-05/module-05-title"  className={(moduleno == 'module-05' && chapterno == 'chapter01')?"active-sidebar":""}    >
                                            {t('M5chapter01screen01')}
                                                </NavLink>}

                                            {(pageItem.includes('M5C1'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M5chapter02screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M5C2'))  ? <NavLink to="/module-05/chapter-02-screen-01" className={(moduleno == 'module-05' && chapterno == 'chapter02')?"active-sidebar":""} >
                                            {t('M5chapter02Screen1Text1')}
                                                </NavLink>:<NavLink to="" className={(moduleno == 'module-05' && chapterno == 'chapter02')?"active-sidebar":""}  >
                                            {t('M5chapter02Screen1Text1')}
                                                </NavLink>}

                                            {(pageItem.includes('M5C2'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M5chapter03screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M5C3'))  ? <NavLink to="/module-05/chapter-03-screen-01" className={(moduleno == 'module-05' && chapterno == 'chapter03')?"active-sidebar":""} >
                                            {t('M5chapter03screen01')}
                                                </NavLink>:<NavLink to="" className={(moduleno == 'module-05' && chapterno == 'chapter03')?"active-sidebar":""}  >
                                            {t('M5chapter03screen01')}
                                                </NavLink>}

                                            {(pageItem.includes('M5C3'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M5chapter04screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M5C4'))  ? <NavLink to="/module-05/chapter-04-screen-01" className={(moduleno == 'module-05' && chapterno == 'chapter04')?"active-sidebar":""} >
                                            {t('M5chapter04Screen1Text1')}
                                                </NavLink>:<NavLink to="" className={(moduleno == 'module-05' && chapterno == 'chapter04')?"active-sidebar":""}  >
                                            {t('M5chapter04Screen1Text1')}
                                                </NavLink>}

                                            {(pageItem.includes('M5C4'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>


                        <div class="accordion-item">
                            <h2 class="accordion-header" id="headingSix">
                                <button class="accordion-button sidemenu-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                {t('M6Name')}
                                </button>
                            </h2>
                            <div id="collapseSix" class={(myArray[1] == 'module-06')? "accordion-collapse collapse show" :"accordion-collapse collapse"}aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <ul class="nav-links">
                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M6chapter01screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M6C1'))  ? <NavLink to="/module-06/module-06-title" className={(moduleno == 'module-06' && chapterno == 'chapter01')?"active-sidebar":""} >
                                            {t('M6chapter01screen01')}
                                                </NavLink>:<NavLink to="/module-06/module-06-title" className={(moduleno == 'module-06' && chapterno == 'chapter01')?"active-sidebar":""}   >
                                            {t('M6chapter01screen01')}
                                                </NavLink>}

                                            {(pageItem.includes('M6C1'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M6chapter02screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M6C2'))  ? <NavLink to="/module-06/chapter-02-screen-01" className={(moduleno == 'module-06' && chapterno == 'chapter02')?"active-sidebar":""} >
                                            {t('M6chapter02screen01')}
                                                </NavLink>:<NavLink to="" className={(moduleno == 'module-06' && chapterno == 'chapter02')?"active-sidebar":""}  >
                                            {t('M6chapter02screen01')}
                                                </NavLink>}

                                            {(pageItem.includes('M6C2'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M6chapter03screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i> */}

                                            {(pageItem.includes('M6C3'))  ? <NavLink to="/module-06/chapter-03-screen-01" className={(moduleno == 'module-06' && chapterno == 'chapter03')?"active-sidebar":""} >
                                            {t('M6chapter03screen01')}
                                                </NavLink>:<NavLink to="" className={(moduleno == 'module-06' && chapterno == 'chapter03')?"active-sidebar":""}  >
                                            {t('M6chapter03screen01')}
                                                </NavLink>}

                                            {(pageItem.includes('M6C3'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>

                                        <li class='d-flex align-items-center'>
                                            {/* <a href="">{t('M6chapter04screen01')} </a>
                                            <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>
 */}

                                            {(pageItem.includes('M6C4'))  ? <NavLink to="/module-06/chapter-04-screen-01" className={(moduleno == 'module-06' && chapterno == 'chapter04')?"active-sidebar":""} >
                                            {t('M6chapter04screen01')}
                                                </NavLink>:<NavLink to=""   className={(moduleno == 'module-06' && chapterno == 'chapter04')?"active-sidebar":""}>
                                            {t('M6chapter04screen01')}
                                                </NavLink>}

                                            {(pageItem.includes('M6C4'))  ? <i class="fa fa-check-circle-o radio-circle checked-color" aria-hidden="true"></i> :  <i class="fa fa-circle-o radio-circle ms-4" aria-hidden="true"></i>}

                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>






                    </div>
                </li>
            </>
        );


    }


    return (
        <>

            <div class="sidebar-menu col-lg-2" id='sidebar-menu' >
                {/* <i class="fa fa-times close-btn" aria-hidden="true" onClick={closeNav}></i> */}
                <ul class="nav-links">


                    <SideMenuBar />


                </ul>
            </div>

        </>
    )
}

export default Sidebar