import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen17 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-4">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<h3 className="h3 mb-2">{t('additional_resources')}  </h3>
									<a
										href=" https://www.apa.org/pi/lgbt/programs/safe-supportive/lgbt/key-terms.pdf"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M3chapter02Screen24Text1')}</p>
											</div>
										</div>
									</a>

									<a
										href="https://hindi.feminisminindia.com/2020/12/23/myths-related-to-lgbtqia-hindi/"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>	{t('M3chapter02Screen24Text2')}  </p>
											</div>
										</div>
									</a>
									<a
										href="https://agentsofishq.com/what-is-heteronormativity-a-comic/"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M3chapter02Screen24Text3')}  </p>
											</div>
										</div>
									</a>

									<a
										href="https://sexualityanddisability.org/"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>
													<div  dangerouslySetInnerHTML={{
        											__html: t('M3chapter02Screen24Text4')
      													}}>
													</div>
												</p>
											</div>
										</div>
									</a>

									<a
										href="http://agentsofishq.com/how-can-you-escape-from-the-body-image-house-of-mirrors/"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M3chapter02Screen24Text5')}  </p>
											</div>
										</div>
									</a>

									<a
										href="https://www.42d.org/2020/07/21/42-degrees-glossary-and-lexicon-2/ "
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>	{t('M3chapter02Screen24Text6')} </p>
											</div>
										</div>
									</a>

									<a
										href="https://www.youtube.com/watch?v=F4d6kGGfLvA"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>	{t('M3chapter02Screen24Text7')} </p>
											</div>
										</div>
									</a>

									<a
										href="https://www.youtube.com/watch?v=hYP8oFJB3SY"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>	{t('M3chapter02Screen24Text8')}</p>
											</div>
										</div>
									</a>

									{/* <a
					href="https://www.42d.org/2020/07/21/42-degrees-glossary-and-lexicon-2/"
					target="_blank"
					rel="noreferrer"
					>
					<div className="d-flex align-items-center mange-icon mt-2">
						<div>
						<img src="/assets/img/chapter1/list-icon.png" alt="" />
						</div>
						<div>
						<p>	42 Degrees Glossary and Lexicon</p>
						</div>
					</div>
					</a> */}


								</div>

								<div
									className="col-lg-4 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="50%"
											draggable="false"
											src="/assets/img/chapter1/reading-book.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-02-screen-07" />
								<NextBtn link="/module-03/chapter-03-screen-01" text={t('CommonBtnGoNext')} />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen17;
