import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen09 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [checkedQues11, setCheckedQues11] = useState([]);
  const [typea11Checked, setTypeA11Checked] = useState();
  const [typeb11Checked, setTypeB11Checked] = useState();
  const [typec11Checked, setTypeC11Checked] = useState();
  const [typed11Checked, setTypeD11Checked] = useState();

  const [checkedQues12, setCheckedQues12] = useState([]);
  const [typea12Checked, setTypeA12Checked] = useState();
  const [typeb12Checked, setTypeB12Checked] = useState();
  const [typec12Checked, setTypeC12Checked] = useState();
  const [typed12Checked, setTypeD12Checked] = useState();


  const [checkedQues13, setCheckedQues13] = useState([]);
  const [typea13Checked, setTypeA13Checked] = useState();
  const [typeb13Checked, setTypeB13Checked] = useState();
  const [typec13Checked, setTypeC13Checked] = useState();
  const [typed13Checked, setTypeD13Checked] = useState();


  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveGenderBased = (e) => {

    const quesId = e.target.getAttribute("data-quesId");

    const res = e.target.value;
    const checked = e.target.checked;
    checkBoxChecked(res, checked);
    if (quesId == '11') {

      setCheckedQues11(checkedQues11 => [...checkedQues11, res]);
    }
    if (quesId == '12') {

      setCheckedQues12(checkedQues12 => [...checkedQues12, res]);
    }

    if (quesId == '13') {

      setCheckedQues13(checkedQues13 => [...checkedQues13, res]);
    }

  }
  const saveAns = () => {

    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques11": {
          "ans": checkedQues11.join(','),
          "ques_id": "11"
        },
        "ques12": {
          "ans": checkedQues12.join(','),
          "ques_id": "12"
        },
        "ques13": {
          "ans": checkedQues13.join(','),
          "ques_id": "13"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        }

      }
    });
    var config = {
      method: 'post',
      url: BaseURL + '/MasculChapter02',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate('/module-04/chapter-02-screen-10')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues11.length > 0) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };


  const checkedVal = (param, ans) => {


    if (param === '11') {
      const ansArr = ans.split(',');
      ansArr.map((el) => {
        const checked = true;
        checkBoxChecked(el, checked, param);
      })
      setCheckedQues11(ansArr)
    }

    if (param === '12') {
      const ansArr = ans.split(',');
      ansArr.map((el) => {
        const checked = true;
        checkBoxChecked(el, checked, param);
      })
      setCheckedQues12(ansArr)
    }

    if (param === '13') {
      const ansArr = ans.split(',');
      ansArr.map((el) => {
        const checked = true;
        checkBoxChecked(el, checked, param);
      })
      setCheckedQues13(ansArr)
    }


  }

  const checkBoxChecked = (val, checked, param) => {

    if (param == '11') {


      if (val === 'type a') {
        if (checked === true) {
          setTypeA11Checked('checked');

        } else {
          setTypeA11Checked('');
        }
      }

      if (val === 'type b') {
        if (checked === true) {
          setTypeB11Checked('checked');
        } else {
          setTypeB11Checked('');
        }
      }

      if (val === 'type c') {
        if (checked === true) {
          setTypeC11Checked('checked');
        } else {
          setTypeC11Checked('');
        }
      }

      if (val === 'type d') {
        if (checked === true) {
          setTypeD11Checked('checked');
        } else {
          setTypeD11Checked('');
        }
      }
    }


    if (param == '12') {


      if (val === 'type a') {
        if (checked === true) {
          setTypeA12Checked('checked');

        } else {
          setTypeA12Checked('');
        }
      }

      if (val === 'type b') {
        if (checked === true) {
          setTypeB12Checked('checked');
        } else {
          setTypeB12Checked('');
        }
      }

      if (val === 'type c') {
        if (checked === true) {
          setTypeC12Checked('checked');
        } else {
          setTypeC12Checked('');
        }
      }

      if (val === 'type d') {
        if (checked === true) {
          setTypeD12Checked('checked');
        } else {
          setTypeD12Checked('');
        }
      }

    }


    if (param == '13') {


      if (val === 'type a') {
        if (checked === true) {
          setTypeA13Checked('checked');

        } else {
          setTypeA13Checked('');
        }
      }

      if (val === 'type b') {
        if (checked === true) {
          setTypeB13Checked('checked');
        } else {
          setTypeB13Checked('');
        }
      }

      if (val === 'type c') {
        if (checked === true) {
          setTypeC13Checked('checked');
        } else {
          setTypeC13Checked('');
        }
      }

      if (val === 'type d') {
        if (checked === true) {
          setTypeD13Checked('checked');
        } else {
          setTypeD13Checked('');
        }
      }

    }














  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/MasculChapter02Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(), result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="1/5" percentage="20" star="20" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set lavel-content-set">
              <div className="row align-items-center justify-content-between">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="row">
                    <div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >


                      <p className="mb-2 mt-3 ">
                        <b>
                          {t('M4chapter02screen08Text3')}
                        </b>
                      </p>
                    </div>
                    <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle"
                            id="mycirle"

                            defaultValue="type a"
                            onClick={saveGenderBased}
                            checked={typea11Checked}
                            data-quesId="11"
                          />
                          <label className="form-check-label" htmlFor="mycirle">
                            {" "}
                            {t('typea')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle"
                            id="mycirle2"
                            defaultValue="type b"
                            onClick={saveGenderBased}
                            checked={typeb11Checked}
                            data-quesId="11"
                          />
                          <label className="form-check-label" htmlFor="mycirle2">
                            {t('typeb')}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle"
                            id="mycirle3"
                            defaultValue="type c"
                            onClick={saveGenderBased}
                            checked={typec11Checked}
                            data-quesId="11"
                          />
                          <label className="form-check-label" htmlFor="mycirle3">
                            {" "}
                            {t('typec')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle"
                            id="mycirle4"
                            defaultValue="type d"
                            onClick={saveGenderBased}
                            checked={typed11Checked}
                            data-quesId="11"
                          />
                          <label className="form-check-label" htmlFor="mycirle4">
                            {" "}
                            {t('typed')}{" "}
                          </label>
                        </div>
                      </div>




                    </div>

                  </div>


                  <div className="row">
                    <div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >


                      <p className="mb-2 mt-3 ">
                        <b>
                          {t('M4chapter02screen08Text4')}
                        </b>
                      </p>
                    </div>
                    <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle123"
                            id="mycirle10"

                            defaultValue="type a"
                            onClick={saveGenderBased}
                            checked={typea12Checked}
                            data-quesId="12"
                          />
                          <label className="form-check-label" htmlFor="namecircle123">
                            {" "}
                            {t('typea')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle1234"
                            id="mycirle101"
                            defaultValue="type b"
                            onClick={saveGenderBased}
                            checked={typeb12Checked}
                            data-quesId="12"
                          />
                          <label className="form-check-label" htmlFor="namecircle1234">
                            {t('typeb')}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle12345"
                            id="mycirle102"
                            defaultValue="type c"
                            onClick={saveGenderBased}
                            checked={typec12Checked}
                            data-quesId="12"
                          />
                          <label className="form-check-label" htmlFor="namecircle12345">
                            {" "}
                            {t('typec')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle123456"
                            id="mycirle103"
                            defaultValue="type d"
                            onClick={saveGenderBased}
                            checked={typed12Checked}
                            data-quesId="12"
                          />
                          <label className="form-check-label" htmlFor="namecircle123456">
                            {" "}
                            {t('typed')}{" "}
                          </label>
                        </div>
                      </div>




                    </div>

                  </div>


                  <div className="row">
                    <div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >


                      <p className="mb-2 mt-3 ">
                        <b>
                          {t('M4chapter02screen09Text1')}
                        </b>
                      </p>
                    </div>
                    <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle123"
                            id="mycirle10"

                            defaultValue="type a"
                            onClick={saveGenderBased}
                            checked={typea13Checked}
                            data-quesId="13"
                          />
                          <label className="form-check-label" htmlFor="namecircle123">
                            {" "}
                            {t('typea')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle1234"
                            id="mycirle101"
                            defaultValue="type b"
                            onClick={saveGenderBased}
                            checked={typeb13Checked}
                            data-quesId="13"
                          />
                          <label className="form-check-label" htmlFor="namecircle1234">
                            {t('typeb')}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle12345"
                            id="mycirle102"
                            defaultValue="type c"
                            onClick={saveGenderBased}
                            checked={typec13Checked}
                            data-quesId="13"
                          />
                          <label className="form-check-label" htmlFor="namecircle12345">
                            {" "}
                            {t('typec')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle123456"
                            id="mycirle103"
                            defaultValue="type d"
                            onClick={saveGenderBased}
                            checked={typed13Checked}
                            data-quesId="13"
                          />
                          <label className="form-check-label" htmlFor="namecircle123456">
                            {" "}
                            {t('typed')}{" "}
                          </label>
                        </div>
                      </div>




                    </div>

                  </div>


                </div>




              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                  <PrevBtn link="/module-04/chapter-02-screen-07" />

                  {/* <NextBtn link="/module-04/chapter-02-screen-03" text="Go Next"/> */}
                  <BtnDisabled />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen09;
