import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";

const Chapter03Screen02 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-5">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3">{t('learn')}</h3>
									<p className="mb-2  mt-3">{t('M3chapter03Screen02Text1')} </p>
									<p className="mb-2  mt-3">{t('M3chapter03Screen02Text2')}  </p>
									<p className="mb-2  mt-3">{t('M3chapter03Screen02Text3')} </p>
								</div>

								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="65%"
											draggable="false"
											src="/assets/img/chapter1/reading-book.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-03-screen-01" />

								<button className="button_su" >
									<span className="su_button_circle desplode-circle">
									</span>
									<button className="button_su_inner" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
										<span className="button_text_container">
											{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
										</span>
									</button>
								</button>

								{/* <NextBtn link="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop" text={t('CommonBtnGoNext')} /> */}

							</div>
						</div>
					</div>
				</div>
			</div>



			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							<p className="text-white text-center">{t('M3chapter03Screen02Text4')}
							</p>
							<p className="text-white text-center">{t('M3chapter03Screen02Text5')}
							</p>

							<p className="text-white text-center">{t('M3chapter03Screen02Text6')}
							</p>

							<p className="text-white text-center">{t('M3chapter03Screen02Text7')}</p>

							<p className="text-white text-center">{t('M3chapter03Screen02Text8')}
							</p>




							<div class=" d-flex justify-content-center mt-3">
								<button className="button_su" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>

									<NavLink to="/module-03/chapter-03-screen-02n" className="button_su_inner"><span className="button_text_container"> {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>


						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter03Screen02;
