import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen04 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/5" percentage="22" star="22" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3">
								<div
									className="col-lg-7 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p>
										<h3>{t('M4chapter02screen04Text1')}</h3>
										 </p>
									<p><b>{t('M4chapter02screen04Text2')}</b>
									</p>
									<h3 className="h3 mb-1">{t('M4chapter02screen04Text3')}</h3>
									<ul className="list-style-set list-circle">
										<li>
											{t('M4chapter02screen04Text4')}

										</li>
										<li>{t('M4chapter02screen04Text5')}</li>
										<li>{t('M4chapter02screen04Text6')}</li>
										<li>
											{t('M4chapter02screen04Text7')}
										</li>
										<li>
										{t('M4chapter02screen04Text8')}

										</li>
										<li>
										{t('M4chapter02screen04Text9')}

										</li>
									</ul>
								</div>
								<div
									className="col-lg-4 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="38%"
											draggable="false"
											src="/assets/img/md4/md4-shap-2-3.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-02-screen-03" />

									<NextBtn link="/module-04/chapter-02-screen-05" text={t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen04;
