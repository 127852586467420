import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { Modal, Button } from "react-bootstrap";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen47 = () => {

	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const [checkedQues15Option1, setCheckedQues15Option1] = useState('');
	const [checkedQues15Option2, setCheckedQues15Option2] = useState('');

	const [checkedQues15Val, setCheckedQues15Val] = useState('');

	const [checkedQues16Option1, setCheckedQues16Option1] = useState('');
	const [checkedQues16Option2, setCheckedQues16Option2] = useState('');

	const [checkedQues16Val, setCheckedQues16Val] = useState('');

	const [checkedQues17Option1, setCheckedQues17Option1] = useState('');
	const [checkedQues17Option2, setCheckedQues17Option2] = useState('');

	const [checkedQues17Val, setCheckedQues17Val] = useState('');

	const [checkedQues18Option1, setCheckedQues18Option1] = useState('');
	const [checkedQues18Option2, setCheckedQues18Option2] = useState('');

	const [checkedQues18Val, setCheckedQues18Val] = useState('');

	const [checkedQues19Option1, setCheckedQues19Option1] = useState('');
	const [checkedQues19Option2, setCheckedQues19Option2] = useState('');

	const [checkedQues19Val, setCheckedQues19Val] = useState('');



	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		checkedVal(quesId, e.target.value)
	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques15": {
					"ans": checkedQues15Val,
					"ques_id": "15"
				},
				"ques16": {
					"ans": checkedQues16Val,
					"ques_id": "16"
				},
				"ques17": {
					"ans": checkedQues17Val,
					"ques_id": "17"
				},
				"ques18": {
					"ans": checkedQues18Val,
					"ques_id": "18"
				},
				"ques19": {
					"ans": checkedQues19Val,
					"ques_id": "19"
				},



			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityFinalQuiz',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-04-screen-48')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues15Val !== '' && checkedQues16Val !== '' && checkedQues17Val !== '' && checkedQues18Val !== '' && checkedQues19Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '15') {
			if (ans === 'option1') {
				setCheckedQues15Option1('checked')
				setCheckedQues15Option2('');

			} else if (ans === 'option2') {
				setCheckedQues15Option1('')
				setCheckedQues15Option2('checked');

			}
			setCheckedQues15Val(ans)
		}

		if (param === '16') {
			if (ans === 'option1') {
				setCheckedQues16Option1('checked')
				setCheckedQues16Option2('');

			} else if (ans === 'option2') {
				setCheckedQues16Option1('')
				setCheckedQues16Option2('checked');

			}
			setCheckedQues16Val(ans)
		}

		if (param === '17') {
			if (ans === 'option1') {
				setCheckedQues17Option1('checked')
				setCheckedQues17Option2('');

			} else if (ans === 'option2') {
				setCheckedQues17Option1('')
				setCheckedQues17Option2('checked');

			}
			setCheckedQues17Val(ans)
		}

		if (param === '18') {
			if (ans === 'option1') {
				setCheckedQues18Option1('checked')
				setCheckedQues18Option2('');

			} else if (ans === 'option2') {
				setCheckedQues18Option1('')
				setCheckedQues18Option2('checked');

			}
			setCheckedQues18Val(ans)
		}

		if (param === '19') {
			if (ans === 'option1') {
				setCheckedQues19Option1('checked')
				setCheckedQues19Option2('');

			} else if (ans === 'option2') {
				setCheckedQues19Option1('')
				setCheckedQues19Option2('checked');

			}
			setCheckedQues19Val(ans)
		}





	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityFinalQuizResult',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-2">
								<div
									className="col-lg-9 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>


									<p className="mb-2 mt-3 pn"><span><b>15.</b></span> <b>	{t('M3chapter04Screen47Text1')}  </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle" id="mycirle1"
												defaultValue="option1"
												onClick={saveRadio}
												checked={checkedQues15Option1}
												data-quesId="15"
											/>
											<label className="form-check-label" htmlFor="mycirle1">{t('yes')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle" id="mycirle2"
												defaultValue="option2"
												onClick={saveRadio}
												checked={checkedQues15Option2}
												data-quesId="15"
											/>
											<label className="form-check-label" htmlFor="mycirle2">{t('no')}</label>
										</div>
									</div>

									<p className="mb-2 mt-3 pn"><span><b>16.</b></span> <b>	{t('M3chapter04Screen47Text2')}  </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle2" id="mycirle3"
												defaultValue="option1"
												onClick={saveRadio}
												checked={checkedQues16Option1}
												data-quesId="16"
											/>
											<label className="form-check-label" htmlFor="mycirle3">{t('yes')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle2" id="mycirle4"
												defaultValue="option2"
												onClick={saveRadio}
												checked={checkedQues16Option2}
												data-quesId="16"
											/>
											<label className="form-check-label" htmlFor="mycirle4">{t('no')}</label>
										</div>
									</div>

									<p className="mb-2 mt-3 pn"><span><b>17.</b></span> <b>	{t('M3chapter04Screen47Text3')} </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle3" id="mycirle5"
												defaultValue="option1"
												onClick={saveRadio}
												checked={checkedQues17Option1}
												data-quesId="17"
											/>
											<label className="form-check-label" htmlFor="mycirle5">{t('yes')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle3" id="mycirle6"
												defaultValue="option2"
												onClick={saveRadio}
												checked={checkedQues17Option2}
												data-quesId="17"
											/>
											<label className="form-check-label" htmlFor="mycirle6">{t('no')}</label>
										</div>
									</div>


									<p className="mb-2 mt-3 pn"><span><b>18.</b></span> <b>	{t('M3chapter04Screen47Text4')}   </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle4" id="mycirle7"
												defaultValue="option1"
												onClick={saveRadio}
												checked={checkedQues18Option1}
												data-quesId="18"
											/>
											<label className="form-check-label" htmlFor="mycirle7">{t('yes')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle4" id="mycirle8"
												defaultValue="option2"
												onClick={saveRadio}
												checked={checkedQues18Option2}
												data-quesId="18"
											/>
											<label className="form-check-label" htmlFor="mycirle8">{t('no')}</label>
										</div>
									</div>

									<p className="mb-2 mt-3 pn"><span><b>19.</b></span> <b>	{t('M3chapter04Screen47Text5')}  </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle5" id="mycirle9"
												defaultValue="option1"
												onClick={saveRadio}
												checked={checkedQues19Option1}
												data-quesId="19"
											/>
											<label className="form-check-label" htmlFor="mycirle9">{t('yes')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle5" id="mycirle10"
												defaultValue="option2"
												onClick={saveRadio}
												checked={checkedQues19Option2}
												data-quesId="19"
											/>
											<label className="form-check-label" htmlFor="mycirle10">{t('no')}</label>
										</div>
									</div>


								</div>

								<div
									className="col-lg-2 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="80%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-1-15.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-46" />
								{/* <NextBtn link="/module-03/chapter-04-screen-48" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>



		</>
	);
};

export default Chapter04Screen47;
