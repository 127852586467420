import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen03 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [checkedQues3Hegemonic, setCheckedQues3Hegemonic] = useState('');
	const [checkedQues3Complicit, setCheckedQues3Complicit] = useState('');
	const [checkedQues3Subordinate, setCheckedQues3Subordinate] = useState('');
	const [checkedQues3Marginalised, setCheckedQues3Marginalised] = useState('');
	const [checkedQues3Inclusive, setCheckedQues3Inclusive] = useState('');
	const [checkedQues3Val, setCheckedQues3Val] = useState('');
	const [checkedQues4Hegemonic, setCheckedQues4Hegemonic] = useState('');
	const [checkedQues4Complicit, setCheckedQues4Complicit] = useState('');
	const [checkedQues4Subordinate, setCheckedQues4Subordinate] = useState('');
	const [checkedQues4Marginalised, setCheckedQues4Marginalised] = useState('');
	const [checkedQues4Inclusive, setCheckedQues4Inclusive] = useState('');
	const [checkedQues4Val, setCheckedQues4Val] = useState('');
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		checkedVal(quesId, e.target.value)
	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3Val,
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4Val,
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-04/chapter-03-screen-04')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues3Val !== '' && checkedQues4Val !== '') {
			return (
				<>
					<button className={`button_su`} data-wow-duration="1s" data-wow-delay="0.2s">
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '3') {
			// alert(param);
			if (ans === 'Hegemonic') {
				setCheckedQues3Hegemonic('checked')
				setCheckedQues3Complicit('');
				setCheckedQues3Subordinate('')
				setCheckedQues3Marginalised('')
				setCheckedQues3Inclusive('')
			} else if (ans === 'Complicit') {
				setCheckedQues3Hegemonic('')
				setCheckedQues3Complicit('checked');
				setCheckedQues3Subordinate('')
				setCheckedQues3Marginalised('')
				setCheckedQues3Inclusive('')
			} else if (ans === 'Subordinate') {
				setCheckedQues3Hegemonic('')
				setCheckedQues3Complicit('');
				setCheckedQues3Subordinate('checked')
				setCheckedQues3Marginalised('')
				setCheckedQues3Inclusive('')
			} else if (ans === 'Marginalised') {
				setCheckedQues3Hegemonic('')
				setCheckedQues3Complicit('');
				setCheckedQues3Subordinate('')
				setCheckedQues3Marginalised('checked')
				setCheckedQues3Inclusive('')
			} else if (ans === 'Inclusive and gender equitable') {
				setCheckedQues3Hegemonic('')
				setCheckedQues3Complicit('');
				setCheckedQues3Subordinate('')
				setCheckedQues3Marginalised('')
				setCheckedQues3Inclusive('checked')
			}
			setCheckedQues3Val(ans)
		}
		if (param === '4') {
			// alert(param);
			if (ans === 'Hegemonic') {
				setCheckedQues4Hegemonic('checked')
				setCheckedQues4Complicit('');
				setCheckedQues4Subordinate('')
				setCheckedQues4Marginalised('')
				setCheckedQues4Inclusive('')
			} else if (ans === 'Complicit') {
				setCheckedQues4Hegemonic('')
				setCheckedQues4Complicit('checked');
				setCheckedQues4Subordinate('')
				setCheckedQues4Marginalised('')
				setCheckedQues4Inclusive('')
			} else if (ans === 'Subordinate') {
				setCheckedQues4Hegemonic('')
				setCheckedQues4Complicit('');
				setCheckedQues4Subordinate('checked')
				setCheckedQues4Marginalised('')
				setCheckedQues4Inclusive('')
			} else if (ans === 'Marginalised') {
				setCheckedQues4Hegemonic('')
				setCheckedQues4Complicit('');
				setCheckedQues4Subordinate('')
				setCheckedQues4Marginalised('checked')
				setCheckedQues4Inclusive('')
			} else if (ans === 'Inclusive and gender equitable') {
				setCheckedQues4Hegemonic('')
				setCheckedQues4Complicit('');
				setCheckedQues4Subordinate('')
				setCheckedQues4Marginalised('')
				setCheckedQues4Inclusive('checked')
			}
			setCheckedQues4Val(ans)
		}
	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="2/5" percentage="54" star="54" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mt-3">
										<b>
											{t('M4chapter03screen03Text1')}

										</b>{" "}
									</p>
								</div>
								<div className="row align-items-center justify-content-between">
									<div
										className="col-lg-4 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<div className="d-flex ">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle"
													id="mycirle"
													defaultValue="Hegemonic"
													onClick={saveRadio}
													checked={checkedQues3Hegemonic}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="mycirle">
													{" "}
													{t('Hegemonic')}{" "}
												</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle"
													id="mycirle2"
													defaultValue="Complicit"
													onClick={saveRadio}
													checked={checkedQues3Complicit}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="mycirle2">
												{t('Complicit')}
												</label>
											</div>
										</div>
										{/* <div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle"
													id="mycirle3"
													defaultValue="Subordinate"
													onClick={saveRadio}
													checked={checkedQues3Subordinate}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="mycirle3">
													{" "}
													{t('Subordinate')}
												</label>
											</div>
										</div> */}
										<div className="d-flex ">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle"
													id="mycirle4"
													defaultValue="Marginalised"
													onClick={saveRadio}
													checked={checkedQues3Marginalised}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="mycirle4">
													{" "}
													{t('Marginalised')}
												</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle"
													id="mycirle5"
													defaultValue="Inclusive and gender equitable"
													onClick={saveRadio}
													checked={checkedQues3Inclusive}
													data-quesId="3"
												/>
												<label className="form-check-label" htmlFor="mycirle5">
												{t('M4chapter02screen13Text2')}
												</label>
											</div>
										</div>
									</div>
									<div
										className="col-lg-4 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<center>
											<img
												width="100%"
												draggable="false"
												src="/assets/img/md4/md4-3-3-img.png"
												alt=""
											/>
										</center>
									</div>
								</div>
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mt-2">
										<b>
											{t('M4chapter03screen03Text2')}

										</b>{" "}
									</p>
								</div>
								<div className="row align-items-center justify-content-between">
									<div
										className="col-lg-4 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle2"
													id="mycirle6"
													defaultValue="Hegemonic"
													onClick={saveRadio}
													checked={checkedQues4Hegemonic}
													data-quesId="4"
												/>
												<label className="form-check-label" htmlFor="mycirle6">
													{" "}
													{t('Hegemonic')}{" "}
												</label>
											</div>
										</div>
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle2"
													id="mycirle7"
													defaultValue="Complicit"
													onClick={saveRadio}
													checked={checkedQues4Complicit}
													data-quesId="4"
												/>
												<label className="form-check-label" htmlFor="mycirle7">
												{t('Complicit')}{" "}
												</label>
											</div>
										</div>
										{/* <div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle2"
													id="mycirle8"
													defaultValue="Subordinate"
													onClick={saveRadio}
													checked={checkedQues4Subordinate}
													data-quesId="4"
												/>
												<label className="form-check-label" htmlFor="mycirle8">
												{t('Subordinate')}
												</label>
											</div>
										</div> */}
										<div className="d-flex">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle2"
													id="mycirle9"
													defaultValue="Marginalised"
													onClick={saveRadio}
													checked={checkedQues4Marginalised}
													data-quesId="4"
												/>
												<label className="form-check-label" htmlFor="mycirle9">
												{t('Marginalised')}
												</label>
											</div>
										</div>
										<div className="d-flex ">
											<div className="form-check form-check-inline">
												<input
													className="form-check-input custom-check"
													type="radio"
													name="namecircle2"
													id="mycirle10"
													defaultValue="Inclusive and gender equitable"
													onClick={saveRadio}
													checked={checkedQues4Inclusive}
													data-quesId="4"
												/>
												<label className="form-check-label" htmlFor="mycirle10">
												{t('M4chapter02screen13Text2')}{" "}
												</label>
											</div>
										</div>
									</div>
									<div
										className="col-lg-4 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<center>
											<img
												width="100%"
												draggable="false"
												src="/assets/img/md4/md4-3-3-3-img.png"
												alt=""
											/>
										</center>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-03-screen-02" />
									{/* <NextBtn link="/module-04/chapter-03-screen-04" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Chapter03Screen03;
