import React,{useEffect,useState} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter02Screen02 = () => {

  const navigate = useNavigate();

  const [checkedQues2, setCheckedQues2] = useState([]);
  const [checkedQues2Option1, setCheckedQues2Option1] = useState('');
  const [checkedQues2Option2, setCheckedQues2Option2] = useState('');
  const [checkedQues2Option3, setCheckedQues2Option3] = useState('');
  const [checkedQues2Option4, setCheckedQues2Option4] = useState('');

  const [checkedQues3, setCheckedQues3] = useState([]);
  const [checkedQues3Option1, setCheckedQues3Option1] = useState('');
  const [checkedQues3Option2, setCheckedQues3Option2] = useState('');
  const [checkedQues3Option3, setCheckedQues3Option3] = useState('');
  const [checkedQues3Option4, setCheckedQues3Option4] = useState('');
  const [checkedQues3Option5, setCheckedQues3Option5] = useState('');
  const [checkedQues3Option6, setCheckedQues3Option6] = useState('');
  const [checkedQues3Option7, setCheckedQues3Option7] = useState('');

const { t, i18n } = useTranslation();
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveGenderBased = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    const res = e.target.value;
    const checked = e.target.checked;
    checkBoxChecked(res,checked,quesId);
    if(quesId === '2')
    {
      setCheckedQues2(checkedQues2 => [...checkedQues2, res]);
    }
    if(quesId === '2')
    {
      setCheckedQues3(checkedQues3 => [...checkedQues3, res]);
    }
  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques2": {
          "ans": checkedQues2.join(','),
          "ques_id": "2"
        },
        "ques3": {
          "ans": checkedQues3.join(','),
          "ques_id": "3"
        },

      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/ApproachChapter03',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.status === "Token is Expired")
        {
          window.localStorage.clear();
          navigate('/login')
        }
        if(response.data.message === "Token Invalid")
        {
          window.localStorage.clear();
          navigate('/login')
        }
        if(response.data.success === true)
        {
          navigate('/module-06/chapter-03-screen-06')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues2.length > 0 && checkedQues3.length > 0 ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {
    if (param === '2') {
      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues2(ansArr)
    }
    if (param === '3') {
      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues3(ansArr)
    }
  }
  const checkBoxChecked = (val,checked,quesId)=>{
    if(quesId === '2')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues2Option1('checked');
        }else{
          setCheckedQues2Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues2Option2('checked');
        }else{
          setCheckedQues2Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues2Option3('checked');
        }else{
          setCheckedQues2Option3('');
        }
      }

      if(val === 'option4')
      {
        if(checked === true)
        {
          setCheckedQues2Option4('checked');
        }else{
          setCheckedQues2Option4('');
        }
      }

    }
    if(quesId === '3')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues3Option1('checked');
        }else{
          setCheckedQues3Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues3Option2('checked');
        }else{
          setCheckedQues3Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues3Option3('checked');
        }else{
          setCheckedQues3Option3('');
        }
      }
      if(val === 'option4')
        {
          if(checked === true)
          {
            setCheckedQues3Option4('checked');
          }else{
            setCheckedQues3Option4('');
          }
        }

        if(val === 'option5')
          {
            if(checked === true)
            {
              setCheckedQues3Option5('checked');
            }else{
              setCheckedQues3Option5('');
            }
          }

          if(val === 'option6')
            {
              if(checked === true)
              {
                setCheckedQues3Option6('checked');
              }else{
                setCheckedQues3Option6('');
              }
            }

            if(val === 'option7')
              {
                if(checked === true)
                {
                  setCheckedQues3Option7('checked');
                }else{
                  setCheckedQues3Option7('');
                }
              }



    }
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/approachChapter03Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
          <Header leftChapter="2/4" percentage="60" star="50" />
          </div>
        </div>

        <div className="d-flex">
					<Sidebar />

        <div className="data-adjust">
          <div className="container-fluid cf-set">
          <div className="row align-items-top justify-content-between pt-20 mr-3 mt-m-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

                <h2 className="h2">{t('engage')}</h2>
                <h3 className="h3">{t('M6chapter03screen04Text1')}</h3>

                <p className="mb-2 mt-3 pn"><span><b>1.</b></span><b>{t('M6chapter03screen04Text2')}</b> </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                     onClick={saveGenderBased}
                           checked={checkedQues2Option1}
                           data-quesId="2"
                            name="namecircle"
                             id="mycirle"
                             defaultValue="option1"

                     />
                    <label className="form-check-label" htmlFor="mycirle1"> {t('M6chapter03screen04Text3')}</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                      onClick={saveGenderBased}
                           checked={checkedQues2Option2}
                           data-quesId="2"
                            name="namecircle"
                             id="mycirle"
                             defaultValue="option2"

                      />
                    <label className="form-check-label" htmlFor="mycirle2">{t('M6chapter03screen04Text4')}</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check"
                    type="checkbox"
                    onClick={saveGenderBased}
                           checked={checkedQues2Option3}
                           data-quesId="2"
                            name="namecircle"
                             id="mycirle"
                             defaultValue="option3"

                     />
                    <label className="form-check-label" htmlFor="mycirle3"> {t('M6chapter03screen04Text5')}</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                     onClick={saveGenderBased}
                           checked={checkedQues2Option4}
                           data-quesId="2"
                            name="namecircle"
                             id="mycirle"
                             defaultValue="option4"

                      />
                    <label className="form-check-label" htmlFor="mycirle4">{t('M6chapter03screen04Text6')} </label>
                  </div>
                </div>



                <p className="mb-2 mt-3 pn"><span><b>2.</b></span><b> {t('M6chapter03screen04Text7')}</b> </p>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                     name="namecircle2"
                     onClick={saveGenderBased}
                           checked={checkedQues3Option1}
                           data-quesId="3"

                             defaultValue="option1"
                      />
                    <label className="form-check-label" htmlFor="mycirle5"> {t('M6chapter03screen04Text8')}</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                    name="namecircle2"
                     onClick={saveGenderBased}
                           checked={checkedQues3Option2}
                           data-quesId="3"

                             defaultValue="option2"

                      />
                    <label className="form-check-label" htmlFor="mycirle6"> {t('M6chapter03screen04Text9')}</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                   name="namecircle2"
                     onClick={saveGenderBased}
                           checked={checkedQues3Option3}
                           data-quesId="3"

                             defaultValue="option3"
                      />
                    <label className="form-check-label" htmlFor="mycirle7">{t('M6chapter03screen04Text10')}</label>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                   name="namecircle2"
                     onClick={saveGenderBased}
                           checked={checkedQues3Option4}
                           data-quesId="3"

                             defaultValue="option4"
                      />
                    <label className="form-check-label" htmlFor="mycirle7">{t('M6chapter03screen04Text11')}</label>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                   name="namecircle2"
                     onClick={saveGenderBased}
                           checked={checkedQues3Option5}
                           data-quesId="3"

                             defaultValue="option5"
                      />
                    <label className="form-check-label" htmlFor="mycirle7">{t('M6chapter03screen04Text12')}</label>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                   name="namecircle2"
                     onClick={saveGenderBased}
                           checked={checkedQues3Option6}
                           data-quesId="3"

                             defaultValue="option6"
                      />
                    <label className="form-check-label" htmlFor="mycirle7">{t('M6chapter03screen04Text13')}</label>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="checkbox"
                   name="namecircle2"
                     onClick={saveGenderBased}
                           checked={checkedQues3Option7}
                           data-quesId="3"

                             defaultValue="option7"
                      />
                    <label className="form-check-label" htmlFor="mycirle7">{t('M6chapter03screen04Text14')}</label>
                  </div>
                </div>





              </div>


            </div>
          </div>
        </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">

                <PrevBtn link="/module-06/chapter-03-screen-03"/>
                {/* <NextBtn link="/module-06/chapter-03-screen-05" text="Go Next"/> */}
                <BtnDisabled/>


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen02;
