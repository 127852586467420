import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from "react-i18next";

const Chapter04Screen04 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [checkedQues4Option1, setCheckedQues4Option1] = useState('');
	const [checkedQues4Option2, setCheckedQues4Option2] = useState('');
	const [checkedQues4Option3, setCheckedQues4Option3] = useState('');
	const [checkedQues4Val, setCheckedQues4Val] = useState('');
	const [msg1, setMsg1] = useState('');
	const [msg2, setMsg2] = useState('');
	const [msg3, setMsg3] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		if (e.target.value === 'option1') {
			setMsg1('- Passive');
		}

		if (e.target.value === 'option2') {
			setMsg2('- Aggressive');
		}

		if (e.target.value === 'option3') {
			setMsg3('- Assertive');
		}

		// alert(quesId);
		checkedVal(quesId, e.target.value)
	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4Val,
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-04-screen-05')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const checkedVal = (param, ans) => {
		if (param === '4') {
			if (ans === 'option1') {
				setCheckedQues4Option1('checked')
				setCheckedQues4Option2('')
				setCheckedQues4Option3('')

			} else if (ans === 'option2') {
				setCheckedQues4Option1('')
				setCheckedQues4Option2('checked')
				setCheckedQues4Option3('')

			} else if (ans === 'option3') {
				setCheckedQues4Option1('')
				setCheckedQues4Option2('')
				setCheckedQues4Option3('checked')
			}
			setCheckedQues4Val(ans)
		}


	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	const BtnDisabled = () => {
		if (checkedQues4Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="65" star="65" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar/>
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between pt-20 mt-5">
								<h2 className="h3 mt-4">{t('engage')}</h2>
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h4 className="h4 mb-1">
										{t('M1chapter04screen04Text1')}
										
									</h4>
								</div>
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mb-2 mt-3">
										<b>
											1. {t('M1chapter04screen04Text2')}
										</b>
									</p>
									<img
										width="25%"
										draggable="false"
										src="/assets/img/md2/hamari-sarkar.png" className="img-responsive"
										alt=""
									/>
									<div className="d-flex mt-4">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="a"
												id="ture"
												defaultValue="option1"
												onClick={saveRadio}
												data-quesId="4"
												checked={checkedQues4Option1}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
												
												{t('M1chapter04screen04Text3')} <span className="red">{msg1} </span>
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="a"
												id="ture"
												defaultValue="option2"
												onClick={saveRadio}
												data-quesId="4"
												checked={checkedQues4Option2}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
												{t('M1chapter04screen04Text4')}
												<span className="red">{msg2} </span>
											</label>
										</div>
									</div>


									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="a"
												id="ture"
												defaultValue="option3"
												onClick={saveRadio}
												data-quesId="4"
												checked={checkedQues4Option3}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
												{t('M1chapter04screen04Text5')}
												 <span className="red">{msg3} </span>
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-01/chapter-04-screen-03" />

									{/* <NextBtn link="/module-02/chapter-04-screen-05" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen04;
