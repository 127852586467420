import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";
// import "bootstrap/dist/css/bootstrap.min.css";

const Chapter02Screen02 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [show1, setShow1] = useState(false);
	const [show2, setShow2] = useState(false);
	const [show3, setShow3] = useState(false);
	const [show4, setShow4] = useState(false);
	const [show5, setShow5] = useState(false);
	const [show6, setShow6] = useState(false);
	const [show7, setShow7] = useState(false);
	const [show8, setShow8] = useState(false);

	const handleClose1 = () => setShow1(false);
	const handleClose2 = () => setShow2(false);
	const handleClose3 = () => setShow3(false);
	const handleClose4 = () => setShow4(false);
	const handleClose5 = () => setShow5(false);
	const handleClose6 = () => setShow6(false);
	const handleClose7 = () => setShow7(false);
	const handleClose8 = () => setShow8(false);

	const handleShow1 = () => setShow1(true);
	const handleShow2 = () => setShow2(true);
	const handleShow3 = () => setShow3(true);
	const handleShow4 = () => setShow4(true);
	const handleShow5 = () => setShow5(true);
	const handleShow6 = () => setShow6(true);
	const handleShow7 = () => setShow7(true);
	const handleShow8 = () => setShow8(true);

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showModalText, setShowModalText] = useState();


	const [checkedQues1Yes, setCheckedQues1Yes] = useState('');
	const [checkedQues1No, setCheckedQues1No] = useState('');
	const [checkedQues1Val, setCheckedQues1Val] = useState('');
	const [checkedQues2Yes, setCheckedQues2Yes] = useState('');
	const [checkedQues2No, setCheckedQues2No] = useState('');
	const [checkedQues2Val, setCheckedQues2Val] = useState('');
	const [checkedQues3Yes, setCheckedQues3Yes] = useState('');
	const [checkedQues3No, setCheckedQues3No] = useState('');
	const [checkedQues3Val, setCheckedQues3Val] = useState('');
	const [checkedQues4Yes, setCheckedQues4Yes] = useState('');
	const [checkedQues4No, setCheckedQues4No] = useState('');
	const [checkedQues4Val, setCheckedQues4Val] = useState('');
	const [checkedQues5Yes, setCheckedQues5Yes] = useState('');
	const [checkedQues5No, setCheckedQues5No] = useState('');
	const [checkedQues5Val, setCheckedQues5Val] = useState('');
	const [checkedQues6Yes, setCheckedQues6Yes] = useState('');
	const [checkedQues6No, setCheckedQues6No] = useState('');
	const [checkedQues6Val, setCheckedQues6Val] = useState('');
	const [checkedQues7Yes, setCheckedQues7Yes] = useState('');
	const [checkedQues7No, setCheckedQues7No] = useState('');
	const [checkedQues7Val, setCheckedQues7Val] = useState('');
	const [checkedQues8Yes, setCheckedQues8Yes] = useState('');
	const [checkedQues8No, setCheckedQues8No] = useState('');
	const [checkedQues8Val, setCheckedQues8Val] = useState('');



	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		if (quesId === '1') {
			handleShow1()

		}

		if (quesId === '2') {
			handleShow2()

		}

		if (quesId === '3') {
			handleShow3()

		}

		if (quesId === '4') {
			handleShow4()

		}

		if (quesId === '5') {
			handleShow5()

		}

		if (quesId === '6') {
			handleShow6()

		}

		if (quesId === '7') {
			handleShow7()

		}

		if (quesId === '8') {
			handleShow8()

		}

		setShow(true);
		checkedVal(quesId, e.target.value)

	}


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues1Val,
					"ques_id": "1"
				},
				"ques2": {
					"ans": checkedQues2Val,
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3Val,
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4Val,
					"ques_id": "4"
				},
				"ques5": {
					"ans": checkedQues5Val,
					"ques_id": "5"
				},
				"ques6": {
					"ans": checkedQues6Val,
					"ques_id": "6"
				},
				"ques7": {
					"ans": checkedQues7Val,
					"ques_id": "7"
				},
				"ques8": {
					"ans": checkedQues8Val,
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-02/chapter-02-screen-03')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans) => {
		if (param === '1') {

			if (ans === 'yes') {
				setCheckedQues1Yes('checked')
				setCheckedQues1No('')
			} else {
				if (ans === 'no') {
					setCheckedQues1No('checked')
					setCheckedQues1Yes('')
				}
			}
			setCheckedQues1Val(ans)
		}
		if (param === '2') {
			if (ans === 'yes') {
				setCheckedQues2Yes('checked')
				setCheckedQues2No('')
			} else {
				if (ans === 'no') {
					setCheckedQues2No('checked')
					setCheckedQues2Yes(''
					)
				}
			}
			setCheckedQues2Val(ans)
		}




		if (param === '3') {
			if (ans === 'yes') {
				setCheckedQues3Yes('checked')
				setCheckedQues3No('')

			} else {
				if (ans === 'no') {
					setCheckedQues3No('checked')
					setCheckedQues3Yes('')
				}
			}
			setCheckedQues3Val(ans)
		}


		if (param === '4') {
			if (ans === 'yes') {
				setCheckedQues4Yes('checked')
				setCheckedQues4No('')

			} else {
				if (ans === 'no') {
					setCheckedQues4No('checked')
					setCheckedQues4Yes('')
				}
			}
			setCheckedQues4Val(ans)
		}


		if (param === '5') {
			if (ans === 'yes') {
				setCheckedQues5Yes('checked')
				setCheckedQues5No('')

			} else {
				if (ans === 'no') {
					setCheckedQues5No('checked')
					setCheckedQues5Yes('')
				}
			}
			setCheckedQues5Val(ans)
		}

		if (param === '6') {
			if (ans === 'yes') {
				setCheckedQues6Yes('checked')
				setCheckedQues6No('')

			} else {
				if (ans === 'no') {
					setCheckedQues6No('checked')
					setCheckedQues6Yes('')
				}
			}
			setCheckedQues6Val(ans)
		}

		if (param === '7') {
			if (ans === 'yes') {
				setCheckedQues7Yes('checked')
				setCheckedQues7No('')

			} else {
				if (ans === 'no') {
					setCheckedQues7No('checked')
					setCheckedQues7Yes('')
				}
			}
			setCheckedQues7Val(ans)
		}

		if (param === '8') {
			if (ans === 'yes') {
				setCheckedQues8Yes('checked')
				setCheckedQues8No('')

			} else {
				if (ans === 'no') {
					setCheckedQues8No('checked')
					setCheckedQues8Yes('')
				}
			}
			setCheckedQues8Val(ans)
		}


	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues1Val !== '' && checkedQues2Val !== '' && checkedQues3Val !== '' &&
			checkedQues4Val !== '' && checkedQues5Val !== '' && checkedQues6Val !== '' && checkedQues7Val !== ''
			&& checkedQues8Val !== ''
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/5" percentage="21" star="9" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-center pt-20 mt-3">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.1s"
								>
									<h1 className="h1 text-center text-uppercase">{t('engage')}</h1>
									<p className="text-center mb-3">
										{t('M2chapter02screen02Text1')}{" "}
									</p>
								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mt-3">1.  {t('M2chapter02screen02Text2')} </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId1"
												id="ture"
												onClick={saveRadio}
												checked={checkedQues1Yes}

												defaultValue="yes"
												data-quesId="1"
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
											{t('true')}
											</label>
										</div>
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId1"
												id="false"
												onClick={saveRadio}

												defaultValue="no"
												data-quesId="1"
												checked={checkedQues1No}
											/>
											<label className="form-check-label " htmlFor="inlineRadio2">
											{t('false')}
											</label>
										</div>
									</div>
									{/* <div class="img-box-true-false">
				<img src="/assets/img/chapter2/true-1.png" alt="">
				</div> */}
									<p className="mt-0">2. {t('M2chapter02screen02Text3')} </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId2"
												id="ture"
												onClick={saveRadio}

												defaultValue="yes"
												data-quesId="2"
												checked={checkedQues2Yes}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
											{t('true')}
											</label>
										</div>
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId2"
												id="false"
												onClick={saveRadio}

												defaultValue="no"
												data-quesId="2"
												checked={checkedQues2No}
											/>
											<label className="form-check-label " htmlFor="inlineRadio2">
											{t('false')}
											</label>
										</div>
									</div>
									<p className="mt-0">3. {t('M2chapter02screen02Text4')}</p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId3"
												id="ture"
												onClick={saveRadio}

												defaultValue="yes"
												data-quesId="3"
												checked={checkedQues3Yes}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
											{t('true')}
											</label>
										</div>
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId3"
												id="false"
												onClick={saveRadio}

												defaultValue="no"
												data-quesId="3"
												checked={checkedQues3No}
											/>
											<label className="form-check-label " htmlFor="inlineRadio2">
											{t('false')}
											</label>
										</div>
									</div>
									<p className="mt-0">4. {t('M2chapter02screen02Text5')}</p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId4"
												id="ture"
												onClick={saveRadio}

												defaultValue="yes"
												data-quesId="4"
												checked={checkedQues4Yes}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
											{t('true')}
											</label>
										</div>
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId4"
												id="false"
												onClick={saveRadio}

												defaultValue="no"
												data-quesId="4"
												checked={checkedQues4No}
											/>
											<label className="form-check-label " htmlFor="inlineRadio2">
											{t('false')}
											</label>
										</div>
									</div>
								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<img
										width="78%"
										src="/assets/img/md1/md1-shap-2-2.png"
										alt=""
									/>
								</div>
								<div
									className="col-lg-4 offset-lg- wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mt-0">5. {t('M2chapter02screen02Text6')}</p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId5"
												id="ture"
												onClick={saveRadio}

												defaultValue="yes"
												data-quesId="5"
												checked={checkedQues5Yes}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
											{t('true')}
											</label>
										</div>
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId5"
												id="false"
												onClick={saveRadio}

												defaultValue="no"
												data-quesId="5"
												checked={checkedQues5No}
											/>
											<label className="form-check-label " htmlFor="inlineRadio2">
											{t('false')}
											</label>
										</div>
									</div>
									<p className="mt-0">
										6. {t('M2chapter02screen02Text7')}
									</p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId6"
												id="ture"
												onClick={saveRadio}

												defaultValue="yes"
												data-quesId="6"
												checked={checkedQues6Yes}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
											{t('true')}
											</label>
										</div>
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId6"
												id="false"
												onClick={saveRadio}

												defaultValue="no"
												data-quesId="6"
												checked={checkedQues6No}
											/>
											<label className="form-check-label " htmlFor="inlineRadio2">
											{t('false')}
											</label>
										</div>
									</div>
									<p className="mt-0">7. {t('M2chapter02screen02Text8')}</p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId7"
												id="ture"
												onClick={saveRadio}

												defaultValue="yes"
												data-quesId="7"
												checked={checkedQues7Yes}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
											{t('true')}
											</label>
										</div>
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId7"
												id="ture"
												onClick={saveRadio}

												defaultValue="no"
												data-quesId="7"
												checked={checkedQues7No}
											/>
											<label className="form-check-label " htmlFor="inlineRadio2">
											{t('false')}
											</label>
										</div>
									</div>
									<p className="mt-0">8. {t('M2chapter02screen02Text9')} </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId8"
												id="ture"
												onClick={saveRadio}

												defaultValue="yes"
												data-quesId="8"
												checked={checkedQues8Yes}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
											{t('true')}
											</label>
										</div>
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="quesId8"
												id="ture"
												onClick={saveRadio}

												defaultValue="no"
												data-quesId="8"
												checked={checkedQues8No}
											/>
											<label className="form-check-label " htmlFor="inlineRadio2">
												{t('false')}
											</label>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-02-screen-01" />

								{/* <NextBtn
                  link="/module-01/chapter-02-screen-03"
                  text="Go Next"
                  classtype=""
                /> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal show={show1} onHide={handleClose1}>
				<Modal.Header closeButton>
					<Modal.Title>{t('M2chapter02screen02Model1')}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<img src="/assets/img/chapter1/as.png" alt="as" width="100%"></img>
					<p>{t('M2chapter02screen02Model2')}</p>
				</Modal.Body>
			</Modal>

			<Modal show={show2} onHide={handleClose2}>
				<Modal.Header closeButton>
					<Modal.Title>{t('M2chapter02screen02Model3')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<img src="/assets/img/chapter1/a2s.png" width="100%"></img>
					<p>
						{t('M2chapter02screen02Model4')}

					</p>
				</Modal.Body>
			</Modal>


			<Modal show={show3} onHide={handleClose3}>
				<Modal.Header closeButton>
					<Modal.Title>{t('M2chapter02screen02Model5')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<img src="/assets/img/chapter1/as3.png" width="100%"></img>
					<p>
						{t('M2chapter02screen02Model6')}

					</p>
				</Modal.Body>
			</Modal>

			<Modal show={show4} onHide={handleClose4}>
				<Modal.Header closeButton>
					<Modal.Title>{t('M2chapter02screen02Model7')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<img src="/assets/img/chapter1/a5s.png" width="100%"></img>
					<p>
					{t('M2chapter02screen02Model14')}
					</p>
				</Modal.Body>
			</Modal>

			<Modal show={show5} onHide={handleClose5}>
				<Modal.Header closeButton>
					<Modal.Title>{t('M2chapter02screen02Model9')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<img src="/assets/img/chapter1/as4.png" width="100%"></img>
					<p>
						{t('M2chapter02screen02Model10')}
					</p>
				</Modal.Body>
			</Modal>

			<Modal show={show6} onHide={handleClose6}>
				<Modal.Header closeButton>
					<Modal.Title>{t('M2chapter02screen02Model11')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<img src="/assets/img/chapter1/as6.png" width="100%"></img>

				</Modal.Body>
			</Modal>

			<Modal show={show7} onHide={handleClose7}>
				<Modal.Header closeButton>
					<Modal.Title>{t('M2chapter02screen02Model8')} </Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<img src="/assets/img/chapter1/as7.png" width="100%"></img>
					<p>
						{t('M2chapter02screen02Model12')}
					</p>
				</Modal.Body>
			</Modal>

			<Modal show={show8} onHide={handleClose8}>
				<Modal.Header closeButton>
					<Modal.Title>{t('M2chapter02screen02Model13')}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<img src="/assets/img/chapter1/as8.png" width="100%"></img>
					<p>
					{t('M2chapter02screen02Model19')}
					</p>
				</Modal.Body>
			</Modal>




		</>
	);
};

export default Chapter02Screen02;
