import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { Modal, Button } from "react-bootstrap";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';


const Chapter04Screen17 = () => {
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();

	const [checkedQues20Option1, setCheckedQues20Option1] = useState('');
	const [checkedQues20Option2, setCheckedQues20Option2] = useState('');

	const [checkedQues20Val, setCheckedQues20Val] = useState('');

	const [checkedQues21Option1, setCheckedQues21Option1] = useState('');
	const [checkedQues21Option2, setCheckedQues21Option2] = useState('');

	const [checkedQues21Val, setCheckedQues21Val] = useState('');

	const [checkedQues22Option1, setCheckedQues22Option1] = useState('');
	const [checkedQues22Option2, setCheckedQues22Option2] = useState('');

	const [checkedQues22Val, setCheckedQues22Val] = useState('');

	const [checkedQues23Option1, setCheckedQues23Option1] = useState('');
	const [checkedQues23Option2, setCheckedQues23Option2] = useState('');

	const [checkedQues23Val, setCheckedQues23Val] = useState('');

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showModalText, setShowModalText] = useState();







	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		if (quesId === '20') {
			setShowModalText('Anytime semen comes in contact with the vaginal area there is a risk of pregnancy')
		}


		if (quesId === '21') {
			setShowModalText('A person is not protected from pregnancy during menstruation. It is impossible to know exactly which days are “safe”.')
		}

		if (quesId === '22') {
			// setShow(false);
			setShowModalText('Pregnancy occurs when the sperm fertilizes the egg and it is not related to a female orgasm')
		}

		if (quesId === '23') {
			setShowModalText('Open communication is important in safe sex practices as there are many physical and emotional consequences of the decision to be sexually active')
		}


		setShow(true);
		checkedVal(quesId, e.target.value)
	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques20": {
					"ans": checkedQues20Val,
					"ques_id": "20"
				},
				"ques21": {
					"ans": checkedQues21Val,
					"ques_id": "21"
				},
				"ques22": {
					"ans": checkedQues22Val,
					"ques_id": "22"
				},
				"ques23": {
					"ans": checkedQues23Val,
					"ques_id": "23"
				},


			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-03/chapter-04-screen-18')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues20Val !== '' && checkedQues21Val !== '' && checkedQues22Val !== '' && checkedQues23Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '20') {
			if (ans === 'option1') {
				setCheckedQues20Option1('checked')
				setCheckedQues20Option2('');

			} else if (ans === 'option2') {
				setCheckedQues20Option1('')
				setCheckedQues20Option2('checked');

			}
			setCheckedQues20Val(ans)
		}

		if (param === '21') {
			if (ans === 'option1') {
				setCheckedQues21Option1('checked')
				setCheckedQues21Option2('');

			} else if (ans === 'option2') {
				setCheckedQues21Option1('')
				setCheckedQues21Option2('checked');

			}
			setCheckedQues21Val(ans)
		}

		if (param === '22') {
			if (ans === 'option1') {
				setCheckedQues22Option1('checked')
				setCheckedQues22Option2('');

			} else if (ans === 'option2') {
				setCheckedQues22Option1('')
				setCheckedQues22Option2('checked');

			}
			setCheckedQues22Val(ans)
		}

		if (param === '23') {
			if (ans === 'option1') {
				setCheckedQues23Option1('checked')
				setCheckedQues23Option2('');

			} else if (ans === 'option2') {
				setCheckedQues23Option1('')
				setCheckedQues23Option2('checked');

			}
			setCheckedQues23Val(ans)
		}




	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-0">
								<div
									className="col-lg-9 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									{/* yahan bhi popup rahega */}

									<h3 className="h3">{t('reflect')}</h3>
									<p className="mb-2 mt-1">{t('M3chapter04Screen17Text1')} </p>


									<p className="mb-2 mt-3 pn"><span><b>1.</b></span> <b>{t('M3chapter04Screen17Text2')}  </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle" id="mycirle2"
												defaultValue="option1"

												onClick={saveRadio}
												checked={checkedQues20Option1}
												data-quesId="20"
											/>
											<label className="form-check-label" htmlFor="mycirle2">{t('true')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle"
												id="mycirle3"
												defaultValue="option2"

												onClick={saveRadio}
												checked={checkedQues20Option2}
												data-quesId="20"

											/>
											<label className="form-check-label" htmlFor="mycirle3">{t('false')}</label>
										</div>
									</div>

									<p className="mb-2 mt-3 pn"><span><b>2.</b></span> <b>{t('M3chapter04Screen17Text3')}  </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle2" id="mycirle3"
												defaultValue="option1"

												onClick={saveRadio}
												checked={checkedQues21Option1}
												data-quesId="21"

											/>
											<label className="form-check-label" htmlFor="mycirle3">{t('true')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle2" id="mycirle4"
												defaultValue="option2"

												onClick={saveRadio}
												checked={checkedQues21Option2}
												data-quesId="21"

											/>
											<label className="form-check-label" htmlFor="mycirle4">{t('false')}</label>
										</div>
									</div>

									<p className="mb-2 mt-3 pn"><span><b>3.</b></span> <b>{t('M3chapter04Screen17Text4')}  </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle3" id="mycirle5"
												defaultValue="option1"

												onClick={saveRadio}
												checked={checkedQues22Option1}
												data-quesId="22"

											/>
											<label className="form-check-label" htmlFor="mycirle5">{t('true')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle3" id="mycirle6"
												defaultValue="option2"

												onClick={saveRadio}
												checked={checkedQues22Option2}
												data-quesId="22"
											/>
											<label className="form-check-label" htmlFor="mycirle6">{t('false')}</label>
										</div>
									</div>


									<p className="mb-2 mt-3 pn"><span><b>4.</b></span> <b> {t('M3chapter04Screen17Text5')}  </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle4" id="mycirle7"
												defaultValue="option1"


												onClick={saveRadio}
												checked={checkedQues23Option1}
												data-quesId="23"
											/>
											<label className="form-check-label" htmlFor="mycirle7">{t('true')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check"
												type="radio"
												name="namecircle4"
												id="mycirle8"
												defaultValue="option2"

												onClick={saveRadio}
												checked={checkedQues23Option2}
												data-quesId="23"

											/>
											<label className="form-check-label" htmlFor="mycirle8">{t('false')}</label>
										</div>
									</div>

									{/* <p className="mb-2 mt-3">{t('M3chapter04Screen17Text6')}  </p> */}









								</div>

								<div
									className="col-lg-2 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="80%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-2-15.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-16" />
								{/* <NextBtn link="/module-03/chapter-04-screen-18" text="Go Next"/> */}

								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>




			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>
							<p className="text-white text-center">Sexting is the digital sharing of intimate images or videos with another person. It is a private type of self-disclosure. While it can bring partners closer, it may also carry some risks. </p>


							<div className=" d-flex justify-content-center mt-3">
								<button className="button_su m-auto" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="/module-03/chapter-03-screen-11" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>

			{/* Modal */}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					{/* <Modal.Title>Women are physically weak :</Modal.Title> */}
				</Modal.Header>

				<Modal.Body>
					<p>{showModalText}</p>
				</Modal.Body>
			</Modal>


		</>
	);
};

export default Chapter04Screen17;
