import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen06 = () => {
  const { t, i18n } = useTranslation();
  
  const navigate = useNavigate();
  const [checkedQues5Yes, setCheckedQues5Yes] = useState('');
  const [checkedQues5No, setCheckedQues5No] = useState('');
  const [checkedQues5Val, setCheckedQues5Val] = useState('');
  const [checkedQues6Yes, setCheckedQues6Yes] = useState('');
  const [checkedQues6No, setCheckedQues6No] = useState('');
  const [checkedQues6Val, setCheckedQues6Val] = useState('');

  const [checkedQues7Yes, setCheckedQues7Yes] = useState('');
  const [checkedQues7No, setCheckedQues7No] = useState('');
  const [checkedQues7Val, setCheckedQues7Val] = useState('');




  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [showModalText,setShowModalText] = useState();
  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");

    checkedVal(quesId,e.target.value)

  }


  const saveAns = () => {

    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": checkedQues5Val,
          "ques_id": "5"
        },
        "ques6": {
          "ans": checkedQues6Val,
          "ques_id": "6"
        },
        "ques7": {
          "ans": checkedQues7Val,
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": "",
          "ques_id": "10"
        },
        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        },

      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/SexualityChapter01',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.status === "Token is Expired")
        {
          window.localStorage.clear();
          navigate('/login')
        }
        if(response.data.message === "Token Invalid")
        {
          window.localStorage.clear();
          navigate('/login')
        }

        if(response.data.success === true)
        {
          navigate('/module-03/chapter-01-screen-07')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const checkedVal = (param,ans) => {
    if (param === '5') {

      if (ans === 'yes') {
        setCheckedQues5Yes('checked')
        setCheckedQues5No('')
      } else {
        if (ans === 'no') {
          setCheckedQues5No('checked')
          setCheckedQues5Yes('')
        }
      }
      setCheckedQues5Val(ans)
    }
    if (param === '6') {
      if (ans === 'yes') {
        setCheckedQues6Yes('checked')
        setCheckedQues6No('')
      } else {
        if (ans === 'no') {
          setCheckedQues6No('checked')
          setCheckedQues6Yes(''
          )
        }
      }
      setCheckedQues6Val(ans)
    }




    if (param === '7') {
      if (ans === 'yes') {
        setCheckedQues7Yes('checked')
        setCheckedQues7No('')

      } else {
        if (ans === 'no') {
          setCheckedQues7No('checked')
          setCheckedQues7Yes('')
        }
      }
      setCheckedQues7Val(ans)
    }







  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/sexualityChapter01Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  const BtnDisabled = () => {

    if (checkedQues5Val !== '' && checkedQues6Val !== '' && checkedQues7Val !== ''
     ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                
          {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                
{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };



  return (
    <>
      <div className="wrapper bg-bd-2">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
          <Header leftChapter="3/4" percentage="75" star="50" />
          </div>
        </div>
        <div className="d-flex">
	        <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-between pt-20 mt-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h3 className="h3">{t('reflect')}  </h3>
                  <p className="mb-2 mt-2"> <b>{t('M3chapter01Screen06Text1')} </b> </p>



                  <p className="mb-2 mt-2">{t('M3chapter01Screen06Text2')}</p>
                  <div className="d-flex mt-2">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio"
                      name="namecircle"
                        id="mycirle2"
                        onClick={saveRadio}
                                defaultValue="yes"
                                data-quesId="5"
                                checked={checkedQues5Yes}

                        />
                      <label className="form-check-label" htmlFor="mycirle2">{t('yes')}</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio"
                      name="namecircle" id="mycirle3"
                      onClick={saveRadio}
                                defaultValue="no"
                                data-quesId="5"
                                checked={checkedQues5No}

                      />
                      <label className="form-check-label" htmlFor="mycirle3">{t('no')}</label>
                    </div>
                  </div>



                  <p className="mb-2 mt-2">{t('M3chapter01Screen06Text3')} </p>
                  <div className="d-flex mt-2">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio"
                      name="namecircle2" id="mycirle3"
                      onClick={saveRadio}
                                defaultValue="yes"
                                data-quesId="6"
                                checked={checkedQues6Yes}

                        />
                      <label className="form-check-label" htmlFor="mycirle3">{t('yes')}</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio"
                      name="namecircle2" id="mycirle4"
                      onClick={saveRadio}
                                defaultValue="no"
                                data-quesId="6"
                                checked={checkedQues6No}

                        />
                      <label className="form-check-label" htmlFor="mycirle4">{t('no')}</label>
                    </div>
                  </div>


                  <p className="mb-2 mt-2">{t('M3chapter01Screen06Text4')} </p>
                  <div className="d-flex mt-3">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio"
                      name="namecircle3"
                        id="mycirle5"
                        onClick={saveRadio}
                                defaultValue="yes"
                                data-quesId="7"
                                checked={checkedQues7Yes}
                          />
                      <label className="form-check-label" htmlFor="mycirle5">{t('yes')}</label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio"
                      name="namecircle3" id="mycirle6"
                      onClick={saveRadio}
                                defaultValue="no"
                                data-quesId="7"
                                checked={checkedQues7No}

                        />
                      <label className="form-check-label" htmlFor="mycirle6">{t('no')}</label>
                    </div>
                  </div>


                  <div className="para-color-box mt-3">
                    <p className="mb-2 "> {t('M3chapter01Screen06Text5')} </p>
                  </div>

                </div>





              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">

                <PrevBtn link="/module-03/chapter-01-screen-05"/>
                {/* <NextBtn link="/module-05/chapter-01-screen-07" text="Go Next"/> */}
                <BtnDisabled/>


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter01Screen06;
