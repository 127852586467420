import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';




const Chapter04Screen05 = () => {

	const navigate = useNavigate();
	const { t, i18n } = useTranslation();


	const [checkedQues4, setCheckedQues4] = useState('');
	const [checkedQues5, setCheckedQues5] = useState('');
	const [checkedQues6, setCheckedQues6] = useState('');
	const [checkedQues7, setCheckedQues7] = useState('');
	const [checkedQues8, setCheckedQues8] = useState('');
	const [checkedQues3, setCheckedQues3] = useState('');



	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3,

					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4,

					"ques_id": "4"
				},
				"ques5": {
					"ans": checkedQues5,
					"ques_id": "5"
				},
				"ques6": {
					"ans": checkedQues6,
					"ques_id": "6"
				},
				"ques7": {
					"ans": checkedQues7,
					"ques_id": "7"
				},
				"ques8": {
					"ans": checkedQues8,
					"ques_id": "8"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-04-screen-05n')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					results.map((result) => {
						console.log(result);
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);
	const checkedVal = (param, ans) => {

		if (param === '3') {
			setCheckedQues3(ans)

		}

		if (param === '4') {
			setCheckedQues4(ans)

		}

		if (param === '5') {
			setCheckedQues5(ans)

		}

		if (param === '6') {
			setCheckedQues6(ans)

		}
		if (param === '7') {
			setCheckedQues7(ans)

		}
		if (param === '8') {
			setCheckedQues8(ans)

		}

	}
	const BtnDisabled = () => {
		if (checkedQues3 !== '' && checkedQues4 !== '' && checkedQues5 !== '' && checkedQues6 !== '' && checkedQues7 !== '' && checkedQues8 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">

								{t('CommonBtnGoNext')}
								<i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};





	return (
		<>
			<div className="wrapper page-auto md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="36" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />

					<div className="data-adjust">
						<div className="container-fluid cf-set">

							<div className="row align-items-top justify-content-between pt-20 mt-4">

								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className=""><b>{t('M5chapter04Screen5Text1')}</b></p>
									<div className="mt-1 positon-submit">
										<textarea className="form-control" rows={1} placeholder="Type your Answer......."
											defaultValue={checkedQues3}
											onChange={(e) => setCheckedQues3(e.target.value)}
										/>
									</div>

									<p className=" mt-1"><b>{t('M5chapter04Screen5Text2')}</b></p>
									<div className="mt-1 positon-submit">
										<textarea className="form-control fc-height" rows={1} placeholder="Type your Answer......."
											defaultValue={checkedQues4}
											onChange={(e) => setCheckedQues4(e.target.value)}
										/>
									</div>
									<p className=" mt-1"><b>{t('M5chapter04Screen5Text3')}</b></p>
									<div className="mt-1 positon-submit">
										<textarea className="form-control fc-height" rows={1} placeholder="Type your Answer......."

											defaultValue={checkedQues5}
											onChange={(e) => setCheckedQues5(e.target.value)}
										/>
									</div>







									<p className=" mt-3"> <b>{t('M5chapter04Screen5Text4')}</b></p>
									<a
										href="https://www.youtube.com/watch?v=NFs6eNnQ-m0&list=PLbyiiPziC3QqxXRqX-gXCT0fhL94HyoqE"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M5chapter04Screen5Text5')}</p>
											</div>
										</div>
									</a>


									<p className=" mt-3"><b>{t('M5chapter04Screen5Text6')}</b></p>
									<div className="mt-1 positon-submit">
										<textarea className="form-control fc-height" rows={1} placeholder="Type your Answer......."

											defaultValue={checkedQues6}
											onChange={(e) => setCheckedQues6(e.target.value)}

										/>
									</div>

									<p className=" mt-3"><b>{t('M5chapter04Screen5Text7')}</b></p>
									<div className="mt-1 positon-submit">
										<textarea className="form-control fc-height" rows={1} placeholder="Type your Answer......."

											defaultValue={checkedQues7}
											onChange={(e) => setCheckedQues7(e.target.value)}
										/>
									</div>
									<p className=" mt-3"><b>{t('M5chapter04Screen5Text8')}</b></p>
									<div className="mt-1 positon-submit">
										<textarea className="form-control fc-height" rows={1} placeholder="Type your Answer......."

											defaultValue={checkedQues8}
											onChange={(e) => setCheckedQues8(e.target.value)}
										/>
									</div>


















								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											className="mt-0"
											width="70%"
											src="/assets/img/md3/md3-shap-4-04.png"
											alt=""
										/>
									</center>
								</div>

							</div>


						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-05/chapter-04-screen-04" />

									{/* <NextBtn link="/module-05/chapter-04-screen-06" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen05;
