import React,{useState,useEffect} from "react";

import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';
const Chapter03Screen11N = () => {

  const navigate = useNavigate();

  const [checkedQues10, setCheckedQues10] = useState('');
  const [checkedQues11, setCheckedQues11] = useState('');
  const [checkedQues12, setCheckedQues12] = useState('');

 const { t, i18n } = useTranslation();

  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques10": {
          "ans": checkedQues10,
          "ques_id": "10"
        },
        "ques11": {
          "ans": checkedQues11,
          "ques_id": "11"
        },
        "ques12": {
          "ans": checkedQues12,
          "ques_id": "12"
        },

      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/HumanRightsChapter03',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-05/chapter-03-screen-12')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const checkedVal = (param,ans) => {
    if (param === '10') {
      setCheckedQues10(ans)
    }

    if (param === '11') {
      setCheckedQues11(ans)
    }
    if (param === '12') {
      setCheckedQues12(ans)
    }



  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/HumanRightsChapter03Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  const BtnDisabled = () => {
    if (checkedQues10 !== '' && checkedQues11 !=='' && checkedQues12 !=='' ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div className="wrapper  md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="1/4" percentage="25" star="2" />
          </div>
        </div>

        <div className="d-flex">
				<Sidebar/>

        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-3">


              <div
                className="col-lg-9 mb-7  wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h3>{t('reflect')}</h3>
                <p className=" mt-3">{t('M5chapter03Screen11nText1')} <b>{t('M5chapter03Screen11nText2')}</b></p>
                <div className=" positon-submit">
                  <textarea className="form-control"  rows={1}  placeholder="Type your Answer........"
                    onChange={(e)=>setCheckedQues10(e.target.value)}
                    data-quesId="10"
                    defaultValue={checkedQues10}
                  />
                </div>
                <p className=" mt-3">{t('M5chapter03Screen11nText3')}<b>{t('M5chapter03Screen11nText2')}</b></p>
                <div className=" positon-submit">
                  <textarea className="form-control"  rows={1}  placeholder="Type your Answer........"
                    onChange={(e)=>setCheckedQues11(e.target.value)}
                    data-quesId="11"
                    defaultValue={checkedQues11}

                  />
                </div>
                <p className=" mt-3">{t('M5chapter03Screen11nText4')} <b>{t('M5chapter03Screen11nText2')}</b> </p>
                <div className=" positon-submit">
                  <textarea className="form-control"  rows={1}  placeholder="Type your Answer........"
                    onChange={(e)=>setCheckedQues12(e.target.value)}
                    data-quesId="12"
                    defaultValue={checkedQues12}

                  />
                </div>

              </div>

            </div>
          </div>
        </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-05/chapter-03-screen-11"/>
                {/* <NextBtn link="/module-05/chapter-03-screen-12" text="Go Next"/> */}
                <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen11N;
