import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen10 = () => {
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
	const [checkedQues21Option1, setCheckedQues21Option1] = useState('');
	const [checkedQues21Option2, setCheckedQues21Option2] = useState('');
	const [checkedQues21Val, setCheckedQues21Val] = useState('');

	const [checkedQues22Option1, setCheckedQues22Option1] = useState('');
	const [checkedQues22Option2, setCheckedQues22Option2] = useState('');
	const [checkedQues22Val, setCheckedQues22Val] = useState('');

	const [checkedQues23Option1, setCheckedQues23Option1] = useState('');
	const [checkedQues23Option2, setCheckedQues23Option2] = useState('');
	const [checkedQues23Val, setCheckedQues23Val] = useState('');





	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showModalText, setShowModalText] = useState();
	const user_details = JSON.parse(localStorage.getItem('user_details'));



	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		checkedVal(quesId, e.target.value)
	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques21": {
					"ans": checkedQues21Val,
					"ques_id": "21"
				},
				"ques22": {
					"ans": checkedQues22Val,
					"ques_id": "22"
				},
				"ques23": {
					"ans": checkedQues23Val,
					"ques_id": "23"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-01-screen-19')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const checkedVal = (param, ans) => {
		if (param === '21') {
			if (ans === 'option1') {
				setCheckedQues21Option1('checked')
				setCheckedQues21Option2('')

			} else if (ans === 'option2') {
				setCheckedQues21Option1('')
				setCheckedQues21Option2('checked')

			}
			setCheckedQues21Val(ans)
		}

		if (param === '22') {
			if (ans === 'option1') {
				setCheckedQues22Option1('checked')
				setCheckedQues22Option2('')

			} else if (ans === 'option2') {
				setCheckedQues22Option1('')
				setCheckedQues22Option2('checked')

			}
			setCheckedQues22Val(ans)
		}

		if (param === '23') {
			if (ans === 'option1') {
				setCheckedQues23Option1('checked')
				setCheckedQues23Option2('')

			} else if (ans === 'option2') {
				setCheckedQues23Option1('')
				setCheckedQues23Option2('checked')

			}
			setCheckedQues23Val(ans)
		}






	}



	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	const BtnDisabled = () => {
		if (checkedQues21Val !== '' && checkedQues22Val !== '' && checkedQues23Val !== ''
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/2" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-4 mt-m-3">
								<div
									className="col-lg-9 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>


									<p className="mt-3">{t('M3chapter01Screen18thText1')}  </p>
									<p className="mt-3">{t('M3chapter01Screen18thText2')} </p>
									<p className="mt-3"> {t('M3chapter01Screen18thText3')}</p>



									<p className="mt-3"> {t('M3chapter01Screen18thText4')} </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check"
												type="radio"
												name="inlineRadioOptions"
												id="inlineRadio1"
												onClick={saveRadio}
												defaultValue="option1"
												data-quesId="21"
												checked={checkedQues21Option1}

											/>
											<label className="form-check-label" htmlFor="inlineRadio1">{t('yes')}</label>
										</div>
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check"
												type="radio" name="inlineRadioOptions"
												id="inlineRadio2"
												onClick={saveRadio}
												defaultValue="option2"
												data-quesId="21"
												checked={checkedQues21Option2}

											/>
											<label className="form-check-label " htmlFor="inlineRadio2">{t('no')}</label>
										</div>
									</div>

									<p className="mt-3"> {t('M3chapter01Screen18thText5')}</p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check"
												type="radio"
												name="inlineRadioOptions2"
												id="inlineRadio3"
												onClick={saveRadio}
												defaultValue="option1"
												data-quesId="22"
												checked={checkedQues22Option1}
											/>
											<label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
										</div>
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check"
												type="radio"
												name="inlineRadioOptions2"
												id="inlineRadio4"
												onClick={saveRadio}
												defaultValue="option2"
												data-quesId="22"
												checked={checkedQues22Option2}
											/>
											<label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
										</div>
									</div>

									<p className="mt-3">{t('M3chapter01Screen18thText6')}  </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check"
												type="radio"
												name="inlineRadioOptions3"
												id="inlineRadio5"
												onClick={saveRadio}
												defaultValue="option1"
												data-quesId="23"
												checked={checkedQues23Option1}

											/>
											<label className="form-check-label" htmlFor="inlineRadio5">{t('yes')}</label>
										</div>
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check"
												type="radio" name="inlineRadioOptions3"
												id="inlineRadio6"
												onClick={saveRadio}
												defaultValue="option2"
												data-quesId="23"
												checked={checkedQues23Option2}
											/>
											<label className="form-check-label " htmlFor="inlineRadio6">{t('no')}</label>
										</div>
									</div>

								</div>

								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="60%"
											draggable="false"
											src="/assets/img/chapter1/reading-book.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>







					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-01-screen-18t" />
								{/* <NextBtn link="/module-05/chapter-01-screen-19" text="Go Next"/> */}
								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen10;
