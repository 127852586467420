import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";
const Chapter02Screen04N6 = () => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [checkedQues19, setCheckedQues19] = useState([]);
    const [checkedQues19Option1, setCheckedQues19Option1] = useState('');
    const [checkedQues19Option2, setCheckedQues19Option2] = useState('');
    const [checkedQues19Option3, setCheckedQues19Option3] = useState('');
    const [checkedQues19Option4, setCheckedQues19Option4] = useState('');
    const [checkedQues19Option5, setCheckedQues19Option5] = useState('');
    const [checkedQues19Option6, setCheckedQues19Option6] = useState('');
    const [checkedQues19Option7, setCheckedQues19Option7] = useState('');
    const user_details = JSON.parse(localStorage.getItem('user_details'));
    const saveRadio = (e) => {
        const quesId = e.target.getAttribute("data-quesId");
        checkedVal(quesId, e.target.value)
    }
    const saveGenderBased = (e) => {
        const quesId = e.target.getAttribute("data-quesId");
        const res = e.target.value;
        const checked = e.target.checked;
        checkBoxChecked(res, checked, quesId);
        if (quesId === '19') {
            setCheckedQues19(checkedQues19 => [...checkedQues19, res]);
        }

    }
    const saveAns = () => {
        var data = JSON.stringify({
            "user_id": user_details.user_id,
            "answers": {
                "ques19": {
                    "ans": checkedQues19.join(','),
                    "ques_id": "19"
                }

            }
        });
        var config = {
            method: 'post',
            url: BaseURL + '/SexualityChapter02',
            headers: {
                'Authorization': `Bearer ${user_details.token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                if (response.status === "Token is Expired") {
                    window.localStorage.clear();
                    navigate('/login')
                }
                if (response.data.message === "Token Invalid") {
                    window.localStorage.clear();
                    navigate('/login')
                }
                if (response.data.success === true) {
                    navigate('/module-03/chapter-02-screen-05')
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const BtnDisabled = () => {
        if (checkedQues19.length > 0 ) {
            return (
                <>
                    <button
                        className={`button_su`}
                        data-wow-duration="1s"
                        data-wow-delay="0.2s"
                    >
                        <span className="su_button_circle" />
                        <button className="button_su_inner" onClick={saveAns} >
                            <span className="button_text_container">
                                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
                            </span>
                        </button>
                    </button>
                </>
            );
        } else {
            return (
                <>
                    <button className="button_su disabled-btn" type="button" disabled="">
                        <span className="su_button_circle"></span>
                        <span className="button_su_inner">
                            <span className="button_text_container">
                                {" "}
                                {t('CommonBtnGoNext')}<i className="bi bi-arrow-right" />
                            </span>{" "}
                        </span>
                    </button>
                </>
            );
        }
    };
    const checkedVal = (param, ans) => {

        if (param === '19') {
            const ansArr = ans.split(',');
            ansArr.map((el) => {
                const checked = true;
                checkBoxChecked(el, checked, param);
            })
            setCheckedQues19(ansArr)
        }

    }
    const checkBoxChecked = (val, checked, quesId) => {
        if (quesId === '19') {
            if (val === 'option1') {
                if (checked === true) {
                    setCheckedQues19Option1('checked');
                } else {
                    setCheckedQues19Option1('');
                }
            }
            if (val === 'option2') {
                if (checked === true) {
                    setCheckedQues19Option2('checked');
                } else {
                    setCheckedQues19Option2('');
                }
            }
            if (val === 'option3') {
                if (checked === true) {
                    setCheckedQues19Option3('checked');
                } else {
                    setCheckedQues19Option3('');
                }
            }
            if (val === 'option4') {
                if (checked === true) {
                    setCheckedQues19Option4('checked');
                } else {
                    setCheckedQues19Option4('');
                }
            }
            if (val === 'option5') {
                if (checked === true) {
                    setCheckedQues19Option5('checked');
                } else {
                    setCheckedQues19Option5('');
                }
            }
            if (val === 'option6') {
                if (checked === true) {
                    setCheckedQues19Option6('checked');
                } else {
                    setCheckedQues19Option6('');
                }
            }

            if (val === 'option7') {
                if (checked === true) {
                    setCheckedQues19Option7('checked');
                } else {
                    setCheckedQues19Option7('');
                }
            }

        }

    }
    useEffect(() => {
        var data = JSON.stringify({
            "user_id": user_details.user_id,
            "ques_id": ""
        });
        var config = {
            method: 'post',
            url: BaseURL + '/sexualityChapter02Result',
            headers: {
                'Authorization': `Bearer ${user_details.token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                if (response.data.success === true) {
                    const results = response.data.data.chapter;
                    // console.log(results);
                    results.map((result) => {
                        checkedVal(result.ques_id.toString(), result.ans)
                    })
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])
    return (
        <>
            <div className="wrapper bg-bd-2 hp">
                <div
                    className="fixed-header"
                >
                    <div className="container-fluid cf-set">
                        <Header leftChapter="3/4" percentage="78" star="50" />
                    </div>
                </div>
                <div className="d-flex">
                    <Sidebar />
                    <div className="data-adjust">
                        <div className="container-fluid cf-set">
                            <div className="row align-items-top justify-content-between pt-20 mr-3 mt-m-3">
                                <div
                                    className="col-lg-12 wow fadeInDown"
                                    data-wow-duration="1s"
                                    data-wow-delay="0.2s"
                                >
                                    <p className="mb-2 mt-3 pn"><b>{t('M3chapter02Screen04N6Text1')}</b> </p>
                                    <div className="d-flex">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input custom-check" type="checkbox"
                                                name="namecircle"
                                                id="mycirle1"
                                                defaultValue="option1"
                                                onClick={saveGenderBased}
                                                checked={checkedQues19Option1}
                                                data-quesId="19"
                                            />
                                            <label className="form-check-label" htmlFor="mycirle1">{t('M3chapter02Screen04N6Text2')}</label>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input custom-check" type="checkbox"
                                                name="namecircle"
                                                id="mycirle2"
                                                defaultValue="option2"
                                                onClick={saveGenderBased}
                                                checked={checkedQues19Option2}
                                                data-quesId="19"
                                            />
                                            <label className="form-check-label" htmlFor="mycirle2">{t('M3chapter02Screen04N6Text3')}</label>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input custom-check" type="checkbox"
                                                name="namecircle"
                                                id="mycirle3"
                                                defaultValue="option3"
                                                onClick={saveGenderBased}
                                                checked={checkedQues19Option3}
                                                data-quesId="19"
                                            />
                                            <label className="form-check-label" htmlFor="mycirle3"> {t('M3chapter02Screen04N6Text4')}</label>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input custom-check" type="checkbox"
                                                name="namecircle"
                                                id="mycirle4"
                                                defaultValue="option4"
                                                onClick={saveGenderBased}
                                                checked={checkedQues19Option4}
                                                data-quesId="19"
                                            />
                                            <label className="form-check-label" htmlFor="mycirle4"> {t('M3chapter02Screen04N6Text5')} </label>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input custom-check" type="checkbox"
                                                name="namecircle"
                                                id="mycirle4"
                                                defaultValue="option5"
                                                onClick={saveGenderBased}
                                                checked={checkedQues19Option5}
                                                data-quesId="19"
                                            />
                                            <label className="form-check-label" htmlFor="mycirle4"> {t('M3chapter02Screen04N6Text6')} </label>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input custom-check" type="checkbox"
                                                name="namecircle"
                                                id="mycirle4"
                                                defaultValue="option6"
                                                onClick={saveGenderBased}
                                                checked={checkedQues19Option6}
                                                data-quesId="19"
                                            />
                                            <label className="form-check-label" htmlFor="mycirle4"> {t('M3chapter02Screen04N6Text7')} </label>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="form-check form-check-inline">
                                            <input className="form-check-input custom-check" type="checkbox"
                                                name="namecircle"
                                                id="mycirle4"
                                                defaultValue="option7"
                                                onClick={saveGenderBased}
                                                checked={checkedQues19Option7}
                                                data-quesId="19"
                                            />
                                            <label className="form-check-label" htmlFor="mycirle4"> {t('M3chapter02Screen04N6Text8')} </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="button-fixed wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
            >
                <div className="container-fluid cf-set">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className=" d-flex justify-content-end">
                                <PrevBtn link="/module-03/chapter-02-screen-04n5" />
                                {/* <NextBtn link="/module-06/chapter-04-screen-04" text="Go Next"/> */}
                                <BtnDisabled />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
export default Chapter02Screen04N6;
