import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen07 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	// const [checkedQues10, setCheckedQues10] = useState('');
	const [checkedQues10Yes, setCheckedQues10Yes] = useState('');
	const [checkedQues10No, setCheckedQues10No] = useState('');
	const [checkedQues10Val, setCheckedQues10Val] = useState('');

	const [checkedQues11, setCheckedQues11] = useState('');
	const [checkedQues12, setCheckedQues12] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const itemVal = e.target.value;

		checkedVal(quesId, itemVal);



	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": checkedQues10Val,
					"ques_id": "10"
				},
				"ques11": {
					"ans": checkedQues11,
					"ques_id": "11"
				},
				"ques12": {
					"ans": checkedQues12,
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-02-screen-08')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues10Val !== '' && checkedQues11 !== '' && checkedQues12 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '10') {
			if (ans === 'easy') {
				setCheckedQues10Yes('checked')
				setCheckedQues10No('')
			} else {
				if (ans === 'difficuilt') {
					setCheckedQues10No('checked')
					setCheckedQues10Yes(''
					)
				}
			}
			setCheckedQues10Val(ans)
		}
		if (param === '11') {
			setCheckedQues11(ans)
		}

		if (param === '12') {
			setCheckedQues12(ans)
		}

	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},

			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="18" star="18" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
				
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-1">
								<div
									className="col-lg-7 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mb-2 mt-3"> {t('M1chapter02screen07Text1')}</p>
									{/* <textarea
					className="form-control mt-2"
					rows={2}
					placeholder="Write Something here....."
					onChange={(e)=>setCheckedQues10(e.target.value)}
					data-quesId="10"
					defaultValue={checkedQues10}
					>
				</textarea> */}

									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="ques15"
												id="inlineRadio1"
												defaultValue="easy"
												onClick={saveRadio}
												data-quesId="10"
												checked={checkedQues10Yes}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
											{t('easy')}
											</label>
										</div>
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="ques15"
												id="inlineRadio2"
												defaultValue="difficuilt"
												onClick={saveRadio}
												data-quesId="10"
												checked={checkedQues10No}
											/>
											<label className="form-check-label " htmlFor="inlineRadio2">
											{t('difficuilt')}
											</label>
										</div>
									</div>



									<p className="mt-3"> {t('M1chapter02screen07Text2')}</p>
									<textarea
										className="form-control mt-2"
										rows={2}
										placeholder="Write Something here....."
										onChange={(e) => setCheckedQues11(e.target.value)}
										data-quesId="11"
										defaultValue={checkedQues11}
									></textarea>


									<p className="mt-3"> {t('M1chapter02screen07Text3')}</p>

									<textarea
										className="form-control mt-2"
										rows={2}
										placeholder="Write Something here....."
										onChange={(e) => setCheckedQues12(e.target.value)}
										data-quesId="12"
										defaultValue={checkedQues12}
									/>



									{/* <div className="mt-4 positon-submit">

				</div> */}
								</div>
								<div
									className="col-lg-5 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img width="71%" src="/assets/img/md2/md2-shap-2-7.png" alt="" />
									</center>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-02-screen-06" />

								{/* <NextBtn link="/module-02/chapter-02-screen-08" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	);
};

export default Chapter02Screen07;
