import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from "react-i18next";

const Chapter04Screen12 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const [checkedQues2, setCheckedQues2] = useState([]);
	const [brahminChecked, setBrahminChecked] = useState('');
	const [lesbianChecked, setLesbianChecked] = useState('');
	const [singerChecked, setSingerChecked] = useState('');
	const [DarkskinnedChecked, setDarkskinnedChecked] = useState('');

	const [checkedQues3Yes, setCheckedQues3Yes] = useState('');
	const [checkedQues3No, setCheckedQues3No] = useState('');
	const [checkedQues3Val, setCheckedQues3Val] = useState('');

	const [checkedQues4Yes, setCheckedQues4Yes] = useState('');
	const [checkedQues4No, setCheckedQues4No] = useState('');
	const [checkedQues4Val, setCheckedQues4Val] = useState('');



	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);
		checkedVal(quesId, e.target.value)
	}

	const saveGenderBased = (e) => {
		const res = e.target.value;
		const checked = e.target.checked;


		checkBoxChecked(res, checked);
		setCheckedQues2(checkedQues1 => [...checkedQues1, res]);
	}
	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": checkedQues2.join(','),
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3Val,
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4Val,
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfFinalQuiz',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-04-screen-13')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues2.length > 0 && checkedQues3Val !== '' && checkedQues4Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								Go Next <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	const checkedVal = (param, ans) => {


		if (param === '2') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked);
			})
			setCheckedQues2(ansArr)
		}

		if (param === '3') {
			if (ans === 'yes') {
				setCheckedQues3Yes('checked')
				setCheckedQues3No('')


			} else if (ans === 'no') {
				setCheckedQues3Yes('')
				setCheckedQues3No('checked')


			}
			setCheckedQues3Val(ans)
		}

		if (param === '4') {
			if (ans === 'yes') {
				setCheckedQues4Yes('checked')
				setCheckedQues4No('')


			} else if (ans === 'no') {
				setCheckedQues4Yes('')
				setCheckedQues4No('checked')


			}
			setCheckedQues4Val(ans)
		}




	}

	const checkBoxChecked = (val, checked) => {
		if (val === 'Brahmin') {
			if (checked === true) {
				setBrahminChecked('checked');

			} else {
				setBrahminChecked('');
			}
		}

		if (val === 'Lesbian') {
			if (checked === true) {
				setLesbianChecked('checked');
			} else {
				setLesbianChecked('');
			}
		}

		if (val === 'Singer') {
			if (checked === true) {
				setSingerChecked('checked');
			} else {
				setSingerChecked('');
			}
		}

		if (val === 'Dark skinned') {
			if (checked === true) {
				setDarkskinnedChecked('checked');
			} else {
				setDarkskinnedChecked('');
			}
		}



	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfFinalQuizResult',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="92" star="92" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar/>
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between pt-20 ">
								<div
									className="col-lg-9 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mb-2 mt-3 pn">
										<span>
											<b>2.</b>
										</span>{" "}
										<b>
											{t('M1chapter04screen12Text1')}

										</b>{" "}
									</p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle1"
												defaultValue="Brahmin"
												onClick={saveGenderBased}
												checked={brahminChecked}
											/>
											<label className="form-check-label" htmlFor="mycirle1">
											{t('M1chapter04screen12Text2')}{" "}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle2"
												defaultValue="Lesbian"
												onClick={saveGenderBased}
												checked={lesbianChecked}
											/>
											<label className="form-check-label" htmlFor="mycirle2">
											{t('M1chapter04screen12Text3')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle3"
												defaultValue="Singer"
												onClick={saveGenderBased}
												checked={singerChecked}
											/>
											<label className="form-check-label" htmlFor="mycirle3">
											{t('M1chapter04screen12Text4')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle4"
												defaultValue="Dark skinned"
												onClick={saveGenderBased}
												checked={DarkskinnedChecked}
											/>
											<label className="form-check-label" htmlFor="mycirle4">
											{t('M1chapter04screen12Text5')}{" "}
											</label>
										</div>
									</div>
									<p className="mb-2 mt-3 pn">
										<span>
											<b>3.</b>
										</span>
										<b>
											{" "}
											{t('M1chapter04screen12Text6')} {" "}
										</b>{" "}
									</p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="namecircle2"
												id="mycirle5"
												defaultValue="yes"
												onClick={saveRadio}
												data-quesId="3"
												checked={checkedQues3Yes}
											/>
											<label className="form-check-label" htmlFor="mycirle5">
												{t('true')}{" "}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="namecircle2"
												id="mycirle6"
												defaultValue="no"
												onClick={saveRadio}
												data-quesId="3"
												checked={checkedQues3No}
											/>
											<label className="form-check-label" htmlFor="mycirle6">
											{t('false')}
											</label>
										</div>
									</div>
									<p className="mb-2 mt-3 pn">
										<span>
											<b>4.</b>
										</span>{" "}
										<b>{t('M1chapter04screen12Text7')}</b>{" "}
									</p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="namecircle3"
												id="mycirle7"
												defaultValue="yes"
												onClick={saveRadio}
												data-quesId="4"
												checked={checkedQues4Yes}
											/>
											<label className="form-check-label" htmlFor="mycirle7">
												{t('true')}{" "}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="namecircle3"
												id="mycirle8"
												defaultValue="no"
												onClick={saveRadio}
												data-quesId="4"
												checked={checkedQues4No}
											/>
											<label className="form-check-label" htmlFor="mycirle8">
												{t('false')}
											</label>
										</div>
									</div>
								</div>
								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="100%"
											draggable="false"
											src="/assets/img/md2/md2-shap-4-12.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-01/chapter-04-screen-11" />

									{/* <NextBtn
                    link="/module-02/chapter-04-screen-13"
                    text="Go Next"
                  /> */}

									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen12;
