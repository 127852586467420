import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen17 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-1">


								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>


									<h3 className="h3 mt-3">{t('learn')}  </h3>
									<p className="mb-2  mt-3">{t('M3chapter03Screen17Text1')}  </p>
									<p className="mb-2  mt-3">{t('M3chapter03Screen17Text1N')}  </p>
									<p className="mb-2  mt-3">{t('M3chapter03Screen17Text1N2')}  </p>



									<table className="table table-bordered table-dark table-red table-padding mt-4 text-center" style={{  "list-style-type": "none"}}>
										<tbody>
											<tr>
												<td className="td-blue width-20">{t('PASSIVE')}</td>
												<td className="td-green">{t('ASSERTIVE')} </td>
												<td className="td-blue">{t('AGGRESSIVE')} </td>
											</tr>
											<tr>
												<td className="td-blue width-20">

													<ul style={{listStyle:"none"}}>
														<li>{t('M3chapter03Screen17Text2')} </li>
														<li>{t('M3chapter03Screen17Text2N')} </li>
														<li>{t('M3chapter03Screen17Text2N1')} </li>
													</ul>


													</td>
												<td className="td-green">


												<ul style={{listStyle:"none"}}>
														<li>{t('M3chapter03Screen17Text3')} </li>
														<li>{t('M3chapter03Screen17Text3N')} </li>
														<li>{t('M3chapter03Screen17Text3N1')} </li>
													</ul>



													 </td>
												<td className="td-blue">

												<ul style={{listStyle:"none"}}>
														<li>{t('M3chapter03Screen17Text4')} </li>
														<li>{t('M3chapter03Screen17Text4N')} </li>
														<li>{t('M3chapter03Screen17Text4N1')} </li>
													</ul>


													</td>
											</tr>


										</tbody>
									</table>

									<p className="mb-2  mt-3"><b>{t('M3chapter03Screen17Text5')}</b>  </p>

									{/* <p className="mb-2  mt-3">We may have seen or know of, in real life or in films/TV, cases where individuals are in relationships that do not have the characteristics we discussed above! Relationships between partners are often disrespectful, toxic, unequal and even abusive.  </p> */}
								</div>

								{/* <div
					className="col-lg-10 wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<center>
					<img
						width="100%"
						draggable="false"
						src="/assets/img/md5/chap02-2/4.jpg"
						alt=""
					/>
					</center>
				</div>

					*/}



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-03-screen-16" />
								<NextBtn link="/module-03/chapter-03-screen-18" text={t('CommonBtnGoNext')} />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen17;
