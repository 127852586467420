import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen08 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [checkedQues9, setCheckedQues9] = useState([]);
  const [typea9Checked, setTypeA9Checked] = useState();
  const [typeb9Checked, setTypeB9Checked] = useState();
  const [typec9Checked, setTypeC9Checked] = useState();
  const [typed9Checked, setTypeD9Checked] = useState();

  const [checkedQues10, setCheckedQues10] = useState([]);
  const [typea10Checked, setTypeA10Checked] = useState();
  const [typeb10Checked, setTypeB10Checked] = useState();
  const [typec10Checked, setTypeC10Checked] = useState();
  const [typed10Checked, setTypeD10Checked] = useState();


  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveGenderBased = (e) => {

    const quesId = e.target.getAttribute("data-quesId");

    const res = e.target.value;
    const checked = e.target.checked;
    checkBoxChecked(res, checked);
    if (quesId == '9') {

      setCheckedQues9(checkedQues9 => [...checkedQues9, res]);
    }
    if (quesId == '10') {

      setCheckedQues10(checkedQues10 => [...checkedQues10, res]);
    }
  }
  const saveAns = () => {

    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques9": {
          "ans": checkedQues9.join(','),
          "ques_id": "9"
        },
        "ques10": {
          "ans": checkedQues10.join(','),
          "ques_id": "10"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        }

      }
    });
    var config = {
      method: 'post',
      url: BaseURL + '/MasculChapter02',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate('/module-04/chapter-02-screen-09')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues9.length > 0) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };


  const checkedVal = (param, ans) => {


    if (param === '9') {
      const ansArr = ans.split(',');
      ansArr.map((el) => {
        const checked = true;
        checkBoxChecked(el, checked, param);
      })
      setCheckedQues9(ansArr)
    }

    if (param === '10') {
      const ansArr = ans.split(',');
      ansArr.map((el) => {
        const checked = true;
        checkBoxChecked(el, checked, param);
      })
      setCheckedQues10(ansArr)
    }


  }

  const checkBoxChecked = (val, checked, param) => {

    if (param == '9') {


      if (val === 'type a') {
        if (checked === true) {
          setTypeA9Checked('checked');

        } else {
          setTypeA9Checked('');
        }
      }

      if (val === 'type b') {
        if (checked === true) {
          setTypeB9Checked('checked');
        } else {
          setTypeB9Checked('');
        }
      }

      if (val === 'type c') {
        if (checked === true) {
          setTypeC9Checked('checked');
        } else {
          setTypeC9Checked('');
        }
      }

      if (val === 'type d') {
        if (checked === true) {
          setTypeD9Checked('checked');
        } else {
          setTypeD9Checked('');
        }
      }
    }


    if (param == '10') {


      if (val === 'type a') {
        if (checked === true) {
          setTypeA10Checked('checked');

        } else {
          setTypeA10Checked('');
        }
      }

      if (val === 'type b') {
        if (checked === true) {
          setTypeB10Checked('checked');
        } else {
          setTypeB10Checked('');
        }
      }

      if (val === 'type c') {
        if (checked === true) {
          setTypeC10Checked('checked');
        } else {
          setTypeC10Checked('');
        }
      }

      if (val === 'type d') {
        if (checked === true) {
          setTypeD10Checked('checked');
        } else {
          setTypeD10Checked('');
        }
      }

    }













  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/MasculChapter02Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(), result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="1/5" percentage="20" star="20" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set lavel-content-set">
              <div className="row align-items-center justify-content-between">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="row">
                    <div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >


                      <p className="mb-2 mt-3 ">
                        <b>
                          {t('M4chapter02screen08Text1')}
                        </b>
                      </p>
                    </div>
                    <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle"
                            id="mycirle"

                            defaultValue="type a"
                            onClick={saveGenderBased}
                            checked={typea9Checked}
                            data-quesId="9"
                          />
                          <label className="form-check-label" htmlFor="mycirle">
                            {" "}
                            {t('typea')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle"
                            id="mycirle2"
                            defaultValue="type b"
                            onClick={saveGenderBased}
                            checked={typeb9Checked}
                            data-quesId="9"
                          />
                          <label className="form-check-label" htmlFor="mycirle2">
                            {t('typeb')}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle"
                            id="mycirle3"
                            defaultValue="type c"
                            onClick={saveGenderBased}
                            checked={typec9Checked}
                            data-quesId="9"
                          />
                          <label className="form-check-label" htmlFor="mycirle3">
                            {" "}
                            {t('typec')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle"
                            id="mycirle4"
                            defaultValue="type d"
                            onClick={saveGenderBased}
                            checked={typed9Checked}
                            data-quesId="9"
                          />
                          <label className="form-check-label" htmlFor="mycirle4">
                            {" "}
                            {t('typed')}{" "}
                          </label>
                        </div>
                      </div>




                    </div>

                  </div>


                  <div className="row">
                    <div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >


                      <p className="mb-2 mt-3 ">
                        <b>
                          {t('M4chapter02screen08Text2')}
                        </b>
                      </p>
                    </div>
                    <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle123"
                            id="mycirle10"

                            defaultValue="type a"
                            onClick={saveGenderBased}
                            checked={typea10Checked}
                            data-quesId="10"
                          />
                          <label className="form-check-label" htmlFor="namecircle123">
                            {" "}
                            {t('typea')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle1234"
                            id="mycirle101"
                            defaultValue="type b"
                            onClick={saveGenderBased}
                            checked={typeb10Checked}
                            data-quesId="10"
                          />
                          <label className="form-check-label" htmlFor="namecircle1234">
                            {t('typeb')}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle12345"
                            id="mycirle102"
                            defaultValue="type c"
                            onClick={saveGenderBased}
                            checked={typec10Checked}
                            data-quesId="10"
                          />
                          <label className="form-check-label" htmlFor="namecircle12345">
                            {" "}
                            {t('typec')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle123456"
                            id="mycirle103"
                            defaultValue="type d"
                            onClick={saveGenderBased}
                            checked={typed10Checked}
                            data-quesId="10"
                          />
                          <label className="form-check-label" htmlFor="namecircle123456">
                            {" "}
                            {t('typed')}{" "}
                          </label>
                        </div>
                      </div>




                    </div>

                  </div>


                </div>




              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                  <PrevBtn link="/module-04/chapter-02-screen-07" />

                  {/* <NextBtn link="/module-04/chapter-02-screen-03" text="Go Next"/> */}
                  <BtnDisabled />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen08;
