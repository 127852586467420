const BaseURL = 'https://admintcl.genderonline.org/api';
export const AdminUrl = 'https://admintcl.genderonline.org';

// const BaseURL = 'https://todo.studylms.in/repo_admin_tcl/api';
// export const AdminUrl = 'https://todo.studylms.in/repo_admin_tcl';

// const BaseURL = 'https://admintcl.edukrypt.app/api';
// export const AdminUrl = 'https://admintcl.edukrypt.app/';

// const BaseURL = 'http://localhost/admintcl.genderonline.org/api';
// export const AdminUrl = 'http://localhost/admintcl.genderonline.org';


export default BaseURL