import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen11 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3">
								<div
									className="col-lg-7 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h2 mb-1">{t('M1chapter03screen11Title')}</h2>



									<a href="https://www.flickr.com/photos/hragvartanian/8561562900/in/photostream/" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M1chapter03screen11Text1')}</p>
											</div>
										</div>
									</a>

								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="85%"
											src="/assets/img/md2/md2-shap-3-11.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-03-screen-10" />

								<NextBtn
									link="/module-01/chapter-04-screen-01"
									text={t('CommonBtnGoNext')}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen11;
