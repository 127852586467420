import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';




const Chapter04Screen09 = () => {

  const navigate = useNavigate();


  const { t, i18n } = useTranslation();

  const [checkedQues2Option1, setCheckedQues2Option1] = useState('');
  const [checkedQues2Option2, setCheckedQues2Option2] = useState('');
  const [checkedQues2Option3, setCheckedQues2Option3] = useState('');
  const [checkedQues2Option4, setCheckedQues2Option4] = useState('');

  const [checkedQues2Val, setCheckedQues2Val] = useState('');

  const [checkedQues3Option1, setCheckedQues3Option1] = useState('');
  const [checkedQues3Option2, setCheckedQues3Option2] = useState('');
  const [checkedQues3Option3, setCheckedQues3Option3] = useState('');
  const [checkedQues3Option4, setCheckedQues3Option4] = useState('');

  const [checkedQues3Val, setCheckedQues3Val] = useState('');



  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    // alert(quesId);
    checkedVal(quesId, e.target.value)
  }

  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": checkedQues2Val,
          "ques_id": "2"
        },
        "ques3": {
          "ans": checkedQues3Val,
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL + '/HumanRightsFinalQuiz',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate('/module-05/chapter-04-screen-10')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues2Val !== '' && checkedQues3Val !== '') {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {t('CommonBtnGoNext')}


                <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param, ans) => {

    if (param === '2') {
      if (ans === 'option1') {
        setCheckedQues2Option1('checked')
        setCheckedQues2Option2('');
        setCheckedQues2Option3('');
        setCheckedQues2Option4('');


      } else if (ans === 'option2') {
        setCheckedQues2Option1('')
        setCheckedQues2Option3('')
        setCheckedQues2Option4('')
        setCheckedQues2Option2('checked');


      } else if (ans === 'option3') {
        setCheckedQues2Option1('')
        setCheckedQues2Option2('')
        setCheckedQues2Option4('')
        setCheckedQues2Option3('checked');


      } else if (ans === 'option4') {
        setCheckedQues2Option1('')
        setCheckedQues2Option2('')
        setCheckedQues2Option3('')
        setCheckedQues2Option4('checked');


      }
      setCheckedQues2Val(ans)
    }


    if (param === '3') {
      if (ans === 'option1') {
        setCheckedQues3Option1('checked')
        setCheckedQues3Option2('');
        setCheckedQues3Option3('');
        setCheckedQues3Option4('');

      } else if (ans === 'option2') {
        setCheckedQues3Option1('')
        setCheckedQues3Option2('checked');
        setCheckedQues3Option3('')
        setCheckedQues3Option4('')

      } else if (ans === 'option3') {
        setCheckedQues3Option1('')
        setCheckedQues3Option2('');
        setCheckedQues3Option3('checked')
        setCheckedQues3Option4('')

      } else if (ans === 'option4') {
        setCheckedQues3Option1('')
        setCheckedQues3Option2('');
        setCheckedQues3Option3('')
        setCheckedQues3Option4('checked')

      }
      setCheckedQues3Val(ans)
    }




  }

  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/HumanRightsFinalQuizResult',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(), result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])





  return (
    <>
      <div className="wrapper md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="3/4" percentage="75" star="36" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">

              <div className="row align-items-top justify-content-between pt-20 mt-2">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >

                  <h4 className="mb-2 mt-3 pn"> {t('M5chapter04Screen9Text1')}</h4>
                  <div className="d-flex ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio" name="namecircle2" id="ns1"
                        defaultValue="option1"
                        onClick={saveRadio}
                        checked={checkedQues2Option1}
                        data-quesId="2"
                      />
                      <label className="form-check-label" htmlFor="ns1">{t('M5chapter04Screen9Text2')}
                      </label>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio" name="namecircle2" id="ns1"
                        defaultValue="option2"
                        onClick={saveRadio}
                        checked={checkedQues2Option2}
                        data-quesId="2"
                      />
                      <label className="form-check-label" htmlFor="ns1">{t('M5chapter04Screen9Text3')}
                      </label>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio" name="namecircle2" id="ns1"
                        defaultValue="option3"
                        onClick={saveRadio}
                        checked={checkedQues2Option3}
                        data-quesId="2"
                      />
                      <label className="form-check-label" htmlFor="ns1">{t('M5chapter04Screen9Text4')}
                      </label>
                    </div>
                  </div>


                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio" name="namecircle2" id="ns21"
                        defaultValue="option4"
                        onClick={saveRadio}
                        checked={checkedQues2Option4}
                        data-quesId="2"
                      />
                      <label className="form-check-label" htmlFor="ns21">{t('M5chapter04Screen9Text5')} </label>
                    </div>
                  </div>




                  <h4 className="mb-2 mt-3 pn"> {t('M5chapter04Screen9Text6')}</h4>
                  <div className="d-flex ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio" name="namecircle12" id="ns51"
                        defaultValue="option1"

                        onClick={saveRadio}
                        checked={checkedQues3Option1}
                        data-quesId="3"

                      />
                      <label className="form-check-label" htmlFor="ns51">{t('M5chapter04Screen9Text7')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio" name="namecircle12" id="ns61"

                        defaultValue="option2"

                        onClick={saveRadio}
                        checked={checkedQues3Option2}
                        data-quesId="3"
                      />
                      <label className="form-check-label" htmlFor="ns61">{t('M5chapter04Screen9Text8')} </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio" name="namecircle12" id="ns31"
                        defaultValue="option3"


                        onClick={saveRadio}
                        checked={checkedQues3Option3}
                        data-quesId="3"
                      />
                      <label className="form-check-label" htmlFor="ns31">{t('M5chapter04Screen9Text9')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="radio" name="namecircle12" id="ns41"
                        defaultValue="option4"


                        onClick={saveRadio}
                        checked={checkedQues3Option4}
                        data-quesId="3"
                      />
                      <label className="form-check-label" htmlFor="ns41">{t('M5chapter04Screen9Text10')}</label>
                    </div>
                  </div>





                </div>


              </div>


            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                  <PrevBtn link="/module-05/chapter-04-screen-08" />

                  {/* <NextBtn link="/module-05/chapter-04-screen-10" text="Go Next"/> */}

                  <BtnDisabled />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter04Screen09;
