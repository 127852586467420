import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import Sidebar from "../../../common/Sidebar";
import { saveViewPages } from '../../../../api/api';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const Module05IntroScreen = () => {
	const { t, i18n } = useTranslation();
				const user_details = JSON.parse(localStorage.getItem('user_details'));
				  const navigate = useNavigate();
				  const handleSavePage = (event) => {


					  try {
						  const key_name = event.target.getAttribute("data-key_name");
						  const key_url = event.target.getAttribute("data-key_url");

						  const newUser = { user_id: user_details.user_id, key_name: key_name, url: key_url };
						  const createdUser = saveViewPages(newUser);
						  navigate('/module-03/chapter-01-screen-02');
					  } catch (error) {
						  console.error("Error creating user:", error);
					  }
				  };
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="0" star="0" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between pt-20 mt-4">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h4>
										{" "}
										<b> {t('M3hapter01IntroScreenText1')}</b>
									</h4>
									<div className="para-color-box mt-2">

										<p className="mb-2 mt-3">{t('M3hapter01IntroScreenText2')} </p>
										<p className="mb-2 mt-3">{t('M3hapter01IntroScreenText3')}</p>
										<p className="mb-2 mt-3">{t('M3hapter01IntroScreenText4')} </p>
									</div>
								</div>
								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="60%"
											draggable="false"
											src="/assets/img/chapter1/reading-book.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.3s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-03/module-03-title" />

									<NextBtn link="/module-03/chapter-01-screen-01" text={t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Module05IntroScreen;
