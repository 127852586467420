import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

import { useNavigate } from "react-router-dom";
const Chapter02Screen04N4 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	// const [checkedQues2Yes, setCheckedQues2Yes] = useState('');
	// const [checkedQues2No, setCheckedQues2No] = useState('');
	// const [checkedQues2Val, setCheckedQues2Val] = useState('');
	const [checkedQues17, setCheckedQues17] = useState('');

	const [checkedQues18, setCheckedQues18] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));




	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques17": {
					"ans": checkedQues17,

					"ques_id": "17"
				},
				"ques18": {
					"ans": checkedQues18,
					"ques_id": "18"
				}


			}

		});


		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-02-screen-04n5')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans) => {

		if (param === '17') {

			setCheckedQues17(ans)
		}

        if (param === '18') {

			setCheckedQues18(ans)
		}
	}


	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues17 !== '' && checkedQues18 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper page-auto">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="1" star="1" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">

							<div className="row align-items-top justify-content-between pt-20 mt-0">

								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<h3 className="h3 mt-3">{t('reflect')}</h3>
									<p className=""><b> {t('M3chapter02Screen04N4Text1')}</b></p>


									<div className="d-flex">
										<input
											type="text"
											className="form-control"
											placeholder="Type your Answer......."
											onChange={(e) => setCheckedQues17(e.target.value)}
											data-quesId="17"
											value={checkedQues17}
										/>



									</div>

									<p className="mb-0"> <b>{t('M3chapter02Screen04N4Text2')}</b></p>

									<div className="d-flex">

                                        <textarea
                                        className="form-control"
                                        placeholder="Type your Answer......."
                                        onChange={(e) => setCheckedQues18(e.target.value)}
                                        data-quesId="18"
                                        value={checkedQues18}
                                        ></textarea>




									</div>



								</div>
								<div
									className="col-lg-4 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="90%"
											className="mb-2"
											draggable="false"
											src="/assets/img/md1/md1-shap-2.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-03/chapter-02-screen-04n3" />
								{/* <NextBtn link="/module-01/chapter-01-screen-03" text="Go Next" classtype="" /> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Chapter02Screen04N4;
