import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter02Screen02 = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
            <Header leftChapter="1/4" percentage="43" star="50" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20 mt-2 mt-m-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >


                  <h2 className="h2">{t('learn')} </h2>
                  <p className="mb-2 mt-2 text-center">{t('M6hapter02screen04Text1')} </p>
                  {/* <p className="mb-2 mt-2 red"> <i>Design Instruction: This section needs to be designed in an interactive way</i></p> */}

                  <div className="row align-items-center justify-content-center">
                    <div className="col-lg-12  ">
                      <div className="para-color-box p-4">
                        <p className="mb-2 mt-2"> <b>{t('M6hapter02screen04Text2')} </b></p>
                        <ul class=" without-list list-circle">
                          <li><i>{t('M6hapter02screen04Text3')}</i> </li>
                          <li><i>{t('M6hapter02screen04Text4')}</i> </li>
                          <li><i>{t('M6hapter02screen04Text5')}</i> </li>
                          <li><i>{t('M6hapter02screen04Text6')}</i> </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* <div className="row align-items-center justify-content-center mt-2">
                    <div className="col-lg-6  ">
                      <div className="para-color-box p-4">
                        <p className="mb-2 mt-2"> <b>{t('M6hapter02screen04Text6')} </b></p>
                        <ul class="without-list list-circle ">
                          <li>{t('M6hapter02screen04Text7')}</li>
                          <li>{t('M6hapter02screen04Text8')}</li>
                          <li>{t('M6hapter02screen04Text9')} </li>
                        </ul>
                      </div>
                    </div>

                    <div className="col-lg-6  ">
                      <div className="para-color-box p-4">
                        <p className="mb-2 mt-2"> <b>{t('M6hapter02screen04Text10')} </b></p>
                        <ul class="without-list list-circle ">
                          <li>{t('M6hapter02screen04Text11')}</li>
                          <li>{t('M6hapter02screen04Text12')}</li>
                          <li>{t('M6hapter02screen04Text13')}</li>
                        </ul>
                      </div>
                    </div>
                  </div> */}




                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">

                <PrevBtn link="/module-06/chapter-02-screen-03" />
                <NextBtn link="/module-06/chapter-02-screen-05" text={t('CommonBtnGoNext')} />


              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen02;
