import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';
import { saveViewPages } from '../../../../api/api';
import { useNavigate } from "react-router-dom";

const Chapter04Screen01 = () => {
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
		const user_details = JSON.parse(localStorage.getItem('user_details'));
		
		const handleSavePage = (event) => {
		   
				  try {
					  const key_name = event.target.getAttribute("data-key_name");
					  const key_url = event.target.getAttribute("data-key_url");
		   
					  const newUser = { user_id: user_details.user_id, key_name: key_name, url: key_url };
					  const createdUser = saveViewPages(newUser);
					  navigate('/module-01/chapter-04-screen-02');
				  } catch (error) {
					  console.error("Error creating user:", error);
				  }
			  };

	return (
		<div className="wrapper bg-bd-2">
			<div
				className="fixed-header"
				data-wow-duration="1s"
				data-wow-delay="0.1s"
			>
				<div className="container-fluid cf-set">
					<Header leftChapter="3/4" percentage="55" star="55" />
				</div>
			</div>
			<div className="d-flex">
				<Sidebar />
			
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-center justify-content-center pt-20 mt-4">
							<div className="col-lg-9">
								<h1
									className="what-gender m-heading wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									{t('M1chapter04screen01Title')}
								</h1>
								<PrevBtn link="/module-01/chapter-03-screen-11" />
								{/* <NextBtn link="/module-01/chapter-04-screen-02" text=" Let’s Start" classType="mt-5 mt-m-3 wow fadeInDown" /> */}

								<button className="button_su_inner" onClick={handleSavePage} data-key_name="M1C4" data-key_url="//module-01/chapter-04-screen-01" >{t('CommonBtnGoNext')}</button>
							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="73%"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	);
};

export default Chapter04Screen01;
