import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen35 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper page-auto bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-0">
								<div
									className="col-lg-9 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3">{t('additional_resources')}: </h3>

									<ul className="list-style-set list-circle hand-cursor mt-3 mb-3">

										<li data-bs-toggle="modal" data-bs-target="#staticBackdrop">	{t('M3chapter04Screen35Text1')} </li>
										<li data-bs-toggle="modal" data-bs-target="#staticBackdrop2">	{t('M3chapter04Screen35Text2')} </li>
										<li data-bs-toggle="modal" data-bs-target="#staticBackdrop3">	{t('M3chapter04Screen35Text3')}  </li>
										{/* <li data-bs-toggle="modal" data-bs-target="#staticBackdrop4">	  Safer sex  </li> */}

										<li>
										{/* {t('M3chapter04Screen35Text4')} */}

											<a
												href="http://agentsofishq.com/aoi-tutions-with-bitti-sex-pregnancy-and-abortion-mybodymychoice/"
												target="_blank"
												rel="noreferrer"
											>
												<div className="d-flex align-items-center mange-icon mt-2">
													<div>
														<img src="/assets/img/chapter1/list-icon.png" alt="" />
													</div>
													<div>
														<p>{t('M3chapter04Screen35Text5')}</p>
													</div>
												</div>
											</a>

											<a
												href="https://hindi.feminisminindia.com/2021/05/31/video-on-women-and-sexually-transmitted-diseases-hindi/"
												target="_blank"
												rel="noreferrer"
											>
												<div className="d-flex align-items-center mange-icon mt-2">
													<div>
														<img src="/assets/img/chapter1/list-icon.png" alt="" />
													</div>
													<div>
														<p>{t('M3chapter04Screen35Text6')}</p>
													</div>
												</div>
											</a>

											<a
												href="http://agentsofishq.com/hi/the-stages-of-consent/"
												target="_blank"
												rel="noreferrer"
											>
												<div className="d-flex align-items-center mange-icon mt-2">
													<div>
														<img src="/assets/img/chapter1/list-icon.png" alt="" />
													</div>
													<div>
														<p>{t('M3chapter04Screen35Text7')}</p>
													</div>
												</div>
											</a>
										</li>

										<li>
										<a
												href="http://agentsofishq.com/aoi-tutions-with-bitti-sex-pregnancy-and-abortion-mybodymychoice/
https://hindi.feminisminindia.com/2021/05/31/video-on-women-and-sexually-transmitted-diseases-hindi/"
												target="_blank"
												rel="noreferrer"
											>{t('M3chapter04Screen35Text8')}</a>


										</li>


									</ul>

								</div>

								<div
									className="col-lg-2 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="90%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-2-15.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-34n2" />
								<NextBtn link="/module-03/chapter-04-screen-44" text={t('CommonBtnGoNext')} />



							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							{/* <p className="text-white text-center">We often associate sexuality only with our bodies and sexual relationships. In reality, sexuality is a broad concept that includes many aspects of our lives. Let’s get into this!</p> */}

							<h3 className="h3 text-white">{t('M3chapter04Screen35Model1Text1')}  </h3>
							<p className="text-white">{t('M3chapter04Screen35Model1Text2')} </p>

							<ul className="list-style-set list-circle  text-white">
								<li  dangerouslySetInnerHTML={{ __html: t('M3chapter04Screen35Model1Text3') }} />
								<li  dangerouslySetInnerHTML={{ __html: t('M3chapter04Screen35Model1Text4')}} />
								<li  dangerouslySetInnerHTML={{ __html: t('M3chapter04Screen35Model1Text5')}} />
								<li  dangerouslySetInnerHTML={{ __html: t('M3chapter04Screen35Model1Text6')}} />
								<li  dangerouslySetInnerHTML={{ __html: t('M3chapter04Screen35Model1Text7')}} />
								<li  dangerouslySetInnerHTML={{ __html: t('M3chapter04Screen35Model1Text8')}} />


								<li  dangerouslySetInnerHTML={{ __html: t('M3chapter04Screen35Model1Text9')}} />
								<li  dangerouslySetInnerHTML={{ __html: t('M3chapter04Screen35Model1Text10')}} />
								<li  dangerouslySetInnerHTML={{ __html: t('M3chapter04Screen35Model1Text11')}} />
								<li  dangerouslySetInnerHTML={{ __html: t('M3chapter04Screen35Model1Text12')}} />

								<li  dangerouslySetInnerHTML={{ __html: t('M3chapter04Screen35Model1Text13')}} />



							</ul>

							<p className="text-white">{t('M3chapter04Screen35Model1Text14')}</p>
							<p className="text-white"><span dangerouslySetInnerHTML={{ __html:t('M3chapter04Screen35Model1Text15')}}></span> </p>

							<p className="text-white"><span dangerouslySetInnerHTML={{ __html:t('M3chapter04Screen35Model1Text16')}}></span> </p>
							<p className="text-white"><span dangerouslySetInnerHTML={{ __html:t('M3chapter04Screen35Model1Text17')}}></span>  </p>




						</div>
					</div>
				</div>
			</div>

			{/* 2 */}

			<div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							{/* <p className="text-white text-center">We often associate sexuality only with our bodies and sexual relationships. In reality, sexuality is a broad concept that includes many aspects of our lives. Let’s get into this!</p> */}

							<h3 className="h3 text-white">{t('M3chapter04Screen35Model2Text1')} </h3>
							<p className="mb-2 mt-3 text-white">{t('M3chapter04Screen35Model2Text2')} </p>
							<p className="mb-2 mt-3 text-white">{t('M3chapter04Screen35Model2Text3')}  </p>

							<ul className="list-style-set list-circle  mt-3 mb-3 text-white">
								<li>	{t('M3chapter04Screen35Model2Text4')}  </li>
								<li>	{t('M3chapter04Screen35Model2Text5')}  </li>
								<li>	{t('M3chapter04Screen35Model2Text6')} </li>
								<li>	{t('M3chapter04Screen35Model2Text7')} </li>
								<li>	{t('M3chapter04Screen35Model2Text8')} </li>
								<li>	{t('M3chapter04Screen35Model2Text9')} </li>

								<li>	{t('M3chapter04Screen35Model2Text10')}  </li>
								<li>	{t('M3chapter04Screen35Model2Text11')}  </li>
								<li>	{t('M3chapter04Screen35Model2Text12')}  </li>






							</ul>

							<p className="mb-2 mt-3 text-white">{t('M3chapter04Screen35Model2Text13')} </p>

						</div>
					</div>
				</div>
			</div>

			{/* 3 */}

			<div className="modal fade" id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							{/* <p className="text-white text-center">We often associate sexuality only with our bodies and sexual relationships. In reality, sexuality is a broad concept that includes many aspects of our lives. Let’s get into this!</p> */}

							<h3 className="h3 text-white">{t('M3chapter04Screen35Model3Text1')}</h3>
							<p className="mb-2 mt-3 text-white">{t('M3chapter04Screen35Model3Text2')} </p>
							<p className="mb-2 mt-3 text-white">{t('M3chapter04Screen35Model3Text3')}  </p>

							<h3 className="h3 text-white">{t('M3chapter04Screen35Model3Text4')}  </h3>


							<ul className="list-style-set list-circle  mt-3 mb-3 text-white">
								<li>	{t('M3chapter04Screen35Model3Text5')} </li>
								<li>	{t('M3chapter04Screen35Model3Text6')} </li>
								<li>	{t('M3chapter04Screen35Model3Text7')} </li>
								<li>	{t('M3chapter04Screen35Model3Text8')}</li>
							</ul>

							<p className="mb-2 mt-3 text-white">{t('M3chapter04Screen35Model3Text9')}  </p>
							<p className="mb-2 mt-3 text-white"><span dangerouslySetInnerHTML={{ __html:t('M3chapter04Screen35Model3Text10')}}></span>  </p>
							<p className="mb-2 mt-3 text-white"> <span dangerouslySetInnerHTML={{ __html:t('M3chapter04Screen35Model3Text11')}}></span> </p>



						</div>
					</div>
				</div>
			</div>

			{/* 4 */}

			<div className="modal fade" id="staticBackdrop4" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							<p className="text-white text-center">{t('M3chapter04Screen35Model3Text12')}</p>


						</div>
					</div>
				</div>
			</div>

			{/* 5 */}

			<div className="modal fade" id="staticBackdrop5" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							<p className="text-white text-center">{t('M3chapter04Screen35Model3Text13')}</p>


						</div>
					</div>
				</div>
			</div>






		</>
	);
};

export default Chapter04Screen35;
