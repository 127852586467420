import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen12 = () => {

	 const { t, i18n } = useTranslation();

	return (
		<>
			<div className="wrapper md-5-bg hp">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="36" />
					</div>
				</div>
				<div className="d-flex">
				<Sidebar/>

				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3 mt-m-3">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h4 className="mb-2 h3 mt-3"> <b> {t('additional_resources')} </b></h4>
							</div>
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>



								<a
									href="https://drive.google.com/file/d/1w7yo6ljyS0pnvEO-BOrE7Aohmaa9n5Jf/view"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>{t('M5chapter03Screen12Text1')}</p>
										</div>
									</div>
								</a>



								<a
									href="https://www.youtube.com/watch?v=ViDtnfQ9FHc"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>{t('M5chapter03Screen12Text2')}</p>
										</div>
									</div>
								</a>

								<a
									href="https://www.youtube.com/watch?v=z0hiZwWpWnA&t=183s"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>{t('M5chapter03Screen12Text3')} </p>
										</div>
									</div>
								</a>
								<a
									href="https://feminisminindia.com/2020/07/28/video-intersectional-feminism-101/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-3">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>{t('M5chapter03Screen12Text4')}</p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2022/03/01/writing-with-fire-khabar-lahariya-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>{t('M5chapter03Screen12Text5')} </p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2022/01/25/marriage-strike-a-trend-by-indian-men-in-support-of-marital-rape-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>{t('M5chapter03Screen12Text6')}</p>
										</div>
									</div>
								</a>


							</div>
							<div
								className="col-lg-6 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<a
									href="https://hindi.feminisminindia.com/2022/01/07/bully-bai-step-to-oppress-muslim-women-voice-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>{t('M5chapter03Screen12Text7')}</p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2021/12/24/five-indian-trans-women-who-made-headlines-in2021-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon ">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>{t('M5chapter03Screen12Text8')}</p>
										</div>
									</div>
								</a>

								<a
									href="https://www.womankind.org.uk/intersectionality-101-what-is-it-and-why-is-it-important/#:~:text=Intersectionality%20is%20the%20acknowledgement%20that,orientation%2C%20physical%20ability%2C%20etc"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>{t('M5chapter03Screen12Text9')} </p>
										</div>
									</div>
								</a>

								<a
									href="https://achievebrowncounty.org/2021/05/defining-equity-equality-and-justice/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>{t('M5chapter03Screen12Text10')}</p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2021/12/03/world-disability-day-disabled-children-education-has-many-barriers-in-india-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>{t('M5chapter03Screen12Text11')}</p>
										</div>
									</div>
								</a>

								<a
									href="https://hindi.feminisminindia.com/2021/12/13/consent-sex-and-privacy-hindi/"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon mt-2">
										<div>
											<img src="/assets/img/chapter1/list-icon.png" alt="" />
										</div>
										<div>
											<p>{t('M5chapter03Screen12Text12')}</p>
										</div>
									</div>
								</a>



							</div>


							{/* <div
                className="col-lg-4 mt-m-3 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="45%"
                    draggable="false"
                    src="/assets/img/md3/md3-shap-1-10.png"
                    alt=""
                  />
                </center>
              </div> */}




						</div>
					</div>
				</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-05/chapter-03-screen-11n" />
									<NextBtn link="/module-05/chapter-04-screen-01" text= {t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen12;
