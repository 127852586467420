import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify'


const Chapter04Screen08 = () => {

  const navigate = useNavigate();


  const [checkedQues1Option1, setCheckedQues1Option1] = useState('');
  const [checkedQues1Option2, setCheckedQues1Option2] = useState('');
  const [checkedQues1Option3, setCheckedQues1Option3] = useState('');
  const [checkedQues1Option4, setCheckedQues1Option4] = useState('');

  const [checkedQues1Val, setCheckedQues1Val] = useState('');


  const { t, i18n } = useTranslation();



  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    // alert(quesId);
    checkedVal(quesId,e.target.value)
  }

  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans":  checkedQues1Val,
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans":"",
          "ques_id": "6"
        },
        "ques7": {
          "ans":"",
          "ques_id": "7"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/HumanRightsFinalQuiz',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-05/chapter-04-screen-09')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues1Val !== '') {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')}
  <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {
    if (param === '1') {
      if (ans === 'option1') {
        setCheckedQues1Option1('checked')
        setCheckedQues1Option2('');
        setCheckedQues1Option3('');
        setCheckedQues1Option4('');

      } else if (ans === 'option2') {
        setCheckedQues1Option1('')
        setCheckedQues1Option2('checked');
        setCheckedQues1Option3('')
        setCheckedQues1Option4('')

        }else if (ans === 'option3') {
          setCheckedQues1Option1('')
          setCheckedQues1Option2('');
          setCheckedQues1Option3('checked')
          setCheckedQues1Option4('')

          }else if (ans === 'option4') {
            setCheckedQues1Option1('')
            setCheckedQues1Option2('');
            setCheckedQues1Option3('')
            setCheckedQues1Option4('checked')

            }
        setCheckedQues1Val(ans)
    }




  }

  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/HumanRightsFinalQuizResult',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])




  return (
    <>
      <div className="wrapper md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="3/4" percentage="75" star="36" />
          </div>
        </div>
        <div className="d-flex">
  <Sidebar/>

        <div className="data-adjust">
          <div className="container-fluid cf-set">

          <div className="row align-items-top justify-content-between pt-20 mt-2">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h4 className="mb-3 h3 mt-3"> <b>   { t('M5chapter04Screen8Text1') }  </b></h4>

                <h4 className="mb-2 mt-3 pn"> {t('M5chapter04Screen8Text2')}</h4>
                <div className="d-flex ">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio" name="namecircle" id="ns"
                      defaultValue="option1"
                        onClick={saveRadio}
                         checked={checkedQues1Option1}
                         data-quesId="1"
                     />
                    <label className="form-check-label" htmlFor="ns">{t('M5chapter04Screen8Text3')}</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio" name="namecircle" id="ns2"
                      defaultValue="option2"
                        onClick={saveRadio}
                         checked={checkedQues1Option2}
                         data-quesId="1"
                     />
                    <label className="form-check-label" htmlFor="ns2">{t('M5chapter04Screen8Text4')}</label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio" name="namecircle" id="ns3"
                     defaultValue="option3"
                    onClick={saveRadio}
                         checked={checkedQues1Option3}
                         data-quesId="1"
                     />
                    <label className="form-check-label" htmlFor="ns3">{t('M5chapter04Screen8Text5')} </label>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input custom-check" type="radio" name="namecircle" id="ns4"
                     defaultValue="option4"
                    onClick={saveRadio}
                         checked={checkedQues1Option4}
                         data-quesId="1"
                     />
                    <label className="form-check-label" htmlFor="ns4">{t('M5chapter04Screen8Text6')} </label>
                  </div>
                </div>




              </div>


            </div>


          </div>
        </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-05/chapter-04-screen-07"/>
                {/* <NextBtn link="/module-05/chapter-04-screen-09" text="Go Next"/> */}
                <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter04Screen08;
