import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter02Screen02 = () => {

	const navigate = useNavigate();
	const [checkedQues1, setCheckedQues1] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const { t, i18n } = useTranslation();



	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues1,
					"ans2": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				}



			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/ApproachChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-06/chapter-04-screen-03')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans, ans2) => {


		if (param === '1') {

			setCheckedQues1(ans)

		}






	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/approachChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans, result.ans2)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues1 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper bg-bd-2 hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3 mt-m-3">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>


									<h2 className="h2 mt-3">{t('reflect')}</h2>
									<h3 className="h3 mt-3">{t('M6chapter04screen02Text1')}</h3>

									<p className="mb-2 mt-3"> {t('M6chapter04screen02Text2')}</p>
									<textarea className="form-control" defaultValue={checkedQues1} onChange={(e) => setCheckedQues1(e.target.value)} rows={2} placeholder="Write Something here....." />
									<h2 className="h2 mt-3">{t('learn')}</h2>
									<p className="mb-2 mt-3"> {t('M6chapter04screen02Text3')} </p>
									<ul className="list-style-set list-circle mt-2">
										<li>{t('M6chapter04screen02Text4')}</li>
										<li>{t('M6chapter04screen02Text5')}</li>
										<li>{t('M6chapter04screen02Text6')}</li>
										<li>{t('M6chapter04screen02Text7')}</li>
									</ul>

									<p className="mb-2 mt-3">{t('M6chapter04screen02Text8')}<br /><a className="display-inline" href="https://inbreakthrough.org/focus-area/youth-programme/" target="_blank">{t('M6chapter04screen02Text9')}</a> </p>

								</div>

								<div className="col-lg-3 mt-m-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">
									<center>
										<img
											width="50%"
											src="/assets/img/md6/md-60chap-2-3.png"
											alt=""
										/>
									</center>
								</div>


							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-06/chapter-04-screen-01" />
								{/* <NextBtn link="/module-06/chapter-04-screen-03" text="Go Next"/> */}
								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
