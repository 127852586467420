import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter01Screen06 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [checkedQues4, setCheckedQues4] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": checkedQues4,
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				}

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter01',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-02/chapter-01-screen-06')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter01Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const checkedVal = (param, ans) => {
		if (param === '4') {
			setCheckedQues4(ans)
		}




	}


	const BtnDisabled = () => {
		if (checkedQues4 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper overscroll md-5-bg">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/4" percentage="5" star="4" />
					</div>
				</div>
				<div className="d-flex">
          			<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">

							<div className="row align-items-top justify-content-center pt-20 mt-3 mb-5">
								<div className="col-lg-8 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.1s">

									<p>{t('M5chapter01Screen06Text1')} </p>

									<p>{t('M5chapter01Screen06Text2')}</p>
									<h2 className="h4"> <b>{t('M5chapter01Screen06Text3')}</b></h2>



									<a href="https://www.un.org/en/about-us/universal-declaration-of-human-rights" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M5chapter01Screen06Text4')}</p>
											</div>
										</div>
									</a>

									<ul className="without-list list-number ml-1 mt-3">
										<li>{t('M5chapter01Screen06Text5')}</li>
										<li>{t('M5chapter01Screen06Text6')}</li>
										<li>{t('M5chapter01Screen06Text7')}</li>
										<li>{t('M5chapter01Screen06Text8')} </li>
										<li>{t('M5chapter01Screen06Text9')}</li>
										<li>{t('M5chapter01Screen06Text10')} </li>
										<li>{t('M5chapter01Screen06Text11')} </li>
										<li>{t('M5chapter01Screen06Text12')}</li>
										<li>{t('M5chapter01Screen06Text13')}  </li>
										<li>{t('M5chapter01Screen06Text14')}</li>
										<li>{t('M5chapter01Screen06Text15')}</li>
										<li>{t('M5chapter01Screen06Text16')}</li>



										<li>{t('M5chapter01Screen06Text17')}</li>
										<li>{t('M5chapter01Screen06Text18')}</li>
										<li>{t('M5chapter01Screen06Text19')}</li>
										<li>{t('M5chapter01Screen06Text20')}</li>
										<li>{t('M5chapter01Screen06Text21')}</li>
										<li>{t('M5chapter01Screen06Text22')} </li>
										<li>{t('M5chapter01Screen06Text23')}</li>
										<li>{t('M5chapter01Screen06Text24')}</li>
										<li>{t('M5chapter01Screen06Text25')}</li>
										<li>{t('M5chapter01Screen06Text26')} </li>
										<li>{t('M5chapter01Screen06Text27')}</li>
										<li>{t('M5chapter01Screen06Text28')}</li>
										<li>{t('M5chapter01Screen06Text29')}</li>
										<li>{t('M5chapter01Screen06Text30')}</li>
										<li>{t('M5chapter01Screen06Text31')}</li>
										<li>{t('M5chapter01Screen06Text32')}</li>
										<li>{t('M5chapter01Screen06Text33')}</li>
										<li>{t('M5chapter01Screen06Text34')}</li>
									</ul>
								</div>
								<div className="col-lg-4 mt-m-3 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
									<center>
										<img width="100%" src="/assets/img/md3/md3-shap-3-6.png" alt="" />
									</center>
								</div>
							</div>

						</div>
					</div>
				</div>
			</div>


			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-05/chapter-01-screen-04" />

								<NextBtn link="/module-05/chapter-01-screen-08" text={t('CommonBtnGoNext')} />
								{/* <BtnDisabled/> */}

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen06;
