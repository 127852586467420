import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter04Screen11 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="80" star="58" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-5">
								<div
									className="col-lg-7 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.1s"
								>
									<h2 className="h2 mb-1">{t('additional_resources')}</h2>


									<a
										href="https://inbreakthrough.org/redraw-misogyny-change-the-narrative/"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M2chapter04screen11Text1')} </p>
											</div>
										</div>
									</a>
									<a
										href="https://hindi.feminisminindia.com/2021/09/01/matrimonial-ads-and-patriarchy-hindi/"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M2chapter04screen11Text2')}</p>
											</div>
										</div>
									</a>

									<a
										href="https://hindi.feminisminindia.com/2021/04/15/the-ideal-woman-of-indian-cinema-hindi/"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M2chapter04screen11Text3')} </p>
											</div>
										</div>
									</a>


									<a
										href="https://www.youtube.com/watch?v=Id03w_R6toE&list=RDCMUCwDhnERqftGr2agaAeN8zRg&index=8"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M2chapter04screen11Text4')} </p>
											</div>
										</div>
									</a>





								</div>
								<div
									className="col-lg-5 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<iframe
										width="100%"
										height="315"
										src="https://www.youtube.com/embed/Id03w_R6toE"
										title="YouTube video player"
										frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
									></iframe>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-04-screen-10" />
								<NextBtn
									link="/module-02/chapter-05-screen-01"
									text={t('CommonBtnGoNext')}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen11;
