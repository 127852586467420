import React, { useState, useEffect } from "react";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Header from "../../../header/Header";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter04Screen34N2 = () => {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const [checkedQues52_1, setCheckedQues52_1] = useState('');
    const [checkedQues52_2, setCheckedQues52_2] = useState('');
    const [checkedQues53_1, setCheckedQues53_1] = useState('');
    const [checkedQues53_2, setCheckedQues53_2] = useState('');


    const user_details = JSON.parse(localStorage.getItem('user_details'));


    const saveAns = () => {

        var data = JSON.stringify({
            "user_id": user_details.user_id,
            "answers": {

                "ques52": {
                    "ans": checkedQues52_1 + "," + checkedQues52_1 ,
                    "ques_id": "52"
                },

                "ques53": {
                    "ans": checkedQues53_1 + "," + checkedQues53_1 ,
                    "ques_id": "53"
                },


            }
        });
        var config = {
            method: 'post',
            url: BaseURL + '/SexualityChapter04',
            headers: {
                'Authorization': `Bearer ${user_details.token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                if (response.data.success === true) {
                    navigate('/module-03/chapter-04-screen-35')
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        var data = JSON.stringify({
            "user_id": user_details.user_id,
            "ques_id": "52"
        });
        var config = {
            method: 'post',
            url: BaseURL + '/SexualityChapter04Result',
            headers: {
                'Authorization': `Bearer ${user_details.token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                if (response.data.success === true) {
                    const results = response.data.data.chapter.ans;

                    const res = results.split(",");
                    res.map((elem,i)=>{
                        if (i === 0) {
                            setCheckedQues52_1(elem)
                        }
                        if (i === 1) {
                            setCheckedQues52_2(elem)
                        }
                    })



                    // console.log(res);

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    useEffect(() => {
        var data = JSON.stringify({
            "user_id": user_details.user_id,
            "ques_id": "53"
        });
        var config = {
            method: 'post',
            url: BaseURL + '/SexualityChapter04Result',
            headers: {
                'Authorization': `Bearer ${user_details.token}`,
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                if (response.data.success === true) {
                    const results1 = response.data.data.chapter.ans;
                    console.log(results1)

                    const res1 = results1.split(",");

                    res1.map((elem,i)=>{
                        if (i === 0) {
                            setCheckedQues53_1(elem)
                        }
                        if (i === 1) {
                            setCheckedQues53_2(elem)
                        }
                    })

                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])


    const BtnDisabled = () => {
        if (checkedQues52_1 !== '' ) {
            return (
                <>
                    <button
                        className={`button_su`}
                        data-wow-duration="1s"
                        data-wow-delay="0.2s"
                    >
                        <span className="su_button_circle" />
                        <button onClick={saveAns} className="button_su_inner">
                            <span className="button_text_container">
                            {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
                            </span>
                        </button>
                    </button>
                </>
            );
        } else {
            return (
                <>
                    <button className="button_su disabled-btn" type="button" disabled="">
                        <span className="su_button_circle"></span>
                        <span className="button_su_inner">
                            <span className="button_text_container">
                                {" "}
                                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
                            </span>{" "}
                        </span>
                    </button>
                </>
            );
        }
    };



    return (
        <>
            <div className="wrapper">
                <div
                    className="fixed-header"

                >
                    <div className="container-fluid cf-set">
                        <Header leftChapter="3/5" percentage="66" star="42" />
                    </div>
                </div>
                <div className="d-flex">
                    <Sidebar />
                    <div className="data-adjust">
                        <div className="container-fluid cf-set">
                            <div className="row align-items-top justify-content-between pt-20 mt-3">

                                <div
                                    className="col-lg-8 wow fadeInDown"
                                    data-wow-duration="1s"
                                    data-wow-delay="0.2s"
                                >


                                    <h2 className="h2">{t('reflect')}</h2>
                                    <p className="">
                                    {t('M3chapter04Screen34N2Text1')}
                                    </p>
                                    <ul >
                                        <li>
                                        {t('M3chapter04Screen34N2Text2')}

                                        </li>
                                        <li>
                                        {t('M3chapter04Screen34N2Text3')}{" "}

                                        </li>
                                        <li>
                                        {t('M3chapter04Screen34N2Text4')}{" "}

                                        </li>
                                        <li>

                                            <input
                                                type="text"
                                                placeholder=""
                                                className="input-without-style form-control"
                                                onChange={(e) => { setCheckedQues52_1(e.target.value) }}
                                                defaultValue={checkedQues52_1}
                                            />
                                        </li>
                                        <li>

                                            <input
                                                type="text"
                                                placeholder=""
                                                className="input-without-style form-control"
                                                onChange={(e) => { setCheckedQues52_2(e.target.value) }}
                                                defaultValue={checkedQues52_2}
                                            />
                                        </li>
                                    </ul>


                                    <p className="">
                                    {t('M3chapter04Screen34N2Text5')}
                                    </p>
                                    <ul >

                                        <li>

                                            <input
                                                type="text"
                                                placeholder=""
                                                className="input-without-style form-control"
                                                onChange={(e) => { setCheckedQues53_1(e.target.value) }}
                                                defaultValue={checkedQues53_1}
                                            />
                                        </li>
                                        <li>

                                            <input
                                                type="text"
                                                placeholder=""
                                                className="input-without-style form-control"
                                                onChange={(e) => { setCheckedQues53_2(e.target.value) }}
                                                defaultValue={checkedQues53_2}
                                            />
                                        </li>
                                    </ul>

                                </div>
                                <div
                                    className="col-lg-4 wow fadeInDown"
                                    data-wow-duration="1s"
                                    data-wow-delay="0.2s"
                                >
                                    <center>
                                        <img
                                            className="mt-3"
                                            width="70%"
                                            src="/assets/img/md1/md1-shap-4-4.png"
                                            alt=""
                                        />
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="button-fixed wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
            >
                <div className="container-fluid cf-set">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className=" d-flex justify-content-end">
                                <PrevBtn link="/module-03/chapter-04-screen-34n1" />
                                <BtnDisabled />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Chapter04Screen34N2;
