import React, { useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen02 = () => {


	const { t, i18n } = useTranslation();
	const [isActive, setIsActive] = useState(false);
	const [loveActive, setIsLoveActive] = useState(false);
	const [peaceActive, setIsPeaceActive] = useState(false);

	const [freedomActive, setIsFreedomActive] = useState(false);
	const [courageActive, setIsCourageActive] = useState(false);
	const [authenticityActive, setIsAuthenticityActive] = useState(false);

	const [accountabilityActive, setIsAccountabilityActive] = useState(false);
	const [justiceActive, setIsJusticeActive] = useState(false);
	const [gratitudeActive, setIsGratitudeActive] = useState(false);
	const [forgivenessActive, setIsForgivenessActive] = useState(false);
	const [compassionActive, setIsCompassionActive] = useState(false);
	const [commitmentActive, setIsCommitmentActive] = useState(false);
	const [creativityActive, setIsCreativityActive] = useState(false);
	const [integrityActive, setIsIntegrityActive] = useState(false);
	const [equalityActive, setIsEqualityActive] = useState(false);
	const [dignityActive, setIsDignityActive] = useState(false);
	const [inclusionActive, setIsInclusionActive] = useState(false);

	const [trustActive, setIsTrustActive] = useState(false);
	const [joyActive, setIsJoyActive] = useState(false);
	const [diversityActive, setIsDiversityActive] = useState(false);

	const [respectActive, setIsRespectActive] = useState(false);
	const [patienceActive, setIsPatienceActive] = useState(false);
	const [leadershipActive, setIsLeadershipActive] = useState(false);
	const [wellBeingActive, setIsWellBeingActive] = useState(false);
	const [truthActive, setIsTruthActive] = useState(false);

	const [wealthActive, setIsWealthActive] = useState(false);
	const [safetyActive, setIsSafetyActive] = useState(false);
	const [successActive, setIsSuccessActive] = useState(false);
	const [kindnessActive, setIsKindnessActive] = useState(false);
	const [faithActive, setIsFaithActive] = useState(false);
	const [leisureActive, setIsLeisureActive] = useState(false);


	const handleClick = (event) => {
		const key_name = event.target.getAttribute("data-key_name");
		if (key_name == "love") {
			setIsLoveActive(!isActive);
		}

		if (key_name == "peace") {

			setIsPeaceActive(!isActive);
		}

		if (key_name == "freedom") {

			setIsFreedomActive(!isActive);
		}

		if (key_name == "courage") {

			setIsCourageActive(!isActive);
		}

		if (key_name == "authenticity") {

			setIsAuthenticityActive(!isActive);
		}

		if (key_name == "accountability") {

			setIsAccountabilityActive(!isActive);
		}
		if (key_name == "justice") {

			setIsJusticeActive(!isActive);
		}
		if (key_name == "gratitude") {

			setIsGratitudeActive(!isActive);
		}
		if (key_name == "forgiveness") {

			setIsForgivenessActive(!isActive);
		}
		if (key_name == "compassion") {

			setIsCompassionActive(!isActive);
		}
		if (key_name == "commitment") {

			setIsCommitmentActive(!isActive);
		}
		if (key_name == "creativity") {

			setIsCreativityActive(!isActive);
		}
		if (key_name == "integrity") {

			setIsIntegrityActive(!isActive);
		}
		if (key_name == "equality") {

			setIsEqualityActive(!isActive);
		}
		if (key_name == "dignity") {

			setIsDignityActive(!isActive);
		}
		if (key_name == "inclusion") {

			setIsInclusionActive(!isActive);
		}
		if (key_name == "trust") {

			setIsTrustActive(!isActive);
		}
		if (key_name == "joy") {

			setIsJoyActive(!isActive);
		}
		if (key_name == "diversity") {

			setIsDiversityActive(!isActive);
		}
		if (key_name == "respect") {

			setIsRespectActive(!isActive);
		}
		if (key_name == "patience") {

			setIsPatienceActive(!isActive);
		}
		if (key_name == "leadership") {

			setIsLeadershipActive(!isActive);
		}
		if (key_name == "wellBeing") {

			setIsWellBeingActive(!isActive);
		}
		if (key_name == "truth") {

			setIsTruthActive(!isActive);
		}
		if (key_name == "wealth") {

			setIsWealthActive(!isActive);
		}
		if (key_name == "safety") {

			setIsSafetyActive(!isActive);
		}
		if (key_name == "success") {

			setIsSuccessActive(!isActive);
		}if (key_name == "kindness") {

			setIsKindnessActive(!isActive);
		}
		if (key_name == "faith") {

			setIsFaithActive(!isActive);
		}
		if (key_name == "leisure") {

			setIsLeisureActive(!isActive);
		}


	};


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="1" star="1" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-5 mt-m-3">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3">{t('engage')}</h3>
									<p className="mb-2 mt-3">{t('M1chapter02screen02Text1')}</p>


									<div className="table">
										<div className="tr">
											<div className={`td ${loveActive ? "circle" : ""}`} data-key_name="love" onClick={handleClick} >{t('love')}</div>
											<div className={`td ${peaceActive ? "circle" : ""}`} data-key_name="peace" onClick={handleClick}>{t('peace')}</div>
											<div className={`td ${freedomActive ? "circle" : ""}`} data-key_name="freedom" onClick={handleClick} >{t('freedom')}</div>
											<div className={`td ${courageActive ? "circle" : ""}`} data-key_name="courage" onClick={handleClick}>{t('courage')}</div>
											<div className={`td ${authenticityActive ? "circle" : ""}`} data-key_name="authenticity" onClick={handleClick} >{t('authenticity')}</div>
										</div>

										<div className="tr">
											<div className={`td ${accountabilityActive ? "circle" : ""}`} data-key_name="accountability" onClick={handleClick}>{t('accountability')}</div>
											<div className={`td ${justiceActive ? "circle" : ""}`} data-key_name="justice" onClick={handleClick}>{t('justice')}</div>
											<div className={`td ${gratitudeActive ? "circle" : ""}`} data-key_name="gratitude" onClick={handleClick}>{t('gratitude')}</div>
											<div className={`td ${forgivenessActive ? "circle" : ""}`} data-key_name="forgiveness" onClick={handleClick}>{t('forgiveness')}</div>
											<div className={`td ${compassionActive ? "circle" : ""}`} data-key_name="compassion" onClick={handleClick}>{t('compassion')}</div>
										</div>

										<div className="tr">
											<div className={`td ${commitmentActive ? "circle" : ""}`} data-key_name="commitment" onClick={handleClick}>{t('commitment')}</div>
											<div className={`td ${creativityActive ? "circle" : ""}`} data-key_name="creativity" onClick={handleClick}>{t('creativity')}</div>
											<div className={`td ${integrityActive ? "circle" : ""}`} data-key_name="integrity" onClick={handleClick}>{t('integrity')}</div>
											<div className={`td ${equalityActive ? "circle" : ""}`} data-key_name="equality" onClick={handleClick}>{t('equality')}</div>
											<div className={`td ${dignityActive ? "circle" : ""}`} data-key_name="dignity" onClick={handleClick}>{t('dignity')}</div>
										</div>

										<div className="tr">
											<div className={`td ${inclusionActive ? "circle" : ""}`} data-key_name="inclusion" onClick={handleClick}>{t('inclusion')}</div>
											<div className={`td ${trustActive ? "circle" : ""}`} data-key_name="trust" onClick={handleClick}>{t('trust')}</div>
											<div className={`td ${joyActive ? "circle" : ""}`} data-key_name="joy" onClick={handleClick}>{t('joy')}</div>
											<div className={`td ${diversityActive ? "circle" : ""}`} data-key_name="diversity" onClick={handleClick}>{t('diversity')}</div>
											<div className={`td ${respectActive ? "circle" : ""}`} data-key_name="respect" onClick={handleClick}>{t('respect')}</div>
										</div>

										<div className="tr">
											<div className={`td ${patienceActive ? "circle" : ""}`} data-key_name="patience" onClick={handleClick}>{t('patience')}</div>
											<div className={`td ${leadershipActive ? "circle" : ""}`} data-key_name="leadership" onClick={handleClick}>{t('leadership')}</div>
											<div className={`td ${wellBeingActive ? "circle" : ""}`} data-key_name="wellBeing" onClick={handleClick}>{t('wellBeing')}</div>
											<div className={`td ${truthActive ? "circle" : ""}`} data-key_name="truth" onClick={handleClick}>{t('truth')}</div>
											<div className={`td ${wealthActive ? "circle" : ""}`} data-key_name="wealth" onClick={handleClick}>{t('wealth')}</div>
										</div>
										<div className="tr">
											<div className={`td ${safetyActive ? "circle" : ""}`} data-key_name="safety" onClick={handleClick}>{t('safety')}</div>
											<div className={`td ${successActive ? "circle" : ""}`} data-key_name="success" onClick={handleClick}>{t('success')}</div>
											<div className={`td ${kindnessActive ? "circle" : ""}`} data-key_name="kindness" onClick={handleClick}>{t('kindness')}</div>
											<div className={`td ${faithActive ? "circle" : ""}`} data-key_name="faith" onClick={handleClick}>{t('faith')}</div>
											<div className={`td ${leisureActive ? "circle" : ""}`} data-key_name="leisure" onClick={handleClick}>{t('leisure')}</div>
										</div>


									</div>



								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="82%"
											src="/assets/img/md2/md2-shap-2-7.png"
											alt=""
										/>
									</center>
								</div>





							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">


								<PrevBtn link="/module-01/chapter-02-screen-01" />
								<NextBtn link="/module-01/chapter-02-screen-02n" text={t('CommonBtnGoNext')} />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen02;
