import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation  } from 'react-i18next';

const Chapter02Screen14 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items- justify-content-between pt-20 mt-3">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3 mt-3">{t('learn')}</h3>
									<p className="mb-2  mt-3">{t('M3chapter02Screen14Text1')}  </p>
									<p className="mb-2  mt-2" >
										<div dangerouslySetInnerHTML={{
        								__html: t('M3chapter02Screen14Text2'),
											}}>
										</div> 
									</p>




									<a href="https://www.britannica.com/topic/human-sexual-activity/Social-and-cultural-aspects " target="_blank">
										<div className="d-flex align-items-center mange-icon mt-2">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M3chapter02Screen14Text3')}  </p>
											</div>
										</div>
									</a>

									<p className="mb-2  mt-3"> {t('M3chapter02Screen14Text4')}  </p>
									<p className="mb-2  mt-3">{t('M3chapter02Screen14Text5')}  </p>



								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="50%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-2-2.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-02-screen-13" />
								<NextBtn link="/module-03/chapter-02-screen-15" text={t('CommonBtnGoNext')} />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen14;
