import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen05 = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();
  const [checkedQues8, setCheckedQues8] = useState('');


  const user_details = JSON.parse(localStorage.getItem('user_details'));




  const saveAns = () => {

    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": checkedQues8,
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": "",
          "ques_id": "10"
        },
        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        },
        "ques13": {
          "ans": "",
          "ques_id": "13"
        },
        "ques14": {
          "ans": "",
          "ques_id": "14"
        }
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/GenderChapter01',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-02/chapter-01-screen-06')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const checkedVal = (param,ans) => {


    if (param === '8') {

      setCheckedQues8(ans)
    }




  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/GenderChapter01Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  const BtnDisabled = () => {

    if (checkedQues8 !== ''  ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div className="wrapper hp">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
            <Header leftChapter="0/5" percentage="4" star="4" />
          </div>
        </div>
        <div className="d-flex">
					<Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-center pt-20 mt-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <h2
                    className="h2 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    {t('M2chapter01screen05Text1')}&nbsp;
                  </h2>
                  <p>
                    {t('M2chapter01screen05Text2')}
                  </p>
                  <div className="mb-3 positon-submit">
                    <textarea
                      className="form-control"
                      rows={4}
                      placeholder="Write Something here....."
                      onChange={(e)=>setCheckedQues8(e.target.value)}
                      data-quesId="8"
                      defaultValue={checkedQues8}
                    />
                    {/* <button
                      type="button"
                      className="btn btn-primary custom-btn m-auto submit-btn-set"
                    >
                      Submit
                    </button> */}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-02/chapter-01-screen-04" />

                {/* <NextBtn link="/module-01/chapter-01-screen-06" text="Go Next" classtype="" /> */}
                <BtnDisabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter01Screen05;
