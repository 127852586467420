import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";

import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';



const Chapter04Screen01 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="36" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-center pt-20 ">
								<div className="col-lg-9">
									<h1
										className="what-gender wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>

										{t('M5chapter04Screen1Text1')}
									</h1>
									<PrevBtn link="/module-05/chapter-03-screen-12" />
									<NextBtn link="/module-05/chapter-04-screen-02" text={t('Letstart')} classType="mt-5 mt-m-3 wow fadeInDown" />
								</div>
								<div
									className="col-lg-3 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="73%"
											src="/assets/img/chapter1/reading-book.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen01;
