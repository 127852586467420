import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { Modal, Button } from "react-bootstrap";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen11 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const [checkedQues7Option1, setCheckedQues7Option1] = useState('');
	const [checkedQues7Option2, setCheckedQues7Option2] = useState('');

	const [checkedQues7Val, setCheckedQues7Val] = useState('');

	const [checkedQues8Option1, setCheckedQues8Option1] = useState('');
	const [checkedQues8Option2, setCheckedQues8Option2] = useState('');

	const [checkedQues8Val, setCheckedQues8Val] = useState('');

	const [checkedQues9Option1, setCheckedQues9Option1] = useState('');
	const [checkedQues9Option2, setCheckedQues9Option2] = useState('');

	const [checkedQues9Val, setCheckedQues9Val] = useState('');

	const [checkedQues10Option1, setCheckedQues10Option1] = useState('');
	const [checkedQues10Option2, setCheckedQues10Option2] = useState('');
	const [checkedQues10Option3, setCheckedQues10Option3] = useState('');

	const [checkedQues10Val, setCheckedQues10Val] = useState('');

	const [checkedQues11Option1, setCheckedQues11Option1] = useState('');
	const [checkedQues11Option2, setCheckedQues11Option2] = useState('');

	const [checkedQues11Val, setCheckedQues11Val] = useState('');

	const [checkedQues12Option1, setCheckedQues12Option1] = useState('');
	const [checkedQues12Option2, setCheckedQues12Option2] = useState('');

	const [checkedQues12Val, setCheckedQues12Val] = useState('');

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showModalText, setShowModalText] = useState();




	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(e.target.value);

		if (quesId === '7') {
			setShowModalText(t('M3chapter04Screen11Text7'))
		}


		if (quesId === '8') {
			setShowModalText(t('M3chapter04Screen11Text8'))
		}

		if (quesId === '9') {
			// setShow(false);
			setShowModalText(t('M3chapter04Screen11Text9'))
		}

		if (quesId === '10') {
			setShowModalText(t('M3chapter04Screen11Text10'))
		}

		if (quesId === '11') {
			setShowModalText(t('M3chapter04Screen11Text11'))
		}

		if (quesId === '12') {
			setShowModalText(t('M3chapter04Screen11Text12'))
		}




		setShow(true);

		checkedVal(quesId, e.target.value)
	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques7": {
					"ans": checkedQues7Val,
					"ques_id": "7"
				},
				"ques8": {
					"ans": checkedQues8Val,
					"ques_id": "8"
				},
				"ques9": {
					"ans": checkedQues9Val,
					"ques_id": "9"
				},
				"ques10": {
					"ans": checkedQues10Val,
					"ques_id": "10"
				},
				"ques11": {
					"ans": checkedQues11Val,
					"ques_id": "11"
				},
				"ques12": {
					"ans": checkedQues12Val,
					"ques_id": "12"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-04-screen-12')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues7Val !== '' && checkedQues8Val !== '' && checkedQues9Val !== '' && checkedQues10Val !== '' && checkedQues11Val !== '' && checkedQues12Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '7') {
			if (ans === 'option1') {
				setCheckedQues7Option1('checked')
				setCheckedQues7Option2('');

			} else if (ans === 'option2') {
				setCheckedQues7Option1('')
				setCheckedQues7Option2('checked');

			}
			setCheckedQues7Val(ans)
		}

		if (param === '8') {
			if (ans === 'option1') {
				setCheckedQues8Option1('checked')
				setCheckedQues8Option2('');

			} else if (ans === 'option2') {
				setCheckedQues8Option1('')
				setCheckedQues8Option2('checked');

			}
			setCheckedQues8Val(ans)
		}

		if (param === '9') {
			if (ans === 'option1') {
				setCheckedQues9Option1('checked')
				setCheckedQues9Option2('');

			} else if (ans === 'option2') {
				setCheckedQues9Option1('')
				setCheckedQues9Option2('checked');

			}
			setCheckedQues9Val(ans)
		}

		if (param === '10') {
			if (ans === 'option1') {
				setCheckedQues10Option1('checked')
				setCheckedQues10Option2('');
				setCheckedQues10Option2('');

			} else if (ans === 'option2') {
				setCheckedQues10Option1('')
				setCheckedQues10Option2('checked');
				setCheckedQues10Option3('');

			} else if (ans === 'option3') {
				setCheckedQues10Option1('')
				setCheckedQues10Option2('');
				setCheckedQues10Option3('checked');

			}
			setCheckedQues10Val(ans)
		}

		if (param === '11') {
			if (ans === 'option1') {
				setCheckedQues11Option1('checked')
				setCheckedQues11Option2('');

			} else if (ans === 'option2') {
				setCheckedQues11Option1('')
				setCheckedQues11Option2('checked');

			}
			setCheckedQues11Val(ans)
		}

		if (param === '12') {
			if (ans === 'option1') {
				setCheckedQues12Option1('checked')
				setCheckedQues12Option2('');

			} else if (ans === 'option2') {
				setCheckedQues12Option1('')
				setCheckedQues12Option2('checked');

			}
			setCheckedQues12Val(ans)
		}








	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-0">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									{/* yahan popup rahega */}

									<p className="mb-2 mt-3 pn"><span><b>1.</b></span> <b>{t('M3chapter04Screen11Text1')}  </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle"
												id="mycirle2"
												defaultValue="option1"

												onClick={saveRadio}
												checked={checkedQues7Option1}
												data-quesId="7"

											/>
											<label className="form-check-label" htmlFor="mycirle2">{t('myth')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle"
												id="mycirle3"
												defaultValue="option2"


												onClick={saveRadio}
												checked={checkedQues7Option2}
												data-quesId="7"
											/>
											<label className="form-check-label" htmlFor="mycirle3">{t('fact')}</label>
										</div>
									</div>

									<p className="mb-2 mt-3 pn"><span><b>2.</b></span> <b>{t('M3chapter04Screen11Text2')} </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle2"
												id="mycirle345"
												defaultValue="option1"

												onClick={saveRadio}
												checked={checkedQues8Option1}
												data-quesId="8"

											/>
											<label className="form-check-label" htmlFor="mycirle345">{t('myth')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle2"
												id="mycirle4"
												defaultValue="option2"

												onClick={saveRadio}
												checked={checkedQues8Option2}
												data-quesId="8"
											/>
											<label className="form-check-label" htmlFor="mycirle4">{t('fact')}</label>
										</div>
									</div>

									<p className="mb-2 mt-3 pn"><span><b>3.</b></span> <b>{t('M3chapter04Screen11Text3')}  </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle3" id="mycirle5"
												defaultValue="option1"

												onClick={saveRadio}
												checked={checkedQues9Option1}
												data-quesId="9"

											/>
											<label className="form-check-label" htmlFor="mycirle5">{t('myth')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle3"
												id="mycirle6"
												defaultValue="option2"

												onClick={saveRadio}
												checked={checkedQues9Option2}
												data-quesId="9"

											/>
											<label className="form-check-label" htmlFor="mycirle6">{t('fact')}</label>
										</div>
									</div>


									<p className="mb-2 mt-3 pn"><span><b>4.</b></span> <b>{t('M3chapter04Screen11Text4')}  </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle4"
												id="mycirle7"
												defaultValue="option1"


												onClick={saveRadio}
												checked={checkedQues10Option1}
												data-quesId="10"
											/>
											<label className="form-check-label" htmlFor="mycirle7">{t('myth')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle4" id="mycirle8"
												defaultValue="option2"

												onClick={saveRadio}
												checked={checkedQues10Option2}
												data-quesId="10"
											/>
											<label className="form-check-label" htmlFor="mycirle8">{t('fact')}</label>
										</div>
									</div>


									<p className="mb-2 mt-3 pn"><span><b>5.</b></span> <b> {t('M3chapter04Screen11Text5')}  </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle5"
												id="mycirle9"
												defaultValue="option1"

												onClick={saveRadio}
												checked={checkedQues11Option1}
												data-quesId="11"
											/>
											<label className="form-check-label" htmlFor="mycirle9">{t('myth')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle5" id="mycirle10"
												defaultValue="option2"

												onClick={saveRadio}
												checked={checkedQues11Option2}
												data-quesId="11"
											/>
											<label className="form-check-label" htmlFor="mycirle10">{t('fact')}</label>
										</div>
									</div>

									<p className="mb-2 mt-3 pn"><span><b>6.</b></span> <b>{t('M3chapter04Screen11Text6')}   </b> </p>
									<div className="d-flex mt-2">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle6"
												id="mycirle11"
												defaultValue="option1"

												onClick={saveRadio}
												checked={checkedQues12Option1}
												data-quesId="12"
											/>
											<label className="form-check-label" htmlFor="mycirle11">{t('myth')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle6"
												id="mycirle12"
												defaultValue="option2"

												onClick={saveRadio}
												checked={checkedQues12Option2}
												data-quesId="12"
											/>
											<label className="form-check-label" htmlFor="mycirle12">{t('fact')}</label>
										</div>
									</div>







								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="100%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-4-11.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-10" />
								{/* <NextBtn link="/module-03/chapter-04-screen-12" text="Go Next"/> */}
								<BtnDisabled />


							</div>
						</div>
					</div>
				</div>
			</div>




			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>
							<p className="text-white text-center">Sexting is the digital sharing of intimate images or videos with another person. It is a private type of self-disclosure. While it can bring partners closer, it may also carry some risks. </p>


							<div className=" d-flex justify-content-center mt-3">
								<button className="button_su m-auto" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="/module-03/chapter-03-screen-11" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>

						</div>
					</div>
				</div>
			</div>


			{/* Modal */}
			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					{/* <Modal.Title>Women are physically weak :</Modal.Title> */}
				</Modal.Header>

				<Modal.Body>
					<p>{showModalText}</p>
				</Modal.Body>
			</Modal>

		</>
	);
};

export default Chapter04Screen11;
