import React,{useState,useEffect} from "react";

import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";


const Chapter03Screen02N = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [checkedQues1, setCheckedQues1] = useState('');
  const [checkedQues2, setCheckedQues2] = useState('');
  const [checkedQues3, setCheckedQues3] = useState('');

  const [checkedQues4, setCheckedQues4] = useState('');
  const [checkedQues5, setCheckedQues5] = useState('');

  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": checkedQues1,
          "ques_id": "1"
        },
        "ques2": {
          "ans": checkedQues2,
          "ques_id": "2"
        },
        "ques3": {
          "ans": checkedQues3,
          "ques_id": "3"
        },
        "ques4": {
          "ans": checkedQues4,
          "ques_id": "4"
        },
        "ques5": {
          "ans": checkedQues5,
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": "",
          "ques_id": "10"
        },
        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        },
        "ques13": {
          "ans": "",
          "ques_id": "13"
        },
        "ques14": {
          "ans": "",
          "ques_id": "14"
        },
        "ques15": {
          "ans": "",
          "ques_id": "15"
        },
        "ques16": {
          "ans": "",
          "ques_id": "16"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/GenderChapter03',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-02/chapter-03-screen-03')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const checkedVal = (param,ans) => {
    if (param === '1') {
      setCheckedQues1(ans)
    }

    if (param === '2') {
      setCheckedQues2(ans)
    }
    if (param === '3') {
      setCheckedQues3(ans)
    }

    if (param === '4') {
      setCheckedQues4(ans)
    }
    if (param === '5') {
      setCheckedQues5(ans)
    }

  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/GenderChapter03Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  const BtnDisabled = () => {
    if (checkedQues1 !== '' && checkedQues2 !=='' && checkedQues3 !=='' &&  checkedQues4 !== '' && checkedQues5 !==''  ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };


  return (
    <>
      <div className="wrapper hp">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
          <Header leftChapter="2/5" percentage="42" star="22" />
          </div>
        </div>
        <div className="d-flex">
					<Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-center pt-20 mt-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >


                  <p>
                    {t('M2chapter03screen02nText1')}
                    </p>
                    <div className="mb-3 positon-submit">
                    <textarea
                      className="form-control"
                      rows={1}
                      placeholder="Write Something here....."
                      onChange={(e)=>setCheckedQues1(e.target.value)}
                      data-quesId="1"
                      defaultValue={checkedQues1}
                    />
                  </div>
                    <p> {t('M2chapter03screen02nText2')}</p>
                    <textarea
                      className="form-control"
                      rows={1}
                      placeholder="Write Something here....."
                      onChange={(e)=>setCheckedQues2(e.target.value)}
                      data-quesId="2"
                      defaultValue={checkedQues2}
                    />
                    <p className="mt-3"> {t('M2chapter03screen02nText3')}</p>
                    <textarea
                      className="form-control"
                      rows={1}
                      placeholder="Write Something here....."
                      onChange={(e)=>setCheckedQues3(e.target.value)}
                      data-quesId="3"
                      defaultValue={checkedQues3}
                    />



                    <p> {t('M2chapter03screen02nText4')}</p>

                    <textarea
                      className="form-control"
                      rows={1}
                      placeholder="Write Something here....."
                      onChange={(e)=>setCheckedQues4(e.target.value)}
                      data-quesId="4"
                      defaultValue={checkedQues4}
                    />



                    <p> {t('M2chapter03screen02nText5')}
                  </p>
                  <div className="mb-3 positon-submit">
                    <textarea
                      className="form-control"
                      rows={1}
                      placeholder="Write Something here....."
                      onChange={(e)=>setCheckedQues5(e.target.value)}
                      data-quesId="5"
                      defaultValue={checkedQues5}
                    />

                  </div>







                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-02/chapter-03-screen-02" />

                {/* <NextBtn
                  link="/module-01/chapter-03-screen-03"
                  text="Go Next"
                /> */}
                <BtnDisabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen02N;
