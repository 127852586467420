import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen19 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="0" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-4">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<h3 className="h3 mb-4">{t('additional_resources')} </h3>

									<ul style={{"list-style-type":"none"}}>

										<li>


											<a href="https://www.youtube.com/watch?v=GMSPRLMu7UU" target="_blank">
												<div className="d-flex align-items-center mange-icon mt-2">
													<div>
														<img src="/assets/img/chapter2/video-icon.png" alt="" />
													</div>
													<div>
														<p>{t('M3chapter01Screen19Text1')}</p>
													</div>
												</div>
											</a>

											{/* <a target="_blank" href="https://www.youtube.com/watch?v=GMSPRLMu7UU"><p>{t('M3chapter01Screen19Text1')} </p></a> */}
										</li>



										<li>
											{/* <a target="_blank" href="https://theypfoundation.org/resources/understanding-gender-beyond-the-binaries/"><p>{t('M3chapter01Screen19Text2')}	 </p></a> */}



											<a href="https://theypfoundation.org/resources/understanding-gender-beyond-the-binaries/" target="_blank">
												<div className="d-flex align-items-center mange-icon mt-2">
													<div>
														<img src="/assets/img/chapter1/list-icon.png" alt="" />
													</div>
													<div>
														<p>{t('M3chapter01Screen19Text2')}</p>
													</div>
												</div>
											</a>

										</li>


										<li>
											{/* <a target="_blank" href="https://gaysifamily.com/lifestyle/what-even-are-labels-a-gaysi-guide-on-labels-and-how-to-use-them/"><p>	{t('M3chapter01Screen19Text3')}  </p></a> */}



											<a href="https://gaysifamily.com/lifestyle/what-even-are-labels-a-gaysi-guide-on-labels-and-how-to-use-them/" target="_blank">
												<div className="d-flex align-items-center mange-icon mt-2">
													<div>
														<img src="/assets/img/chapter1/list-icon.png" alt="" />
													</div>
													<div>
														<p>{t('M3chapter01Screen19Text3')}</p>
													</div>
												</div>
											</a>

										</li>
									</ul>






								</div>

								<div
									className="col-lg-4 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="50%"
											draggable="false"
											src="/assets/img/chapter1/reading-book.png"
											alt=""
										/>
									</center>
								</div>




							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-01-screen-15" />
								<NextBtn link="/module-03/chapter-02-screen-01" text={t('CommonBtnGoNext')} />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen19;
