import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen06 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper vidoe-bg">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="0/5" percentage="5" star="5" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">


						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20">



								<div
									className="col-lg-7 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.1s"
								>

<div
                    className="alert alert-primary text-center mt-2"
                    role="alert"
                  >
                   {t('M2chapter01screen05Text3')}
                  </div>

									<h2 className="h2">{t('learn')}&nbsp;</h2>
									<p>
									{t('M2chapter01screen06Text1')}
									</p>
									<h5>{t('M2chapter01screen06Text2')}</h5>





									<iframe
										width="100%"
										height="360"
										src="https://www.youtube.com/embed/Y19kYh6k7ls"
										title="Sex Assigned at Birth and Gender Identity: What Is The Difference?"
										frameborder="0"
										allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
										allowfullscreen
									></iframe>



								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.1s"
								>

									<center>
										<img
											width="65%"
											className="mb-4"
											draggable="false"
											src="/assets/img/md1/md1-shap-6.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-01-screen-05" />

								<NextBtn
									link="/module-02/chapter-01-screen-07"
									text={t('CommonBtnGoNext')}
									classtype=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen06;
