import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen23 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between pt-20 mt-5">

								<div
									className="col-lg-6 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="100%"
											draggable="false"
											src="/assets/img/md5/chap02-4/23.jpg"
											alt=""
										/>
									</center>
								</div>
								<div
									className="col-lg-6 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>


									<p className="mb-2 mt-3"><span dangerouslySetInnerHTML={{
        									__html: t('M3chapter04Screen23Text1')}}></span>  </p>
									<p className="mb-2 mt-3">{t('M3chapter04Screen23Text2')}  </p>


								</div>




							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-22" />
								<NextBtn link="/module-03/chapter-04-screen-23n" text={t('CommonBtnGoNext')} />



							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter04Screen23;
