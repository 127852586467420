import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from "react-i18next";


const Chapter04Screen14 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [checkedQues7, setCheckedQues7] = useState([]);
  const [checkedQues7Option1, setCheckedQues7Option1] = useState('');
  const [checkedQues7Option2, setCheckedQues7Option2] = useState('');
  const [checkedQues7Option3, setCheckedQues7Option3] = useState('');
  const [checkedQues7Option4, setCheckedQues7Option4] = useState('');
 const [checkedQues7Val, setCheckedQues7Val] = useState('');



  const [checkedQues8, setCheckedQues8] = useState([]);
  const [option1Checked, setOption1Checked] = useState('');
  const [option2Checked, setOption2Checked] = useState('');
  const [option3Checked, setOption3Checked] = useState('');
  const [option4Checked, setOption4Checked] = useState('');

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    // alert(e.target.value);
    checkedVal(quesId,e.target.value)
  }
  const saveGenderBased = (e) => {
    const quesId = e.target.getAttribute("data-quesId");

    const res = e.target.value;
    const checked = e.target.checked;

    if(quesId === "7")
    {
      // alert(quesId);
      checkBoxChecked(res,checked,quesId);
      setCheckedQues7(checkedQues7 => [...checkedQues7, res]);
    }

    if(quesId === "8")
    {
      checkBoxChecked(res,checked,quesId);
      setCheckedQues8(checkedQues8 => [...checkedQues8, res]);
    }



  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": checkedQues7.join(','),
          "ques_id": "7"
        },
        "ques8": {
          "ans": checkedQues8.join(','),
          "ques_id": "8"
        }
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/SelfFinalQuiz',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-01/score')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues8.length > 0 && checkedQues7.length >0) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {

    if (param === '7') {

      const ansArr = ans.split(',');
      ansArr.map((el)=>{
        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues7(ansArr)
    }

    if (param === '8') {

      const ansArr = ans.split(',');
      ansArr.map((el)=>{

        const checked = true;
        checkBoxChecked(el,checked,param);
      })
      setCheckedQues8(ansArr)
    }

    // if (param === '7') {
    //   if (ans === 'option1') {
    //     setCheckedQues7Option1('checked')
    //     setCheckedQues7Option2('');
    //     setCheckedQues7Option3('')
    //     setCheckedQues7Option4('')
    //   } else if (ans === 'option2') {
    //     setCheckedQues7Option1('')
    //     setCheckedQues7Option2('checked');
    //     setCheckedQues7Option3('')
    //     setCheckedQues7Option4('')
    //     }else if (ans === 'option3') {
    //       setCheckedQues7Option1('')
    //       setCheckedQues7Option2('');
    //       setCheckedQues7Option3('checked')
    //       setCheckedQues7Option4('')
    //       }else if (ans === 'option4') {
    //         setCheckedQues7Option1('')
    //     setCheckedQues7Option2('');
    //     setCheckedQues7Option3('')
    //     setCheckedQues7Option4('checked')
    //         }
    //     setCheckedQues7Val(ans)
    // }
  }
  const checkBoxChecked = (val,checked,param)=>{


    if(param === '7')
    {
      if(val === 'option1')
      {
        if(checked === true)
        {
          setCheckedQues7Option1('checked');
        }else{
          setCheckedQues7Option1('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setCheckedQues7Option2('checked');
        }else{
          setCheckedQues7Option2('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setCheckedQues7Option3('checked');
        }else{
          setCheckedQues7Option3('');
        }
      }
      if(val === 'option4')
      {
        if(checked === true)
        {
          setCheckedQues7Option4('checked');
        }else{
          setCheckedQues7Option4('');
        }
      }
    }

    if(param === '8')
    {
      // alert();
      if(val === 'option1')
      {
        if(checked === true)
        {
          setOption1Checked('checked');
        }else{
          setOption1Checked('');
        }
      }
      if(val === 'option2')
      {
        if(checked === true)
        {
          setOption2Checked('checked');
        }else{
          setOption2Checked('');
        }
      }
      if(val === 'option3')
      {
        if(checked === true)
        {
          setOption3Checked('checked');
        }else{
          setOption3Checked('');
        }
      }
      if(val === 'option4')
      {
        if(checked === true)
        {
          setOption4Checked('checked');
        }else{
          setOption4Checked('');
        }
      }
    }

  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfFinalQuizResult',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="3/4" percentage="100" star="92" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20 ">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <p className="mb-2 mt-3 pn">
                    <span>
                      <b>7.</b>
                    </span>{" "}
                    <b> {t('M1chapter04screen14Text1')} </b>{" "}
                  </p>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-check"
                        type="checkbox"
                        name="namecircle1"
                        id="mycirle1"
                        defaultValue="option1"
                        onClick={saveGenderBased}
                        data-quesId="7"
                        checked={checkedQues7Option1}
                      />
                      <label className="form-check-label" htmlFor="mycirle1">
                      {t('M1chapter04screen14Text2')}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-check"
                        type="checkbox"
                        name="namecircle1"
                        id="mycirle2"
                        defaultValue="option2"
                        onClick={saveGenderBased}
                        data-quesId="7"
                        checked={checkedQues7Option2}
                      />
                      <label className="form-check-label" htmlFor="mycirle2">
                      {t('M1chapter04screen14Text3')}
                        {" "}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-check"
                        type="checkbox"
                        name="namecircle1"
                        id="mycirle3"
                        defaultValue="option3"
                        onClick={saveGenderBased}
                        data-quesId="7"
                        checked={checkedQues7Option3}
                      />
                      <label className="form-check-label" htmlFor="mycirle3">
                        {" "}
                        {t('M1chapter04screen14Text4')}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-check"
                        type="checkbox"
                        name="namecircle1"
                        id="mycirle4"
                        defaultValue="option4"
                        onClick={saveGenderBased}
                        data-quesId="7"
                        checked={checkedQues7Option4}
                      />
                      <label className="form-check-label" htmlFor="mycirle4">
                      {t('M1chapter04screen14Text5')}
                       
                      </label>
                    </div>
                  </div>
                  <p className="mb-2 mt-3 pn">
                    <span>
                      <b>8.</b>
                    </span>
                    <b>  {t('M1chapter04screen14Text6')} </b>{" "}
                  </p>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-check"
                        type="checkbox"
                        name="namecircle2"
                        id="mycirle5"
                        defaultValue="option1"
                        onClick={saveGenderBased}
                        checked={option1Checked}
                        data-quesId="8"
                      />
                      <label className="form-check-label" htmlFor="mycirle5">
                      {t('M1chapter04screen14Text7')} {" "}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-check"
                        type="checkbox"
                        name="namecircle2"
                        id="mycirle6"
                        defaultValue="option2"
                        onClick={saveGenderBased}
                        checked={option2Checked}
                        data-quesId="8"
                      />
                      <label className="form-check-label" htmlFor="mycirle6">
                        {" "}
                        {t('M1chapter04screen14Text8')} {" "}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-check"
                        type="checkbox"
                        name="namecircle2"
                        id="mycirle7"
                        defaultValue="option3"
                        onClick={saveGenderBased}
                        checked={option3Checked}
                        data-quesId="8"
                      />
                      <label className="form-check-label" htmlFor="mycirle7">
                      {t('M1chapter04screen14Text9')}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check ">
                      <input
                        className="form-check-input custom-check"
                        type="checkbox"
                        name="namecircle2"
                        id="mycirle8"
                        defaultValue="option4"
                        onClick={saveGenderBased}
                        checked={option4Checked}
                        data-quesId="8"
                      />
                      <label
                        className="form-check-label over-content"
                        htmlFor="mycirle8"
                      >
                        {t('M1chapter04screen14Text10')}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                  <PrevBtn link="/module-01/chapter-04-screen-13" />

                  {/* <NextBtn
                    link="/"
                    text="Go Next"
                  /> */}

                  <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter04Screen14;
