import React, { useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
// import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter05Screen18 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();




	const [checkedQues12, setCheckedQues12] = useState([]);
	const [option1Checked, setOption1Checked] = useState('');
	const [option2Checked, setOption2Checked] = useState('');
	const [option3Checked, setOption3Checked] = useState('');
	const [option4Checked, setOption4Checked] = useState('');
	const [option5Checked, setOption5Checked] = useState('');
	const [option6Checked, setOption6Checked] = useState('');
	const [option7Checked, setOption7Checked] = useState('');
	const [option8Checked, setOption8Checked] = useState('');
	const [option9Checked, setOption9Checked] = useState('');
	const [option10Checked, setOption10Checked] = useState('');
	const [option11Checked, setOption11Checked] = useState('');




	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveGenderBased = (e) => {
		const res = e.target.value;
		const checked = e.target.checked;
		checkBoxChecked(res, checked);
		setCheckedQues12(checkedQues12 => [...checkedQues12, res]);
	}


	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques12": {
					"ans": checkedQues12.join(','),
					"ques_id": "12"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculFinalQuiz',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-04/score')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues12.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '12') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked);
			})
			setCheckedQues12(ansArr)
		}

	}
	const checkBoxChecked = (val, checked) => {
		if (val === 'option1') {
			if (checked === true) {
				setOption1Checked('checked');
			} else {
				setOption1Checked('');
			}
		}
		if (val === 'option2') {
			if (checked === true) {
				setOption2Checked('checked');
			} else {
				setOption2Checked('');
			}
		}
		if (val === 'option3') {
			if (checked === true) {
				setOption3Checked('checked');
			} else {
				setOption3Checked('');
			}
		}
		if (val === 'option4') {
			if (checked === true) {
				setOption4Checked('checked');
			} else {
				setOption4Checked('');
			}
		}

		if (val === 'option5') {
			if (checked === true) {
				setOption5Checked('checked');
			} else {
				setOption5Checked('');
			}
		}

		if (val === 'option6') {
			if (checked === true) {
				setOption6Checked('checked');
			} else {
				setOption6Checked('');
			}
		}

		if (val === 'option7') {
			if (checked === true) {
				setOption7Checked('checked');
			} else {
				setOption7Checked('');
			}
		}

		if (val === 'option8') {
			if (checked === true) {
				setOption8Checked('checked');
			} else {
				setOption8Checked('');
			}
		}

		if (val === 'option9') {
			if (checked === true) {
				setOption9Checked('checked');
			} else {
				setOption9Checked('');
			}
		}

		if (val === 'option10') {
			if (checked === true) {
				setOption10Checked('checked');
			} else {
				setOption10Checked('');
			}
		}

		if (val === 'option11') {
			if (checked === true) {
				setOption11Checked('checked');
			} else {
				setOption11Checked('');
			}
		}





	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculFinalQuizResult',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>

			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="98" star="98" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between ">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h4 className="mb-2 mt-2 pn">
										{" "}
										<span>
											<b>12.</b>{" "}
										</span>{" "}
										<b>
											{" "}
											{t('M4chapter05screen18Text1')}
											
										</b>
									</h4>
								</div>
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle2"

												defaultValue="option1"
												onClick={saveGenderBased}
												checked={option1Checked}
											/>
											<label className="form-check-label" htmlFor="mycirle2">
											{t('M4chapter05screen18Text2')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle3"

												defaultValue="option2"
												onClick={saveGenderBased}
												checked={option2Checked}
											/>
											<label className="form-check-label" htmlFor="mycirle3">
											{t('M4chapter05screen18Text3')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle4"

												defaultValue="option3"
												onClick={saveGenderBased}
												checked={option3Checked}
											/>
											<label className="form-check-label" htmlFor="mycirle4">
											{t('M4chapter05screen18Text4')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle5"
												defaultValue="option4"
												onClick={saveGenderBased}
												checked={option4Checked}
											/>
											<label className="form-check-label" htmlFor="mycirle5">
											{t('M4chapter05screen18Text5')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle6"
												defaultValue="option5"
												onClick={saveGenderBased}
												checked={option5Checked}
											/>
											<label className="form-check-label" htmlFor="mycirle6">
												{" "}
												{t('M4chapter05screen18Text6')}{" "}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle7"
												defaultValue="option6"
												onClick={saveGenderBased}
												checked={option6Checked}
											/>
											<label className="form-check-label" htmlFor="mycirle7">
											{t('M4chapter05screen18Text7')}
												
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle8"
												defaultValue="option7"
												onClick={saveGenderBased}
												checked={option7Checked}
											/>
											<label className="form-check-label" htmlFor="mycirle8">
											{t('M4chapter05screen18Text8')}
												
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle9"
												defaultValue="option8"
												onClick={saveGenderBased}
												checked={option8Checked}
											/>
											<label className="form-check-label" htmlFor="mycirle9">
											{t('M4chapter05screen18Text9')}
												
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle10"
												defaultValue="option9"
												onClick={saveGenderBased}
												checked={option9Checked}
											/>
											<label className="form-check-label" htmlFor="mycirle10">
											{t('M4chapter05screen18Text10')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle11"
												defaultValue="option10"
												onClick={saveGenderBased}
												checked={option10Checked}
											/>
											<label className="form-check-label" htmlFor="mycirle11">
											{t('M4chapter05screen18Text11')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle12"
												defaultValue="option11"
												onClick={saveGenderBased}
												checked={option11Checked}
											/>
											<label className="form-check-label" htmlFor="mycirle12">
											{t('M4chapter05screen18Text12')}
												
											</label>
										</div>
									</div>
								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="33%"
											draggable="false"
											src="/assets/img/md4/md4-shap-5-18.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-05-screen-17" />

									{/* <NextBtn link="/" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen18;
