import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import PrevBtn from "../../../common/PrevBtn";
import Header from '../../../header/Header';
import { useDrop } from "react-dnd";
// import Image from './Image';
import Chapter03Screen15_1 from './Chapter03Screen15_1';
import axios from 'axios';
import BaseURL from '../../../config/Baseurl';
import { useEffect } from 'react';
import NextBtn from '../../../common/NextBtn'
import { useDrag } from "react-dnd";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';


const Chapter03Screen15 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	let name1 = t('M3chapter03Screen15Text2');
	let name2 = t('M3chapter03Screen15Text3');
	let name3 = t('M3chapter03Screen15Text4');
	let name4 = t('M3chapter03Screen15Text5');
	let name5 = t('M3chapter03Screen15Text6');
	let name6 = t('M3chapter03Screen15Text7');
	let name7 = t('M3chapter03Screen15Text8');
	let name8 = t('M3chapter03Screen15Text9');
	let name9 = t('M3chapter03Screen15Text10');
	let name10 = t('M3chapter03Screen15Text11');
	let name11 = t('M3chapter03Screen15Text12');
	let name12 = t('M3chapter03Screen15Text13');
	
	
	const [PictureList, setPictureList] = useState([
		{ "id": "1", "name": name1 },
		{ "id": "2", "name": name2 },
		{ "id": "3", "name": name3 },
		{ "id": "4", "name": name4 },
		{ "id": "5", "name": name5 },
		{ "id": "6", "name": name6 },
		{ "id": "7", "name": name7 },
		{ "id": "8", "name": name8 },
		{ "id": "9", "name": name9 },
		{ "id": "10", "name": name10 },
		{ "id": "11", "name": name11 },
		{ "id": "12", "name": name12 },

	]);




	const [meArray, setMeArray] = useState([]);
	const [familyArray, setFamilyArray] = useState([]);
	const [friendsArray, setFriendsArray] = useState([]);
	const [acquaintanceArray, setAcquaintanceArray] = useState([]);
	const [interactionArray, setinteractionArray] = useState([]);
	const [strangerArray, setStrangerArray] = useState([]);


	// stranger

	const [{ isStrangerOver }, strangerdrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addStrangerArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addStrangerArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setStrangerArray((strangerArray) => [...strangerArray, picture_List[0]]);
	};

	const onItemRemoveStranger = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = strangerArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setStrangerArray(meArray =>
			strangerArray.filter(stranger => {
				return stranger.id !== id;
			}),
		);
	};


	// interaction

	const [{ isInteractionOver }, interactiondrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addInteractionArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addInteractionArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setinteractionArray((interactionArray) => [...interactionArray, picture_List[0]]);
	};

	const onItemRemoveInteraction = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = interactionArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setinteractionArray(meArray =>
			interactionArray.filter(interaction => {
				return interaction.id !== id;
			}),
		);
	};


	// acquaintance

	const [{ isAcquaintanceOver }, acquaintancedrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addAcquaintanceArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addAcquaintanceArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setAcquaintanceArray((acquaintanceArray) => [...acquaintanceArray, picture_List[0]]);
	};

	const onItemRemoveAcquaintance = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = acquaintanceArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setAcquaintanceArray(meArray =>
			acquaintanceArray.filter(acquaintance => {
				return acquaintance.id !== id;
			}),
		);
	};


	// for Me
	const [{ isMeOver }, medrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addMeArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addMeArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setMeArray((meArray) => [...meArray, picture_List[0]]);
	};

	const onItemRemoveMe = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = meArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setMeArray(meArray =>
			meArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};

	//  for family


	const [{ isFamilyOver }, familydrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addFamilyArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addFamilyArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setFamilyArray((meArray) => [...meArray, picture_List[0]]);
	};

	const onItemRemoveFamily = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = familyArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setFamilyArray(meArray =>
			familyArray.filter(family => {
				return family.id !== id;
			}),
		);
	};

	//  for friends


	const [{ isFriendsOver }, friendsdrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addFriendsArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const addFriendsArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setFriendsArray((friendsArray) => [...friendsArray, picture_List[0]]);
	};

	const onItemRemoveFriends = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = friendsArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setFriendsArray(meArray =>
			friendsArray.filter(friends => {
				return friends.id !== id;
			}),
		);
	};










	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques24": {
					"ans": JSON.stringify(meArray),
					"ans2": JSON.stringify(familyArray),
					"ans3": JSON.stringify(friendsArray),
					"ans4": JSON.stringify(acquaintanceArray),
					"ans5": JSON.stringify(interactionArray),
					"ans6": JSON.stringify(strangerArray),
					"ques_id": "24"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-03-screen-16');
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,

			"ques_id": "24"
		});

		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				console.log(response.data);
				if (response.data.success === true) {
					// console.log()
					const ans = JSON.parse(response.data.data.chapter.ans)
					const ans2 = JSON.parse(response.data.data.chapter.ans2)
					const ans3 = JSON.parse(response.data.data.chapter.ans3)
					const ans4 = JSON.parse(response.data.data.chapter.ans4)
					const ans5 = JSON.parse(response.data.data.chapter.ans5)
					const ans6 = JSON.parse(response.data.data.chapter.ans6)


					setMeArray(ans);
					setFamilyArray(ans2);
					setFriendsArray(ans3);
					setAcquaintanceArray(ans4);
					setinteractionArray(ans5);
					setStrangerArray(ans6);

					if (ans.length > 0) {
						ans.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans2.length > 0) {
						ans2.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans3.length > 0) {
						ans3.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans4.length > 0) {
						ans4.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans5.length > 0) {
						ans5.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans6.length > 0) {
						ans6.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}




				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);


	const BtnDisabled = () => {
		if (meArray.length !== 0 && familyArray.length !== 0 && friendsArray.length !== 0
			&& acquaintanceArray.length !== 0 && interactionArray.length !== 0 && strangerArray.length !== 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-1">
								<div
									className="col-lg-9 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3">{t('engage')} </h3>
									<p className="mb-2  mt-3">{t('M3chapter03Screen15Text1')}  </p>







									<div className="row">
										<div className="col-lg-7" >
											<div className="row" ref={medrop}>
												<div className="col-lg-5">
													<div className="box-drag">
														{t('Me')}
													</div>
												</div>
												<div className="col-lg-7" >
													<div className="close-box-design list-inline mt-1" >
														<ul>

															{meArray.map((e) => {

																return (
																	<>

																		<li>
																			<button
																				onClick={onItemRemoveMe}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			/>  {e.name}
																		</li>



																	</>
																)


															})}


														</ul>
													</div>
												</div>
											</div>

											<div className="row" ref={familydrop}>
												<div className="col-lg-5">
													<div className="box-drag family-c">
													{t('Family')}
													</div>
												</div>
												<div className="col-lg-7">
													<div className="close-box-design list-inline mt-1">
														<ul>
															{familyArray.map((e) => {

																return (
																	<>

																		<li>
																			<button
																				onClick={onItemRemoveFamily}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			/>  {e.name}
																		</li>



																	</>
																)


															})}
														</ul>
													</div>
												</div>
											</div>
											<div className="row" ref={friendsdrop}>
												<div className="col-lg-5">
													<div className="box-drag friend-c">
													{t('Friends')}
													</div>
												</div>
												<div className="col-lg-7">
													<div className="close-box-design list-inline mt-1">
														<ul>
															{friendsArray.map((e) => {

																return (
																	<>

																		<li>
																			<button
																				onClick={onItemRemoveFriends}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			/>  {e.name}
																		</li>



																	</>
																)


															})}
														</ul>
													</div>
												</div>
											</div>

											<div className="row" ref={acquaintancedrop}>
												<div className="col-lg-5">
													<div className="box-drag acquaintance-c">
													{t('Acquaintance')}
													</div>
												</div>
												<div className="col-lg-7">
													<div className="close-box-design list-inline mt-1">
														<ul>
															{acquaintanceArray.map((e) => {

																return (
																	<>

																		<li>
																			<button
																				onClick={onItemRemoveAcquaintance}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			/>  {e.name}
																		</li>



																	</>
																)


															})}
														</ul>
													</div>
												</div>
											</div>

											<div className="row" ref={interactiondrop}>
												<div className="col-lg-5">
													<div className="box-drag interaction-c">
													{t('Interaction')}
													</div>
												</div>
												<div className="col-lg-7">
													<div className="close-box-design list-inline mt-1">
														<ul>
															{interactionArray.map((e) => {

																return (
																	<>

																		<li>
																			<button
																				onClick={onItemRemoveInteraction}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			/>  {e.name}
																		</li>



																	</>
																)


															})}
														</ul>
													</div>
												</div>
											</div>
											<div className="row" ref={strangerdrop}>
												<div className="col-lg-5">
													<div className="box-drag strangers-c">
													{t('Strangers')}
													</div>
												</div>
												<div className="col-lg-7">
													<div className="close-box-design list-inline mt-1">
														<ul>
															{strangerArray.map((e) => {

																return (
																	<>

																		<li>
																			<button
																				onClick={onItemRemoveStranger}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			/>  {e.name}
																		</li>



																	</>
																)


															})}
														</ul>
													</div>
												</div>
											</div>

										</div>

										<div className="col-lg-5" >
											<ul class="list-style-set list-circle cursor-pointer ml-1">
												{
													PictureList.map((item, index) => {
														return (
															<>

																<Chapter03Screen15_1 key={item.id} id={item.id} imageName={item.name} />

															</>
														)
													})
												}

											</ul>
										</div>
									</div>




								</div>

								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="60%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-3-19.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-03-screen-13" />
								{/* <NextBtn link="/module-03/chapter-03-screen-16" text="Go Next"/> */}
								<BtnDisabled />




							</div>
						</div>
					</div>
				</div>
			</div>



		</>
	);
};

export default Chapter03Screen15;
