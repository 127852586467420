import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter02Screen03 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header "

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/5" percentage="22" star="10" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3">
								<div
									className="col-lg-7 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.1s"
								>
									<h1 className="h1 text-left">{t('learn')} </h1>
									<p className="text-left mb-3">
										{t('M2chapter02screen03Text1')} {" "}
									</p>





									<div
                    className="alert alert-primary text-center mt-2"
                    role="alert"
                  >
                   {t('M2chapter02screen03Text2')}
                  </div>


								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center><img width="45%" src="/assets/img/md1/md1-shap-2-3.png" alt="" /></center>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-02-screen-02" />

								<NextBtn link="/module-02/chapter-02-screen-04" text={t('CommonBtnGoNext')} classtype="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	);
};

export default Chapter02Screen03;
