import React, { useState } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter05Screen11 = () => {
	const { t, i18n } = useTranslation();
	const [readMoreContent, setReadMoreContent] = useState();
	const [readMoreText, setReadMoreText] = useState("Read More...");

	const text = () => {
		return (
			<>
				<p>
					Another GBV she faced was getting married off early, before the legal
					age and by force. In her marital home she faced many forms of domestic
					violence including physical, emotional, psychological and sexual. This
					happened because she was forced to keep giving birth even when she was
					not physically fit and her husband refused to use condoms, was violent
					towards her hence further deteriorating her physical and mental
					health.
				</p>
			</>
		);
	};

	const addReadMore = () => {
		setReadMoreContent(text);
		setReadMoreText("");
	};

	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="94" star="76" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between pt-20 mt-3">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h2 ">Learn</h2>
									<div >
										<p>
											{t('M2chapter05screen11Text1')}

										</p>
										<p>{t('M2chapter05screen11Text2')}</p>


										<p className="mb-2">
										{t('M2chapter05screen11Text3')}

										</p>
										{/* {readMoreContent} */}

									</div>

									{/* <NavLink to="" className="watch-now" onClick={addReadMore}>
						{readMoreText}
					</NavLink> */}
								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img width="55%" src="/assets/img/md1/md1-shap-5-11.png" alt="" />
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-05-screen-09" />
								<NextBtn
									link="/module-02/chapter-05-screen-12"
									text={t('CommonBtnGoNext')}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen11;
