import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';


const Chapter02Screen06 = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [checkedQues7_1, setCheckedQues7_1] = useState('');
  const [checkedQues7_2, setCheckedQues7_2] = useState('');
  const [checkedQues7Val, setCheckedQues7Val] = useState('');
  const [checkedQues8_1, setCheckedQues8_1] = useState('');
  const [checkedQues8_2, setCheckedQues8_2] = useState('');
  const [checkedQues8Val, setCheckedQues8Val] = useState('');
  const [checkedQues9_1, setCheckedQues9_1] = useState('');
  const [checkedQues9_2, setCheckedQues9_2] = useState('');
  const [checkedQues9Val, setCheckedQues9Val] = useState('');

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    checkedVal(quesId,e.target.value)
  }
  // console.log(checkedQues5);
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans":"",
          "ques_id": "5"
        },
        "ques6": {
          "ans":  "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": checkedQues7Val,
          "ques_id": "7"
        },
        "ques8": {
          "ans": checkedQues8Val,
          "ques_id": "8"
        },
        "ques9": {
          "ans": checkedQues9Val,
          "ques_id": "9"
        },
        "ques10": {
          "ans": "",
          "ques_id": "10"
        },
        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        },
        "ques13": {
          "ans": "",
          "ques_id": "13"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/SelfChapter02',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-01/chapter-02-screen-07')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues7Val !== '' && checkedQues8Val !== ''&& checkedQues9Val !== '') {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {
    if (param === '7') {
      if (ans === '1') {
        setCheckedQues7_1('checked')
        setCheckedQues7_2('')
      } else {
        if (ans === '2') {
          setCheckedQues7_2('checked')
          setCheckedQues7_1('')
        }
      }
      setCheckedQues7Val(ans)
    }
    if (param === '8') {
      if (ans === '1') {
        setCheckedQues8_1('checked')
        setCheckedQues8_2('')
      } else {
        if (ans === '2') {
          setCheckedQues8_2('checked')
          setCheckedQues8_1('')
        }
      }
      setCheckedQues8Val(ans)
    }

    if (param === '9') {
      if (ans === '1') {
        setCheckedQues9_1('checked')
        setCheckedQues9_2('')
      } else {
        if (ans === '2') {
          setCheckedQues9_2('checked')
          setCheckedQues9_1('')
        }
      }
      setCheckedQues9Val(ans)
    }

  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfChapter02Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },

      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  return (
    <>
      <div className="wrapper bg-bd-2 ">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
          <Header leftChapter="1/4" percentage="16" star="16" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20 mt-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <p className="pn">
                  <span><b>3.</b> </span>
                    <b>
                      {t('M1chapter02screen06Text1')}
                    </b>{" "}
                  </p>
                  <div className="wiout-border-table without-bg table-padding-set">
                    <table className="table table-intend">
                      <tbody>
                        <tr>
                          <td>
                          {t('M1chapter02screen06Text2')} <br></br>

                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="m"
                                  id="inlineRadio1"
                                  defaultValue="1"
                                  checked={checkedQues7_1}
                                  onClick={saveRadio}
                                  data-quesId="7"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            {t('M1chapter02screen06Text3')}

                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="m"
                                  id="inlineRadio1"
                                  defaultValue="2"
                                  checked={checkedQues7_2}
                                  onClick={saveRadio}
                                  data-quesId="7"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="inlineRadio1"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="mb-2 mt-3 pn">
                  <span><b>4.</b> </span>
                    <b>
                    {t('M1chapter02screen06Text4')}
                    {" "}
                    </b>
                  </p>
                  <div className="wiout-border-table without-bg table-padding-set">
                    <table className="table table-intend">
                      <tbody>
                        <tr>
                          <td>
                          {t('M1chapter02screen06Text5')}

                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="a"
                                  id=""
                                  defaultValue="1"
                                  checked={checkedQues8_1}
                                  onClick={saveRadio}
                                  data-quesId="8"
                                />
                                <label className="form-check-label" htmlFor="" />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                          {t('M1chapter02screen06Text6')}

                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="a"
                                  id=""
                                  defaultValue="2"
                                  checked={checkedQues8_2}
                                  onClick={saveRadio}
                                  data-quesId="8"
                                />
                                <label className="form-check-label" htmlFor="" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <p className="pn">
                  <span><b>5.</b> </span>
                    <b>
                    {t('M1chapter02screen06Text7')}

                    </b>
                  </p>
                  <div className="wiout-border-table without-bg table-padding-set">
                    <table className="table table-intend">
                      <tbody>
                        <tr>
                          <td>
                            {t('M1chapter02screen06Text8')}

                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="l"
                                  id=""
                                  defaultValue="1"
                                  checked={checkedQues9_1}
                                  onClick={saveRadio}
                                  data-quesId="9"
                                />
                                <label className="form-check-label" htmlFor="" />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td>
                            {t('M1chapter02screen06Text9')}
                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="l"
                                  id=""
                                  defaultValue="2"
                                  checked={checkedQues9_2}
                                  onClick={saveRadio}
                                  data-quesId="9"
                                />
                                <label className="form-check-label" htmlFor="" />
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
              <PrevBtn link="/module-01/chapter-02-screen-05"/>

                {/* <NextBtn link="/module-02/chapter-02-screen-07" text="Go Next"/> */}
                <BtnDisabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen06;
