import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate, NavLink } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen05 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [checkedQues5, setCheckedQues5] = useState("");

  const user_details = JSON.parse(localStorage.getItem("user_details"));

  const saveAns = () => {
    // alert();
    var data = JSON.stringify({
      user_id: user_details.user_id,
      answers: {
        ques5: {
          ans: checkedQues5,
          ques_id: "5",
        },
      },
    });
    var config = {
      method: "post",
      url: BaseURL + "/SexualityChapter02",
      headers: {
        Authorization: `Bearer ${user_details.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate("/module-03/chapter-02-screen-06");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const checkedVal = (param, ans) => {
    if (param === "5") {
      setCheckedQues5(ans);
    }
  };
  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_details.user_id,
      ques_id: "",
    });
    var config = {
      method: "post",
      url: BaseURL + "/sexualityChapter02Result",
      headers: {
        Authorization: `Bearer ${user_details.token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(), result.ans);
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const BtnDisabled = () => {
    if (checkedQues5 !== "") {
      return (
        <>
          <button className="button_su">
            <span className="su_button_circle desplode-circle"></span>
            <button
              className="button_su_inner"
              onClick={saveAns}
            >
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="2/4" percentage="50" star="50" />
          </div>
        </div>
        <div className="d-flex">
					<Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-between pt-20 mt-4 mt-m-3">
                <div
                  className="col-lg-8 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h3 className="h3 mt-2">{t('reflect')}</h3>
                  <p className="mb-2 mt-1">
                    {t('M3chapter02Screen05nText1')}
                    {" "}
                  </p>

                  <textarea
                    className="form-control"
                    rows={7}
                    placeholder="Write Something here....."
                    onChange={(e) => setCheckedQues5(e.target.value)}
                    data-quesId="8"
                    defaultValue={checkedQues5}
                  />

                  {/* <p className="mb-2 mt-1">
                  {t('M3chapter02Screen05nText2')}
                   {" "}
                  </p> */}
                </div>

                <div
                  className="col-lg-3 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      width="65%"
                      draggable="false"
                      src="/assets/img/chapter1/reading-book.png"
                      alt=""
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-03/chapter-02-screen-05" />
                {/* <NextBtn link="/module-03/chapter-02-screen-06" text="Go Next"/> */}
                {/* <button className="button_su" >
                    <span className="su_button_circle desplode-circle">
                    </span>
                    <button onClick={saveAns}  className="button_su_inner" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                    <span className="button_text_container">
                      Go Next <i className="bi bi-arrow-right" />
                    </span>
                  </button>
                </button> */}

                <BtnDisabled />
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default Chapter02Screen05;
