import React, { useState, useEffect } from "react";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Header from "../../../header/Header";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen28 = () => {
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
	const [checkedQues50_1, setCheckedQues50_1] = useState('');
	const [checkedQues50_2, setCheckedQues50_2] = useState('');
	const [checkedQues50_3, setCheckedQues50_3] = useState('');

	const [checkedQues51_1, setCheckedQues51_1] = useState('');
	const [checkedQues51_2, setCheckedQues51_2] = useState('');




	// const [checkedQues32_5, setCheckedQues11_5] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {

				"ques50": {
					"ans": checkedQues50_1 + "," + checkedQues50_2 + "," + checkedQues50_3 ,
					"ques_id": "50"
				},
				"ques51": {
					"ans": checkedQues51_1 + "," + checkedQues51_2 ,
					"ques_id": "51"
				}




			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-04-screen-24')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		ques50numb();
		ques51numb();
	}, [])

	const ques50numb = ()=>{
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": "50"
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter.ans;
					console.log(results);
					const res = results.split(",");
					res.map((elem, i) => {
						// console.log(i);
						if (i === 0) {
							setCheckedQues50_1(elem)
						}
						if (i === 1) {
							setCheckedQues50_2(elem)
						}
						if (i === 2) {
							setCheckedQues50_3(elem)
						}



					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	const ques51numb = ()=>{
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": "51"
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter.ans;
					console.log(results);
					const res = results.split(",");
					res.map((elem, i) => {
						// console.log(i);
						if (i === 0) {
							setCheckedQues51_1(elem)
						}
						if (i === 1) {
							setCheckedQues51_2(elem)
						}




					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	const BtnDisabled = () => {
		if (checkedQues50_1 !== '' && checkedQues51_1 !== ''
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-0">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<h3 className="h3">{t('reflect')} </h3>
									<p className="mb-2  mt-3">{t('M3chapter04Screen23nText1')}  </p>
									<ul className="list-style-set list-input list-line-height mt-3 mb-3">
										<li>{t('M3chapter04Screen23nText2')}</li>
										<li>{t('M3chapter04Screen23nText3')}  </li>
										<li>{t('M3chapter04Screen23nText4')}  </li>

										<li> <input type="text" placeholder="" onChange={(e) => { setCheckedQues50_1(e.target.value) }} data-quesId="50" defaultValue={checkedQues50_1} className="input-without-style form-control" /> </li>
										<li> <input type="text" placeholder
											className="input-without-style form-control"
											onChange={(e) => { setCheckedQues50_2(e.target.value) }}
											data-quesId="50"
											defaultValue={checkedQues50_2}
										/></li>
										<li><input type="text"
											placeholder
											className="input-without-style form-control"
											onChange={(e) => { setCheckedQues50_3(e.target.value) }}
											data-quesId="50"
											defaultValue={checkedQues50_3}
										/></li>

										<li className="mt-3">{t('M3chapter04Screen23nText5')} </li>

										<li> . <input type="text" placeholder
											className="input-without-style form-control"
											onChange={(e) => { setCheckedQues51_1(e.target.value) }}
											data-quesId="51"
											defaultValue={checkedQues51_1}
										/></li>

										<li> . <input type="text" placeholder className="input-without-style form-control" onChange={(e) => { setCheckedQues51_2(e.target.value) }}
											data-quesId="51"
											defaultValue={checkedQues51_2}
										/></li>


									</ul>




								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="45%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-2-15.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-23" />
								{/* <NextBtn link="/module-03/chapter-04-screen-29" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>



		</>
	);
};

export default Chapter04Screen28;
