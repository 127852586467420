import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter05Screen06 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="86" star="66" />
					</div>
				</div>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
								style={{
									visibility: "visible",
									animationDuration: "1s",
									animationDelay: "0.2s",
									animationName: "fadeInDown",
								}}
							>
								<p className="mb-4">
									{t('M2chapter05screen06Text2')}
								</p>

							</div>
							<div className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s">
								<center>
									<img width="53%" src="/assets/img/md1/md1-shap-5-07n.png" alt="" />
								</center>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className="d-flex align-items-top justify-content-end mt-4">
								<PrevBtn link="/module-02/chapter-05-screen-05" />
								<NextBtn
									link="/module-02/chapter-05-screen-07"
									text={t('CommonBtnGoNext')}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen06;
