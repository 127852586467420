import React, { useState,useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import HeaderHome from "../../header/HeaderHome";
import axios from "axios";
import BaseURL from "../../config/Baseurl";
// import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const Register = () => {

	const navigate = useNavigate();
	const [mobEmail, setEmailMob] = useState();
	const [password, setPassword] = useState();
	const [userName, setUserName] = useState();
	const [gender, setGender] = useState();

	// for error state
	const [message, setMessage] = useState("");
	const [mobEmailErr, setmobEmailErr] = useState({});
	const [userNameErr, setUserNameErr] = useState({});
	const [passwordErr, setPasswordErr] = useState({});
	const [genderErr, setGenderErr] = useState({});

	const [states,setState] = useState([]);



	const [selectedState, setSelectedState] = useState(''); // Initial state
	const [studentType, setStudentType] = useState(''); // Initial state

  const changeValueState = (event) => {

    setSelectedState(event.target.value); // Update state with the selected value
  };


  const changeStudentType = (event) => {

    setStudentType(event.target.value); // Update state with the selected value
  };







	useEffect(()=>{
        getState();
    },[])
    const getState = ()=>{

            let data = '';

            let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: BaseURL + '/fetchStates',
            headers: { },
            data : data
            };

            axios.request(config)
            .then((response) => {
                if(response.data.success == true)
                {
                    setState(response.data.data);
                }
            // console.log(JSON.stringify(response.data));
            })
            .catch((error) => {
            console.log(error);
            });

    }



	const registerOtpSent = (e) => {
		e.preventDefault();

		const isValid = formValidation();



		if (isValid === true) {
			const regex = /(<([^>]+)>)/gi;
			const __mobEmail =
				mobEmail !== undefined ? mobEmail.replace(regex, "") : "";
			const __userName =
				userName !== undefined ? userName.replace(regex, "") : "";
			const __password =
				password !== undefined ? password.replace(regex, "") : "";


			var data = JSON.stringify({
				emailMob: __mobEmail,
			});




			var config = {
				method: "post",
				url: BaseURL + "/registerSentOtp",
				headers: {
					"Content-Type": "application/json",
				},
				data: data,
			};

			axios(config)
				.then(function (response) {
					if (response.data.success === true) {


						var forgot_password_details = JSON.stringify({

							mobemail: __mobEmail,
							email: __mobEmail,
							username: __userName,
							password: __password,
							gender: gender,
							state_id: selectedState,
							user_type: studentType,
							email_message_id: response.data.data.message_id,
						});


						localStorage.setItem(
							"student_signup_details",
							forgot_password_details
						);
						navigate("/signup-otp");
					} else {
						setMessage(response.data.message);
						// setType("danger");
					}
					// console.log(JSON.stringify(response.data));
				})
				.catch(function (error) {
					console.log(error);
					// setMessage(error);
					// setType("danger");
				});
		}

		console.log(isValid);
	};

	// form validation
	const formValidation = () => {
		const mobEmailErr = {};
		const userNameErr = {};
		const passwordErr = {};
		const genderErr = {};
		const mobEmail1 = mobEmail === undefined || mobEmail === "" ? "" : mobEmail;
		const userName1 = userName === undefined || userName === "" ? "" : userName;
		const password1 = password === undefined || password === "" ? "" : password;
		const gender1 = gender === undefined || gender === "" ? "" : gender;

		let isValid = true;

		var pattern = new RegExp(/^[0-9\b]+$/);
		if (pattern.test(mobEmail1)) {
			if (mobEmail1.length !== 10) {
				isValid = false;

				mobEmailErr.required = "Please enter valid phone number.";
			}
		} else {
			let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
			if (reg.test(mobEmail1) === false) {
				isValid = false;
				mobEmailErr.required = "Please enter valid Email.";
			}
		}

		// console.log(mobEmail.length);
		if (mobEmail1.length === 0) {
			mobEmailErr.required = "First Name Is Required";
			isValid = false;
		}

		if (userName1.length === 0) {
			userNameErr.required = "User Name Is Required";
			isValid = false;
		}

		if (password1.length === 0) {
			passwordErr.required = "Password Is Required";
			isValid = false;
		}

		if (gender1.length === 0) {
			genderErr.required = "Gender Is Required";
			isValid = false;
		}

		// checkEmailMob();

		setmobEmailErr(mobEmailErr);
		setUserNameErr(userNameErr);
		setPasswordErr(passwordErr);
		setGenderErr(genderErr);
		return isValid;
	};

	const checkEmailMob = (e) => {
		const MobEmailVal = e.target.value;
		setEmailMob(MobEmailVal);
		var data = JSON.stringify({
			emailMob: MobEmailVal,
		});

		var config = {
			method: "post",
			url: BaseURL + "/checkEmailMob",
			headers: {
				"Content-Type": "application/json",
			},
			data: data,
		};

		axios(config)
			.then(function (response) {
				console.log(response.data);
				mobEmailErr.required = response.data.message;
				setMessage(response.data.message);
				setmobEmailErr(mobEmailErr);
				// setMessage()
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	const checkUserName = (e) => {
		const username = e.target.value;
		setUserName(username);
		var data12 = JSON.stringify({
			logUsername: username,
		});

		var config = {
			method: "post",
			url: BaseURL + "/checkUserId",
			headers: {
				"Content-Type": "application/json",
			},
			data: data12,
		};

		axios(config)
			.then(function (response) {
				userNameErr.required = response.data.message;
				setUserNameErr(userNameErr);
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<>
			<div className="wrapper">

				<div className="wrapper">
					<div className="fixed-header ">
						<div className="container-fluid cf-set">
							<HeaderHome />
						</div>
					</div>


					<form onSubmit={registerOtpSent}>
						<div className="data-adjust">

							<div className="container-fluid cf-set">
								<div className="row align-items-top justify-content-center pt-20">
									<div className="col-lg-8">
										<h2 className="h2 mb-5">Sign up</h2>
										<span className="error">{message}</span>
										<div className="row">
											<div className="col-lg-6">
												<div className="mb-3">
													<label
														htmlFor="exampleInputEmail1"
														className="form-label"
													>
														Email/Mobile No.
													</label>
													<input
														type="text"
														className="form-control"
														placeholder=""
														// onChange={(e)=>setEmailMob(e.target.value)}

														onChange={checkEmailMob}
													/>

													{Object.keys(mobEmailErr).map((key) => {
														return (
															<span className="error">{mobEmailErr[key]}</span>
														);
													})}
												</div>
											</div>
											<div className="col-lg-6">
												<div className="mb-3">
													<label
														htmlFor="exampleInputEmail1"
														className="form-label"
													>
														Password
													</label>
													<input
														type="password"
														className="form-control"
														placeholder=""
														onChange={(e) => setPassword(e.target.value)}
													/>
												</div>
												{Object.keys(passwordErr).map((key) => {
													return (
														<span className="error">{passwordErr[key]}</span>
													);
												})}
											</div>
											<div className="col-lg-6">
												<div className="mb-3">
													<label
														htmlFor="exampleInputEmail1"
														className="form-label"
													>
														Username
													</label>
													<input
														type="text"
														className="form-control"
														placeholder=""
														// onChange={(e)=>setUserName(e.target.value)}
														onChange={checkUserName}
													/>
												</div>
												{Object.keys(userNameErr).map((key) => {
													return (
														<span className="error">{userNameErr[key]}</span>
													);
												})}
											</div>
											<div className="col-lg-6">
												<div className="mb-3">
													<label
														htmlFor="exampleInputEmail1"
														className="form-label"
													>
														Gender
													</label>
													<div className="d-flex">
														<div className="form-check form-check-inline">
															<input
																className="form-check-input custom-check"
																type="radio"
																name="inlineRadioOptions"
																id="inlineRadio1"
																defaultValue="male"
																defaultChecked=""
																onChange={(e) => setGender(e.target.value)}
															/>
															<label
																className="form-check-label"
																htmlFor="inlineRadio1"
															>
																Male
															</label>
														</div>
														<div className="form-check form-check-inline">
															<input
																className="form-check-input custom-check"
																type="radio"
																name="inlineRadioOptions"
																id="inlineRadio2"
																defaultValue="female"
																onChange={(e) => setGender(e.target.value)}
															/>
															<label
																className="form-check-label "
																htmlFor="inlineRadio2"
															>
																Female
															</label>
														</div>
														<div className="form-check form-check-inline">
															<input
																className="form-check-input custom-check"
																type="radio"
																name="inlineRadioOptions"
																id="inlineRadio3"
																defaultValue="other"
																onChange={(e) => setGender(e.target.value)}
															/>
															<label
																className="form-check-label"
																htmlFor="inlineRadio3"
															>
																Other
															</label>
														</div>
													</div>
													{Object.keys(genderErr).map((key) => {
														return (
															<span className="error">{genderErr[key]}</span>
														);
													})}
												</div>
											</div>

											<div className="col-lg-6 ">
												<div className="mb-3 relative">
													<label
														htmlFor="exampleInputEmail1"
														className="form-label"
													>
														User Type
													</label>
													<i class="fa fa-angle-down select-arrow" aria-hidden="true"></i>

													<select required value={studentType} onChange={changeStudentType} className='form-control relative'>

														<option value="" disabled >Select User Tye</option>
														<option value="student">Student</option>
														<option value="professional">professional</option>
														<option value="other">Other</option>
													</select>


													{Object.keys(mobEmailErr).map((key) => {
														return (
															<span className="error">{mobEmailErr[key]}</span>
														);
													})}
												</div>
											</div>

											<div className="col-lg-6 ">
												<div className="mb-3 relative">
													<label
														htmlFor="exampleInputEmail1"
														className="form-label"
													>
														State
													</label>
													<i class="fa fa-angle-down select-arrow" aria-hidden="true"></i>

													<select name="" id="" value={selectedState} onChange={changeValueState} className='form-control'>
													{
														(states.length>0)?states.map((state)=>{

															return (<>
																	<option value={state.id}>{state.state_name}</option>
															</>);
														}):""
													}


													</select>


													{Object.keys(mobEmailErr).map((key) => {
														return (
															<span className="error">{mobEmailErr[key]}</span>
														);
													})}
												</div>
											</div>
										</div>


										<div className="d-flex mt-2">
											<div className="form-check form-check-inline">
												<input className="form-check-input custom-check" required type="checkbox" name="namecircle" id="mycirle3" defaultValue="option1" />
												<label className="form-check-label" htmlFor="mycirle3">By logging into this course , you agree to our Terms of use and Privacy Policy. </label>
											</div>
										</div>

										<button
											className="button_su mt-3 wow fadeInDown"
											data-wow-duration="1s"
											data-wow-delay="0.2s"
										>
											<span className="su_button_circle" />
											<button type="submit" className="button_su_inner">
												<span className="button_text_container">
													{" "}
													Submit <i className="bi bi-arrow-right" />
												</span>
											</button>
										</button>

										<button
											className="button_su mt-3 "
										>
											<span className="su_button_circle" />
											<NavLink to="/login" className="button_su_inner">
												<span className="button_text_container">
													{" "}
													Sign In <i className="bi bi-arrow-right" />
												</span>
											</NavLink>
										</button>
									</div>
									<div
										className="col-lg-3 offset-lg-1 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<center>
											<img
												width="70%"
												src="/assets/img/chapter1/reading-book.png"
												alt=""
											/>
										</center>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>

			<div
				className="button-fixed termsc wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-center">

								<ul className="list-style-set list-circle mt-1">
								<li> <NavLink to="/terms-and-conditions">Terms & Conditions </NavLink>	</li>
									<li> <NavLink to="/privacy-policy">Privacy Policy </NavLink>	</li>

								</ul>


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Register;
