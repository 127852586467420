import React,{useEffect,useState} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter03Screen03n4 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();


  const [checkedQues6Opt1, setCheckedQues6Opt1] = useState('');
  const [checkedQues6Opt2, setCheckedQues6Opt2] = useState('');
  const [checkedQues6Opt3, setCheckedQues6Opt3] = useState('');
  const [checkedQues6Val, setCheckedQues6Val] = useState('');

  const [checkedQues7Opt1, setCheckedQues7Opt1] = useState('');
  const [checkedQues7Opt2, setCheckedQues7Opt2] = useState('');
  const [checkedQues7Opt3, setCheckedQues7Opt3] = useState('');
  const [checkedQues7Val, setCheckedQues7Val] = useState('');

  const [checkedQues8Opt1, setCheckedQues8Opt1] = useState('');
  const [checkedQues8Opt2, setCheckedQues8Opt2] = useState('');
  const [checkedQues8Opt3, setCheckedQues8Opt3] = useState('');
  const [checkedQues8Val, setCheckedQues8Val] = useState('');





  // const [checkedQues8Opt1, setCheckedQues8Opt1] = useState('');
  // const [checkedQues8Opt2, setCheckedQues8Opt2] = useState('');
  // const [checkedQues8Opt3, setCheckedQues8Opt3] = useState('');
  // const [checkedQues8Val, setCheckedQues8Val] = useState('');
  const [checkedQues9Opt1, setCheckedQues9Opt1] = useState('');
  const [checkedQues9Opt2, setCheckedQues9Opt2] = useState('');
  const [checkedQues9Opt3, setCheckedQues9Opt3] = useState('');
  const [checkedQues9Val, setCheckedQues9Val] = useState('');
  const [checkedQues10Opt1, setCheckedQues10Opt1] = useState('');
  const [checkedQues10Opt2, setCheckedQues10Opt2] = useState('');
  const [checkedQues10Opt3, setCheckedQues10Opt3] = useState('');
  const [checkedQues10Val, setCheckedQues10Val] = useState('');
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    // alert(quesId)
    checkedVal(quesId,e.target.value)
  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": checkedQues6Val,
          "ques_id": "6"
        },
        "ques7": {
          "ans": checkedQues7Val,
          "ques_id": "7"
        },
        "ques8": {
          "ans": checkedQues8Val,
          "ques_id": "8"
        },
        "ques9": {
          "ans": checkedQues9Val,
          "ques_id": "9"
        },

        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        },
        "ques13": {
          "ans": "",
          "ques_id": "13"
        },
        "ques14": {
          "ans": "",
          "ques_id": "14"
        }
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/GenderChapter03',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-02/chapter-03-screen-03n5')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/GenderChapter03Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])

  console.log(checkedQues7Val);
  const BtnDisabled = () => {
    if (checkedQues6Val !== '' && checkedQues7Val !== '' && checkedQues8Val !== '' && checkedQues9Val !== ''   ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };

  const checkedVal = (param,ans) => {
// alert('out');
    if (param === '6') {

      if (ans === 'option1') {
        setCheckedQues6Opt1('checked')
        setCheckedQues6Opt2('')
        setCheckedQues6Opt3('')
      } else if(ans === 'option2') {

        setCheckedQues6Opt1('')
        setCheckedQues6Opt2('checked')
        setCheckedQues6Opt3('')

      } else if(ans === 'option3') {
        setCheckedQues6Opt1('')
        setCheckedQues6Opt2('')
        setCheckedQues6Opt3('checked')

      }

      setCheckedQues6Val(ans)
    }

    if (param === '7') {

      if (ans === 'option1') {
        setCheckedQues7Opt1('checked')
        setCheckedQues7Opt2('')
        setCheckedQues7Opt3('')
      } else if(ans === 'option2') {

        setCheckedQues7Opt1('')
        setCheckedQues7Opt2('checked')
        setCheckedQues7Opt3('')

      } else if(ans === 'option3') {
        setCheckedQues7Opt1('')
        setCheckedQues7Opt2('')
        setCheckedQues7Opt3('checked')

      }
      setCheckedQues7Val(ans)
    }

    if (param === '8') {
      if (ans === 'option1') {
        setCheckedQues8Opt1('checked')
        setCheckedQues8Opt2('')
        setCheckedQues8Opt3('')
      } else if(ans === 'option2') {
        setCheckedQues8Opt1('')
        setCheckedQues8Opt2('checked')
        setCheckedQues8Opt3('')
      } else if(ans === 'option3') {
        setCheckedQues8Opt1('')
        setCheckedQues8Opt2('')
        setCheckedQues8Opt3('checked')
      }
      setCheckedQues8Val(ans)
    }
    if (param === '9') {
      if (ans === 'option1') {
        setCheckedQues9Opt1('checked')
        setCheckedQues9Opt2('')
        setCheckedQues9Opt3('')
      } else if(ans === 'option2') {
        setCheckedQues9Opt1('')
        setCheckedQues9Opt2('checked')
        setCheckedQues9Opt3('')
      } else if(ans === 'option3') {
        setCheckedQues9Opt1('')
        setCheckedQues9Opt2('')
        setCheckedQues9Opt3('checked')
      }
      setCheckedQues9Val(ans)
    }
    if (param === '10') {
      if (ans === 'option1') {
        setCheckedQues10Opt1('checked')
        setCheckedQues10Opt2('')
        setCheckedQues10Opt3('')
      } else if(ans === 'option2') {
        setCheckedQues10Opt1('')
        setCheckedQues10Opt2('checked')
        setCheckedQues10Opt3('')
      } else if(ans === 'option3') {
        setCheckedQues10Opt1('')
        setCheckedQues10Opt2('')
        setCheckedQues10Opt3('checked')
      }
      setCheckedQues10Val(ans)
    }
  }
  return (
    <>
      <div className="wrapper hp">
        <div
          className="fixed-header"
        >
          <div className="container-fluid cf-set">
            <Header leftChapter="2/5" percentage="46" star="26" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-center pt-20 mt-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <h3 className="h3 mt-1 ">{t('engage')}</h3>
                  <p className="">{t('M2chapter03screen03n4Text1')}</p>
                  <div className="wiout-border-table without-bg table-padding-0">
                    <table className="table">
                      <tbody>


                      <tr>
                          <td>
                            <p className="pn ">1.&nbsp;&nbsp;{t('M2chapter03screen03n4Text2')}</p>
                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check ">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="ques6option1"
                                  id="ture"

                                  onClick={saveRadio}
                                  checked={checkedQues6Opt1}
                                  defaultValue="option1"
                                  data-quesId="6"

                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mycirle1"
                                ></label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <p className="pn ml-1" > {t('M2chapter03screen03n4Text3')}</p>
                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check ">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="ques6option2"
                                  id="ques1option2"
                                  defaultValue="option2"
                                  data-quesId="6"
                                  onClick={saveRadio}
                                  checked={checkedQues6Opt2}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mycirle1"
                                ></label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <p className="pn ml-1"> {t('M2chapter03screen03n4Text4')}</p>
                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check ">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="ques6option3"
                                  id="mycirle1"
                                  defaultValue="option3"
                                  data-quesId="6"
                                  onClick={saveRadio}
                                  checked={checkedQues6Opt3}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mycirle1"
                                ></label>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <br></br>
                        <tr>

                          <td className="padding-set-table">
                          <p className="pn">2.&nbsp;{t('M2chapter03screen03n4Text5')} </p>
                          </td>
                          <td className="padding-set-table">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check ">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="ques7option1"
                                  id="ques7option1"
                                  defaultValue="option1"
                                  data-quesId="7"
                                  onClick={saveRadio}
                                  checked={checkedQues7Opt1}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mycirle1"
                                ></label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <p className="pn ml-1"> {t('M2chapter03screen03n4Text6')}</p>
                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check ">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="ques7option2"
                                  id="ques8option2"
                                  defaultValue="option2"
                                  data-quesId="7"
                                  onClick={saveRadio}
                                  checked={checkedQues7Opt2}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mycirle1"
                                ></label>
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <p className="pn ml-1"> {t('M2chapter03screen03n4Text7')}{" "}</p>
                          </td>
                          <td>
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check ">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  id="ques7option3"
                                  name="ques7option3"
                                  defaultValue="option3"
                                  data-quesId="7"
                                  onClick={saveRadio}
                                  checked={checkedQues7Opt3}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="mycirle1"
                                ></label>
                              </div>
                            </div>
                          </td>
                        </tr>

                        <br></br>












                        <tr>
                          <td className="padding-set-table">
                          <p className="pn"> 3.&nbsp; {t('M2chapter03screen03n4Text8')}{" "}</p>
                          </td>
                          <td className="padding-set-table">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check ">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="ques8option1"
                                  id="ture"
                                  onClick={saveRadio}
                                  checked={checkedQues8Opt1}
                                  defaultValue="option1"
                                  data-quesId="8"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td >
                          <p className="pn ml-1"> {t('M2chapter03screen03n4Text9')}{" "}</p>
                          </td>
                          <td >
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check ">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="ques8option2"
                                  id="ture"
                                  onClick={saveRadio}
                                  checked={checkedQues8Opt2}
                                  defaultValue="option2"
                                  data-quesId="8"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td >
                          <p className="pn ml-1"> {t('M2chapter03screen03n4Text10')}{" "}</p>
                          </td>
                          <td >
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check ">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="ques8option3"
                                  id="ture"
                                  onClick={saveRadio}
                                  checked={checkedQues8Opt3}
                                  defaultValue="option3"
                                  data-quesId="8"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <br></br>
                        <tr>
                          <td className="padding-set-table">
                          <p className="pn"> 4.&nbsp; {t('M2chapter03screen03n4Text11')}</p>
                          </td>
                          <td className="padding-set-table">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check ">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="ques9option1"
                                  id="ture"
                                  onClick={saveRadio}
                                  checked={checkedQues9Opt1}
                                  defaultValue="option1"
                                  data-quesId="9"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <p className="pn ml-1"> {t('M2chapter03screen03n4Text12')}</p>
                          </td>
                          <td >
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check ">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="ques9option2"
                                  id="ture"
                                  onClick={saveRadio}
                                  checked={checkedQues9Opt2}
                                  defaultValue="option2"
                                  data-quesId="9"
                                />
                              </div>
                            </div>
                          </td>
                        </tr>







                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-02/chapter-03-screen-03" />
                {/* <NextBtn
                  link="/module-01/chapter-03-screen-03n5"
                  text="Go Next"
                /> */}
                <BtnDisabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Chapter03Screen03n4;
