import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from "react-i18next";

const Chapter04Screen06 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [checkedQues6Option1, setCheckedQues6Option1] = useState('');
	const [checkedQues6Option2, setCheckedQues6Option2] = useState('');
	const [checkedQues6Option3, setCheckedQues6Option3] = useState('');
	const [checkedQues6Val, setCheckedQues6Val] = useState('');

	const [checkedQues7Option1, setCheckedQues7Option1] = useState('');
	const [checkedQues7Option2, setCheckedQues7Option2] = useState('');
	const [checkedQues7Option3, setCheckedQues7Option3] = useState('');
	const [checkedQues7Val, setCheckedQues7Val] = useState('');

	const [checkedQues16Option1, setCheckedQues16Option1] = useState('');
	const [checkedQues16Option2, setCheckedQues16Option2] = useState('');
	const [checkedQues16Option3, setCheckedQues16Option3] = useState('');
	const [checkedQues16Val, setCheckedQues16Val] = useState('');

	const [msg6_1, setMsg6_1] = useState('');
	const [msg6_2, setMsg6_2] = useState('');
	const [msg6_3, setMsg6_3] = useState('');

	const [msg7_1, setMsg7_1] = useState('');
	const [msg7_2, setMsg7_2] = useState('');
	const [msg7_3, setMsg7_3] = useState('');

	const [msg16_1, setMsg16_1] = useState('');
	const [msg16_2, setMsg16_2] = useState('');
	const [msg16_3, setMsg16_3] = useState('');




	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);
		if (quesId === '6') {
			if (e.target.value === 'option1') {
				setMsg6_1('- Assertive');
			}

			if (e.target.value === 'option2') {
				setMsg6_2('- Aggressive');
			}

			if (e.target.value === 'option3') {
				setMsg6_3('- Passive');
			}
		}

		if (quesId === '7') {
			if (e.target.value === 'option1') {
				setMsg7_1('- Aggressive');
			}

			if (e.target.value === 'option2') {
				setMsg7_2('- Assertive');
			}

			if (e.target.value === 'option3') {
				setMsg7_3('- Passive');
			}
		}

		if (quesId === '16') {
			if (e.target.value === 'option1') {
				setMsg16_1('- Aggressive');
			}

			if (e.target.value === 'option2') {
				setMsg16_2('- Assertive');
			}

			if (e.target.value === 'option3') {
				setMsg16_3('- Passive');
			}
		}


		checkedVal(quesId, e.target.value)
	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": checkedQues6Val,
					"ques_id": "6"
				},
				"ques7": {
					"ans": checkedQues7Val,
					"ques_id": "7"
				},
				"ques16": {
					"ans": checkedQues16Val,
					"ques_id": "16"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-04-screen-07')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const checkedVal = (param, ans) => {
		if (param === '6') {
			if (ans === 'option1') {
				setCheckedQues6Option1('checked')
				setCheckedQues6Option2('')
				setCheckedQues6Option3('')

			} else if (ans === 'option2') {
				setCheckedQues6Option1('')
				setCheckedQues6Option2('checked')
				setCheckedQues6Option3('')

			} else if (ans === 'option3') {
				setCheckedQues6Option1('')
				setCheckedQues6Option2('')
				setCheckedQues6Option3('checked')
			}
			setCheckedQues6Val(ans)
		}

		if (param === '7') {
			if (ans === 'option1') {
				setCheckedQues7Option1('checked')
				setCheckedQues7Option2('')
				setCheckedQues7Option3('')

			} else if (ans === 'option2') {
				setCheckedQues7Option1('')
				setCheckedQues7Option2('checked')
				setCheckedQues7Option3('')

			} else if (ans === 'option3') {
				setCheckedQues7Option1('')
				setCheckedQues7Option2('')
				setCheckedQues7Option3('checked')
			}
			setCheckedQues7Val(ans)
		}

		if (param === '16') {
			if (ans === 'option1') {
				setCheckedQues16Option1('checked')
				setCheckedQues16Option2('')
				setCheckedQues16Option3('')

			} else if (ans === 'option2') {
				setCheckedQues16Option1('')
				setCheckedQues16Option2('checked')
				setCheckedQues16Option3('')

			} else if (ans === 'option3') {
				setCheckedQues16Option1('')
				setCheckedQues16Option2('')
				setCheckedQues16Option3('checked')
			}
			setCheckedQues16Val(ans)
		}



	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])
	const BtnDisabled = () => {
		if (checkedQues6Val !== '' && checkedQues7Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.1s"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="68" star="68" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-2">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mb-2 mt-3 pn">
										<span><b>3.</b> </span>
										<b>
											{t('M1chapter04screen06Text1')}
										</b>
									</p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="a"
												id="ture"
												defaultValue="option1"
												onClick={saveRadio}
												data-quesId="6"
												checked={checkedQues6Option1}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
												{t('M1chapter04screen06Text2')}
												 <span className="red">{msg6_1} </span>
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="a"
												id="ture"
												defaultValue="option2"
												onClick={saveRadio}
												data-quesId="6"
												checked={checkedQues6Option2}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
												{t('M1chapter04screen06Text3')}
												<span className="red">{msg6_2} </span>
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="a"
												id="ture"
												defaultValue="option3"
												onClick={saveRadio}
												data-quesId="6"
												checked={checkedQues6Option3}
											/>
											<label className="form-check-label" htmlFor="inlineRadio1">
											{t('M1chapter04screen06Text4')} <span className="red">{msg6_3} </span>
											</label>
										</div>
									</div>





									<p className="mb-2 mt-3 pn">
										<span><b>4.</b> </span>
										<b>
										{t('M1chapter04screen06Text5')}
										</b>
									</p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="c"
												id="circle1"
												defaultValue="option1"
												onClick={saveRadio}
												data-quesId="7"
												checked={checkedQues7Option1}
											/>
											<label className="form-check-label" htmlFor="circle1">
											{t('M1chapter04screen06Text6')} <span className="red">{msg7_1} </span>
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="c"
												id="circle2"
												defaultValue="option2"
												onClick={saveRadio}
												data-quesId="7"
												checked={checkedQues7Option2}
											/>
											<label className="form-check-label" htmlFor="circle2">
											{t('M1chapter04screen06Text7')} <span className="red">{msg7_2} </span>
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="c"
												id="circle3"
												defaultValue="option3"
												onClick={saveRadio}
												data-quesId="7"
												checked={checkedQues7Option3}
											/>
											<label className="form-check-label" htmlFor="circle3">
											{t('M1chapter04screen06Text8')} <span className="red">{msg7_3} </span>
											</label>
										</div>
									</div>





									<p className="mb-2 mt-3 pn">
										<span><b>5.</b> </span>
										<b>
										{t('M1chapter04screen06Text9')}
										</b>
									</p>
									<a href="https://www.youtube.com/watch?v=3ozLwjlT1bI" target="_blank">
										<div className="d-flex align-items-center mange-icon mb-3 mt-1">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p> {t('M1chapter04screen06Text10')}</p>
											</div>
										</div>
									</a>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="b"
												id="circle4"
												defaultValue="option1"
												onClick={saveRadio}
												data-quesId="16"
												checked={checkedQues16Option1}
											/>
											<label className="form-check-label" htmlFor="circle4">
											{t('M1chapter04screen06Text11')}  <span className="red">{msg16_1} </span>
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="b"
												id="circle6"
												defaultValue="option3"
												onClick={saveRadio}
												data-quesId="16"
												checked={checkedQues16Option3}
											/>
											<label className="form-check-label" htmlFor="circle6">
											{t('M1chapter04screen06Text12')} <span className="red">{msg16_3} </span>
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="radio"
												name="b"
												id="circle5"
												defaultValue="option2"
												onClick={saveRadio}
												data-quesId="16"
												checked={checkedQues16Option2}
											/>
											<label className="form-check-label" htmlFor="circle5">
											{t('M1chapter04screen06Text13')} <span className="red">{msg16_2} </span>
											</label>
										</div>
									</div>

<p>{t('M1chapter04screen06Text14')}</p>


								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img width="73%" src="/assets/img/md2/md2-shap-4-6.png" alt="" />
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-01/chapter-04-screen-05" />

									{/* <NextBtn link="/module-02/chapter-04-screen-07" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen06;
