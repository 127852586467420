import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen11 = () => {
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
	const [pencilColorItem, setPencilColorItem] = useState([]);
	const [kandhaChecked,setKandhaChecked] = useState();
	const [breastChecked,setBreastChecked] = useState();
	const [stomachChecked,setStomachChecked] = useState();
	const [rightHandChecked,setRightHandChecked] = useState();
	const [leftHandChecked,setLeftHandChecked] = useState();
	const [legChecked,setLegChecked] = useState();
	const [headChecked,setHeadChecked] = useState();

	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const selectItem = (e)=>{
		let selVal = e.target.value;

		if(selVal === 'breast')
		{
			setPencilColorItem(current=>[...current,selVal]);
			setBreastChecked('checked')
		}

		if(selVal === 'stomach')
		{
			setPencilColorItem(current=>[...current,selVal]);
			setStomachChecked('checked')
		}

		if(selVal === 'righthand')
		{
			setPencilColorItem(current=>[...current,selVal]);
			setRightHandChecked('checked')
		}
		if(selVal === 'lefthand')
		{
			setPencilColorItem(current=>[...current,selVal]);
			setLeftHandChecked('checked')
		}
		if(selVal === 'leg')
		{
			setPencilColorItem(current=>[...current,selVal]);
			setLegChecked('checked')
		}

		if(selVal === 'head')
		{
			setPencilColorItem(current=>[...current,selVal]);
			setHeadChecked('checked')
		}

		if(selVal === 'kandha')
		{
			setPencilColorItem(current=>[...current,selVal]);
			setKandhaChecked('checked')
		}


	}


	const checkedItem = (selVal)=>{

		if(selVal === 'breast')
		{
			setPencilColorItem(current=>[...current,selVal]);
			setBreastChecked('checked')
		}

		if(selVal === 'stomach')
		{
			setPencilColorItem(current=>[...current,selVal]);
			setStomachChecked('checked')
		}

		if(selVal === 'righthand')
		{
			setPencilColorItem(current=>[...current,selVal]);
			setRightHandChecked('checked')
		}
		if(selVal === 'lefthand')
		{
			setPencilColorItem(current=>[...current,selVal]);
			setLeftHandChecked('checked')
		}
		if(selVal === 'leg')
		{
			setPencilColorItem(current=>[...current,selVal]);
			setLegChecked('checked')
		}

		if(selVal === 'head')
		{
			setPencilColorItem(current=>[...current,selVal]);
			setHeadChecked('checked')
		}

		if(selVal === 'kandha')
		{
			setPencilColorItem(current=>[...current,selVal]);
			setKandhaChecked('checked')
		}


	}


	useEffect(()=>{
		fetchItem();
	},[]);

	const fetchItem = ()=>{
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": 25
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					const results = response.data.data.chapter;
					const parseAns = JSON.parse(results.ans);

					parseAns.map((item)=>{
						checkedItem(item);
						// console.warn(item);
					})
					// results.map((result) => {
					// 	checkedVal(result.ques_id.toString(), result.ans)
					// })
				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}

	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques25": {
					"ans": pencilColorItem,
					"ques_id": "25"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-03/chapter-03-screen-11')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}



	const BtnDisabled = () => {
		if (pencilColorItem.length === 7) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	// console.log(pencilColorItem);
  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="2/4" percentage="50" star="50" />
          </div>
        </div>
		<div className="d-flex">
			<Sidebar />
			<div className="data-adjust">
			<div className="container-fluid cf-set">
				<div className="row align-items-top justify-content-between pt-20 mt-5">
				<div
					className="col-lg-7 wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<h3 className="h3">{t('engage')}: </h3>
					<p className="mb-2  mt-3">
						{t('M3chapter03Screen10Text1')}
					{" "}
					</p>
					<p className="mb-2  mt-3">
						1. {t('M3chapter03Screen10Text2')}
					{" "}
					</p>
					<p className="mb-2  mt-3">
						2. {t('M3chapter03Screen10Text3')}
					{" "}
					</p>

					<p className="mb-2  mt-3">
						3. {t('M3chapter03Screen10Text4')}
					{" "}
					</p>
					<ul>
						<li>{t('M3chapter03Screen10Text5')}</li>
						<li>{t('M3chapter03Screen10Text6')}</li>
						<li>{t('M3chapter03Screen10Text7')}</li>
						<li>{t('M3chapter03Screen10Text8')}</li>
					</ul>
					<p>{t('M3chapter03Screen10Text9')}</p>
				</div>

				<div
					className="col-lg-4 wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="body_img">
					<input
					type="radio"
					id="successColor"
					value="kandha"
					onClick={selectItem}
					checked={kandhaChecked}

					/>
					<input type="radio" id="specifyColor"
						value="breast"
						onClick={selectItem}
						checked={breastChecked}
					/>
					<input type="radio" id="specifyColor"
						value="stomach"
						onClick={selectItem}
						checked={stomachChecked}
					/>
					<input type="radio" id="successColor"
						value="righthand"
						onClick={selectItem}
						checked={rightHandChecked}
					/>
					<input type="radio" id="successColor"
						value="lefthand"
						onClick={selectItem}
						checked={leftHandChecked}
					/>
					<input type="radio" id="specifyColor"
						value="leg"
						onClick={selectItem}
						checked={legChecked}
					/>
					<input type="radio" id="successColor"
						value="head"
						onClick={selectItem}
						checked={headChecked}
					/>
					<img
						src="/assets/img/md5/chap02-2/body.jpeg"
						className="width100"
					/>
					</div>
				</div>
				</div>
			</div>
			</div>
		</div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-03/chapter-03-screen-09" />
                {/* <NextBtn
                  link="/module-03/chapter-03-screen-11"
                  text="Go Next"
                /> */}

				<BtnDisabled/>

                {/* <button className="button_su">
                  <span className="su_button_circle desplode-circle">
                  </span>
                  <a href="" data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </a>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>
              <p className="text-white text-center">The users should be able to mark the same alphabet over a body part multiple times, also the user can mark the same area with different alphabets at the same time </p>


              <div className=" d-flex justify-content-center mt-3">
                <button className="button_su m-auto" data-bs-dismiss="modal" aria-label="Close">
                  <span className="su_button_circle desplode-circle">
                  </span>
                  <a href="/#/module-03/chapter-03-screen-11"  className="button_su_inner"><span className="button_text_container"> Go Next <i className="bi bi-arrow-right" /></span> </a>
                </button>
              </div>

            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Chapter03Screen11;
