import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen36 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [checkedQues17, setCheckedQues17] = useState([]);
	const [checkedQues17Option1, setCheckedQues17Option1] = useState('');
	const [checkedQues17Option2, setCheckedQues17Option2] = useState('');
	const [checkedQues17Option3, setCheckedQues17Option3] = useState('');


	const [checkedQues18, setCheckedQues18] = useState([]);
	const [checkedQues18Option1, setCheckedQues18Option1] = useState('');
	const [checkedQues18Option2, setCheckedQues18Option2] = useState('');
	const [checkedQues18Option3, setCheckedQues18Option3] = useState('');



	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);
		checkedVal(quesId, e.target.value)
	}

	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;
		checkBoxChecked(res, checked, quesId);
		if (quesId === '17') {
			setCheckedQues17(checkedQues17 => [...checkedQues17, res]);
		}

		if (quesId === '18') {
			setCheckedQues18(checkedQues18 => [...checkedQues18, res]);
		}

	}

	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques17": {
					"ans": checkedQues17.join(','),
					"ques_id": "17"
				},

				"ques18": {
					"ans": checkedQues18.join(','),
					"ques_id": "18"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-03/chapter-03-screen-37')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	const checkedVal = (param, ans) => {
		if (param === '17') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues17(ansArr)
		}

		if (param === '18') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues18(ansArr)
		}


	}
	const checkBoxChecked = (val, checked, quesId) => {
		if (quesId === '17') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues17Option1('checked');
				} else {
					setCheckedQues17Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues17Option2('checked');
				} else {
					setCheckedQues17Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues17Option3('checked');
				} else {
					setCheckedQues17Option3('');
				}
			}
		}



		if (quesId === '18') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues18Option1('checked');
				} else {
					setCheckedQues18Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues18Option2('checked');
				} else {
					setCheckedQues18Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues18Option3('checked');
				} else {
					setCheckedQues18Option3('');
				}
			}







		}

	}

	const BtnDisabled = () => {
		if (checkedQues17.length > 0 && checkedQues18.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-1">
								<div
									className="col-lg-9 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mb-2 mt-3 pn"><span><b>1.</b></span> <b>{t('M3chapter03Screen36Text1')}</b> </p>

									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle2" id="mycirle5"
												defaultValue="option1"
												onClick={saveGenderBased}
												data-quesId="17"
												checked={checkedQues17Option1}
											/>
											<label className="form-check-label" htmlFor="mycirle5">{t('M3chapter03Screen36Text2')}	</label>
										</div>
									</div>

									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle2" id="mycirle7"
												defaultValue="option2"
												onClick={saveGenderBased}
												data-quesId="17"
												checked={checkedQues17Option2}

											/>
											<label className="form-check-label" htmlFor="mycirle7">{t('M3chapter03Screen36Text3')}	 </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle2" id="mycirle8"
												defaultValue="option3"
												onClick={saveGenderBased}
												data-quesId="17"
												checked={checkedQues17Option3}

											/>
											<label className="form-check-label" htmlFor="mycirle8">	{t('M3chapter03Screen36Text4')}</label>
										</div>
									</div>


									<p className="mb-2 mt-3 pn"><span><b>2.</b></span> <b>{t('M3chapter03Screen36Text5')}</b> </p>

									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle3" id="mycirle5"
												defaultValue="option1"
												onClick={saveGenderBased}
												data-quesId="18"
												checked={checkedQues18Option1}

											/>
											<label className="form-check-label" htmlFor="mycirle5">{t('M3chapter03Screen36Text6')} </label>
										</div>
									</div>

									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle3" id="mycirle7"
												defaultValue="option2"
												onClick={saveGenderBased}
												data-quesId="18"
												checked={checkedQues18Option2}

											/>
											<label className="form-check-label" htmlFor="mycirle7">{t('M3chapter03Screen36Text7')}  </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle3" id="mycirle8"

												defaultValue="option3"
												onClick={saveGenderBased}
												data-quesId="18"
												checked={checkedQues18Option3}
											/>
											<label className="form-check-label" htmlFor="mycirle8">	{t('M3chapter03Screen36Text8')} </label>
										</div>
									</div>



								</div>

								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="70%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-2-2.png"
											alt=""
										/>
									</center>
								</div>




							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-03-screen-35" />
								{/* <NextBtn link="/module-03/chapter-03-screen-37" text="Go Next"/> */}

								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>


		</>
	);
};

export default Chapter03Screen36;
