import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen03 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const [checkedQues19Yes, setCheckedQues19Yes] = useState('');
	const [checkedQues19No, setCheckedQues19No] = useState('');
	const [checkedQues19Unsecure, setCheckedQues19Unsecure] = useState('');
	const [checkedQues19Val, setCheckedQues19Val] = useState('');

	const [checkedQues20Yes, setCheckedQues20Yes] = useState('');
	const [checkedQues20No, setCheckedQues20No] = useState('');
	const [checkedQues20Unsecure, setCheckedQues20Unsecure] = useState('');
	const [checkedQues20Val, setCheckedQues20Val] = useState('');

	const [checkedQues21Yes, setCheckedQues21Yes] = useState('');
	const [checkedQues21No, setCheckedQues21No] = useState('');
	const [checkedQues21Unsecure, setCheckedQues21Unsecure] = useState('');
	const [checkedQues21Val, setCheckedQues21Val] = useState('');

	const [checkedQues22Yes, setCheckedQues22Yes] = useState('');
	const [checkedQues22No, setCheckedQues22No] = useState('');
	const [checkedQues22Unsecure, setCheckedQues22Unsecure] = useState('');
	const [checkedQues22Val, setCheckedQues22Val] = useState('');

	const [checkedQues23Yes, setCheckedQues23Yes] = useState('');
	const [checkedQues23No, setCheckedQues23No] = useState('');
	const [checkedQues23Unsecure, setCheckedQues23Unsecure] = useState('');
	const [checkedQues23Val, setCheckedQues23Val] = useState('');

	const [checkedQues26Yes, setCheckedQues26Yes] = useState('');
	const [checkedQues26No, setCheckedQues26No] = useState('');
	const [checkedQues26Unsecure, setCheckedQues26Unsecure] = useState('');
	const [checkedQues26Val, setCheckedQues26Val] = useState('');





	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showModalText, setShowModalText] = useState();
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		checkedVal(quesId, e.target.value)

	}

	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques19": {
					"ans": checkedQues19Val,
					"ques_id": "19"
				},
				"ques20": {
					"ans": checkedQues20Val,
					"ques_id": "20"
				},
				"ques21": {
					"ans": checkedQues21Val,
					"ques_id": "21"
				},
				"ques22": {
					"ans": checkedQues22Val,
					"ques_id": "22"
				},

				"ques23": {
					"ans": checkedQues23Val,
					"ques_id": "23"
				},
				"ques26": {
					"ans": checkedQues26Val,
					"ques_id": "26"
				},



			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-03/chapter-03-screen-04')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans) => {
		if (param === '19') {

			if (ans === 'yes') {
				setCheckedQues19Yes('checked')
				setCheckedQues19No('')
				setCheckedQues19Unsecure('')
			} else if (ans === 'unsecure') {
				setCheckedQues19Unsecure('checked')
				setCheckedQues19Yes('')
				setCheckedQues19No('')
			}else {
				if (ans === 'no') {
					setCheckedQues19No('checked')
					setCheckedQues19Yes('')
					setCheckedQues19Unsecure('')
				}
			}
			setCheckedQues19Val(ans)
		}
		if (param === '20') {
			if (ans === 'yes') {
				setCheckedQues20Yes('checked')
				setCheckedQues20No('')
				setCheckedQues20Unsecure('')
			} else if (ans === 'unsecure') {
				setCheckedQues20Unsecure('checked')
				setCheckedQues20Yes('')
				setCheckedQues20No('')
			}else {
				if (ans === 'no') {
					setCheckedQues20No('checked')
					setCheckedQues20Yes(''
					)
					setCheckedQues20Unsecure('')
				}
			}
			setCheckedQues20Val(ans)
		}

		if (param === '21') {
			if (ans === 'yes') {
				setCheckedQues21Yes('checked')
				setCheckedQues21No('')
				setCheckedQues21Unsecure('')
			} else if (ans === 'unsecure') {
				setCheckedQues21Unsecure('checked')
				setCheckedQues21Yes('')
				setCheckedQues21No('')
			}else {
				if (ans === 'no') {
					setCheckedQues21No('checked')
					setCheckedQues21Yes('')
					setCheckedQues21Unsecure('')
				}
			}
			setCheckedQues21Val(ans)
		}

		if (param === '22') {
			if (ans === 'yes') {
				setCheckedQues22Yes('checked')
				setCheckedQues22No('')
				setCheckedQues22Unsecure('')
			} else if (ans === 'unsecure') {
				setCheckedQues22Unsecure('checked')
				setCheckedQues22Yes('')
				setCheckedQues22No('')
			} else {
				if (ans === 'no') {
					setCheckedQues22No('checked')
					setCheckedQues22Yes('')
					setCheckedQues22Unsecure('')
				}
			}
			setCheckedQues22Val(ans)
		}

		if (param === '23') {
			if (ans === 'yes') {
				setCheckedQues23Yes('checked')
				setCheckedQues23No('')
				setCheckedQues23Unsecure('')
			}else if (ans === 'unsecure') {
				setCheckedQues23Unsecure('checked')
				setCheckedQues23Yes('')
				setCheckedQues23No('')
			} else {
				if (ans === 'no') {
					setCheckedQues23No('checked')
					setCheckedQues23Yes('')
					setCheckedQues23Unsecure('')
				}
			}
			setCheckedQues23Val(ans)
		}

		if (param === '26') {
			if (ans === 'yes') {
				setCheckedQues26Yes('checked')
				setCheckedQues26No('')
				setCheckedQues26Unsecure('')
			}else if (ans === 'unsecure') {
					setCheckedQues26Unsecure('checked')
					setCheckedQues26Yes('')
					setCheckedQues26No('')
			} else {
				if (ans === 'no') {
					setCheckedQues26No('checked')
					setCheckedQues26Yes('')
					setCheckedQues26Unsecure('')
				}
			}
			setCheckedQues26Val(ans)
		}








	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues19Val !== '' && checkedQues20Val !== '' && checkedQues21Val !== '' && checkedQues22Val !== '' && checkedQues23Val !== ''
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};

	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3 mt-m-3">


								<div
									className="col-lg-9 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3">{t('engage')}  </h3>
									<p className="mb-2  mt-3">{t('M3chapter03Screen03Text1')} </p>
									<p className="mb-2">{t('M3chapter03Screen03Text2')} </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="one" id="circle1"
												onClick={saveRadio}
												defaultValue="yes"
												data-quesId="19"
												checked={checkedQues19Yes}
											/>
											<label className="form-check-label" htmlFor="circle1">{t('yes')}</label>
										</div>
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="one" id="circle2"
												onClick={saveRadio}
												defaultValue="no"
												data-quesId="19"
												checked={checkedQues19No}

											/>
											<label className="form-check-label " htmlFor="circle2">{t('no')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="one" id="circle2"
												onClick={saveRadio}
												defaultValue="unsecure"
												data-quesId="19"
												checked={checkedQues19Unsecure}

											/>
											<label className="form-check-label " htmlFor="circle2">{t('unsure')}</label>
										</div>




									</div>

									<p className="mb-2">{t('M3chapter03Screen03Text3')}  </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="two" id="circle3"
												onClick={saveRadio}
												defaultValue="yes"
												data-quesId="20"
												checked={checkedQues20Yes}
											/>
											<label className="form-check-label" htmlFor="circle3">{t('yes')}</label>
										</div>
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="two" id="circle4"
												onClick={saveRadio}
												defaultValue="no"
												data-quesId="20"
												checked={checkedQues20No}
											/>
											<label className="form-check-label " htmlFor="circle4">{t('no')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="two" id="circle4"
												onClick={saveRadio}
												defaultValue="unsecure"
												data-quesId="20"
												checked={checkedQues20Unsecure}
											/>
											<label className="form-check-label " htmlFor="circle4">{t('unsure')}</label>
										</div>




									</div>

									<p className="mb-2">{t('M3chapter03Screen03Text4')}  </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="third" id="circle5"
												onClick={saveRadio}
												defaultValue="yes"
												data-quesId="21"
												checked={checkedQues21Yes}

											/>
											<label className="form-check-label" htmlFor="circle5">{t('yes')}</label>
										</div>
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="third" id="circle6"
												onClick={saveRadio}
												defaultValue="no"
												data-quesId="21"
												checked={checkedQues21No}
											/>
											<label className="form-check-label " htmlFor="circle6">{t('no')}</label>
										</div>


										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="third" id="circle6"
												onClick={saveRadio}
												defaultValue="unsecure"
												data-quesId="21"
												checked={checkedQues21Unsecure}
											/>
											<label className="form-check-label " htmlFor="circle6">{t('unsure')}</label>
										</div>



									</div>

									<p className="mb-2">{t('M3chapter03Screen03Text5')}  </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="four" id="circle7"
												onClick={saveRadio}
												defaultValue="yes"
												data-quesId="22"
												checked={checkedQues22Yes}
											/>
											<label className="form-check-label" htmlFor="circle7">{t('yes')}</label>
										</div>
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="four" id="circle8"
												onClick={saveRadio}
												defaultValue="no"
												data-quesId="22"
												checked={checkedQues22No}
											/>
											<label className="form-check-label " htmlFor="circle8">{t('no')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="third" id="circle6"
												onClick={saveRadio}
												defaultValue="unsecure"
												data-quesId="22"
												checked={checkedQues21Unsecure}
											/>
											<label className="form-check-label " htmlFor="circle6">{t('unsure')}</label>
										</div>

									</div>

									<p className="mb-2">{t('M3chapter03Screen03Text6')}  </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="fives" id="circle9"
												onClick={saveRadio}
												defaultValue="yes"
												data-quesId="23"
												checked={checkedQues23Yes}
											/>
											<label className="form-check-label" htmlFor="circle9">{t('yes')}</label>
										</div>
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="fives" id="circle10"
												onClick={saveRadio}
												defaultValue="no"
												data-quesId="23"
												checked={checkedQues23No}
											/>
											<label className="form-check-label " htmlFor="circle10">{t('no')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="third" id="circle6"
												onClick={saveRadio}
												defaultValue="unsecure"
												data-quesId="23"
												checked={checkedQues23Unsecure}
											/>
											<label className="form-check-label " htmlFor="circle6">{t('unsure')}</label>
										</div>

									</div>


									<p className="mb-2">{t('M3chapter03Screen03Text7')}  </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="six" id="circle911"
												onClick={saveRadio}
												defaultValue="yes"
												data-quesId="26"
												checked={checkedQues26Yes}
											/>
											<label className="form-check-label" htmlFor="circle9">{t('yes')}</label>
										</div>
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="seven" id="circle1011"
												onClick={saveRadio}
												defaultValue="no"
												data-quesId="26"
												checked={checkedQues26No}
											/>
											<label className="form-check-label " htmlFor="circle10">{t('no')}</label>
										</div>

										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio" name="eight" id="circle111"
												onClick={saveRadio}
												defaultValue="unsecure"
												data-quesId="26"
												checked={checkedQues26Unsecure}
											/>
											<label className="form-check-label " htmlFor="circle6">{t('unsure')}</label>
										</div>

									</div>




								</div>


								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="65%"
											draggable="false"
											src="/assets/img/chapter1/reading-book.png"
											alt=""
										/>
									</center>
								</div>




							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-03-screen-02n" />
								{/* <NextBtn link="/module-03/chapter-03-screen-04" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen03;
