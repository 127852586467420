import React, { useState, useEffect } from "react";

import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";

import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen11 = () => {

 const { t, i18n } = useTranslation();
  return (
    <>
      <div className="wrapper page-auto md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="1/4" percentage="25" star="2" />
          </div>
        </div>
        <div className="d-flex">
				<Sidebar/>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h4 className="h3 mb-2 mt-3"><b>{t('Summary')}</b></h4>
              </div>
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <ul className="without-list list-style-circle ml-1 mt-1">
                  <li>{t('M5chapter03Screen11Text1')} </li>
                  <li>{t('M5chapter03Screen11Text2')}</li>
                  <li>{t('M5chapter03Screen11Text3')}</li>
                  <li>{t('M5chapter03Screen11Text4')}</li>

                  <li>{t('M5chapter03Screen11Text5')}</li>
                  <li>{t('M5chapter03Screen11Text6')}</li>
                  <li>{t('M5chapter03Screen11Text7')}</li>

                  <li>{t('M5chapter03Screen11Text8')}</li>
                  <li>{t('M5chapter03Screen11Text9')} </li>


                  <li>{t('M5chapter03Screen11Text10')}</li>
                  <li>{t('M5chapter03Screen11Text11')}  </li>

                </ul>

              </div>



            </div>
          </div>
        </div>

        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                  <PrevBtn link="/module-05/chapter-03-screen-10" />
                  <NextBtn link="/module-05/chapter-03-screen-11n" text={t('CommonBtnGoNext')} />
                  {/* <BtnDisabled/> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen11;
