import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen13 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2 hp">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items- justify-content-between pt-20 mt-4 mt-m-3">
								<div
									className="col-lg-6 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<p className="mb-2  mt-3">{t('M3chapter02Screen13Text1')} </p>
									<p>{t('M3chapter02Screen13Text2')}</p>

									<h3 className="h3 mt-3">{t('M3chapter02Screen13Text3')}</h3>
									<ul class="list-style-set list-circle mt-1">
										<li>{t('M3chapter02Screen13Text4')}</li>
										<li>{t('M3chapter02Screen13Text5')}  </li>
									</ul>


								</div>

								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="65%"
											draggable="false"
											src="/assets/img/chapter1/reading-book.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-02-screen-12" />
								<NextBtn link="/module-03/chapter-02-screen-14" text={t('CommonBtnGoNext')} />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen13;
