import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen03 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3">{t('learn')}  </h3>
									<p className="mb-2  mt-3"> {t('M3chapter02Screen03Text1')}</p>
									<p> {t('M3chapter02Screen03Text2')}    </p>
									<p className="mb-2  mt-3">{t('M3chapter02Screen03Text3')}  </p>


									<a href="https://www.youtube.com/watch?v=MfYSpuKq_-8" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p> <b>{t('M3chapter02Screen03Text4')} </b> </p>
											</div>
										</div>
									</a>


									<a href="https://www.youtube.com/watch?v=Ue41QYld3dU" target="_blank">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p> <b>{t('M3chapter02Screen03Text5')} </b> </p>
											</div>
										</div>
									</a>

									<a href="https://drive.google.com/file/d/1D4PSh3nZT96J-lfXgAVQpEiax21OADVp/view?usp=sharing " target="_blank">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>  {t('M3chapter02Screen03Text5')}  </p>
											</div>
										</div>
									</a>





									<a href="https://drive.google.com/file/d/1D4PSh3nZT96J-lfXgAVQpEiax21OADVp/view?usp=sharing " target="_blank">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>  {t('M3chapter02Screen03Text6')}  </p>
											</div>
										</div>
									</a>




								</div>

								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="90%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-2-3.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-02-screen-02n" />
								<NextBtn link="/module-03/chapter-02-screen-03n" text="Go Next"/>

								{/* <button className="button_su" >
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop" className="button_su_inner"><span className="button_text_container"> {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" /></span> </NavLink>
								</button> */}


							</div>
						</div>
					</div>
				</div>
			</div>


			<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							<p className="text-white text-left">{t('M3chapter02Screen03Model1')}    <a href="https://pronouns.org" className="text-white" target="_blank">https://pronouns.org/ </a>
							</p>

							<div class=" d-flex justify-content-center mt-3">
								<button className="button_su" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="/module-03/chapter-02-screen-04" className="button_su_inner"><span className="button_text_container"> {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>




						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter02Screen03;
