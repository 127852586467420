import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";

import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen06 = () => {

     const { t, i18n } = useTranslation();
  return (
    <>
      <div className="wrapper md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="1/4" percentage="25" star="2" />
          </div>
        </div>
        <div className="d-flex">
				<Sidebar/>
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-2">

              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h3>{t('learn')}</h3>
               <p className="mb-2 mt-3">{t('M5chapter03Screen6Text1')}</p>
               <p className="mb-2 mt-3">{t('M5chapter03Screen6Text2')} </p>
               <p className="mb-2 mt-3">{t('M5chapter03Screen6Text3')} </p>
               <p className="mb-2 mt-3">{t('M5chapter03Screen6Text4')}</p>
               <p className="mb-2 mt-3">{t('M5chapter03Screen6Text5')} </p>
               <p className="mb-2 mt-3">{t('M5chapter03Screen6Text6')} </p>


              </div>
            </div>
          </div>
        </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-05/chapter-03-screen-03"/>
                <NextBtn link="/module-05/chapter-03-screen-07" text={t('CommonBtnGoNext')}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen06;
