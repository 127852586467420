import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter03Screen04 = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="wrapper">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="2/5" percentage="50" star="30" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-center pt-20 mt-3">
                <div className="col-lg-12">
                  <h3 className="h3">{t('learn')}</h3>
                  <p className="mt-3">{t('M2chapter03screen04Text1')} </p>
                </div>
                <div className="col-lg-6">
                  <div className="para-color-box">
                    <h3 className="h3"> {t('men')}</h3>
                    <ul className="without-list">
                      <li> {t('power')}</li>
                      <li> {t('privileges')}</li>
                      <li>{t('M2chapter03screen04Text2')}</li>
                      <li>{t('M2chapter03screen04Text3')} </li>
                      <li>{t('M2chapter03screen04Text4')}</li>
                      <li>{t('M2chapter03screen04Text5')} </li>
                      <li>{t('M2chapter03screen04Text6')} </li>
                      <li>{t('M2chapter03screen04Text7')}</li>
                      <li>{t('M2chapter03screen04Text8')} </li>
                      <li>{t('M2chapter03screen04Text9')} </li>
                      <li>{t('M2chapter03screen04Text10')}</li>
                      <li>{t('M2chapter03screen04Text11')}</li>
                      <li>
                        {t('M2chapter03screen04Text12')}
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="para-color-box textAllignRight" >
                    <h3 className="h3 mt-m-1">{t('women')} </h3>
                    <ul className="without-list">
                      <li>{t('M2chapter03screen04Text13')}</li>
                      <li>{t('M2chapter03screen04Text14')}</li>
                      <li>{t('M2chapter03screen04Text15')}</li>
                      <li>{t('M2chapter03screen04Text16')}</li>
                      <li>
                        {t('M2chapter03screen04Text17')}
                      </li>
                      <li>
                      {t('M2chapter03screen04Text18')}
                      </li>
                      <li>   {t('M2chapter03screen04Text19')}</li>
                      <li>{t('M2chapter03screen04Text20')}
  </li>
                      <li> {t('M2chapter03screen04Text21')}     </li>
                      <li>
                      {t('M2chapter03screen04Text22')}
                      </li>
                      <li>{t('M2chapter03screen04Text23')}</li>
                      <li>{t('M2chapter03screen04Text24')} </li>
                      <li>{t('M2chapter03screen04Text25')}</li>
                      <li>{t('M2chapter03screen04Text26')}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-02/chapter-03-screen-03n5" />
                <NextBtn
                  link="/module-02/chapter-03-screen-05"
                  text={t('CommonBtnGoNext')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen04;
