import React from "react";
import { useDrag } from "react-dnd";
import { NavLink } from 'react-router-dom';
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

function Chapter02Screen015_1({ id, url,imageName }) {
  const { t, i18n } = useTranslation();
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "image",
    item: { id: id },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
   <>
        <li
        ref={drag}
           draggable="false"
         
            id={id}
          
            // style={{ border: isDragging ? "5px solid pink" : "0px" }}
            
        >{imageName}</li>
   </>
  );
}

export default Chapter02Screen015_1;
