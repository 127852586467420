import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen32 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-center pt-20 mt-2">


								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3 mt-3">{t('engage')}</h3>
									<p className="mb-2 mt-3">{t('M3chapter04Screen32Text1')}  </p>
								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
									style={{margin:"10px"}}

								>


									<p className="mb-2 mt-3 text-center pn"> <span> <b>1.</b></span> {t('M3chapter04Screen32Text2')}  </p>
									<center>
										<img
											width="100%"
											draggable="false"
											src="/assets/img/md5/chap02-4/w1.jpg"
											alt=""

										/>
									</center>
								</div>


								{/* <div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mb-2 mt-3 text-center pn"> <span> <b>2.</b></span>{t('M3chapter04Screen32Text3')}  </p>
									<center>
										<img
											width="70%"
											draggable="false"
											src="/assets/img/md5/chap02-4/w2.jpg"
											alt=""
										/>
									</center>
								</div> */}


								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
									style={{marginLeft:"200px"}}
								>
									<p className="mb-2 mt-3 text-center pn"> <span> <b>2.</b></span> {t('M3chapter04Screen32Text4')}  </p>
									<center>
										<img
											width="70%"
											draggable="false"
											src="/assets/img/md5/chap02-4/w3.jpg"
											alt=""
										/>
									</center>
								</div>




							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-31" />
								<NextBtn link="/module-03/chapter-04-screen-33" text={t('CommonBtnGoNext')} />

							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter04Screen32;
