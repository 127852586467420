import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen08 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [checkedQues14, setCheckedQues14] = useState();
  const [checkedQues14_1, setCheckedQues14_1] = useState();
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": "",
          "ques_id": "10"
        },
        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        },
        "ques13": {
          "ans": "",
          "ques_id": "13"
        },
        "ques14": {
          "ans": checkedQues14+"-"+checkedQues14_1,
          "ques_id": "14"
        }
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/GenderChapter01',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-02/chapter-01-screen-09')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const checkedVal = (param,ans) => {
    if (param === '14') {
      var ___ans = ans.split('-')
      setCheckedQues14(___ans[0])
      setCheckedQues14_1(___ans[1])
    }
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/GenderChapter01Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])
  const BtnDisabled = () => {
    if (checkedQues14 !== '' && checkedQues14_1 !==''  ) {
      return (
        <>
          <button
            className="button_su"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    }else if (checkedQues14 === ''  ) {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  return (
    <>
      <div className="wrapper hp">
        <div
          className="fixed-header"
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="0/5" percentage="7" star="7" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-center pt-20 mt-3">
                <div
                  className="col-lg-12  wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >

                  <p> {t('M2chapter01screen08Text1')}</p>
                  <p>
                    {t('M2chapter01screen08Text2')}
                  </p>
                  <button className="button_su" >
                    <span className="su_button_circle"></span>
                    <a
                      href=""
                      className="button_su_inner"
                      data-bs-toggle="modal" data-bs-target="#staticBackdrop"
                    >
                      <span className="">
                        {" "}
                        {t('glossary')}
                      </span>{" "}
                    </a>
                  </button>

                  <h2 className="h2">
                  {t('M2chapter01screen08Text3')}

                  </h2>



                  <p>
                   {t('M2chapter01screen08Text4')}&nbsp;&nbsp;{" "}
                  </p>
                  <div className="mb-3 positon-submit">
                    <textarea
                      className="form-control"
                      rows={3}
                      placeholder="Misconceptions that were challenged or broken"
                      onChange={(e)=>setCheckedQues14(e.target.value)}
                      data-quesId="14"
                      defaultValue={checkedQues14}
                    />


                    {/* <button
                      type="button"
                      className="btn btn-primary custom-btn m-auto submit-btn-set"
                    >
                      Submit
                    </button> */}
                  </div>

                  <div className="mb-3 positon-submit">
                    <textarea
                      className="form-control"
                      rows={3}
                      placeholder="Learning and how you will apply them"
                      onChange={(e)=>setCheckedQues14_1(e.target.value)}
                      data-quesId="14"
                      defaultValue={checkedQues14_1}
                    />


                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
              <PrevBtn link="/module-02/chapter-01-screen-07"/>
                {/* <NextBtn link="/module-01/chapter-01-screen-09" text="Go Next" classtype=""/> */}
                <BtnDisabled/>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

              <p className="text-white text-text-left"><span className="text-bg-danger"><b>Sex: </b></span>is assigned at birth based on the biological characteristics- sexual and reproductive anatomy that we are born with. This is usually assigned in binary- male or female. When someone’s sexual and reproductive anatomy doesn’t seem to fit the binary of female or male, they may be described as intersex.</p>
              <p className="text-white text-text-left"><span className="text-bg-danger"><b>Gender:</b></span>  is socially constructed on the basis of sex. It is a learnt behaviour, thought process, role expectation that can change and varies based on society, culture and time periods. It is a process of socialization and refers to the ways in which individuals are raised and encouraged to behave, act and dress in order to be categorized as “men” and “women”. Although there are more than two genders, most people know of three, namely man, woman and transgender. </p>
              <p className="text-white text-text-left"><span className="text-bg-danger"><b>Gender Identity: </b> </span> Gender identity is how one feels inside which could lead to what they do and do not identify as- this does not have to be in binary like sex assigned at birth. Many individuals may not necessarily identify as the sex and gender they were assigned at birth or/and in binary. Such individuals identify as <b>transgender, gender non-binary, gender fluid or gender queer. </b> Individuals who identify with the sex and gender assigned at birth are called <b> cisgender.</b> </p>

              <p className="text-white text-text-left"><span className="text-bg-danger"><b>Intersex:</b>  </span> Intersex is a general term used for a variety of situations in which a person is born with reproductive or sexual anatomy that doesn’t fit the boxes of “female” or “male.” Sometimes doctors do surgeries on intersex babies and children to make their bodies fit binary ideas of “male” or “female”. Doctors always assign intersex babies a legal sex (male or female, in most states), but, just like with non-intersex people, that doesn’t mean that’s the gender identity they’ll grow up to have. </p>
              <p className="text-white text-text-left"><span className="text-bg-danger"><b> Transgender*:</b> </span>  Transgender is a term that includes the many ways that people’s gender identities can be different from the gender they were assigned at birth. There are a lot of different terms transgender people use to describe themselves. For example, sometimes the word transgender is shortened to just trans, trans*, or trans male/trans female. It’s always best to use the language and labels that the person prefers.</p>
              <p className="text-white text-text-left"><b>OR</b> </p>
              <p className="text-white text-text-left">This term refers to all persons whose own sense of their gender does not match the gender assigned to them at birth. Spelt with an asterisk in this way, trans* is an umbrella term coined within gender studies in order to refer to all non-cisgender gender identities including transsexual, transvestite, genderqueer, genderfluid, genderless, agender, non-gendered, third gender, two-spirit, bigender, MTF (male-to-female), FTM (female-to-male), transman, transwoman, and (m)any others.</p>
              <p className="text-white text-text-left"><span className="text-bg-danger"><b>Cisgender*:</b></span>  A cisgender person is someone whose own sense of gender matches the gender assigned to her, or to him, at birth. Thus a cisman is a person assigned gender male at birth who identifies as man, and a ciswoman is a person assigned gender female at birth who identifies as woman. To be cisgender, then, is to enjoy cisgender privilege, which a trans* person lacks in a world based on the gender binary.</p>
              <p className="text-white text-text-left"><b>*taken from a study by Labia called- Breaking the Binary</b>  </p>






            </div>
          </div>
        </div>
      </div>


    </>
  );
};
export default Chapter01Screen08;
