import React, { useState,useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import BaseURL from "../../../config/Baseurl";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';


const Chapter03Screen03 = () => {

  const navigate = useNavigate();
  const [classValue,setClassValue] = useState([]);
  const [casteValue,setCasteValue] = useState([]);
  const [abilityValue,setAbilityValue] = useState([]);
  const [placeValue,setPlaceValue] = useState([]);
  const [languageValue,setLanguageValue] = useState([]);
  const [religionValue,setReligionValue] = useState([]);
  const [sexValue,setSexValue] = useState([]);
  const [genderValue,setGenderValue] = useState([]);
  const [identityValue,setIdentityValue] = useState([]);
  const [educationValue,setEducationValue] = useState([]);
  const [technologyValue,setTechnologyValue] = useState([]);
  const [motherTongueValue,setMotherTongueValue] = useState([]);
  const [totalNumberValue,setTotalNumberValue] = useState([]);
  const user_details = JSON.parse(localStorage.getItem('user_details'));

   const { t, i18n } = useTranslation();

  const [Avalue,setAValue] = useState([]);
  const [Bvalue,setBValue] = useState([]);
  const [Cvalue,setCValue] = useState([]);
  const [Dvalue,setDValue] = useState([]);

  const checkboxClick = (e)=>{
      var value = e.target.value;
      var isChecked = e.target.checked;
      const nameVal = e.target.getAttribute("name");
      // alert(nameVal);

      if(nameVal === 'A')
      {
        if(isChecked === true)
        {
          setAValue(Avalue=>[...Avalue,value]);
          setClassValue(classValue=>[...classValue,value]);
          const checkeitem = "checked";
          checkVal(value,checkeitem);


        }else if(isChecked === false){

          setAValue((AValue) =>
          AValue.filter((j) => {
              return j !== value;
            })
          );

          setClassValue((classValue) =>
          classValue.filter((j) => {
              return j !== value;
            })
          );

          const checkeitem = "";
          checkVal(value,checkeitem);
        }
      }

      if(nameVal === 'B')
      {


        if(isChecked === true)
        {
          setBValue(Bvalue=>[...Bvalue,value]);
          setCasteValue(casteValue=>[...casteValue,value]);
          const checkeitem = "checked";
          checkVal(value,checkeitem);


        }else if(isChecked === false){
          setBValue((BValue) =>
          BValue.filter((j) => {
              return j !== value;
            })
          );

          setCasteValue((casteValue) =>
          casteValue.filter((j) => {
              return j !== value;
            })
          );

          const checkeitem = "";
          checkVal(value,checkeitem);
        }

      }

      if(nameVal === 'C')
      {

        if(isChecked === true)
        {
          setCValue(Cvalue=>[...Cvalue,value]);
          setAbilityValue(abilityValue=>[...abilityValue,value]);
          const checkeitem = "checked";
          checkVal(value,checkeitem);


        }else if(isChecked === false){

          setCValue((CValue) =>
          CValue.filter((j) => {
              return j !== value;
            })
          );

          setAbilityValue((abilityValue) =>
          abilityValue.filter((j) => {
              return j !== value;
            })
          );

          const checkeitem = "";
          checkVal(value,checkeitem);
        }

      }

      if(nameVal === 'D')
      {
        // setPlaceValue(placeValue=>[...placeValue,value]);

        if(isChecked === true)
        {
          setDValue(Dvalue=>[...Dvalue,value]);
          setPlaceValue(placeValue=>[...placeValue,value]);
          const checkeitem = "checked";
          checkVal(value,checkeitem);


        }else if(isChecked === false){

          setDValue((DValue) =>
          DValue.filter((j) => {
              return j !== value;
            })
          );

          setPlaceValue((placeValue) =>
          placeValue.filter((j) => {
              return j !== value;
            })
          );

          const checkeitem = "";
          checkVal(value,checkeitem);
        }
      }




  }





  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques9": {
          "ans":  Avalue.join(','),
          "ans2":  Bvalue.join(','),
          "ans3":  Cvalue.join(','),
          "ans4":  Dvalue.join(','),


          "ques_id": "9"
        },

      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/HumanRightsChapter03',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-05/chapter-03-screen-06')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": "9"
    });
    var config = {
      method: 'post',
      url: BaseURL + '/HumanRightsChapter03Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {

        // console.log(response);
        if (response.data.success === true) {
          const classes = response.data.data.chapter.ans; //class
          const caste = response.data.data.chapter.ans2; //caste
          const ability = response.data.data.chapter.ans3; //ability
          const place = response.data.data.chapter.ans4; //place
          const language = response.data.data.chapter.ans5; //language
          const religion = response.data.data.chapter.ans6; //religion
          const sex = response.data.data.chapter.ans7; //sex
          const gender = response.data.data.chapter.ans8; //gender
          const identity = response.data.data.chapter.ans9; //identity
          const education = response.data.data.chapter.ans10; //education
          const technology = response.data.data.chapter.ans11; //technology
          const mother_tongue = response.data.data.chapter.ans12; //mother_tongue

         const ___class =  classes.split(',');

         const checkeitem = "checked";
         ___class.map((value)=>{
          checkVal(value,checkeitem);
          setClassValue(classValue=>[...classValue,value]);
          setAValue(Avalue=>[...Avalue,value]);
         })

         const ___caste =  caste.split(',');
         ___caste.map((value,)=>{
          checkVal(value,checkeitem);
          setCasteValue(casteValue=>[...casteValue,value]);
          setBValue(Bvalue=>[...Bvalue,value]);
         })
         const ___ability =  ability.split(',');
         ___ability.map((value)=>{
          checkVal(value,checkeitem);
          setAbilityValue(abilityValue=>[...abilityValue,value]);
          setCValue(Cvalue=>[...Cvalue,value]);
         })
         const ___place =  place.split(',');
         ___place.map((value)=>{
          checkVal(value,checkeitem);
          setPlaceValue(placeValue=>[...placeValue,value]);
          setDValue(Dvalue=>[...Dvalue,value]);
         })
         const ___language =  language.split(',');
         ___language.map((value)=>{
          checkVal(value,checkeitem);
          setLanguageValue(languageValue=>[...languageValue,value]);
         })
         const ___religion =  religion.split(',');
         ___religion.map((value)=>{
          checkVal(value,checkeitem);
          setReligionValue(religionValue=>[...religionValue,value]);
         })
         const ___sex =  sex.split(',');
         ___sex.map((value)=>{
          checkVal(value,checkeitem);
          setSexValue(sexValue=>[...sexValue,value]);
         })
         const ___gender =  gender.split(',');
         ___gender.map((value)=>{
          checkVal(value,checkeitem);
          setGenderValue(genderValue=>[...genderValue,value]);
         })
         const ___identity =  identity.split(',');
         ___identity.map((value)=>{
          checkVal(value,checkeitem);
          setIdentityValue(identityValue=>[...identityValue,value]);
         })
         const ___education =  education.split(',');
         ___education.map((value)=>{
          checkVal(value,checkeitem);
          setEducationValue(educationValue=>[...educationValue,value]);
         })

         const ___technology =  technology.split(',');
         ___technology.map((value)=>{
          checkVal(value,checkeitem);
          setTechnologyValue(technologyValue=>[...technologyValue,value]);
         })

         const ___mother_tongue =  mother_tongue.split(',');
         ___mother_tongue.map((value)=>{
          checkVal(value,checkeitem);
          setMotherTongueValue(motherTongueValue=>[...motherTongueValue,value]);
         })





        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);


  const [richChecked,setRichChecked] = useState('');
  const [middleClassChecked,setMiddleClassChecked] = useState('');
  const [poorChecked,setPoorChecked] = useState('');
  const [veryPoorChecked,setVeryPoorChecked] = useState('');
  const [brahminsChecked,setBrahminsChecked] = useState('');
  const [kashtriyaChecked,setKashtriyaChecked] = useState('');
  const [shudraChecked,setShudraChecked] = useState('');
  const [dalitsChecked,setDalitsChecked] = useState('');
  const [healthProblemsChecked,setHealthProblemsChecked] = useState('');
  const [minorPhysicalChecked,setMinorPhysicalChecked] = useState('');
  const [minorMentalChecked,setMinorMentalChecked] = useState('');
  const [majorPhysicalChecked,setMajorPhysicalChecked] = useState('');
  const [cityChecked,setCityChecked] = useState('');
  const [townChecked,setTownChecked] = useState('');
  const [miniMetroChecked,setMiniMetroChecked] = useState('');
  const [metroCityChecked,setMetroCityChecked] = useState('');
  const [englishChecked,setEnglishChecked] = useState('');
  const [hindiChecked,setHindiChecked] = useState('');
  const [villagesChecked,setVillagesChecked] = useState('');
  const [nomadsChecked,setNomadsChecked] = useState('');
  const [religionFollowedByMajorityPopulationChecked,setReligionFollowedChecked] = useState('');
  const [anotherDominantReligionChecked,setAnotherDominantReligionChecked] = useState('');
  const [otherReligionChecked,setOtherReligionChecked] = useState('');
  const [atheistChecked,setAtheistChecked] = useState('');
  const [maleChecked,setMaleChecked] = useState('');
  const [femaleChecked,setFemaleChecked] = useState('');
  const [transSexualChecked,setTransSexualChecked] = useState('');
  const [intersexChecked,setIntersexChecked] = useState('');
  const [cismanChecked,setCismanChecked] = useState('');
  const [ciswomanChecked,setCiswomanChecked] = useState('');
  const [transgenderChecked,setTransGenderChecked] = useState('');
  const [nonBinaryChecked,setNonBinaryChecked] = useState('');
  const [heterosexualChecked,setHeterosexualChecked] = useState('');
  const [biSexualChecked,setBiSexualChecked] = useState('');
  const [homosexualChecked,setHomeSexualChecked] = useState('');
  const [allOtherIdentitiesChecked,setAllOtherIdentitiesChecked] = useState('');
  const [foreignDegreeChecked,setForeignDegreeChecked] = useState('');
  const [graduateChecked,setGraduateChecked] = useState('');
  const [primaryEducationChecked,setPrimaryEducationChecked] = useState('');
  const [illiterateChecked,setIlliterateChecked] = useState('');
  const [highSpeedFullTimeInternetConnectionChecked,setHighSpeedFullTimeInternetConnectionChecked] = useState('');
  const [slowInternetConnectionChecked,setSlowInternetConnectionChecked] = useState('');
  const [cyberCafeChecked,setCyberCafeChecked] = useState('');
  const [noInternetConnectivityChecked,setNoInternetConnectivityChecked] = useState('');
  const [vernacularChecked,setVernacularChecked] = useState('');
  const [localdialectChecked,setLocaldialectChecked] = useState('');
  const [English1Checked,setEnglish1Checked] = useState('');
  const [Hindi1Checked,setHindi1Checked] = useState('');


  const checkVal = (val,checkeitem)=>{
      if(val === 'rich')
      {
        setRichChecked(checkeitem);
      }
      if(val === 'middle class')
      {
        setMiddleClassChecked(checkeitem);
      }

      if(val === 'poor')
      {
        setPoorChecked(checkeitem);
      }

      if(val === 'very poor')
      {
        setVeryPoorChecked(checkeitem);
      }

      if(val === 'brahmins')
      {
        setBrahminsChecked(checkeitem);
      }

      if(val === 'kashtriya')
      {
        setKashtriyaChecked(checkeitem);
      }

      if(val === 'shudra')
      {
        setShudraChecked(checkeitem);
      }

      if(val === 'dalits')
      {
        setDalitsChecked(checkeitem);
      }

      if(val === 'health problems')
      {
        setHealthProblemsChecked(checkeitem);
      }

      if(val === 'minor physical')
      {
        setMinorPhysicalChecked(checkeitem);
      }
      if(val === 'minor mental')
      {
        setMinorMentalChecked(checkeitem);
      }

      if(val === 'major physical')
      {
        setMajorPhysicalChecked(checkeitem);
      }

      if(val === 'city')
      {
        setCityChecked(checkeitem);
      }

      if(val === 'town')
      {
        setTownChecked(checkeitem);
      }

      if(val === 'mini metro')
      {
        setMiniMetroChecked(checkeitem);
      }

      if(val === 'metro city')
      {
        setMetroCityChecked(checkeitem);
      }

      if(val === 'english')
      {
        setEnglishChecked(checkeitem);
      }

      if(val === 'hindi')
      {
        setHindiChecked(checkeitem);
      }

      if(val === 'villages')
      {
        setVillagesChecked(checkeitem);
      }

      if(val === 'nomads')
      {
        setNomadsChecked(checkeitem);
      }

      if(val === 'religion followed by majority population')
      {
        setReligionFollowedChecked(checkeitem);
      }

      if(val === 'another dominant religion')
      {
        setAnotherDominantReligionChecked(checkeitem);
      }

      if(val === 'other religion')
      {
        setOtherReligionChecked(checkeitem);
      }

      if(val === 'atheist')
      {
        setAtheistChecked(checkeitem);
      }

      if(val === 'male')
      {
        setMaleChecked(checkeitem);
      }
      if(val === 'female')
      {
        setFemaleChecked(checkeitem);
      }
      if(val === 'trans sexual')
      {
        setTransSexualChecked(checkeitem);
      }
      if(val === 'intersex')
      {
        setIntersexChecked(checkeitem);
      }
      if(val === 'cisman')
      {
        setCismanChecked(checkeitem);
      }
      if(val === 'ciswoman')
      {
        setCiswomanChecked(checkeitem);
      }
      if(val === 'transgender')
      {
        setTransGenderChecked(checkeitem);
      }

      if(val === 'non-binary')
      {
        setNonBinaryChecked(checkeitem);
      }

      if(val === 'heterosexual')
      {
        setHeterosexualChecked(checkeitem);
      }
      if(val === 'bi-sexual')
      {
        setBiSexualChecked(checkeitem);
      }
      if(val === 'homosexual')
      {
        setHomeSexualChecked(checkeitem);
      }
      if(val === 'all other identities')
      {
        setAllOtherIdentitiesChecked(checkeitem);
      }
      if(val === 'foreign degree')
      {
        setForeignDegreeChecked(checkeitem);
      }
      if(val === 'graduate')
      {
        setGraduateChecked(checkeitem);
      }
      if(val === 'primary education')
      {
        setPrimaryEducationChecked(checkeitem);
      }

      if(val === 'illiterate')
      {
        setIlliterateChecked(checkeitem);
      }

      if(val === 'high speed full time internet connection')
      {
        setHighSpeedFullTimeInternetConnectionChecked(checkeitem);
      }
      if(val === 'slow internet connections')
      {
        setSlowInternetConnectionChecked(checkeitem);
      }
      if(val === 'cyber cafe')
      {
        setCyberCafeChecked(checkeitem);
      }
      if(val === 'no internet connectivity')
      {
        setNoInternetConnectivityChecked(checkeitem);
      }
      if(val === 'english1')
      {
        setEnglish1Checked(checkeitem);
      }
      if(val === 'hindi1')
      {
        setHindi1Checked(checkeitem);
      }
      if(val === 'vernacular')
      {
        setVernacularChecked(checkeitem);
      }
      if(val === 'local dialect')
      {
        setLocaldialectChecked(checkeitem);
      }





  }

  console.log(Dvalue.length);

  const BtnDisabled = () => {
    // if (Avalue.length > 0 || Bvalue.length || 0 && Cvalue.length > 0 || Dvalue.length > 0 ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    // } else {
    //   return (
    //     <>
    //       <button className="button_su disabled-btn" type="button" disabled="">
    //         <span className="su_button_circle"></span>
    //         <span className="button_su_inner">
    //           <span className="button_text_container">
    //             {" "}
    //             {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
    //           </span>{" "}
    //         </span>
    //       </button>
    //     </>
    //   );
    // }
  };


  return (
    <>
      <div className="wrapper page-auto md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="1/4" percentage="25" star="2" />
          </div>
        </div>

        <div className="d-flex">
				<Sidebar/>

        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-2">
              <h2>{t('engage')}</h2>
              <h3>{t('M5chapter03Screen3Text1')}</h3>
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <h4 className="mb-2 h3 mt-3">  <b></b></h4>
                <p>{t('M5chapter03Screen3Text2')} </p>
                <p>{t('M5chapter03Screen3Text3')} </p>
              </div>
              <div
                className="col-lg-12 mb-7 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >

              <div className="table-responsive">
                <table className="table table-bordered table-chaeck table-dark table-red">
                  <tbody>
                    <tr>
                      <td>{t('M5chapter03Screen3Text4')}</td>
                      <td>{t('a')}</td>
                      <td>{t('b')}</td>
                      <td>{t('c')}</td>
                      <td>{t('d')}</td>
                    </tr>
                    <tr className="color-dark-red">
                      <td >{t('M5chapter03Screen3Text5')}</td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number1" name="A" onClick={checkboxClick} defaultValue="rich" data-quesId="9" checked={richChecked}/>
                            <label className="form-check-label" htmlFor="number1">{t('M5chapter03Screen3Text18')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number2" onClick={checkboxClick} name="B" defaultValue="middle class" data-quesId="9" checked={middleClassChecked}  />
                            <label className="form-check-label" htmlFor="number2">{t('M5chapter03Screen3Text19')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number3" onClick={checkboxClick} name="C" defaultValue="poor" data-quesId="9" checked={poorChecked}/>
                            <label className="form-check-label" htmlFor="number3">{t('M5chapter03Screen3Text20')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" onClick={checkboxClick} id="number4" name="D" defaultValue="very poor" data-quesId="9" checked={veryPoorChecked} />
                            <label className="form-check-label" htmlFor="number4">{t('M5chapter03Screen3Text21')}</label>
                          </div>
                        </div>
                      </td>

                    </tr>
                    <tr className=" color-dark-red2">
                      <td >{t('M5chapter03Screen3Text6')}</td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number5" onClick={checkboxClick} name="A" defaultValue="brahmins" data-quesId="9" checked={brahminsChecked} />
                            <label className="form-check-label" htmlFor="number5">{t('M5chapter03Screen3Text22')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number6" onClick={checkboxClick} defaultValue="kashtriya" name="B" data-quesId="9" checked={kashtriyaChecked} />
                            <label className="form-check-label" htmlFor="number6">{t('M5chapter03Screen3Text23')} </label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number7" onClick={checkboxClick} defaultValue="shudra" name="C" data-quesId="9" checked={shudraChecked} />
                            <label className="form-check-label" htmlFor="number7">{t('M5chapter03Screen3Text24')} </label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number8" onClick={checkboxClick} defaultValue="dalits" name="D" data-quesId="9" checked={dalitsChecked} />
                            <label className="form-check-label" htmlFor="number8">{t('M5chapter03Screen3Text25')}</label>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr className="color-dark-red">
                      <td >{t('M5chapter03Screen3Text7')}</td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number9" onClick={checkboxClick} defaultValue="health problems" name="A" data-quesId="9" checked={healthProblemsChecked}/>
                            <label className="form-check-label" htmlFor="number9">{t('M5chapter03Screen3Text26')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number10" onClick={checkboxClick} defaultValue="minor physical" name="B" data-quesId="9" checked={minorPhysicalChecked}/>
                            <label className="form-check-label" htmlFor="number10">{t('M5chapter03Screen3Text27')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number11" onClick={checkboxClick} defaultValue="minor mental" name="C" data-quesId="9" checked={minorMentalChecked}/>
                            <label className="form-check-label" htmlFor="number11">{t('M5chapter03Screen3Text28')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number12" onClick={checkboxClick} defaultValue="major physical" name="D" data-quesId="9"  checked={majorPhysicalChecked}/>
                            <label className="form-check-label" htmlFor="number12">{t('M5chapter03Screen3Text29')}</label>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className="color-dark-red2">
                      <td >{t('M5chapter03Screen3Text8')}</td>

                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number13" onClick={checkboxClick} defaultValue="city" name="A" data-quesId="9" checked={cityChecked}/>
                            <label className="form-check-label" htmlFor="number13">{t('M5chapter03Screen3Text30')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number14" onClick={checkboxClick} defaultValue="town" name="B" data-quesId="9" checked={townChecked}/>
                            <label className="form-check-label" htmlFor="number14">{t('M5chapter03Screen3Text31')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number15" onClick={checkboxClick} defaultValue="mini metro" name="C" data-quesId="9" checked={miniMetroChecked}/>
                            <label className="form-check-label" htmlFor="number15">{t('M5chapter03Screen3Text32')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number16" onClick={checkboxClick} defaultValue="metro city" name="D" data-quesId="9" checked={metroCityChecked}/>
                            <label className="form-check-label" htmlFor="number16">{t('M5chapter03Screen3Text33')}</label>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className="color-dark-red">
                    <td >{t('M5chapter03Screen3Text9')}</td>

                    <td>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input  className="form-check-input custom-check" type="checkbox" id="number17" onClick={checkboxClick} defaultValue="english" name="A" data-quesId="9" checked={englishChecked}/>
                          <label className="form-check-label" htmlFor="number17">{t('M5chapter03Screen3Text34')}</label>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input  className="form-check-input custom-check" type="checkbox" id="number18" onClick={checkboxClick} defaultValue="hindi" name="B" data-quesId="9" checked={hindiChecked} />
                          <label className="form-check-label" htmlFor="number18">{t('M5chapter03Screen3Text35')}</label>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input  className="form-check-input custom-check" type="checkbox" id="number19" onClick={checkboxClick} defaultValue="villages" name="C" data-quesId="9" checked={villagesChecked}/>
                          <label className="form-check-label" htmlFor="number19">{t('M5chapter03Screen3Text36')}</label>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input  className="form-check-input custom-check" type="checkbox" id="number20" onClick={checkboxClick} defaultValue="nomads" name="D" data-quesId="9" checked={nomadsChecked}/>
                          <label className="form-check-label" htmlFor="number16">{t('M5chapter03Screen3Text37')}</label>
                        </div>
                      </div>
                    </td>
                    </tr>


                    <tr className="color-dark-red2">
                      <td rowSpan="">{t('M5chapter03Screen3Text10')}</td>


                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number21" onClick={checkboxClick} defaultValue="religion followed by majority population" name="A" data-quesId="9" checked={religionFollowedByMajorityPopulationChecked} />
                            <label className="form-check-label" htmlFor="number21">{t('M5chapter03Screen3Text38')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number22" onClick={checkboxClick} defaultValue="another dominant religion" name="B" data-quesId="9" checked={anotherDominantReligionChecked}/>
                            <label className="form-check-label" htmlFor="number22">{t('M5chapter03Screen3Text39')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number23" onClick={checkboxClick} defaultValue="other religion" name="C" data-quesId="9" checked={otherReligionChecked} />
                            <label className="form-check-label" htmlFor="number23">{t('M5chapter03Screen3Text40')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number24" onClick={checkboxClick} defaultValue="atheist" name="D" data-quesId="9" checked={atheistChecked}/>
                            <label className="form-check-label" htmlFor="number24">{t('M5chapter03Screen3Text41')}</label>
                          </div>
                        </div>
                      </td>
                    </tr>


                    <tr className="color-dark-red">
                      <td >{t('M5chapter03Screen3Text11')}</td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number25" onClick={checkboxClick} defaultValue="male" name="A" data-quesId="9" checked={maleChecked}/>
                            <label className="form-check-label" htmlFor="number25">{t('M5chapter03Screen3Text42')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number26" onClick={checkboxClick} defaultValue="female" name="B" data-quesId="9" checked={femaleChecked}/>
                            <label className="form-check-label" htmlFor="number26">{t('M5chapter03Screen3Text43')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number27" onClick={checkboxClick} defaultValue="trans sexual" name="C" data-quesId="9" checked={transSexualChecked}/>
                            <label className="form-check-label" htmlFor="number27">{t('M5chapter03Screen3Text44')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number28" onClick={checkboxClick} defaultValue="intersex" name="D" data-quesId="9" checked={intersexChecked}/>
                            <label className="form-check-label" htmlFor="number28">{t('M5chapter03Screen3Text45')}</label>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className="color-dark-red2">
                      <td>{t('M5chapter03Screen3Text12')}</td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number29" onClick={checkboxClick} defaultValue="cisman" name="A" data-quesId="9" checked={cismanChecked}/>
                            <label className="form-check-label" htmlFor="number29">{t('M5chapter03Screen3Text46')} </label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number30" onClick={checkboxClick} defaultValue="ciswoman" name="B" data-quesId="9" checked={ciswomanChecked}/>
                            <label className="form-check-label" htmlFor="number30">{t('M5chapter03Screen3Text47')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number31" onClick={checkboxClick} defaultValue="transgender" name="C" data-quesId="9" checked={transgenderChecked} />
                            <label className="form-check-label" htmlFor="number31">{t('M5chapter03Screen3Text48')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number32" onClick={checkboxClick} defaultValue="non-binary" name="D" data-quesId="9" checked={nonBinaryChecked}/>
                            <label className="form-check-label" htmlFor="number32">{t('M5chapter03Screen3Text49')}</label>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className="color-dark-red">
                      <td >{t('M5chapter03Screen3Text13')}</td>


                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number33" onClick={checkboxClick} defaultValue="heterosexual" name="A" data-quesId="9" checked={heterosexualChecked} />
                            <label className="form-check-label" htmlFor="number33">{t('M5chapter03Screen3Text50')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number34" onClick={checkboxClick} defaultValue="bi-sexual" name="B" data-quesId="9" checked={biSexualChecked}/>
                            <label className="form-check-label" htmlFor="number34">{t('M5chapter03Screen3Text51')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number35" onClick={checkboxClick} defaultValue="homosexual" name="C" data-quesId="9" checked={homosexualChecked} />
                            <label className="form-check-label" htmlFor="number35">{t('M5chapter03Screen3Text52')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number36" onClick={checkboxClick} defaultValue="all other identities" name="D" data-quesId="9" checked={allOtherIdentitiesChecked} />
                            <label className="form-check-label" htmlFor="number36">{t('M5chapter03Screen3Text53')}</label>
                          </div>
                        </div>
                      </td>

                    </tr>

                    <tr className="color-dark-red2">
                      <td >{t('M5chapter03Screen3Text14')}</td>


                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number37" onClick={checkboxClick} defaultValue="foreign degree" name="A" data-quesId="9" checked={foreignDegreeChecked}/>
                            <label className="form-check-label" htmlFor="number37">{t('M5chapter03Screen3Text54')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number38" onClick={checkboxClick} defaultValue="graduate" name="B" data-quesId="9" checked={graduateChecked} />
                            <label className="form-check-label" htmlFor="number38">{t('M5chapter03Screen3Text55')} </label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number39" onClick={checkboxClick} defaultValue="primary education" name="C" data-quesId="9" checked={primaryEducationChecked} />
                            <label className="form-check-label" htmlFor="number39">{t('M5chapter03Screen3Text56')} </label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number40" onClick={checkboxClick} defaultValue="illiterate" name="D" data-quesId="9" checked={illiterateChecked} />
                            <label className="form-check-label" htmlFor="number40">{t('M5chapter03Screen3Text57')}</label>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className="color-dark-red">
                      <td>{t('M5chapter03Screen3Text15')}</td>

                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number41" onClick={checkboxClick} defaultValue="high speed full time internet connection" name="A" data-quesId="9" checked={highSpeedFullTimeInternetConnectionChecked} />
                            <label className="form-check-label" htmlFor="number41">{t('M5chapter03Screen3Text58')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number42" onClick={checkboxClick} defaultValue="slow internet connections" name="B" data-quesId="9" checked={slowInternetConnectionChecked} />
                            <label className="form-check-label" htmlFor="number42">{t('M5chapter03Screen3Text59')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number43" onClick={checkboxClick} defaultValue="cyber cafe" name="C" data-quesId="9" checked={cyberCafeChecked} />
                            <label className="form-check-label" htmlFor="number43">{t('M5chapter03Screen3Text60')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number44" onClick={checkboxClick} defaultValue="no internet connectivity" name="D" data-quesId="9" checked={noInternetConnectivityChecked} />
                            <label className="form-check-label" htmlFor="number44">{t('M5chapter03Screen3Text61')}</label>
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr className="color-dark-red2">
                      <td >{t('M5chapter03Screen3Text16')}</td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number45" onClick={checkboxClick} defaultValue="english1" name="A" data-quesId="9" checked={English1Checked}/>
                            <label className="form-check-label" htmlFor="number45">{t('M5chapter03Screen3Text62')} </label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number46" onClick={checkboxClick} defaultValue="hindi1" name="B" data-quesId="9" checked={Hindi1Checked}/>
                            <label className="form-check-label" htmlFor="number46">{t('M5chapter03Screen3Text63')}</label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number47" onClick={checkboxClick} defaultValue="vernacular" name="C" data-quesId="9" checked={vernacularChecked}/>
                            <label className="form-check-label" htmlFor="number47">{t('M5chapter03Screen3Text64')} </label>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="d-flex">
                          <div className="form-check form-check-inline">
                            <input  className="form-check-input custom-check" type="checkbox" id="number48" onClick={checkboxClick} defaultValue="local dialect" name="D" data-quesId="9" checked={localdialectChecked}/>
                            <label className="form-check-label" htmlFor="number48">{t('M5chapter03Screen3Text65')}</label>
                          </div>
                        </div>
                      </td>


                    </tr>

                    <tr className="color-dark-red">
                      <td >{t('M5chapter03Screen3Text17')}</td>
                      <td>{Avalue.length} </td>
                      <td> {Bvalue.length}</td>
                      <td> {Cvalue.length}</td>
                      <td> {Dvalue.length}</td>
                    </tr>



                  </tbody>
                </table>
              </div>


              </div>
            </div>
          </div>
        </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-05/chapter-03-screen-02"/>
                {/* <NextBtn link="/module-05/chapter-03-screen-06" text="Go Next"/> */}
                <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter03Screen03;
