import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from "react-i18next";



const Chapter04Screen07 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();


  // parents yes no
  const [checkedQues8ParentsYes, setCheckedQues8ParentsYes] = useState('');
  const [checkedQues8ParentsNo, setCheckedQues8ParentsNo] = useState('');
  const [checkedQues8ParentsVal, setCheckedQues8ParentsVal] = useState('');
//siblings yes no
  const [checkedQues8SiblingsYes, setCheckedQues8SiblingsYes] = useState('');
  const [checkedQues8SiblingsNo, setCheckedQues8SiblingsNo] = useState('');
  const [checkedQues8SiblingsVal, setCheckedQues8SiblingsVal] = useState('');

  const [checkedQues8FriendsYes, setCheckedQues8FriendsYes] = useState('');
  const [checkedQues8FriendsNo, setCheckedQues8FriendsNo] = useState('');
  const [checkedQues8FriendsVal, setCheckedQues8FriendsVal] = useState('');

  const [checkedQues8RelativesYes, setCheckedQues8RelativesYes] = useState('');
  const [checkedQues8RelativesNo, setCheckedQues8RelativesNo] = useState('');
  const [checkedQues8RelativesVal, setCheckedQues8RelativesVal] = useState('');

  const [checkedQues8StrangersYes, setCheckedQues8StrangersYes] = useState('');
  const [checkedQues8StrangersNo, setCheckedQues8StrangersNo] = useState('');
  const [checkedQues8StrangersVal, setCheckedQues8StrangersVal] = useState('');

  const [checkedQues8TeachersYes, setCheckedQues8TeachersYes] = useState('');
  const [checkedQues8TeachersNo, setCheckedQues8TeachersNo] = useState('');
  const [checkedQues8TeachersVal, setCheckedQues8TeachersVal] = useState('');

  const [checkedQues8CollegeYes, setCheckedQues8CollegeYes] = useState('');
  const [checkedQues8CollegeNo, setCheckedQues8CollegeNo] = useState('');
  const [checkedQues8CollegeVal, setCheckedQues8CollegeVal] = useState('');


  //ques 9


  // parents yes no
  const [checkedQues9ParentsYes, setCheckedQues9ParentsYes] = useState('');
  const [checkedQues9ParentsNo, setCheckedQues9ParentsNo] = useState('');
  const [checkedQues9ParentsVal, setCheckedQues9ParentsVal] = useState('');
//siblings yes no
  const [checkedQues9SiblingsYes, setCheckedQues9SiblingsYes] = useState('');
  const [checkedQues9SiblingsNo, setCheckedQues9SiblingsNo] = useState('');
  const [checkedQues9SiblingsVal, setCheckedQues9SiblingsVal] = useState('');

  const [checkedQues9FriendsYes, setCheckedQues9FriendsYes] = useState('');
  const [checkedQues9FriendsNo, setCheckedQues9FriendsNo] = useState('');
  const [checkedQues9FriendsVal, setCheckedQues9FriendsVal] = useState('');

  const [checkedQues9RelativesYes, setCheckedQues9RelativesYes] = useState('');
  const [checkedQues9RelativesNo, setCheckedQues9RelativesNo] = useState('');
  const [checkedQues9RelativesVal, setCheckedQues9RelativesVal] = useState('');

  const [checkedQues9StrangersYes, setCheckedQues9StrangersYes] = useState('');
  const [checkedQues9StrangersNo, setCheckedQues9StrangersNo] = useState('');
  const [checkedQues9StrangersVal, setCheckedQues9StrangersVal] = useState('');

  const [checkedQues9TeachersYes, setCheckedQues9TeachersYes] = useState('');
  const [checkedQues9TeachersNo, setCheckedQues9TeachersNo] = useState('');
  const [checkedQues9TeachersVal, setCheckedQues9TeachersVal] = useState('');

  const [checkedQues9CollegeYes, setCheckedQues9CollegeYes] = useState('');
  const [checkedQues9CollegeNo, setCheckedQues9CollegeNo] = useState('');
  const [checkedQues9CollegeVal, setCheckedQues9CollegeVal] = useState('');

  // ques10

    // parents yes no
    const [checkedQues10ParentsYes, setCheckedQues10ParentsYes] = useState('');
    const [checkedQues10ParentsNo, setCheckedQues10ParentsNo] = useState('');
    const [checkedQues10ParentsVal, setCheckedQues10ParentsVal] = useState('');
  //siblings yes no
    const [checkedQues10SiblingsYes, setCheckedQues10SiblingsYes] = useState('');
    const [checkedQues10SiblingsNo, setCheckedQues10SiblingsNo] = useState('');
    const [checkedQues10SiblingsVal, setCheckedQues10SiblingsVal] = useState('');

    const [checkedQues10FriendsYes, setCheckedQues10FriendsYes] = useState('');
    const [checkedQues10FriendsNo, setCheckedQues10FriendsNo] = useState('');
    const [checkedQues10FriendsVal, setCheckedQues10FriendsVal] = useState('');

    const [checkedQues10RelativesYes, setCheckedQues10RelativesYes] = useState('');
    const [checkedQues10RelativesNo, setCheckedQues10RelativesNo] = useState('');
    const [checkedQues10RelativesVal, setCheckedQues10RelativesVal] = useState('');

    const [checkedQues10StrangersYes, setCheckedQues10StrangersYes] = useState('');
    const [checkedQues10StrangersNo, setCheckedQues10StrangersNo] = useState('');
    const [checkedQues10StrangersVal, setCheckedQues10StrangersVal] = useState('');

    const [checkedQues10TeachersYes, setCheckedQues10TeachersYes] = useState('');
    const [checkedQues10TeachersNo, setCheckedQues10TeachersNo] = useState('');
    const [checkedQues10TeachersVal, setCheckedQues10TeachersVal] = useState('');

    const [checkedQues10CollegeYes, setCheckedQues10CollegeYes] = useState('');
    const [checkedQues10CollegeNo, setCheckedQues10CollegeNo] = useState('');
    const [checkedQues10CollegeVal, setCheckedQues10CollegeVal] = useState('');

    // ques11

    // parents yes no
    const [checkedQues11ParentsYes, setCheckedQues11ParentsYes] = useState('');
    const [checkedQues11ParentsNo, setCheckedQues11ParentsNo] = useState('');
    const [checkedQues11ParentsVal, setCheckedQues11ParentsVal] = useState('');
  //siblings yes no
    const [checkedQues11SiblingsYes, setCheckedQues11SiblingsYes] = useState('');
    const [checkedQues11SiblingsNo, setCheckedQues11SiblingsNo] = useState('');
    const [checkedQues11SiblingsVal, setCheckedQues11SiblingsVal] = useState('');

    const [checkedQues11FriendsYes, setCheckedQues11FriendsYes] = useState('');
    const [checkedQues11FriendsNo, setCheckedQues11FriendsNo] = useState('');
    const [checkedQues11FriendsVal, setCheckedQues11FriendsVal] = useState('');

    const [checkedQues11RelativesYes, setCheckedQues11RelativesYes] = useState('');
    const [checkedQues11RelativesNo, setCheckedQues11RelativesNo] = useState('');
    const [checkedQues11RelativesVal, setCheckedQues11RelativesVal] = useState('');

    const [checkedQues11StrangersYes, setCheckedQues11StrangersYes] = useState('');
    const [checkedQues11StrangersNo, setCheckedQues11StrangersNo] = useState('');
    const [checkedQues11StrangersVal, setCheckedQues11StrangersVal] = useState('');

    const [checkedQues11TeachersYes, setCheckedQues11TeachersYes] = useState('');
    const [checkedQues11TeachersNo, setCheckedQues11TeachersNo] = useState('');
    const [checkedQues11TeachersVal, setCheckedQues11TeachersVal] = useState('');

    const [checkedQues11CollegeYes, setCheckedQues11CollegeYes] = useState('');
    const [checkedQues11CollegeNo, setCheckedQues11CollegeNo] = useState('');
    const [checkedQues11CollegeVal, setCheckedQues11CollegeVal] = useState('');

    // ques 12

    // parents yes no
    const [checkedQues12ParentsYes, setCheckedQues12ParentsYes] = useState('');
    const [checkedQues12ParentsNo, setCheckedQues12ParentsNo] = useState('');
    const [checkedQues12ParentsVal, setCheckedQues12ParentsVal] = useState('');
  //siblings yes no
    const [checkedQues12SiblingsYes, setCheckedQues12SiblingsYes] = useState('');
    const [checkedQues12SiblingsNo, setCheckedQues12SiblingsNo] = useState('');
    const [checkedQues12SiblingsVal, setCheckedQues12SiblingsVal] = useState('');

    const [checkedQues12FriendsYes, setCheckedQues12FriendsYes] = useState('');
    const [checkedQues12FriendsNo, setCheckedQues12FriendsNo] = useState('');
    const [checkedQues12FriendsVal, setCheckedQues12FriendsVal] = useState('');

    const [checkedQues12RelativesYes, setCheckedQues12RelativesYes] = useState('');
    const [checkedQues12RelativesNo, setCheckedQues12RelativesNo] = useState('');
    const [checkedQues12RelativesVal, setCheckedQues12RelativesVal] = useState('');

    const [checkedQues12StrangersYes, setCheckedQues12StrangersYes] = useState('');
    const [checkedQues12StrangersNo, setCheckedQues12StrangersNo] = useState('');
    const [checkedQues12StrangersVal, setCheckedQues12StrangersVal] = useState('');

    const [checkedQues12TeachersYes, setCheckedQues12TeachersYes] = useState('');
    const [checkedQues12TeachersNo, setCheckedQues12TeachersNo] = useState('');
    const [checkedQues12TeachersVal, setCheckedQues12TeachersVal] = useState('');

    const [checkedQues12CollegeYes, setCheckedQues12CollegeYes] = useState('');
    const [checkedQues12CollegeNo, setCheckedQues12CollegeNo] = useState('');
    const [checkedQues12CollegeVal, setCheckedQues12CollegeVal] = useState('');





  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    const quesVal = e.target.getAttribute("data-quesVal");

    checkedVal1(quesId,e.target.value,quesVal)

  }

  const checkedVal1 = (param,ans,quesVal) => {

    if (param === '8') {

      if(quesVal === 'parents')
      {
        if (ans === 'yes') {
          setCheckedQues8ParentsYes('checked')
          setCheckedQues8ParentsNo('')
        } else if (ans === 'no') {
            setCheckedQues8ParentsNo('checked')
            setCheckedQues8ParentsYes(''
            )
          }

          setCheckedQues8ParentsVal(ans)
      }

      if(quesVal === 'siblings')
      {
        // alert(quesVal);
        if (ans === 'yes') {
          setCheckedQues8SiblingsYes('checked')
          setCheckedQues8SiblingsNo('')
        } else if (ans === 'no') {
            setCheckedQues8SiblingsNo('checked')
            setCheckedQues8SiblingsYes(''
            )
          }

          setCheckedQues8SiblingsVal(ans)
      }

      if(quesVal === 'friends')
      {
        if (ans === 'yes') {
          setCheckedQues8FriendsYes('checked')
          setCheckedQues8FriendsNo('')
        } else if (ans === 'no') {
            setCheckedQues8FriendsNo('checked')
            setCheckedQues8FriendsYes('')
          }

          setCheckedQues8FriendsVal(ans)
      }

      if(quesVal === 'relatives')
      {
        if (ans === 'yes') {
          setCheckedQues8RelativesYes('checked')
          setCheckedQues8RelativesNo('')
        } else if (ans === 'no') {
            setCheckedQues8RelativesNo('checked')
            setCheckedQues8RelativesYes('')
          }

          setCheckedQues8RelativesVal(ans)
      }

      if(quesVal === 'strangers')
      {
        if (ans === 'yes') {
          setCheckedQues8StrangersYes('checked')
          setCheckedQues8StrangersNo('')
        } else if (ans === 'no') {
            setCheckedQues8StrangersNo('checked')
            setCheckedQues8StrangersYes('')
          }

          setCheckedQues8StrangersVal(ans)
      }

      if(quesVal === 'teachers')
      {
        if (ans === 'yes') {
          setCheckedQues8TeachersYes('checked')
          setCheckedQues8TeachersNo('')
        } else if (ans === 'no') {
            setCheckedQues8TeachersNo('checked')
            setCheckedQues8TeachersYes('')
          }

          setCheckedQues8TeachersVal(ans)
      }

      if(quesVal === 'college')
      {

          if (ans === 'yes') {
            setCheckedQues8CollegeYes('checked')
            setCheckedQues8CollegeNo('')
          } else if (ans === 'no') {
              setCheckedQues8CollegeNo('checked')
              setCheckedQues8CollegeYes('')
            }

          setCheckedQues8CollegeVal(ans)
      }


    }

    if (param === '9') {

      if(quesVal === 'parents')
      {
        if (ans === 'yes') {
          setCheckedQues9ParentsYes('checked')
          setCheckedQues9ParentsNo('')
        } else if (ans === 'no') {
            setCheckedQues9ParentsNo('checked')
            setCheckedQues9ParentsYes(''
            )
          }

          setCheckedQues9ParentsVal(ans)
      }

      if(quesVal === 'siblings')
      {
        // alert(quesVal);
        if (ans === 'yes') {
          setCheckedQues9SiblingsYes('checked')
          setCheckedQues9SiblingsNo('')
        } else if (ans === 'no') {
            setCheckedQues9SiblingsNo('checked')
            setCheckedQues9SiblingsYes(''
            )
          }

          setCheckedQues9SiblingsVal(ans)
      }

      if(quesVal === 'friends')
      {
        if (ans === 'yes') {
          setCheckedQues9FriendsYes('checked')
          setCheckedQues9FriendsNo('')
        } else if (ans === 'no') {
            setCheckedQues9FriendsNo('checked')
            setCheckedQues9FriendsYes('')
          }

          setCheckedQues9FriendsVal(ans)
      }

      if(quesVal === 'relatives')
      {
        if (ans === 'yes') {
          setCheckedQues9RelativesYes('checked')
          setCheckedQues9RelativesNo('')
        } else if (ans === 'no') {
            setCheckedQues9RelativesNo('checked')
            setCheckedQues9RelativesYes('')
          }

          setCheckedQues9RelativesVal(ans)
      }

      if(quesVal === 'strangers')
      {
        if (ans === 'yes') {
          setCheckedQues9StrangersYes('checked')
          setCheckedQues9StrangersNo('')
        } else if (ans === 'no') {
            setCheckedQues9StrangersNo('checked')
            setCheckedQues9StrangersYes('')
          }

          setCheckedQues9StrangersVal(ans)
      }

      if(quesVal === 'teachers')
      {
        if (ans === 'yes') {
          setCheckedQues9TeachersYes('checked')
          setCheckedQues9TeachersNo('')
        } else if (ans === 'no') {
            setCheckedQues9TeachersNo('checked')
            setCheckedQues9TeachersYes('')
          }

          setCheckedQues9TeachersVal(ans)
      }

      if(quesVal === 'college')
      {

          if (ans === 'yes') {
            setCheckedQues9CollegeYes('checked')
            setCheckedQues9CollegeNo('')
          } else if (ans === 'no') {
              setCheckedQues9CollegeNo('checked')
              setCheckedQues9CollegeYes('')
            }

          setCheckedQues9CollegeVal(ans)
      }


    }

    if (param === '10') {

      if(quesVal === 'parents')
      {
        if (ans === 'yes') {
          setCheckedQues10ParentsYes('checked')
          setCheckedQues10ParentsNo('')
        } else if (ans === 'no') {
            setCheckedQues10ParentsNo('checked')
            setCheckedQues10ParentsYes(''
            )
          }

          setCheckedQues10ParentsVal(ans)
      }

      if(quesVal === 'siblings')
      {
        // alert(quesVal);
        if (ans === 'yes') {
          setCheckedQues10SiblingsYes('checked')
          setCheckedQues10SiblingsNo('')
        } else if (ans === 'no') {
            setCheckedQues10SiblingsNo('checked')
            setCheckedQues10SiblingsYes(''
            )
          }

          setCheckedQues10SiblingsVal(ans)
      }

      if(quesVal === 'friends')
      {
        if (ans === 'yes') {
          setCheckedQues10FriendsYes('checked')
          setCheckedQues10FriendsNo('')
        } else if (ans === 'no') {
            setCheckedQues10FriendsNo('checked')
            setCheckedQues10FriendsYes('')
          }

          setCheckedQues10FriendsVal(ans)
      }

      if(quesVal === 'relatives')
      {
        if (ans === 'yes') {
          setCheckedQues10RelativesYes('checked')
          setCheckedQues10RelativesNo('')
        } else if (ans === 'no') {
            setCheckedQues10RelativesNo('checked')
            setCheckedQues10RelativesYes('')
          }

          setCheckedQues10RelativesVal(ans)
      }

      if(quesVal === 'strangers')
      {
        if (ans === 'yes') {
          setCheckedQues10StrangersYes('checked')
          setCheckedQues10StrangersNo('')
        } else if (ans === 'no') {
            setCheckedQues10StrangersNo('checked')
            setCheckedQues10StrangersYes('')
          }

          setCheckedQues10StrangersVal(ans)
      }

      if(quesVal === 'teachers')
      {
        if (ans === 'yes') {
          setCheckedQues10TeachersYes('checked')
          setCheckedQues10TeachersNo('')
        } else if (ans === 'no') {
            setCheckedQues10TeachersNo('checked')
            setCheckedQues10TeachersYes('')
          }

          setCheckedQues10TeachersVal(ans)
      }

      if(quesVal === 'college')
      {

          if (ans === 'yes') {
            setCheckedQues10CollegeYes('checked')
            setCheckedQues10CollegeNo('')
          } else if (ans === 'no') {
              setCheckedQues10CollegeNo('checked')
              setCheckedQues10CollegeYes('')
            }

          setCheckedQues10CollegeVal(ans)
      }


    }

    if (param === '11') {

      if(quesVal === 'parents')
      {
        if (ans === 'yes') {
          setCheckedQues11ParentsYes('checked')
          setCheckedQues11ParentsNo('')
        } else if (ans === 'no') {
            setCheckedQues11ParentsNo('checked')
            setCheckedQues11ParentsYes(''
            )
          }

          setCheckedQues11ParentsVal(ans)
      }

      if(quesVal === 'siblings')
      {
        // alert(quesVal);
        if (ans === 'yes') {
          setCheckedQues11SiblingsYes('checked')
          setCheckedQues11SiblingsNo('')
        } else if (ans === 'no') {
            setCheckedQues11SiblingsNo('checked')
            setCheckedQues11SiblingsYes(''
            )
          }

          setCheckedQues11SiblingsVal(ans)
      }

      if(quesVal === 'friends')
      {
        if (ans === 'yes') {
          setCheckedQues11FriendsYes('checked')
          setCheckedQues11FriendsNo('')
        } else if (ans === 'no') {
            setCheckedQues11FriendsNo('checked')
            setCheckedQues11FriendsYes('')
          }

          setCheckedQues11FriendsVal(ans)
      }

      if(quesVal === 'relatives')
      {
        if (ans === 'yes') {
          setCheckedQues11RelativesYes('checked')
          setCheckedQues11RelativesNo('')
        } else if (ans === 'no') {
            setCheckedQues11RelativesNo('checked')
            setCheckedQues11RelativesYes('')
          }

          setCheckedQues11RelativesVal(ans)
      }

      if(quesVal === 'strangers')
      {
        if (ans === 'yes') {
          setCheckedQues11StrangersYes('checked')
          setCheckedQues11StrangersNo('')
        } else if (ans === 'no') {
            setCheckedQues11StrangersNo('checked')
            setCheckedQues11StrangersYes('')
          }

          setCheckedQues11StrangersVal(ans)
      }

      if(quesVal === 'teachers')
      {
        if (ans === 'yes') {
          setCheckedQues11TeachersYes('checked')
          setCheckedQues11TeachersNo('')
        } else if (ans === 'no') {
            setCheckedQues11TeachersNo('checked')
            setCheckedQues11TeachersYes('')
          }

          setCheckedQues11TeachersVal(ans)
      }

      if(quesVal === 'college')
      {

          if (ans === 'yes') {
            setCheckedQues11CollegeYes('checked')
            setCheckedQues11CollegeNo('')
          } else if (ans === 'no') {
              setCheckedQues11CollegeNo('checked')
              setCheckedQues11CollegeYes('')
            }

          setCheckedQues11CollegeVal(ans)
      }


    }

    if (param === '12') {

      if(quesVal === 'parents')
      {
        if (ans === 'yes') {
          setCheckedQues12ParentsYes('checked')
          setCheckedQues12ParentsNo('')
        } else if (ans === 'no') {
            setCheckedQues12ParentsNo('checked')
            setCheckedQues12ParentsYes(''
            )
          }

          setCheckedQues12ParentsVal(ans)
      }

      if(quesVal === 'siblings')
      {
        // alert(quesVal);
        if (ans === 'yes') {
          setCheckedQues12SiblingsYes('checked')
          setCheckedQues12SiblingsNo('')
        } else if (ans === 'no') {
            setCheckedQues12SiblingsNo('checked')
            setCheckedQues12SiblingsYes(''
            )
          }

          setCheckedQues12SiblingsVal(ans)
      }

      if(quesVal === 'friends')
      {
        if (ans === 'yes') {
          setCheckedQues12FriendsYes('checked')
          setCheckedQues12FriendsNo('')
        } else if (ans === 'no') {
            setCheckedQues12FriendsNo('checked')
            setCheckedQues12FriendsYes('')
          }

          setCheckedQues12FriendsVal(ans)
      }

      if(quesVal === 'relatives')
      {
        if (ans === 'yes') {
          setCheckedQues12RelativesYes('checked')
          setCheckedQues12RelativesNo('')
        } else if (ans === 'no') {
            setCheckedQues12RelativesNo('checked')
            setCheckedQues12RelativesYes('')
          }

          setCheckedQues12RelativesVal(ans)
      }

      if(quesVal === 'strangers')
      {
        if (ans === 'yes') {
          setCheckedQues12StrangersYes('checked')
          setCheckedQues12StrangersNo('')
        } else if (ans === 'no') {
            setCheckedQues12StrangersNo('checked')
            setCheckedQues12StrangersYes('')
          }

          setCheckedQues12StrangersVal(ans)
      }

      if(quesVal === 'teachers')
      {
        if (ans === 'yes') {
          setCheckedQues12TeachersYes('checked')
          setCheckedQues12TeachersNo('')
        } else if (ans === 'no') {
            setCheckedQues12TeachersNo('checked')
            setCheckedQues12TeachersYes('')
          }

          setCheckedQues12TeachersVal(ans)
      }

      if(quesVal === 'college')
      {

          if (ans === 'yes') {
            setCheckedQues12CollegeYes('checked')
            setCheckedQues12CollegeNo('')
          } else if (ans === 'no') {
              setCheckedQues12CollegeNo('checked')
              setCheckedQues12CollegeYes('')
            }

          setCheckedQues12CollegeVal(ans)
      }


    }




  }




  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": checkedQues8ParentsVal,//parents
          "ans2": checkedQues8SiblingsVal, //siblings
          "ans3": checkedQues8FriendsVal, // friends
          "ans4": checkedQues8RelativesVal, //relatives
          "ans5": checkedQues8StrangersVal, //strangers
          "ans6": checkedQues8TeachersVal, //teachers
          "ans7": checkedQues8CollegeVal, //college
          "ques_id": "8"
        },
        "ques9": {
          "ans": checkedQues9ParentsVal,//parents
          "ans2": checkedQues9SiblingsVal, //siblings
          "ans3": checkedQues9FriendsVal, // friends
          "ans4": checkedQues9RelativesVal, //relatives
          "ans5": checkedQues9StrangersVal, //strangers
          "ans6": checkedQues9TeachersVal, //teachers
          "ans7": checkedQues9CollegeVal, //college
          "ques_id": "9"
        },
        "ques10": {
          "ans": checkedQues10ParentsVal,//parents
          "ans2": checkedQues10SiblingsVal, //siblings
          "ans3": checkedQues10FriendsVal, // friends
          "ans4": checkedQues10RelativesVal, //relatives
          "ans5": checkedQues10StrangersVal, //strangers
          "ans6": checkedQues10TeachersVal, //teachers
          "ans7": checkedQues10CollegeVal, //college
          "ques_id": "10"
        },
        "ques11": {
          "ans": checkedQues11ParentsVal,//parents
          "ans2": checkedQues11SiblingsVal, //siblings
          "ans3": checkedQues11FriendsVal, // friends
          "ans4": checkedQues11RelativesVal, //relatives
          "ans5": checkedQues11StrangersVal, //strangers
          "ans6": checkedQues11TeachersVal, //teachers
          "ans7": checkedQues11CollegeVal, //college
          "ques_id": "11"
        },
        "ques12": {
          "ans": checkedQues12ParentsVal,//parents
          "ans2": checkedQues12SiblingsVal, //siblings
          "ans3": checkedQues12FriendsVal, // friends
          "ans4": checkedQues12RelativesVal, //relatives
          "ans5": checkedQues12StrangersVal, //strangers
          "ans6": checkedQues12TeachersVal, //teachers
          "ans7": checkedQues12CollegeVal, //college
          "ques_id": "12"
        },
        "ques13": {
          "ans": "",
          "ques_id": "13"
        },
        "ques14": {
          "ans": "",
          "ques_id": "14"
        },
        "ques15": {
          "ans": "",
          "ques_id": "15"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/SelfChapter04',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-01/chapter-04-screen-08')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfChapter04Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans,result.ans2,result.ans3,result.ans4,result.ans5,result.ans6,result.ans7)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  const checkedVal = (param,ans,ans2,ans3,ans4,ans5,ans6,ans7) => {

    if (param === '8') {

      if (ans === 'yes') {
        setCheckedQues8ParentsYes('checked')
        setCheckedQues8ParentsNo('')
      } else if (ans === 'no') {
          setCheckedQues8ParentsNo('checked')
          setCheckedQues8ParentsYes(''
          )
        }



      if (ans2 === 'yes') {
        setCheckedQues8SiblingsYes('checked')
        setCheckedQues8SiblingsNo('')
      } else if (ans2 === 'no') {
          setCheckedQues8SiblingsNo('checked')
          setCheckedQues8SiblingsYes(''
          )
        }


      if (ans3 === 'yes') {
        setCheckedQues8FriendsYes('checked')
        setCheckedQues8FriendsNo('')
      } else if (ans3 === 'no') {
          setCheckedQues8FriendsNo('checked')
          setCheckedQues8FriendsYes('')
        }


      if (ans4 === 'yes') {
        setCheckedQues8RelativesYes('checked')
        setCheckedQues8RelativesNo('')
      } else if (ans4 === 'no') {
          setCheckedQues8RelativesNo('checked')
          setCheckedQues8RelativesYes('')
        }


      if (ans5 === 'yes') {
        setCheckedQues8StrangersYes('checked')
        setCheckedQues8StrangersNo('')
      } else if (ans5 === 'no') {
          setCheckedQues8StrangersNo('checked')
          setCheckedQues8StrangersYes('')
        }


      if (ans6 === 'yes') {
        setCheckedQues8TeachersYes('checked')
        setCheckedQues8TeachersNo('')
      } else if (ans6 === 'no') {
          setCheckedQues8TeachersNo('checked')
          setCheckedQues8TeachersYes('')
        }


      if (ans7 === 'yes') {
        setCheckedQues8CollegeYes('checked')
        setCheckedQues8CollegeNo('')
      } else if (ans7 === 'no') {
          setCheckedQues8CollegeNo('checked')
          setCheckedQues8CollegeYes('')
        }



      setCheckedQues8CollegeVal(ans7)
      setCheckedQues8TeachersVal(ans6)
      setCheckedQues8StrangersVal(ans5)
      setCheckedQues8RelativesVal(ans4)
      setCheckedQues8FriendsVal(ans3)
      setCheckedQues8SiblingsVal(ans2)
      setCheckedQues8ParentsVal(ans)
    }

    if (param === '9') {

      if (ans === 'yes') {
        setCheckedQues9ParentsYes('checked')
        setCheckedQues9ParentsNo('')
      } else if (ans === 'no') {
          setCheckedQues9ParentsNo('checked')
          setCheckedQues9ParentsYes(''
          )
        }



      if (ans2 === 'yes') {
        setCheckedQues9SiblingsYes('checked')
        setCheckedQues9SiblingsNo('')
      } else if (ans2 === 'no') {
          setCheckedQues9SiblingsNo('checked')
          setCheckedQues9SiblingsYes(''
          )
        }


      if (ans3 === 'yes') {
        setCheckedQues9FriendsYes('checked')
        setCheckedQues9FriendsNo('')
      } else if (ans3 === 'no') {
          setCheckedQues9FriendsNo('checked')
          setCheckedQues9FriendsYes('')
        }


      if (ans4 === 'yes') {
        setCheckedQues9RelativesYes('checked')
        setCheckedQues9RelativesNo('')
      } else if (ans4 === 'no') {
          setCheckedQues9RelativesNo('checked')
          setCheckedQues9RelativesYes('')
        }


      if (ans5 === 'yes') {
        setCheckedQues9StrangersYes('checked')
        setCheckedQues9StrangersNo('')
      } else if (ans5 === 'no') {
          setCheckedQues9StrangersNo('checked')
          setCheckedQues9StrangersYes('')
        }


      if (ans6 === 'yes') {
        setCheckedQues9TeachersYes('checked')
        setCheckedQues9TeachersNo('')
      } else if (ans6 === 'no') {
          setCheckedQues9TeachersNo('checked')
          setCheckedQues9TeachersYes('')
        }


      if (ans7 === 'yes') {
        setCheckedQues9CollegeYes('checked')
        setCheckedQues9CollegeNo('')
      } else if (ans7 === 'no') {
          setCheckedQues9CollegeNo('checked')
          setCheckedQues9CollegeYes('')
        }



      setCheckedQues9CollegeVal(ans7)
      setCheckedQues9TeachersVal(ans6)
      setCheckedQues9StrangersVal(ans5)
      setCheckedQues9RelativesVal(ans4)
      setCheckedQues9FriendsVal(ans3)
      setCheckedQues9SiblingsVal(ans2)
      setCheckedQues9ParentsVal(ans)
    }

    if (param === '10') {

      if (ans === 'yes') {
        setCheckedQues10ParentsYes('checked')
        setCheckedQues10ParentsNo('')
      } else if (ans === 'no') {
          setCheckedQues10ParentsNo('checked')
          setCheckedQues10ParentsYes(''
          )
        }



      if (ans2 === 'yes') {
        setCheckedQues10SiblingsYes('checked')
        setCheckedQues10SiblingsNo('')
      } else if (ans2 === 'no') {
          setCheckedQues10SiblingsNo('checked')
          setCheckedQues10SiblingsYes(''
          )
        }


      if (ans3 === 'yes') {
        setCheckedQues10FriendsYes('checked')
        setCheckedQues10FriendsNo('')
      } else if (ans3 === 'no') {
          setCheckedQues10FriendsNo('checked')
          setCheckedQues10FriendsYes('')
        }


      if (ans4 === 'yes') {
        setCheckedQues10RelativesYes('checked')
        setCheckedQues10RelativesNo('')
      } else if (ans4 === 'no') {
          setCheckedQues10RelativesNo('checked')
          setCheckedQues10RelativesYes('')
        }


      if (ans5 === 'yes') {
        setCheckedQues10StrangersYes('checked')
        setCheckedQues10StrangersNo('')
      } else if (ans5 === 'no') {
          setCheckedQues10StrangersNo('checked')
          setCheckedQues10StrangersYes('')
        }


      if (ans6 === 'yes') {
        setCheckedQues10TeachersYes('checked')
        setCheckedQues10TeachersNo('')
      } else if (ans6 === 'no') {
          setCheckedQues10TeachersNo('checked')
          setCheckedQues10TeachersYes('')
        }


      if (ans7 === 'yes') {
        setCheckedQues10CollegeYes('checked')
        setCheckedQues10CollegeNo('')
      } else if (ans7 === 'no') {
          setCheckedQues10CollegeNo('checked')
          setCheckedQues10CollegeYes('')
        }



      setCheckedQues10CollegeVal(ans7)
      setCheckedQues10TeachersVal(ans6)
      setCheckedQues10StrangersVal(ans5)
      setCheckedQues10RelativesVal(ans4)
      setCheckedQues10FriendsVal(ans3)
      setCheckedQues10SiblingsVal(ans2)
      setCheckedQues10ParentsVal(ans)
    }

    if (param === '11') {

      if (ans === 'yes') {
        setCheckedQues11ParentsYes('checked')
        setCheckedQues11ParentsNo('')
      } else if (ans === 'no') {
          setCheckedQues11ParentsNo('checked')
          setCheckedQues11ParentsYes(''
          )
        }



      if (ans2 === 'yes') {
        setCheckedQues11SiblingsYes('checked')
        setCheckedQues11SiblingsNo('')
      } else if (ans2 === 'no') {
          setCheckedQues11SiblingsNo('checked')
          setCheckedQues11SiblingsYes(''
          )
        }


      if (ans3 === 'yes') {
        setCheckedQues11FriendsYes('checked')
        setCheckedQues11FriendsNo('')
      } else if (ans3 === 'no') {
          setCheckedQues11FriendsNo('checked')
          setCheckedQues11FriendsYes('')
        }


      if (ans4 === 'yes') {
        setCheckedQues11RelativesYes('checked')
        setCheckedQues11RelativesNo('')
      } else if (ans4 === 'no') {
          setCheckedQues11RelativesNo('checked')
          setCheckedQues11RelativesYes('')
        }


      if (ans5 === 'yes') {
        setCheckedQues11StrangersYes('checked')
        setCheckedQues11StrangersNo('')
      } else if (ans5 === 'no') {
          setCheckedQues11StrangersNo('checked')
          setCheckedQues11StrangersYes('')
        }


      if (ans6 === 'yes') {
        setCheckedQues11TeachersYes('checked')
        setCheckedQues11TeachersNo('')
      } else if (ans6 === 'no') {
          setCheckedQues11TeachersNo('checked')
          setCheckedQues11TeachersYes('')
        }


      if (ans7 === 'yes') {
        setCheckedQues11CollegeYes('checked')
        setCheckedQues11CollegeNo('')
      } else if (ans7 === 'no') {
          setCheckedQues11CollegeNo('checked')
          setCheckedQues11CollegeYes('')
        }



      setCheckedQues11CollegeVal(ans7)
      setCheckedQues11TeachersVal(ans6)
      setCheckedQues11StrangersVal(ans5)
      setCheckedQues11RelativesVal(ans4)
      setCheckedQues11FriendsVal(ans3)
      setCheckedQues11SiblingsVal(ans2)
      setCheckedQues11ParentsVal(ans)
    }

    if (param === '12') {

      if (ans === 'yes') {
        setCheckedQues12ParentsYes('checked')
        setCheckedQues12ParentsNo('')
      } else if (ans === 'no') {
          setCheckedQues12ParentsNo('checked')
          setCheckedQues12ParentsYes(''
          )
        }



      if (ans2 === 'yes') {
        setCheckedQues12SiblingsYes('checked')
        setCheckedQues12SiblingsNo('')
      } else if (ans2 === 'no') {
          setCheckedQues12SiblingsNo('checked')
          setCheckedQues12SiblingsYes(''
          )
        }


      if (ans3 === 'yes') {
        setCheckedQues12FriendsYes('checked')
        setCheckedQues12FriendsNo('')
      } else if (ans3 === 'no') {
          setCheckedQues12FriendsNo('checked')
          setCheckedQues12FriendsYes('')
        }


      if (ans4 === 'yes') {
        setCheckedQues12RelativesYes('checked')
        setCheckedQues12RelativesNo('')
      } else if (ans4 === 'no') {
          setCheckedQues12RelativesNo('checked')
          setCheckedQues12RelativesYes('')
        }


      if (ans5 === 'yes') {
        setCheckedQues12StrangersYes('checked')
        setCheckedQues12StrangersNo('')
      } else if (ans5 === 'no') {
          setCheckedQues12StrangersNo('checked')
          setCheckedQues12StrangersYes('')
        }


      if (ans6 === 'yes') {
        setCheckedQues12TeachersYes('checked')
        setCheckedQues12TeachersNo('')
      } else if (ans6 === 'no') {
          setCheckedQues12TeachersNo('checked')
          setCheckedQues12TeachersYes('')
        }


      if (ans7 === 'yes') {
        setCheckedQues12CollegeYes('checked')
        setCheckedQues12CollegeNo('')
      } else if (ans7 === 'no') {
          setCheckedQues12CollegeNo('checked')
          setCheckedQues12CollegeYes('')
        }



      setCheckedQues12CollegeVal(ans7)
      setCheckedQues12TeachersVal(ans6)
      setCheckedQues12StrangersVal(ans5)
      setCheckedQues12RelativesVal(ans4)
      setCheckedQues12FriendsVal(ans3)
      setCheckedQues12SiblingsVal(ans2)
      setCheckedQues12ParentsVal(ans)
    }


  }
  const BtnDisabled = () => {
    if (checkedQues8ParentsVal !== '' ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                Go Next <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };

  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="3/4" percentage="75" star="75" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar/>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <p className="mb-3 ">
                    <b>
                      {t('M1chapter04screen07Text1')}

                    </b>
                  </p>
                  <div className="table-responsive">
                  <table className="table  table-bordered table-set tabble-reset-pa table-font">
                    <tbody>
                      <tr className="first-row-black">
                        <td />
                        <td>{t('parents')}</td>
                        <td>{t('siblings')}</td>
                        <td>{t('friends')}</td>
                        <td>{t('relatives')}</td>
                        <td>{t('strangers')}</td>
                        <td>{t('teacherOrSeniorsInOffice')}</td>
                        <td>{t('collegeOrWorkplace')}</td>
                      </tr>
                      <tr>
                        <td className="first-td">
                          {t('M1chapter04screen07Text2')}

                        </td>
                        <td>
                          {/* {" "}
                          <input
                            className="form-control without-border-input"
                            type="text"
                            placeholder="..."
                            onChange={(e)=>setCheckedQues8Parents(e.target.value)}
                            defaultValue={checkedQues8Parents}
                          />{" "} */}


                          <div className="d-flex">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input custom-check"
                              type="radio"

                              name="ques8Parents"
                              defaultValue="yes"
                              onClick={saveRadio}
                              data-quesId="8"
                              data-quesVal="parents"
                              id="inlineRadio4"
                              checked={checkedQues8ParentsYes}

                              />
                              <label className="form-check-label" htmlFor="inlineRadio1">{t('yes')}</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input custom-check"
                              type="radio"

                                name="ques8Parents"
                              defaultValue="no"
                              onClick={saveRadio}
                              data-quesId="8"
                              id="inlineRadio4"
                              data-quesVal="parents"
                              checked={checkedQues8ParentsNo}

                                />
                              <label className="form-check-label " htmlFor="inlineRadio2">{t('no')}</label>
                            </div>
                          </div>



                        </td>
                        <td>

                            <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"

                                  name="ques8Siblings"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="8"
                                  id="inlineRadio4"
                                  data-quesVal="siblings"
                                  checked={checkedQues8SiblingsYes}
                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques8Siblings"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="8"
                                  data-quesVal="siblings"
                                  id="inlineRadio4"
                                  checked={checkedQues8SiblingsNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>
                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques8Friends"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="8"
                                  id="inlineRadio4"
                                  data-quesVal="friends"
                                  checked={checkedQues8FriendsYes}

                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques8Friends"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="8"
                                  id="inlineRadio4"
                                  data-quesVal="friends"
                                  checked={checkedQues8FriendsNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques8Relatives"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="8"
                                  id="inlineRadio4"
                                  data-quesVal="relatives"
                                  checked={checkedQues8RelativesYes}

                                />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques8Relatives"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="8"
                                  id="inlineRadio4"
                                  data-quesVal="relatives"
                                  checked={checkedQues8RelativesNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques8Strangers"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="8"
                                  id="inlineRadio4"
                                  data-quesVal="strangers"
                                  checked={checkedQues8StrangersYes}

                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques8Strangers"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="8"
                                  id="inlineRadio4"
                                  checked={checkedQues8StrangersNo}
                                  data-quesVal="strangers"

                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques8Teacher"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="8"
                                  id="inlineRadio4"
                                  data-quesVal="teachers"
                                  checked={checkedQues8TeachersYes}
                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques8Teacher"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="8"
                                  id="inlineRadio4"
                                  data-quesVal="teachers"
                                  checked={checkedQues8TeachersNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input
                                custom-check"
                                type="radio"
                                name="ques8College"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="8"
                                  id="inlineRadio4"
                                  data-quesVal="college"
                                  checked={checkedQues8CollegeYes}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques8Colelge"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="8"
                                  id="inlineRadio4"
                                  data-quesVal="college"
                                  checked={checkedQues8CollegeNo}

                                />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                      </tr>
                      <tr>
                        <td className="first-td">{t('M1chapter04screen07Text3')}</td>
                        <td>
                          {/* {" "}
                          <input
                            className="form-control without-border-input"
                            type="text"
                            placeholder="..."
                            onChange={(e)=>setCheckedQues8Parents(e.target.value)}
                            defaultValue={checkedQues8Parents}
                          />{" "} */}


                          <div className="d-flex">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input custom-check"
                              type="radio"

                              name="ques9Parents"
                              defaultValue="yes"
                              onClick={saveRadio}
                              data-quesId="9"
                              data-quesVal="parents"
                              id="inlineRadio4"
                              checked={checkedQues9ParentsYes}

                              />
                              <label className="form-check-label" htmlFor="inlineRadio1">{t('yes')}</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input custom-check"
                              type="radio"

                                name="ques9Parents"
                              defaultValue="no"
                              onClick={saveRadio}
                              data-quesId="9"
                              id="inlineRadio4"
                              data-quesVal="parents"
                              checked={checkedQues9ParentsNo}

                                />
                              <label className="form-check-label " htmlFor="inlineRadio2">{t('no')}</label>
                            </div>
                          </div>



                        </td>
                        <td>

                            <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"

                                  name="ques9Siblings"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="9"
                                  id="inlineRadio4"
                                  data-quesVal="siblings"
                                  checked={checkedQues9SiblingsYes}
                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques9Siblings"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="9"
                                  data-quesVal="siblings"
                                  id="inlineRadio4"
                                  checked={checkedQues9SiblingsNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>
                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques9Friends"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="9"
                                  id="inlineRadio4"
                                  data-quesVal="friends"
                                  checked={checkedQues9FriendsYes}

                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques9Friends"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="9"
                                  id="inlineRadio4"
                                  data-quesVal="friends"
                                  checked={checkedQues9FriendsNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques9Relatives"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="9"
                                  id="inlineRadio4"
                                  data-quesVal="relatives"
                                  checked={checkedQues9RelativesYes}

                                />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques9Relatives"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="9"
                                  id="inlineRadio4"
                                  data-quesVal="relatives"
                                  checked={checkedQues9RelativesNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques9Strangers"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="9"
                                  id="inlineRadio4"
                                  data-quesVal="strangers"
                                  checked={checkedQues9StrangersYes}

                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques9Strangers"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="9"
                                  id="inlineRadio4"
                                  checked={checkedQues9StrangersNo}
                                  data-quesVal="strangers"

                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques9Teacher"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="9"
                                  id="inlineRadio4"
                                  data-quesVal="teachers"
                                  checked={checkedQues9TeachersYes}
                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques9Teacher"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="9"
                                  id="inlineRadio4"
                                  data-quesVal="teachers"
                                  checked={checkedQues9TeachersNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input
                                custom-check"
                                type="radio"
                                name="ques9College"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="9"
                                  id="inlineRadio4"
                                  data-quesVal="college"
                                  checked={checkedQues9CollegeYes}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques9Colelge"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="9"
                                  id="inlineRadio4"
                                  data-quesVal="college"
                                  checked={checkedQues9CollegeNo}

                                />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                      </tr>
                      <tr>
                        <td className="first-td">
                        {t('M1chapter04screen07Text4')}

                        </td>
                        <td>


                          <div className="d-flex">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input custom-check"
                              type="radio"

                              name="ques10Parents"
                              defaultValue="yes"
                              onClick={saveRadio}
                              data-quesId="10"
                              data-quesVal="parents"
                              id="inlineRadio4"
                              checked={checkedQues10ParentsYes}

                              />
                              <label className="form-check-label" htmlFor="inlineRadio1">{t('yes')}</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input custom-check"
                              type="radio"

                                name="ques10Parents"
                              defaultValue="no"
                              onClick={saveRadio}
                              data-quesId="10"
                              id="inlineRadio4"
                              data-quesVal="parents"
                              checked={checkedQues10ParentsNo}

                                />
                              <label className="form-check-label " htmlFor="inlineRadio2">{t('no')}</label>
                            </div>
                          </div>



                        </td>
                        <td>

                            <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"

                                  name="ques10Siblings"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="10"
                                  id="inlineRadio4"
                                  data-quesVal="siblings"
                                  checked={checkedQues10SiblingsYes}
                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques10Siblings"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="10"
                                  data-quesVal="siblings"
                                  id="inlineRadio4"
                                  checked={checkedQues10SiblingsNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>
                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques10Friends"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="10"
                                  id="inlineRadio4"
                                  data-quesVal="friends"
                                  checked={checkedQues10FriendsYes}

                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques10Friends"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="10"
                                  id="inlineRadio4"
                                  data-quesVal="friends"
                                  checked={checkedQues10FriendsNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques10Relatives"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="10"
                                  id="inlineRadio4"
                                  data-quesVal="relatives"
                                  checked={checkedQues10RelativesYes}

                                />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques10Relatives"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="10"
                                  id="inlineRadio4"
                                  data-quesVal="relatives"
                                  checked={checkedQues10RelativesNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques10Strangers"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="10"
                                  id="inlineRadio4"
                                  data-quesVal="strangers"
                                  checked={checkedQues10StrangersYes}

                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques10Strangers"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="10"
                                  id="inlineRadio4"
                                  checked={checkedQues10StrangersNo}
                                  data-quesVal="strangers"

                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques10Teacher"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="10"
                                  id="inlineRadio4"
                                  data-quesVal="teachers"
                                  checked={checkedQues10TeachersYes}
                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques10Teacher"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="10"
                                  id="inlineRadio4"
                                  data-quesVal="teachers"
                                  checked={checkedQues10TeachersNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input
                                custom-check"
                                type="radio"
                                name="ques10College"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="10"
                                  id="inlineRadio4"
                                  data-quesVal="college"
                                  checked={checkedQues10CollegeYes}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques10Colelge"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="10"
                                  id="inlineRadio4"
                                  data-quesVal="college"
                                  checked={checkedQues10CollegeNo}

                                />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                      </tr>
                      <tr>
                        <td className="first-td">
                        {t('M1chapter04screen07Text5')}

                        </td>
                        <td>


                          <div className="d-flex">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input custom-check"
                              type="radio"

                              name="ques11Parents"
                              defaultValue="yes"
                              onClick={saveRadio}
                              data-quesId="11"
                              data-quesVal="parents"
                              id="inlineRadio4"
                              checked={checkedQues11ParentsYes}

                              />
                              <label className="form-check-label" htmlFor="inlineRadio1">{t('yes')}</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input custom-check"
                              type="radio"

                                name="ques11Parents"
                              defaultValue="no"
                              onClick={saveRadio}
                              data-quesId="11"
                              id="inlineRadio4"
                              data-quesVal="parents"
                              checked={checkedQues11ParentsNo}

                                />
                              <label className="form-check-label " htmlFor="inlineRadio2">{t('no')}</label>
                            </div>
                          </div>



                        </td>
                        <td>

                            <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"

                                  name="ques11Siblings"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="11"
                                  id="inlineRadio4"
                                  data-quesVal="siblings"
                                  checked={checkedQues11SiblingsYes}
                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques11Siblings"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="11"
                                  data-quesVal="siblings"
                                  id="inlineRadio4"
                                  checked={checkedQues11SiblingsNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>
                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques11Friends"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="11"
                                  id="inlineRadio4"
                                  data-quesVal="friends"
                                  checked={checkedQues11FriendsYes}

                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques11Friends"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="11"
                                  id="inlineRadio4"
                                  data-quesVal="friends"
                                  checked={checkedQues11FriendsNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques11Relatives"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="11"
                                  id="inlineRadio4"
                                  data-quesVal="relatives"
                                  checked={checkedQues11RelativesYes}

                                />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques11Relatives"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="11"
                                  id="inlineRadio4"
                                  data-quesVal="relatives"
                                  checked={checkedQues11RelativesNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques11Strangers"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="11"
                                  id="inlineRadio4"
                                  data-quesVal="strangers"
                                  checked={checkedQues11StrangersYes}

                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques11Strangers"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="11"
                                  id="inlineRadio4"
                                  checked={checkedQues11StrangersNo}
                                  data-quesVal="strangers"

                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques11Teacher"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="11"
                                  id="inlineRadio4"
                                  data-quesVal="teachers"
                                  checked={checkedQues11TeachersYes}
                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques11Teacher"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="11"
                                  id="inlineRadio4"
                                  data-quesVal="teachers"
                                  checked={checkedQues11TeachersNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input
                                custom-check"
                                type="radio"
                                name="ques11College"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="11"
                                  id="inlineRadio4"
                                  data-quesVal="college"
                                  checked={checkedQues11CollegeYes}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques11Colelge"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="11"
                                  id="inlineRadio4"
                                  data-quesVal="college"
                                  checked={checkedQues11CollegeNo}

                                />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                      </tr>
                      <tr>
                        <td className="first-td">
                        {t('M1chapter04screen07Text6')}

                        </td>
                        <td>


                          <div className="d-flex">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input custom-check"
                              type="radio"

                              name="ques12Parents"
                              defaultValue="yes"
                              onClick={saveRadio}
                              data-quesId="12"
                              data-quesVal="parents"
                              id="inlineRadio4"
                              checked={checkedQues12ParentsYes}

                              />
                              <label className="form-check-label" htmlFor="inlineRadio1">{t('yes')}</label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input custom-check"
                              type="radio"

                                name="ques12Parents"
                              defaultValue="no"
                              onClick={saveRadio}
                              data-quesId="12"
                              id="inlineRadio4"
                              data-quesVal="parents"
                              checked={checkedQues12ParentsNo}

                                />
                              <label className="form-check-label " htmlFor="inlineRadio2">{t('no')}</label>
                            </div>
                          </div>



                        </td>
                        <td>

                            <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"

                                  name="ques12Siblings"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="12"
                                  id="inlineRadio4"
                                  data-quesVal="siblings"
                                  checked={checkedQues12SiblingsYes}
                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques12Siblings"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="12"
                                  data-quesVal="siblings"
                                  id="inlineRadio4"
                                  checked={checkedQues12SiblingsNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>
                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques12Friends"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="12"
                                  id="inlineRadio4"
                                  data-quesVal="friends"
                                  checked={checkedQues12FriendsYes}

                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques12Friends"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="12"
                                  id="inlineRadio4"
                                  data-quesVal="friends"
                                  checked={checkedQues12FriendsNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input
                                className="form-check-input custom-check"
                                type="radio"
                                name="ques12Relatives"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="12"
                                  id="inlineRadio4"
                                  data-quesVal="relatives"
                                  checked={checkedQues12RelativesYes}

                                />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques12Relatives"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="12"
                                  id="inlineRadio4"
                                  data-quesVal="relatives"
                                  checked={checkedQues12RelativesNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques12Strangers"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="12"
                                  id="inlineRadio4"
                                  data-quesVal="strangers"
                                  checked={checkedQues12StrangersYes}

                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques12Strangers"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="12"
                                  id="inlineRadio4"
                                  checked={checkedQues12StrangersNo}
                                  data-quesVal="strangers"

                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques12Teacher"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="12"
                                  id="inlineRadio4"
                                  data-quesVal="teachers"
                                  checked={checkedQues12TeachersYes}
                                  />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques12Teacher"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="12"
                                  id="inlineRadio4"
                                  data-quesVal="teachers"
                                  checked={checkedQues12TeachersNo}
                                  />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                        <td>


                          <div className="d-flex">
                              <div className="form-check form-check-inline">
                                <input className="form-check-input
                                custom-check"
                                type="radio"
                                name="ques12College"
                                  defaultValue="yes"
                                  onClick={saveRadio}
                                  data-quesId="12"
                                  id="inlineRadio4"
                                  data-quesVal="college"
                                  checked={checkedQues12CollegeYes}
                                />
                                <label className="form-check-label" htmlFor="inlineRadio3">{t('yes')}</label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input className="form-check-input custom-check"
                                type="radio"
                                name="ques12Colelge"
                                  defaultValue="no"
                                  onClick={saveRadio}
                                  data-quesId="12"
                                  id="inlineRadio4"
                                  data-quesVal="college"
                                  checked={checkedQues12CollegeNo}

                                />
                                <label className="form-check-label " htmlFor="inlineRadio4">{t('no')}</label>
                              </div>
                            </div>

                        </td>
                      </tr>
                    </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
              <PrevBtn link="/module-01/chapter-04-screen-06"/>

                {/* <NextBtn link="/module-02/chapter-04-screen-08" text="Go Next"/> */}
                <BtnDisabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter04Screen07;
