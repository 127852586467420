import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen08 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [checkedQues13Option1, setCheckedQues13Option1] = useState('');
  const [checkedQues13Option2, setCheckedQues13Option2] = useState('');
  const [checkedQues13Val, setCheckedQues13Val] = useState('');
  const [checkedQues14Option1, setCheckedQues14Option1] = useState('');
  const [checkedQues14Option2, setCheckedQues14Option2] = useState('');
  const [checkedQues14Val, setCheckedQues14Val] = useState('');
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    // alert(quesId);
    checkedVal(quesId,e.target.value)
  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        },
        "ques9": {
          "ans": "",
          "ques_id": "9"
        },
        "ques10": {
          "ans": "",
          "ques_id": "10"
        },
        "ques11": {
          "ans": "",
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        },
        "ques13": {
          "ans": checkedQues13Val,
          "ques_id": "13"
        },
        "ques14": {
          "ans": checkedQues14Val,
          "ques_id": "14"
        }
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/SelfChapter03',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-01/chapter-03-screen-09')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const checkedVal = (param,ans) => {
    if (param === '13') {
      if (ans === 'option1') {
        setCheckedQues13Option1('checked')
        setCheckedQues13Option2('')

      } else if (ans === 'option2') {
        setCheckedQues13Option1('')
        setCheckedQues13Option2('checked')

        }
        setCheckedQues13Val(ans)
    }
    if (param === '14') {
      if (ans === 'option1') {
        setCheckedQues14Option1('checked')
        setCheckedQues14Option2('')

      } else if (ans === 'option2') {
        setCheckedQues14Option1('')
        setCheckedQues14Option2('checked')

        }
        setCheckedQues14Val(ans)
    }

  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/SelfChapter03Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  const BtnDisabled = () => {
    if (checkedQues13Val !== '' && checkedQues14Val !== '' ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="2/4" percentage="42" star="42" />
          </div>
        </div>
        <div className="d-flex">
            <Sidebar />

          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-between mt-3">
              <h2 className="h2 mb-1"> {t('apply')}</h2>
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h4 className="mb-3">
                    {t('M1chapter03screen08Text1')}

                  </h4>
                  <p className="pn ">
                  <span><b>1.</b> </span>
                    <b>
                      {t('M1chapter03screen08Text2')}
                    </b>
                  </p>
                  <div className="d-flex mt-2 div-table-intend" >
                    <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="a"
                      id=""
                      defaultValue="option1"
                      onClick={saveRadio}
                      data-quesId="13"
                      checked={checkedQues13Option1}
                    />
                    <label className="form-check-label" htmlFor="">
                    {t('M1chapter03screen08Text3')}

                    </label>
                    </div>
                  </div>
                  <div className="d-flex mt-2 div-table-intend">
                    <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="a"
                      id=""
                      defaultValue="option2"
                      onClick={saveRadio}
                      data-quesId="13"
                      checked={checkedQues13Option2}
                    />
                    <label className="form-check-label over-content" htmlFor="">
                    {t('M1chapter03screen08Text4')}
                    </label>
                    </div>
                  </div>
                  <p className="pn mt-3">
                  <span><b>2.</b> </span>
                    <b>
                    {t('M1chapter03screen08Text5')}

                    </b>
                  </p>
                  <div className="d-flex mt-2 div-table-intend">
                    <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="b"
                      id=""
                      defaultValue="option1"
                      onClick={saveRadio}
                      data-quesId="14"
                      checked={checkedQues14Option1}
                    />
                    <label className="form-check-label over-content" htmlFor="">
                    {t('M1chapter03screen08Text6')}

                    </label>
                    </div>
                  </div>
                  <div className="d-flex mt-2 div-table-intend">
                    <div className="form-check form-check-inline">
                    <input
                      className="form-check-input custom-check"
                      type="radio"
                      name="b"
                      id=""
                      defaultValue="option2"
                      onClick={saveRadio}
                      data-quesId="14"
                      checked={checkedQues14Option2}
                    />
                    <label className="form-check-label over-content" htmlFor="">
                    {t('M1chapter03screen08Text7')}

                    </label>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
              <PrevBtn link="/module-01/chapter-03-screen-07"/>

                <BtnDisabled/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Chapter03Screen08;
