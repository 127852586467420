import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen10 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [checkedQues14, setCheckedQues14] = useState([]);
  const [typea14Checked, setTypeA14Checked] = useState();
  const [typeb14Checked, setTypeB14Checked] = useState();
  const [typec14Checked, setTypeC14Checked] = useState();
  const [typed14Checked, setTypeD14Checked] = useState();

  const [checkedQues15, setCheckedQues15] = useState([]);
  const [typea15Checked, setTypeA15Checked] = useState();
  const [typeb15Checked, setTypeB15Checked] = useState();
  const [typec15Checked, setTypeC15Checked] = useState();
  const [typed15Checked, setTypeD15Checked] = useState();


  const [checkedQues16, setCheckedQues16] = useState([]);
  const [typea16Checked, setTypeA16Checked] = useState();
  const [typeb16Checked, setTypeB16Checked] = useState();
  const [typec16Checked, setTypeC16Checked] = useState();
  const [typed16Checked, setTypeD16Checked] = useState();


  const [checkedQues17, setCheckedQues17] = useState([]);
  const [typea17Checked, setTypeA17Checked] = useState();
  const [typeb17Checked, setTypeB17Checked] = useState();
  const [typec17Checked, setTypeC17Checked] = useState();
  const [typed17Checked, setTypeD17Checked] = useState();


  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveGenderBased = (e) => {

    const quesId = e.target.getAttribute("data-quesId");

    const res = e.target.value;
    const checked = e.target.checked;
    checkBoxChecked(res, checked);
    if (quesId == '14') {

      setCheckedQues14(checkedQues14 => [...checkedQues14, res]);
    }
    if (quesId == '15') {

      setCheckedQues15(checkedQues15 => [...checkedQues15, res]);
    }

    if (quesId == '16') {

      setCheckedQues16(checkedQues16 => [...checkedQues16, res]);
    }

    if (quesId == '17') {

      setCheckedQues17(checkedQues17 => [...checkedQues17, res]);
    }

  }
  const saveAns = () => {

    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques14": {
          "ans": checkedQues14.join(','),
          "ques_id": "14"
        },
        "ques15": {
          "ans": checkedQues15.join(','),
          "ques_id": "15"
        },
        "ques16": {
          "ans": checkedQues16.join(','),
          "ques_id": "16"
        },
       "ques17": {
          "ans": checkedQues17.join(','),
          "ques_id": "17"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": "",
          "ques_id": "7"
        },
        "ques8": {
          "ans": "",
          "ques_id": "8"
        }

      }
    });
    var config = {
      method: 'post',
      url: BaseURL + '/MasculChapter02',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate('/module-04/chapter-02-screen-11')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues14.length > 0) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };


  const checkedVal = (param, ans) => {


    if (param === '14') {
      const ansArr = ans.split(',');
      ansArr.map((el) => {
        const checked = true;
        checkBoxChecked(el, checked, param);
      })
      setCheckedQues14(ansArr)
    }

    if (param === '15') {
      const ansArr = ans.split(',');
      ansArr.map((el) => {
        const checked = true;
        checkBoxChecked(el, checked, param);
      })
      setCheckedQues15(ansArr)
    }

    if (param === '16') {
      const ansArr = ans.split(',');
      ansArr.map((el) => {
        const checked = true;
        checkBoxChecked(el, checked, param);
      })
      setCheckedQues16(ansArr)
    }

    if (param === '17') {
      const ansArr = ans.split(',');
      ansArr.map((el) => {
        const checked = true;
        checkBoxChecked(el, checked, param);
      })
      setCheckedQues17(ansArr)
    }


  }

  const checkBoxChecked = (val, checked, param) => {

    if (param == '14') {


      if (val === 'type a') {
        if (checked === true) {
          setTypeA14Checked('checked');

        } else {
          setTypeA14Checked('');
        }
      }

      if (val === 'type b') {
        if (checked === true) {
          setTypeB14Checked('checked');
        } else {
          setTypeB14Checked('');
        }
      }

      if (val === 'type c') {
        if (checked === true) {
          setTypeC14Checked('checked');
        } else {
          setTypeC14Checked('');
        }
      }

      if (val === 'type d') {
        if (checked === true) {
          setTypeD14Checked('checked');
        } else {
          setTypeD14Checked('');
        }
      }
    }


    if (param == '15') {


      if (val === 'type a') {
        if (checked === true) {
          setTypeA15Checked('checked');

        } else {
          setTypeA15Checked('');
        }
      }

      if (val === 'type b') {
        if (checked === true) {
          setTypeB15Checked('checked');
        } else {
          setTypeB15Checked('');
        }
      }

      if (val === 'type c') {
        if (checked === true) {
          setTypeC15Checked('checked');
        } else {
          setTypeC15Checked('');
        }
      }

      if (val === 'type d') {
        if (checked === true) {
          setTypeD15Checked('checked');
        } else {
          setTypeD15Checked('');
        }
      }

    }


    if (param == '16') {


      if (val === 'type a') {
        if (checked === true) {
          setTypeA16Checked('checked');

        } else {
          setTypeA16Checked('');
        }
      }

      if (val === 'type b') {
        if (checked === true) {
          setTypeB16Checked('checked');
        } else {
          setTypeB16Checked('');
        }
      }

      if (val === 'type c') {
        if (checked === true) {
          setTypeC16Checked('checked');
        } else {
          setTypeC16Checked('');
        }
      }

      if (val === 'type d') {
        if (checked === true) {
          setTypeD16Checked('checked');
        } else {
          setTypeD16Checked('');
        }
      }

    }



    if (param == '17') {


      if (val === 'type a') {
        if (checked === true) {
          setTypeA17Checked('checked');

        } else {
          setTypeA17Checked('');
        }
      }

      if (val === 'type b') {
        if (checked === true) {
          setTypeB17Checked('checked');
        } else {
          setTypeB17Checked('');
        }
      }

      if (val === 'type c') {
        if (checked === true) {
          setTypeC17Checked('checked');
        } else {
          setTypeC17Checked('');
        }
      }

      if (val === 'type d') {
        if (checked === true) {
          setTypeD17Checked('checked');
        } else {
          setTypeD17Checked('');
        }
      }

    }













  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/MasculChapter02Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(), result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="1/5" percentage="20" star="20" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set lavel-content-set">
              <div className="row align-items-center justify-content-between">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="row">
                    <div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >


                      <p className="mb-2 mt-3 ">
                        <b>
                          {t('M4chapter02screen09Text2')}
                        </b>
                      </p>
                    </div>
                    <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle"
                            id="mycirle"

                            defaultValue="type a"
                            onClick={saveGenderBased}
                            checked={typea14Checked}
                            data-quesId="14"
                          />
                          <label className="form-check-label" htmlFor="mycirle">
                            {" "}
                            {t('typea')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle"
                            id="mycirle2"
                            defaultValue="type b"
                            onClick={saveGenderBased}
                            checked={typeb14Checked}
                            data-quesId="14"
                          />
                          <label className="form-check-label" htmlFor="mycirle2">
                            {t('typeb')}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle"
                            id="mycirle3"
                            defaultValue="type c"
                            onClick={saveGenderBased}
                            checked={typec14Checked}
                            data-quesId="14"
                          />
                          <label className="form-check-label" htmlFor="mycirle3">
                            {" "}
                            {t('typec')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle"
                            id="mycirle4"
                            defaultValue="type d"
                            onClick={saveGenderBased}
                            checked={typed14Checked}
                            data-quesId="14"
                          />
                          <label className="form-check-label" htmlFor="mycirle4">
                            {" "}
                            {t('typed')}{" "}
                          </label>
                        </div>
                      </div>




                    </div>

                  </div>


                  <div className="row">
                    <div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >


                      <p className="mb-2 mt-3 ">
                        <b>
                          {t('M4chapter02screen09Text3')}
                        </b>
                      </p>
                    </div>
                    <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle123"
                            id="mycirle10"

                            defaultValue="type a"
                            onClick={saveGenderBased}
                            checked={typea15Checked}
                            data-quesId="15"
                          />
                          <label className="form-check-label" htmlFor="namecircle123">
                            {" "}
                            {t('typea')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle1234"
                            id="mycirle101"
                            defaultValue="type b"
                            onClick={saveGenderBased}
                            checked={typeb15Checked}
                            data-quesId="15"
                          />
                          <label className="form-check-label" htmlFor="namecircle1234">
                            {t('typeb')}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle12345"
                            id="mycirle102"
                            defaultValue="type c"
                            onClick={saveGenderBased}
                            checked={typec15Checked}
                            data-quesId="15"
                          />
                          <label className="form-check-label" htmlFor="namecircle12345">
                            {" "}
                            {t('typec')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle123456"
                            id="mycirle103"
                            defaultValue="type d"
                            onClick={saveGenderBased}
                            checked={typed15Checked}
                            data-quesId="15"
                          />
                          <label className="form-check-label" htmlFor="namecircle123456">
                            {" "}
                            {t('typed')}{" "}
                          </label>
                        </div>
                      </div>




                    </div>

                  </div>


                  <div className="row">
                    <div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >


                      <p className="mb-2 mt-3 ">
                        <b>
                          {t('M4chapter02screen10Text1')}
                        </b>
                      </p>
                    </div>
                    <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle123"
                            id="mycirle10"

                            defaultValue="type a"
                            onClick={saveGenderBased}
                            checked={typea16Checked}
                            data-quesId="16"
                          />
                          <label className="form-check-label" htmlFor="namecircle123">
                            {" "}
                            {t('typea')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle1234"
                            id="mycirle101"
                            defaultValue="type b"
                            onClick={saveGenderBased}
                            checked={typeb16Checked}
                            data-quesId="16"
                          />
                          <label className="form-check-label" htmlFor="namecircle1234">
                            {t('typeb')}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle12345"
                            id="mycirle102"
                            defaultValue="type c"
                            onClick={saveGenderBased}
                            checked={typec16Checked}
                            data-quesId="16"
                          />
                          <label className="form-check-label" htmlFor="namecircle12345">
                            {" "}
                            {t('typec')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle123456"
                            id="mycirle103"
                            defaultValue="type d"
                            onClick={saveGenderBased}
                            checked={typed16Checked}
                            data-quesId="16"
                          />
                          <label className="form-check-label" htmlFor="namecircle123456">
                            {" "}
                            {t('typed')}{" "}
                          </label>
                        </div>
                      </div>




                    </div>

                  </div>


                  <div className="row">
                    <div className="col-lg-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >


                      <p className="mb-2 mt-3 ">
                        <b>
                          {t('M4chapter02screen10Text2')}
                        </b>
                      </p>
                    </div>
                    <div className="col-lg-6 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s" >
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle123"
                            id="mycirle10"

                            defaultValue="type a"
                            onClick={saveGenderBased}
                            checked={typea17Checked}
                            data-quesId="17"
                          />
                          <label className="form-check-label" htmlFor="namecircle123">
                            {" "}
                            {t('typea')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle1234"
                            id="mycirle101"
                            defaultValue="type b"
                            onClick={saveGenderBased}
                            checked={typeb17Checked}
                            data-quesId="17"
                          />
                          <label className="form-check-label" htmlFor="namecircle1234">
                            {t('typeb')}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle12345"
                            id="mycirle102"
                            defaultValue="type c"
                            onClick={saveGenderBased}
                            checked={typec17Checked}
                            data-quesId="17"
                          />
                          <label className="form-check-label" htmlFor="namecircle12345">
                            {" "}
                            {t('typec')}{" "}
                          </label>
                        </div>
                      </div>
                      <div className="d-flex ">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input custom-check"
                            type="checkbox"
                            name="namecircle1234563"
                            id="mycirle1033"
                            defaultValue="type d"
                            onClick={saveGenderBased}
                            checked={typed17Checked}
                            data-quesId="17"
                          />
                          <label className="form-check-label" htmlFor="namecircle1234563">
                            {" "}
                            {t('typed')}{" "}
                          </label>
                        </div>
                      </div>




                    </div>

                  </div>



                </div>




              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                  <PrevBtn link="/module-04/chapter-02-screen-07" />

                  {/* <NextBtn link="/module-04/chapter-02-screen-03" text="Go Next"/> */}
                  <BtnDisabled />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen10;
