import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";

import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Module1FinalQuizScreen01 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [checkedQues1Yes, setCheckedQues1Yes] = useState('');
  const [checkedQues1No, setCheckedQues1No] = useState('');
  const [checkedQues1Val, setCheckedQues1Val] = useState('');

  const [checkedQues2Yes, setCheckedQues2Yes] = useState('');
  const [checkedQues2No, setCheckedQues2No] = useState('');
  const [checkedQues2Val, setCheckedQues2Val] = useState('');

  const [checkedQues3Yes, setCheckedQues3Yes] = useState('');
  const [checkedQues3No, setCheckedQues3No] = useState('');
  const [checkedQues3Val, setCheckedQues3Val] = useState('');

  const [checkedQues4Yes, setCheckedQues4Yes] = useState('');
  const [checkedQues4No, setCheckedQues4No] = useState('');
  const [checkedQues4Val, setCheckedQues4Val] = useState('');

  const [checkedQues5Yes, setCheckedQues5Yes] = useState('');
  const [checkedQues5No, setCheckedQues5No] = useState('');
  const [checkedQues5Val, setCheckedQues5Val] = useState('');

  const [checkedQues6Yes, setCheckedQues6Yes] = useState('');
  const [checkedQues6No, setCheckedQues6No] = useState('');
  const [checkedQues6Val, setCheckedQues6Val] = useState('');

  const [checkedQues7Yes, setCheckedQues7Yes] = useState('');
  const [checkedQues7No, setCheckedQues7No] = useState('');
  const [checkedQues7Val, setCheckedQues7Val] = useState('');

  const [checkedQues8Yes, setCheckedQues8Yes] = useState('');
  const [checkedQues8No, setCheckedQues8No] = useState('');
  const [checkedQues8Val, setCheckedQues8Val] = useState('');

  const [checkedQues9Yes, setCheckedQues9Yes] = useState('');
  const [checkedQues9No, setCheckedQues9No] = useState('');
  const [checkedQues9Val, setCheckedQues9Val] = useState('');

  const [checkedQues10Yes, setCheckedQues10Yes] = useState('');
  const [checkedQues10No, setCheckedQues10No] = useState('');
  const [checkedQues10Val, setCheckedQues10Val] = useState('');

  const [checkedQues11Yes, setCheckedQues11Yes] = useState('');
  const [checkedQues11No, setCheckedQues11No] = useState('');
  const [checkedQues11Val, setCheckedQues11Val] = useState('');

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    // alert(e.target.value);
    checkedVal(quesId,e.target.value)

  }

  const saveAns = () => {

    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": checkedQues1Val,
          "ques_id": "1"
        },
        "ques2": {
          "ans": checkedQues2Val,
          "ques_id": "2"
        },
        "ques3": {
          "ans": checkedQues3Val,
          "ques_id": "3"
        },
        "ques4": {
          "ans": checkedQues4Val,
          "ques_id": "4"
        },
        "ques5": {
          "ans": checkedQues5Val,
          "ques_id": "5"
        },
        "ques6": {
          "ans": checkedQues6Val,
          "ques_id": "6"
        },
        "ques7": {
          "ans": checkedQues7Val,
          "ques_id": "7"
        },
        "ques8": {
          "ans": checkedQues8Val,
          "ques_id": "8"
        },
        "ques9": {
          "ans": checkedQues9Val,
          "ques_id": "9"
        },
        "ques10": {
          "ans": checkedQues10Val,
          "ques_id": "10"
        },
        "ques11": {
          "ans": checkedQues11Val,
          "ques_id": "11"
        },
        "ques12": {
          "ans": "",
          "ques_id": "12"
        },
        "ques13": {
          "ans": "",
          "ques_id": "13"
        },
        "ques14": {
          "ans": "",
          "ques_id": "14"
        },
        "ques15": {
          "ans": "",
          "ques_id": "15"
        },
        "ques16": {
          "ans": "",
          "ques_id": "16"
        },
        "ques17": {
          "ans": "",
          "ques_id": "17"
        },
        "ques18": {
          "ans": "",
          "ques_id": "18"
        },
        "ques19": {
          "ans": "",
          "ques_id": "19"
        },
        "ques20": {
          "ans": "",
          "ques_id": "20"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/GenderFinalQuiz',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if(response.data.success === true)
        {
          navigate('/module-02/module-01-finalquiz-screen-02')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues1Val !== '' && checkedQues2Val !== '' && checkedQues3Val !== ''&& checkedQues4Val !== ''
    && checkedQues5Val !== ''&& checkedQues6Val !== ''&& checkedQues7Val !== ''&& checkedQues8Val !== ''
    && checkedQues9Val !== ''&& checkedQues10Val !== ''&& checkedQues11Val !== ''
    ) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };


  const checkedVal = (param,ans) => {




    if (param === '1') {
      if (ans === 'yes') {
        // alert();
        setCheckedQues1Yes('checked')
        setCheckedQues1No('')
        setCheckedQues1Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues1No('checked')
          setCheckedQues1Yes('')
          setCheckedQues1Val(ans)
        }
      }
    }

    if (param === '2') {
      if (ans === 'yes') {
        setCheckedQues2Yes('checked')
        setCheckedQues2No('')
        setCheckedQues2Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues2No('checked')
          setCheckedQues2Yes('')
          setCheckedQues2Val(ans)
        }
      }
    }

    if (param === '3') {
      if (ans === 'yes') {
        setCheckedQues3Yes('checked')
        setCheckedQues3No('')
        setCheckedQues3Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues3No('checked')
          setCheckedQues3Yes('')
          setCheckedQues3Val(ans)
        }
      }
    }

    if (param === '4') {
      if (ans === 'yes') {
        setCheckedQues4Yes('checked')
        setCheckedQues4No('')
        setCheckedQues4Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues4No('checked')
          setCheckedQues4Yes('')
          setCheckedQues4Val(ans)
        }
      }
    }

    if (param === '5') {
      if (ans === 'yes') {
        setCheckedQues5Yes('checked')
        setCheckedQues5No('')
        setCheckedQues5Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues5No('checked')
          setCheckedQues5Yes('')
          setCheckedQues5Val(ans)
        }
      }
    }

    if (param === '6') {
      if (ans === 'yes') {
        setCheckedQues6Yes('checked')
        setCheckedQues6No('')
        setCheckedQues6Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues6No('checked')
          setCheckedQues6Yes('')
          setCheckedQues6Val(ans)
        }
      }
    }

    if (param === '7') {
      if (ans === 'yes') {
        setCheckedQues7Yes('checked')
        setCheckedQues7No('')
        setCheckedQues7Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues7No('checked')
          setCheckedQues7Yes('')
          setCheckedQues7Val(ans)
        }
      }
    }

    if (param === '8') {
      if (ans === 'yes') {
        setCheckedQues8Yes('checked')
        setCheckedQues8No('')
        setCheckedQues8Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues8No('checked')
          setCheckedQues8Yes('')
          setCheckedQues8Val(ans)
        }
      }
    }

    if (param === '9') {
      if (ans === 'yes') {
        setCheckedQues9Yes('checked')
        setCheckedQues9No('')
        setCheckedQues9Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues9No('checked')
          setCheckedQues9Yes('')
          setCheckedQues9Val(ans)
        }
      }
    }

    if (param === '10') {
      if (ans === 'yes') {
        setCheckedQues10Yes('checked')
        setCheckedQues10No('')
        setCheckedQues10Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues10No('checked')
          setCheckedQues10Yes('')
          setCheckedQues10Val(ans)
        }
      }
    }

    if (param === '11') {
      if (ans === 'yes') {
        setCheckedQues11Yes('checked')
        setCheckedQues11No('')
        setCheckedQues11Val(ans)
      } else {
        if (ans === 'no') {
          setCheckedQues11No('checked')
          setCheckedQues11Yes('')
          setCheckedQues11Val(ans)
        }
      }
    }







  }

  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/GenderFinalQuizResult',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])




  return (

      <>
        <div className="wrapper">
          <div
            className="fixed-header"

          >
            <div className="container-fluid cf-set">
            <Header leftChapter="5/5" percentage="100" star="90" />
            </div>
          </div>
          <div className="d-flex">
            <Sidebar />
            <div className="data-adjust">
              <div className="container-fluid cf-set">
                <div className="row align-items-top justify-content-center">
                  <div
                    className="col-lg-12 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                  >
                    <h2 className="h2">{t('M2quizscreen01Text1')}</h2>
                    <p>
                      <b>
                      {t('M2quizscreen01Text2')}
                       
                      </b>
                    </p>
                    <div className="wiout-border-table without-bg table-padding-0">
                      <table className="table">
                        <tbody>
                          <tr>
                            <td>
                              1.  {t('M2quizscreen01Text3')}{" "}
                            </td>
                            <td className="wdth-table-yes">
                              <div className="d-flex align-items-center justify-content-end">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz"
                                    id="yes"
                                    defaultValue="yes"
                                    data-quesId="1"
                                    onClick={saveRadio}
                                    checked={checkedQues1Yes}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="yes"
                                  >
                                    {t('yes')}
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz"
                                    id="no"
                                    defaultValue="no"
                                    data-quesId="1"
                                    onClick={saveRadio}
                                    checked={checkedQues1No}
                                  />
                                  <label
                                    className="form-check-label "
                                    htmlFor="no"
                                  >
                                    {t('no')}
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              2. {t('M2quizscreen01Text4')}{" "}
                            </td>
                            <td className="wdth-table-yes">
                              <div className="d-flex align-items-center justify-content-end">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz2"
                                    id="yes2"
                                    defaultValue="yes"
                                    data-quesId="2"
                                    onClick={saveRadio}
                                    checked={checkedQues2Yes}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="yes2"
                                  >
                                    {t('yes')}
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz2"
                                    id="no2"
                                    defaultValue="no"
                                    data-quesId="2"
                                    onClick={saveRadio}
                                    checked={checkedQues2No}
                                  />
                                  <label
                                    className="form-check-label "
                                    htmlFor="no2"
                                  >
                                    {t('no')}
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              3. {t('M2quizscreen01Text5')}
                            </td>
                            <td className="wdth-table-yes">
                              <div className="d-flex align-items-center justify-content-end">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz3"
                                    id="yes3"
                                    defaultValue="yes"
                                    data-quesId="3"
                                    onClick={saveRadio}
                                    checked={checkedQues3Yes}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="yes3"
                                  >
                                    {t('yes')}
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz3"
                                    id="no3"
                                    defaultValue="no"
                                    data-quesId="3"
                                    onClick={saveRadio}
                                    checked={checkedQues3No}
                                  />
                                  <label
                                    className="form-check-label "
                                    htmlFor="no3"
                                  >
                                    {t('no')}
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              4. {t('M2quizscreen01Text6')}
                            </td>
                            <td className="wdth-table-yes">
                              <div className="d-flex align-items-center justify-content-end">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz4"
                                    id="yes4"
                                    defaultValue="yes"
                                    data-quesId="4"
                                    onClick={saveRadio}
                                    checked={checkedQues4Yes}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="yes4"
                                  >
                                    {t('yes')}
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz4"
                                    id="no4"
                                    defaultValue="no"
                                    data-quesId="4"
                                    onClick={saveRadio}
                                    checked={checkedQues4No}
                                  />
                                  <label
                                    className="form-check-label "
                                    htmlFor="no4"
                                  >
                                    {t('no')}
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              5. {t('M2quizscreen01Text7')}
                            </td>
                            <td className="wdth-table-yes">
                              <div className="d-flex align-items-center justify-content-end">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz5"
                                    id="yes5"

                                    defaultValue="yes"
                                    data-quesId="5"
                                    onClick={saveRadio}
                                    checked={checkedQues5Yes}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="yes5"
                                  >
                                    {t('yes')}
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz5"
                                    id="no5"
                                    defaultValue="no"
                                    data-quesId="5"
                                    onClick={saveRadio}
                                    checked={checkedQues5No}
                                  />
                                  <label
                                    className="form-check-label "
                                    htmlFor="no5"
                                  >
                                    {t('no')}
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              6. {t('M2quizscreen01Text8')}{" "}
                            </td>
                            <td className="wdth-table-yes">
                              <div className="d-flex align-items-center justify-content-end">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz6"
                                    id="yes6"
                                    defaultValue="yes"
                                    data-quesId="6"
                                    onClick={saveRadio}
                                    checked={checkedQues6Yes}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="yes6"
                                  >
                                    {t('yes')}
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz6"
                                    id="no6"
                                    defaultValue="no"
                                    data-quesId="6"
                                    onClick={saveRadio}
                                    checked={checkedQues6No}
                                  />
                                  <label
                                    className="form-check-label "
                                    htmlFor="no6"
                                  >
                                    {t('no')}
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              7.  {t('M2quizscreen01Text9')}{" "}
                            </td>
                            <td className="wdth-table-yes">
                              <div className="d-flex align-items-center justify-content-end">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz7"
                                    id="yes7"
                                    defaultValue="yes"
                                    data-quesId="7"
                                    onClick={saveRadio}
                                    checked={checkedQues7Yes}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="yes7"
                                  >
                                    {t('yes')}
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz7"
                                    id="no7"
                                    defaultValue="no"
                                    data-quesId="7"
                                    onClick={saveRadio}
                                    checked={checkedQues7No}
                                  />
                                  <label
                                    className="form-check-label "
                                    htmlFor="no7"
                                  >
                                    {t('no')}
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              8. {t('M2quizscreen01Text10')}{" "}
                            </td>
                            <td className="wdth-table-yes">
                              <div className="d-flex align-items-center justify-content-end">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz8"
                                    id="yes8"
                                    defaultValue="yes"
                                    data-quesId="8"
                                    onClick={saveRadio}
                                    checked={checkedQues8Yes}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="yes8"
                                  >
                                    {t('yes')}
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz8"
                                    id="no8"
                                    defaultValue="no"
                                    data-quesId="8"
                                    onClick={saveRadio}
                                    checked={checkedQues8No}
                                  />
                                  <label
                                    className="form-check-label "
                                    htmlFor="no8"
                                  >
                                    {t('no')}
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              9. {t('M2quizscreen01Text11')}{" "}
                            </td>
                            <td className="wdth-table-yes">
                              <div className="d-flex align-items-center justify-content-end">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz9"
                                    id="yes9"
                                    defaultValue="yes"
                                    data-quesId="9"
                                    onClick={saveRadio}
                                    checked={checkedQues9Yes}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="yes9"
                                  >
                                    {t('yes')}
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz9"
                                    id="no9"
                                    defaultValue="no"
                                    data-quesId="9"
                                    onClick={saveRadio}
                                    checked={checkedQues9No}
                                  />
                                  <label
                                    className="form-check-label "
                                    htmlFor="no9"
                                  >
                                    {t('no')}
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              10. {t('M2quizscreen01Text12')}
                            </td>
                            <td className="wdth-table-yes">
                              <div className="d-flex align-items-center justify-content-end">
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz10"
                                    id="yes10"
                                    defaultValue="yes"
                                    data-quesId="10"
                                    onClick={saveRadio}
                                    checked={checkedQues10Yes}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="yes10"
                                  >
                                    {t('yes')}
                                  </label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input custom-check"
                                    type="radio"
                                    name="finalquiz10"
                                    id="no10"
                                    defaultValue="no"
                                    data-quesId="10"
                                    onClick={saveRadio}
                                    checked={checkedQues10No}
                                  />
                                  <label
                                    className="form-check-label "
                                    htmlFor="no10"
                                  >
                                    {t('no')}
                                  </label>
                                </div>
                              </div>
                            </td>
                          </tr>

                          <tr >
                          <td className="">
                          <p class="pn"> <span>11.</span>{t('M2quizscreen01Text13')}</p>


                          </td>
                          <td className="wdth-table-yes">
                            <div className="d-flex align-items-center justify-content-end">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz12"
                                  id="yes"
                                  defaultValue="yes"
                                  data-quesId="11"
                                  onClick={saveRadio}
                                  checked={checkedQues11Yes}
                                />
                                <label className="form-check-label" htmlFor="yes">
                                {t('yes')}
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input custom-check"
                                  type="radio"
                                  name="finalquiz12"
                                  id="no"
                                  defaultValue="no"
                                  data-quesId="11"
                                  onClick={saveRadio}
                                  checked={checkedQues11No}
                                />
                                <label className="form-check-label " htmlFor="no">
                                {t('no')}
                                </label>
                              </div>
                            </div>
                          </td>
                        </tr>

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.3s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-02/chapter-05-screen-16" />
                {/* <NextBtn
                  link="/module-01/module-01-finalquiz-screen-02"
                  text="Go Next"
                /> */}
                <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

  );
};

export default Module1FinalQuizScreen01;
