import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen04 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="75" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-2">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<h3 className="h3 mt-3">{t('reflect')} </h3>
									<p className="mb-2  mt-3">{t('M3chapter04Screen04Text1')}</p>
									<a
										href="https://www.youtube.com/watch?v=fGoWLWS4-kU"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M3chapter04Screen04Text2')}</p>
											</div>
										</div>
									</a>
								</div>

								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="52%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-3-11.png"
											alt=""
										/>
									</center>
								</div>


							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-04-screen-02" />
								<NextBtn link="/module-03/chapter-04-screen-05" text={t('CommonBtnGoNext')} />



							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen04;
