import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen07 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper page-auto bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="0" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-4">
								<div
									className="col-lg-9 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3">{t('M3chapter01Screen07Text1')} </h3>
									<p className="mb-2 mt-3">{t('M3chapter01Screen07Text3N')}</p>
									{/* <p className="mb-2 mt-3"><b>{t('sex')} -</b> {t('M3chapter01Screen07Text2')} </p> */}
									<p className="mb-2 mt-3"><b>{t('M3chapter01Screen07Text3')} -</b>  {t('M3chapter01Screen07Text4')}  </p>
									{/* <p className="mb-2 mt-3"><b>{t('M3chapter01Screen07Text5')} –</b> {t('M3chapter01Screen07Text6')}   </p> */}
									<p className="mb-2 mt-3"><b>{t('M3chapter01Screen07Text7')} -</b> {t('M3chapter01Screen07Text8')}  </p>
									<p className="mb-2 mt-3"><b> {t('M3chapter01Screen07Text9')} –</b> {t('M3chapter01Screen07Text10')}   </p>

									<p className="mb-2 mt-3"><b>{t('M3chapter01Screen07Text11')}  –</b> {t('M3chapter01Screen07Text12')}  </p>
									<p className="mb-2 mt-3"><b>{t('M3chapter01Screen07Text13')} –</b> {t('M3chapter01Screen07Text14')}  </p>
									<p className="mb-2 mt-3"><b>{t('M3chapter01Screen07Text15')} –</b>{t('M3chapter01Screen07Text16')}   </p>
									<p className="mb-2 mt-3"><b>{t('M3chapter01Screen07Text17')} –</b>{t('M3chapter01Screen07Text18')}  </p>


								</div>

								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="60%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-1-7.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-01-screen-06" />
								<NextBtn link="/module-03/chapter-01-screen-08" text={t('CommonBtnGoNext')} />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen07;
