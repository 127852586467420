import axios from "axios";
import BaseURL from "../components/config/Baseurl";

// Base URL for the API
// const API_BASE_URL = "https://api.example.com";

// Create an Axios instance
const apiClient = axios.create({
  baseURL: BaseURL,
  timeout: 10000, // Optional: Set a timeout for requests
  headers: {
    "Content-Type": "application/json",
  },
});

// Add a request interceptor (optional)
// apiClient.interceptors.request.use(
//   (config) => {
//     // You can add an Authorization token or other headers here
//     const token = localStorage.getItem("token"); // Example: Get token from local storage
//     if (token) {
//     }
//     config.headers.Authorization = `Bearer ${token}`;
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// Add a response interceptor (optional)
apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // Handle errors globally
    console.error("API Error:", error);
    return Promise.reject(error);
  }
);

// Example API calls
// export const fetchUsers = async () => {
//   try {
//     const response = await apiClient.get("/users");
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };

export const saveViewPages = async (userData) => {
  try {
    const response = await apiClient.post("/saveViewPages", userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// export const deleteUser = async (userId) => {
//   try {
//     const response = await apiClient.delete(`/users/${userId}`);
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// };
