import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen35 = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="wrapper page-auto bg-bd-2">
        <div
          className="fixed-header"
        >
          <div className="container-fluid cf-set">
          <Header leftChapter="2/4" percentage="50" star="50" />
          </div>
        </div>
        <div className="d-flex">
					<Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-between pt-20 mt-1">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >

                  <h3 className="h3 mt-3">{t('apply')}   </h3>

                  <p className="mb-2  mt-3"><b>{t('M3chapter03Screen35Text1')}</b></p>
                  <p className="mb-2  mt-3">{t('M3chapter03Screen35Text2')} </p>
                  <p className="mb-2  mt-3">{t('M3chapter03Screen35Text3')} </p>
                  <p className="mb-2  mt-3">{t('M3chapter03Screen35Text4')}  </p>
                  <p className="mb-2  mt-3">{t('M3chapter03Screen35Text5')}</p>

                  <p className="mb-2 mb-7 mt-3">{t('M3chapter03Screen35Text6')}  </p>





                </div>






              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">

                <PrevBtn link="/module-03/chapter-03-screen-34"/>
                <NextBtn link="/module-03/chapter-03-screen-36" text={t('CommonBtnGoNext')}/>

              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default Chapter03Screen35;
