import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen18 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-between pt-20 mt-2">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>


									<h3 className="h3 mt-3">{t('engage')}:  </h3>
									<p className="mb-2  mt-3">{t('M3chapter03Screen18Text1')}  </p>
									<ul className="list-style-set list-input mt-3 mb-3">
										<li>{t('M3chapter03Screen18Text2')}  <input type="text" placeholder className="input-without-style form-control" /> {t('M3chapter03Screen18Text3')} <input type="text" placeholder className="input-without-style form-control" /> {t('M3chapter03Screen18Text4')} <input type="text" placeholder className="input-without-style form-control" /></li>
									</ul>
									<p className="mb-2  mt-3">{t('M3chapter03Screen18Text5')}  </p>


									<p className="mb-2  mt-3"><i>{t('M3chapter03Screen18Text6')}</i> </p>
									<ul className="list-style-set decimal mt-3 mb-3">
										<li>{t('M3chapter03Screen18Text7')}

										<input type="text" placeholder className="input-without-style form-control" />
										</li>

										<li>
											{t('M3chapter03Screen18Text8')}

											<input type="text" placeholder className="input-without-style form-control" />
										</li>
										<li>{t('M3chapter03Screen18Text9')}

										<input type="text" placeholder className="input-without-style form-control" />
										</li>
										<li>{t('M3chapter03Screen18Text10')}
										<input type="text" placeholder className="input-without-style form-control" />
											 </li>
									</ul>

								</div>




							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-03-screen-17" />
								<NextBtn link="/module-03/chapter-03-screen-20" text={t('CommonBtnGoNext')} />


							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Chapter03Screen18;
