import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter05Screen12 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="96" star="78" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-4">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h2">{t('learn')}</h2>
									<p className="">
										{t('M2chapter05screen12Text1')}

									</p>

									{/* <a href="https://www.youtube.com/watch?v=jhrjPXheGE8">
					<div className="d-flex align-items-center mange-icon">
						<div>
						<img src="/assets/img/chapter2/video-icon.png" alt="" />
						</div>
						<div>
						<p>
							Why Is It So Difficult For Us To Believe Women? |
							Breakthrough India
							</p>
						</div>
					</div>
					</a> */}

<iframe width="100%" height="360" src="https://www.youtube.com/embed/jhrjPXheGE8" title="Why Is It So Difficult For Us To Believe Women? | Breakthrough India" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>


									<p className="mt-2">{t('M2chapter05screen12Text2')} {" "} </p>

									<a
										href="https://www.thequint.com/neon/gender/transgender-day-of-remembrance-tracking-violence-against-lgbt-community-in-india#read-more"
										target="_blank"
									>
										<div className="d-flex align-items-center mange-icon">
											<div>
												<img src="/assets/img/chapter1/list-icon.png" alt="" />
											</div>
											<div>
												<p>
												{t('M2chapter05screen12Text3')}

												</p>
											</div>
										</div>
									</a>
								</div>
{/*
								<div
									className="col-lg-5 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.1s"
								>
									<iframe width="100%" height="360" src="https://www.youtube.com/embed/jhrjPXheGE8" title="Why Is It So Difficult For Us To Believe Women? | Breakthrough India" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
								</div> */}






							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-05-screen-11" />
								<NextBtn
									link="/module-02/chapter-05-screen-13"
									text={t('CommonBtnGoNext')}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen12;
