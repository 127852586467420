import React from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';



const Home = () => {

	const { t, i18n } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const user_details = JSON.parse(localStorage.getItem("user_details"));



		  const changeLanguage = (e) => {

			 let lng = e.target.value;
			 localStorage.setItem("selectLng", lng);
			i18n.changeLanguage(lng); // Change language dynamically
		  };

		  let selectLng = localStorage.getItem("selectLng");


	const logout = () => {
		localStorage.removeItem('user_details')
		navigate('/')

	}

	const goToProfile = () => {
		const pathdata = location.pathname
		localStorage.setItem('previous_link', JSON.stringify(pathdata));
		navigate("/profile")
	}


	const LoginMenu = () => {
		if (user_details === null || user_details === '' || user_details === '') {
			return (
				<>
					<ul className="nav nav-pills header-ul">

						<li className="nav-item">
						<div className="d-flex">

							<select className="manage-select" value={selectLng} onChange={changeLanguage}>
								<option value="en" >English </option>
								<option value="hi"  >Hindi </option>

							</select>
								{/* <Checked/> */}

								{/* <Select className="redesign" options={options} /> */}
							</div>

						</li>
						<li className="nav-item">
							<NavLink to="/register" className="header-btn">
								{" "}
								<i class="bi bi-people"></i> Sign up
							</NavLink>
						</li>
						<li className="nav-item">
							<NavLink to="/login" className="header-btn btn-gray-bg">
								{" "}
								<i class="bi bi-box-arrow-in-right"></i> Login
							</NavLink>
						</li>
					</ul>
				</>
			);
		} else {

			return (
				<>
					<ul className="nav nav-pills header-ul">
						<li className="nav-item">
							<button onClick={logout} className="header-btn">
								{" "}
								<i class="bi bi-box-arrow-right"></i> Logout
							</button>
						</li>
						<li className="nav-item">
							<button onClick={goToProfile} className="header-btn btn-gray-bg">
								{" "}
								<i class="bi bi-person-fill"></i> Profile
							</button>
						</li>

						<li className="nav-item">
							<NavLink to="/home" className="header-btn">
								{" "}
								<i class="bi bi-person-fill"></i> Dashboard
							</NavLink>
						</li>

					</ul>
				</>
			);



		}
	};

	const Module1 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink
						to="/module-01/chapter-01-intro-screen"
						className="home-box-btn"
					>
						{" "}
						Module 1
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 1{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module2 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink to="/module-02/chapter-01-intro-screen" className="home-box-btn">
						{" "}
						Module 2{" "}
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 2{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module3 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink to="/module-03/chapter-01-intro-screen" className="home-box-btn">
						{" "}
						Module 3{" "}
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 3{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module4 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink to="/module-04/module-04-intro-screen" className="home-box-btn">
						{" "}
						Module 4
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 4{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module5 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink to="/module-05/module-05-intro-screen" className="home-box-btn">
						{" "}
						Module 5
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/login" className="home-box-btn">
						{" "}
						Module 5{" "}
					</NavLink>
				</>
			)
		}
	}

	const Module6 = () => {
		if (user_details !== null) {
			return (
				<>
					<NavLink
						to="/module-06/module-06-intro-screen"
						className="home-box-btn"
					>
						{" "}
						Module 6
					</NavLink>
				</>
			)
		} else {
			return (
				<>
					<NavLink to="/module-06/module-06-intro-screen" className="home-box-btn">
						{" "}
						Module 6
					</NavLink>
				</>
			)
		}
	}


	return (
		<>
			<div className="wrapper page-auto">
				<div className="fixed-header   black-header">
					<div className="container-fluid cf-set">
						<header className="d-flex flex-wrap justify-content-center align-items-center">
							<NavLink
								to="/"
								className="d-flex align-items-center mb-md-0 me-md-auto  text-decoration-none"
							>
								<img
									className="brand-logo"
									src="/assets/img/logo-black.svg"
									alt=""
								/>
							</NavLink>
							<LoginMenu />
						</header>
					</div>
				</div>

				<div className="data-adjust ">
					<div className="container-fluid cf-set">
						<div className="row align-items-top  mt-3">
							<div className="col-lg-9  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">

								<h3 className="h3 mt-2"> {t('HText1')}</h3>
								<p>{t('HText2')}</p>

								<div className="row mt-3">

									<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3">
										<div className="home-box light-yellow-bg">
											<div className="price">{t('HText3')}</div>
											<div className="price-content"><i class="bi bi-translate"></i> {t('HText4')}</div>
										</div>
									</div>
									<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3">
										<div className="home-box light-pink-bg">
											<div className="price">{t('HText5')}</div>
											<div className="price-content"><i class="bi bi-alarm"></i> 30 {t('HText6')}</div>
										</div>
									</div>

									<div className="col-xs-6 col-sm-6 col-md-4 col-lg-3">
										<div className="home-box green-bg">
											<div className="price">{t('HText7')}</div>
											<div className="price-content"><i class="bi bi-patch-check"></i> {t('HText8')}</div>
										</div>
									</div>

								</div>

								<div className="mt-4">
									<ul className="nav nav-pills mb-3 list-set" id="pills-tab" role="tablist">
										<li className="nav-item" role="presentation">
											<button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">{t('HText9')}</button>
										</li>
										<li className="nav-item" role="presentation">
											<button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">{t('HText10')}</button>
										</li>
										<li className="nav-item" role="presentation">
											<button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">{t('HText11')}</button>
										</li>
										<li className="nav-item" role="presentation">
											<button className="nav-link" id="pills-contact-tab2" data-bs-toggle="pill" data-bs-target="#pills-contact2" type="button" role="tab" aria-controls="pills-contact2" aria-selected="false">{t('HText12')}</button>
										</li>
									</ul>
									<div className="tab-content" id="pills-tabContent">

										<div className="tab-pane fade " id="pills-contact2" role="tab" aria-labelledby="pills-contact-tab2">

											<p className="red"><b> {t('HText71')}</b></p>
											<p className="text-justify">{t('HText72')}</p>


											<p className="red"><b> {t('HText73')}</b></p>
											<p className="text-justify">{t('HText74')}</p>


											<p className="red"><b>{t('HText75')}</b></p>
											<p className="text-justify">{t('HText76')}</p>

											<p className="red"><b>{t('HText77')}</b></p>
											<p className="text-justify">{t('HText78')}</p>

											<p className="red"><b>{t('HText79')}</b></p>
											<p className="text-justify">{t('HText80')}</p>

											<p className="red"><b>{t('HText81')}</b></p>
											<p className="text-justify">{t('HText82')}</p>

											<p className="red"><b>{t('HText83')}</b></p>
											<p className="text-justify">{t('HText84')}</p>


											<p className="red"><b>{t('HText85')}</b></p>
											<p className="text-justify">{t('HText86')}</p>








										</div>

										<div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
											<h3 className="red">{t('HText13')}</h3>
											<p className="text-justify">{t('HText14')}</p>
											<h3 className="red">{t('HText15')}</h3>
											<p className="text-justify">{t('HText16')} </p>

											<h3 className="red mt-3">{t('HText17')}</h3>
											<p>{t('HText18')}</p>
											<ul className="list-style-set list-circle">
												<li>{t('HText19')}</li>
												<li>{t('HText20')}</li>
												<li>{t('HText22')}</li>
												<li>{t('HText23')}</li>
												<li>{t('HText24')}</li>
											</ul>




										</div>
										<div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
											<h3 className="red mt-3">{t('HText25')}</h3>
											<p>{t('HText26')}</p>

											<p className="red"><b> {t('HText27')}</b></p>
											<ul className="list-style-set list-circle">
												<li>{t('HText28')}</li>
												<li>{t('HText29')} </li>
												<li>{t('HText30')}</li>
												<li>{t('HText31')}</li>
											</ul>

											<p className="red"><b> {t('HText32')}</b></p>
											<ul className="list-style-set list-circle">
												<li>{t('HText33')}</li>
												<li>{t('HText34')} </li>
												<li>{t('HText35')} </li>
												<li>{t('HText36')}</li>
												<li>{t('HText37')} </li>
											</ul>

											<p className="red"><b>{t('HText38')}</b></p>
											<ul className="list-style-set list-circle">
												<li>{t('HText39')}</li>
												<li>{t('HText40')}</li>
												<li>{t('HText41')} </li>
												<li>{t('HText42')}</li>
											</ul>

											<p className="red"><b>{t('HText43')}</b></p>
											<ul className="list-style-set list-circle">
												<li>{t('HText44')}</li>
												<li>{t('HText45')}</li>
												<li>{t('HText46')}</li>
												<li>{t('HText47')}</li>
												<li>{t('HText48')}</li>
											</ul>

											<p className="red"><b>{t('HText49')}</b></p>
											<ul className="list-style-set list-circle">
												<li>{t('HText50')}</li>
												<li>{t('HText51')}</li>
												<li>{t('HText52')}</li>
												<li>{t('HText53')}</li>
											</ul>

											<p className="red"><b>{t('HText54')}</b></p>
											<ul className="list-style-set list-circle">
												<li>{t('HText55')}</li>
												<li>{t('HText56')}</li>
												<li>{t('HText57')}</li>
												<li>{t('HText58')}</li>
											</ul>



										</div>
										<div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">

											<div className="row row-cols-2 row-cols-lg-4">
												<div className="col d-flex">
													<div class="serviceBox2">
														<div class="service-icon">{t('HText59')} </div>
														<h3 class="title">{t('HText60')}</h3>
														<p class="description">
														{t('HText61')}
														</p>
													</div>
												</div>

												<div className="col d-flex">
													<div class="serviceBox2">
														<div class="service-icon">{t('HText62')}</div>
														<h3 class="title">{t('HText63')}</h3>
														<p class="description">
														{t('HText64')}
														</p>
													</div>
												</div>

												<div className="col d-flex">
													<div class="serviceBox2">
														<div class="service-icon">{t('HText65')}</div>
														<h3 class="title">{t('HText66')}</h3>
														<p class="description">
														{t('HText67')}
														</p>
													</div>
												</div>

												<div className="col d-flex">
													<div class="serviceBox2">
														<div class="service-icon">{t('HText68')}</div>
														<h3 class="title">{t('HText69')}</h3>
														<p class="description">
														{t('HText70')}
														</p>
													</div>
												</div>

												{/* <div className="col">
                          <div class="serviceBox2">
                              <div class="service-icon"><i class="fa fa-globe"></i></div>
                              <h3 class="title">Web Design</h3>
                              <p class="description">
                                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusantium consequuntur, deleniti eaque excepturi.
                              </p>
                          </div>
                        </div> */}


											</div>

											<div className="tab-pane fade" id="pills-contact2" role="tabpanel" aria-labelledby="pills-contact-tab2">
												......sadfasdfs
											</div>



										</div>
									</div>
								</div>




								{/* <div className="para-color-box p-4">
                  <h3 className="h3 red">About Breakthrough</h3>
                  <p>Breakthrough is a human rights organization working to make violence and discrimination against women and girls unacceptable. We use media, arts, technology and pop-culture to address the issues of domestic violence, sexual harassment in public spaces, early marriage and gender-based discrimination. All of these issues are rooted in and arise from gender and sexuality based discrimination that violate human rights</p>

                  <h3 className="h3 red mt-4">About the Course</h3>
                  <p>This course aims to enable learners’ build a basic understanding about Self, Gender, Sexuality and Human Rights. You will be able to identify the systems and structures which exist in our society and in your lives, and the role they play in maintaining the status quo. You will also learn about Breakthrough’s approach of working with people, systems, structures and institutions to address Gender based discrimination and violence in order to create a gender equal and just world.</p>
                </div> */}
							</div>

							<div className="col-lg-3 mt-5 mt-m-3 order-1  order-sm-1 order-md-1 order-lg-2  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
								<center>
									<img
										width="70%"
										src="/assets/img/chapter1/reading-book.png"
										alt=""
									/>
								</center>
							</div>




						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Home;
