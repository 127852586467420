import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL, { AdminUrl } from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen08 = () => {
	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [checkedQues24Option1, setCheckedQues24Option1] = useState('');
	const [checkedQues24Option2, setCheckedQues24Option2] = useState('');
	const [checkedQues24Val, setCheckedQues24Val] = useState('');

	const [checkedQues25Option1, setCheckedQues25Option1] = useState('');
	const [checkedQues25Option2, setCheckedQues25Option2] = useState('');
	const [checkedQues25Val, setCheckedQues25Val] = useState('');

	const [checkedQues26Option1, setCheckedQues26Option1] = useState('');
	const [checkedQues26Option2, setCheckedQues26Option2] = useState('');
	const [checkedQues26Val, setCheckedQues26Val] = useState('');








	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
	  const quesId = e.target.getAttribute("data-quesId");

	  checkedVal(quesId, e.target.value)

	}

	const saveAns = () => {
	  var data = JSON.stringify({
		"user_id": user_details.user_id,
		"answers": {

		  "ques24": {
			"ans": checkedQues24Val,
			"ques_id": "24"
		  },
		  "ques25": {
			"ans": checkedQues24Val,
			"ques_id": "25"
		  },
		  "ques26": {
			"ans": checkedQues24Val,
			"ques_id": "26"
		  }





		}
	  });
	  var config = {
		method: 'post',
		url: BaseURL + '/SexualityChapter01',
		headers: {
		  'Authorization': `Bearer ${user_details.token}`,
		  'Content-Type': 'application/json'
		},
		data: data
	  };
	  axios(config)
		.then(function (response) {

		  if (response.status === "Token is Expired") {
			window.localStorage.clear();
			navigate('/login')
		  }
		  if (response.data.message === "Token Invalid") {
			window.localStorage.clear();
			navigate('/login')
		  }

		  if (response.data.success === true) {
			navigate('/module-03/chapter-01-screen-09')
		  }
		})
		.catch(function (error) {
		  console.log(error);
		});
	}
	const BtnDisabled = () => {
	  if (checkedQues24Val !== '' && checkedQues25Val !== '' && checkedQues26Val !== '' ) {
		return (
		  <>
			<button
			  className={`button_su`}
			  data-wow-duration="1s"
			  data-wow-delay="0.2s"
			>
			  <span className="su_button_circle" />
			  <button onClick={saveAns} className="button_su_inner">
				<span className="button_text_container">
				  {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
				</span>
			  </button>
			</button>


		  </>
		);
	  } else {
		return (
		  <>
			<button className="button_su disabled-btn" type="button" disabled="">
			  <span className="su_button_circle"></span>
			  <span className="button_su_inner">
				<span className="button_text_container">
				  {" "}
				  {t('CommonBtnGoNext')}<i className="bi bi-arrow-right" />
				</span>{" "}
			  </span>
			</button>
		  </>
		);
	  }
	};
	const checkedVal = (param, ans) => {


	  if (param === '24') {

		if (ans === 'option1') {
		  setCheckedQues24Option1('checked')
		  setCheckedQues24Option2('')

		} else if (ans === 'option2') {
		  setCheckedQues24Option1('')
		  setCheckedQues24Option2('checked')

		}
		setCheckedQues24Val(ans)
	  }

	  if (param === '25') {

		if (ans === 'option1') {
		  setCheckedQues25Option1('checked')
		  setCheckedQues25Option2('')

		} else if (ans === 'option2') {
		  setCheckedQues25Option1('')
		  setCheckedQues25Option2('checked')

		}
		setCheckedQues25Val(ans)
	  }

	  if (param === '26') {

		if (ans === 'option1') {
		  setCheckedQues26Option1('checked')
		  setCheckedQues26Option2('')

		} else if (ans === 'option2') {
		  setCheckedQues26Option1('')
		  setCheckedQues26Option2('checked')

		}
		setCheckedQues26Val(ans)
	  }









	}

	useEffect(() => {
	  var data = JSON.stringify({
		"user_id": user_details.user_id,
		"ques_id": ""
	  });
	  var config = {
		method: 'post',
		url: BaseURL + '/sexualityChapter01Result',
		headers: {
		  'Authorization': `Bearer ${user_details.token}`,
		  'Content-Type': 'application/json'
		},
		data: data
	  };
	  axios(config)
		.then(function (response) {
		  if (response.data.success === true) {
			const results = response.data.data.chapter;
			// console.log(results);
			results.map((result) => {
			  checkedVal(result.ques_id.toString(), result.ans)
			})
		  }
		})
		.catch(function (error) {
		  console.log(error);
		});
	}, [])




	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="0" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3 mt-m-3">
								<div
									className="col-lg-8  wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>




									<h2 className="h2">{t('M3chapter01Screen08Text1')}  </h2>
									<h4 className="h4 mt-3">{t('M3chapter01Screen08Text2')}</h4>



									<p className="mb-2 mt-3">{t('M3chapter01Screen08Text3')}</p>
									<p className="mb-2 mt-3">{t('M3chapter01Screen08Text4')}</p>

									<p className="mb-2 mt-3 pn"><span><b>1</b></span><b> {t('M3chapter01Screen08Text5')}</b> </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle24"
											id="mycirle241"
											defaultValue="option1"
											onClick={saveRadio}
											checked={checkedQues24Option1}
											data-quesId="24"

										/>
										<label className="form-check-label" htmlFor="mycirle9"> {t('true')}</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle24"
											id="mycirle241"
											defaultValue="option2"
											onClick={saveRadio}
											checked={checkedQues24Option2}
											data-quesId="24"

										/>
										<label className="form-check-label" htmlFor="mycirle10"> {t('false')} </label>
										</div>
									</div>



									<p className="mb-2 mt-3 pn"><span><b>2</b></span><b> {t('M3chapter01Screen08Text6')}</b> </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle25"
											id="mycirle251"
											defaultValue="option1"
											onClick={saveRadio}
											checked={checkedQues25Option1}
											data-quesId="25"

										/>
										<label className="form-check-label" htmlFor="mycirle9"> {t('true')}</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle25"
											id="mycirle25"
											defaultValue="option2"
											onClick={saveRadio}
											checked={checkedQues25Option2}
											data-quesId="25"

										/>
										<label className="form-check-label" htmlFor="mycirle10"> {t('false')} </label>
										</div>
									</div>


									<p className="mb-2 mt-3 pn"><span><b>3</b></span><b> {t('M3chapter01Screen08Text7')}</b> </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle26"
											id="mycirle261"
											defaultValue="option1"
											onClick={saveRadio}
											checked={checkedQues26Option1}
											data-quesId="26"

										/>
										<label className="form-check-label" htmlFor="mycirle9"> {t('true')}</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio"
											name="namecircle26"
											id="mycirle26"
											defaultValue="option2"
											onClick={saveRadio}
											checked={checkedQues26Option2}
											data-quesId="26"

										/>
										<label className="form-check-label" htmlFor="mycirle10"> {t('false')} </label>
										</div>
									</div>




								</div>

								<div
									className="col-lg-4 mt-m-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="75%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-1-4.png"
											alt=""
										/>
									</center>
								</div>



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-01-screen-07" />
								{/* <NextBtn link="/module-03/chapter-01-screen-09" text={t('CommonBtnGoNext')} /> */}

								<BtnDisabled/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter01Screen08;
