import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter04Screen07 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const [checkedQues9, setCheckedQues9] = useState([]);
	const [checkedQues9Option1, setCheckedQues9Option1] = useState('');
	const [checkedQues9Option2, setCheckedQues9Option2] = useState('');
	const [checkedQues9Option3, setCheckedQues9Option3] = useState('');
	const [checkedQues9Option4, setCheckedQues9Option4] = useState('');
	const [checkedQues9Option5, setCheckedQues9Option5] = useState('');
	const [checkedQues9Val, setCheckedQues9Val] = useState('');

	const [checkedQues8, setCheckedQues8] = useState([]);
	const [option1Checked, setOption1Checked] = useState('');
	const [option2Checked, setOption2Checked] = useState('');
	const [option3Checked, setOption3Checked] = useState('');
	const [option4Checked, setOption4Checked] = useState('');
	const [option5Checked, setOption5Checked] = useState('');

	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);
		checkedVal(quesId, e.target.value)
	}
	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;
		// alert(quesId);
		if (quesId === '8') {
			checkBoxChecked(res, checked, quesId);
			setCheckedQues8(checkedQues8 => [...checkedQues8, res]);
		}

		if (quesId === '9') {
			checkBoxChecked(res, checked, quesId);
			setCheckedQues9(checkedQues9 => [...checkedQues9, res]);
		}


	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": checkedQues8.join(','),
					"ques_id": "8"
				},
				"ques9": {
					"ans": checkedQues9.join(','),
					"ques_id": "9"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-04/chapter-04-screen-08')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues8.length > 0 && checkedQues9.length > 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '8') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues8(ansArr)
		}

		if (param === '9') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues9(ansArr)
		}

		// if (param === '9') {
		//   if (ans === 'option1') {
		//     setCheckedQues9Option1('checked')
		//     setCheckedQues9Option2('');
		//     setCheckedQues9Option3('')
		//     setCheckedQues9Option4('')
		//     setCheckedQues9Option5('')
		//   } else if (ans === 'option2') {
		//     setCheckedQues9Option1('')
		//     setCheckedQues9Option2('checked');
		//     setCheckedQues9Option3('')
		//     setCheckedQues9Option4('')
		//     setCheckedQues9Option5('')
		//     }else if (ans === 'option3') {
		//       setCheckedQues9Option1('')
		//       setCheckedQues9Option2('');
		//       setCheckedQues9Option3('checked')
		//       setCheckedQues9Option4('')
		//       setCheckedQues9Option5('')
		//       }else if (ans === 'option4') {
		//         setCheckedQues9Option1('')
		//         setCheckedQues9Option2('');
		//         setCheckedQues9Option3('')
		//         setCheckedQues9Option4('checked')
		//         setCheckedQues9Option5('')
		//         }else if (ans === 'option5') {
		//           setCheckedQues9Option1('')
		//           setCheckedQues9Option2('');
		//           setCheckedQues9Option3('')
		//           setCheckedQues9Option4('')
		//           setCheckedQues9Option5('checked')
		//           }
		//     setCheckedQues9Val(ans)
		// }
	}
	const checkBoxChecked = (val, checked, param) => {

		if (param === '8') {
			if (val === 'option1') {
				if (checked === true) {
					setOption1Checked('checked');
				} else {
					setOption1Checked('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setOption2Checked('checked');
				} else {
					setOption2Checked('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setOption3Checked('checked');
				} else {
					setOption3Checked('');
				}
			}
			if (val === 'option4') {
				if (checked === true) {
					setOption4Checked('checked');
				} else {
					setOption4Checked('');
				}
			}

			if (val === 'option5') {
				if (checked === true) {
					setOption5Checked('checked');
				} else {
					setOption5Checked('');
				}
			}
		}

		if (param === '9') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues9Option1('checked');
				} else {
					setCheckedQues9Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues9Option2('checked');
				} else {
					setCheckedQues9Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues9Option3('checked');
				} else {
					setCheckedQues9Option3('');
				}
			}
			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues9Option4('checked');
				} else {
					setCheckedQues9Option4('');
				}
			}

			if (val === 'option5') {
				if (checked === true) {
					setCheckedQues9Option5('checked');
				} else {
					setCheckedQues9Option5('');
				}
			}
		}


	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/MasculChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="65" star="65" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-1">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h3 className="h3 mb-1">
										{" "}
										<b>
											{t('M4chapter04screen07Text1')}
										</b>{" "}
									</h3>
								</div>
								<div
									className="col-lg-7 mt-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="pn">
										{" "}
										<span>
											<b>1.</b>
										</span>{" "}
										<b> {t('M4chapter04screen07Text2')} </b>
									</p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle1"
												defaultValue="option1"
												onClick={saveGenderBased}
												checked={option1Checked}
												data-quesId="8"
											/>
											<label className="form-check-label" htmlFor="mycirle1">
												{t('team_leader')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle2"
												defaultValue="option2"
												onClick={saveGenderBased}
												checked={option2Checked}
												data-quesId="8"
											/>
											<label className="form-check-label" htmlFor="mycirle2">
											{t('roma')}{" "}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle3"
												defaultValue="option3"
												onClick={saveGenderBased}
												checked={option3Checked}
												data-quesId="8"
											/>
											<label className="form-check-label" htmlFor="mycirle3">
											{t('elder_brother')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirle4"
												defaultValue="option4"
												onClick={saveGenderBased}
												checked={option4Checked}
												data-quesId="8"
											/>
											<label className="form-check-label" htmlFor="mycirle4">
											{t('son')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle"
												id="mycirleextra"
												defaultValue="option5"
												onClick={saveGenderBased}
												checked={option5Checked}
												data-quesId="8"
											/>
											<label className="form-check-label" htmlFor="mycirleextra">
											{t('cop')}{" "}
											</label>
										</div>
									</div>
									<p className="pn">
										{" "}
										<span>
											<b>2.</b>
										</span>{" "}
										<b>{t('M4chapter04screen07Text3')} </b>
									</p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle2"
												id="mycirle5"
												defaultValue="option1"

												onClick={saveGenderBased}
												data-quesId="9"
												checked={checkedQues9Option1}
											/>
											<label className="form-check-label" htmlFor="mycirle5">
												{t('team_leader')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle2"
												id="mycirle6"
												defaultValue="option2"

												onClick={saveGenderBased}
												data-quesId="9"
												checked={checkedQues9Option2}
											/>
											<label className="form-check-label" htmlFor="mycirle6">
											{t('roma')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle2"
												id="mycirle7"
												defaultValue="option3"

												onClick={saveGenderBased}
												data-quesId="9"
												checked={checkedQues9Option3}
											/>
											<label className="form-check-label" htmlFor="mycirle7">
											{t('elder_brother')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle2"
												id="mycirle89"
												defaultValue="option4"

												onClick={saveGenderBased}
												data-quesId="9"
												checked={checkedQues9Option4}
											/>
											<label className="form-check-label" htmlFor="mycirle89">
											{t('son')}
											</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input
												className="form-check-input custom-check"
												type="checkbox"
												name="namecircle2"
												id="mycirle8"
												defaultValue="option5"

												onClick={saveGenderBased}
												data-quesId="9"
												checked={checkedQues9Option5}
											/>
											<label className="form-check-label" htmlFor="mycirle8">
											{t('cop')}
											</label>
										</div>
									</div>
								</div>
								<div
									className="col-lg-5 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="46%"
											draggable="false"
											src="/assets/img/md4/md4-shap-4-7.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-04-screen-06n" />

									{/* <NextBtn link="/module-04/chapter-04-screen-08" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen07;
