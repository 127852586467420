import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter02Screen06 = () => {

const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper md-5-bg">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="25" star="2" />
					</div>
				</div>
				<div className="d-flex">
				<Sidebar/>
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">

							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h4>{t('learn')}</h4>
								<p className="mb-2 ">{t('M5chapter02Screen6Text1')} </p>

								<p className="mb-2 mt-3">
								{t('M5chapter02Screen6Text2')}
								</p>

								<p className="mb-2 mt-3">{t('M5chapter02Screen6Text3')} </p>

							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="62%"
										draggable="false"
										src="/assets/img/md3/md3-shap-2-7.png"
										alt=""
									/>
								</center>
							</div>

						</div>
					</div>
				</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-05/chapter-02-screen-05" />

									<NextBtn link="/module-05/chapter-02-screen-07" text="Go Next" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen06;
