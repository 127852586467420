import React,{useState,useEffect} from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter05Screen03 = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [checkedQues1Option1, setCheckedQues1Option1] = useState('');
  const [checkedQues1Option2, setCheckedQues1Option2] = useState('');
 
  const [checkedQues1Val, setCheckedQues1Val] = useState('');

  const [checkedQues2Option1, setCheckedQues2Option1] = useState('');
  const [checkedQues2Option2, setCheckedQues2Option2] = useState('');
  const [checkedQues2Option3, setCheckedQues2Option3] = useState('');
  const [checkedQues2Option4, setCheckedQues2Option4] = useState('');

  const [checkedQues2Val, setCheckedQues2Val] = useState('');


  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e)=>{
    const quesId = e.target.getAttribute("data-quesId");
    // alert(quesId);
    checkedVal(quesId,e.target.value)
  }

  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans":  checkedQues1Val,       
          "ques_id": "1"
        },
        "ques2": {
          "ans": checkedQues2Val,         
          "ques_id": "2"
        },

      }
    });
    var config = {
      method: 'post',
      url: BaseURL +'/MasculChapter05',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        if(response.data.success === true)
        {
          navigate('/module-04/chapter-05-screen-04')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues1Val !== '' && checkedQues2Val !== '') {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
              {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param,ans) => {
  
    if (param === '1') {
      if (ans === 'option1') {
        setCheckedQues1Option1('checked')
        setCheckedQues1Option2('');
       
      } else if (ans === 'option2') {
        setCheckedQues1Option1('')
        setCheckedQues1Option2('checked');
      
        }
        setCheckedQues1Val(ans)
    }

    if (param === '2') {
      if (ans === 'option1') {
        setCheckedQues2Option1('checked')
        setCheckedQues2Option2('');
        setCheckedQues2Option3('')
        setCheckedQues2Option4('')
       
      } else if (ans === 'option2') {
        setCheckedQues2Option1('')
        setCheckedQues2Option2('checked');
        setCheckedQues2Option3('')
        setCheckedQues2Option4('')
      
        }else if (ans === 'option3') {
          setCheckedQues2Option1('')
          setCheckedQues2Option2('');
          setCheckedQues2Option3('checked')
          setCheckedQues2Option4('')
      
          }else if (ans === 'option4') {
            setCheckedQues2Option1('')
            setCheckedQues2Option2('');
            setCheckedQues2Option3('')
            setCheckedQues2Option4('checked')
            
            }
        setCheckedQues2Val(ans)
    }
  }
 
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/MasculChapter05Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(),result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])



  return (
    <>
      <div className="wrapper bg-bd-2">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
          <Header leftChapter="4/5" percentage="72" star="72  " />
          </div>
        </div>
        <div className="d-flex">
					<Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-between pt-20 mt-4">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h3 className="h3 mb-2">
                    {" "}
                    <b>
                      {t('M4chapter05screen03Text1')}
                      
                    </b>{" "}
                  </h3>
                </div>
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <p className="mt-2 pn">
                    {" "}
                    <span>
                      <b>1.</b>{" "}
                    </span>{" "}
                    <b> {t('M4chapter05screen03Text2')}</b>
                  </p>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-check"
                        type="radio"
                        name="namecircle"
                        id="mycirle1"
                        defaultValue="option1"
                        onClick={saveRadio}
                        data-quesId="1"
                        checked={checkedQues1Option1}
                      />
                      <label className="form-check-label" htmlFor="mycirle1">
                      {t('M4chapter05screen03Text3')}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-check"
                        type="radio"
                        name="namecircle"
                        id="mycirle2"
                        defaultValue="option2"
                        onClick={saveRadio}
                        data-quesId="1"
                        checked={checkedQues1Option2}
                      />
                      <label className="form-check-label" htmlFor="mycirle2">
                        {t('M4chapter05screen03Text4')}
                      </label>
                    </div>
                  </div>
                  <p className="mt-3 pn">
                    {" "}
                    <span>
                      <b>2.</b>{" "}
                    </span>
                    <b> {t('M4chapter05screen03Text5')} </b>
                  </p>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-check"
                        type="radio"
                        name="namecircle2"
                        id="mycirle5"
                        defaultValue="option1"
                        onClick={saveRadio}
                        data-quesId="2"
                        checked={checkedQues2Option1}
                      />
                      <label className="form-check-label" htmlFor="mycirle5">
                      {t('M4chapter05screen03Text6')}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-check"
                        type="radio"
                        name="namecircle2"
                        id="mycirle6"
                        defaultValue="option2"
                        onClick={saveRadio}
                        data-quesId="2"
                        checked={checkedQues2Option2}
                      />
                      <label className="form-check-label" htmlFor="mycirle6">
                      {t('M4chapter05screen03Text7')}{" "}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-check"
                        type="radio"
                        name="namecircle2"
                        id="mycirle7"
                        defaultValue="option3"
                        onClick={saveRadio}
                        data-quesId="2"
                        checked={checkedQues2Option3}
                      />
                      <label className="form-check-label" htmlFor="mycirle7">
                        {t('M4chapter05screen03Text8')}
                      </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input custom-check"
                        type="radio"
                        name="namecircle2"
                        id="mycirle8"
                        defaultValue="option4"
                        onClick={saveRadio}
                        data-quesId="2"
                        checked={checkedQues2Option4}
                      />
                      <label className="form-check-label" htmlFor="mycirle8">
                        {t('M4chapter05screen03Text9')}
                      </label>
                    </div>
                  </div>
                  <p className="mb-2 mt-3">
                  {t('M4chapter05screen03Text10')}
                    
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-04/chapter-05-screen-02"/>                  
                
                {/* <NextBtn link="/module-04/chapter-05-screen-04" text="Go Next"/> */}
                <BtnDisabled/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabIndex={-1}
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div className="modal-content modal-bg-blue">
              <div className="modal-body">
                <button
                  type="button"
                  className="btn-close close-set"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="bi bi-x-lg" />
                </button>
                <p className="text-white text-center">
                  Kamla Bhasin - Wikipedia
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter05Screen03;
