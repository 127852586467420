import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { NavLink } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen28 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-2">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									<h3 className="h3 mt-3">{t('learn')} </h3>
									<p className="mb-2  mt-3">{t('M3chapter03Screen28Text1')} </p>

									<h3 className="h3 mt-3">{t('apply')}:  </h3>
									<p className="mb-2  mt-3">{t('M3chapter03Screen28Text2')} </p>
									<a
										href="https://www.youtube.com/watch?v=vyU8wEujJkc"
										target="_blank"

									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M3chapter03Screen28Text3')}	  </p>
											</div>
										</div>
									</a>
									<a
										href="https://www.youtube.com/watch?v=PxZgN6FOkmE"
										target="_blank"

									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>	{t('M3chapter03Screen28Text4')}  </p>
											</div>
										</div>
									</a>




								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="75%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-4-9.png"
											alt=""
										/>
									</center>
								</div>




							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-03-screen-27" />
								{/* <NextBtn link="/module-03/chapter-03-screen-29" text="Go Next"/> */}
								<button className="button_su" >
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" className="button_su_inner"><span className="button_text_container"> {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>



							</div>
						</div>
					</div>
				</div>
			</div>



			{/* <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content modal-bg-blue">
            <div className="modal-body">
              <button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>


              <div className="row mt-5">
                <div className="col-lg-6">
                  <iframe width="100%" height="300" src="https://www.youtube.com/embed/vyU8wEujJkc" title="Deepika Padukone wears salwar for mom in law" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className="col-lg-6">
                  <iframe width="100%" height="300" src="https://www.youtube.com/embed/PxZgN6FOkmE" title="Deepika Padukone wants to be an Indian bahu" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </div>



            </div>
          </div>
        </div>
      </div> */}


			<div className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
				<div className="modal-dialog modal-dialog-centered modal-xl">
					<div className="modal-content modal-bg-blue">
						<div className="modal-body">
							<button type="button" className="btn-close close-set" data-bs-dismiss="modal" aria-label="Close"><i className="bi bi-x-lg" /></button>

							<p className="text-white text-left">{t('M3chapter03Screen28Text5')}
							</p>

							<ul class="list-style-set list-circle mt-1">
								<li className="text-white">{t('M3chapter03Screen28Text6')}</li>
								<li className="text-white">{t('M3chapter03Screen28Text7')}</li>
							</ul>

							<div class=" d-flex justify-content-center mt-3">
								<button className="button_su" data-bs-dismiss="modal" aria-label="Close">
									<span className="su_button_circle desplode-circle">
									</span>
									<NavLink to="/module-03/chapter-03-screen-29" className="button_su_inner"><span className="button_text_container"> {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" /></span> </NavLink>
								</button>
							</div>




						</div>
					</div>
				</div>
			</div>


		</>
	);
};

export default Chapter03Screen28;
