import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import BaseURL from "../../config/Baseurl";
import HeaderHome from "../../header/HeaderHome";

const SignUpOtp = () => {
	const signup_details = JSON.parse(
		localStorage.getItem("student_signup_details")
	);
	const navigate = useNavigate();
	const [inputValue, setIputValue] = useState();
	const [inputValueErr, setinputValueErr] = useState({});
	//   const [globalErr,setGlobalErr] = useState();

	const matchOtp = (e) => {
		e.preventDefault();
		const isValid = formValidation();

		if (isValid === true) {
			const data = JSON.stringify({
				message_id: signup_details.email_message_id,
				otp: inputValue,
			});

			var config = {
				method: "post",
				url: BaseURL + "/forgotPassword_OTP_Match",
				headers: {
					"Content-Type": "application/json",
				},
				data: data,
			};

			axios(config)
				.then(function (response) {
					// console.log(response);
					if (response.data.success === true) {
						var data = JSON.stringify({
							mobemail: signup_details.email,
							username: signup_details.username,
							password: signup_details.password,
							gender: signup_details.gender,
							state_id: signup_details.state_id,
							user_type: signup_details.user_type,
						});

						//   console.log(data);

						var config = {
							method: "post",
							url: BaseURL + "/studentRegisterAPI",
							headers: {
								"Content-Type": "application/json",
							},
							data: data,
						};

						axios(config)
							.then(function (response) {
								if (response.data.success === true) {
									const userId = response.data.data.id;
									const email = response.data.data.email;
									const name = response.data.data.name;
									const user_unique_id = response.data.data.user_unique_id;
									const token = response.data.token;

									localStorage.removeItem("student-details");
									localStorage.removeItem("student_signup_details");

									const userData = {
										token: token,
										user_id: userId,
										email: email,
										name:name,
										user_unique_id: user_unique_id,
									};
									localStorage.setItem(
										"user_details",
										JSON.stringify(userData)
									);
									navigate("/");
								}
								// console.log(JSON.stringify(response.data));
							})
							.catch(function (error) {
								console.log(error);
							});
					} else {
						// setMessage(response.data.message);
						// setType("danger");
					}
					// console.log(JSON.stringify(response.data));
				})
				.catch(function (error) {
					console.log(error);

				});
		}
	};

	//   // form validation
	const formValidation = () => {
		const inputvalueErr = {};

		const inputValue1 = inputValue === undefined ? "" : inputValue;

		let isValid = true;

		// console.log(mobEmail.length);
		if (inputValue1.length === 0) {
			inputvalueErr.required = "Field Is Required";
			isValid = false;
		}

		setinputValueErr(inputvalueErr);

		return isValid;
	};

	return (
		<>
			<div className="wrapper">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<HeaderHome />
					</div>
				</div>
				<form onSubmit={matchOtp}>
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-center justify-content-center pt-20">
								<div className="col-lg-4">
									<h2 className="h2 mb-5">OTP </h2>
									<div className="mb-3">
										<label htmlFor="exampleInputEmail1" className="form-label">
											Enter OTP Here
										</label>
										<input
											type="text"
											className="form-control"
											placeholder=""
											value={inputValue}
											onChange={(e) => {
												setIputValue(e.target.value);
											}}
										/>
										{Object.keys(inputValueErr).map((key) => {
											return (
												<span className="error">{inputValueErr[key]}</span>
											);
										})}
									</div>

									<button
										className="button_su mt-3 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<span className="su_button_circle" />
										<button type="submit" className="button_su_inner">
											<span className="button_text_container">
												{" "}
												Submit <i className="bi bi-arrow-right" />
											</span>
										</button>
									</button>
								</div>
								<div
									className="col-lg-3 offset-lg-1 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="100%"
											src="/assets/img/chapter1/Jhole-wali-didi.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</>
	);
};

export default SignUpOtp;
