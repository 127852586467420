import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import PrevBtn from "../../../common/PrevBtn";
import Header from '../../../header/Header';
import { useDrop } from "react-dnd";
// import Image from './Image';

import Chapter04Screen09_1 from './Chapter04Screen09_1';
import axios from 'axios';
import BaseURL from '../../../config/Baseurl';
import { useEffect } from 'react';
import NextBtn from '../../../common/NextBtn'
import { useDrag } from "react-dnd";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";
const Chapter04Screen09 = () => {

	const navigate = useNavigate();
	const { t, i18n } = useTranslation();
	const [PictureList, setPictureList] = useState([
		{ "id": "1", "name": t('M6chapter04screen09Text5') },
		{ "id": "2", "name": t('M6chapter04screen09Text6') },
		{ "id": "3", "name": t('M6chapter04screen09Text7') },
		{ "id": "4", "name": t('M6chapter04screen09Text8') },
		{ "id": "5", "name": t('M6chapter04screen09Text9') },
		{ "id": "6", "name": t('M6chapter04screen09Text10') },
		{ "id": "7", "name": t('M6chapter04screen09Text11') },
		{ "id": "8", "name": t('M6chapter04screen09Text12') },
		{ "id": "9", "name": t('M6chapter04screen09Text13') },

	]);




	const [deepArray, setDeepArray] = useState([]);
	const [enableArray, setEnableArray] = useState([]);
	const [resultArray, setResultArray] = useState([]);
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const [{ isUnsureOver }, unsuredrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addResultArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));


	const [{ isOver }, agreedrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addAgreeArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));

	const [{ isShasOver }, disagreedrop] = useDrop(() => ({
		accept: "image",
		drop: (item) => addEnableArray(item.id),
		collect: (monitor) => ({
			isOver: !!monitor.isOver(),
		}),
	}));


	// Add To Array Samar

	const addResultArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setResultArray((resultArray) => [...resultArray, picture_List[0]]);
	};


	const addAgreeArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setDeepArray((deepArray) => [...deepArray, picture_List[0]]);
	};

	// Add To sahas Array
	const addEnableArray = (id) => {
		const picture_List = PictureList.filter((picture) => id === picture.id);
		setPictureList(PictureList =>
			PictureList.filter(e => {
				return e.id !== id;
			}),
		);
		setEnableArray((enableArray) => [...enableArray, picture_List[0]]);
	};


	const onItemRemoveAgree = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = deepArray.filter((item) => item.id === id);

		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setDeepArray(deepArray =>
			deepArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};

	const onItemRemoveUnsure = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = resultArray.filter((item) => item.id === id);
		console.log(_item);
		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setResultArray(resultArray =>
			resultArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};


	const onItemRemoveDisagree = (e) => {
		const id = e.currentTarget.dataset.id;
		// Add in Picture List
		const _item = enableArray.filter((item) => item.id === id);
		console.log(_item);
		setPictureList((picture) => [...PictureList, _item[0]]);

		// Remove from sahasBoard array
		setEnableArray(enableArray =>
			enableArray.filter(sahas => {
				return sahas.id !== id;
			}),
		);
	};


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques10": {
					"ans": JSON.stringify(deepArray),
					"ans2": JSON.stringify(enableArray),
					"ans3": JSON.stringify(resultArray),
					"ques_id": "10"
				},

			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/ApproachChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {

				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-06/chapter-04-screen-12');
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,

			"ques_id": "10"
		});

		var config = {
			method: 'post',
			url: BaseURL + '/approachChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};

		axios(config)
			.then(function (response) {
				console.log(response.data);
				if (response.data.success === true) {

					const ans = JSON.parse(response.data.data.chapter.ans)
					const ans2 = JSON.parse(response.data.data.chapter.ans2)
					const ans3 = JSON.parse(response.data.data.chapter.ans3)
					console.log(ans3);
					setDeepArray(ans);
					setEnableArray(ans2);
					setResultArray(ans3);
					if (ans.length > 0) {
						ans.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}

					if (ans2.length > 0) {
						ans2.map((elem) => {

							setPictureList(current =>
								current.filter(PictureList => {
									return PictureList.id !== elem.id;
								}),
							);


						});
					}


				}
			})
			.catch(function (error) {
				console.log(error);
			});

	}, []);


	const BtnDisabled = () => {
		if (enableArray.length !== 0 && deepArray.length !== 0) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/4" percentage="92" star="50" />
					</div>
				</div>

				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-1">
								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mb-2 mt-3 pn"><span><b>4.</b></span><b>{t('M6chapter04screen09Text1')}</b> </p>
									<table className="table table-bordered table-dark table-red table-white table-padding mt-4">
										<tbody>
											<tr><td colspan="2" className="td-yellow">{t('M6chapter04screen09Text2')}</td></tr>
											<tr>
												<td colspan="2">

													<div className="dragarea" ref={unsuredrop}>
														<ul>

															{resultArray.map((e) => {

																return (
																	<>
																		<li>

																			{e.name}
																			<button
																				onClick={onItemRemoveUnsure}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			></button>
																		</li>

																	</>
																)


															})}
														</ul>
													</div>
												</td>
											</tr>
											<tr>
												<td className="td-green">{t('M6chapter04screen09Text3')}</td>
												<td className="td-red">{t('M6chapter04screen09Text4')}</td>


											</tr>
											<tr className="drag-table table-width-set">
												<td>
													<div className="dragarea" ref={agreedrop}>
														<ul>

															{deepArray.map((e) => {

																return (
																	<>
																		<li>

																			{e.name}
																			<button
																				onClick={onItemRemoveAgree}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			></button>
																		</li>

																	</>
																)


															})}
														</ul>
													</div>
												</td>
												<td>
													<div className="dragarea" ref={disagreedrop}>
														<ul>

															{enableArray.map((e) => {

																return (
																	<>
																		<li>

																			{e.name}
																			<button
																				onClick={onItemRemoveDisagree}
																				data-id={e.id}
																				type="button"
																				className="btn-close"
																				aria-label="Close"
																			></button>
																		</li>

																	</>
																)


															})}
														</ul>
													</div>
												</td>

											</tr>
										</tbody>
									</table>
								</div>

								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<ul className="list-style-set list-cursor list-circle mt-3 mb-3">
										{
											PictureList.map((e) => {


												return (

													<Chapter04Screen09_1 key={e.id} id={e.id} imageName={e.name} />


												)

											})
										}
									</ul>
								</div>



								{/* <div
                className="col-lg-10 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
              >
                <center>
                  <img
                    width="100%"
                    draggable="false"
                    src="/assets/img/md5/chap02-2/4.jpg"
                    alt=""
                  />
                </center>
              </div>

                */}



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-06/chapter-04-screen-08" />
								{/* <NextBtn link="/module-05/chapter-03-screen-07" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter04Screen09;
