import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter01Screen09 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<>
				<div className="wrapper">
					<div
						className="fixed-header"

					>
						<div className="container-fluid cf-set">
							<Header leftChapter="0/5" percentage="20" star="8" />
						</div>
					</div>
					<div className="d-flex">
						<Sidebar />
						<div className="data-adjust">
							<div className="container-fluid cf-set">
								<div className="row align-items-top justify-content-between pt-20 mt-3">
									<div
										className="col-lg-12 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<h2 className="h3">
											{t('M2chapter01screen09Text1')}
											
										</h2>
									</div>
									<div
										className="col-lg-8 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>

										<a
											href="https://www.youtube.com/watch?v=YGAu8gRfv9c/"
											target="_blank"
											rel="noreferrer"
										>
											<div className="d-flex align-items-center mange-icon mt-3">
												<div>
													<img src="/assets/img/chapter2/video-icon.png" alt="" />
												</div>
												<div>
													<p> {t('M2chapter01screen09Text2')} </p>
												</div>
											</div>
										</a>
										<a
											href="https://www.youtube.com/watch?v=uX8lyI-5nr4"
											target="_blank"
											rel="noreferrer"
										>
											<div className="d-flex align-items-center mange-icon mt-3">
												<div>
													<img src="/assets/img/chapter2/video-icon.png" alt="" />
												</div>
												<div>
													<p> {t('M2chapter01screen09Text4')}</p>
												</div>
											</div>
										</a>
										<a
											href="https://www.itspronouncedmetrosexual.com/2011/11/list-of-cisgender-privileges/"
											target="_blank"
											rel="noreferrer"
										>
											<div className="d-flex align-items-center mange-icon mt-3">
												<div>
													<img src="/assets/img/chapter1/list-icon.png" alt="" />
												</div>
												<div>
													<p> {t('M2chapter01screen09Text3')} </p>
												</div>
											</div>
										</a>

										{/* <ul className="list-style-set">
						<li>
						{" "}
						<span>
						<img src="/assets/img/chapter2/video-icon.png" alt="" />
						</span>{" "}
						<a href="https://www.youtube.com/watch?v=YGAu8gRfv9c/" target="_blank">
							“Once Upon a Boy” (Promundo)
						</a>{" "}
						</li>
						<li>
						{" "}
						<span>
						<img src="/assets/img/chapter2/video-icon.png" alt="" />
						</span>{" "}
						<a href="https://www.youtube.com/watch?v=uX8lyI-5nr4" target="_blank">
							“Once Upon a Girl” (Promundo)
						</a>{" "}
						</li>
						<li>
						{" "}
						<span>
							<img src="/assets/img/chapter1/list-icon.png" alt="" />
						</span>{" "}
						<a target="_blank" href="https://www.itspronouncedmetrosexual.com/2011/11/list-of-cisgender-privileges/">
						30+ Examples of Cisgender Privileges
						</a>
						</li>
					</ul> */}
									</div>
									<div
										className="col-lg-4 mt-m-3 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.1s"
									>
										<center>
											<img
												width="57%"
												className="mb-4"
												draggable="false"
												src="/assets/img/md1/md1-shap-9.png"
												alt=""
											/>
										</center>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.3s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-02/chapter-01-screen-08" />

									<NextBtn link="/module-02/chapter-02-screen-01" text={t('CommonBtnGoNext')} classtype="" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
};

export default Chapter01Screen09;
