import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
// import NextBtn from "../../../common/NextBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
const Chapter05Screen02 = () => {
	const { t, i18n } = useTranslation();

	const navigate = useNavigate();
	const [checkedQues1, setCheckedQues1] = useState('');
	const [checkedQues2, setCheckedQues2] = useState('');
	const [checkedQues3, setCheckedQues3] = useState([]);
	const [checkedQues3_1, setCheckedQues3_1] = useState('');
	const [homeChecked, setHomeChecked] = useState('');
	const [educationChecked, setEducationalChecked] = useState('');
	const [officeChecked, setOfficeChecked] = useState('');
	const [publicChecked, setPublicChecked] = useState('');
	const [anyChecked, setAnyChecked] = useState('');


	const [val, setVal] = useState();

	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const TextArea = () => {
		if (val === 'Any Other') {
			return (
				<>
					<div className="positon-submit">
						<textarea
							className="form-control"
							rows="1"
							placeholder="Write Something here....."
							onChange={(e) => setCheckedQues3_1(e.target.value)}
							defaultValue={checkedQues3_1}
						></textarea>
					</div>
				</>
			)
		}

	}
	const saveGenderBased = (e) => {
		const res = e.target.value;
		const checked = e.target.checked;

		setVal(res);


		checkBoxChecked(res, checked);
		setCheckedQues3(checkedQues3 => [...checkedQues3, res]);
	}
	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": checkedQues1,
					"ques_id": "1"
				},
				"ques2": {
					"ans": checkedQues2,
					"ques_id": "2"
				},
				"ques3": {
					"ans": checkedQues3.join(','),
					"ans2": checkedQues3_1,
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter05',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-02/chapter-05-screen-03')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues1 !== '' && checkedQues2 !== '' && checkedQues3 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	const checkedVal = (param, ans) => {
		if (param === '1') {
			setCheckedQues1(ans)
		}
		if (param === '2') {
			setCheckedQues2(ans)
		}

		if (param === '3') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked);
			})
			setCheckedQues3(ansArr)
		}


	}

	const checkBoxChecked = (val, checked) => {
		if (val === 'Home') {
			if (checked === true) {
				setHomeChecked('checked');

			} else {
				setHomeChecked('');
			}
		}

		if (val === 'Educational Institutions') {
			if (checked === true) {
				setEducationalChecked('checked');
			} else {
				setEducationalChecked('');
			}
		}

		if (val === 'Office') {
			if (checked === true) {
				setOfficeChecked('checked');
			} else {
				setOfficeChecked('');
			}
		}
		if (val === 'Public spaces') {
			if (checked === true) {
				setPublicChecked('checked');
			} else {
				setPublicChecked('');
			}
		}

		if (val === 'Any Other') {
			if (checked === true) {
				setAnyChecked('checked');
			} else {
				setAnyChecked('');
			}
		}

	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter05Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="81" star="60" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3">
								<div
									className="col-lg-9 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>


									<p>{t('M2chapter05screen02Text1')} </p>
									<h2 className="h2">{t('engage')} </h2>

									<p>{t('M2chapter05screen02Text2')}</p>
									<div className="positon-submit">
										<textarea
											className="form-control"
											rows="1"
											placeholder="Write Something here....."
											onChange={(e) => setCheckedQues1(e.target.value)}
											defaultValue={checkedQues1}
										></textarea>
									</div>
									<p> {t('M2chapter05screen02Text3')}</p>
									<div className="positon-submit">
										<textarea
											className="form-control"
											rows="1"
											placeholder="Write Something here....."
											onChange={(e) => setCheckedQues2(e.target.value)}
											defaultValue={checkedQues2}
										></textarea>
									</div>

									<p>{t('M2chapter05screen02Text4')}</p>


									<div className="row">
										<div className="col-md-6">



											<div className="d-flex">
												<div className="form-check form-check-inline">
													<input
														className="form-check-input custom-check"
														type="checkbox"
														name="namecircle"
														id="mycirle5"
														defaultValue="Home"
														onClick={saveGenderBased}
														checked={homeChecked}
													/>
													<label className="form-check-label" htmlFor="mycirle5">
														{t('home')}
													</label>
												</div>
											</div>
											<div className="d-flex">
												<div className="form-check form-check-inline">
													<input
														className="form-check-input custom-check"
														type="checkbox"
														name="namecircle"
														id="mycirle6"
														defaultValue="Educational Institutions"
														onClick={saveGenderBased}
														checked={educationChecked}
													/>
													<label className="form-check-label" htmlFor="mycirle6">
														{t('educational_institutions')}
													</label>
												</div>
											</div>

											<div className="d-flex">
												<div className="form-check form-check-inline">
													<input
														className="form-check-input custom-check"
														type="checkbox"
														name="namecircle"
														id="mycirle7"
														defaultValue="Office"
														onClick={saveGenderBased}
														checked={officeChecked}
													/>
													<label className="form-check-label" htmlFor="mycirle7">
														{t('office')}
													</label>
												</div>
											</div>


										</div>
										<div className="col-md-6">

											<div className="d-flex">
												<div className="form-check form-check-inline">
													<input
														className="form-check-input custom-check"
														type="checkbox"
														name="namecircle"
														id="mycirle8"
														defaultValue="Public spaces"
														onClick={saveGenderBased}
														checked={publicChecked}
													/>
													<label className="form-chePublic form-check-label spacesck-label" htmlFor="mycirle8">
														{t('public_spaces')}
													</label>
												</div>
											</div>


											<div className="d-flex">
												<div className="form-check form-check-inline">
													<input
														className="form-check-input custom-check"
														type="checkbox"
														name="namecircle"
														id="mycirle9"
														defaultValue="Any Other"
														onClick={saveGenderBased}
														checked={anyChecked}
													/>
													<label className="form-check-label" htmlFor="mycirle9">
														{t('any_other')}
													</label>
												</div>

											</div>

											<TextArea />





										</div>
									</div>
								</div>
								<div
									className="col-lg-3 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img width="55%" src="/assets/img/md1/md1-shap-5-02.png" alt="" />
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-05-screen-01" />
								{/* <NextBtn
                  link="/module-01/chapter-05-screen-03"
                  text="Go Next"
                /> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Chapter05Screen02;
