import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter04Screen09 = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <div className="wrapper">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
          <Header leftChapter="3/5" percentage="76" star="54" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-top justify-content-center pt-20 mt-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <h3 className="h3">{t('M2chapter04screen09Text1')}</h3>
                </div>
                <div
                  className="col-lg-6 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <ul className="without-list">
                    <li>{t('M2chapter04screen09Text2')}</li>
                    <li>{t('M2chapter04screen09Text3')}</li>
                    <li>{t('M2chapter04screen09Text4')} </li>
                    <li>{t('M2chapter04screen09Text5')} </li>
                    <li>{t('M2chapter04screen09Text6')} </li>
                    <li>{t('M2chapter04screen09Text7')} </li>
                    <li>{t('M2chapter04screen09Text8')} </li>
                    <li>{t('M2chapter04screen09Text9')} </li>
                    <li>{t('M2chapter04screen09Text10')} </li>
                    <li>{t('M2chapter04screen09Text11')} </li>
                    <li>{t('M2chapter04screen09Text12')}</li>
                    <li>{t('M2chapter04screen09Text13')}</li>
                    <li>{t('M2chapter04screen09Text14')}</li>
                    <li>{t('M2chapter04screen09Text15')}</li>
                    <li>{t('M2chapter04screen09Text16')}</li>
                    <li>{t('M2chapter04screen09Text17')}</li>
                  </ul>
                </div>
                <div
                  className="col-lg-6  wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.1s"
                >
                  <ul className="without-list mt-m-3">
                    <li>{t('M2chapter04screen09Text18')}</li>
                    <li>{t('M2chapter04screen09Text19')} </li>
                    <li>{t('M2chapter04screen09Text20')}</li>
                    <li>{t('M2chapter04screen09Text21')} </li>
                    <li>{t('M2chapter04screen09Text22')}</li>
                    <li>{t('M2chapter04screen09Text23')}</li>
                    <li>{t('M2chapter04screen09Text24')}</li>
                    <li>{t('M2chapter04screen09Text25')} </li>
                    <li>{t('M2chapter04screen09Text26')} </li>
                    <li>{t('M2chapter04screen09Text27')}</li>
                    <li>{t('M2chapter04screen09Text28')} </li>
                    <li>{t('M2chapter04screen09Text29')} </li>
                    <li>{t('M2chapter04screen09Text30')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">
                <PrevBtn link="/module-02/chapter-04-screen-08" />
                <NextBtn
                  link="/module-02/chapter-04-screen-10"
                  text={t('CommonBtnGoNext')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter04Screen09;
