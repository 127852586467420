import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { NavLink } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter04Screen03 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="3/5" percentage="64" star="40" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-3">
								<div
									className="col-lg-7 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<h2 className="h2">{t('learn')} </h2>
									<p>
									{t('M2chapter04screen03Text1')}
										</p>
									<p><b>{t('M2chapter04screen03Text2')}{" "}</b>
									</p>

									<a
										href="https://www.youtube.com/watch?v=xzk3JXkDqpQ"
										target="_blank"
										rel="noreferrer"
									>
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p>{t('M2chapter04screen03Text3')}</p>
											</div>
										</div>
									</a>


								</div>
								<div
									className="col-lg-5 mt-m-2 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img width="70%" src="/assets/img/md1/md1-shap-4-3.png" alt="" />
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-04-screen-02" />
								<NextBtn link="/module-02/chapter-04-screen-04" text={t('CommonBtnGoNext')} />

							</div>
						</div>
					</div>
				</div>
			</div>
		</>

	);
};

export default Chapter04Screen03;
