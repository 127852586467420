import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter05Screen07 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div className="fixed-header">
					<div className="container-fluid cf-set">
						<Header leftChapter="4/5" percentage="80" star="80" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-2">
								<div
									className="col-lg-7 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<p className="mb-2 mt-3">
										Awareness and behavior change work with boys and men, gender
										responsive parenting work with parents and other stakeholders
										who impact the lives of boys and men. Holding media
										accountable for projecting and influencing the culture of
										violent masculinity and instead inspiring gender equitable and
										inclusive masculinities in media. Using positive examples from
										the media for awareness and behaviour change. Working with the
										stakeholders in the state- police, law enforcement,
										politicians, civil servants etc. and service providers in the
										various sectors especially educational institutions. Across
										all the sectors and spheres engaging with boys and men
										actively to inspire gender equitable and inclusive
										masculinities. This will enable them as activists and allies
										for a gender-just and equitable world.
									</p>
								</div>
								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>

										<img
											width="72%"
											draggable="false"
											src="/assets/img/md4/md4-shap-5-7.png"
											alt=""
										/>
									</center>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-04/chapter-05-screen-06" />

									<NextBtn link="/module-04/chapter-05-screen-08" text={t('CommonBtnGoNext')} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="modal fade"
					id="staticBackdrop"
					data-bs-backdrop="static"
					data-bs-keyboard="false"
					tabIndex={-1}
					aria-labelledby="staticBackdropLabel"
					aria-hidden="true"
				>
					<div className="modal-dialog modal-dialog-centered modal-xl">
						<div className="modal-content modal-bg-blue">
							<div className="modal-body">
								<button
									type="button"
									className="btn-close close-set"
									data-bs-dismiss="modal"
									aria-label="Close"
								>
									<i className="bi bi-x-lg" />
								</button>
								<p className="text-white text-center">
									Kamla Bhasin - Wikipedia
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter05Screen07;
