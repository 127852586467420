import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';





const Chapter04Screen11 = () => {

  const navigate = useNavigate();

  const { t, i18n } = useTranslation();



  const [checkedQues7, setCheckedQues7] = useState([]);
  const [checkedQues7Option1, setCheckedQues7Option1] = useState('');
  const [checkedQues7Option2, setCheckedQues7Option2] = useState('');
  const [checkedQues7Option3, setCheckedQues7Option3] = useState('');
  const [checkedQues7Option4, setCheckedQues7Option4] = useState('');
  const [checkedQues7Option5, setCheckedQues7Option5] = useState('');
  const [checkedQues7Option6, setCheckedQues7Option6] = useState('');
  const [checkedQues7Option7, setCheckedQues7Option7] = useState('');
  const [checkedQues7Option8, setCheckedQues7Option8] = useState('');
  const [checkedQues7Option9, setCheckedQues7Option9] = useState('');
  const [checkedQues7Option10, setCheckedQues7Option10] = useState('');
  const [checkedQues7Option11, setCheckedQues7Option11] = useState('');
  const [checkedQues7Option12, setCheckedQues7Option12] = useState('');
  const [checkedQues7Option13, setCheckedQues7Option13] = useState('');

  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const saveRadio = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    // alert(quesId);
    checkedVal(quesId, e.target.value)
  }
  const saveGenderBased = (e) => {
    const quesId = e.target.getAttribute("data-quesId");
    const res = e.target.value;
    const checked = e.target.checked;
    checkBoxChecked(res, checked, quesId);
    if (quesId === '7') {
      setCheckedQues7(checkedQues7 => [...checkedQues7, res]);
    }



  }
  const saveAns = () => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": "",
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        },
        "ques4": {
          "ans": "",
          "ques_id": "4"
        },
        "ques5": {
          "ans": "",
          "ques_id": "5"
        },
        "ques6": {
          "ans": "",
          "ques_id": "6"
        },
        "ques7": {
          "ans": checkedQues7.join(','),
          "ques_id": "7"
        },
      }
    });
    var config = {
      method: 'post',
      url: BaseURL + '/HumanRightsFinalQuiz',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate('/module-05/chapter-04-screen-12')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const BtnDisabled = () => {
    if (checkedQues7.length > 0) {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                {t('CommonBtnGoNext')}

                <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };
  const checkedVal = (param, ans) => {
    if (param === '7') {
      const ansArr = ans.split(',');
      ansArr.map((el) => {
        const checked = true;
        checkBoxChecked(el, checked, param);
      })
      setCheckedQues7(ansArr)
    }




  }
  const checkBoxChecked = (val, checked, quesId) => {


    if (quesId === '7') {
      if (val === 'option1') {
        if (checked === true) {
          setCheckedQues7Option1('checked');
        } else {
          setCheckedQues7Option1('');
        }
      }
      if (val === 'option2') {
        if (checked === true) {
          setCheckedQues7Option2('checked');
        } else {
          setCheckedQues7Option2('');
        }
      }
      if (val === 'option3') {
        if (checked === true) {
          setCheckedQues7Option3('checked');
        } else {
          setCheckedQues7Option3('');
        }
      }

      if (val === 'option4') {
        if (checked === true) {
          setCheckedQues7Option4('checked');
        } else {
          setCheckedQues7Option4('');
        }
      }

      if (val === 'option5') {
        if (checked === true) {
          setCheckedQues7Option5('checked');
        } else {
          setCheckedQues7Option5('');
        }
      }

      if (val === 'option6') {
        if (checked === true) {
          setCheckedQues7Option6('checked');
        } else {
          setCheckedQues7Option6('');
        }
      }

      if (val === 'option7') {
        if (checked === true) {
          setCheckedQues7Option7('checked');
        } else {
          setCheckedQues7Option7('');
        }
      }

      if (val === 'option8') {
        if (checked === true) {
          setCheckedQues7Option8('checked');
        } else {
          setCheckedQues7Option8('');
        }
      }

      if (val === 'option9') {
        if (checked === true) {
          setCheckedQues7Option9('checked');
        } else {
          setCheckedQues7Option9('');
        }
      }

      if (val === 'option10') {
        if (checked === true) {
          setCheckedQues7Option10('checked');
        } else {
          setCheckedQues7Option10('');
        }
      }

      if (val === 'option11') {
        if (checked === true) {
          setCheckedQues7Option11('checked');
        } else {
          setCheckedQues7Option11('');
        }
      }

      if (val === 'option12') {
        if (checked === true) {
          setCheckedQues7Option12('checked');
        } else {
          setCheckedQues7Option12('');
        }
      }

      if (val === 'option13') {
        if (checked === true) {
          setCheckedQues7Option13('checked');
        } else {
          setCheckedQues7Option13('');
        }
      }










    }

  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/HumanRightsFinalQuizResult',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          // console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(), result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])




  return (
    <>
      <div className="wrapper md-5-bg">
        <div className="fixed-header">
          <div className="container-fluid cf-set">
            <Header leftChapter="3/4" percentage="75" star="36" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">

              <div className="row align-items-top justify-content-between pt-20 ">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >

                  <h4 className="mb-2 pn"> {t('M5chapter04Screen11Text1')}</h4>

                  <div className="d-flex ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle"

                        defaultValue="option1"


                        onClick={saveGenderBased}
                        checked={checkedQues7Option1}
                        data-quesId="7"

                      />
                      <label className="form-check-label" htmlFor="mycirle">{t('M5chapter04Screen11Text2')} </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle2"
                        defaultValue="option2"
                        onClick={saveGenderBased}
                        checked={checkedQues7Option2}
                        data-quesId="7"
                      />
                      <label className="form-check-label" htmlFor="mycirle2">{t('M5chapter04Screen11Text3')} </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle3"
                        defaultValue="option3"

                        onClick={saveGenderBased}
                        checked={checkedQues7Option3}
                        data-quesId="7"
                      />
                      <label className="form-check-label" htmlFor="mycirle3">{t('M5chapter04Screen11Text4')} </label>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle4"
                        defaultValue="option4"
                        onClick={saveGenderBased}
                        checked={checkedQues7Option4}
                        data-quesId="7"
                      />
                      <label className="form-check-label" htmlFor="mycirle4">{t('M5chapter04Screen11Text5')}</label>
                    </div>
                  </div>



                  <div className="d-flex ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle"
                        defaultValue="option5"
                        onClick={saveGenderBased}
                        checked={checkedQues7Option5}
                        data-quesId="7"
                      />
                      <label className="form-check-label" htmlFor="mycirle">{t('M5chapter04Screen11Text6')} </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle2"
                        defaultValue="option6"

                        onClick={saveGenderBased}
                        checked={checkedQues7Option6}
                        data-quesId="7"
                      />
                      <label className="form-check-label" htmlFor="mycirle2">{t('M5chapter04Screen11Text7')}</label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle3"
                        defaultValue="option7"

                        onClick={saveGenderBased}
                        checked={checkedQues7Option7}
                        data-quesId="7"
                      />
                      <label className="form-check-label" htmlFor="mycirle3">{t('M5chapter04Screen11Text8')} </label>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle4"
                        defaultValue="option8"
                        onClick={saveGenderBased}
                        checked={checkedQues7Option8}
                        data-quesId="7"
                      />
                      <label className="form-check-label" htmlFor="mycirle4">{t('M5chapter04Screen11Text9')}</label>
                    </div>
                  </div>



                  <div className="d-flex ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle"
                        defaultValue="option9"
                        onClick={saveGenderBased}
                        checked={checkedQues7Option9}
                        data-quesId="7"
                      />
                      <label className="form-check-label" htmlFor="mycirle">{t('M5chapter04Screen11Text10')} </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle2"
                        defaultValue="option10"
                        onClick={saveGenderBased}
                        checked={checkedQues7Option10}
                        data-quesId="7"
                      />
                      <label className="form-check-label" htmlFor="mycirle2">{t('M5chapter04Screen11Text11')} </label>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle3"
                        defaultValue="option11"
                        onClick={saveGenderBased}
                        checked={checkedQues7Option11}
                        data-quesId="7"
                      />
                      <label className="form-check-label" htmlFor="mycirle3">{t('M5chapter04Screen11Text12')}  </label>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle4"
                        defaultValue="option12"
                        onClick={saveGenderBased}
                        checked={checkedQues7Option12}
                        data-quesId="7"
                      />
                      <label className="form-check-label" htmlFor="mycirle4">{t('M5chapter04Screen11Text13')}</label>
                    </div>
                  </div>

                  <div className="d-flex ">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input custom-check" type="checkbox" name="namecircle" id="mycirle4"

                        defaultValue="option13"
                        onClick={saveGenderBased}
                        checked={checkedQues7Option13}
                        data-quesId="7"
                      />
                      <label className="form-check-label" htmlFor="mycirle4">{t('M5chapter04Screen11Text14')} </label>
                    </div>
                  </div>

                </div>
              </div>


            </div>
          </div>
        </div>
        <div
          className="button-fixed wow fadeInDown"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <div className="container-fluid cf-set">
            <div className="row">
              <div className="col-lg-12">
                <div className=" d-flex justify-content-end">
                  <PrevBtn link="/module-05/chapter-04-screen-10" />

                  {/* <NextBtn link="/module-05/chapter-04-screen-12" text="Go Next"/> */}
                  <BtnDisabled />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter04Screen11;
