import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen22 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();

	const [checkedQues8, setCheckedQues8] = useState([]);
	const [checkedQues8Option1, setCheckedQues8Option1] = useState('');
	const [checkedQues8Option2, setCheckedQues8Option2] = useState('');
	const [checkedQues8Option3, setCheckedQues8Option3] = useState('');
	const [checkedQues8Option4, setCheckedQues8Option4] = useState('');
	const [checkedQues8Val, setCheckedQues8Val] = useState('');


	const [checkedQues9Option1, setCheckedQues9Option1] = useState('');
	const [checkedQues9Option2, setCheckedQues9Option2] = useState('');
	const [checkedQues9Option3, setCheckedQues9Option3] = useState('');
	const [checkedQues9Option4, setCheckedQues9Option4] = useState('');
	const [checkedQues9Val, setCheckedQues9Val] = useState('');






	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const [showModalText, setShowModalText] = useState();
	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;

		checkBoxChecked(res, checked, quesId);
		if (quesId === '8') {

			setCheckedQues8(checkedQues8 => [...checkedQues8, res]);

		}



	}


	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");



		if (quesId === '9') {
			setShowModalText(t('M3chapter03Screen22Text7'))
		}



		setShow(true);
		checkedVal(quesId, e.target.value)

	}


	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques8": {
					"ans": checkedQues8.join(','),
					"ques_id": "8"
				},
				"ques9": {
					"ans": checkedQues9Val,
					"ques_id": "9"
				},



			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SexualityChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.status === "Token is Expired") {
					window.localStorage.clear();
					navigate('/login')
				}
				if (response.data.message === "Token Invalid") {
					window.localStorage.clear();
					navigate('/login')
				}

				if (response.data.success === true) {
					navigate('/module-03/chapter-03-screen-23')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans) => {

		if (param === '8') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues8(ansArr)
		}



		if (param === '9') {

			if (ans === 'option1') {
				setCheckedQues9Option1('checked')
				setCheckedQues9Option2('')
				setCheckedQues9Option3('')
				setCheckedQues9Option4('')
			} else if (ans === 'option2') {
				setCheckedQues9Option1('')
				setCheckedQues9Option2('checked')
				setCheckedQues9Option3('')
				setCheckedQues9Option4('')
			} else if (ans === 'option3') {
				setCheckedQues9Option1('')
				setCheckedQues9Option2('')
				setCheckedQues9Option3('checked')
				setCheckedQues9Option4('')
			} else if (ans === 'option4') {
				setCheckedQues9Option1('')
				setCheckedQues9Option2('')
				setCheckedQues9Option3('')
				setCheckedQues9Option4('checked')
			}

			setCheckedQues9Val(ans)
		}





	}

	const checkBoxChecked = (val, checked, quesId) => {
		if (quesId === '8') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues8Option1('checked');
				} else {
					setCheckedQues8Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues8Option2('checked');
				} else {
					setCheckedQues8Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues8Option3('checked');
				} else {
					setCheckedQues8Option3('');
				}
			}
			if (val === 'option4') {
				if (checked === true) {
					setCheckedQues8Option4('checked');
				} else {
					setCheckedQues8Option4('');
				}
			}






		}



	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/sexualityChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	const BtnDisabled = () => {

		if (checkedQues8.length > 0 && checkedQues9Val !== ''
		) {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"
				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-2">
								<div
									className="col-lg-8 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

									{/* Yahan Par Popup Rahega */}

									<p className="mb-2 mt-3 pn"><span><b>3.</b></span> <b> {t('M3chapter03Screen22Text1')} </b> </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle3" id="mycirle7"
												onClick={saveGenderBased}
												defaultValue="option1"
												data-quesId="8"
												checked={checkedQues8Option1}

											/>
											<label className="form-check-label" htmlFor="mycirle7">{t('M3chapter03Screen22Text2')}  </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle3" id="mycirle8"
												onClick={saveGenderBased}
												defaultValue="option2"
												data-quesId="8"
												checked={checkedQues8Option2}
											/>
											<label className="form-check-label" htmlFor="mycirle8">{t('M3chapter03Screen22Text3')}  </label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle3" id="mycirle9"
												onClick={saveGenderBased}
												defaultValue="option3"
												data-quesId="8"
												checked={checkedQues8Option3}

											/>
											<label className="form-check-label" htmlFor="mycirle9">{t('M3chapter03Screen22Text4')}</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="checkbox"
												name="namecircle3" id="mycirle10"
												onClick={saveGenderBased}
												defaultValue="option4"
												data-quesId="8"
												checked={checkedQues8Option4}
											/>
											<label className="form-check-label" htmlFor="mycirle10">{t('M3chapter03Screen22Text5')} </label>
										</div>
									</div>




									<p className="mb-2 mt-3 pn"><span><b>4.</b></span> <b> {t('M3chapter03Screen22Text6')}</b> </p>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle4" id="mycirle7"
												onClick={saveRadio}
												defaultValue="option1"
												data-quesId="9"
												checked={checkedQues9Option1}

											/>
											<label className="form-check-label" htmlFor="mycirle7">	{t('true')}</label>
										</div>
									</div>
									<div className="d-flex">
										<div className="form-check form-check-inline">
											<input className="form-check-input custom-check" type="radio"
												name="namecircle4"
												id="mycirle8"
												onClick={saveRadio}
												defaultValue="option2"
												data-quesId="9"
												checked={checkedQues9Option2}

											/>
											<label className="form-check-label" htmlFor="mycirle8">	{t('false')} </label>
										</div>
									</div>


								</div>

								<div
									className="col-lg-4 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>
									<center>
										<img
											width="38%"
											draggable="false"
											src="/assets/img/md5/md-5-chap-3-11.png"
											alt=""
										/>
									</center>
								</div>




							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-03-screen-21" />
								{/* <NextBtn link="/module-03/chapter-03-screen-23" text="Go Next"/> */}
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>

			<Modal show={show} onHide={handleClose}>
				<Modal.Header closeButton>
					{/* <Modal.Title>Women are physically weak :</Modal.Title> */}
				</Modal.Header>
				<Modal.Body>
					<p>{showModalText}</p>
				</Modal.Body>
			</Modal>


		</>
	);
};

export default Chapter03Screen22;
