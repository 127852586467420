import React from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter05Screen04 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<>
				<div className="wrapper">
					<div
						className="fixed-header"

					>
						<div className="container-fluid cf-set">
							<Header leftChapter="4/5" percentage="83" star="64" />
						</div>
					</div>
					<div className="d-flex">
						<Sidebar />
						<div className="data-adjust">
							<div className="container-fluid cf-set">
								<div className="row align-items-top justify-content-between pt-20 mt-4">
									<div
										className="col-lg-8 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s"
									>
										<p className="mb-4">
											<b>{t('M2chapter05screen04Text1')}</b>

										</p>

										<p className="mb-4">
											{t('M2chapter05screen04Text2')}

										</p>

									</div>
									<div className="col-lg-3 wow fadeInDown"
										data-wow-duration="1s"
										data-wow-delay="0.2s">
										<center>
											<img width="53%" src="/assets/img/md1/md1-shap-5-04.png" alt="" />
										</center>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.3s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-02/chapter-05-screen-03" />
									<NextBtn
										link="/module-02/chapter-05-screen-05"
										text={t('CommonBtnGoNext')}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		</>
	);
};

export default Chapter05Screen04;
