import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter04Screen07N = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [checkedQues8Yes, setCheckedQues8Yes] = useState('');
	const [checkedQues8No, setCheckedQues8No] = useState('');
	const [checkedQues8Val, setCheckedQues8Val] = useState('');
	const [checkedQues9Yes, setCheckedQues9Yes] = useState('');
	const [checkedQues9No, setCheckedQues9No] = useState('');
	const [checkedQues9Val, setCheckedQues9Val] = useState('');
	const [checkedQues10Yes, setCheckedQues10Yes] = useState('');
	const [checkedQues10No, setCheckedQues10No] = useState('');
	const [checkedQues10Val, setCheckedQues10Val] = useState('');
	const [checkedQues11Yes, setCheckedQues11Yes] = useState('');
	const [checkedQues11No, setCheckedQues11No] = useState('');
	const [checkedQues11Val, setCheckedQues11Val] = useState('');



	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");

		checkedVal(quesId, e.target.value)

	}





	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": checkedQues8Val,
					"ques_id": "8"
				},
				"ques9": {
					"ans": checkedQues9Val,
					"ques_id": "9"
				},
				"ques10": {
					"ans": checkedQues10Val,
					"ques_id": "10"
				},
				"ques11": {
					"ans": checkedQues11Val,
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				}
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter04',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-02/chapter-04-screen-08')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}


	const checkedVal = (param, ans) => {
		// if (param === '8') {
		//   if (ans === 'yes') {
		//     setCheckedQues8Yes('checked')
		//     setCheckedQues8No('')
		//   } else {
		//     if (ans === 'no') {
		//       setCheckedQues8No('checked')
		//       setCheckedQues8Yes('')
		//     }
		//   }
		//   setCheckedQues8Val(ans)
		// }
		if (param === '9') {
			if (ans === 'yes') {
				setCheckedQues9Yes('checked')
				setCheckedQues9No('')
			} else {
				if (ans === 'no') {
					setCheckedQues9No('checked')
					setCheckedQues9Yes(''
					)
				}
			}
			setCheckedQues9Val(ans)
		}


		if (param === '10') {
			if (ans === 'yes') {
				setCheckedQues10Yes('checked')
				setCheckedQues10No('')
			} else {
				if (ans === 'no') {
					setCheckedQues10No('checked')
					setCheckedQues10Yes(''
					)
				}
			}
			setCheckedQues10Val(ans)
		}

		if (param === '11') {
			if (ans === 'yes') {
				setCheckedQues11Yes('checked')
				setCheckedQues11No('')

			} else {
				if (ans === 'no') {
					setCheckedQues11No('checked')
					setCheckedQues11Yes('')
				}
			}
			setCheckedQues11Val(ans)
		}

	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/GenderChapter04Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])

	const BtnDisabled = () => {
		if (checkedQues9Val !== '' && checkedQues10Val !== '' &&
			checkedQues11Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};


	return (
		<div className="wrapper">
			<div
				className="fixed-header"

			>
				<div className="container-fluid cf-set">
					<Header leftChapter="3/5" percentage="72" star="50" />
				</div>
			</div>
			<div className="d-flex">
				<Sidebar />
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-12 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>



							</div>



							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h2 class="h2">{t('M2chapter04screen07nText1')}</h2>
								<table class="table table-borderless table-padding-0">
									<tbody>
										<tr>
											<td>{t('M2chapter04screen07nText2')}</td>
											{/* <td>
						<div className="d-flex">
							<div className="form-check form-check-inline">
							<input
								className="form-check-input custom-check"
								type="radio"
								name="ques8"
								id="inlineRadio1"
								defaultValue="yes"
								onClick={saveRadio}
								data-quesId="8"
								checked={checkedQues8Yes}
							/>
							<label
								className="form-check-label"
								htmlFor="inlineRadio1"
							>
								Yes{" "}
							</label>
							</div>
							<div className="form-check form-check-inline">
							<input
								className="form-check-input custom-check"
								type="radio"
								name="ques8"
								id="inlineRadio1"
								defaultValue="no"
								onClick={saveRadio}
								data-quesId="8"
								checked={checkedQues8No}
							/>
							<label
								className="form-check-label "
								htmlFor="inlineRadio2"
							>
								No
							</label>
							</div>
						</div>
						</td> */}
										</tr>
										<tr>
											<td>1. {t('raazi')}</td>
											<td>
												<div className="d-flex" style={{"margin-right":"30px"}}>
													<div className="form-check form-check-inline">
														<input
															className="form-check-input custom-check"
															type="radio"
															name="ques9"
															id="inlineRadio1"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="9"
															checked={checkedQues9Yes}
														/>
														<label
															className="form-check-label"
															htmlFor="inlineRadio1"
														>
															{t('yes')}{" "}
														</label>
													</div>
													<div className="form-check form-check-inline">
														<input
															className="form-check-input custom-check"
															type="radio"
															name="ques9"
															id="inlineRadio1"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="9"
															checked={checkedQues9No}
														/>
														<label
															className="form-check-label "
															htmlFor="inlineRadio2"
														>
															{t('no')}
														</label>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>2. {t('highway')}</td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input
															className="form-check-input custom-check"
															type="radio"
															name="ques10"
															id="inlineRadio1"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="10"
															checked={checkedQues10Yes}
														/>
														<label
															className="form-check-label"
															htmlFor="inlineRadio1"
														>
															{t('yes')}{" "}
														</label>
													</div>
													<div className="form-check form-check-inline">
														<input
															className="form-check-input custom-check"
															type="radio"
															name="ques10"
															id="inlineRadio1"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="10"
															checked={checkedQues10No}
														/>
														<label
															className="form-check-label "
															htmlFor="inlineRadio2"
														>
															{t('no')}
														</label>
													</div>
												</div>
											</td>
										</tr>
										<tr>
											<td>3. {t('badhai_ho')}</td>
											<td>
												<div className="d-flex">
													<div className="form-check form-check-inline">
														<input
															className="form-check-input custom-check"
															type="radio"
															name="ques11"
															id="inlineRadio1"
															defaultValue="yes"
															onClick={saveRadio}
															data-quesId="11"
															checked={checkedQues11Yes}
														/>
														<label
															className="form-check-label"
															htmlFor="inlineRadio1"
														>
															{t('yes')}{" "}
														</label>
													</div>
													<div className="form-check form-check-inline">
														<input
															className="form-check-input custom-check"
															type="radio"
															name="ques11"
															id="inlineRadio1"
															defaultValue="no"
															onClick={saveRadio}
															data-quesId="11"
															checked={checkedQues11No}
														/>
														<label
															className="form-check-label "
															htmlFor="inlineRadio2"
														>
															{t('no')}
														</label>
													</div>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>

							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img width="70%" src="/assets/img/md1/md1-shap-4-6.png" alt="" />
								</center>
							</div>

						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.3s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-02/chapter-04-screen-07" />
								<BtnDisabled />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Chapter04Screen07N;
