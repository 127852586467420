import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import Sidebar from './components/common/Sidebar';
import './i18n';


import { HashRouter,BrowserRouter } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter  >

		<App />
	</BrowserRouter>,
);


// ReactDOM.render(

// 	document.getElementById('root')
// );

