import React, { useEffect, useState } from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";
const Chapter02Screen02 = () => {

  const navigate = useNavigate();
  const [checkedQues1, setCheckedQues1] = useState('');
  const { t, i18n } = useTranslation();

  const user_details = JSON.parse(localStorage.getItem('user_details'));




  const saveAns = () => {

    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "answers": {
        "ques1": {
          "ans": checkedQues1,
          "ques_id": "1"
        },
        "ques2": {
          "ans": "",
          "ques_id": "2"
        },
        "ques3": {
          "ans": "",
          "ques_id": "3"
        }



      }
    });
    var config = {
      method: 'post',
      url: BaseURL + '/ApproachChapter01',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          navigate('/module-06/chapter-01-screen-03')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const checkedVal = (param, ans) => {


    if (param === '1') {

      setCheckedQues1(ans)
    }




  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_details.user_id,
      "ques_id": ""
    });
    var config = {
      method: 'post',
      url: BaseURL + '/approachChapter01Result',
      headers: {
        'Authorization': `Bearer ${user_details.token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          const results = response.data.data.chapter;
          console.log(results);
          results.map((result) => {
            checkedVal(result.ques_id.toString(), result.ans)
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  const BtnDisabled = () => {

    if (checkedQues1 !== '') {
      return (
        <>
          <button
            className={`button_su`}
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <span className="su_button_circle" />
            <button onClick={saveAns} className="button_su_inner">
              <span className="button_text_container">
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>
            </button>
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="button_su disabled-btn" type="button" disabled="">
            <span className="su_button_circle"></span>
            <span className="button_su_inner">
              <span className="button_text_container">
                {" "}
                {t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
              </span>{" "}
            </span>
          </button>
        </>
      );
    }
  };



  return (
    <>
      <div className="wrapper bg-bd-2 hp">
        <div
          className="fixed-header"

        >
          <div className="container-fluid cf-set">
            <Header leftChapter="0/4" percentage="6" star="50" />
          </div>
        </div>
        <div className="d-flex">
          <Sidebar />
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-between pt-20 mt-5 mt-m-3">
                <div
                  className="col-lg-12 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                   <h2 className="h2">{t('reflect')}</h2>
                  <h3 className="h3">{t('M6hapter01screen02Text1')}…</h3>
                  <p className="mb-2 mt-3">{t('M6hapter01screen02Text2')}
                  </p>

                  <div className="mb-4">
                    <textarea className="form-control" defaultValue={checkedQues1} onChange={(e) => setCheckedQues1(e.target.value)} rows="4" placeholder=".."></textarea>
                  </div>

                  <p className="mb-2 mt-2">{t('M6hapter01screen02Text3')}.</p>


                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="button-fixed wow fadeInDown"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div className="container-fluid cf-set">
          <div className="row">
            <div className="col-lg-12">
              <div className=" d-flex justify-content-end">

                <PrevBtn link="/module-06/chapter-01-screen-01" />
                {/* <NextBtn link="/module-06/chapter-01-screen-03" text="Go Next"/> */}

                <BtnDisabled />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Chapter02Screen02;
