import React from 'react'
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useTranslation } from 'react-i18next';
import Sidebar from "../../../common/Sidebar";

const Chapter05Screen05 = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
    <div className="wrapper page-auto">
      <div
        className="fixed-header"

      >
        <div className="container-fluid cf-set">
        <Header leftChapter="4/5" percentage="82" star="64" />
        </div>
      </div>
      <div className="d-flex">
        <Sidebar />
        <div className="data-adjust">
          <div className="container-fluid cf-set">
            <div className="row align-items-top justify-content-between pt-20 mt-3">
              <div
                className="col-lg-12 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.2s"
                style={{
                  visibility: "visible",
                  animationDuration: "1s",
                  animationDelay: "0.2s",
                  animationName: "fadeInDown"
                }}
              >

              <h2 className="h2 mb-1">{t('engage')}</h2>
                <p className="mb-3">
                  {t('M2chapter05screen05Text1')}
                  
                </p>

                <p className="mb-5">
                {t('M2chapter05screen05Text2')}
                   
                  </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      className="button-fixed wow fadeInDown"
      data-wow-duration="1s"
      data-wow-delay="0.3s"
    >
      <div className="container-fluid cf-set">
        <div className="row">
          <div className="col-lg-12">
            <div className="d-flex align-items-top justify-content-end mt-4">

              <PrevBtn link="/module-02/chapter-05-screen-04" />
              <NextBtn link="/module-02/chapter-05-screen-07" text={t('CommonBtnGoNext')} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </>

  )
}

export default Chapter05Screen05