import React from "react";
import Header from "../../../header/Header";
import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter03Screen12 = () => {
	const { t, i18n } = useTranslation();
	return (
		<>
			<div className="wrapper bg-bd-2">
				<div
					className="fixed-header"

				>
					<div className="container-fluid cf-set">
						<Header leftChapter="2/4" percentage="50" star="50" />
					</div>
				</div>
				<div className="d-flex">
					<Sidebar />
					<div className="data-adjust">
						<div className="container-fluid cf-set">
							<div className="row align-items-top justify-content-between pt-20 mt-1">


								<div
									className="col-lg-12 wow fadeInDown"
									data-wow-duration="1s"
									data-wow-delay="0.2s"
								>

								<h2>{t('M3chapter03Screen12Text1N')}</h2>
									<table className="table table-bordered table-text-color-black table-dark table-red table-padding mt-4 text-center" >
										<tbody>
											<tr>
												<th><b>{t('M3chapter03Screen12Text1N1')}</b></th>
												<th><b>{t('M3chapter03Screen12Text1N2')}</b></th>
												<th> <b>{t('M3chapter03Screen12Text1N3')}</b></th>
											</tr>
											<tr>
												<td className="td-yellow width-20">{t('Feelings')}</td>
												<td className="td-green">{t('M3chapter03Screen12Text1')} </td>
												<td className="td-red">{t('M3chapter03Screen12Text2')}  </td>
											</tr>
											<tr>
												<td className="td-yellow width-20">{t('Communication')}</td>
												<td className="td-green"> {t('M3chapter03Screen12Text3')}</td>
												<td className="td-red">{t('M3chapter03Screen12Text4')}</td>
											</tr>
											<tr>
												<td className="td-yellow width-20">{t('Disagreements')}</td>
												<td className="td-green"> {t('M3chapter03Screen12Text5')}</td>
												<td className="td-red">{t('M3chapter03Screen12Text6')}</td>
											</tr>
											<tr>
												<td className="td-yellow width-20">{t('Arguments')}</td>
												<td className="td-green">{t('M3chapter03Screen12Text7')}</td>
												<td className="td-red">{t('M3chapter03Screen12Text8')}</td>
											</tr>
											<tr>
												<td className="td-yellow width-20">{t('Intimacy')}</td>
												<td className="td-green">{t('M3chapter03Screen12Text9')}</td>
												<td className="td-red">{t('M3chapter03Screen12Text10')}</td>
											</tr>

										</tbody>
									</table>

									<a href="https://www.youtube.com/watch?v=vp60M2ZrXcE&t=26s " target="_blank">
										<div className="d-flex align-items-center mange-icon mt-3">
											<div>
												<img src="/assets/img/chapter2/video-icon.png" alt="" />
											</div>
											<div>
												<p> <b>{t('M3chapter03Screen12Text12')} </b> </p>
											</div>
										</div>
									</a>


									<p className="mb-2  mt-3">{t('M3chapter03Screen12Text13')}  </p>
								</div>

								{/* <div
					className="col-lg-10 wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<center>
					<img
						width="100%"
						draggable="false"
						src="/assets/img/md5/chap02-2/4.jpg"
						alt=""
					/>
					</center>
				</div>

					*/}



							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">

								<PrevBtn link="/module-03/chapter-03-screen-11" />
								<NextBtn link="/module-03/chapter-03-screen-13" text={t('CommonBtnGoNext')} />


							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter03Screen12;
