import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
// import NextBtn from "../../../common/NextBtn";
import PrevBtn from "../../../common/PrevBtn";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import { useNavigate } from "react-router-dom";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';


const Chapter03Screen10 = () => {
	const { t, i18n } = useTranslation();
	const navigate = useNavigate();
	const [checkedQues15, setCheckedQues15] = useState('');


	const user_details = JSON.parse(localStorage.getItem('user_details'));

	const saveAns = () => {

		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": "",
					"ques_id": "5"
				},
				"ques6": {
					"ans": "",
					"ques_id": "6"
				},
				"ques7": {
					"ans": "",
					"ques_id": "7"
				},
				"ques8": {
					"ans": "",
					"ques_id": "8"
				},
				"ques9": {
					"ans": "",
					"ques_id": "9"
				},
				"ques10": {
					"ans": "",
					"ques_id": "10"
				},
				"ques11": {
					"ans": "",
					"ques_id": "11"
				},
				"ques12": {
					"ans": "",
					"ques_id": "12"
				},
				"ques13": {
					"ans": "",
					"ques_id": "13"
				},
				"ques14": {
					"ans": "",
					"ques_id": "14"
				},
				"ques15": {
					"ans": checkedQues15,
					"ques_id": "15"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter03',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-01/chapter-03-screen-11')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/SelfChapter03Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;

					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, []);

	const checkedVal = (param, ans) => {
		if (param === '15') {
			setCheckedQues15(ans)
		}






	}


	const BtnDisabled = () => {
		if (checkedQues15 !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')} <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};



	return (
		<div className="wrapper bg-bd-2">
			<div
				className="fixed-header"

			>
				<div className="container-fluid cf-set">
					<Header leftChapter="2/4" percentage="46" star="46" />
				</div>
			</div>
			<div className="d-flex">
				<Sidebar />
				<div className="data-adjust">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-4">
							<div
								className="col-lg-8 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>

								<p> {t('M1chapter03screen10Text1')}</p>
								<p className="mt-4"> 
									{t('M1chapter03screen10Text2')}
								</p>
								<div className="mt-4 positon-submit">
									<textarea
										className="form-control"
										rows={4}
										placeholder="Write Something here....."
										defaultValue={checkedQues15}
										onChange={(e) => setCheckedQues15(e.target.value)}
									/>

								</div>
							</div>


							<div
								className="col-lg-4 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img width="70%" src="/assets/img/md2/md2-shap-3-9.png" alt="" />
								</center>
							</div>




						</div>
					</div>
				</div>
			</div>
			<div
				className="button-fixed wow fadeInDown"
				data-wow-duration="1s"
				data-wow-delay="0.2s"
			>
				<div className="container-fluid cf-set">
					<div className="row">
						<div className="col-lg-12">
							<div className=" d-flex justify-content-end">
								<PrevBtn link="/module-01/chapter-03-screen-09" />

								{/* <NextBtn link="/module-02/chapter-03-screen-11" text="Go Next"/> */}
								<BtnDisabled />

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Chapter03Screen10;
