import React, { useState, useEffect } from "react";
import Header from "../../../header/Header";
import PrevBtn from "../../../common/PrevBtn";
import NextBtn from "../../../common/NextBtn";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BaseURL from "../../../config/Baseurl";
import Sidebar from "../../../common/Sidebar";
import { useTranslation } from 'react-i18next';

const Chapter02Screen07 = () => {

	const navigate = useNavigate();
	const [checkedQues7, setCheckedQues7] = useState([]);
	const [checkedQues7Option1, setCheckedQues7Option1] = useState('');
	const [checkedQues7Option2, setCheckedQues7Option2] = useState('');
	const [checkedQues7Option3, setCheckedQues7Option3] = useState('');

	const [checkedQues5Option1, setCheckedQues5Option1] = useState('');
	const [checkedQues5Option2, setCheckedQues5Option2] = useState('');
	const [checkedQues5Option3, setCheckedQues5Option3] = useState('');
	const [checkedQues5Option4, setCheckedQues5Option4] = useState('');
	const [checkedQues5Val, setCheckedQues5Val] = useState('');

	const [checkedQues6Option1, setCheckedQues6Option1] = useState('');
	const [checkedQues6Option2, setCheckedQues6Option2] = useState('');

	const [checkedQues6Val, setCheckedQues6Val] = useState('');

const { t, i18n } = useTranslation();
	const user_details = JSON.parse(localStorage.getItem('user_details'));
	const saveRadio = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		// alert(quesId);
		checkedVal(quesId, e.target.value)
	}
	const saveGenderBased = (e) => {
		const quesId = e.target.getAttribute("data-quesId");
		const res = e.target.value;
		const checked = e.target.checked;
		checkBoxChecked(res, checked, quesId);
		if (quesId === '7') {
			setCheckedQues7(checkedQues7 => [...checkedQues7, res]);
		}
	}
	const saveAns = () => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"answers": {
				"ques1": {
					"ans": "",
					"ques_id": "1"
				},
				"ques2": {
					"ans": "",
					"ques_id": "2"
				},
				"ques3": {
					"ans": "",
					"ques_id": "3"
				},
				"ques4": {
					"ans": "",
					"ques_id": "4"
				},
				"ques5": {
					"ans": checkedQues5Val,
					"ques_id": "5"
				},
				"ques6": {
					"ans": checkedQues6Val,
					"ques_id": "6"
				},
				"ques7": {
					"ans": checkedQues7.join(),
					"ques_id": "7"
				},
			}
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter02',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					navigate('/module-05/chapter-02-screen-08')
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}
	const BtnDisabled = () => {
		if (checkedQues5Val !== '' && checkedQues6Val !== '') {
			return (
				<>
					<button
						className={`button_su`}
						data-wow-duration="1s"
						data-wow-delay="0.2s"
					>
						<span className="su_button_circle" />
						<button onClick={saveAns} className="button_su_inner">
							<span className="button_text_container">
							{t('CommonBtnGoNext')}  <i className="bi bi-arrow-right" />
							</span>
						</button>
					</button>
				</>
			);
		} else {
			return (
				<>
					<button className="button_su disabled-btn" type="button" disabled="">
						<span className="su_button_circle"></span>
						<span className="button_su_inner">
							<span className="button_text_container">
								{" "}
								{t('CommonBtnGoNext')}  <i className="bi bi-arrow-right" />
							</span>{" "}
						</span>
					</button>
				</>
			);
		}
	};
	const checkedVal = (param, ans) => {
		if (param === '5') {
			if (ans === 'option1') {
				setCheckedQues5Option1('checked')
				setCheckedQues5Option2('');
				setCheckedQues5Option3('');
				setCheckedQues5Option4('');
			} else if (ans === 'option2') {
				setCheckedQues5Option1('')
				setCheckedQues5Option2('checked');
				setCheckedQues5Option3('')
				setCheckedQues5Option4('')
			} else if (ans === 'option3') {
				setCheckedQues5Option1('')
				setCheckedQues5Option2('');
				setCheckedQues5Option3('checked')
				setCheckedQues5Option4('')
			} else if (ans === 'option4') {
				setCheckedQues5Option1('')
				setCheckedQues5Option2('');
				setCheckedQues5Option3('')
				setCheckedQues5Option4('checked')
			}
			setCheckedQues5Val(ans)
		}

		if (param === '6') {
			if (ans === 'option1') {
				setCheckedQues6Option1('checked')
				setCheckedQues6Option2('');

			} else if (ans === 'option2') {
				setCheckedQues6Option1('')
				setCheckedQues6Option2('checked');

			}
			setCheckedQues6Val(ans)
		}


		if (param === '7') {
			const ansArr = ans.split(',');
			ansArr.map((el) => {
				const checked = true;
				checkBoxChecked(el, checked, param);
			})
			setCheckedQues7(ansArr)
		}

	}
	const checkBoxChecked = (val, checked, quesId) => {
		if (quesId === '7') {
			if (val === 'option1') {
				if (checked === true) {
					setCheckedQues7Option1('checked');
				} else {
					setCheckedQues7Option1('');
				}
			}
			if (val === 'option2') {
				if (checked === true) {
					setCheckedQues7Option2('checked');
				} else {
					setCheckedQues7Option2('');
				}
			}
			if (val === 'option3') {
				if (checked === true) {
					setCheckedQues7Option3('checked');
				} else {
					setCheckedQues7Option3('');
				}
			}
		}
	}
	useEffect(() => {
		var data = JSON.stringify({
			"user_id": user_details.user_id,
			"ques_id": ""
		});
		var config = {
			method: 'post',
			url: BaseURL + '/HumanRightsChapter02Result',
			headers: {
				'Authorization': `Bearer ${user_details.token}`,
				'Content-Type': 'application/json'
			},
			data: data
		};
		axios(config)
			.then(function (response) {
				if (response.data.success === true) {
					const results = response.data.data.chapter;
					// console.log(results);
					results.map((result) => {
						checkedVal(result.ques_id.toString(), result.ans)
					})
				}
			})
			.catch(function (error) {
				console.log(error);
			});
	}, [])


	return (
		<>
			<div className="wrapper page-auto md-5-bg">
				<div className="fixed-header ">
					<div className="container-fluid cf-set">
						<Header leftChapter="1/4" percentage="25" star="2" />
					</div>
				</div>
				<div className="d-flex">
				<Sidebar/>
				<div className="data-adjust data-adjust2">
					<div className="container-fluid cf-set">
						<div className="row align-items-top justify-content-between pt-20 mt-3">
							<div
								className="col-lg-9 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<h3>{t('reflectandapply')}</h3>
								<h4 className="h4 mb-3">{t('M5chapter02Screen7Text1')} </h4>
								<p className="mb-2 mt-3"><b>{t('M5chapter02Screen7Text2')} </b></p>
								<a
									href="https://www.youtube.com/watch?v=TtZBu9HPwV0"
									target="_blank"
									rel="noreferrer"
								>
									<div className="d-flex align-items-center mange-icon">
										<div>
											<img src="/assets/img/chapter2/video-icon.png" alt="" />
										</div>
										<div>
											<p>
											{t('M5chapter02Screen7Text3')}
											</p>
										</div>
									</div>
								</a>

								<h4 className="mb-2 mt-3 pn"> <span> <b>1.</b></span> <b>{t('M5chapter02Screen7Text4')}  </b></h4>
								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio" name="namecircle" id="ns1"
											defaultValue="option1"
											onClick={saveRadio}
											checked={checkedQues5Option1}
											data-quesId="5"
										/>
										<label className="form-check-label" htmlFor="ns1"> {t('M5chapter02Screen7Text5')} </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio" name="namecircle" id="ns21"
											defaultValue="option2"
											onClick={saveRadio}
											checked={checkedQues5Option2}
											data-quesId="5"
										/>
										<label className="form-check-label" htmlFor="ns21">{t('M5chapter02Screen7Text6')} </label>
									</div>
								</div>

								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio" name="namecircle" id="ns31"
											defaultValue="option3"
											onClick={saveRadio}
											checked={checkedQues5Option3}
											data-quesId="5"
										/>
										<label className="form-check-label" htmlFor="ns31"> {t('M5chapter02Screen7Text7')}  </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio" name="namecircle" id="ns41"
											defaultValue="option4"
											onClick={saveRadio}
											checked={checkedQues5Option4}
											data-quesId="5"
										/>
										<label className="form-check-label" htmlFor="ns41"> {t('M5chapter02Screen7Text8')} </label>
									</div>
								</div>


								<h4 className="mb-2 mt-3 pn"> <span> <b>2.</b></span> <b>{t('M5chapter02Screen7Text9')} </b></h4>
								<div className="d-flex ">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio" name="namecircle123" id="ns62"
											defaultValue="option1"
											onClick={saveRadio}
											checked={checkedQues6Option1}
											data-quesId="6"
										/>
										<label className="form-check-label" htmlFor="ns62"> {t('M5chapter02Screen7Text10')} </label>
									</div>
								</div>
								<div className="d-flex">
									<div className="form-check form-check-inline">
										<input className="form-check-input custom-check" type="radio" name="namecircle123" id="ns71"
											defaultValue="option2"
											onClick={saveRadio}
											checked={checkedQues6Option2}
											data-quesId="6"
										/>
										<label className="form-check-label" htmlFor="ns71">{t('M5chapter02Screen7Text11')}  </label>
									</div>
								</div>


							</div>
							<div
								className="col-lg-3 wow fadeInDown"
								data-wow-duration="1s"
								data-wow-delay="0.2s"
							>
								<center>
									<img
										width="65%"
										draggable="false"
										src="/assets/img/md3/md3-shap-2-7.png"
										alt=""
									/>
								</center>
							</div>
						</div>
					</div>
				</div>
				</div>
				<div
					className="button-fixed wow fadeInDown"
					data-wow-duration="1s"
					data-wow-delay="0.2s"
				>
					<div className="container-fluid cf-set">
						<div className="row">
							<div className="col-lg-12">
								<div className=" d-flex justify-content-end">
									<PrevBtn link="/module-05/chapter-02-screen-06" />

									{/* <NextBtn link="/module-05/chapter-02-screen-08" text="Go Next"/> */}
									<BtnDisabled />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Chapter02Screen07;
